import { Action } from '@ngrx/store';
import { ClientDTO } from '@models/client/client.dto';
// import { AllClientBenefitListActions } from '@store/main/client-management/client/horace/client-benefit-list/client-benefit-list.actions';
// import { AllClientBenefitActions } from '@store/main/client-management/client/horace/client-benefit/client-benefit.actions';
// import { AllClientInfoActions } from '@store/main/client-management/client/horace/client-info/client-info.actions';
// import { AllClientContactActions } from '@store/main/client-management/client/horace/client-contact/client-contact.actions';
// import { AllClientConfigActions } from '@store/main/client-management/client/horace/client-config/client-config.actions';
// tslint:disable-next-line:max-line-length
// import { AllClientBenefitConfigActions } from '@store/main/client-management/client/horace/client-benefit-config/client-benefit-config.actions';

export enum ClientActionTypes {
  GET_CLIENT = '[GENERIC] GET_CLIENT',
  GET_CLIENT_SUCCESS = '[GENERIC] GET_CLIENT_SUCCESS',
  GET_CLIENT_FAIL = '[GENERIC] GET_CLIENT_FAIL',
  SAVE_CLIENT_SUCCESS = '[GENERIC] SAVE_CLIENT_SUCCESS',
  SAVE_CLIENT_FAIL = '[GENERIC] SAVE_CLIENT_FAIL',
  DELETE_CLIENT = '[GENERIC] DELETE_CLIENT',
  DELETE_CLIENT_SUCCESS = '[GENERIC] DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_FAIL = '[GENERIC] DELETE_CLIENT_FAIL'
}

export class GetClientAction implements Action {
  readonly type = ClientActionTypes.GET_CLIENT;
  constructor(public payload: { entityClientId: string }) {}
}
export class GetClientSuccessAction implements Action {
  readonly type = ClientActionTypes.GET_CLIENT_SUCCESS;
  constructor(public payload: { client: ClientDTO }) {}
}
export class GetClientFailAction implements Action {
  readonly type = ClientActionTypes.GET_CLIENT_FAIL;
  constructor(public payload: { error: any }) {}
}

export class SaveClientSuccessAction implements Action {
  readonly type = ClientActionTypes.SAVE_CLIENT_SUCCESS;
  constructor(public payload: { client: ClientDTO }) {}
}
export class SaveClientFailAction implements Action {
  readonly type = ClientActionTypes.SAVE_CLIENT_FAIL;
  constructor(public payload: { error: any }) {}
}

export class DeleteClientAction implements Action {
  readonly type = ClientActionTypes.DELETE_CLIENT;
  constructor(public payload: { clientId: string }) {}
}
export class DeleteClientSuccessAction implements Action {
  readonly type = ClientActionTypes.DELETE_CLIENT_SUCCESS;
}
export class DeleteClientFailAction implements Action {
  readonly type = ClientActionTypes.DELETE_CLIENT_FAIL;
  constructor(public payload: { error: any }) {}
}

export type AllClientActions =
  | GetClientAction
  | GetClientSuccessAction
  | GetClientFailAction
  | SaveClientSuccessAction
  | SaveClientFailAction
  | DeleteClientAction
  | DeleteClientSuccessAction
  | DeleteClientFailAction;
  // | AllClientInfoActions
  // | AllClientContactActions
  // | AllClientConfigActions
  // | AllClientBenefitListActions
  // | AllClientBenefitActions;

  // | AllClientBenefitConfigActions;
