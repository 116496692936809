<!-- <div *ngIf='benefitEntityPolicyDetailDTO!=null &&benefitEntityPolicyDetailDTO.length>0 && devUser'>
  <app-benefittiers-policy [benefitEntityPolicyId]='_benefitEntityPolicyId'
    [benefitEntityPolicyDetailDTO]='benefitEntityPolicyDetailDTO'>
  </app-benefittiers-policy>
</div>

<div *ngIf='benefitEntityPolicyBillConfigDTO!=null && benefitEntityPolicyBillConfigDTO.length>0'>
  <app-benefitbillconfig [benefitEntityPolicyId]='_benefitEntityPolicyId'
    [benefitEntityPolicyBillConfigDTO]='benefitEntityPolicyBillConfigDTO'>
  </app-benefitbillconfig>
</div>

<div *ngIf='benefitEntityPolicyCoverageDTO!=null && benefitEntityPolicyCoverageDTO.length>0 && devUser'>
  <app-benefitcoverage [benefitEntityPolicyId]='_benefitEntityPolicyId'
    [benefitEntityPolicyCoverageDTO]='benefitEntityPolicyCoverageDTO'>
  </app-benefitcoverage>
</div>

<div *ngIf='benefitEntityPolicyCoverageRuleDTO!=null && benefitEntityPolicyCoverageRuleDTO.length>0'>
  <app-benefitcoveragerule [benefitEntityPolicyId]='_benefitEntityPolicyId'
    [benefitEntityPolicyCoverageRuleDTO]='benefitEntityPolicyCoverageRuleDTO'>
  </app-benefitcoveragerule>
</div>

<div *ngIf='coverageReductionsRuleDTO!=null && coverageReductionsRuleDTO.length>0'>
  <app-benefitreductionsrule [benefitEntityPolicyId]='_benefitEntityPolicyId'
    [coverageReductionsRuleDTO]='coverageReductionsRuleDTO'>
  </app-benefitreductionsrule>
</div>

<div *ngIf='coverageTerminationRuleDTO!=null && coverageTerminationRuleDTO.length>0'>
  <app-coverageterminationrule [coverageTerminationDTO]='coverageTerminationRuleDTO'
  [benefitEntityPolicyId]='_benefitEntityPolicyId'
  >
  </app-coverageterminationrule>
</div> -->


  <app-benefitbillconfig [benefitEntityPolicyId]='_benefitEntityPolicyId'
    [benefitEntityPolicyBillConfigDTO]='benefitEntityPolicyBillConfigDTO'>
  </app-benefitbillconfig>

  <app-benefitcoveragerule [benefitEntityPolicyId]='_benefitEntityPolicyId'
    [benefitEntityPolicyCoverageRuleDTO]='benefitEntityPolicyCoverageRuleDTO'>
  </app-benefitcoveragerule>

  <app-benefitreductionsrule [benefitEntityPolicyId]='_benefitEntityPolicyId'
    [coverageReductionsRuleDTO]='coverageReductionsRuleDTO'>
  </app-benefitreductionsrule>

  <app-coverageterminationrule 
  [benefitEntityPolicyId]='_benefitEntityPolicyId'
  [coverageTerminationDTO]='coverageTerminationRuleDTO'>
  </app-coverageterminationrule>

  <div *ngIf='!clientHM || devUser'>
    <app-benefitcoverage [benefitEntityPolicyId]='_benefitEntityPolicyId'
      [benefitEntityPolicyCoverageDTO]='benefitEntityPolicyCoverageDTO'>
    </app-benefitcoverage>
  </div>

  <div *ngIf='!clientHM|| devUser'>
    <app-benefittiers-policy [benefitEntityPolicyId]='_benefitEntityPolicyId'
      [benefitEntityPolicyDetailDTO]='benefitEntityPolicyDetailDTO'>
    </app-benefittiers-policy>
  </div>
