import { Component, OnInit, Input } from '@angular/core';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import * as _ from 'lodash';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SpinnerService } from '@shared/services/spinner.service';
import { ExcelService } from '@services/excel/excel.service';
@Component({
  selector: 'app-filetabs',
  templateUrl: './filetabs.component.html',
  styleUrls: ['./file.scss'],
})
export class FileTabsComponent extends BaseComponent implements OnInit {
  summary;
  summaryJson;
  errorJson;
  @Input()
  processId;
  @Input()
  processTypeId;
  @Input()
  type;
  @Input()
  jsonType;
  @Input()
  tabName;
  @Input()
  scheduleRunnerId;
  @Input()
  sql;
  rows;
  headers;
  errorKeys;
  summaryKeys;
  private _array = Array;
  private _object = Object;
  columnNames;
  data;
  oridata;
  footerColumnNames;
  footerData;
  headerColumnNames;
  headerData;
  sortName;
  sortValue: string | null = null;
  sortType;
  filters: any = [];
  loading = false;
  searchValue = '';

  constructor(
    private notification: NzNotificationService,
    private clientService: ClientService,
    private spinner: SpinnerService,
    protected excelService: ExcelService
  ) {
    super();
  }

  getTypeof(o) {
    return typeof o
  }

  ngOnInit() {
    if (this.type === 'Json') {
      const url =
        'FileManageMent/getRunnerSummary?scheduleRunnerId=' +
        this.scheduleRunnerId;
      const observ$ = this.clientService
        .getWithUrl(url, {})
        .pipe(untilComponentDestroyed(this));
      observ$.subscribe((data) => {
        this.summary = data.details[0];
        this.summaryJson = JSON.parse(
          (<any>this.summary.runnerSummary) as string
        );
        this.summaryKeys = Object.keys(this.summaryJson);
        this.errorJson = JSON.parse((<any>this.summary.errorSummary) as string);
        if (this.errorJson != null) {
          this.errorKeys = Object.keys(this.errorJson);
        }
      });
    }
    if (this.type === 'Sql') {
      // replace @scheduleRunnerId
      const regex = /@ScheduleRunnerId/gi;
      this.sql = this.sql.replace(regex, this.scheduleRunnerId);
      const url =
        'FileManageMent/runQuery?scheduleRunnerId=' +
        this.scheduleRunnerId +
        '&processTypeId=' +
        this.processTypeId +
        '&tabName=' +
        this.tabName +
        '&processId=' +
        this.processId;
      const observ$ = this.clientService.getWithUrl(url, {});
      observ$.subscribe((data) => {
        this.rows = data.details;
        if (this.rows != null && this.rows.length > 0) {
          this.headers = Object.keys(this.rows[0]);
          this.columnNames = this.headers;
          this.data = this.rows;
          this.oridata = this.data;
        }
      });
    }
  }

  downloadJson(key) {
    const excelrows: any = [];
    const rows = this.summaryJson[key];
    if (Array.isArray(this.summaryJson[key]) === true) {
      rows.forEach((row) => {
        delete row['$type'];
        excelrows.push(row);
      });
    } else {
      delete rows['$type'];
      excelrows.push(rows);
    }

    this.excelService.exportAsExcelFile(excelrows, key);
  }

  download() {
    const excelrows: any = this.rows;
    this.excelService.exportAsExcelFile(excelrows, this.tabName);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    // need to find data type
    this.sortType = 'text';
    this.search();
  }

  searchCust(key, obj) {
    // obj.filterType = 'in';
    const index = this.filters.findIndex((x) => x.key === key);
    if (index === -1) {
      this.filters.push({ value: this.searchValue, key, type: 'search' });
    } else {
      this.filters[index] = { value: this.searchValue, key, type: 'search' };
    }
    this.search();
  }
  
  resetCust(key) {
    const index = this.filters.findIndex((x) => x.key === key);
    if (index === -1) {
      // this.filters.push({value: this.searchValue, key , type: 'search'});
    } else {
      this.filters[index] = { value: '', key, type: 'search' };
      this.search();
    }
  }

  search(): void {
    this.data = this.oridata;
    this.rows = this.data;
    this.filters.forEach((element) => {
      const f = this.columnNames.findIndex((x) => x === element.key);
      if (element.value !== '') {
        this.rows = this.rows.filter((item) => {
          if (item[element.key] == null) {
            return false;
          }
          const index = item[element.key]
            .toLowerCase()
            .indexOf(element.value.toLowerCase());
          return index !== -1;
        });
      }
    });
    if (this.sortName && this.sortValue) {
      this.rows = this.rows.sort((a, b) => {
        const f = this.columnNames.findIndex((x) => x === this.sortName);
        return this.sortValue === 'ascend'
          ? // tslint:disable-next-line:no-non-null-assertion
            a[this.sortName] > b[this.sortName]
            ? 1
            : -1
          : // tslint:disable-next-line:no-non-null-assertion
          b[this.sortName] > a[this.sortName]
          ? 1
          : -1;
      });
      this.rows = [...this.rows];
    } else {
      // this.rows = this.rows;
      /* if (filteredData != null) {
            this.rows = [...filteredData];
          } */
    }
  }
}
