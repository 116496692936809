<div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
  <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
    height:200px; 
    position: fixed;
    top: 50%; 
    left: 50%; 
    margin-top: -100px; 
    margin-left: -100px;"></nz-spin>
  <span class="default-font-Color-dark">Loading ...</span>
</div>
<div style="padding:20px 0px 10px 20px">
  <h2 class="member-function-header">Bill Creation</h2>
</div>
<div class="row  col-20" style="margin: 0px">
  <div class="col-2">

    <ng-select (change)="selectedEntityChange($event)" [(ngModel)]="selectedEntity">
      <ng-container *ngFor="let item of entities">
        <ng-option value='{{item.id}}'>{{item.entityName}}</ng-option>
        <!-- <ng-option   value='4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d'>Horace Mann</ng-option>
    <ng-option   value='ac586073-69d6-41bf-875c-eb754f750457'>UTC</ng-option>
    <ng-option   value='31e56172-ac96-487c-a6b3-d5847954f3b2'>UNUM</ng-option>
    <ng-option   value='d6a2ff7e-bba0-48fa-9396-a6b202631669'>DBS</ng-option>
    <ng-option   value='fd6fa6d7-3684-45e9-beb2-44c677449b7b'>Agent Management</ng-option>
    <ng-option   value='3b7805a6-10fa-48b5-b0ee-ea5365258341'>Essex Corporation</ng-option>
    <ng-option   value='a11ee4de-9b44-4bcf-a01e-04e1a2fc6028'>Anthem BCBS</ng-option>
    <ng-option   value='c935affd-0e7c-4b2c-ae8b-1c9d0e53905b'>TENET</ng-option>
 -->
      </ng-container>
    </ng-select>
  </div>
  <!-- <div class="col-4"  style="padding-left: 0px"   >
    Select an Client:
    <ng-select style="width: 40%" (change)="selectedEntityClientChange($event)"
    [(ngModel)]="selectedEntityClient"
    >
            <ng-option *ngFor="let item of entityClients"
            [value]='item'>{{ item.entityClientName }}</ng-option>
    </ng-select>
</div> -->
  <div class="col-8" style="padding-left: 0px">
    <button mat-flat-button
    type="submit"
    color="primary" style="margin-left: 15px" app-popconfirm
      abcTitle="Are you sure you want to save?" (onOK)="onSubmit()">Save </button> &nbsp;
    <input type="file" id="fileUploader" name="fileUploader" accept=".xls, .xlsx" (change)="fileChange($event)" />
  </div>
</div>
<div style=" overflow: auto; white-space: nowrap; ">
  <app-dynamic-main-grid 
    (actionPopup)="actionPopup($event)" 
    pageSize=50 class="col-12" 
    gridid='billcreation'
    [inputRows]='rows'>
  </app-dynamic-main-grid>
</div>