<div class="date_wrap">
  <input #input 
         type="text"
         [textMask]="textMaskConfig"
         placeholder="mm/dd/yyyy"
         class="input date_wrap__input form-control"
         [matDatepicker]="picker"
         (dateInput)="onDateInput($event.value)"
         (dateChange)="onDateInput($event.value)"
         (focus)="inputFocus($event.target.value)"
         (blur)="inputBlur($event.target.value)" >

       
  <mat-datepicker-toggle matSuffix 
                         class="date_wrap__icon"
                         [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>