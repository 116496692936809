import { OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import {Directive} from '@angular/core';
@Directive() 
export class BaseComponent implements OnDestroy {
  ngOnDestroy() {

  }

  isTruthy(value: any): boolean {
    if (_.isString(value)) {
      value = ((value || '') as string).trim().toLowerCase();
      return !_.some(['false', '0', 'null'], x => x === value);
    }

    return !!value;
  }
  protected transurl(url, curval) {
    let myurl = url;
    while (true) {
      let n = myurl.search(/:/i);
      if (n === -1) {
        break;
      }
      let res = myurl.substring( n + 1);
      n = res.search(/&/i);
      if ( n === -1) {
        res = res.substring( 0 );
      } else {
        res = res.substring( 0, n );
      }
      const val = curval[res];
      const pt = ':' + res;
      if (val != null) {
        myurl = myurl.replace(pt, val);
      } else {
        break;
      }
    }
    return myurl;
  }
}
