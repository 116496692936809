import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { Store } from '@ngrx/store';
import { SpinnerService } from '@shared/services/spinner.service';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '@store/app.state';
import { MainState } from '@store/main/main.state';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { ClientService } from '@services/client/client.service';
import { getMainState, isMainStateMutating } from '@store/main/main.selector';
import { DynamicMainFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { BaseComponent } from '@shared/components/base.component';
import { Observable } from 'rxjs';
import { HttpService } from '@shared/api/http.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  templateUrl: './dynamic_form_with_grid_component.html',
})

export class DynamicFormWithGridContainnerComponent extends BaseComponent implements OnInit {
  @Output()
  onprint: EventEmitter<any> = new EventEmitter();
  formid; printformid; innerformid; innerformid1; getUrl; printButton; actionButton1; actionButton2; action1CheckField; action1CheckValue;
  formValue; params; _jsonURL;
  initValue; saveButtonInit = 'false'; saving = false;
  saveSuccess = false; action1msg; param; param1; param2; param3; param4;
  successMessage = 'Record saved successfully';
  saved = false; paraArr; deleteButton;
  addInit = {}; ddInit1 = {};
  @ViewChild(DynamicMainFormComponent)
  protected memberComponent: DynamicMainFormComponent;
  constructor(@Inject(MAT_DIALOG_DATA,) public data: any,
    protected clientService: ClientService,
    public dialogRef: MatDialogRef<DynamicFormWithGridContainnerComponent>,
    public store: Store<AppState>, 
    private route: ActivatedRoute, 
    protected notification: NzNotificationService,
    private routeHelper: RouteHelperService, 
    private httpser: HttpService, 
    private spinner: SpinnerService) {
    super();
    this.formid = data.formid;
    this.printformid = this.formid + 'print';
    this.formValue = data.item;
    this.innerformid = data.innerformid;
    this.innerformid1 = data.innerformid1;
    this.paraArr = this.data.paraArr;
    this._jsonURL = data.formid.toLowerCase() + 'formgrid';
  }

  public getJSON(): Observable<any> {
    return (
      this.httpser.get('json?path=' + this._jsonURL, {
        urlParams: null,
        options: {
          isMock: false
        }
      })
    );
  }

  onCancel() {
    const obj = this.memberComponent.getFormValue();
    if (this.saved) {
      this.dialogRef.close(obj);
    } else {
      this.dialogRef.close(this.saved);
    }
  }

  onPrint() {
    const item = { printformid: this.printformid, formValue: this.formValue };
    this.onprint.emit(item);
  }

  onSave() {

  }

  onDelete() {
    this.dialogRef.close();
  }

  private MainStateChange(state: MainState) {
    if (!isMainStateMutating(state)) {
      if (this.saving && !state.saving && state.saveError == null) {
        this.saveSuccess = true;
        this.saving = false;
      }
      this.spinner.hide();
    }
  }

  subscribeMainState() {
    this.store
      .select(getMainState)
      .pipe(untilComponentDestroyed(this))
      .subscribe(x => this.MainStateChange(x));
  }

  onAction1() {
    this.memberComponent.onAction1();
  }

  onAction2() {
    this.memberComponent.onAction2();
  }

  getFormValue() {
    const obj = this.memberComponent.getFormValue();
    return obj;
  }

  action1Check() {
    if (this.action1CheckField != null && this.action1CheckValue != null) {
      const obj = this.memberComponent.getFormValue();
      if (obj[this.action1CheckField] === this.action1CheckValue) {
        return false;
      }
    }
    return true;
  }

  onChangeForm(e) {
    if (e != null) {
      this.formValue = e;
    } else {
      this.formValue = this.getFormValue();
    }

    this.params = {};
    this.params[this.param] = this.formValue[this.param];
    this.params[this.param1] = this.formValue[this.param1];
    this.params[this.param2] = this.formValue[this.param2];
    this.params[this.param3] = this.formValue[this.param3];
    this.params[this.param4] = this.formValue[this.param4];
    this.addInit[this.param] = this.formValue[this.param];
    this.addInit[this.param1] = this.formValue[this.param1];
    this.addInit[this.param2] = this.formValue[this.param2];
    this.addInit[this.param3] = this.formValue[this.param3];
    this.addInit[this.param4] = this.formValue[this.param4];
  }

  ngOnInit() {
    this.getJSON().pipe(untilComponentDestroyed(this)).subscribe(data1 => {
      if (data1 == null) {
        return;
      }
      const json = JSON.parse((<any>data1.jsonText) as string);
      if (data1 === null) {
        this.params = {};
      } else {
        this.param = json.param;
        this.param1 = json.param1;
        this.param2 = json.param2;
        this.param3 = json.param3;
        this.param4 = json.param4;
        this.printButton = json.printButton;
        this.actionButton1 = json.actionButton1;
        this.actionButton2 = json.actionButton2;
        this.deleteButton = json.deleteButton;
        this.params = {};
        this.action1CheckValue = json.action1CheckValue;
        this.action1CheckField = json.action1CheckField;
        this.params[this.param] = this.formValue[this.param];
        this.params[this.param1] = this.formValue[this.param1];
        this.params[this.param2] = this.formValue[this.param2];
        this.params[this.param3] = this.formValue[this.param3];
        this.params[this.param4] = this.formValue[this.param4];
        this.addInit[this.param] = this.formValue[this.param];
        this.addInit[this.param1] = this.formValue[this.param1];
        this.addInit[this.param2] = this.formValue[this.param2];
        this.addInit[this.param3] = this.formValue[this.param3];
        this.addInit[this.param4] = this.formValue[this.param4];
        if (this.actionButton1 != null) {
          this.action1msg = 'Do you want to ' + this.actionButton1 + '?';
        }
      }
    });
  }
  
}
