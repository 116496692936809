<ng-container *ngIf="benefitEntities">
  <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px">
    <h5 class="col-lg-8 col-md-8 col-sm-8 col-xs-8 BenefitsCart"><i
        class="fa fa-shopping-cart BenefitsCart"></i>&nbsp;&nbsp;Benefits Cart</h5>
    <span></span>
  </div>
  <div *ngFor="let item of benefitEntities; index as i">
    <ng-container *ngIf="subCost(item.benefitEntityId) as subTot">
      <a id="v-pills-tab-{{item.benefitEntityId}}" *ngIf="subTot> 0 "
        style="font-size:16px !important;font-weight: 400;border-bottom: 1px solid rgb(230, 230, 230);padding-top:8px;padding-bottom:5px"
        data-toggle="pill" href="#v-pills-{{item.benefitEntityId}}" role="tab" class="col-12 no-gutters row">
        <div class="col-8 no-gutters">
          {{item.benefitEntityDescription}}
        </div>
        <div class="col-4 no-gutters" style="text-align:right" *ngIf='subTot'>
          <span>
            <span class="CostLabel">
              <span class="CartSubCost"> {{subTot | currency:'USD':true:'0.2-3'}}
              </span>
            </span>
          </span>
        </div>
        <!-- <span *ngFor="let p of getPolicies(item.benefitEntityId) as policies" style="float: right;">
                  <span *ngIf="policies.length > 0 && benefitValue[item.benefitEntityId]!=null && benefitValue[item.benefitEntityId][p]?.tiers?.tierName!=null ">
                  <span   class="col-12"><span class="CostLabel">Cost :<span class="CartSubCost"> {{((benefitValue? benefitValue[item.benefitEntityId][p]?.tiers?.rate  :'' )?(benefitValue? benefitValue[item.benefitEntityId][p]?.tiers?.rate  :'' ) : '5.67')  | currency}}</span></span></span> 
                  </span>
        </span> -->
      </a>
    </ng-container>
  </div>
  <br>
  <div class="row CostText">
    <div class="col-6">
      <span style="font-weight: 400;">Total Cost</span>
    </div>
    <div class="col-5" style="text-align: right;color: green;font-weight: 400;padding-right: 0px ">
      {{getSum() | currency :'USD':true:'0.2-3'}}
    </div>
  </div>
  <div>
    <br>
  </div>
  <button 
    title="Enrollment Submission" 
    style="width:100%" 
    mat-flat-button color="primary" 
    app-popconfirm
    abcTitle="Are you sure you want to save?" 
    (onOK)='onEnrollment()' 
    *ngIf='showconfirm===true'>Confirm Enrollment <i
    class="fa fa-arrow-right"></i>
  </button>
</ng-container>