import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
@Component({
    selector: 'app-dynamic-grid-preview',
    templateUrl: './dynamic-grid-preview.component.html'
  })
  export class DynamicGridPreviewComponent extends BaseComponent implements OnInit {
    @Input()
    gridConfigs;
    @Input()
    paging;
    ngOnInit() {}

  }
