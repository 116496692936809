<div class="ssn"
     [ngClass]="revealIcon ? 'ssn-icon' : ''">

  <div *ngIf="revealIcon"
       class="icon ssn__icon">
    <span class="fa fa-eye"></span>
  </div>

  <input #input
         type="text"
         class="input form-control"
         [ngClass]="revealIcon ? 'ssn__input-icon' : ''"
         (focus)="inputFocus($event.target.value)"
         (blur)="inputBlur($event.target.value)">
</div>