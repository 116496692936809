import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, zip } from 'rxjs';
import * as _ from 'lodash';
import { HttpService } from '@shared/api/http.service';
import { ConfigService } from '@shared/config.service';
import { EntityDTO, EntityIdClientIdDTO } from '@models/entity/entity.dto';
import { KNOWN_ENTITY_NAME } from '@shared/contants/contants';
import { switchMap, map, mergeMap } from 'rxjs/operators';
import { ENTITY_CODE_TO_ID } from '@shared/route/constants';
import { ClientDTO } from '@models/client/client.dto';

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  private apiUrls: any;
  private entityListWithClientsCache: any[];

  constructor(private configService: ConfigService, private http: HttpService) {
    this.apiUrls = this.configService.api.entityService;
  }

  getEntityList(): Observable<EntityDTO[]> {
    return this.http.get(this.apiUrls.entityList, {
      options: {
        isMock: false
      }
    });
  }

  getEntityClientList(params: { entityId?: string }): Observable<any[]> {
    return this.http.get(this.apiUrls.entityClients, {
      urlParams: params,
      options: {
        isMock: false
      }
    });
  }

  getEntityListWithClients(): Observable<any[]> {
    /* if (!_.isEmpty(this.entityListWithClientsCache)) {
      return of(this.entityListWithClientsCache);
    } */

    return this.getEntityList().pipe(
      switchMap(entities => {
        const array$ = entities.map(entity =>
          this.getEntityClientList({ entityId: entity['id'] }).pipe(
            map(clients => {
              const array = clients.map(client => {
                const dto = new EntityIdClientIdDTO();
                dto.entityId = entity.id;
                dto.entityName = entity.entityName;
                dto.clientId = client.clientId;
                dto.clientCode = client. clientCode;
                dto.clientName = client.clientName;
                dto.entityClientNumber = client.entityClientNumber;
                dto.entityClientId = client.entityClientId;
                dto.id = _.compact([
                  dto.entityId,
                  dto.clientId,
                  dto.entityClientNumber
                ]).join('|');

                return dto;
              });

              const entityDto = new EntityIdClientIdDTO();
              entityDto.entityId = entity.id;
              entityDto.entityName = entity.entityName;
              entityDto.id = [
                entityDto.entityId
              ].join('|');
              array.unshift(entityDto);

              return array;
            })
          )
        );
        return forkJoin(...array$);
      }),
      map(data => {
        this.entityListWithClientsCache = _.sortBy(_.flatten(data), x => x.Id);
        return this.entityListWithClientsCache;
      })
    );
  }

  getEntity(params: { entityId: string }): Observable<EntityDTO> {
    const entity = new EntityDTO();
    entity.id = params.entityId;
    entity.entityName = KNOWN_ENTITY_NAME[params.entityId];
    return of(entity);
    // TODO: get from api
    // return this.http.get(this.apiUrls.entity, { urlParams: params });
  }
}
