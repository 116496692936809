<div class="dashboard">

  <div class="app-container">

    <div class="row">
      <div class="col-12" *ngIf='prePath==null'>

        <app-selected-member-header-share [memberState]="memberState" [entityClientId]='EntityClientId'>
        </app-selected-member-header-share>
        <hr style="margin: 5px 0px 0px 0px" />
        <!-- <span class="alert alert-danger"><span class="fa fa-bell"></span>&nbsp;Alert : Last payment of $45.25 is pending.</span> <br><br> -->
      </div>
        <div *ngFor="let dashboardConfig of dashboardConfigs" class="client-icon" >

          <div *ngIf="dashboardConfig.controlComponent==='dynamicformentity' &&  dashboardConfig.entityid==EntityId">
            <a [routerLink]="getMemberRoute(MEMBER_ROUTE_SEGMENT.dynamicForm+'/'+dashboardConfig.controlName)">
              <div class="icon-dashboard">
                <i [class]='dashboardConfig.controlIcon'></i><span>{{dashboardConfig.controlLabel}}</span>
                <div class="shape"></div>
              </div>
            </a>
          </div>
          <div *ngIf="dashboardConfig.controlComponent==='dynamicform'">
            <a [routerLink]="getMemberRoute(MEMBER_ROUTE_SEGMENT.dynamicForm+'/'+dashboardConfig.controlName)">
              <div class="icon-dashboard">
                <i [class]='dashboardConfig.controlIcon'></i><span>{{dashboardConfig.controlLabel}}</span>
                <div class="shape"></div>
              </div>
            </a>
          </div>
          <div *ngIf="dashboardConfig.controlComponent==='dynamicgrid'">
            <a [routerLink]="getMemberRoute(MEMBER_ROUTE_SEGMENT.dynamicGrid+'/'+dashboardConfig.controlName)">
              <div class="icon-dashboard">
                <i [class]='dashboardConfig.controlIcon'></i><span>{{dashboardConfig.controlLabel}}</span>
                <div class="shape"></div>
              </div>
            </a>
          </div>
          <div *ngIf="dashboardConfig.controlComponent==='dynamicletters'">
            <a [routerLink]="getMemberRoute('letters/'+dashboardConfig.controlName)">
              <div class="icon-dashboard">
                <i [class]='dashboardConfig.controlIcon'></i><span>{{dashboardConfig.controlLabel}}</span>
                <div class="shape"></div>
              </div>
            </a>
          </div>
          <div *ngIf="dashboardConfig.controlComponent==='dynamiclettersclient'">
            <a [routerLink]="getMemberRoute('letters/lettersclient')">
              <div class="icon-dashboard">
                <i [class]='dashboardConfig.controlIcon'></i><span>{{dashboardConfig.controlLabel}}</span>
                <div class="shape"></div>
              </div>
            </a>
          </div>
          <div *ngIf="dashboardConfig.controlComponent==='dynamicplans'">
            <a [routerLink]="getMemberRoute(MEMBER_ROUTE_SEGMENT.dynamicBenefit)">
              <div class="icon-dashboard">
                <i [class]='dashboardConfig.controlIcon'></i><span>{{dashboardConfig.controlLabel}}</span>
                <div class="shape"></div>
              </div>
            </a>
          </div>
          <div *ngIf="dashboardConfig.controlComponent==='dynamicplanssummary'">
            <a [routerLink]="getMemberRoute(MEMBER_ROUTE_SEGMENT.dynamicBenefitSummary)">
              <div class="icon-dashboard">
                <i [class]='dashboardConfig.controlIcon'></i><span>{{dashboardConfig.controlLabel}}</span>
                <div class="shape"></div>
              </div>
            </a>
          </div>
          <div *ngIf="dashboardConfig.controlComponent==='reinstate'">
            <a [routerLink]="getMemberRoute(MEMBER_ROUTE_SEGMENT.dynamicReinstate)">
              <div class="icon-dashboard">
                <i [class]='dashboardConfig.controlIcon'></i><span>{{dashboardConfig.controlLabel}}</span>
                <div class="shape"></div>
              </div>
            </a>
          </div>
          <div *ngIf="dashboardConfig.controlComponent==='self'">
            <a [routerLink]="getMemberRoute(dashboardConfig.controlName)">
              <div class="icon-dashboard">
                <i [class]='dashboardConfig.controlIcon'></i><span>{{dashboardConfig.controlLabel}}</span>
                <div class="shape"></div>
              </div>
            </a>
          </div>
          <div *ngIf="dashboardConfig.controlComponent==='path'">
            <a [routerLink]=dashboardConfig.controlName>
              <div class="icon-dashboard">
                <i [class]='dashboardConfig.controlIcon'></i><span>{{dashboardConfig.controlLabel}}</span>
                <div class="shape"></div>
              </div>
            </a>
          </div>
  

      </div>
      
    </div>
  </div>
</div>