import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';

// ex: 2018-11-10T15:00:00
const DATE_TIME_REGEXP = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/;
// ex: 2018-11-10T15:00:00Z
const DATE_TIMEZONE_UTC_REGEXP = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;
// ex: 2018-11-10T15:00:00+05:00
const DATE_TIMEZONE_REGEXP = /^(\d{4})(-(\d{2}))(-(\d{2}))(T(\d{2}):(\d{2})(:(\d{2}))(\.(\d+))??(([\+\-]{1}\d{2}:\d{2})|Z))$/;

@Injectable()
export class Iso8601DateInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.convertDates(event.body);
        }
      })
    );
  }

  private convertDates(object: any) {
    if (!object || _.isString(object)) {
      return;
    }

    if (object instanceof Array) {
      return _.each(object, item => this.deserializeDates(item));
    }

    if (object instanceof Object) {
      return this.deserializeDates(object);
    }
  }

  private deserializeDates(object: object) {
    if (!object || !(object instanceof Object)) {
      return;
    }

    const self = this;

    _.each(_.keys(object), key => {
      const value = object[key];

      if (typeof value === 'string' && this.regexTest(value)) {
        const date = new Date(value);
        // If the parsing above fails leave the data as is.
        if (isNaN(date.getTime())) {
          return;
        }
        object[key] = date;
      }

      self.deserializeDates(value);
    });
  }

  private regexTest(value: string): boolean {
    return (
      DATE_TIME_REGEXP.test(value) || // ex: 2018-11-10T15:00:00
      DATE_TIMEZONE_UTC_REGEXP.test(value) || // ex: 2018-11-10T15:00:00Z
      DATE_TIMEZONE_REGEXP.test(value) // ex: 2018-11-10T15:00:00+05:00
    );
  }
}
