<app-suspense-comment (AddSuspensecomment)="addSuspenseComment()" (moveSuspense)="moveSuspense($event)"
  [suspenseComments]='suspenseComments'></app-suspense-comment>
<br />
<div *ngIf='dates != null && dates.length >0'>
  Select Paydate
  <select (change)="onChange($event)" [(ngModel)]="selectedDate">
    <option></option>
    <option *ngFor="let opt of dates" [value]="opt">{{opt | date}}</option>
  </select>
  <br /> <br />
</div>
<ng-container *ngIf="member$ | async as member">
  <nz-table nzSize="small" [nzShowPagination]="true" #basicTable [nzData]="member.participantList"  [nzPageSize]="100">
    <thead>
      <tr>
        <th nzWidth="50px" nzShowExpand *ngIf="expandable"></th>
        <th nzWidth="248px">Member Name</th>
        <th nzWidth="248px">Member Id</th>
        <th style="text-align:right" nzWidth="200px">Suspense Balance</th>
        <th style="text-align:right">Outstanding</th>
        <th style="text-align:left;">
          <span style="text-align:left;">Total Amount: <b>{{totalBillAmount | currency}}</b>&nbsp;&nbsp;</span>
          <span *ngIf="!spiner" style="cursor: pointer" nz-popconfirm nzPopconfirmTitle="Are you sure you want to save ?"
          (nzOnConfirm)='onApplyAll()'><i class="fa fa-floppy-o PatchFont-toppadding"
          aria-hidden="true"></i>&nbsp;Save All </span>
          <span *ngIf="spiner" class="spinner-border" style="width: 1.3rem; height: 1.3rem;" role="status">
            <span class="sr-only">Loading...</span>
          </span>
        </th>
        
        
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-data2 [ngForOf]="basicTable.data">
        <tr>
          <td nzShowExpand *ngIf="expandable" [(nzExpand)]="mapOfExpandData[data2.Id]"
            (nzExpandChange)="expand(data2,$event)"></td>
          <td>{{ data2.lastName +" " + data2.firstName }}</td>
          <td>{{ data2.idNumber }}</td>
          <td style="text-align:right">{{data2.suspenseAmount | currency}}</td>
          <td style="text-align:right" nzWidth="30%">{{ data2.outstandingBillAmount | currency}}</td>
          <td nzWidth="30%" style="padding-right: 24px">
            <div style="float:right"><input type="checkbox" [(ngModel)]="data2.autoPost"> Auto Post&nbsp;&nbsp;
              <nz-input-number style="cursor: pointer" [nzFormatter]="formatterDollar" [nzParser]="parserDollar"
                [(ngModel)]="data2.amount" style="width:80px;"></nz-input-number>&nbsp;&nbsp;<i style="cursor: pointer"
                class="fa fa-floppy-o PatchFont-toppadding" aria-hidden="true" nz-popconfirm
                nzPopconfirmTitle="Are you sure you want to save  ?" (nzOnConfirm)=onApply(data2)></i>&nbsp;&nbsp;<i
                class="fa fa-arrow-up PatchFont-toppadding" aria-hidden="true" nz-popconfirm
                nzPopconfirmTitle="Are you sure you want to move this up?" (nzOnConfirm)="onMoveUp(data2)" *ngIf='data2.suspenseAmount!=0'></i>
            </div>
          </td>
        </tr>
        <tr [nzExpand]="mapOfExpandData[data2.Id] && expandable">
          <td></td>
          <td colspan="4">
            <div>
              <app-suspense-member (refresh)="onRefresh($event)" [entityClientId]='entityClientId' [entityId]='entityId'
                [clientId]='clientId' [payDate]='selectedDate' [memberId]="data2.participantGuid"></app-suspense-member>
            </div>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </nz-table>
</ng-container>