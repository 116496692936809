import { Component } from '@angular/core';
import * as _ from 'lodash';
import { LoginComponent } from '@core/login/login.component';

@Component({
    selector: 'user-login',
    templateUrl: './loginuser.component.html',
    styleUrls: ['./loginuser.component.scss']
     
  })
  export class LoginUserComponent extends LoginComponent   {

    config = window['___alliant-config-eniac-APP___']
    
    logintype = this.config.logintype
    welcomeText = this.config.welcomeText
    phoneNum = this.config.phoneNum
    primaryColor = this.config.primaryColor
    logoImage = this.config.logoImage
    bgImage = this.config.bgImage
    isPrivacyLink = this.config.isPrivacyLink
    isTermsLink = this.config.isTermsLink
    registrationParam = this.config.registrationParam
  
  }