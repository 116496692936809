import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class EnrollmentService {

  constructor() { }

  LoadingComplete(bandObj, initFormValue, id) {
    initFormValue[id] = {};
    const policies = bandObj.benefitEntityPolicies;
    policies.forEach(element => {
      initFormValue[id][element.benefitEntityPolicyId] = {};
      const tt = initFormValue[id][element.benefitEntityPolicyId];
      tt.benefitEntityId = element.benefitEntityId;
      tt.benefitEntityPolicyDescription = element.benefitEntityPolicyDescription;
      tt.effactiveDate = element.effectiveDate;
      tt.dropEffectiveDate = element.dropEffectiveDate;
      tt.oldDropEffectiveDate = element.oldDropEffectiveDate;
      tt.isEISEnabled = element.isEISEnabled;

      const currentEnrollment = element.currentEnrollment;
      // tt.coverages = currentEnrollment.coverages;
      if (currentEnrollment != null) {
        tt.locationCode = currentEnrollment.locationCode;
        tt.qualificationCode = currentEnrollment.qualificationCode;

        if (currentEnrollment.coverages != null && currentEnrollment.coverages.length > 0) {
          tt.coverages = {
            coverage: currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageId,
            coverageValue: currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageValue,
            packageCode: currentEnrollment.coverages[0].packageCode,
            classCode: currentEnrollment.coverages[0].classCode
          };
        }
        if (element.enrollmentUITierDisplay === 'HideTier') {
          tt.tiercoverage = {};
          tt.tiercoverage.benefitEntityPolicyDetailId = currentEnrollment.benefitEntityPolicyDetailId;
          if (currentEnrollment.eisDetails != null) {
            tt.tiercoverage.eisDetail = {};
            // tslint:disable-next-line:max-line-length
            tt.tiercoverage.eisDetail.integration = currentEnrollment.eisDetails.integration != null ? currentEnrollment.eisDetails.integration.eisRateTableId : '';
            // tslint:disable-next-line:max-line-length
            tt.tiercoverage.eisDetail.minimumBenefit = currentEnrollment.eisDetails.minimumBenefit != null ? currentEnrollment.eisDetails.minimumBenefit.eisRateTableId : '';
            // tslint:disable-next-line:max-line-length
            tt.tiercoverage.eisDetail.cola = currentEnrollment.eisDetails.cola != null ? currentEnrollment.eisDetails.cola.eisRateTableId : '';
            // tslint:disable-next-line:max-line-length
            tt.tiercoverage.eisDetail.survivor = currentEnrollment.eisDetails.survivor != null ? currentEnrollment.eisDetails.survivor.eisRateTableId : '';
            // tt.tiercoverage.eisDetails
          }
          tt.tiercoverage.eisDetails = currentEnrollment.eisDetails;
          tt.tiercoverage.enrolled = true;
          tt.tiercoverage.deps = currentEnrollment.dependents;
          tt.tiercoverage.beneficiaries = currentEnrollment.benefitBeneficiaries;
          currentEnrollment.coverages.forEach(x => {
            /*  x.coverageValue=x.benefitEntityPolicyDetailCoverageValue;
             x.coverage=x.benefitEntityPolicyDetailCoverageId;
             x.coverageRelationShipCodes=x.coverageRelationShipCodes[0]; */
            tt.tiercoverage['coverages' + x.benefitEntityPolicyDetailCoverageId] = x;
          });

          return;
        }
        tt.tiers = {};
        tt.tiers.tier = currentEnrollment.benefitEntityPolicyDetailId;
        tt.tiers.enrolled = currentEnrollment.dependents;
        tt.tiers.beneficiaries = currentEnrollment.benefitBeneficiaries;
        // tier coverage
        const tiers = element.tiers;
        const f = tiers.find(t => t.benefitEntityPolicyDetailId === tt.tiers.tier);
        if (f != null) {
          if (f.coverages && f.uiControlType === 'object') {
            tt.tiers.tiercoverage = {};
            f.coverages.forEach(e1 => {
              tt.tiers.tiercoverage['coverages' + e1.benefitEntityPolicyDetailCoverageId] = {
                coverage: e1.benefitEntityPolicyDetailCoverageId
              };
              // tslint:disable-next-line:max-line-length
              if (e1.coverageRelationShipCodes != null && e1.coverageRelationShipCodes.length > 0 && e1.coverageRelationShipCodes[0] === 'E') {
                if (currentEnrollment != null && currentEnrollment.coverages != null && currentEnrollment.coverages.length > 0) {
                  // tslint:disable-next-line:max-line-length
                  tt.tiers.tiercoverage['coverages' + e1.benefitEntityPolicyDetailCoverageId].coverage = e1.benefitEntityPolicyDetailCoverageId;
                  tt.tiers.tiercoverage['coverages' + e1.benefitEntityPolicyDetailCoverageId].coverageRelationShipCodes = 'E';
                  // tslint:disable-next-line:max-line-length
                  tt.tiers.tiercoverage['coverages' + e1.benefitEntityPolicyDetailCoverageId].coverageValue = currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageValue;
                }
                // tt.tiers.tiercoverage[e1.benefitEntityPolicyDetailCoverageId].coverage
              } else if (e1.coverageRelationShipCodes != null && e1.coverageRelationShipCodes.length > 0) {
                if (currentEnrollment != null && currentEnrollment.dependents != null && currentEnrollment.dependents.length > 0) {
                  currentEnrollment.dependents.forEach(dep => {
                    if (dep.coverages != null && dep.coverages.length > 0) {
                      if (dep.coverages[0].benefitEntityPolicyDetailCoverageId === e1.benefitEntityPolicyDetailCoverageId) {
                        // tslint:disable-next-line:max-line-length
                        tt.tiers.tiercoverage['coverages' + e1.benefitEntityPolicyDetailCoverageId].coverage = dep.coverages[0].benefitEntityPolicyDetailCoverageId;
                        // tslint:disable-next-line:max-line-length
                        tt.tiers.tiercoverage['coverages' + e1.benefitEntityPolicyDetailCoverageId].coverageRelationShipCodes = e1.coverageRelationShipCodes[0];
                        // tslint:disable-next-line:max-line-length
                        tt.tiers.tiercoverage['coverages' + e1.benefitEntityPolicyDetailCoverageId].coverageValue = dep.coverages[0].benefitEntityPolicyDetailCoverageValue;
                      }
                    }
                  });
                }
              }
            });
          }
        }
      }

    });
  }
  comparelist(l1, l2) {
    if (l2 == null && l1 != null) {
      if (l1.length == 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (l2 && l1) {
        if (l1.length == l2.length) {
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  }
  isChanged(oldForm, curtform) {
    const tt = oldForm;
    if (curtform.selectOriffDate == true) {
      return true;
    }
    if (curtform.locationCode != '' && oldForm.locationCode != null && curtform.locationCode != oldForm.locationCode) {
      return true;
    }
    if (Date.parse(tt.effactiveDate) !== Date.parse(curtform.effactiveDate)) {
      if (curtform.effactiveDate != null) {
        return true;
      }

    }
    if (Date.parse(tt.dropEffectiveDate) !== Date.parse(curtform.dropEffectiveDate)) {
      if (curtform.dropEffectiveDate != null) {
        return true;
      }
    } else {
      if (tt.oldDropEffectiveDate != null) {
        return true;
      }

    }
    if (tt.coverages != null && curtform.coverages == null) {
      return true;
    }
    // tslint:disable-next-line:max-line-length
    if (tt.coverages != null && curtform.coverages != null && (tt.coverages.coverage !== curtform.coverages.coverage || tt.coverages.coverageValue != curtform.coverages.coverageValue)) {
      return true;
    }
    if (tt.coverages != null && tt.coverages.coverage === curtform.coverages.coverage) {
      // tslint:disable-next-line:max-line-length
      if ((curtform.coverages.packageCode != null && tt.coverages.packageCode !== curtform.coverages.packageCode) ||
        (curtform.coverages.classCode != null && tt.coverages.classCode !== curtform.coverages.classCode)) {
        return true;
      }

    }
    if (tt.coverages == null && curtform.coverages != null && curtform.coverages.coverage != null && curtform.tiercoverage == null) {
      return true;
    }

    if (tt.tiercoverage == null && curtform.tiercoverage != null && curtform.tiercoverage.enrolled === true) {
      return true;
    }
    if (tt.tiercoverage != null && curtform.tiercoverage != null && curtform.tiercoverage.enrolled !== tt.tiercoverage.enrolled) {
      return true;
    }

    if (tt.qualificationCode != curtform.qualificationCode) {
      return true;
    }
    if (tt.tiercoverage != null && curtform.tiercoverage != null) {
      if (curtform.tiercoverage.deps != null && tt.tiercoverage.deps == null || tt.tiercoverage.deps != null && curtform.tiercoverage.deps != null && tt.tiercoverage.deps.length != curtform.tiercoverage.deps.length) {
        return true;
      }
    }
    if (tt.tiercoverage != null && curtform.tiercoverage != null && curtform.tiercoverage.enrolled === tt.tiercoverage.enrolled
      && curtform.tiercoverage.enrolled === true) {
      for (const propertytiercoverage in curtform.tiercoverage) {
        if (propertytiercoverage.startsWith('coverages')) {
          if (tt.tiercoverage[propertytiercoverage] == null) {
            if (curtform.tiercoverage[propertytiercoverage].packageCode != null) {
              return true;
            }
          } else {
            if (tt.tiercoverage[propertytiercoverage].packageCode !== curtform.tiercoverage[propertytiercoverage].packageCode) {
              return true;
            }
          }

        }
      }
    }
    if (tt.tiercoverage != null && curtform.tiercoverage != null && (this.comparelist(curtform.tiercoverage.beneficiaries, tt.tiercoverage.beneficiaries) == false)) {
      return true;
    }
    if (tt.tiercoverage == null && curtform.tiercoverage != null && curtform.tiercoverage.beneficiaries != null && curtform.tiercoverage.beneficiaries.length > 0) {
      return true;
    }
    if (curtform.isEISEnabled === true) {
      if (tt.tiercoverage != null && tt.tiercoverage.eisDetail == null && curtform.tiercoverage.eisDetail != null) {
        return true;
      }
      if (tt.tiercoverage != null && tt.tiercoverage.eisDetail != null && curtform.tiercoverage.eisDetail != null) {
        if (tt.tiercoverage.eisDetail.minimumBenefit !== curtform.tiercoverage.eisDetail.minimumBenefit) {
          return true;
        }
        if (tt.tiercoverage.eisDetail.integration !== curtform.tiercoverage.eisDetail.integration) {
          return true;
        }
        if (tt.tiercoverage.eisDetail.survivor !== curtform.tiercoverage.eisDetail.survivor) {
          return true;
        }
        if (tt.tiercoverage.eisDetail.cola !== curtform.tiercoverage.eisDetail.cola) {
          return true;
        }
      }
    }
    let findall;
    if (tt.tiers == null && curtform.tiers != null && curtform.tiers.tier != null && curtform.tiers.tier !== '') {
      return true;
    }
    if (tt.tiers != null && curtform.tiers != null) {
      // if (tt.tiers.custrate != curtform.tiers.custrate) {
      //   return true;
      // }
      if (curtform.tiers.ratePR != '') {
        return true;
      }
      if (curtform.tiers.rateER != '') {
        return true;
      }
      if (tt.tiers.tier !== curtform.tiers.tier) {
        return true;
      }
      // check tiercoverage
      if (tt.tiers.tiercoverage != null) {
        // tslint:disable-next-line:forin
        for (const property in tt.tiers.tiercoverage) {
          if (property.startsWith('coverages')) {
            const coverage = tt.tiers.tiercoverage[property].coverage;
            if (coverage !== curtform.tiers.tiercoverage[property].coverage) {
              return true;
            }
            const coverageValue = tt.tiers.tiercoverage[property].coverageValue;
            if (coverageValue != curtform.tiers.tiercoverage[property].coverageValue) {
              return true;
            }
          }

        }
      }
      if (tt.tiers.tiercoverage == null) {
        if (curtform.tiers.tiercoverage != null) {
          return true;
        }
      }
      if (tt.tiers.enrolled.length !== curtform.tiers.enrolled.length) {
        return true;
      }
      tt.tiers.enrolled.forEach(element => {
        const f = curtform.tiers.enrolled.find(en => en.dependentId === element.dependentId);
        if (f == null) {
          findall = false;
        }

      });
      if (tt.tiers.beneficiaries.length !== curtform.tiers.beneficiaries.length) {
        return true;
      }
      tt.tiers.beneficiaries.forEach(element => {
        const f = curtform.tiers.beneficiaries.find(en => en.beneficiaryId === element.beneficiaryId && en.percentage === element.split);
        if (f == null) {
          findall = false;
        }

      });
      curtform.tiers.beneficiaries.forEach(element => {
        const f = tt.tiers.beneficiaries.find(en => en.beneficiaryId === element.beneficiaryId && en.split === element.percentage);
        if (f == null) {
          findall = false;
        }

      });
    }
    if (findall === false) {
      return true;
    }

    if (tt.tiercoverage != null && curtform.tiercoverage != null) {
      // if (tt.tiers.custrate != curtform.tiers.custrate) {
      //   return true;
      // }
      // if ( curtform.tiers.ratePR!='') {
      //   return true;
      // }
      // if ( curtform.tiers.rateER!='') {
      //   return true;
      // }
      // if (tt.tiers.tier !== curtform.tiers.tier) {
      //   return true;
      // }
      // check tiercoverage
      // if (tt.tiers.tiercoverage != null) {
      //   // tslint:disable-next-line:forin
      //   for (const property in tt.tiers.tiercoverage) {
      //     if ( property.startsWith('coverages')) {
      //       const coverage = tt.tiers.tiercoverage[ property].coverage;
      //       if (coverage !== curtform.tiers.tiercoverage[ property].coverage) {
      //         return true;
      //       }
      //       const coverageValue = tt.tiers.tiercoverage[ property].coverageValue;
      //       if (coverageValue != curtform.tiers.tiercoverage[ property].coverageValue) {
      //         return true;
      //       }
      //     }

      //   }
      // }
      // if (tt.tiers.tiercoverage == null) {
      //   if (curtform.tiers.tiercoverage != null) {
      //     return true;
      //   }
      // }
      // if (tt.tiers.enrolled.length !== curtform.tiers.enrolled.length) {
      //   return true;
      // }
      // tt.tiers.enrolled.forEach(element => {
      //   const f = curtform.tiers.enrolled.find(en => en.dependentId === element.dependentId);
      //   if (f == null) {
      //     findall = false;
      //   }

      // });
      if (tt.tiercoverage.beneficiaries && curtform.tiercoverage.beneficiaries) {
        if (tt.tiercoverage.beneficiaries.length !== curtform.tiercoverage.beneficiaries.length) {
          return true;
        }

        tt.tiercoverage.beneficiaries.forEach(element => {
          const f = curtform.tiercoverage.beneficiaries.find(en => en.beneficiaryId === element.beneficiaryId && en.percentage === element.split);
          if (f == null) {
            findall = false;
          }
        })

        curtform.tiercoverage.beneficiaries.forEach(element => {
          const f = tt.tiercoverage.beneficiaries.find(en => en.beneficiaryId === element.beneficiaryId && en.split === element.percentage);
          if (f == null) {
            findall = false;
          }
        })
      }
    }
    if (findall === false) {
      return true;
    }
    return false;
  }
  confirm(cartItems, enrollmententity, initFormValue) {
    for (const enrollment in enrollmententity) {
      if (enrollmententity.hasOwnProperty(enrollment)) {
        for (const property in enrollmententity[enrollment]) {
          if (enrollmententity[enrollment].hasOwnProperty(property)) {
            const policyForm = enrollmententity[enrollment][property];
            const tt = initFormValue[enrollment][property];
            const isChanged = this.isChanged(tt, policyForm);
            if (isChanged === false) {
              continue;
            }
            const isEISEnabled = policyForm.isEISEnabled;
            let benefitEntityPolicyDetailId: any = null;
            let EISDetails;
            const childCov: any = {};
            const spauseCov: any = {};
            let Coverages: any = [];
            let dropCode;
            let IsEmployeeOptedOut = false;
            let IsUseMemberTableRate = false;
            let CustomRate = '';
            let CustomRateER = '';
            let CustomRatePR = '';
            const Dependents: any = [];
            if (isEISEnabled) {
              // EISDetail = policyForm.tiercoverage.eisDetail ;
              EISDetails = {
                // tslint:disable-next-line:max-line-length
                'MinimumBenefit': [{ eisRateTableId: policyForm.tiercoverage.eisDetail.minimumBenefit }],
                'Integration': [{ eisRateTableId: policyForm.tiercoverage.eisDetail.integration }],
                'survivor': [{ eisRateTableId: policyForm.tiercoverage.eisDetail.survivor }],
                'cola': [{ eisRateTableId: policyForm.tiercoverage.eisDetail.cola }],
              };
              benefitEntityPolicyDetailId = policyForm.tiercoverage.benefitEntityPolicyDetailId;
              const coverage: any = {};
              coverage.benefitEntityPolicyDetailCoverageId = enrollmententity[enrollment][property].coverages.coverage;
              coverage.benefitEntityPolicyDetailCoverageValue = enrollmententity[enrollment][property].coverages.coverageValue;
              coverage.packageCode = enrollmententity[enrollment][property].coverages.packageCode;
              coverage.classCode = enrollmententity[enrollment][property].coverages.classCode;
              coverage.CoverageNumber = enrollmententity[enrollment][property].coverages.CoverageNumber;
              if (coverage.benefitEntityPolicyDetailCoverageId != null) {
                Coverages.push(coverage);
              }
            } else {
              const coverage: any = {
                benefitEntityPolicyDetailCoverageId: '',
                benefitEntityPolicyDetailCoverageValue: ''
              };
              coverage.benefitEntityPolicyDetailCoverageId = enrollmententity[enrollment][property].coverages.coverage;
              coverage.benefitEntityPolicyDetailCoverageValue = enrollmententity[enrollment][property].coverages.coverageValue;
              coverage.packageCode = enrollmententity[enrollment][property].coverages.packageCode;
              coverage.classCode = enrollmententity[enrollment][property].coverages.classCode;
              if (coverage.benefitEntityPolicyDetailCoverageId != null) {
                Coverages.push(coverage);
              }
              if (coverage.benefitEntityPolicyDetailCoverageId == null && coverage.benefitEntityPolicyDetailCoverageValue != null) {
                if (policyForm.tiercoverage != null) {
                  Coverages.push(coverage);
                }
              }
              const enrolledg = policyForm.tiers != null ? policyForm.tiers.enrolled : null;
              if (policyForm.tiercoverage != null && policyForm.tiercoverage.benefitEntityPolicyDetailId != null) {
                benefitEntityPolicyDetailId = policyForm.tiercoverage.benefitEntityPolicyDetailId;
                if (policyForm.tiercoverage.enrolled === false) {
                  IsEmployeeOptedOut = true;
                }
                for (const propertytiercoverage in policyForm.tiercoverage) {
                  if (propertytiercoverage.startsWith('coverages')) {
                    const val = policyForm.tiercoverage[propertytiercoverage].coverageValue;
                    const id = policyForm.tiercoverage[propertytiercoverage].coverage;
                    const coverageRelationShipCodes = policyForm.tiercoverage[propertytiercoverage].coverageRelationShipCodes;
                    if (coverageRelationShipCodes === 'E') {
                      Coverages = [];
                      Coverages.push({
                        benefitEntityPolicyDetailCoverageValue: val,
                        benefitEntityPolicyDetailCoverageId: id,
                        packageCode: policyForm.tiercoverage[propertytiercoverage].packageCode,
                        classCode: policyForm.tiercoverage[propertytiercoverage].classCode,
                      });
                    }
                    if (coverageRelationShipCodes !== 'E') {
                      const depObj: any = {
                        dependentId: null,
                        Coverages: [
                          {
                            benefitEntityPolicyDetailCoverageId: id,
                            benefitEntityPolicyDetailCoverageValue: val,
                            packageCode: policyForm.tiercoverage[propertytiercoverage].packageCode,
                            classCode: policyForm.tiercoverage[propertytiercoverage].classCode,
                          }
                        ]
                      };
                      if (enrolledg == null) {
                        Dependents.push(depObj);
                      }

                    }
                  }
                }
              }
              if (policyForm.tiers != null && policyForm.tiers.tiercoverage != null) {

                for (const propertytiercoverage in policyForm.tiers.tiercoverage) {
                  if (propertytiercoverage.startsWith('coverages')) {
                    // const  tt = enrollmententity[enrollment][property].tiers.tiercoverage;
                    const val = policyForm.tiers.tiercoverage[propertytiercoverage].coverageValue;
                    const code = policyForm.tiers.tiercoverage[propertytiercoverage].coverageRelationShipCodes;
                    const id = policyForm.tiers.tiercoverage[propertytiercoverage].coverage;
                    if (val != null && val !== '' && code === 'E') {
                      Coverages = [];
                      Coverages.push({
                        benefitEntityPolicyDetailCoverageValue: val,
                        benefitEntityPolicyDetailCoverageId: id
                      });
                    }
                    if (code === 'C') {

                      childCov.id = id;
                      childCov.val = val;
                      if (childCov.id != null && childCov.val != null) {
                        const depObj: any = {};
                        depObj.benefitEntityPolicyDetailId = policyForm.tiers.tier;
                        depObj.Coverages = [];
                        depObj.Coverages.push({
                          benefitEntityPolicyDetailCoverageId: childCov.id,
                          benefitEntityPolicyDetailCoverageValue: childCov.val
                        });
                        if (enrolledg == null) {
                          Dependents.push(depObj);
                        }
                        //  Dependents.push(depObj);
                      }
                    }
                    if (code === 'S') {
                      spauseCov.id = id;
                      spauseCov.val = val;
                      if (spauseCov.id != null && spauseCov.val != null) {
                        const depObj: any = {};
                        depObj.benefitEntityPolicyDetailId = policyForm.tiers.tier;
                        depObj.Coverages = [];
                        depObj.Coverages.push({
                          benefitEntityPolicyDetailCoverageId: spauseCov.id,
                          benefitEntityPolicyDetailCoverageValue: spauseCov.val
                        });
                        if (enrolledg == null) {
                          Dependents.push(depObj);
                        }

                      }
                    }

                  }
                }

              }
            }

            const BenefitBeneficiaries: any = [];
            let mode = 'add';
            const ttt = initFormValue[enrollment][property];
            const oldTier = (ttt.tiers == null) ? null : ttt.tiers.tier;
            const oldTierCoverage = (ttt.tiercoverage == null) ? null : ttt.tiercoverage;

            const policyObj = enrollmententity[enrollment][property];
            // tslint:disable-next-line:max-line-length
            if (policyObj.tiers != null && policyObj.tiers.tier != null || (enrollmententity[enrollment][property].tiers != null && enrollmententity[enrollment][property].tiers.tier === '')) {
              benefitEntityPolicyDetailId = enrollmententity[enrollment][property].tiers.tier;
              const enrolled = policyForm.tiers.enrolled;
              if (enrolled != null) {
                enrolled.forEach(element => {
                  const depObj: any = {};
                  depObj.dependentId = element.dependentId;
                  depObj.benefitEntityPolicyDetailId = policyForm.tiers.tier;
                  depObj.Coverages = [];
                  if (element.relationShip === 'C') {
                    if (childCov.id != null) {
                      depObj.Coverages.push({
                        benefitEntityPolicyDetailCoverageId: childCov.id,
                        benefitEntityPolicyDetailCoverageValue: childCov.val
                      });
                    }

                  }
                  if (element.relationShip === 'S') {
                    if (spauseCov.id != null) {
                      depObj.Coverages.push({
                        benefitEntityPolicyDetailCoverageId: spauseCov.id,
                        benefitEntityPolicyDetailCoverageValue: spauseCov.val
                      });
                    }

                  }
                  Dependents.push(depObj);
                });
              }
              const beneficiaries = policyForm.tiers.beneficiaries;

              if (beneficiaries != null) {
                beneficiaries.forEach(element => {
                  const bObj: any = {};
                  bObj.BeneficiaryId = element.beneficiaryId;
                  bObj.Split = element.percentage;
                  bObj.beneficiaryTypeId = element.beneficiaryTypeId;
                  BenefitBeneficiaries.push(bObj);
                });

              }
              if (benefitEntityPolicyDetailId === '') {
                IsEmployeeOptedOut = true;
              }
              const rate = enrollmententity[enrollment][property].tiers.rate;
              const custrate = enrollmententity[enrollment][property].tiers.custrate;
              const ratePR = enrollmententity[enrollment][property].tiers.ratePR;
              const rateER = enrollmententity[enrollment][property].tiers.rateER;
              // if (rate !== custrate && custrate != null) {
              //   IsUseMemberTableRate = true;
              //   CustomRate = custrate;
              // }
              if (ratePR !== '') {
                IsUseMemberTableRate = true;
                CustomRatePR = ratePR;
              }
              if (ratePR !== '') {
                IsUseMemberTableRate = true;
                CustomRateER = rateER;
              }
              const curtier = enrollmententity[enrollment][property].tiers.tier;
              if (oldTier != null && oldTier !== '' && curtier !== '') {
                mode = 'change';
              }
              if (oldTier !== '' && curtier === '') {
                mode = 'drop';
                dropCode = enrollmententity[enrollment][property].tiers.optReason;
              }

            }
            if (policyObj.tiercoverage != null) {
              const beneficiaries = policyForm.tiercoverage.beneficiaries;
              const deps = policyForm.tiercoverage.deps;
              if (beneficiaries != null) {
                beneficiaries.forEach(element => {
                  const bObj: any = {};
                  bObj.BeneficiaryId = element.beneficiaryId;
                  bObj.Split = element.percentage;
                  bObj.beneficiaryTypeId = element.beneficiaryTypeId;
                  BenefitBeneficiaries.push(bObj);
                });

              }
              if (deps != null) {
                deps.forEach(element => {
                  const depObj: any = {};
                  depObj.dependentId = element.dependentId;
                  depObj.benefitEntityPolicyDetailId = policyForm.tiercoverage.benefitEntityPolicyDetailId;

                  Dependents.push(depObj);
                });
              }
            }
            if (oldTierCoverage !== null && policyForm.tiercoverage != null && policyForm.tiercoverage.enrolled === false) {
              mode = 'drop';
              dropCode = policyForm.tiercoverage.optReason;
            }
            if (oldTierCoverage !== null && policyForm.tiercoverage != null && policyForm.tiercoverage.enrolled === true) {
              mode = 'change';
              // dropCode = enrollmententity[enrollment][property].tiers.optReason;
            }
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            const yyyy = today.getFullYear();
            const appDate = enrollmententity[enrollment][property].appDate;  // mm + '/' + dd + '/' + yyyy;
            const campaignCode = enrollmententity[enrollment][property].campaignCode;
            const healthPlanSelectedOption = enrollmententity[enrollment][property].healthPlanOptionVal;
            const policy = {
              benefitEntityPolicyId: property,
              oldBenefitEntityPolicyDetailId: oldTier,
              benefitEntityPolicyDetailId: benefitEntityPolicyDetailId,
              healthPlanSelectedOption,
              Coverages,
              'UIEnrollmentActionCode': mode,
              'IsEmployeeOptedOut': IsEmployeeOptedOut,
              IsUseMemberTableRate,
              //CustomRate,
              CustomRatePR,
              CustomRateER,
              appDate,
              Dependents,
              BenefitBeneficiaries,
              EISDetails,
              'remarkTypeId': dropCode,
              'remarks': enrollmententity[enrollment][property].remark,
              'LastAppId': enrollmententity[enrollment][property].underWritingVal,
              'enrollmentEffectiveDate': enrollmententity[enrollment][property].effactiveDate,
              'originalEnrollmentDateTime': enrollmententity[enrollment][property].oriEffDate,
              'originalEnrollmentEffectiveDate': enrollmententity[enrollment][property].oriEffDate,
              'selectOriginalEffectiveDate': enrollmententity[enrollment][property].selectOriffDate,
              'dropEffectiveDate': enrollmententity[enrollment][property].dropEffectiveDate,
              'areaCode': enrollmententity[enrollment][property].areaCode,
              'locationCode': enrollmententity[enrollment][property].locationCode,
              'planCode': enrollmententity[enrollment][property].planCode,
              'CovCode': enrollmententity[enrollment][property].CovCode,
              'qualificationCode': enrollmententity[enrollment][property].qualificationCode,
              dropCode,
              campaignCode
            };
            if (benefitEntityPolicyDetailId || benefitEntityPolicyDetailId === '') {
              cartItems.push(policy);
            }
          }
        }
      }

    }
  }
}
