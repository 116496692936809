<div class="col-12 no-gutters" style="padding:0px;">
  <div class="row page-header">
    <div class="col-12">
      <h4 class="page-header__client-name">

        <ng-container>
          <span> {{entityName}}&nbsp;/&nbsp;{{entityClientName}}</span><span
            *ngIf="clientNumber != null && clientNumber != ''">({{clientNumber}})</span><span
            *ngIf="entityClientNumber != null && entityClientNumber != ''"> / {{entityClientNumber}}</span>
        </ng-container>
      </h4>
    </div>
  </div>
  <h5>{{memberName}}</h5>
  <!-- Group: <span style="color:#005aff;font-weight: 400">{{profile?.companyGroup}}</span> -->
  <span *ngIf="member?.entityId=='3b7805a6-10fa-48b5-b0ee-ea5365258341' && profile?.externalId != null">Cert Number :
    <span style="color:#005aff;font-weight: 400">{{profile?.externalId}}</span>&nbsp;</span>
    <span
    *ngIf="(member?.entityId == '9808f856-f047-4843-bec8-277e414fc649') && profile?.idNumber != null">CERT ID: <span style="color:#005aff;font-weight: 400">{{profile?.dmcEmployeeId}}</span>&nbsp;</span>
  <span
    *ngIf="(member?.entityId != '3b7805a6-10fa-48b5-b0ee-ea5365258341' && member?.entityId !='4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d' && member?.entityId!='a11ee4de-9b44-4bcf-a01e-04e1a2fc6028' && member?.entityId!='9808f856-f047-4843-bec8-277e414fc649') && profile?.externalId != null">Member Id: <span style="color:#005aff;font-weight: 400">{{profile?.externalId}}</span>&nbsp;</span>
  <span
    *ngIf="(member?.entityId=='4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d' || member?.entityId=='a11ee4de-9b44-4bcf-a01e-04e1a2fc6028') && profile?.idNumber != null">Member Id: <span style="color:#005aff;font-weight: 400">{{profile?.idNumber}}</span>&nbsp;</span>
  <span *ngIf="profile?.employmentStatus != null">Status: <span
      style="color:#005aff;font-weight: 400">{{profile?.employmentStatus}}</span>&nbsp;</span>
  <span *ngIf="member?.entityId=='68bc62e6-dd96-4428-91e8-efe285bd23a0'">
    <span *ngIf="profile?.companyGroup != null">Member Source: <span
        style="color:#005aff;font-weight: 400">{{profile?.companyGroup}}</span>&nbsp;</span>
    <span *ngIf="profile?.isOrphaned === '' || profile?.isOrphaned === null ||  profile?.isOrphaned == false">Orphaned:
      <span style="color:#005aff;font-weight: 400">No</span>&nbsp;</span>
    <span *ngIf="profile?.isOrphaned === true">Orphaned: <span
        style="color:#005aff;font-weight: 400">Yes</span>&nbsp;</span>
  </span>
  <!-- <span *ngIf="profile?.ssNumber != null">SS Number: <span style="color:#005aff;font-weight: 400" title="{{profile?.ssNumber}}" >*****{{profile?.ssNumber.substring(5, 10)}} </span>&nbsp;</span> -->
  <span *ngIf="profile?.ssNumber != null">SS Number: <span [appssn]='profile?.ssNumber'
      style="color:#005aff;font-weight: 400">XXX-XX-XXXX</span>&nbsp;</span>
  <span *ngIf="profile?.birthDate != null">Date of Birth: <span
      style="color:#005aff;font-weight: 400">{{profile?.birthDate | date:'longDate'}}</span>&nbsp;</span>
  <span *ngIf="profile?.payFrequency != null">Pay Frequency: <span
      style="color:#005aff;font-weight: 400">{{profile?.payFrequency}}<span *ngIf="profile?.payFrequency != null"><span *ngIf="profile?.payFrequencyDescription != null">&nbsp;-&nbsp;</span>{{profile?.payFrequencyDescription}}</span></span>&nbsp;</span>
  <span *ngIf="profile?.email != null">Email: <span
      style="color:#005aff;font-weight: 400">{{profile?.email}}</span>&nbsp;</span>
  <span *ngIf="profile?.phone != null">Phone: <span
      style="color:#005aff;font-weight: 400">{{profile?.phone}}</span>&nbsp;</span>
  <span *ngIf="profile?.address1 != null">Address: <span style="color:#005aff;font-weight: 400">{{profile?.address1}}
      {{profile?.address2}}, {{profile?.city}}, {{profile?.state}}, {{profile?.zip}}</span>&nbsp;</span>
  <span *ngIf="member?.entityId=='68bc62e6-dd96-4428-91e8-efe285bd23a0'">
    <span *ngIf="profile?.hasHealthPlan != null &&  profile?.hasHealthPlan == true">Health Plan: <span
        style="color:#005aff;font-weight: 400">Yes</span>&nbsp;</span>
    <span *ngIf="profile?.hasHealthPlan == null || profile?.hasHealthPlan == false">Health Plan: <span
        style="color:#005aff;font-weight: 400">No</span>&nbsp;</span>
  </span>
  <div *ngIf="member?.entityId=='d6a2ff7e-bba0-48fa-9396-a6b202631669' || member?.entityId=='a11ee4de-9b44-4bcf-a01e-04e1a2fc6028'">
    <span *ngIf="profile?.cobraStatus == 'A'" style="color: green;font-size: 20px;font-weight: 400">Member is enrolled in benefits</span>
    <span *ngIf="profile?.cobraStatus == 'T'" style="color: red;font-size: 20px;font-weight: 400">All benefits are terminated</span>
    <span *ngIf="profile?.cobraStatus == 'P'" style="color: rgb(226, 121, 0);font-size: 20px;font-weight: 400">Member has pending benefits only</span>
  </div>
</div>