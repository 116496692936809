import * as faker from 'faker';
import * as _ from 'lodash';
import {
  MockRequestHandler,
  MockRequestHandlerParams
} from '@mock/mock-data/shared/mock-handler.interface';
import { RelationshipTypeDTO } from '@models/shared/relationship-type.dto';
import { WELL_KNOWN_DEPENDENT } from '@mock/mock-data/shared/common.mock';

export class RelationshipTypeLookupMockHandler extends MockRequestHandler<
  RelationshipTypeDTO[]
> {
  handleRequest(params: MockRequestHandlerParams): RelationshipTypeDTO[] {
    return _.map(
      _.keys(WELL_KNOWN_DEPENDENT),
      key => WELL_KNOWN_DEPENDENT[key] as RelationshipTypeDTO
    );
  }
}
