<div style="padding-left: 15px;padding-top: 15px;">
    <table  class="table table-sm table-hover" >
        <tr  *ngFor="let data1 of config | keyvalue">
            <td>
                <span class="key">{{data1.key}}: </span>
            </td>
            <td>
                <span class="value">{{data1.value}}</span>
            </td>
        </tr>
    </table>
    
</div>
