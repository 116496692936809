<div [formGroup]='eisDetailForm' class="app-form-group-wrapper" style="margin-top: 0px">
  <h6>Make EIS selection for the plan</h6>
  <span *ngFor="let item of eisDetailsArr;" style="display: inline">
    <span *ngIf='item.arr.length>0' style="display: inline">
      <div style="width: 20%; display: inline-block;padding: 0px;margin-right: 15px ">
        {{item.name}}
        <select class="form-control input-sm col-12" style="margin-bottom: 15px" [formControlName]="item.name"
          (change)="onChange($event,item.name)">
          <option></option>
          <option *ngFor="let opt of  item.arr " [value]="opt.eisRateTableId">{{opt.configurationName}}</option>
        </select>
      </div>
    </span>
  </span>
</div>