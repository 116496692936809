<div class="row">
  <!--  <div class="col-12">
          <app-dynamic-fields #fields 
          [cancelButton]='cancelButton' [deleteButton]='deleteButton' 
          [formConfigs]='formConfigs' [formValue]="formValue" (cancle)="onCancel()"
          [saveButton]='saveButton'
           (save)="onSearch($event)"></app-dynamic-fields>
        </div> -->

  <button nz-button nzType="default" (click)="onSearch()"><i nz-icon [nzType]="'search'"></i>Search</button>
</div>

<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <app-form-group-wrapper>

          <nz-table #basicTable [nzData]="rows" (nzCurrentPageDataChange)="currentPageDataChange($event)"
            class="table table-hover" style="margin-top: 10px" *ngIf='gridConfigs!=null'>
            <thead (nzSortChange)="sort($event)" nzSingleSort class="thead-dark">
              <tr>
                <th nzShowCheckbox nzShowRowSelection [(nzChecked)]="isAllDisplayDataChecked"
                  [nzIndeterminate]="isIndeterminate" (nzCheckedChange)="checkAll($event)"></th>
                <ng-container *ngFor="let access of gridConfigs">
                  <th nowrap="" nzShowSort [nzSortKey]="access.columnName"
                    (nzFilterChange)="filter($event,access.columnName,access.filterType )" [nzFilterMultiple]="'false'"
                    nzShowFilter [nzFilters]="access.filterList"
                    *ngIf="access.sort!=null && access.filter!=null && access.filterType==='in'">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>

                  </th>
                  <th nowrap="" nzShowSort [nzSortKey]="access.columnName" nzCustomFilter
                    *ngIf="access.sort!=null && access.filter!=null && access.filterType==='search'">
                    {{access?.columnLabel}}&nbsp;
                    <nz-filter-trigger [nzVisible]="visible" [nzActive]="'true'" [nzDropdownMenu]="menu">
                      <i nz-icon [nzType]="'search'"></i>
                    </nz-filter-trigger>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <div class="ant-table-filter-dropdown">
                        <div class="search-box">
                          <input type="text" nz-input placeholder={{access.columnLabel}} [(ngModel)]="searchValue" />
                          <button nz-button nzSize="small" nzType="primary"
                            (click)="searchCust(access.columnName,access)" class="search-button">
                            Search
                          </button>
                          <button nz-button nzSize="small" (click)="resetCust(access.columnName)">Reset</button>
                        </div>
                      </div>
                    </nz-dropdown-menu>

                  </th>
                  <th nowrap="" (nzFilterChange)="filter($event,access.columnName,access.filterType )"
                    [nzFilterMultiple]="'false'" nzShowFilter [nzFilters]="access.filterList"
                    *ngIf="access.sort==null && access.filter!=null  && access.filterType==='in'">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>

                  </th>
                  <th nowrap="" nzShowSort [nzSortKey]="access.columnName"
                    *ngIf="access.sort!=null && access.filter==null">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>

                  </th>
                  <th nowrap="" *ngIf="access.sort==null &&  access.filter==null">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>

                  </th>
                </ng-container>

                <th *ngIf="actionButton!=='none'" style="width:10%"><b>Selection</b></th>
              </tr>
            </thead>
            <tbody>

              <ng-container *ngFor="let item of basicTable.data">
                <tr class="ng-scope">
                  <td nzShowCheckbox (nzCheckedChange)="checkedChange($event,item[rowid])"
                    [(nzChecked)]="mapOfCheckedId[item[rowid]]"></td>
                  <ng-container *ngFor="let access of gridConfigs">
                    <ng-container [ngSwitch]="access.columnType">
                      <td class="ng-binding" *ngSwitchCase="'text'">{{item[access.columnName]}}</td>
                      <td class="ng-binding" *ngSwitchCase="'date'">{{item[access.columnName]| date:'M/d/yy, h:mm a'}}
                      </td>
                      <td class="ng-binding" *ngSwitchCase="'phone'">{{ item[access.columnName] | slice:0:3 }}-{{
                        item[access.columnName] | slice:3:6 }}-{{ item[access.columnName] | slice:6:10 }}</td>
                    </ng-container>
                  </ng-container>
                  <td *ngIf="actionButton!=='none'" class="default-font-Color"><span style="cursor: pointer"
                      (click)="selection(item)" class="btnEdit"><i class="fa fa-eye"
                        aria-hidden="true"></i>Selection</span></td>
                </tr>
              </ng-container>
            </tbody>
          </nz-table>
          <!-- pager row -->
        </app-form-group-wrapper>
      </div>
    </div>
  </div>
</div>