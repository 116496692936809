<app-suspense-comment (AddSuspensecomment)="addSuspenseComment()" (moveSuspense)="moveSuspense($event)"
  [suspenseComments]='suspenseComments'></app-suspense-comment>
<br/>
<div *ngIf="_clientId != null" style="border:1px solid rgb(182, 182, 182);padding: 15px; ">
  <br/>
  <nz-table nzSize="small" [nzShowPagination]="true" #basicTable [nzData]="listOfData1">
    <thead>
      <tr>
        <th nzWidth="50px" nzShowExpand *ngIf="expandable"></th>
        <th nzWidth="315px">Section Name</th>
        <th nzWidth="200px" style="text-align:right">Suspense Balance</th>
        <th style="text-align:right">Outstanding</th>
        <th style="text-align:right;padding-right: 55px">Amount</th>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-data1 [ngForOf]="basicTable.data">
        <tr>
          <td nzShowExpand *ngIf="expandable" [(nzExpand)]="mapOfExpandData[data1.Id] "
            (nzExpandChange)="expand(data1,$event)"></td>
          <td>{{ data1.SectionName }}</td>
          <td style="text-align:right">{{ data1.SuspenceBalance | currency}}</td>
          <td style="text-align:right">{{ data1.OutStanding | currency}}</td>
          <td style="padding-right: 42px">
            <div style="float:right">&nbsp;
              <!--  <input checked type="checkbox" [(ngModel)]="data1.autoPost" > Auto Post&nbsp;&nbsp; -->
              <nz-input-number [nzFormatter]="formatterDollar" [nzParser]="parserDollar" [(ngModel)]="data1.Amount"
                style="width:80px;"></nz-input-number>&nbsp;&nbsp;<i style="cursor: pointer"
                class="fa fa-floppy-o  PatchFont-toppadding" aria-hidden="true"
                (click)=onApply(data1)></i>&nbsp;&nbsp;<i style="cursor: pointer"
                class="fa fa-arrow-up  PatchFont-toppadding" aria-hidden="true" nz-popconfirm
                nzPopconfirmTitle="Are you sure you want to move this up?" (nzOnConfirm)="onMoveUp(data1)" *ngIf='data1.SuspenceBalance!=0'></i>
            </div>
          </td>
        </tr>
        <tr [nzExpand]="mapOfExpandData[data1.Id] && expandable">
          <td></td>
          <td colspan="4">
            <div>
              <app-suspense-section (refresh)="onRefresh()" [entityId]='entityId' [clientId]='_clientId'
                [entityClientId]='data1.entityClientId'></app-suspense-section>
            </div>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </nz-table>
</div>