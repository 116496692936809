import { Component, OnInit, Input, Inject, Output } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from '@shared/api/http.service';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
@Component({
  selector: 'app-benefit-policy',
  templateUrl: './benefitpolicy.component.html',
  styleUrls: ['./benefit.component.scss']
})
export class BenefitPolicyComponent extends BaseComponent implements OnInit {
  @Input()
  entityId;
  @Input()
  entityclientId;
  @Input()benefitEntityId; mapOfExpandData: { [key: string]: boolean } = {};listOfData: any[] = []; _jsonURL = 'benefitpolicygrid';
  @Input()
  set benefitEntityPolicyId(benefitEntityPolicyId) {
    if (benefitEntityPolicyId != null) {
      this._benefitEntityPolicyId = benefitEntityPolicyId;
    }
  }
  _benefitEntityPolicyId;
  EntityId: string; 
  EntityClientId; 
  gridConfigs: any;
  bordered = false; 
  loading = false; 
  sizeChanger = false;
  pagination = true; 
  header = true; 
  title = true; footer = true;
  fixHeader = false; 
  size = 'small'; 
  expandable = true; 
  simple = false;

  constructor(
    private httpser: HttpService, 
    protected routeHelper: RouteHelperService, 
    protected route: ActivatedRoute,
    public dialog: MatDialog, 
    protected clientService: ClientService, 
    private notification: NzNotificationService) {
    super();
  }

  public getJSON(): Observable<any> {
    // return this.http.get(this._jsonURL);
    if (this.entityId == '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
      this._jsonURL = 'hmbenefitpolicygrid';
    } else {
      this._jsonURL = 'benefitpolicygrid';
    }
    return (
      this.httpser.get('json?path=' + this._jsonURL, {
        urlParams: null,
        options: {
          isMock: false
        }
      })
    );
  }

  ngOnInit() {
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
    this.getJSON().subscribe(data1 => {
      //  this.spinner.hide();
      const json = JSON.parse((<any>data1.jsonText) as string);

      if (data1 === null) {
        this.gridConfigs = [];
      } else {
        this.readJson(json);
      }
    });
    const url = `CaseManagement/BenefitEntityPolicyList?entityId=${this.entityId}&entityclientId=${this.entityclientId}&benefitEntityId=${this.benefitEntityId}`
      // + '&benefitEntityId=' + this.benefitEntityId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.listOfData = data;
    });
  }

  onEdit(data) {
    let formid;
    if (this.EntityId == '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
      formid = 'hmbenefitpolicy';
    } else {
      formid = 'benefitpolicy';
    }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: false,
        item: data,
        formid: formid,
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitEntityPolicyList?entityId=${this.entityId}&entityclientId=${this.entityclientId}&benefitEntityId=${this.benefitEntityId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe((data1) => {
          this.listOfData = data1;
        },
        );
      }
    });
  }

  expand(data, event) {
    if (event === true) {
      this.mapOfExpandData = {};
      this.mapOfExpandData[data.benefitEntityPolicyId] = true;
    }
  }

  readJson(data1) {
    this.gridConfigs = (<any>data1).GridConfig;
  }

  onAdd() {
    let formid;
    if (this.EntityId == '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
      formid = 'hmbenefitpolicy';
    } else {
      formid = 'benefitpolicy';
    }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: true,
        item: {
          entityClientId: this.EntityClientId,
          entityId: this.EntityId,
          benefitEntityId: this.benefitEntityId,
          benefitEntityPolicyId: this._benefitEntityPolicyId
        },
        formid: formid,
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitEntityPolicyList?entityId=${this.entityId}&entityclientId=${this.entityclientId}&benefitEntityId=${this.benefitEntityId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe((data1) => {
          this.listOfData = data1;
        },
        );
      }
    });
  }
}
