
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DynamicSearchComponent } from '@shared/components/dynamic_search/dynamic_search.component';

@Component({
  templateUrl: './membersearch.component.html',
  styleUrls: ['./membersearch.component.scss']
})

export class MemberSearchComponent extends DynamicSearchComponent {
  rows; selected; 
  amount = 0; 
  autoPost = false; 
  balance; butdisable = false;
  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');
  selection(item) {
    this.selected = item;
  }

  protected init() {
    this.balance = this.formValue.Balance;
  }

  onClose() {
    this.dialogRef.close(true);
  }

  onMoveToEntity() {
    if (this.selected != null) {
      const url = 'Payment/ReshuffleParticipantBalanceToEntity';
      const dto = {
        entitySuspenseAccountId: this.formValue.suspenseAccountId,
        suspenseAccountId: this.selected.suspenseAccountId,
        amount: this.amount,
        entityClientId: this.selected.entityClientId,
        entityId: this.selected.entityId,
        participantId: this.selected.participantId,
        isAutoPost: this.autoPost
      };
      this.butdisable = true;
      const observ$ = this.clientService.postWithUrl({ url, dto });
      observ$.subscribe((data1) => {
        this.notification.blank('Success', ' ');
        this.butdisable = false;
        this.balance = data1.entitySuspenseAmount;
        this.selected.suspenseAmount = data1.participantSuspenseAmount;
        this.formValue.suspenseAccountId = data1.suspenseAccountId;
      }, err => {
          this.notification.blank(err.error[0], ' ');
          this.butdisable = false;
        }
      );

    }
  }

  onMoveToMember() {
    if (this.selected != null) {
      const url = 'Payment/MoveEntitySuspenseToParticipant';
      const dto = {
        suspenseAccountId: this.formValue.suspenseAccountId,
        amount: this.amount,
        entityClientId: this.selected.entityClientId,
        entityId: this.selected.entityId,
        participantId: this.selected.participantId,
        autoPost: this.autoPost
      };
      this.butdisable = true;
      const observ$ = this.clientService.postWithUrl({ url, dto });
      observ$.subscribe( data1 => {
        this.notification.blank('Success', ' ');
        this.butdisable = false;
        this.balance = data1.amount;
        this.selected.suspenseAmount = data1.participantSuspenseAmount;
        this.formValue.suspenseAccountId = data1.suspenseAccountId;
      }, err => {
          this.notification.blank(err.error[0], ' ');
          this.butdisable = false;
        }
      );
    }
  }

  convertPseudo(str) {
    var newstr = "";
    for (var i = 0; i < str.length; i++) {
      var c = str.charAt(i);
      if (c >= '0' && c <= '9') {
        var cc = 9 - c;
        newstr = newstr + cc.toString();
      }
    }
    return newstr;
  }

  onSearch(data) {
    if (data['pseudossNumber'] != '' && data['pseudossNumber'] != null) {
      data['ssNumber'] = this.convertPseudo(data['pseudossNumber']);
    }
    const url = 'Participant-Lookup';
    const dto = {
      'firstName': data.firstName,
      'ssNumber': data.ssNumber,
      'entityId': data.EntityId,
      'lastName': data.lastName,
    };
    data.entityId = data.EntityId;
    const observ$ = this.clientService.postWithUrl({ url, dto: data });
    observ$.subscribe((data1) => {
      this.rows = data1.participantList;
    }, err => {
      }
    );
  }
}