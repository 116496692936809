<mat-dialog-content>
    <div class="app-container">
      <div class="alert alert-success"
           role="alert"
           *ngIf="saveSuccess">
        {{successMessage}}
      </div>
      <div>
        <h6 class="member-function-header">{{formType}}</h6>
      </div>
      <div class="row">
        <div class="col-12">
          <app-dynamic-fields [formConfigs]='formConfigs'
                              [formValue]="formValue"
                              [deleteButton]='deleteButton'
                              [saveButton]='saveButton'
                              [addNew] = 'addNew'
                              [validators]='validators'
                              [actionButton1] = 'actionButton1'
                              [actionButton2] = 'actionButton2'
                              [paraArr] = 'paraArr'
                              (action1)="onAction1()"
                              (action2)="onAction2()"
                              (cancle)="onCancel()" 
                              (delete)="onDelete($event)" 
                              (search) = "onSearch($event)"
                              (changeObvSub) = "onchangeObvSub($event)"
                              (save)="onSave($event)"></app-dynamic-fields>
        </div>
      </div>
    </div>
    <div *ngIf="uwresponse==true" class="app-container">
        <br />
        <div     style="margin-bottom: 15px; padding: 15px;border:1px solid rgba(16, 140, 146, 0.5);background: rgba(16, 140, 146, 0.02)">
                            <div >
                                    <h6  style="color: rgba(16, 140, 146, 0.5)" >Underwriting result</h6>
                                    Action : <b class="default-font-Color-dark" >{{resAction}}</b> <br />  
                                    Description : <b class="default-font-Color-dark">{{actionText}}</b>  <br /> 
                                    Code : <b class="default-font-Color-dark">{{code}}</b> <br /> 
                    </div>
      </div>        
     
      <input type="radio" value="decline" name="mode" [(ngModel)]="actionUW" (click)="onClick()"> Decline
      <input type="radio" value="pending" name="mode" [(ngModel)]="actionUW" (click)="onClick()"> Pending
            <app-dynamic-form #form [formid]="'enrollmentpolicydecline'" [formIntiValue]='formValue1' *ngIf="actionUW=='decline'"
             (action1Complete) = 'onActionComplete1()'
            >            
            </app-dynamic-form>
            <app-dynamic-form #form [formid]="'enrollmentpolicypending'" [formIntiValue]='formValue1' *ngIf="actionUW=='pending'"
             (action1Complete) = 'onActionComplete1()'
            >            
            </app-dynamic-form>
    </div>
</mat-dialog-content>