import { Component, OnInit, Input, Inject } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { HttpService } from '@shared/api/http.service';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-benefittiers-policy',
  templateUrl: './tiers.component.html',
  styleUrls: ['./benefit.component.scss']
})

export class BenefitTiersComponent extends BaseComponent implements OnInit {
  EntityClientId: string
  @Input() set benefitEntityPolicyDetailDTO(benefitEntityPolicyDetailDTO) {
    this.rows = benefitEntityPolicyDetailDTO;
  }
  // @Input()
  // benefitEntityPolicyDetailDTO;
  @Input()
  benefitEntityPolicyId; mapOfExpandData: { [key: string]: boolean } = {}; expandable = true; gridConfigs: any; rows; EntityId; _jsonURL = 'benefitentitypolicydetailgrid';

  constructor(
    private httpser: HttpService,
    public dialog: MatDialog, 
    protected clientService: ClientService, 
    protected routeHelper: RouteHelperService,
    protected route: ActivatedRoute,
    private notification: NzNotificationService) {
    super();
  }

  public getJSON(): Observable<any> {
    // return this.http.get(this._jsonURL);
    return (
      this.httpser.get('json?path=' + this._jsonURL, {
        urlParams: null,
        options: {
          isMock: false
        }
      })
    );
  }
  
  ngOnInit() {
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
    this.getJSON().subscribe(data1 => {
      //  this.spinner.hide();
      const json = JSON.parse((<any>data1.jsonText) as string);
      if (data1 === null) {
        this.gridConfigs = [];
      } else {
        this.readJson(json);
      }
    });
  }

  onAdd() {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: true,
        item: { 
          benefitEntityPolicyId: this.benefitEntityPolicyId,
          entityId: this.EntityId
        },
        formid: 'benefittier',
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const url = `CaseManagement/BenefitEntityPolicyDetails/?entityId=${this.EntityId}&benefitEntityPolicyId=${this.benefitEntityPolicyId}`
        const  observ$ = this.clientService.getWithUrl(url, { });
        observ$.subscribe( res => {
          this.rows = res
          },
        );
      }
  });
  }

  onEdit(data) {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: false,
        item: data,
        formid: 'benefittier',
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitEntityPolicyDetails/?entityId=${this.EntityId}&benefitEntityPolicyId=${this.benefitEntityPolicyId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe( data1 => {
          this.rows = data1
        },
        );
      }
    });
  }

  expand(data, event) {
    if (event === true) {
      this.mapOfExpandData = {};
      this.mapOfExpandData[data.benefitEntityPolicyDetailId] = true;
    }
  }

  readJson(data1) {
    this.gridConfigs = (<any>data1).GridConfig;
  }
  
}
