import { Component, Input, OnInit } from '@angular/core';

import { BaseComponent } from '@shared/components/base.component';
@Component({
    selector: 'app-print-form',
    templateUrl: './dynamic-print-form.component.html'
  })
  export class DynamicPrintFormComponent extends BaseComponent implements OnInit {
    @Input()
    formid;
    @Input()
    formIntiValue;
    ngOnInit() {

    }
  }
