import { Injector } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCacheInterceptor } from '@shared/interceptors/http-cache.interceptor';
import { CleanupRequestInterceptor } from '@shared/interceptors/http-cleanup-request.interceptor';
import { TokenInterceptor } from '@shared/interceptors/token.interceptor';
import { Iso8601DateInterceptor } from '@shared/interceptors/iso-date.interceptor';

export const HTTP_INTERCEPTOR_PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
    deps: [Injector]
  },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: HttpErrorInterceptor,
  //   multi: true,
  //   deps: [Injector]
  // },
 /*  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpCacheInterceptor,
    multi: true,
    deps: [Injector]
  }, */
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: DuplicateHttpRequestInterceptor,
  //   multi: true,
  //   deps: [Injector]
  // },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CleanupRequestInterceptor,
    multi: true,
    deps: [Injector]
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: Iso8601DateInterceptor,
    multi: true,
    deps: [Injector]
  }
];
