import {
    AllClientListActions,
    ClientListActionTypes
  } from '@store/main/client-management/client-list/client-list.actions';
  import { ClientListState } from '@store/main/client-management/client-list/client-list.state';
  export const initialState = new ClientListState();
  export function clientListReducer(
    state: ClientListState = initialState,
    action: AllClientListActions
  ): ClientListState {
    switch (action.type) {
      case ClientListActionTypes.GET_CLIENT_LIST: {
        return { ...state, loading: true, entityId: action.payload.entityId };
      }
      case ClientListActionTypes.GET_CLIENT_LIST_SUCCESS: {
        return { ...state, loading: false, clients: action.payload.clients };
      }
      case ClientListActionTypes.GET_CLIENT_LIST_FAIL: {
        return { ...state, loading: false, error: action.payload.error };
      }
      default:
        return state;
    }
  }
