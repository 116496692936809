import { fromEvent } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { map, filter, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, ElementRef, forwardRef, Input, AfterViewInit, ViewChild,  ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
@Component({
    selector: 'app-typeahead',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./../dynamic.component.scss'],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TypeaHeadComponent),
        multi: true
      }
    ]
  })
export class TypeaHeadComponent extends BaseComponent implements OnInit, AfterViewInit , ControlValueAccessor {
  @Input()
    size;  
  @Input()
    noclass;  
  @Input()
    init;
    @Input()
    mask;
    @ViewChild('input')
    input: ElementRef;
    @Output()
    changed: EventEmitter<any> = new EventEmitter();
    value = "";disabled = false;
    onChange: any = () => { };
  onTouched: any = () => { };
    ngOnInit() {
        if (this.init == null) {
            this.init = '';
        }
        
    }
    writeValue(value): void {
      this.value = value;
    }
  
    registerOnChange(fn: any): void {
      this.onChange = fn;
      //this.onChange(this.init);

    }
  
    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }
  
    setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }
    ngAfterViewInit() {
       // InputMask({ mask: PHONE_INPUT_MASK }).mask(this.input.nativeElement);
       const typeahead = fromEvent(this.input.nativeElement, 'input').pipe(
        untilComponentDestroyed(this),
        map((e: any) => e.target.value),
        debounceTime(500),
        distinctUntilChanged(),
      );
      typeahead.subscribe(data => {
        this.onChange(data);
        this.changed.emit(data);
       });
      }
}
