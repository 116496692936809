import { ErrorType } from '@store/app.state';
import { MemberDTO } from '@models/shared/member.dto';

export class MemberState {
  loading = false;
  saving = false;
  deleting = false;
  member?: MemberDTO;
  objupdate?:any;
  loadError?: ErrorType;
  saveError?: ErrorType;
  deleteError?: ErrorType;
}
