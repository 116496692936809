import { Component, Output, Input, ElementRef, HostListener, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ClientService } from '@services/client/client.service';
import { Observable } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, filter, tap, finalize } from 'rxjs/operators';
export class User {
  constructor(public id: number, public name: string) { }
}

export interface IUserResponse {
  total: number;
  results: User[];
}
@Component({
  selector: 'app-dynamic-autocomplete-client',
  templateUrl: './dynamic_autocomplete_client.component.html',

})

export class DynamicAutoCompleteClientComponent implements OnInit {
  filteredUsers: Observable<any>;
  usersForm: UntypedFormGroup;
  @Input()
  dto;
  @Output()
  selected: EventEmitter<any> = new EventEmitter();
  isLoading = false;
  entityId
  constructor(private fb: UntypedFormBuilder, private clientService: ClientService) { }
  ngOnInit() {
    this.entityId = this.dto.entityId
    this.usersForm = this.fb.group({
      userInput: null
    });
    const tt = this.usersForm.get('userInput');
    if (tt != null) {
      const url = 'Entity/SearchEntityClients?entityId=' + this.entityId + '&searchText='; 
      tt.valueChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          filter((query: string) => query.length > 3),
          filter((query: string) => typeof query === 'string'),
          tap(() => this.isLoading = true),
          switchMap(value => {
            return this.clientService.getWithUrl( url + value, {}).pipe(
              finalize(() => this.isLoading = false),
            );
          })
        ).subscribe(users => {
          this.filteredUsers = users;
        });
    }
  }

  optionSelected(e) {
    this.selected.emit(e);
  }

  displayFn(user) {
    if (user) { return user.clientNumber + ' ' + user.entityClientName; } else {
      return null;
    }
  }
}
