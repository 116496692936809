<span *ngFor="let item of dependentsInfoOwn">
  <span class="default-font-Color-dark" *ngIf='selectEnrolled(item)==true'>
    &nbsp; <i title="{{item.genderText}}" *ngIf="item.gender == 'F'" class="fa fa-female" aria-hidden="true">&nbsp;</i>
    <i title="{{item.genderText}}" *ngIf="item.gender == 'M'" class="fa fa-male" aria-hidden="true">&nbsp;</i>
    <i title="{{item.relationShipText}}" *ngIf="item.relationship == 'C'" class="fa fa-child" aria-hidden="true">&nbsp;</i>
    <i title="{{item.relationShipText}}" *ngIf="item.relationship == 'S'" class="fa fa-user-plus" aria-hidden="true">&nbsp;</i>
    {{item.fullName}} ({{item.birthDate | date }}) <br />
    <div *ngIf='item.effectiveDate!=null'>
      EffectiveDate: {{item.effectiveDate | date}}
    </div>
    <div *ngIf='item.coverageValue!=null '>
      Coverage Value:{{item.coverageValue | currency}}<br />
      Current Coverage Value:{{item.coverageValueCurrent | currency}}<br />
      Billable Coverage Value:{{item.coverageValueBillable | currency}}<br />
    </div>
  </span>
</span>
<br />