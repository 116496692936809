<div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
  <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
    height:200px; 
    position: fixed; 
    z-index:2;
    top: 50%; 
    left: 50%; 
    margin-top: -100px; 
    margin-left: -100px;"></nz-spin>
  <span class="default-font-Color-dark">Loading ...</span>
</div>

<div class="app-container app-form-group-wrapper">

  <div class="alert alert-success" role="alert" *ngIf="saveSuccess">
    {{successMessage}}
  </div>
  <div>
    <h6 class="member-function-header">{{formType}}</h6>
  </div>
  <div class="row">
    <div class="col-12">
      <app-dynamic-fields #fields *ngIf='isPrint!==true' [saveButton]='getSaveButton()' [actionButton1]='actionButton1'
        [cancelButton]='cancelButton' [deleteButton]='deleteButton' [validators]='validators'
        [searchobvUrl]='searchobvUrl' [formConfigs]='formConfigs' [formValue]="formValue" (cancle)="onCancel()"
        [paraArr]='paraArrInput' [hideButton]='hideButton' (delete)="onDelete($event)" (action1)="onAction1()"
        (search)="onSearch($event)" (changeObvSub)="onchangeObvSub($event)"
        (save)="onSave($event)"></app-dynamic-fields>

      <app-dynamic-printfields *ngIf='isPrint===true' [formConfigs]='formConfigs' [formValue]="formValue">

      </app-dynamic-printfields>
    </div>
  </div>
</div>