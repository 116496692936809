import * as _ from 'lodash';

export const REQUEST_HEADERS = {
  HTTP_ERROR_INTERCEPTOR: 'X-A-Http-Error-Interceptor',
  SKIP_HTTP_ERROR_INTERCEPTOR: 'skip-error',
  SUPPRESS_HTTP_ERROR: 'suppress-error',
  TEMPLATE_SKIP_HTTP_ERROR: _.template('skip-error=${data.status}', {
    variable: 'data'
  }),
  TEMPLATE_SUPPRESS_HTTP_ERROR: _.template('suppress-error=${data.status}', {
    variable: 'data'
  }),
  ///
  CACHE: 'X-A-Cache-Control',
  NO_CACHE: 'no-cache',
  CACHE_MAX_AGE_PREFIX: 'max-age=',
  TEMPLATE_CACHE_MAX_AGE: _.template('max-age=${data.maxAge}', {
    variable: 'data'
  }),
  DEFAULT_MAX_AGE_SECONDS: 60,
  ///
  DUPLICATE_REQUEST: 'X-A-Duplicate-Request',
  URL_TEMPLATE: 'X-A-Url-Template',
  HTTP_PARAMS: 'X-A-Http-Params',
  USE_MOCK: 'X-A-Use-Mock'
  ///
};

export interface HttpRequestOptions {
  headers?: any;
  cache?: boolean | number;
  skipErrorInterceptor?: boolean | number;
  suppressError?: boolean | number;
  duplicateRequest?: DuplicateRequestResolveMode;
  isMock?: boolean;
}

export enum DuplicateRequestResolveMode {
  None = 'None',
  Reuse = 'Reuse',
  Reject = 'Reject'
}
