
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';

import { AuthState } from '@store/auth/auth.state';
import { SpinnerService } from '@shared/services/spinner.service';
import { AuthService } from '@services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
    selector: 'user-login',
    templateUrl: './loginsso.component.html',

     
  })
  export class LoginSSOComponent    {
    appid
    ABCSSOToken
    queryStringParams: any
    //form: UntypedFormGroup;
    authState: AuthState;
    config = window['___alliant-config-eniac-APP___']
    
    logintype = this.config.logintype
    welcomeText = this.config.welcomeText
    phoneNum = this.config.phoneNum
    primaryColor = this.config.primaryColor
    logoImage = this.config.logoImage
    bgImage = this.config.bgImage
    isPrivacyLink = this.config.isPrivacyLink
    isTermsLink = this.config.isTermsLink
    registrationParam = this.config.registrationParam
    return = '';
    constructor(
      private notification: NzNotificationService,
      
      private router: Router,
      
      private spinner: SpinnerService,
      private authService: AuthService,  private route: ActivatedRoute
    ) {
     
    }
    ssologin(){
      if (this.queryStringParams.ABCSSOToken != null) {
        this.appid = this.queryStringParams.appid
        this.ABCSSOToken = this.queryStringParams.ABCSSOToken
        this.spinner.show();
        setTimeout(() => {
          this.authService.authenticateSSO({
            appid: this.appid,
            ABCSSOToken: this.ABCSSOToken,
          }).subscribe(o=>{
            
            this.authService.setAuth(o);
            this.spinner.hide();
            if (this.authService.isAuthenticated()) {
              setTimeout(() => {
                this.return = '/main/enterprise-dashboard';
            
                this.router.navigate([this.return]);
              }, 600);
              
          }
          },
          err=>{
            this.notification.blank( err.message, ' ',{ nzDuration: 10000 });
            this.spinner.hide();
          }
          );
        },600)
        

      } 
    }
    ngOnInit() {
      
      this.route.queryParams
      .subscribe(params => {
       
        this.queryStringParams = params;
        this.ssologin();
      }
      );
  
    }
   

  
  
  }