import * as _ from 'lodash';

export const FORM_CONTROL_DEFAULT_ERROR_TEMPLATE = _.template(
  '<%= data.controlPath %> has validation errors.',
  { variable: 'data' }
);

export const FORM_CONTROL_VALIDATION_ERROR_TEMPLATE = {
  min: _.template(
    '<%= data.controlPath %> must be greater than or equal to <%= data.min %>.',
    { variable: 'data' }
  ),
  max: _.template(
    '<%= data.controlPath %> must be less than or equal to <%= data.max %>.',
    { variable: 'data' }
  ),
  required: _.template('<%= data.controlPath %> is required.', {
    variable: 'data'
  }),
  requiredTrue: _.template('<%= data.controlPath %> must be true.', {
    variable: 'data'
  }),
  email: _.template('<%= data.controlPath %> must be a valid email.', {
    variable: 'data'
  }),
  minlength: _.template(
    // tslint:disable-next-line:max-line-length
    '<%= data.controlPath %> must have a minimum length greater than or equal to <%= data.requiredLength %>. Actual is <%= data.actualLength %>.',
    { variable: 'data' }
  ),
  maxlength: _.template(
    // tslint:disable-next-line:max-line-length
    '<%= data.controlPath %> must have a maximum length less than or equal to <%= data.requiredLength %>. Actual is <%= data.actualLength %>.',
    { variable: 'data' }
  )
};
