import { Injector } from '@angular/core';
import { HttpService, HttpFactory } from '@shared/api/http.service';
import {
  SampleHttpService,
  SampleHttpFactory
} from '@shared/api/sample-http.service';

export const HTTP_SERVICE_PROVIDERS = [
  {
    provide: HttpService,
    useFactory: HttpFactory,
    deps: [Injector]
  },
  {
    provide: SampleHttpService,
    useFactory: SampleHttpFactory,
    deps: [Injector]
  }
];
