import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { GroupComponent } from '@modules/main/client-management/suspense-management/group.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MatDialog } from '@angular/material/dialog';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';

@Component({
  selector: 'app-suspense-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./suspense-management.component.scss'],

})
export class EntityComponent extends BaseComponent implements OnInit {
  @ViewChild(GroupComponent)
  memberComponent: GroupComponent;
  @Input()
  entityId;
  @Input()
  suspenseAccountId;
  @Output()
  refresh = new EventEmitter<any>();
  observ$; autoPost = true;
  clientId;
  expandable = true; applyAmt: any = '';
  suspenseAmount; outstandingAmount;
  suspenseComments;
  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');
  constructor(
    public dialog: MatDialog,
    protected clientService: ClientService,private notification: NzNotificationService) {
    super();
  }

  onChange(event) {
    localStorage.setItem('ClientId', this.clientId);
  }

  onMoveUp() {
    const url = 'Payment/ReshuffleGroupSuspense';
    const dto = {
      'amount': this.applyAmt,
      'suspenseAccountId': this.suspenseAccountId,
      'entityId': this.entityId,
      'clientId': this.clientId,
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe((data) => {
      // this.memberComponent.load();
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(data);
    },
      err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  onApply() {
    const url = 'Payment/SplitGroupSuspenseBalance';

    // const list = this.memberComponent.getList();
    // const groupSectionSuspenseAmounts = list.map(x => ({'clientId': x.clientId , 'Amount': x.Amount}));
    const dto = {
      'suspenseAccountType': 'ENTITY',
      'suspenseAccountId': this.suspenseAccountId,
      'entityId': this.entityId,
      //  'balance' : this.outstandingAmount,
      'groupSectionSuspenseAmounts': [{ 'clientId': this.clientId, 'Amount': this.applyAmt }]
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe((data) => {
      this.memberComponent.load();
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(data);
    },
      err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  onRefresh() {
    this.refresh.emit(null);
  }

  ngOnInit() {
    if (localStorage.getItem('ClientId') != null) {
      this.clientId = localStorage.getItem('ClientId');
    }
    const url = 'Entity/EntityClients?entityId=' + this.entityId;
    this.observ$ = this.clientService.getWithUrl(url, {});
    this.getSuspenseComments();
  }

  suspenseAmountChange(data) {
    this.suspenseAmount = data.suspenseAmount;
    this.outstandingAmount = data.outstandingAmount;
    this.applyAmt = this.outstandingAmount - this.suspenseAmount;
    if (this.applyAmt < 0) {
      this.applyAmt = '';
    }
  }

  moveSuspense(data) {
    const url = 'Payment/MoveSuspenseCommentBalance';
    const dto = {
      'suspenseAccountId': data.suspenseAccountId,
      'parentId': this.suspenseAccountId,
      'entityId': this.entityId,
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe((res) => {
      // this.member$ = this.getMember(this.selectedDate);
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit();
      // this.getSuspenseComments() ;
    },
      err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }


  addSuspenseComment() {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: false,
        item: { 'entityId': this.entityId, 'suspenseType': 'Entity', 'suspenseAccountId': this.suspenseAccountId },
        formid: 'suspensecomment',
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSuspenseComments();
      this.refresh.emit();
    });
  }

  getSuspenseComments() {
    // tslint:disable-next-line:max-line-length
    const url = 'Payment/SuspenseAccountCommentBalance?suspenseType=entity&entityId=' + this.entityId + '&suspenseAccountId=' + this.suspenseAccountId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.suspenseComments = data.suspenseAmountBalanceDTO;
    });
  }
}
