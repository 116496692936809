import { Injectable } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  constructor(protected route: ActivatedRoute, private router: Router) { }

  printDocument(documentName: string, documentData: any) {
    this.isPrinting = true;
  //   this.router.navigateByUrl('main/reportprint/(print:print)');
    this.router.navigate(['main/reportprint',
      { outlets: {
        'print': ['print', 'test', 'ttt' ]
      }}], {relativeTo: this.route});
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate(['main/reportprint']);
    });
  }
}
