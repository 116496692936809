import { ClientDTO } from '@models/client/client.dto';

export class EntityDTO {
  id: string;
  entityName: string;
  entityShortName: string;
  entityCode: string;
  entityStockCode?: any;
  startDate: Date;
  endDate: Date;
  address1?: any;
  address2?: any;
  city?: any;
  state?: any;
  zip?: any;
  phone?: any;
  website?: any;
  createdBy: string;
  createdDate: Date;
  lastUpdatedBy?: any;
  lastUpdatedDate?: any;
  rowversion: string;
  clients?: any[];
}

export class EntityClientDTO {
  entityClientId: string;
  entityId: string;
  clientId: string;
  entityClientName: string;
  clientNumber: string;
  entityClientNumber: string;
  iIsVIP: boolean;
  status: string;
  entityName: string;
  entityShortName: string;
  entityCode: string;
  EntityStockCode?: any;
  clientName: string;
  clientCode: string;
  clientStockCode?: any;
}

export class EntityAndClientDTO {
  entity?: EntityDTO | null;
  client?: ClientDTO | null;
}

export class EntityIdClientIdDTO {
  id?: string;
  entityId?: string;
  entityName?: string;
  clientId?: string;
  entityClientId?: string;
  clientName?: string;
  clientCode?: string;
  entityClientNumber?: string;
  entityClientName?: string;
}
