<div [formGroup]='coverageForm'>
  <div *ngIf='IsNoPackageCoverageNumberRequired!==true'>
    <div style="display: inline-block;">
      <div style="display: inline-block;">
        <span> Select a Package Code</span>
        <br />
        <div *ngIf='UIControlType=="checkbox"'>
          <label nz-checkbox formControlName="selected"></label>
        </div>
        <select class="form-control  input-sm col-12" formControlName="packageCode" (change)="onChangePackage($event)">
          <option *ngFor="let opt of  CoverageNumberPackages " [value]="opt.PackageCode">{{opt.PackageCode}}</option>
        </select>
      </div>&nbsp;&nbsp;
      <div *ngIf='classes!=null' style="display: inline-block;">
        <span> Select a Class Code</span>
        <br />
        <select class="form-control  input-sm col-12" formControlName="classCode" (change)="onChange($event)">
          <option></option>
          <option *ngFor="let opt of  classes " [value]="opt.ClassCode">{{opt.ClassCode}}</option>
        </select>
      </div>
      <div *ngIf='classes==null' style="margin: 0; padding: 0px;display: inline-block;">
        <div width="250px" *ngIf='selectedPackage?.IsCoverageAmountRequired===true && IsCoverageAmountRequired==true'>
          Select Coverage Amount ($):<app-typeahead style="padding: 0px;" [init]='coverageVal'
            class="form-control input-sm col-6" (changed)="modelChanged($event)"></app-typeahead>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf='IsNoPackageCoverageNumberRequired===true && UIControlType!=="line"'>
    <div *ngIf='dropdownbox===true'>
      <div *ngIf='usedropdown==true'>
        <div class="row" style="margin: 0px;"> <select class="form-control  input-sm col-4"
            formControlName="coverageValue" (change)="onChangeAmt($event)">
            <option></option>
            <option *ngFor="let opt of  droplist " [value]="opt">{{opt}}</option>
          </select>
          &nbsp;<label nz-checkbox formControlName="notusedropdown" (ngModelChange)="Check($event)"></label>&nbsp;
          Enable Custom Value
        </div>
      </div>
      <div *ngIf='usedropdown==false' width="250px" style="padding-bottom: 10px;">
        Select Coverage Amount ($):
        <div class="row" style="margin: 0px;">
          <app-typeahead [init]='coverageVal' class="form-control  input-sm col-4" style="padding: 0px;"
            (changed)="modelChanged($event)"></app-typeahead>
          &nbsp;<label nz-checkbox formControlName="notusedropdown" (ngModelChange)="Check($event)"></label>&nbsp;Enable
          Custom Value
        </div>
      </div>
    </div>
    <div *ngIf='dropdownbox!==true && EntityId!="ac586073-69d6-41bf-875c-eb754f750457"' width="250px" style="padding-bottom: 10px;">
      Select Coverage Amount ($) :
      <div class="row" style="margin: 0px;">
        <app-typeahead [init]='coverageVal' (changed)="modelChanged($event)"></app-typeahead>
      </div>
    </div>
  </div>
  <div *ngIf='IsNoPackageCoverageNumberRequired===true && UIControlType=="line"'>

    <div *ngIf='dropdownbox!==true' width="250px" style="padding-bottom: 10px;">
      Select Coverage Amount ($):{{coverageVal}}
     
    </div>
  </div>

  <div *ngIf='selectedClass!=null'>
    <div
      *ngIf='selectedClass.CoverageCalculationTypeCode==="C" && selectedClass.AllowToChooseLowerValue===false && selectedClass.GuaranteeIssueValue!=null && selectedClass.AllowedCoverageAmount>selectedClass.GuaranteeIssueValue'>
      Select Coverage Amount:
      <select class="form-control  input-sm col-4" formControlName="coverageValue" (change)="onChangeAmt($event)">
        <option></option>
        <option [value]="selectedClass.GuaranteeIssueValue!">{{selectedClass.GuaranteeIssueValue!}}
        </option>
        <option [value]="selectedClass.AllowedCoverageAmount!">{{selectedClass.AllowedCoverageAmount!}}
        </option>
      </select>
    </div>
    <div
      *ngIf='selectedClass.CoverageCalculationTypeCode==="C" && selectedClass.AllowToChooseLowerValue===false && ( selectedClass.GuaranteeIssueValue==null || selectedClass.GuaranteeIssueValue!=null && selectedClass.AllowedCoverageAmount<=selectedClass.GuaranteeIssueValue)'>
      <br /><span style="font-size: 14px; color: rgb(155, 155, 155)">Available Coverage Amount: </span>
      <b>{{selectedClass.AllowedCoverageAmount | currency}}</b>
    </div>
    <div *ngIf='selectedClass.CoverageCalculationTypeCode==="F"'>
      <br /> <span style="font-size: 14px; color: rgb(155, 155, 155)">Available Coverage Amount: </span>
      <b>{{selectedClass.FlatAmount | currency }}</b>
    </div>
    <div width="250px" *ngIf='selectedClass.CoverageCalculationTypeCode==="C" && selectedClass.AllowToChooseLowerValue===true'>
      <span style="font-size: 14px; color: rgb(155, 155, 155);">Select Coverage Amount ($):</span>
      <app-typeahead (changed)="modelChanged($event)"></app-typeahead>
    </div>
    <div *ngIf='selectedClass.CoverageCalculationTypeCode==="T" && selectedClass.AllowToChooseLowerValue===false'>
      <br /> <span style="font-size: 14px; color: rgb(155, 155, 155)">Available Coverage Amount: </span>
      <b>{{selectedClass.MaximumumIssueValue | currency}}</b>
    </div>
    <div width="250px" *ngIf='selectedClass.CoverageCalculationTypeCode==="T" && selectedClass.AllowToChooseLowerValue===true'
      style="padding-bottom: 10px;">
      Select Coverage Amount ($): <app-typeahead (changed)="modelChanged($event)"
        style="display: inline;"></app-typeahead>
      Message: Maximum Value is {{selectedClass.MaximumumIssueValue | currency}} and coverage should of multiples of
      {{selectedClass.IncrementalValue | currency}}
    </div>
    <div *ngIf='selectedClass.CoverageCalculationTypeCode==="I" && selectedClass.AllowToChooseLowerValue===false'>
      <br /> <span style="font-size: 14px; color: rgb(155, 155, 155)">Available Coverage Amount: </span>
      <b>{{selectedClass.MaximumumIssueValue | currency}}</b>
    </div>
    <div *ngIf='selectedClass.CoverageCalculationTypeCode==="I" && selectedClass.AllowToChooseLowerValue===true'
    width="250px" style="padding-bottom: 10px; margin: 0;">
      Select Coverage Amount ($): <app-typeahead  (changed)="modelChanged($event)"
        style="display: inline;"></app-typeahead>
      Message: Maximum Value is {{selectedClass.MaximumumIssueValue | currency}} and coverage should of multiples of
      {{selectedClass.IncrementalValue | currency}}
    </div>
  </div>
</div>