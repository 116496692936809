import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { ConfigService } from '@shared/config.service';
import { AuthService } from '@services/auth/auth.service';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as StackTraceParser from 'error-stack-parser';
import { HttpService } from '@shared/api/http.service';
import { HttpBaseService } from '@shared/api/http-base.service';
import { LoggerService } from '@shared/logging/logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly errorLogUrl = 'log-error';

  private logger: LoggerService;
  private configService: ConfigService;
  private authService: AuthService;
  private http: HttpBaseService;
  private location: LocationStrategy;

  constructor(private injector: Injector) {
    this.logger = injector.get(LoggerService);
    this.configService = injector.get(ConfigService);
    this.authService = injector.get(AuthService);
    this.http = injector.get(HttpService);
    this.location = this.injector.get(LocationStrategy);
  }

  handleError(error: Error) {
    this.logger.error(error);
    this.displayError(error);
  }

  private displayError(error: Error) {
    // TODO: notify the user using a NotificationService/NotificationComponent

    this.logErrorOnServer(error);
  }

  private logErrorOnServer(error: Error) {
    if (!this.configService.config.logErrorsToServer) {
      return;
    }

    const errorWithContextInfo = this.createErrorContextInfo(error);
    // we use a promise since Angular seems to silently ignore errors that are thrown
    // by Observables from inside the GlobalErrorHandler.
    // we set skipErrorInterceptor: true because we want to handle the error right here
    this.http
      .post(this.errorLogUrl, {
        body: errorWithContextInfo,
        options: {
          skipErrorInterceptor: true
        }
      })
      .toPromise()
      .catch(err => this.logger.warn(err));
  }

  private createErrorContextInfo(error): any {
    // All the context details that we want (usually coming from other services; Constants, UserService...)
    const name = error.name || null;
    const appId = this.configService.config.appId;
    const userId = this.authService.getUserId();
    const username = this.authService.getUsername();
    const clientTime = new Date();
    const url =
      this.location instanceof PathLocationStrategy ? this.location.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();
    // const stack = (error instanceof HttpErrorResponse) ? null : StackTraceParser.parse(error);
    const errorToSend = {
      name,
      appId,
      userId,
      username,
      clientTime,
      url,
      status,
      message
    };

    return errorToSend;
  }
}
