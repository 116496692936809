import { Component, OnInit, Input, Output, ChangeDetectorRef , EventEmitter } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { MemberService } from '@services/client/member.service';
import {   Observable  } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '@services/client/client.service';
@Component({
    selector: 'app-member-tier-eis-coverage',
    templateUrl: './tier-eis-coverage.component.html',
    styleUrls: ['./../dynamic.component.scss']
  })
  export class TierEisCoverageComponent extends BaseComponent implements OnInit {
    @Input()
    dropReason;
    @Input()
    set rate(rate) {
      this._rate = rate;
      if (this.tierForm != null) {
        setTimeout(() => {
          this.tierForm.value.rate = rate.benefitEntityPolicyDetailRates[0].rate;
          this.tierForm.patchValue(this.tierForm.value);
        });

      }


    }
    _rate;
    @Input()
    currentEnrollment;
    @Input()
    set eisDetails(eisDetails) {
      this._eisDetails = eisDetails;
    }
    _eisDetails;
    @Input()
    set entityId(entityId) {
      if (entityId != null) {
        this.EntityId = entityId;
      }

    }
    @Input()
    set clientId(clientId) {
      if (clientId != null) {
        this.ClientId = clientId;
      }

    }
    @Input()
    set memberId(memberId) {
      if (memberId != null) {
        this.MemberId = memberId;
      }

    }
    @Input()
    set entityClientId(entityClientId) {
      if (entityClientId != null) {
        this.EntityClientId = entityClientId;
      }

    }
    @Input()
    inTier;
    @Input()
    coverage;
    @Input()
    tiers;
    @Input()
    benefitEntityPolicy;
    @Output() change1 = new EventEmitter<any>();
    @Output() formReady = new EventEmitter<UntypedFormGroup>();
    tierForm: UntypedFormGroup;
    EntityId: string;
    ClientId: string; EntityClientId; initCoverageList: any = [];
    MemberId: string; rate$: Observable<any>;
    // dropReason;
    constructor(private routeHelper: RouteHelperService, protected clientService: ClientService, public changeDetectorRef: ChangeDetectorRef,
        private route: ActivatedRoute, private memberService: MemberService, private fb: UntypedFormBuilder) {
        super();
        this.EntityId = this.routeHelper.getEntityId(this.route);
        this.ClientId = this.routeHelper.getClientId(this.route);
        this.MemberId = this.routeHelper.getMemberId(this.route);
        this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
      }
    ngOnInit() {
      // tslint:disable-next-line:max-line-length
      const rate = this._rate != null && this._rate.benefitEntityPolicyDetailRates[0] != null ? this._rate.benefitEntityPolicyDetailRates[0].rate : null;
        this.tierForm = this.fb.group({
            'uiControlType': this.tiers[0].uiControlType,
            benefitEntityPolicyDetailId: this.tiers[0].benefitEntityPolicyDetailId,
            enrolled: false,
            rate,
            optReason: ''
          });
          if (this.currentEnrollment != null) {
            this.tierForm.value.enrolled = true;
            this.tierForm.patchValue(this.tierForm.value);
          }
          // Emit the form group to the father to do whatever it wishes
          this.formReady.emit(this.tierForm);
         /*  const url = 'Enrollment/EntityCodesLookup/' + this.EntityId + '/EnrollmentTerminationCode'  ;
          const observ$ = this.clientService.getWithUrl(url, { });
          observ$.subscribe((data) => {
              this.dropReason = data;
          }); */
    }
    onChangeCovInit(coverage) {

      const coverages: any = [];
      if (coverage.packageCode != null) {
        this.initCoverageList.push(coverage);
      }
   //   this.change1.emit(this.initCoverageList);
    }
    onChangeCov(obj) {
      // get all coverages
      const coverages: any = [];
      for (const  property in this.tierForm.value) {
        if ( property.startsWith('coverages')) {
          const coverage = this.tierForm.value[property];
          if (coverage.packageCode != null) {
            coverages.push({
              id: coverage.coverage,
              coverage: coverage.coverageValue,
              benefitEntityPolicyDetailCoverageTierCode: coverage.benefitEntityPolicyDetailCoverageTierCode,
              benefitEntityPolicyDetailCoverageValue: coverage.coverageValue,
              packageCode: coverage.packageCode,
              classCode: coverage.classCode,
              value: coverage.coverageValue,
              CoverageNumber: coverage.CoverageNumber
            });
          } else {
            coverages.push({
              id: coverage.coverage,
              coverage: coverage.coverageValue,

            });
          }

        }
      }

      this.change1.emit(coverages);
    }
    radioClick(item) {
        // tslint:disable-next-line:forin
        for (const prop in this.tierForm.value) {
          if (prop !== 'uiControlType') {
            this.tierForm.value[prop].selected = false;
          }

        }
        this.tierForm.value[item.benefitEntityPolicyDetailCoverageId].selected = true;
        this.tierForm.patchValue(this.tierForm.value);
      }

      isSelected(benefitEntityPolicyDetailCoverageId) {
          //   return this.tierForm.value[benefitEntityPolicyDetailCoverageId].selected;
          if (this.tierForm.value[benefitEntityPolicyDetailCoverageId]) {
                return this.tierForm.value[benefitEntityPolicyDetailCoverageId].selected;
             // return true;
          }
          return false;

      }
      getRate(item) {
          if (this.tierForm.value[item.benefitEntityPolicyDetailCoverageId] != null) {
            const rate = this.tierForm.value[item.benefitEntityPolicyDetailCoverageId].rate;
            return rate;
          }

        return null;
      }
    formInitialized(name: string, form: UntypedFormGroup) {

        setTimeout(() => {
          this.tierForm.setControl(name, form);
        });

      }
onChange(event, item) {
  this.change1.emit(event);

}

}
