import { Component, OnInit, Input } from '@angular/core';
import { MemberState } from '@store/main/member/member.state';
import { MemberDTO, MemberProfileDTO } from '@models/shared/member.dto';
import * as _ from 'lodash';

import { ClientService } from '@services/client/client.service';
@Component({
  selector: 'app-selected-member-header-share',
  templateUrl: './selected-member-header.component.html',
  styleUrls: ['./selected-member-header.component.scss']
})
export class SelectedMemberHeaderComponent implements OnInit {
  @Input()
  memberState: MemberState;
  @Input()
  set entityClientId(entityClientId) {
    if (entityClientId) {
        this._entityClientId = entityClientId;
        const url = 'Entity/ClientProfile?entityClientId=' + entityClientId ;
          const observ$ = this.clientService.getWithUrl(url, { });
          observ$.subscribe((data) => {
              this.entityName = data.entityName;
              this.entityClientName = data.entityClientName;
              this.entityClientNumber = data.entityClientNumber;
              this.clientNumber = data.clientNumber;
          });
    }
  }
  @Input()
  formValue;
  // set formValue(formValue) {
  //   if(formValue!=null){
  //     this.member.profile={...this.member.profile,...formValue};
  //   }
  // }
  _entityClientId;
  entityName; entityClientName; entityClientNumber; clientNumber;
  constructor(private clientService: ClientService) {}

  ngOnInit() {}

  get member(): MemberDTO | undefined {
    if (this.memberState && this.memberState.member) {
      return this.memberState.member;
      
      
    }
    return;
  }

  get profile(): MemberProfileDTO | undefined {
    if (this.member && this.member.profile) {
      if(this.formValue==null){
        return this.member.profile;
      }else{
        
        return {...this.memberState.member.profile,...this.formValue};
      }
      //return this.member.profile;
    }
    return;
  }

  get memberName(): string {
    if (this.profile) {
      return `${this.profile.firstName} ${this.profile.lastName}`;
    }
    return '';
  }

  get address(): string {
    if (!this.profile) {
      return '';
    }

    const address = _.compact([
      this.profile.address1,
      this.profile.address2,
      this.profile.city,
      this.profile.state
    ]);

    return `${address.join(', ')} ${this.profile.zip}`;
  }
}
