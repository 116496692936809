import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { MemberService } from '@services/client/member.service';
import {   Observable  } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '@services/client/client.service';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';

@Component({
    selector: 'app-member-tier-coverage',
    templateUrl: './tier-coverage.component.html',
    styleUrls: ['./../dynamic.component.scss']
  })
  export class TierCoverageComponent extends BaseComponent implements OnInit {
    @Input()
    dropReason;
    @Input()
    set rate(rate) {
      this._rate = rate;
      if (this.tierForm != null) {
        setTimeout(() => {
          this.tierForm.value.rate = rate.benefitEntityPolicyDetailRates[0].rate;
          this.tierForm.patchValue(this.tierForm.value);
        });

      }


    }
    _rate;
    @Input()
    currentEnrollment;
    @Input()
    set entityId(entityId) {
      if (entityId != null) {
        this.EntityId = entityId;
      }

    }
    @Input()
    set clientId(clientId) {
      if (clientId != null) {
        this.ClientId = clientId;
      }

    }
    @Input()
    set memberId(memberId) {
      if (memberId != null) {
        this.MemberId = memberId;
      }

    }
    @Input()
    set entityClientId(entityClientId) {
      if (entityClientId != null) {
        this.EntityClientId = entityClientId;
      }

    }
    @Input()
    inTier;
    @Input()
    coverage;
    @Input()
    tiers;
    @Input()
    benefitEntityPolicy;
    @Input()
  set tierValue(tierValue) {
    this._tierValue = tierValue;
    if (tierValue) {
      this.tier = tierValue['tier'];
      //  this.enrollmentEffectiveDate = tierValue['enrollmentEffectiveDate'];
      this.tierName = tierValue['tierName'];
      this.deps = tierValue['enrolled'];
      this.beneficiaries = tierValue['beneficiaries'];
      if (this.beneficiaries == null) {
        this.beneficiaries = [];
      }
      // this.oldTier = tierValue['oldTier'];
      /* if (this.tier) {
        this.tiers.forEach(element => {
          if (element.benefitEntityPolicyDetailId === this.tier) {
            this._tierValue['tierName'] = element.benefitEntityPolicyDetailName;
            this.tierName = element.benefitEntityPolicyDetailName;
          }
        });
      } else {
        this._tierValue['tierName'] = null;
      } */

    }
  }
    @Output() change1 = new EventEmitter<any>();
    @Output() formReady = new EventEmitter<UntypedFormGroup>();
    beneficiaries: any = [];

    tierForm: UntypedFormGroup;
    //  dropReason;
    EntityId: string;
    ClientId: string; EntityClientId;
    MemberId: string; rate$: Observable<any>; initCoverageList: any = [];
    deps: any = [];
    numsubcomp;firednumsubcomp=0;
    _tierValue; tier; tierName;
    constructor(protected clientService: ClientService, private routeHelper: RouteHelperService,
      private route: ActivatedRoute, private memberService: MemberService, private fb: UntypedFormBuilder) {
      super();
      this.EntityId = this.routeHelper.getEntityId(this.route);
      this.ClientId = this.routeHelper.getClientId(this.route);
      this.MemberId = this.routeHelper.getMemberId(this.route);
      this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
    }
    ngAfterViewInit() {
      // child is set
     
    }
    checked(item) {
      if (item.coverageRelationShipCodes[0] === 'E') {
        if (this.currentEnrollment != null && this.currentEnrollment.coverages != null) {
          const f = this.currentEnrollment.coverages.find(x => x.benefitEntityPolicyDetailCoverageId === item.benefitEntityPolicyDetailCoverageId);
          if (f != null) {
            return true;
          } else {
            return false;
          }

        }
      } else {
        if (this.currentEnrollment != null && this.currentEnrollment.coverages != null) {
        }
      }
      return true;
    }
    onChangeCovInit(coverage) {

      const coverages: any = [];
      if (coverage.packageCode != null) {
        this.initCoverageList.push(coverage);
      }
   //   this.change1.emit(this.initCoverageList);
    }
    onChangeCov(obj) {
      // get all coverages
      const coverages: any = [];
      for (const  property in this.tierForm.value) {
        if ( property.startsWith('coverages')) {
          const coverage = this.tierForm.value[property];
          if (coverage.packageCode != null) {
            coverages.push({
              id: coverage.coverage,
              coverage: coverage.coverageValue,
              benefitEntityPolicyDetailCoverageTierCode: coverage.benefitEntityPolicyDetailCoverageTierCode,
              benefitEntityPolicyDetailCoverageValue: coverage.coverageValue,
              packageCode: coverage.packageCode,
              classCode: coverage.classCode,
              value: coverage.coverageValue,
              CoverageNumber: coverage.CoverageNumber
            });
          } else {
            coverages.push({
              id: coverage.coverage,
              coverage: coverage.coverageValue,

            });
          }

        }
      }

      this.change1.emit(coverages);
    }
    enrollCheck(e) {

   /*    this.tierForm.value.enrolled = e.target.checked;
      this.tierForm.patchValue(this.tierForm.value); */
    }
    onChangeDep(item) {
      /* if (item.coverages && item.uiControlType === 'object') {
        this.changeCoverage.emit(event);
      } else {
        this.tierChange.emit();
      } */
      this.change1.emit();
    }
    
    ngOnInit() {
     if( this.tiers[0].coverages!=null)
     {
      this.numsubcomp=this.tiers[0].coverages.length;
     }
      // tslint:disable-next-line:max-line-length
     const rate = this._rate != null && this._rate.benefitEntityPolicyDetailRates[0] != null ? this._rate.benefitEntityPolicyDetailRates[0].rate : null;
     // const  rate = null;
     this.tierForm = this.fb.group({
        'uiControlType': this.tiers[0].uiControlType,
         benefitEntityPolicyDetailId: this.tiers[0].benefitEntityPolicyDetailId,
         enrolled : false,
         rate,
         optReason: '',
         deps: [this.deps],
         beneficiaries: [this.beneficiaries],
      });
      if (this.currentEnrollment != null) {
        this.tierForm.value.enrolled = true;
        this.tierForm.patchValue(this.tierForm.value);
      }
      // Emit the form group to the father to do whatever it wishes
     
      // set up value for coveragetier
      this.tiers[0].coverages.forEach(element => {
        if (element.coverageRelationShipCodes[0] === 'E') {
            if (this.currentEnrollment != null && this.currentEnrollment.coverages != null && this.currentEnrollment.coverages.length > 0) {
             /*  if (this.currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageId === element.benefitEntityPolicyDetailCoverageId) {
                  element.coverageValue = {coverageValue: this.currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageValue};
                } */
                element.coverageValue = {coverageValue: this.currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageValue};
                // tslint:disable-next-line:max-line-length
                const f = this.currentEnrollment.coverages.find(x => x.benefitEntityPolicyDetailCoverageId === element.benefitEntityPolicyDetailCoverageId);
                  if (f != null) {
                    if (element.benefitEntityPolicyDetailCoverageId === f.benefitEntityPolicyDetailCoverageId) {
                      element.coverage = f;
                    }
                  }
            }
        } else {
          if (this.currentEnrollment != null && this.currentEnrollment.dependents != null && this.currentEnrollment.dependents.length > 0) {
            this.currentEnrollment.dependents.forEach(dep => {
              if (dep.coverages != null && dep.coverages.length > 0) {
                /* if (dep.coverages[0].benefitEntityPolicyDetailCoverageId === element.benefitEntityPolicyDetailCoverageId) {
                  element.coverageValue = {coverageValue: dep.coverages[0].benefitEntityPolicyDetailCoverageValue};
                } */
                if (dep.relationShip === element.coverageRelationShipCodes[0]) {
                  element.coverageValue = {coverageValue: dep.coverages[0].benefitEntityPolicyDetailCoverageValue};
                }
              }
            });
          }
          if (this.currentEnrollment != null) {
            // tslint:disable-next-line:max-line-length
            const f = this.currentEnrollment.coverages.find(x => x.benefitEntityPolicyDetailCoverageId === element.benefitEntityPolicyDetailCoverageId);
            if (f != null) {
              if (element.benefitEntityPolicyDetailCoverageId === f.benefitEntityPolicyDetailCoverageId) {
                element.coverage = f;
              }
            }
          }
        }

      });
      this.formReady.emit(this.tierForm);
      /* const url = 'Enrollment/EntityCodesLookup/' + this.EntityId + '/EnrollmentTerminationCode'  ;
      const observ$ = this.clientService.getWithUrl(url, { }).pipe(untilComponentDestroyed(this));
      observ$.subscribe((data) => {
          this.dropReason = data;
      }); */
    }
    onChange(event, item) {
      if (this.inTier == null) {

      } else {
        if (typeof event === 'string') {
         const obj = {id: item.benefitEntityPolicyDetailCoverageId, value: event};
          this.change1.emit(obj);
        }
      }


    }
    formInitialized(name: string, form: UntypedFormGroup) {

      setTimeout(() => {
        this.tierForm.setControl(name, form);
        if(this.inTier=='true'){
          this.firednumsubcomp++;
          if(this.numsubcomp==this.firednumsubcomp){
            this.change1.emit(null);
          }
         
        }
        
      });

    }
    getRate(item) {
      const rate = this.tierForm.value[item.benefitEntityPolicyDetailCoverageId].rate;
      return rate;
    }
    checkClick($event, item) {
      this.tierForm.value['coverages' + item.benefitEntityPolicyDetailCoverageId].selected = $event.target.checked;
      this.tierForm.patchValue(this.tierForm.value);
      // query rate here
      const coverages: any = [];
      for (const  property in this.tierForm.value) {
        if ( property.startsWith('coverages')) {
          const coverage = this.tierForm.value[property];
          if (coverage.selected === true) {
            coverages.push({
              id: coverage.coverage,
              coverage: coverage.coverageValue,
            });
          }

        }
      }

      this.change1.emit(coverages);

    }
    radioClick(item) {
      // tslint:disable-next-line:forin
      for (const prop in this.tierForm.value) {
        if (prop !== 'uiControlType') {
          this.tierForm.value[prop].selected = false;
        }

      }
      this.tierForm.value[item.benefitEntityPolicyDetailCoverageId].selected = true;
      this.tierForm.patchValue(this.tierForm.value);
    }
  }
