<nz-back-top></nz-back-top>
<div class="app-container">
  <br />
  <h2 class="member-function-header title">Check Assignment</h2>

  <form [formGroup]="assignGroupCheck">

    <div class="top-dropdown">
      <label for="selectEntityId">Entity ID*</label>
      <ng-select class="formcontrol" formControlName="selectEntityId" (change)="selectedEntityChange($event)"
        [(ngModel)]="selectedEntity" required>
        <ng-container *ngFor="let item of entities">
          <ng-option value='{{item.id}}'>{{item.entityName}}</ng-option>
        </ng-container>
      </ng-select>
      <span class="validation"
        *ngIf="assignGroupCheck.get('selectEntityId').touched && assignGroupCheck.get('selectEntityId').invalid"> Please
        select Entity ID</span>

      <!--  <ng-select  (change)="selectedEntityClientChange($event)"
            [(ngModel)]="selectedEntityClient">
            <ng-option *ngFor="let item of entityListWithClients" 
                    [value]='item'>{{ getEntityClientDisplayText(item) }}</ng-option>
            </ng-select> -->
    </div>

    <div class="col-12 amount-box">
      <span style="padding: 0 100px">Total Amount: {{totAmt | currency}}</span>
      <button mat-flat-button color="primary" (click)="addRow()" nzType="primary">Add New Check
      </button>
    </div>
    <nz-table nzSize="small" #editRowTable nzBordered [nzData]="listOfData" [nzHideOnSinglePage]='true'
      [nzPageSize]='200'>
      <thead>
        <tr>
          <th>Employer Code </th>
          <th>Check Amount</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of editRowTable.data">

          <td>
            <ng-container *ngIf="!editCache[data.id].edit; else employerCodeInputTpl">
              {{ data.name }}
            </ng-container>
            <ng-template #employerCodeInputTpl>
              <!-- <input class="formcontrol" formControlName="emplCodeInput" type="text" nz-size="small" nz-input [(ngModel)]="editCache[data.id].data.employerCode" />  -->
              <app-dynamic-autocomplete-client [dto]='dto' (selected)='selected($event,data.id)'>
              </app-dynamic-autocomplete-client>
            </ng-template>
          </td>

          <td>
            <ng-container *ngIf="!editCache[data.id].edit; else amountInputTpl">
              {{ data.amount }}
            </ng-container>
            <ng-template #amountInputTpl>
              <input class="formcontrol" formControlName="amountInput" type="text" nz-size="small" nz-input
                [(ngModel)]="editCache[data.id].data.amount" />
            </ng-template>
          </td>

          <td>
            <div class="editable-row-operations">
              <ng-container *ngIf="!editCache[data.id].edit; else saveTpl">
                <a (click)="startEdit(data.id)" class="PatchFont">Edit</a> &nbsp;<a nz-popconfirm
                  nzTitle="Are you sure you want to delete this?" (nzOnConfirm)="delete(data.id)"
                  class="PatchFont">Delete</a>
              </ng-container>
              <ng-template #saveTpl>
                <a (click)="saveEdit(data.id)" class="PatchFont">Save</a> &nbsp;
                <a app-popconfirm abcTitle="Are you sure you want to cancel this?" class="PatchFont"
                  (onOK)="cancelEdit(data.id)">Cancel</a>
              </ng-template>
            </div>
          </td>

        </tr>
      </tbody>
    </nz-table>
    <div class="submit-btn-box">
      <button mat-flat-button color="primary" (click)="submit()" nzType="primary"
        type="submit">Submit</button>
    </div>
  </form>
</div>