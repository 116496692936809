<app-suspense-comment   (AddSuspensecomment)="addSuspenseComment()" (moveSuspense)="moveSuspense($event)"  [suspenseComments]='suspenseComments'></app-suspense-comment>

<div  *ngIf="dates != null && dates.length >0"  style="width:100%;border:1px solid rgb(182, 182, 182);padding: 15px; ">
<div  *ngIf="needSelectDate===true">
        Select Paydate
        <select  (change)="onChange($event)" [(ngModel)]="selectedDate">
                <option></option>
                <option *ngFor="let opt of dates" [value]="opt">{{opt | date}}</option>
        </select>
        <br />	<br />
</div>
<br/>
 <nz-table   nzSize="small" [nzShowPagination]="false" #basicTable [nzData]="Benefit">
     <thead>
          <tr>
              <th  nzWidth="432px">Benefit Name</th>
              <th style="text-align:right">Outstanding</th>
              <th style="text-align:right">
                <span style="text-align:left;">Total Amount: <b>{{totalBillAmount | currency}}</b>&nbsp;&nbsp;</span>
                <i style="cursor: pointer" 
                    class="fa fa-floppy-o PatchFont-toppadding" 
                    aria-hidden="true" 
                    nz-popconfirm 
                    nzPopconfirmTitle="Are you sure you want to save  ?"
                    (nzOnConfirm)='onApplyAll()'>
                </i>&nbsp;Save All</th>
          </tr>
     </thead>
     <tbody>
        <ng-template ngFor let-data3 [ngForOf]="basicTable.data">
            <tr >
                <td nzWidth="40%">{{ data3.BenefitName }}</td>
                <td style="text-align:right" nzWidth="30%">{{ data3.OutStanding | currency }}</td>
                <td nzWidth="30%" >
                    <div style="float:right">
                        <label nz-checkbox [(ngModel)]="data3.WriteOff">WriteOff</label>
                        <nz-input-number 
                            [nzFormatter]="formatterDollar" 
                            [nzParser]="parserDollar" style="width:80px;" 
                            [(ngModel)]="data3.Amount"></nz-input-number>&nbsp;&nbsp;
                            <i  nz-popconfirm nzPopconfirmTitle="Are you sure you want to save  ?"
                                (nzOnConfirm)='onApply(data3)' style="cursor: pointer" 
                                class="fa fa-floppy-o  PatchFont-toppadding" 
                                aria-hidden="true">
                            </i>&nbsp;&nbsp;
                    </div>
                </td>
            </tr>
         </ng-template>
     </tbody>
  </nz-table>
</div>
