<nz-back-top></nz-back-top>
<div class="row justify-content-center col-12" style="padding: 20px">
  <div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
    <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
                height:200px; 
                position: fixed; 
                
                top: 50%; 
                left: 50%; 
                margin-top: -100px; 
                margin-left: -100px;"></nz-spin>
    <span class="default-font-Color-dark">Loading ...</span>
  </div>
  <div style="position: fixed;right:10px;top:127px;">
    Campaign Code: <input class="form-control" [(ngModel)]="campaignCode" />
  </div>
  <div class="col-lg-8 col-md-9 col-sm-12 col-xs-12">
    <br />
    <div>
      <h3 class="row col-12" style="margin: 0px;font-weight: 400;padding: 0px">Quick Enrollment</h3>
      <br />
      <div class="row" style="margin: 0px">
        <div style="width: 45%; padding-right: 10px">
          <ng-select (change)="selectedEntityClientChange($event)" [(ngModel)]="selectedEntityClient">
            <ng-option *ngFor="let item of entityListWithClients" [value]='item'>{{
              getEntityClientDisplayText(item) }}</ng-option>
          </ng-select>
        </div>
        <div style="width: 45%; padding-right: 10px">
          <ng-select (change)="selectedBenefitChange($event)" [(ngModel)]="selectedBenefit">
            <ng-option *ngFor="let item of BenefitsArray" [value]='item'>
              {{item.benefitEntityDescription +' - ' +item.vendorName }}
            </ng-option>
          </ng-select>
        </div>
        <button style="width: 10%" mat-flat-button color="primary" (click)="onSearch()"><i
            nz-icon [nzType]="'search'"></i>Search</button>
      </div>
      <div *ngIf="firstName != null" class="col-12 row" style="margin: 0px 0px 15px 0px;padding: 15px 0px 0px 0px; ">
        <br /><br />
        <div class="col-12 row app-form-group-wrapper"
          style="background:rgba(16, 141, 148, 0.03);  margin: 0px;padding: 0px;">
          <h5 class="col-12" style="padding: 0px">Member Information</h5><br>
          <h6 style="line-height: 25px;font-weight: 300;margin:0px">
            Name:&nbsp;<b>{{firstName}} {{lastName}}</b>&nbsp;&nbsp; <br />
            <span *ngIf="searchResult?.externalId != null">Member Id:&nbsp;
              <b>{{searchResult?.externalId }}</b>&nbsp;&nbsp;<br /> </span>
            <span *ngIf="searchResult?.ssNumber != null">SS Number:&nbsp;
              <b>{{searchResult?.ssNumber }}</b>&nbsp;&nbsp;<br /> </span>
            <span *ngIf="searchResult?.gender != null">Gender:&nbsp;
              <b>{{searchResult?.gender}}</b>&nbsp;&nbsp;<br /> </span>
            <span *ngIf="searchResult?.birthDate != null">Birth Date:&nbsp;
              <b>{{searchResult?.birthDate | date}}</b>&nbsp;&nbsp;<br />
            </span>
            <span *ngIf="searchResult?.payFrequency != null">Pay Frequency:&nbsp;
              <b>{{searchResult?.payFrequency}}</b>&nbsp;&nbsp;<br /> </span>
            <span *ngIf="searchResult?.employmentStatus != null">Employement
              Status:&nbsp;
              <b>{{searchResult?.employmentStatus}}</b>&nbsp;&nbsp;<br />
            </span>
            <span *ngIf="searchResult?.phone != null">Phone:&nbsp;
              <b>{{searchResult?.phone}}</b>&nbsp;&nbsp;<br /> </span>
            <span *ngIf="searchResult?.email != null">Email:&nbsp;
              <b>{{searchResult?.email }}</b><br /></span>
            <span
              *ngIf="searchResult?.address1 != null">Address:&nbsp;<b>{{searchResult?.address1}}</b>&nbsp;&nbsp;</span>
            <span *ngIf="searchResult?.city != null">City:&nbsp;<b>{{searchResult?.city}}</b>&nbsp;&nbsp;</span>
            <span *ngIf="searchResult?.state != null">State:&nbsp;<b>{{searchResult?.state}}</b>&nbsp;&nbsp;</span>
            <span *ngIf="searchResult?.zip != null">Zip:&nbsp;<b>{{searchResult?.zip}}</b>
            </span>
          </h6>
          <u><a class="PatchFont" (click)="edit()">Edit</a></u>
          <br />
        </div>
        <br />
        <div class="col-12" style="padding: 0px;" *ngIf="searchResult?.payFrequency != null ">
          <div class="row col-12  app-form-group-wrapper" style="margin: 15px 0px 0px 0px !important;padding: 0px">
            <div class="col-12" *ngFor="let item of selectedArray">
              <app-member-plans-benefitentity *ngIf='selectedBenefit!=null' [memberProfile]="searchResult"
                [entityClient]='selectedEntityClient' [benefitEntity]="selectedBenefit" [participantId]='participantId'
                [campaignCode]='campaignCode' (loadingComplete)='onLoadingComplete($event)'
                (formReady)="formInitialized(item.benefitEntityId, $event)">
              </app-member-plans-benefitentity>
            </div>
          </div>
          <!-- total: {{band.subCost()}} -->
          <div class="col-12" style="margin: 15px 0px;padding:0px;text-align: right">
            <button mat-flat-button color="primary">Cancel</button>&nbsp;&nbsp;
            <button mat-flat-button color="primary" nz-popconfirm
              nzPopconfirmTitle="Are you sure you want to save?" (nzOnConfirm)="onConfirm()">Confirm Enrollment</button>
            <div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
              <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
                                                height:200px; 
                                                position: fixed;
                                                top: 50%; 
                                                left: 50%; 
                                                margin-top: -100px; 
                                                margin-left: -100px;"></nz-spin>
              <span class="default-font-Color-dark">Loading ...</span>
            </div>
          </div>
        </div>
        <div class="blurredBox col-12" style="padding: 20px" *ngIf="searchResult?.payFrequency == null">
          <h5 style="color:red;">Pay Frequency is required to Enroll in Benefits, Please click on Edit and update the details.</h5>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <pre>
  {{entityForm.value | json}}
</pre> -->