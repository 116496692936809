<ng-container [formGroup]="phoneFormGroup">
    <div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-left-padding">
        <label>Phone:
          <input type="text"
                 class="form-control"
                 formControlName="phoneNumber">
        </label>
      </div>

    </div>
  </ng-container>
