import { Action } from '@ngrx/store';
import { AuthRequestDTO, AuthSuccessDTO } from '@models/auth';
import { LocalStorageService } from '@shared/storage/local-storage.service';
export enum AuthActionTypes {
  LOGIN = '[Auth] LOGIN',
  LOGOUT = '[Auth] LOGOUT',
  LOGIN_SUCCESS = '[Auth] LOGIN_SUCCESS',
  LOGIN_FAIL = '[Auth] LOGIN_FAIL'
}

export class LoginAction implements Action {
  readonly type = AuthActionTypes.LOGIN;
  constructor(public payload: { request: AuthRequestDTO }) {}
}

export class LogoutAction implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export class LoginSuccessAction implements Action {

  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public payload: { result: AuthSuccessDTO }) {
    /* if (payload.result.username === 'texasmedical') {
      payload.result = {...payload.result, entityList: [{entityId: '9808f856-f047-4843-bec8-277e414fc649', entityName: 'Texas Medical'}]};
    } */

  }
}

export class LoginFailAction implements Action {
  readonly type = AuthActionTypes.LOGIN_FAIL;
  constructor(public payload: { error: any }) {}
}

export type AllAuthActions =
  | LoginAction
  | LogoutAction
  | LoginSuccessAction
  | LoginFailAction;
