<div [formGroup]='tierForm'>
  <ng-container *ngIf="tiers[0].uiControlType==='radio'">
    <nz-radio-group formControlName="tier" [nzButtonStyle]="'solid'" class="col-12">
      <div *ngFor="let item of tiers[0].coverages; ">
        <label (click)='radioClick(item)' nz-radio nzValue={{item.benefitEntityPolicyDetailCoverageId}}></label>
        <span class="PlanText">{{item.benefitEntityPolicyDetailCoverageName}} <span
            style="font-size: 14px; color: rgb(155, 155, 155)"></span></span>&nbsp;<span
          style="font-size: 14px; color: rgb(155, 155, 155)">cost:</span><span style="font-size: 20px; color:green">
          {{getRate(item) | currency}}
        </span>
        <div style="padding-left: 40px " [hidden]='!isSelected(item.benefitEntityPolicyDetailCoverageId)'>
          <app-member-eiscoverage [isSelected]='isSelected(item.benefitEntityPolicyDetailCoverageId)'
            [eisDetails]='item.eisDetails' [coverages]='item.childCoverages'
            [enrollmentCoverageObject]='item.enrollmentCoverageObject'
            [benefitEntityPolicyDetailCoverageId]='item.benefitEntityPolicyDetailCoverageId' [coverageValue]='coverage'
            [benefitEntityPolicy]='benefitEntityPolicy'
            [benefitEntityPolicyDetailId]='tiers[0].benefitEntityPolicyDetailId'
            [name]='item.benefitEntityPolicyDetailCoverageName' [inTier]='inTier' [entityId]='EntityId'
            [clientId]='ClientId' [memberId]='MemberId' [uiControlText]='item.uiControlText'
            (formReady)="formInitialized(item.benefitEntityPolicyDetailCoverageId, $event)"
            (changeValue)="onChange($event, item)">
          </app-member-eiscoverage>
        </div>
      </div>
    </nz-radio-group>
  </ng-container>
  <ng-container *ngIf="tiers[0].uiControlType!=='radio'">
    <div *ngFor="let item of tiers[0].coverages; ">
      <input type="checkbox" checked *ngIf="tiers[0].uiControlType==='checkbox' && checked(item)===true"
        (change)="checkClick($event,item)" />
      <input type="checkbox" *ngIf="tiers[0].uiControlType==='checkbox' && checked(item)===false"
        (change)="checkClick($event,item)" />
      <span class="PlanText">{{item.benefitEntityPolicyDetailCoverageName}} </span>

      <app-member-coverage-runtime
        (formReady)="formInitialized('coverages'+item.benefitEntityPolicyDetailCoverageId, $event)" [coverages]='item'
        (changeCov)='onChangeCov($event)' (changeCovInit)='onChangeCovInit($event)' [coverageValue]='item.coverageValue'
        *ngIf="item.enrollmentCoverageObjectType==='EnrollmentRuntimeCoverageObject'">
      </app-member-coverage-runtime>

      <app-dependentbeneficiaries [tierName]='item.tierName' [entityId]='EntityId' [clientId]='ClientId'
        [memberId]='MemberId' [enrolled]='deps' [dependentRules]='item.dependentRules'
        [benefitEntityPolicy]='benefitEntityPolicy' (changeDep)='onChangeDep(item)' [beneficiaries]='beneficiaries'>
      </app-dependentbeneficiaries>

      <app-member-coveragetier [coverages]='item.childCoverages'
        [enrollmentCoverageObject]='item.enrollmentCoverageObject'
        [benefitEntityPolicyDetailCoverageId]='item.benefitEntityPolicyDetailCoverageId'
        [benefitEntityPolicyDetailCoverageValue]='item.benefitEntityPolicyDetailCoverageValue'
        [coverageValue]='item.coverageValue' [benefitEntityPolicy]='benefitEntityPolicy'
        [benefitEntityPolicyDetailId]='tiers[0].benefitEntityPolicyDetailId'
        [name]='item.benefitEntityPolicyDetailCoverageName' [inTier]='inTier' [entityId]='EntityId'
        [clientId]='ClientId' [memberId]='MemberId' [entityClientId]='EntityClientId'
        [coverageRelationShipCodes]='item.coverageRelationShipCodes' [initChecked]='checked(item)'
        (formReady)="formInitialized('coverages'+item.benefitEntityPolicyDetailCoverageId, $event)"
        (change)='onChange($event, item)' *ngIf="item.enrollmentCoverageObjectType!=='EnrollmentRuntimeCoverageObject'">
      </app-member-coveragetier>

    </div>
  </ng-container>
  <br />
  <span *ngIf="_rate?.benefitEntityPolicyDetailRates[0]?.rate != null"
    style="font-size: 14px; color: rgb(155, 155, 155)">{{_rate?.payFrequency}} Cost:
  </span>
  <span style="font-size: 16px; color:green">{{_rate?.benefitEntityPolicyDetailRates[0]?.rate | currency}}
  </span>
  <br />
  <app-member-eisdetail [eisDetails]='_eisDetails' (changeValue)="onChange($event)"
    (formReady)="formInitialized('eisDetail', $event)" [eisDetailsVal]='currentEnrollment?.eisDetails'
    *ngIf='_eisDetails!=null'>
  </app-member-eisdetail>
  <div style="padding-top:15px;">
    <input type="checkbox" formControlName="enrolled">
    <span *ngIf="tierForm.value.enrolled" style="color:green; font-size: 18px"> Enrolled in the plan, Uncheck to Opt-out
      of the plan</span>
    <ng-container *ngIf="tierForm.value.enrolled == false">
      <span *ngIf="tierForm.value.enrolled == false" style="color:black; font-size: 18px;font-weight: 400">
        Please click here to add plan to shopping cart
      </span>
      <br /> Reason: <select class="form-control  input-sm col-3" formControlName="optReason">
        <option></option>
        <option *ngFor="let item of dropReason; " [value]="item.codeLookupId">{{item.name}}</option>
      </select>
    </ng-container>
  </div>
</div>