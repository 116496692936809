<form class="example-form" [formGroup]='usersForm'>
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Choose a member" [matAutocomplete]="auto" formControlName='userInput'>
    </mat-form-field>
    
  
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='optionSelected($event)'>
         <mat-option *ngIf="isLoading" class="is-loading"><mat-spinner diameter="20"></mat-spinner></mat-option>
         <ng-container *ngIf="!isLoading">
            <mat-option *ngFor="let user of filteredUsers?.participantList" [value]="user">
            
              <small>{{ user.firstName  + ' ' + user.lastName }}</small>
         
            </mat-option>
        </ng-container>
    </mat-autocomplete>
  </form>
