import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { MemberService } from '@services/client/member.service';
import { ActivatedRoute } from '@angular/router';
import {   Observable  } from 'rxjs';
import { ClientService } from '@services/client/client.service';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
@Component({
    selector: 'app-member-coveragetier',
    templateUrl: './coveragetier.component.html',
    styleUrls: ['./../dynamic.component.scss']
  })
  export class CoverageTierComponent extends BaseComponent implements OnInit {
    @Input()
    initChecked;
    @Input()
    coverageRelationShipCodes;

    @Input()
    set entityId(entityId) {
      if (entityId != null) {
        this.EntityId = entityId;
      }

    }
    @Input()
    set clientId(clientId) {
      if (clientId != null) {
        this.ClientId = clientId;
      }

    }
    @Input()
    set memberId(memberId) {
      if (memberId != null) {
        this.MemberId = memberId;
      }

    }
    @Input()
    set entityClientId(entityClientId) {
      if (entityClientId != null) {
        this.EntityClientId = entityClientId;
      }
    }
    @Input()
    benefitEntityPolicyDetailCoverageId;
    @Input()
    benefitEntityPolicyDetailCoverageValue;
    @Input()
    inTier;
    @Input()
    benefitEntityPolicyDetailId;
    @Input()
    name;
    @Input()
    coverages;
    @Input()
    benefitEntityPolicy;
    @Input()
    set enrollmentCoverageObject(enrollmentCoverageObject) {
      if (enrollmentCoverageObject) {
        const json = JSON.parse((<any>enrollmentCoverageObject) as string);
        const rows: any = [];
        let from = json['from'];
        const to = json.to;
        const step = json.step;
        while (from <= to) {
          // tslint:disable-next-line:max-line-length
          const obj = {benefitEntityPolicyDetailCoverageId: from, benefitEntityPolicyDetailCoverageValue: from, benefitEntityPolicyDetailCoverageName: from};
          rows.push(obj);
          from = from + step;
        }
        this.coverages = rows;
      }
    }
    @Input()
    set coverageValue(coverageValue) {
      this._coverageValue = coverageValue;
      if (coverageValue) {
        this.coverage = coverageValue['coverage'];
        if (this.coverage != null && this.coverage !== 0) {
          this.coverage = this.coverage.toString();
          if (this.coverageForm != null) {
            this.coverageForm.value['coverage'] = this.coverage;
            this.coverageForm.patchValue(this.coverageForm.value);
            this.changeCommon(this.coverage);
          }

        }
      }
    }

    _coverageValue;
    coverage;
    @Output() formReady = new EventEmitter<UntypedFormGroup>();
    @Output() change = new EventEmitter<number>();
    coverageForm: UntypedFormGroup;
    EntityId: string; coverageVal;
    ClientId: string; EntityClientId;
    MemberId: string; rate$: Observable<any>; ratemessage;
    constructor(protected clientService: ClientService, private routeHelper: RouteHelperService,
        private route: ActivatedRoute, private memberService: MemberService, private fb: UntypedFormBuilder) {
      super();
      this.EntityId = this.routeHelper.getEntityId(this.route);
     // this.ClientId = this.routeHelper.getClientId(this.route);
      this.MemberId = this.routeHelper.getMemberId(this.route);
      this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
    }

    changeCommon(amt) {
      if (amt === '') {
        this.coverageForm.value['rate'] = '';
        this.coverageForm.value['name'] = this.name;
        this.coverageForm.patchValue(this.coverageForm.value);
        return;
      }
      if (this.inTier != null) {
        return;
      } else {
        const coverages: any = [];
        if (amt != null) {
          // tslint:disable-next-line:max-line-length
          coverages.push({ benefitEntityPolicyDetailCoverageId: amt});
          const dto = {
            'clientId': this.ClientId,
            'entityId' : this.EntityId,
            'participantId' : this.MemberId,
            'benefitEntityId' : this.benefitEntityPolicy.benefitEntityId,
            'benefitEntityPolicyId': this.benefitEntityPolicy.benefitEntityPolicyId,
            'benefitEntityPolicyDetailId': this.benefitEntityPolicyDetailId,
            'effectiveDate': '05/10/2019',
            'appDate': '05/10/2019',
            'Coverages' : coverages,
        };

        this.rate$ = this.clientService.postWithUrl({url: 'Enrollment/BenefitEntityPolicyDetailsRate', dto}).pipe(untilComponentDestroyed(this));
        this.rate$.subscribe(data => {
          if (data.benefitEntityPolicyDetailRates.length > 0) {
            this.coverageForm.value['rate'] = data.benefitEntityPolicyDetailRates[0].rate;
            this.coverageForm.value['name'] = this.name;
            this.coverageForm.patchValue(this.coverageForm.value);
            this.ratemessage = '';
          } else {
            this.ratemessage = 'Technical issue in Fetching the Rate. Please contact dev team.';
          }
        });
        }
      }
    }

    onChange(event) {
        if (!event) {
            return ;
          }
          const amt = event.target.value;
          // const result = this.coverages.find(item => item.benefitEntityPolicyDetailCoverageId === amt);
          this.changeCommon(amt);
          this.change.emit(amt);
    }

    ngOnInit() {
      if ( this.coverages.length === 0) {
        this.coverage = this.benefitEntityPolicyDetailCoverageId;
        this.coverageVal = this.benefitEntityPolicyDetailCoverageValue;
      } else {
        if (this.coverageValue) {
          this.coverage = this.coverageValue['coverage'];
        }
      }

      this.coverageForm = this.fb.group({
        coverage: this.coverage,
        coverageValue : this.coverageVal,
        rate: '',
        name: name,
        selected: this.initChecked,
        coverageRelationShipCodes: this.coverageRelationShipCodes
      });

      // Emit the form group to the father to do whatever it wishes
      this.formReady.emit(this.coverageForm);
      }
}
