<div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
  <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
        height:200px; 
        position: fixed;   
        top: 50%; 
        left: 50%; 
        margin-top: -100px; 
        margin-left: -100px;"></nz-spin>
  <span class="default-font-Color-dark">Loading ...</span>
</div>
<div class="row col-12 default-border onHoverChange" *ngIf="policies != null && policies.length > 0"
  style="margin-top:1px;padding:20px;margin-left:0px">
  <div class="row col-12" style="margin:0px;padding:0px">
    <div class="col-6" style="padding:0px">
      <h5 style="font-weight:400;" class="default-font-Color-dark">
        {{_benefitEntity.benefitEntityDescription}}
        <span *ngIf="checkEnrolled() === false">
          <span *ngIf="(memberProfile.isOrphaned == null || memberProfile.isOrphaned == false)"
            style="color: red; border-radius: 5px; font-size:14px !important;border: 1px solid rgb(255, 0, 0);display: inline-block;padding: 1px;  ">&nbsp;Not
            Enrolled&nbsp;
          </span>
        </span>
        <br />
        <span class="member-function-header" style="color: gray; font-size : 14px !important">
          <i class="fa fa-handshake-o" style=" padding: 3px; border-radius:18px;font-size: 12px; "
            aria-hidden="true"></i>
          &nbsp;{{_benefitEntity.vendorName}}</span>
      </h5>
    </div>
    <div class="pull-right col-6" style="text-align: right;padding-right:0px">
      <div>
        <div style="margin-left:10px">
          <span
            *ngIf="checkCurrentEnrolled() === true && EntityId!=='ac586073-69d6-41bf-875c-eb754f750457' && EntityId!=='f9d5f5e4-9e28-4a23-9cc3-9bb9d81d15e4'">
            <button title="Waiver" (click)="onWaiver()" class="btn btn-sm YellowButton">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;{{waiverTitle()}}
            </button>&nbsp;
          </span>
          <button
            *ngIf="EntityId!=='d6a2ff7e-bba0-48fa-9396-a6b202631669' && EntityId !== '9808f856-f047-4843-bec8-277e414fc649' && (memberProfile.isOrphaned == null || memberProfile.isOrphaned == false)"
            title="Pending" (click)="onPending()" class="btn btn-sm YellowButton">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;Pending
          </button>&nbsp;
          <button
            *ngIf="(memberProfile.isOrphaned == null || memberProfile.isOrphaned == false) && EntityId!=='d6a2ff7e-bba0-48fa-9396-a6b202631669' && EntityId !== '9808f856-f047-4843-bec8-277e414fc649'"
            title="Decline" (click)="onDecline()" class="btn btn-sm RedButton">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;Decline
          </button>&nbsp;
          <div style="display: inline" *ngIf='_initExpand==null'>
            <button *ngIf="state==='close' && checkEnrolled() === true" title="Modify" (click)="onClick()"
              class="btn btn-sm GrayButton">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;Modify
            </button>
            <button
              *ngIf="state==='close' && checkEnrolled() === false && (memberProfile.isOrphaned == null || memberProfile.isOrphaned == false)"
              title="Enroll" (click)="onClick()" class="btn btn-sm GrayButton">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;Enroll
            </button>
            <button *ngIf="state==='expand'" style="width:35px;margin-right:5px" (click)="onClick()"
              class="btn btn-sm GrayButton">
              <i class="fa fa-reply" aria-hidden="true"></i>
            </button>
            <span *ngIf="state==='close'">&nbsp;</span>
            <!-- <button   (click)="onClick()"  style="width:35px" class="btn btn-sm GreenButton">
            <i class="fa fa-phone" aria-hidden="true"></i>
            </button> <span>&nbsp;</span>  -->
            <button *ngIf="EntityId=='68bc62e6-dd96-4428-91e8-efe285bd23a0' "
              (click)="onInfoClick(_benefitEntity.benefitEntityDescription)" style="width:35px"
              class="btn btn-sm BlueButton">
              <i class="fa fa-info" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span *ngIf="memberProfile.isOrphaned == true &&  checkEnrolled() === false" style="color:red">Member is orphaned and
    not eligible to enroll any new plan.</span>
    
  <div *ngFor="let item of policies; index as i" class="col-12" style="padding: 0px">
    <ng-container *ngIf='item.benefitEntityId===_benefitEntity.benefitEntityId'>

      <span [hidden]="state==='expand'" class="PlanText">
        <div *ngIf="check(item) ||( item.underWriting!=null && item.underWriting.length > 0)">
          <span style="font-weight:400;font-size: 16px;background:#108d949d;border-radius: 3px;color: white;padding:0px 5px 3px 5px">{{item.benefitEntityPolicyDescription}}
          <span *ngIf="EntityId!=='3b7805a6-10fa-48b5-b0ee-ea5365258341'">- {{item.benefitEntityPolicyCode}}</span></span>
        </div>
        <!-- <div  *ngIf="checkEnrolled() === false"><span style="font-weight:400;">{{item.benefitEntityPolicyDescription}}</span></div> -->
        <ng-container *ngIf="check(item) as enrolledPolicy" class="row">
          <span
            *ngIf='EntityId=="d6a2ff7e-bba0-48fa-9396-a6b202631669" && item.benefitEntityPolicySubCode!=null && item.benefitEntityPolicySubCode.trim()!==""'>
            <span> Benefit Code: </span>{{item.benefitEntityPolicySubCode}}</span>
          <span
            *ngIf='EntityId!=="d6a2ff7e-bba0-48fa-9396-a6b202631669" && item.benefitEntityPolicySubCode!=null && item.benefitEntityPolicySubCode.trim()!==""'>
            <span>COV Code: </span>{{item.benefitEntityPolicySubCode}}</span>
          <div class="row no-gutter" style="margin-top:5px" *ngIf="enrolledPolicy.tiers!=null">
            <div class="col-4">
              <span class="Summary_Lable">Cost: </span> {{enrolledPolicy.tiers?.rate | currency:'USD':true:'0.2-3'}}<br>
              <span class="Summary_Lable">Tier: </span> {{enrolledPolicy.tiers.tierName}}<br>
              <div *ngIf="enrolledPolicy.tiers.level!=null">
                <span class="Summary_Lable">Level: </span> {{getLevel(enrolledPolicy.tiers.level)}}<br>
              </div>

              <span class="Summary_Lable" *ngIf="item.currentEnrollment != null">Last Enrollment Date:</span>
              {{item.currentEnrollment?.latestEnrollmentDateTime | date }}<br>
              <span class="Summary_Lable">Latest Effective Date: </span>
              {{item.currentEnrollment?.latestEnrollmentEffectiveDate | date}} <br />
            </div>
            <div class="col-4">
              <span *ngIf="enrolledPolicy?.coverages?.coverage != null && enrolledPolicy?.coverages?.coverage != 0"> <span
                  class="Summary_Lable">Coverage: </span> {{enrolledPolicy?.coverages.coverageValue | currency
                }}</span><br>
              <span *ngIf="enrolledPolicy?.healthPlanOptionVal!= null "> <span
                  class="Summary_Lable">HealthPlanOptionVal: </span> {{enrolledPolicy?.healthPlanOptionVal }}</span><br>

              <span
                *ngIf="item.currentEnrollment != null && item.currentEnrollment.originalIssueState != null && item.currentEnrollment.originalIssueState != ''">
                <span class="Summary_Lable">Issue State:</span> {{item.currentEnrollment.originalIssueState}}<br></span>
              <span
                *ngIf="item.currentEnrollment != null && item.currentEnrollment.originalEnrollmentEffectiveDate != null">
                <span class="Summary_Lable">Original Effective Date:</span>
                {{item.currentEnrollment.originalEnrollmentEffectiveDate | date}}<br></span>

            </div>
          </div>
          <div class="row no-gutter" style="margin-top:5px" *ngIf="enrolledPolicy.tiercoverage!=null">
            <div class="col-4">
              <span class="Summary_Lable">Cost: </span> {{enrolledPolicy.tiercoverage?.rate | currency}}<br>
              <span
                *ngIf="item.currentEnrollment != null && item.currentEnrollment.coverages != null && item.currentEnrollment.coverages.length > 0"
                class="Summary_Lable">Coverage Amount: </span>
              {{item.currentEnrollment?.coverages[0]?.benefitEntityPolicyDetailCoverageValue | currency }}<br>
              <span
                *ngIf="item.currentEnrollment != null && item.currentEnrollment.coverages != null && item.currentEnrollment.coverages.length > 0 && item.currentEnrollment.coverages[0].coverageCode!=null "
                class="Summary_Lable">Coverage Code: {{item.currentEnrollment?.coverages[0]?.coverageCode }}<br></span>
              <span
                *ngIf="item.currentEnrollment != null && item.currentEnrollment.coverages != null && item.currentEnrollment.coverages.length > 0 && item.currentEnrollment?.coverages[0].packageCode!=null "
                class="Summary_Lable">Package Code: </span> {{item.currentEnrollment?.coverages[0]?.packageCode }}<br>
              <span
                *ngIf="item.currentEnrollment != null && item.currentEnrollment.coverages != null && item.currentEnrollment.coverages.length > 0 && item.currentEnrollment?.coverages[0].classCode!=null "
                class="Summary_Lable">Class Code: </span> {{item.currentEnrollment?.coverages[0]?.classCode }}<br>
              <span
                *ngIf="item.currentEnrollment != null && item.currentEnrollment.coverages != null && item.currentEnrollment.coverages.length > 0"
                class="Summary_Lable">Original Effective Date: </span>
              {{item.currentEnrollment?.originalEnrollmentEffectiveDate | date}}<br>

            </div>
          </div>
          <hr />
        </ng-container>

      </span>

      <div [hidden]="state==='close'" class="row" style="margin: 0px">
        <div class="col-10" *ngIf="EntityId=='d6a2ff7e-bba0-48fa-9396-a6b202631669'" style="padding:10px 0px 0px 0px;">
          <span
            style="font-weight:400;font-size: 16px;background:#108d949d;border-radius: 3px;color: white;padding:0px 5px 3px 5px"> <a  style="padding: 10px;color: white;" data-toggle="collapse"
            href="#b{{index}}a{{i}}"  >{{item.benefitEntityPolicyDescription}}</a>
            <span
              *ngIf="EntityId=='d6a2ff7e-bba0-48fa-9396-a6b202631669'">-{{item.benefitEntityPolicyCode}}</span>&nbsp;</span>
          <span *ngIf='item.benefitEntityPolicySubCode!=null  && item.benefitEntityPolicySubCode.trim()!==""'
            style="font-weight:400;font-size: 16px;">Benefit Code: {{item.benefitEntityPolicySubCode}}</span>

        </div>
        <div class="col-10" *ngIf="EntityId!=='d6a2ff7e-bba0-48fa-9396-a6b202631669'" style="padding:10px 0px 0px 0px;">
          <span
            style="font-weight:400;font-size: 16px;background:#108d949d;border-radius: 3px;color: white;padding:0px 5px 3px 5px">
            <a  style="padding: 10px;color: white;" data-toggle="collapse"
            href="#b{{index}}a{{i}}"  >{{item.benefitEntityPolicyDescription}}</a>
            <span
              *ngIf="EntityId!=='d6a2ff7e-bba0-48fa-9396-a6b202631669' && EntityId!=='3b7805a6-10fa-48b5-b0ee-ea5365258341'">-{{item.benefitEntityPolicyCode}}</span>&nbsp;</span>
            <span *ngIf='item.benefitEntityPolicySubCode!=null  && item.benefitEntityPolicySubCode.trim()!==""'
            style="font-weight:400;font-size: 16px;">
            <span *ngIf='EntityId!=="ac586073-69d6-41bf-875c-eb754f750457"'> COV Code:</span>
            {{item.benefitEntityPolicySubCode}}</span>
        </div>
      </div>
      <!-- item.effectiveDate -->

     
      <div class="collapse " id="b{{index}}a{{i}}"  *ngIf='item.enrolled==false'>
        
        <br [hidden]="state==='close'" /><br [hidden]="state==='close'" />
        <div [hidden]="state==='close'" style="padding-bottom:25px;margin-top: -20px">
          <br/>
          <app-member-plan [dropReason]='dropReason' [memberProfile]="memberProfile" [entityId]='EntityId'
            [clientId]='ClientId' [memberId]='MemberId' [entityClientId]='entityclientId' [benefitEntityPolicy]='item'
            *ngIf="item.isEISEnabled===false && checkAccess(item, 'all')" [underWritingVal]='item.underWritingId'
            (reloadUW)="loadUnderWriting()" [currentEnrollment]='item.currentEnrollment' [campaignCode]='campaignCode'
            [initFormValue]='initFormValue[_benefitEntity.benefitEntityId][item.benefitEntityPolicyId]'
            (formReady)="formInitialized(item.benefitEntityPolicyId, $event)"> </app-member-plan>

          <app-member-eisplan [dropReason]='dropReason' [entityId]='EntityId' [clientId]='ClientId' [memberId]='MemberId'
            [entityClientId]='entityclientId' [benefitEntityPolicy]='item' *ngIf='item.isEISEnabled===true'
            [underWritingVal]='item.underWritingId' (formReady)="formInitialized(item.benefitEntityPolicyId, $event)"
            [currentEnrollment]='item.currentEnrollment'>

          </app-member-eisplan>
        </div>
      </div>
      <div   *ngIf='item.enrolled==true'>
        <br [hidden]="state==='close'" /><br [hidden]="state==='close'" />
        <div [hidden]="state==='close'" style="padding-bottom:25px;margin-top: -20px">
        
          <app-member-plan [dropReason]='dropReason' [memberProfile]="memberProfile" [entityId]='EntityId'
            [clientId]='ClientId' [memberId]='MemberId' [entityClientId]='entityclientId' [benefitEntityPolicy]='item'
            *ngIf="item.isEISEnabled===false && checkAccess(item, 'all')" [underWritingVal]='item.underWritingId'
            (reloadUW)="loadUnderWriting()" [currentEnrollment]='item.currentEnrollment' [campaignCode]='campaignCode'
            [initFormValue]='initFormValue[_benefitEntity.benefitEntityId][item.benefitEntityPolicyId]'
            (formReady)="formInitialized(item.benefitEntityPolicyId, $event)"> </app-member-plan>

          <app-member-eisplan [dropReason]='dropReason' [entityId]='EntityId' [clientId]='ClientId' [memberId]='MemberId'
            [entityClientId]='entityclientId' [benefitEntityPolicy]='item' *ngIf='item.isEISEnabled===true'
            [underWritingVal]='item.underWritingId' (formReady)="formInitialized(item.benefitEntityPolicyId, $event)"
            [currentEnrollment]='item.currentEnrollment'>

          </app-member-eisplan>
        </div>
      </div>
      <div *ngIf="item.UWHistory == null || item.UWHistory == false">

        <div
          *ngIf="item.underWriting!=null && item.underWriting[0] != null && item.underWriting[0].enrollmentApplicationDetails != null && item.underWriting[0].enrollmentApplicationDetails?.length > 0"
          style="margin-bottom: 15px; padding: 15px;border:1px solid rgba(16, 140, 146, 0.5);background: rgba(16, 140, 146, 0.02)">
          <!-- <div *ngFor = "let det of uw.enrollmentApplicationDetails "> -->
          <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status == 'Pending'">Last Underwriting
            Action: <span style="color: rgb(201, 127, 17)">Application Pending</span></h6>
          <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status == 'Declined'">Last Underwriting
            Action: <span style="color: red">Application Declined</span></h6>
          <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status == 'Approved'">Last Underwriting
            Action: <span style="color: green">Application Approved</span></h6>
          <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.createdDate != null">Date changed : <b
              class="default-font-Color-dark">{{ item.underWriting[0].enrollmentApplicationDetails[0].createdDate |
              date}}</b> <br /> </span>
          <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.appReceiveDate != null">Received Date: <b
              class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].appReceiveDate |
              date}}</b> <br /> </span>
          <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.responseDate != null">App Response Date :
            <b class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].responseDate |
              date}}</b> <br /> </span>
          <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status != null">Status : <b
              class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].status}}</b> <br />
          </span>
          <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.remarks != null">Remarks : <b
              class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].remarks}}</b>
            <br /> </span>
          <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.reason != null">Reason : <b
              class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].reason}}</b>
            <br /></span>
          <span
            *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.campaignCode != null && item.underWriting[0].enrollmentApplicationDetails[0]?.campaignCode != ''">Campaign
            Code : <b
              class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].campaignCode}}</b>
            <br /></span>
          <span
            *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.source != null&& item.underWriting[0].enrollmentApplicationDetails[0]?.source != ''">Source
            : <b class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].source}}</b>
            <br /> </span>
          <span
            *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.replacement != null&& item.underWriting[0].enrollmentApplicationDetails[0]?.replacement != ''">Replacement
            : <b
              class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].replacement}}</b>
            <br /> </span>
          <!-- </div> -->
          <a class="PatchFont" style="float: right"
            *ngIf="item.underWriting!=null && (item.underWriting.length > 1 || item.underWriting[0].enrollmentApplicationDetails?.length > 1)"
            (click)="item.UWHistory = true">History</a>
        </div>
      </div>

      <div
        *ngIf="item.underWriting!=null && item.underWriting.length > 0 && (item.UWHistory != null && item.UWHistory == true)"
        style="margin-bottom: 15px; padding: 15px;border:1px solid rgba(16, 140, 146, 0.5);background: rgba(16, 140, 146, 0.02)">
        <h5 style="color: rgb(16, 141, 148)">Application History</h5>
        <div *ngFor="let uw of item.underWriting">

          <div *ngFor="let det of uw.enrollmentApplicationDetails" style=" margin-right:10px;">
            <h6 *ngIf="det.status == 'Pending'" style="color: rgb(201, 127, 17)">Application Pending</h6>
            <h6 *ngIf="det.status == 'Declined'" style="color: red">Application Declined</h6>
            <h6 *ngIf="det.status == 'Approved'" style="color: green">Application Approved</h6>
            <span *ngIf="det.createdDate != null">Date Changed : <b class="default-font-Color-dark">{{det.createdDate |
                date}}</b> <br /> </span>
            <span *ngIf="det.appReceiveDate != null">Received Date: <b
                class="default-font-Color-dark">{{det.appReceiveDate | date}}</b> <br /> </span>
            <span *ngIf="det.responseDate != null"> App Response Date: <b
                class="default-font-Color-dark">{{det.responseDate | date}}</b> <br /> </span>
            <span *ngIf="det.status != null">Status : <b class="default-font-Color-dark">{{det.status}}</b> <br />
            </span>
            <span *ngIf="det.remarks != null"> Remarks : <b class="default-font-Color-dark">{{det.remarks}}</b> <br />
            </span>
            <span *ngIf="det.reason != null"> Reason : <b class="default-font-Color-dark">{{det.reason}}</b> </span>
            <span *ngIf="det.campaignCode != null"> Campaign Code : <b
                class="default-font-Color-dark">{{det.campaignCode}}</b> <br /> </span>
            <span *ngIf="det.source != null"> Source : <b class="default-font-Color-dark">{{det.source}}</b> <br />
            </span>
            <span *ngIf="det.replacement != null">Replacement : <b
                class="default-font-Color-dark">{{det.replacement}}</b> <br /> </span>
            <hr style="margin: 5px" />
          </div>
        </div>
        <a class="PatchFont" style="float: right" (click)="item.UWHistory = false">Collapse</a>
        <br />
      </div>
      <app-waiverdisp [waiver]='item.waiver' *ngIf="item.waiver != null && item.waiver.length>0">

      </app-waiverdisp>
    </ng-container>
  </div>
  <!-- <h2 class='mat-h2'>Form Value policiesForm</h2> -->
  <!-- <pre>
      {{policiesForm.value | json}}
    </pre>   -->
</div>
<div class="col-12" *ngIf="_initExpand=='expand' && subCost() > 0"
  style="text-align: right;padding: 15px;background: rgba(16, 140, 146, 0.03);border:1px solid  rgba(16, 140, 146, 0.2)">
  <span style="font-size: 24px;">Plan cost </span><span
    style="color:white;border-radius:4px;padding: 3px;background:green;font-size: 24px; ">&nbsp;{{subCost() | currency
    :'USD':true:'0.2-3'}}&nbsp;</span>
</div>