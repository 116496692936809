import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DynamicPlansContainnerComponent} from '@shared/components/dynamicplan/dynamicplancontainner.component';
import {UntypedFormGroup} from '@angular/forms';
import {SaveMemberTypeAction} from '@store/main/member/member.actions';
import {  GetMemberAction } from '@store/main/member/member.actions';
import { MEMBER_ROUTE_SEGMENT } from '@shared/route/constants';
import { MemberProfileDTO, MemberDTO } from '@models/shared/member.dto';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';

@Component({
    selector: 'app-member-plans-containernew',
    templateUrl: './dynamicplancontainnernew.component.html',
    styleUrls: ['./../dynamic.component.scss']
  })

  export class DynamicPlanContainerNewComponent extends DynamicPlansContainnerComponent implements OnInit {
    entityForm: UntypedFormGroup;
    initBenfitBandValue: any = {};
    initFormValue: any = {}; 
    paths;
    memberProfile; 
    loading = false;
    prePath: string|null;
    readonly MEMBER_ROUTE_SEGMENT = MEMBER_ROUTE_SEGMENT;
    ngOnInit() {
        this.entityForm = this.fb.group({
      });
        // loading profile
         const myurl = 'Participant/' + this.EntityId + '/' + this.ClientId + '/' + this.MemberId + '/Profile';
        const  observ$ = this.clientService.getWithUrl(myurl, {}).pipe(untilComponentDestroyed(this));
        observ$.subscribe((data) => {
          this.memberProfile = data;
        });
        const url = 'Entity/ClientProfile?entityClientId=' + this.entityclientId ;
           const observ1$ = this.clientService.getWithUrl(url, { }).pipe(untilComponentDestroyed(this));
          observ1$.subscribe((data) => {

              this.paths = [{
                name: 'Home',
                path: '/main/user-dashboard'
              },
              {
                name: data.entityName,
                path: '/main/clients/' + this.EntityId
              },
              {
                name: data.entityClientName,
                path: '/main/clients/' + this.EntityId + '/' + this.entityclientId
              },
              {
                name: 'Dashboard',
                path: '../dashboard'
              },
              {
                name: 'Enrollment',
                path: ''
              }
             ];
          });
    }
    formInitialized(name: string, form: UntypedFormGroup) {
        setTimeout(() => {
            this.entityForm.setControl(name, form);
      });
    }

    get member(): MemberDTO | undefined {
      if (this.memberState && this.memberState.member) {
        return this.memberState.member;
      }
      return;
    }

    get profile(): MemberProfileDTO | undefined {
      if (this.member && this.member.profile) {
        return this.member.profile;
      }
      return;
    }

    getRouterLink(segment: string) {
      if (this.prePath == null) {
       this.prePath = '../../../';
      }
      switch (segment) {
        case  MEMBER_ROUTE_SEGMENT.profile :
          return this.prePath + 'member/' + this.MemberId + '/' + `dynamicform/${segment}`;
      }
      return '';
    }

  onLoadingComplete(bandObj) {

      this.initBenfitBandValue = Object.assign({}, bandObj);
      const id = bandObj.benefitEntityId;
      // this.initFormValue[id] = {};
      this.enrollmentService.LoadingComplete(bandObj, this.initFormValue , id) ;
    }

    isChanged(bandid, policyId, curtform) {
      const tt = this.initFormValue[bandid][policyId];
      // check effactiveDate
      if (tt == null) {

      }
      return this.enrollmentService.isChanged(tt, curtform);
    }

    confirm(cartItems) {
      const enrollmententity = this.entityForm.value;
      this.enrollmentService.confirm(cartItems, enrollmententity, this.initFormValue);
    }

    posturl(url, cartItems, refresh) {
      const apiObj = {
        entityId: this.EntityId,
        participantId: this.MemberId,
        // clientId: this.ClientId,
        entityClientId: this.entityclientId,
        cartItems
      };
      const observ$ = this.clientService.postWithUrl({url, dto: apiObj}).pipe(untilComponentDestroyed(this));
      let error = false;
      this.loading = true;
      observ$.subscribe( data => {
        this.loading = false;
        if (data.cartItems != null) {
          data.cartItems.forEach(element => {
            if (element.messages != null && element.messages.length > 0) {
              this.notification.blank( element.messages[0].message, ' ');
              error = true;
              return;
            }
          });
        }
        if (error === false && (data.messages == null || data.messages.length === 0)) {
          this.notification.blank( ' Posted successfully', ' ');
          this.initFormValue = JSON.parse(JSON.stringify(this.entityForm.value));
          if (refresh) {
            this.store.dispatch(new GetMemberAction({EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId }));
          //}else{
          // const url1='Helper/ProcessAfterEnrollment/?entityId='+ this.EntityId+'&participantId='+this.MemberId;
          // const observ1$ = this.clientService.getWithUrl(url1, { });
          // observ1$.subscribe();
          }
        } else if ( data.messages.length !== 0 ) {
          for ( let message of data.messages ){
            this.notification.error( message.message, ' ')
          }
          this.loading = false;
        }
      }, err => {
        this.loading = false;
        this.notification.blank(' Posted failed', ' ');
        }
      );
    }

    onActivate() {
      const cartItems: any = [];
      this.confirm(cartItems);
      this.posturl('Enrollment/ActivateEnrollment', cartItems, true);
    }

    onConfirm() {
      const cartItems: any = [];
      this.confirm(cartItems);
      if (cartItems.length > 0) {
        this.posturl('Enrollment/Enrollment', cartItems, false);
      } else {
        this.notification.blank( 'There is no changes in Enrollment to Submit', ' ');
      }
    }
  }
