import { Component, OnInit, OnChanges , Input, ViewChild, Output, EventEmitter, SimpleChanges, ElementRef} from '@angular/core';
import {FormConfig} from '../model/inputcontrol';
@Component({
    selector: 'app-dynamic-printfields',
   templateUrl: './dynamic-print-fields.component.html',
 })

 export class DynamicPrintFieldsComponent implements OnInit  {
    @Input()
    formConfigs: FormConfig[];
    @Input()
  set formValue(formValue: any) {
      if (formValue) {
        this._formValue = formValue;
      }
  }
  _formValue;
    ngOnInit() {
    }
 }
