import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
  })
export class ExcelService {

  constructor() { }
   ec(r, c){
    return XLSX.utils.encode_cell({r:r,c:c});
}
 delete_row(ws, row_index){
    var variable = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < variable.e.r; ++R){
        for(var C = variable.s.c; C <= variable.e.c; ++C){
            ws[this.ec(R,C)] = ws[this.ec(R+1,C)];
        }
    }
    variable.e.r--
    ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
}
public exportAsExcelFileNoHeader(json: any[], excelFileName: string): void {

  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  
  this.delete_row(worksheet,0);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}
  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // worksheet['!cols'] = [
    //   { width: 15 },
    //   { width: 20 },
    //   { width: 25 },
    //   { width: 30 }
    // ];
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public exportAsExcelFileWithColWidth(json: any[], excelFileName: string,colw:any[]): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet['!cols'] = colw;
 
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public importExcelFile(file: File){
    var myReader: FileReader = new FileReader();
    myReader.onloadend=(e) => {  
      var  data = (<any>e.target).result;  
      
      let workbook = XLSX.read(data, {  
        type: 'binary'  
      });
      let json_object;
      workbook.SheetNames.forEach((sheetname)=>{
        let XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname]);  
        json_object = JSON.stringify(XL_row_object);  
      })  
      return  json_object;
      

    } 
    myReader.onerror = function(ex) {   
    };  
    myReader.readAsBinaryString(file);  

  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
