import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router'
import { ClientService } from '@services/client/client.service';
// import { UpdatePassword } from '../shared/interfaces';
// import { UpdatePasswordService } from '../shared/updatePassword.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup
  // entityClientId: string = '42445106-54b5-44d3-a134-a2dfa7837c17'   //LEEBA  hardcoded value
  // clientId: string = 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b'   //LEEBA ClientID hardcoded value
  entityId: string = '9808F856-F047-4843-BEC8-277E414FC649'   //TMAIT  hardcoded value
  entityClientId: string = '00000000-0000-0000-0000-000000000000'   //TMAIT ClientID hardcoded value
  userId: string
  token: string
  alertRed: boolean = false
  alertGreen: boolean = false
  alertMessage: any = ''
  config = window['___alliant-config-eniac-APP___']
  logintype = this.config.logintype
  welcomeText = this.config.welcomeText
  phoneNum = this.config.phoneNum
  primaryColor = this.config.primaryColor
  bgImage = this.config.bgImage



  constructor(
    private route: ActivatedRoute,
    protected clientService: ClientService,
    public dialog: MatDialog

  ) { }

  ngOnInit(): void {

    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', [Validators.required,
      Validators.minLength(10),
      // Validators.pattern('^[a-zA-Z0-9@#$%+*!='),
      Validators.pattern(/[A-Z]/),
      Validators.pattern(/[a-z]/),
      Validators.pattern(/[@#$%+*!=]/),
      ]),
      repeatPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
    }, { validators: this.passwordMatchValidator })

    this.entityClientId = this.route.snapshot.queryParams["clm"];
    this.userId = this.route.snapshot.queryParams["rnd"];
    this.token = this.route.snapshot.queryParams["efg"];

  }


  passwordMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const password = control.get('newPassword').value;
    const repeatedPassword = control.get('repeatPassword').value;
    if (password === repeatedPassword) {
      return null
    } else {
      return { 'mismatch': true }
    }
  }


  onSubmit() {
    if (this.changePasswordForm.valid) {
      const updatePasswordInfo = {
        currentPassword: this.changePasswordForm.value.currentPassword,
        newPassword: this.changePasswordForm.value.newPassword,
        userId: this.userId,
        token: this.token
      }

      const observ$ = this.clientService.postWithUrl({ url: `/CaseManagement/ResetPassword/${this.entityId}/${this.entityClientId}`, dto: updatePasswordInfo });
      observ$.subscribe(res => {
        if (res) {
          this.alertGreen = true
          this.alertRed = false
          this.alertMessage = 'Password has been updated successfully. You will be redirected to the login page! '
          this.openDialog()
        }
      }, err => {
        this.alertRed = true
        this.alertGreen = false
        this.alertMessage = err[0]
        this.openDialog()
      })
    } else {
      this.changePasswordForm.markAllAsTouched()
    }
  }



  openDialog() {
    this.dialog.open(DialogElementsChangePasswordPopup, {
      data: {
        alertMessage: this.alertMessage,
        alertRed: this.alertRed,
        alertGreen: this.alertGreen
      }
    });
  }
}

@Component({
  selector: 'change-pasword-popup',
  templateUrl: 'change-pasword-popup.html',
})
export class DialogElementsChangePasswordPopup {
  constructor(@Inject(MAT_DIALOG_DATA) public data) { }
}   
