<nz-back-top></nz-back-top>
<div style="padding: 0px 5%;">
  <div style=" text-align: right;margin-top:-20px;">
    <app-breadcrumb-router [paths]='paths'> </app-breadcrumb-router>
  </div>

  <div>

    <app-selected-member-header-share class="col-12" style="padding: 0px" [memberState]="memberState"
      [entityClientId]='EntityClientId'></app-selected-member-header-share>
  </div>
  <app-form-group-wrapper>

    <h2 style="margin-top: -15px;" class="member-function-header">Benefit Summary (<a [routerLink]="['../benefits']">Manage Benefits</a>)</h2>
    <hr/>
    <h5 class="col-10" style="text-align: right;"> Total Cost: <span style="color: green;">{{totalCost() | currency}}</span></h5>
    <div class="row" style="margin: 0px">
      <div class="col-10" style="padding: 0px;">
        <h6 class="col-12 page-header__client-name">Enrolled Benefits / Pending Benefits </h6>
        <div *ngFor="let item of currentEnrollment?.enrollmentSummary | orderBy: 'benefitEntityDescription'">
          <div class="col-12" style="padding: 0px;" *ngIf="item.currentEnrollment != null">
            <h5 style="font-weight:400;" class="default-font-Color-dark">
              {{item.benefitEntityDescription}}
              <button *ngIf="EntityId=='68bc62e6-dd96-4428-91e8-efe285bd23a0' "
                (click)="onInfoClick(item.benefitEntityDescription)" style="width:35px;float: right;"
                class="btn btn-sm BlueButton">
                <i class="fa fa-info" aria-hidden="true"></i>
              </button>
              <br />
              <span class="member-function-header" style="color: gray; font-size : 14px !important">
                <i class="fa fa-handshake-o" style=" padding: 3px; border-radius:18px;font-size: 12px; "
                  aria-hidden="true"></i>
                &nbsp;{{item.vendorName}}</span>
            </h5>
            <div class="row" *ngIf="item.currentEnrollment != null">
              <div class="col-4">
                
                <span class="Summary_Lable">Policy Details: {{item.benefitEntityPolicyDescription}}<br></span>
                <span class="Summary_Lable">Policy Code: {{item.benefitEntityPolicyCode}}<br></span>
                <span *ngIf='EntityId=="ac586073-69d6-41bf-875c-eb754f750457"' class="Summary_Lable">Policy Sub Code: {{item.benefitEntityPolicySubCode}}<br></span>
                <ng-container *ngIf='item.certificateNumber!=null'>
                  <span class="Summary_Lable">Certificate Number: {{item.certificateNumber}}<br></span>
                </ng-container>
                <!-- <ng-container *ngIf='item.certificateEffectiveDate!=null'>
                    <span class="Summary_Lable">CertificateEffective Date : </span>  {{item.certificateEffectiveDate | date}}<br>
                  </ng-container> -->
                <span class="Summary_Lable">Type: {{item.currentEnrollment.levelName}}<br></span>
                <!-- <span *ngIf="item.healthPlanOptions != null && item.healthPlanOptions.length > 0">
                      <span class="Summary_Lable">Health Plan: </span>  {{item.healthPlanOptions[0].levelName}}<br>
                  </span> -->
                <div *ngIf="item.currentEnrollment.coverages != null && item.currentEnrollment.coverages[0]!=null">
                  <span class="Summary_Lable">Enrolled Coverage Value: {{item.currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageValueEnrolled | currency}}<br></span>
                  <span class="Summary_Lable" *ngIf='item.currentEnrollment.coverages[0].coverageCode!=null'>Coverage Code: {{item.currentEnrollment.coverages[0].coverageCode}}<br></span>
                  <ng-container *ngIf='item.currentEnrollment.coverages[0].packageCode!=null'>
                    <span class="Summary_Lable">Package Code: {{item.currentEnrollment.coverages[0].packageCode}}<br></span>
                  </ng-container>
                  <ng-container *ngIf='item.currentEnrollment.coverages[0].classCode!=null'>
                    <span class="Summary_Lable">Class Code: {{item.currentEnrollment.coverages[0].classCode}}<br></span>
                  </ng-container>
                </div>
                <ng-container *ngIf="item.currentEnrollment.covCode != null ">
                  <span class="Summary_Lable">Cov Code: {{item.currentEnrollment.covCode}} </span> <br>
                </ng-container>
                <span class="Summary_Lable">Tier: {{item.currentEnrollment.benefitEntityPolicyDetailName}}<br></span>
                <ng-container *ngIf='item.currentEnrollment.rate!=null '>
                  <ng-container *ngFor="let itemrate of item.currentEnrollment.rate;">
                    <span class="Summary_Lable">
                      <span *ngIf="itemrate.contributorCode == 'PR'">Member</span>
                      <span *ngIf="itemrate.contributorCode == 'ER'">Employer</span>
                      Paid Premium: {{itemrate.rate | currency}}<br></span>
                    <span *ngIf="itemrate.age != null" class="Summary_Lable">Rate Age: {{itemrate.age}}<br /> </span>
                    <table *ngIf='itemrate.splitRateDetails!=null'>
                      <tr *ngFor='let it1 of itemrate.splitRateDetails' class="Summary_Lable">
                        <td>Age:</td>
                        <td> {{it1.age}};&nbsp;</td>
                        <td>  Relationship:</td>
                        <td> {{it1.relationshipText}};&nbsp;</td>
                        <td>  Rate:</td>
                        <td> {{it1.rate}};&nbsp;</td>
                      </tr>
                    </table>
                  </ng-container>
                </ng-container>
                <span class="Summary_Lable">Effective Date: {{item.effectiveDate | date}}<br> </span>
                <div *ngIf='EntityId=="d6a2ff7e-bba0-48fa-9396-a6b202631669"'>
                  <span class="Summary_Lable">Anniversary Date: {{item.currentEnrollment.policyAnniversaryDate}}</span> <br>
                  <!-- <span class="Summary_Lable">Coverage Code: </span> {{item.currentEnrollment.covCode}}<br> -->
                </div>
                <span *ngIf="item.currentEnrollment.planCode != null" class="Summary_Lable">Plan Code:
                  {{item.currentEnrollment.planCode}}<br></span>
                <span *ngIf="item.currentEnrollment.locationCode != null" class="Summary_Lable">Location Code:
                  {{item.currentEnrollment.locationCode}}<br></span>
                <ng-container *ngIf='item.currentEnrollment.areaCode!=null'>
                  <span class="Summary_Lable">Area Code: </span> {{item.currentEnrollment.areaCode }}<br>
                </ng-container>
                <ng-container *ngIf='item.currentEnrollment.qualificationCode!=null'>
                  <span class="Summary_Lable">{{qualCodeText}}{{item.currentEnrollment.qualificationCode}}</span> <br>
                </ng-container>
                <ng-container *ngIf='item.currentEnrollment.isTakeoverFlag === true'>
                  <span class="Summary_Lable">Is Takeover Flag: {{item.currentEnrollment.isTakeoverFlag}}</span> <br>
                </ng-container>
                <ng-container *ngIf='item.currentEnrollment.originalEnrollmentEffectiveDateAddional != null && EntityId=="d6a2ff7e-bba0-48fa-9396-a6b202631669"'>
                  <span class="Summary_Lable">Insured Medical Effective Date: {{item.currentEnrollment.originalEnrollmentEffectiveDateAddional | date}}</span><br>
                </ng-container>
              </div>
              <div class="col-4">
                <span class="Summary_Lable">Original Enrollment Date:
                  {{item.currentEnrollment.originalEnrollmentDateTime | date}}<br></span>
                <span *ngIf="item.currentEnrollment.originalIssueState  != null" class="Summary_Lable">Original Issue
                  State: {{item.currentEnrollment.originalIssueState}}<br /></span>
                <span class="Summary_Lable">Original Effective Date:
                  {{item.currentEnrollment.originalEnrollmentEffectiveDate | date}}<br></span>
                <span class="Summary_Lable">Last Change Date: {{item.currentEnrollment.lastChangeDate | date}}<br>
                </span>
                <span *ngIf="item.currentEnrollment.payThroughDate  != null" class="Summary_Lable">Pay Through Date:
                  {{item.currentEnrollment.payThroughDate | date}}<br /> </span>
                <span *ngIf="item.currentEnrollment.latestEnrollmentDateTime  != null" class="Summary_Lable">Latest
                  Enrollment Date: {{item.currentEnrollment.latestEnrollmentDateTime | date}}<br /> </span>
                <span *ngIf="item.currentEnrollment.appReceivedDate  != null" class="Summary_Lable">App Received Date:
                  {{item.currentEnrollment.appReceivedDate | date}}<br /> </span>
                <span
                  *ngIf="item.currentEnrollment.isHealthPlan != null && EntityId=='68bc62e6-dd96-4428-91e8-efe285bd23a0'"
                  class="Summary_Lable">Health Plan: {{item.currentEnrollment.isHealthPlan}}<br> </span>
                <span *ngIf="item.currentEnrollment.lastBillDate != null" class="Summary_Lable">First Bill Date:
                  {{item.currentEnrollment.firstBilledDate | date}}<br> </span>
                <span *ngIf="item.currentEnrollment.lastBillAmount  != null" class="Summary_Lable">Last Bill Amount:
                  {{item.currentEnrollment.lastBillAmount | currency}}<br> </span>
                <span *ngIf="item.currentEnrollment.firstBilledDate != null" class="Summary_Lable">Last Bill Date:
                  {{item.currentEnrollment.lastBillDate | date}}<br> </span>
                <span *ngIf="item.currentEnrollment.nextBillDate  != null" class="Summary_Lable">Next Bill Date:
                  {{item.currentEnrollment.nextBillDate | date}}<br></span>
                <div *ngIf='item.dropEffectiveDate != null && (EntityId == "d6a2ff7e-bba0-48fa-9396-a6b202631669" || EntityId == "a11ee4de-9b44-4bcf-a01e-04e1a2fc6028") '>
                  <span class="Summary_Lable">Drop Date : {{item.dropEffectiveDate | date}}</span> <br>
                </div>
                <span *ngIf="item.currentEnrollment.currentCoverageValue  != null" class="Summary_Lable">
                  Current Coverage Value: {{item.currentEnrollment.currentCoverageValue | currency}}<br> </span>
                <span *ngIf="item.currentEnrollment.currentCoverageValueRemark  != null" class="Summary_Lable">
                  Current Coverage Value Remark: {{item.currentEnrollment.currentCoverageValueRemark }}<br> </span>
                <span *ngIf="item.currentEnrollment.billableCoverageValue  != null" class="Summary_Lable">
                  Billable Coverage Value: {{item.currentEnrollment.billableCoverageValue | currency}}<br> </span>
                <!--<span *ngIf="item.currentEnrollment.dependents[0] != null" class="Summary_Lable">
                  Dependent Current Coverage Value: {{item.currentEnrollment.dependents[0].coverages[0].benefitEntityPolicyDetailCoverageValueCurrent | currency}}<br> </span>
                <span *ngIf="item.currentEnrollment.dependents[0] != null" class="Summary_Lable">
                  Dependent Billable Coverage Value: {{item.currentEnrollment.dependents[0].coverages[0].benefitEntityPolicyDetailCoverageValueBillable | currency}}<br> </span>-->
                <div *ngIf="EntityId=='31e56172-ac96-487c-a6b3-d5847954f3b2'">
                  <span *ngIf="item.currentEnrollment.eliminationPeriod  != null" class="Summary_Lable">Elimination
                    Period: {{item.currentEnrollment.eliminationPeriod }}<br> </span>
                  <span *ngIf="item.currentEnrollment.benefitPeriod  != null" class="Summary_Lable">Benefit Period:
                    {{item.currentEnrollment.benefitPeriod }}<br> </span>

                </div>
              </div>
              <div class="col-4">
                <span class="Summary_Lable">Enrollment Status: {{item.currentEnrollment.enrollmentStatus }}<br> </span>
                <div *ngIf="item.currentEnrollment.dependents != null ">
                  <span class="Summary_Lable">Dependents <br /></span>
                  <app-plan-dependentview [dependents]='item.currentEnrollment.dependents'
                    [benefitEntityPolicyDetailId]='item.currentEnrollment.benefitEntityPolicyDetailId'
                    [dependentsInfo]='dependentsInfo'>
                  </app-plan-dependentview>
                </div>
                <!-- <a style="text-align: right;" class="default-font-Color-dark"  (click)="history(item)"><u>History</u></a> -->
                <app-benefitsummarylink [hideReissue]='"true"' [item]='item'
                  [participantBenefitId]='item.currentEnrollment.participantBenefitId'></app-benefitsummarylink>
              </div>
              <div class="col-12" style="margin-top:15px;" *ngIf="item.UWHistory == null || item.UWHistory == false">
                <div
                  *ngIf="item.underWriting!=null && item.underWriting[0] != null && item.underWriting[0].enrollmentApplicationDetails != null"
                  style="margin-bottom: 15px; padding: 15px;border:1px solid rgba(16, 140, 146, 0.5);background: rgba(16, 140, 146, 0.02)">
                  <!-- <div *ngFor = "let det of uw.enrollmentApplicationDetails "> -->
                  <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].status == 'Pending'">Last Underwriting
                    Action: <span style="color: rgb(201, 127, 17)">Application Pending</span></h6>
                  <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].status == 'Declined'">Last
                    Underwriting Action: <span style="color: red">Application Declined</span></h6>
                  <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].status == 'Approved'">Last
                    Underwriting Action: <span style="color: green">Application Approved</span></h6>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].createdDate != null">Date changed:
                    <b class="default-font-Color-dark">{{
                      item.underWriting[0].enrollmentApplicationDetails[0].createdDate | date}}</b> <br /> </span>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].appReceiveDate != null">Received
                    Date: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].appReceiveDate
                      | date}}</b> <br /> </span>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].responseDate != null">App Response
                    Date: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].responseDate
                      | date}}</b> <br /> </span>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].status != null">Status: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].status}}</b>
                    <br /> </span>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].remarks != null">Remarks: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].remarks}}</b>
                    <br /> </span>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].reason != null">Reason: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].reason}}</b>
                    <br /></span>
                  <span
                    *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].campaignCode != null && item.underWriting[0].enrollmentApplicationDetails[0].campaignCode != ''">Campaign
                    Code: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].campaignCode}}</b>
                    <br /></span>
                  <span
                    *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].source != null&& item.underWriting[0].enrollmentApplicationDetails[0].source != ''">Source:
                    <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].source}}</b>
                    <br /> </span>
                  <span
                    *ngIf="item.underWriting[0].enrollmentApplicationDetails[0].replacement != null&& item.underWriting[0].enrollmentApplicationDetails[0].replacement != ''">Replacement:
                    <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].replacement}}</b>
                    <br /> </span>
                  <!-- </div> -->
                  <a class="PatchFont" style="float: right"
                    *ngIf="item.underWriting!=null && (item.underWriting.length > 1 || item.underWriting[0].enrollmentApplicationDetails?.length > 1)"
                    (click)="item.UWHistory = true">History</a>
                  <br />
                </div>
              </div>
              <div class="col-12"
                *ngIf="item.underWriting!=null && item.underWriting.length > 0 && (item.UWHistory != null && item.UWHistory == true) "
                style="margin-bottom: 15px; padding: 15px;border:1px solid rgba(16, 140, 146, 0.5);background: rgba(16, 140, 146, 0.02)">
                <h5 style="color: rgb(16, 141, 148)">Application History</h5>
                <div *ngFor="let uw of item.underWriting">
                  <div *ngFor="let det of uw.enrollmentApplicationDetails" style=" margin-right:10px;">
                    <h6 *ngIf="det.status == 'Pending'" style="color: rgb(201, 127, 17)">Application Pending</h6>
                    <h6 *ngIf="det.status == 'Declined'" style="color: red">Application Declined</h6>
                    <h6 *ngIf="det.status == 'Approved'" style="color: green">Application Approved</h6>
                    <span *ngIf="det.createdDate != null">Date Changed: <b
                        class="default-font-Color-dark">{{det.createdDate | date}}</b> <br /> </span>
                    <span *ngIf="det.appReceiveDate != null">Received Date: <b
                        class="default-font-Color-dark">{{det.appReceiveDate | date}}</b> <br /> </span>
                    <span *ngIf="det.responseDate != null"> App Response Date: <b
                        class="default-font-Color-dark">{{det.responseDate | date}}</b> <br /> </span>
                    <span *ngIf="det.status != null">Status: <b class="default-font-Color-dark">{{det.status}}</b>
                      <br /> </span>
                    <span *ngIf="det.remarks != null"> Remarks: <b class="default-font-Color-dark">{{det.remarks}}</b>
                      <br /> </span>
                    <span *ngIf="det.reason != null"> Reason: <b class="default-font-Color-dark">{{det.reason}}</b>
                    </span>
                    <span *ngIf="det.campaignCode != null"> Campaign Code: <b
                        class="default-font-Color-dark">{{det.campaignCode}}</b> </span>
                    <span *ngIf="det.source != null"> Source: <b class="default-font-Color-dark">{{det.source}}</b>
                    </span>
                    <span *ngIf="det.replacement != null">Replacement: <b
                        class="default-font-Color-dark">{{det.replacement}}</b> <br /> </span>
                    <hr style="margin: 5px" />
                  </div>
                </div>
                <a class="PatchFont" style="float: right" (click)="item.UWHistory = false">Collapse</a>
                <br />
              </div>
              <app-waiverdisp class="col-12" [waiver]='item.waiver' *ngIf="item.waiver != null && item.waiver.length>0">
              </app-waiverdisp>
              <div *ngIf="item.currentEnrollment?.riders!= null ">
                <div *ngFor="let item1 of item.currentEnrollment?.riders">
                  Rider Name:{{item1.riderName}}<br />
                  Rider Code:{{item1.riderCode}}<br />
                  BenefitEntityPolicyDetailCoverageValueBillable:{{item1.benefitEntityPolicyDetailCoverageValueBillable}}<br />
                  <span *ngIf="item1.coverageCode != null"> <span class="Summary_Lable">Coverage Code:
                    </span>{{item1.coverageCode}} <br /> </span>
                  <span *ngIf="item1.packageCode != null"> <span class="Summary_Lable">Package Code:
                    </span>{{item1.packageCode}} <br /> </span>
                  <span *ngIf="item1.classCode != null"> <span class="Summary_Lable">Class Code:
                    </span>{{item1.classCode}} <br /> </span>
                  <span *ngIf="item1.effectiveDate != null"> <span class="Summary_Lable">Effective Date:
                    </span>{{item1.effectiveDate}} <br /> </span>
                  <span *ngIf="item1.areaCode != null"> <span class="Summary_Lable">Area Code: </span>{{item1.areaCode}}
                    <br /> </span>
                  <span *ngIf="item1.qualificationCode != null"> <span class="Summary_Lable">{{qualCodeText}}
                    </span>{{item1.qualificationCode}} <br /> </span>
                  <span *ngIf="item1.planCode != null"> <span class="Summary_Lable">Plan Code: </span>{{item1.planCode}}
                    <br /> </span>
                  <span *ngIf="item1.locationCode != null"> <span class="Summary_Lable">Location Code:
                    </span>{{item1.locationCode}} <br /> </span>
                  <span *ngIf="item1.contributorCode != null"> <span class="Summary_Lable">Contributor Code:
                    </span>{{item1.contributorCode}} <br /> </span>
                  <span *ngIf="item1.covCode != null"> <span class="Summary_Lable">Cov Code: </span>{{item1.covCode}}
                    <br /> </span>
                  <span *ngIf="item1.eliminationPeriod != null"> <span class="Summary_Lable">Elimination Period:
                    </span>{{item1.eliminationPeriod}} <br /> </span>
                  <span *ngIf="item1.benefitPeriod != null"> <span class="Summary_Lable">Benefit Period:
                    </span>{{item1.benefitPeriod}} <br /> </span>
                </div>
              </div>
            </div>
            <hr />
          </div>

        </div>
        <br />
        <a>
          <h6 class="col-12 page-header__client-name" style="padding: 10px;">Termed Benefits</h6>
        </a>
        <br />
        <div class="  row"
          *ngFor="let item of currentEnrollment?.enrollmentSummary | orderBy: 'benefitEntityDescription'">
          <div class="col-12" *ngIf="item.currentEnrollment == null && item.isTermed === true">
            <h5 style="font-weight:400;" class="default-font-Color-dark">
              {{item.benefitEntityDescription+'-'+item.benefitEntityPolicyDescription}}
              <button *ngIf="EntityId=='68bc62e6-dd96-4428-91e8-efe285bd23a0' "
                (click)="onInfoClick(item.benefitEntityDescription)" style="width:35px" class="btn btn-sm BlueButton">
                <i class="fa fa-info" aria-hidden="true"></i> </button>
              <br />
              <span class="member-function-header" style="color: gray; font-size : 14px !important">
                <i class="fa fa-handshake-o" style=" padding: 3px; border-radius:18px;font-size: 12px; "
                  aria-hidden="true"></i>
                &nbsp;{{item.vendorName}}</span>
            </h5>

            <div class="row" *ngIf="item.cancelledEnrollment != null">
              <div class="col-4">
                <span class="Summary_Lable">Policy Details: </span> {{item.benefitEntityPolicyDescription}}<br>
                <span class="Summary_Lable">Policy Code: </span> {{item.benefitEntityPolicyCode}}<br>
                <ng-container *ngIf='item.certificateNumber!=null'>
                  <span class="Summary_Lable">Certificate Number: </span> {{item.certificateNumber}}<br>
                </ng-container>
                <!-- <ng-container *ngIf='item.certificateEffectiveDate!=null'>
                        <span class="Summary_Lable">CertificateEffective Date : </span>  {{item.certificateEffectiveDate | date}}<br>
                      </ng-container> -->
                <span class="Summary_Lable">Type: </span> {{item.cancelledEnrollment.levelName}}<br>
                <!-- <span *ngIf="item.healthPlanOptions != null && item.healthPlanOptions.length > 0">
                          <span class="Summary_Lable">Health Plan: </span>  {{item.healthPlanOptions[0].levelName}}<br>
                      </span> -->
                <div *ngIf="item.cancelledEnrollment.coverages != null && item.cancelledEnrollment.coverages[0]!=null">
                  <span class="Summary_Lable">Enrolled Coverage Value: </span>
                  {{item.cancelledEnrollment.coverages[0].benefitEntityPolicyDetailCoverageValue}}<br>
                  <ng-container *ngIf='item.cancelledEnrollment.coverages[0].packageCode!=null'>
                    <span class="Summary_Lable">Package Code: </span>
                    {{item.cancelledEnrollment.coverages[0].packageCode}}<br>
                  </ng-container>
                  <ng-container *ngIf='item.cancelledEnrollment.coverages[0].classCode!=null'>
                    <span class="Summary_Lable">Class Code: </span>
                    {{item.cancelledEnrollment.coverages[0].classCode}}<br>
                  </ng-container>
                </div>
                <ng-container *ngIf="item.cancelledEnrollment.covCode != null ">
                  <span class="Summary_Lable">Cov Code: {{item.cancelledEnrollment.covCode}} </span> <br>
                </ng-container>
                <ng-container>
                  <span class="Summary_Lable">Reason:{{item.cancelledEnrollment.reason}} </span> <br>
                </ng-container>
                <span class="Summary_Lable">Tier: </span> {{item.cancelledEnrollment.benefitEntityPolicyDetailName}}<br>
                <ng-container *ngIf='item.cancelledEnrollment.rate!=null '>
                  <ng-container *ngFor="let itemrate of item.cancelledEnrollment.rate;">
                    <span class="Summary_Lable">
                      <span *ngIf="itemrate.contributorCode == 'PR'">Member</span>
                      <span *ngIf="itemrate.contributorCode == 'ER'">Employer</span>
                      Paid Premium: {{itemrate.rate | currency}}<br></span>

                    <span *ngIf="itemrate.age != null" class="Summary_Lable">Rate Age: {{itemrate.age}}<br /> </span>
                    <table *ngIf='itemrate.splitRateDetails!=null'>
                      <tr *ngFor='let it1 of itemrate.splitRateDetails'>
                        <td>Age:</td>
                        <td>{{it1.age}};&nbsp;</td>
                        <td>Relationship:</td>
                        <td>{{it1.relationshipText}}&nbsp;</td>
                        <td>Rate:</td>
                        <td>{{it1.rate}}&nbsp;</td>
                      </tr>
                    </table>
                  </ng-container>
                </ng-container>
                <span class="Summary_Lable">Effective Date: {{item.effectiveDate | date}}<br> </span>
                <div *ngIf='EntityId=="d6a2ff7e-bba0-48fa-9396-a6b202631669"'>
                  <span class="Summary_Lable">Anniversary Date: {{item.cancelledEnrollment.policyAnniversaryDate}}</span> <br>
                  <span class="Summary_Lable">Coverage Code: </span> {{item.cancelledEnrollment.covCode}}<br>
                </div>

                <span *ngIf="item.cancelledEnrollment.planCode != null" class="Summary_Lable">Plan Code:
                  {{item.cancelledEnrollment.planCode}}<br></span>
                <span *ngIf="item.cancelledEnrollment.locationCode != null" class="Summary_Lable">Location Code:
                  {{item.cancelledEnrollment.locationCode}}<br></span>

                <span class="Summary_Lable">Cancelled Date: </span> {{item.cancelledEnrollment.cancelledDate |
                date}}<br>
                <ng-container
                  *ngIf='item.cancelledEnrollment.areaCode!=null && item.cancelledEnrollment.areaCode != ""'>
                  <span class="Summary_Lable">Area Code: </span> {{item.cancelledEnrollment.areaCode }}<br>
                </ng-container>Current Coverage Value Remark:
                <ng-container *ngIf='item.cancelledEnrollment.qualificationCode!=null'>
                  <span class="Summary_Lable">{{qualCodeText}}</span> {{item.cancelledEnrollment.qualificationCode
                  }}<br>
                </ng-container>
                <ng-container *ngIf='item.cancelledEnrollment.isTakeoverFlag===true'>
                  <span class="Summary_Lable">Is Takeover Flag: </span> {{item.cancelledEnrollment.isTakeoverFlag }}<br>
                </ng-container>
                <ng-container *ngIf='item.cancelledEnrollment.originalEnrollmentEffectiveDateAddional != null'>
                  <span class="Summary_Lable">Insured Medical Effective Date: {{item.cancelledEnrollment.originalEnrollmentEffectiveDateAddional | date}}</span> <br>
                </ng-container>
              </div>
              <div class="col-4">
                <span class="Summary_Lable">Original Enrollment Date: </span>
                {{item.cancelledEnrollment.originalEnrollmentDateTime | date}}<br>
                <span
                  *ngIf="item.cancelledEnrollment.originalIssueState  != null && item.cancelledEnrollment.originalIssueState  != ''"
                  class="Summary_Lable">Original Issue State: {{item.cancelledEnrollment.originalIssueState}}<br />
                </span>
                <span class="Summary_Lable">Original Effective Date: </span>
                {{item.cancelledEnrollment.originalEnrollmentEffectiveDate | date}}<br>
                <span class="Summary_Lable">Last Change Date: </span> {{item.cancelledEnrollment.lastChangeDate |
                date}}<br>
                <span *ngIf="item.cancelledEnrollment.payThroughDate  != null" class="Summary_Lable">Pay Through Date:
                </span> {{item.cancelledEnrollment.payThroughDate | date}}<br
                  *ngIf="item.cancelledEnrollment.payThroughDate  != null" />
                <span *ngIf="item.cancelledEnrollment.latestEnrollmentDateTime  != null" class="Summary_Lable">Latest
                  Enrollment Date: </span> {{item.cancelledEnrollment.latestEnrollmentDateTime | date}}<br
                  *ngIf="item.cancelledEnrollment.latestEnrollmentDateTime  != null" />
                <span *ngIf="item.cancelledEnrollment.appReceivedDate  != null" class="Summary_Lable">App Received Date:
                  {{item.cancelledEnrollment.appReceivedDate | date}}<br /> </span>
                <!-- <span *ngIf="item.cancelledEnrollment.isHealthPlan  != null" class="Summary_Lable">Health Plan: </span>  {{item.cancelledEnrollment.isHealthPlan}}<br>  -->
                <span *ngIf="item.cancelledEnrollment.firstBilledDate!= null" class="Summary_Lable">First Bill Date:
                  {{item.cancelledEnrollment.firstBilledDate | date}}<br> </span>
                <span *ngIf="item.cancelledEnrollment.lastBillAmount  != null" class="Summary_Lable">Last Bill Amount:
                  {{item.cancelledEnrollment.lastBillAmount | currency}}<br> </span>
                <span *ngIf="item.cancelledEnrollment.lastBillDate != null" class="Summary_Lable">Last Bill Date:
                  {{item.cancelledEnrollment.lastBillDate | date}}<br> </span>
                <span *ngIf="item.cancelledEnrollment.nextBillDate  != null" class="Summary_Lable">Next Bill Date:
                  {{item.cancelledEnrollment.nextBillDate | date}}<br> </span>
                <span *ngIf="item.cancelledEnrollment.currentCoverageValue  != null" class="Summary_Lable">Current
                  Coverage Value: {{item.cancelledEnrollment.currentCoverageValue }}<br> </span>
                <span *ngIf="item.cancelledEnrollment.currentCoverageValueRemark  != null" class="Summary_Lable">Current
                  Coverage Value Remark: {{item.cancelledEnrollment.currentCoverageValueRemark }}<br> </span>
                <span *ngIf="item.cancelledEnrollment.billableCoverageValue  != null" class="Summary_Lable">Billable
                  Coverage Value: {{item.cancelledEnrollment.billableCoverageValue }}<br> </span>
                <div *ngIf="EntityId=='31e56172-ac96-487c-a6b3-d5847954f3b2'">
                  <span class="Summary_Lable">Elimination Period: {{item.cancelledEnrollment.eliminationPeriod }}<br>
                  </span>
                  <span class="Summary_Lable">Benefit Period: {{item.cancelledEnrollment.benefitPeriod }}<br> </span>

                </div>
              </div>
              <div class="col-4">
                <span class="Summary_Lable">Enrollment Status: </span> {{item.cancelledEnrollment.enrollmentStatus
                }}<br>
                <div *ngIf="item.cancelledEnrollment.dependents != null ">
                  Dependents <br />
                  <app-plan-dependentview [dependents]='item.cancelledEnrollment.dependents'
                    [dependentsInfo]='dependentsInfo'
                    [benefitEntityPolicyDetailId]='item.cancelledEnrollment.benefitEntityPolicyDetailId'>
                  </app-plan-dependentview>
                </div>
                <!-- <a style="text-align: right;" class="default-font-Color-dark"  (click)="history(item)"><u>History</u></a> -->
                <app-benefitsummarylink [hideReissue]='"false"' [item]='item'
                  [participantBenefitId]='item.cancelledEnrollment.participantBenefitId'></app-benefitsummarylink>
              </div>

              <div class="col-12" style="margin-top:15px;" *ngIf="item.UWHistory == null || item.UWHistory == false">
                <div
                  *ngIf="item.underWriting!=null && item.underWriting[0] != null && item.underWriting[0].enrollmentApplicationDetails != null"
                  style="margin-bottom: 15px; padding: 15px;border:1px solid rgba(16, 140, 146, 0.5);background: rgba(16, 140, 146, 0.02)">
                  <!-- <div *ngFor = "let det of uw.enrollmentApplicationDetails "> -->
                  <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status == 'Pending'">Last
                    Underwriting Action: <span style="color: rgb(201, 127, 17)">Application Pending</span></h6>
                  <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status == 'Declined'">Last
                    Underwriting Action: <span style="color: red">Application Declined</span></h6>
                  <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status == 'Approved'">Last
                    Underwriting Action: <span style="color: green">Application Approved</span></h6>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.createdDate != null">Date changed:
                    <b class="default-font-Color-dark">{{
                      item.underWriting[0].enrollmentApplicationDetails[0].createdDate | date}}</b> <br /> </span>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.appReceiveDate != null">Received
                    Date: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].appReceiveDate
                      | date}}</b> <br /> </span>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.responseDate != null">App Response
                    Date: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].responseDate
                      | date}}</b> <br /> </span>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status != null">Status: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].status}}</b>
                    <br /> </span>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.remarks != null">Remarks: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].remarks}}</b>
                    <br /> </span>
                  <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.reason != null">Reason: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].reason}}</b>
                    <br /></span>
                  <span
                    *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.campaignCode != null && item.underWriting[0].enrollmentApplicationDetails[0]?.campaignCode != ''">Campaign
                    Code: <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].campaignCode}}</b>
                    <br /></span>
                  <span
                    *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.source != null&& item.underWriting[0].enrollmentApplicationDetails[0]?.source != ''">Source:
                    <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].source}}</b>
                  </span>
                  <span
                    *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.replacement != null&& item.underWriting[0].enrollmentApplicationDetails[0]?.replacement != ''">Replacement:
                    <b
                      class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].replacement}}</b>
                    <br /> </span>
                  <!-- </div> -->
                  <a class="PatchFont" style="float: right"
                    *ngIf="item.underWriting!=null && (item.underWriting.length > 1 || item.underWriting[0].enrollmentApplicationDetails?.length > 1)"
                    (click)="item.UWHistory = true">History</a>
                  <br />
                </div>
              </div>
              <div class="col-12"
                *ngIf="item.underWriting!=null && item.underWriting.length > 0 && (item.UWHistory != null && item.UWHistory == true) "
                style="margin-bottom: 15px; padding: 15px;border:1px solid rgba(16, 140, 146, 0.5);background: rgba(16, 140, 146, 0.02)">
                <h5 style="color: rgb(16, 141, 148)">Application History</h5>
                <div *ngFor="let uw of item.underWriting">
                  <div *ngFor="let det of uw.enrollmentApplicationDetails" style=" margin-right:10px;">
                    <h6 *ngIf="det.status == 'Pending'" style="color: rgb(201, 127, 17)">Application Pending</h6>
                    <h6 *ngIf="det.status == 'Declined'" style="color: red">Application Declined</h6>
                    <h6 *ngIf="det.status == 'Approved'" style="color: green">Application Approved</h6>
                    <span *ngIf="det.createdDate != null">Date Changed: <b
                        class="default-font-Color-dark">{{det.createdDate | date}}</b> <br /> </span>
                    <span *ngIf="det.appReceiveDate != null">Received Date: <b
                        class="default-font-Color-dark">{{det.appReceiveDate | date}}</b> <br /> </span>
                    <span *ngIf="det.responseDate != null"> App Response Date: <b
                        class="default-font-Color-dark">{{det.responseDate | date}}</b> <br /> </span>
                    <span *ngIf="det.status != null">Status: <b class="default-font-Color-dark">{{det.status}}</b>
                      <br /> </span>
                    <span *ngIf="det.remarks != null"> Remarks: <b class="default-font-Color-dark">{{det.remarks}}</b>
                      <br /> </span>
                    <span *ngIf="det.reason != null"> Reason: <b class="default-font-Color-dark">{{det.reason}}</b>
                    </span>
                    <span *ngIf="det.campaignCode != null">Campaign Code: <b
                        class="default-font-Color-dark">{{det.campaignCode}}</b> </span>
                    <span *ngIf="det.source != null"> Source: <b class="default-font-Color-dark">{{det.source}}</b>
                    </span>
                    <span *ngIf="det.replacement != null">Replacement: <b
                        class="default-font-Color-dark">{{det.replacement}}</b> <br /> </span>
                    <hr style="margin: 5px" />
                  </div>
                </div>
                <a class="PatchFont" style="float: right" (click)="item.UWHistory = false">Collapse</a>
                <br />
              </div>
              <app-waiverdisp class="col-12" [waiver]='item.waiver' *ngIf="item.waiver != null && item.waiver.length>0">
              </app-waiverdisp>
              <div *ngIf="item.cancelledEnrollment?.riders!= null ">
                <div *ngFor="let item1 of item.cancelledEnrollment?.riders">
                  Rider Name:{{item1.riderName}}<br />
                  Rider Code:{{item1.riderCode}}<br />
                  BenefitEntityPolicyDetailCoverageValueBillable:
                  {{item1.benefitEntityPolicyDetailCoverageValueBillable}}<br />
                  <span *ngIf="item1.coverageCode != null"> Coverage Code: {{item1.coverageCode}} <br /> </span>
                  <span *ngIf="item1.packageCode != null"> Package Code: {{item1.packageCode}} <br /> </span>
                  <span *ngIf="item1.classCode != null"> Class Code: {{item1.classCode}} <br /> </span>
                  <span *ngIf="item1.effectiveDate != null"> Effective Date: {{item1.effectiveDate}} <br /> </span>
                  <span *ngIf="item1.areaCode != null"> Area Code: {{item1.areaCode}} <br /> </span>
                  <span *ngIf="item1.qualificationCode != null">{{qualCodeText}}{{item1.qualificationCode}}<br /></span>
                  <span *ngIf="item1.planCode != null"> Plan Code: {{item1.planCode}} <br /> </span>
                  <span *ngIf="item1.locationCode != null"> Location Code: {{item1.locationCode}} <br /> </span>
                  <span *ngIf="item1.contributorCode != null"> Contributor Code: {{item1.contributorCode}} <br /></span>
                  <span *ngIf="item1.covCode != null"> Cov Code: {{item1.covCode}} <br /> </span>
                  <span *ngIf="item1.eliminationPeriod != null"> Elimination Period: {{item1.eliminationPeriod}}
                    <br /></span>
                  <span *ngIf="item1.benefitPeriod != null"> Benefit Period: {{item1.benefitPeriod}} <br /> </span>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <a data-toggle="collapse"
        href="#NonEnrolledDiv">
          <h6 class="col-12 page-header__client-name" style="padding: 10px;">Show Non-Enrolled Benefits (Click here to view)</h6>
        </a>
        <br />
        <div class="collapse row" id="NonEnrolledDiv"
          *ngFor="let item of currentEnrollment?.enrollmentSummary | orderBy: 'benefitEntityDescription'">
          <div class="col-12" *ngIf="item.currentEnrollment == null && item.isTermed === false">
            <h5 style="font-weight:400;" class="default-font-Color-dark">
              {{item.benefitEntityDescription+'-'+item.benefitEntityPolicyDescription}}
              <button *ngIf="EntityId=='68bc62e6-dd96-4428-91e8-efe285bd23a0' "
                (click)="onInfoClick(item.benefitEntityDescription)" style="width:35px" class="btn btn-sm BlueButton">
                <i class="fa fa-info" aria-hidden="true"></i> </button>
              <br />
              <span class="member-function-header" style="color: gray; font-size : 14px !important">
                <i class="fa fa-handshake-o" style=" padding: 3px; border-radius:18px;font-size: 12px; "
                  aria-hidden="true"></i>
                &nbsp;{{item.vendorName}}</span>
            </h5>
            <span class="Summary_Lable">Policy Details: </span> {{item.benefitEntityPolicyDescription}}<br>
            <!-- <span class="Summary_Lable">Type: </span>  {{item.levelName}}<br> -->
            <a style="text-align: right;" class="default-font-Color-dark" (click)="history(item)"><u>History</u></a>

            <div class="col-12" style="margin-top:15px;" *ngIf="item.UWHistory == null || item.UWHistory == false">
              <div
                *ngIf="item.underWriting!=null && item.underWriting[0] != null && item.underWriting[0].enrollmentApplicationDetails != null"
                style="margin-bottom: 15px; padding: 15px;border:1px solid rgba(16, 140, 146, 0.5);background: rgba(16, 140, 146, 0.02)">
                <!-- <div *ngFor = "let det of uw.enrollmentApplicationDetails "> -->
                <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status == 'Pending'">Last Underwriting
                  Action: <span style="color: rgb(201, 127, 17)">Application Pending</span></h6>
                <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status == 'Declined'">Last Underwriting
                  Action: <span style="color: red">Application Declined</span></h6>
                <h6 *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status == 'Approved'">Last Underwriting
                  Action: <span style="color: green">Application Approved</span></h6>
                <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.createdDate != null">Date Changed: <b
                    class="default-font-Color-dark">{{ item.underWriting[0].enrollmentApplicationDetails[0].createdDate
                    | date}}</b> <br /> </span>
                <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.appReceiveDate != null">Received
                  Date: <b
                    class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].appReceiveDate
                    | date}}</b> <br /> </span>
                <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.responseDate != null">App Response
                  Date: <b
                    class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].responseDate
                    | date}}</b> <br /> </span>
                <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.status != null">Status: <b
                    class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].status}}</b>
                  <br /> </span>
                <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.remarks != null">Remarks: <b
                    class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].remarks}}</b>
                  <br /> </span>
                <span *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.reason != null">Reason: <b
                    class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].reason}}</b>
                  <br /></span>
                <span
                  *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.campaignCode != null && item.underWriting[0].enrollmentApplicationDetails[0].campaignCode != ''">Campaign
                  Code: <b
                    class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].campaignCode}}</b>
                  <br /></span>
                <span
                  *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.source != null&& item.underWriting[0].enrollmentApplicationDetails[0].source != ''">Source:
                  <b class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].source}}</b>
                  <br /> </span>
                <span
                  *ngIf="item.underWriting[0].enrollmentApplicationDetails[0]?.replacement != null&& item.underWriting[0].enrollmentApplicationDetails[0].replacement != ''">Replacement:
                  <b
                    class="default-font-Color-dark">{{item.underWriting[0].enrollmentApplicationDetails[0].replacement}}</b>
                  <br /> </span>
                <!-- </div> -->
                <a class="PatchFont" style="float: right"
                  *ngIf="item.underWriting!=null && (item.underWriting.length > 1 || item.underWriting[0].enrollmentApplicationDetails?.length > 1)"
                  (click)="item.UWHistory = true">History</a>
                <br />
              </div>
            </div>

            <div class="col-12"
              *ngIf="item.underWriting!=null && item.underWriting.length > 0 && (item.UWHistory != null && item.UWHistory == true) "
              style="margin-bottom: 15px; padding: 15px;border:1px solid rgba(16, 140, 146, 0.5);background: rgba(16, 140, 146, 0.02)">
              <h5 style="color: rgb(16, 141, 148)">Application History</h5>
              <div *ngFor="let uw of item.underWriting">
                <div *ngFor="let det of uw.enrollmentApplicationDetails" style=" margin-right:10px;">
                  <h6 *ngIf="det.status == 'Pending'" style="color: rgb(201, 127, 17)">Application Pending</h6>
                  <h6 *ngIf="det.status == 'Declined'" style="color: red">Application Declined</h6>
                  <h6 *ngIf="det.status == 'Approved'" style="color: green">Application Approved</h6>
                  <span *ngIf="det.createdDate != null">Date Changed: <b class="default-font-Color-dark">{{det.createdDate | date}}</b><br/></span>
                  <span *ngIf="det.appReceiveDate != null">Received Date: <b class="default-font-Color-dark">{{det.appReceiveDate | date}}</b><br/></span>
                  <span *ngIf="det.responseDate != null"> App Response Date: <b class="default-font-Color-dark">{{det.responseDate | date}}</b><br/></span>
                  <span *ngIf="det.status != null">Status: <b class="default-font-Color-dark">{{det.status}}</b><br/></span>
                  <span *ngIf="det.remarks != null"> Remarks: <b class="default-font-Color-dark">{{det.remarks}}</b><br/></span>
                  <span *ngIf="det.reason != null"> Reason: <b class="default-font-Color-dark">{{det.reason}}</b></span>
                  <span *ngIf="det.campaignCode != null"> Campaign Code: <b class="default-font-Color-dark">{{det.campaignCode}}</b></span>
                  <span *ngIf="det.source != null"> Source: <b class="default-font-Color-dark">{{det.source}}</b></span>
                  <span *ngIf="det.replacement != null">Replacement: <b class="default-font-Color-dark">{{det.replacement}}</b><br/></span>
                  <hr style="margin: 5px"/>
                </div>
              </div>
              <a class="PatchFont" style="float: right" (click)="item.UWHistory = false">Collapse</a>
              <br />
            </div>

            <app-waiverdisp 
              class="col-12" [waiver]='item.waiver' *ngIf="item.waiver != null && item.waiver.length>0">
            </app-waiverdisp>

            <hr />
          </div>
        </div>
      </div>

      <div class="col-2">
        <app-member-quick-links-share [entityId]='EntityId' [prePath]="'../'"></app-member-quick-links-share>
      </div>
    </div>
  </app-form-group-wrapper>

  <div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
    <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
      height:200px; 
      position: fixed; 
      top: 50%; 
      left: 50%; 
      margin-top: -100px; 
      margin-left: -100px;"></nz-spin>
    <span class="default-font-Color-dark">Loading ...</span>
  </div>
</div>
