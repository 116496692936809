import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { MemberListItemDTO } from '@models/member-list/member-list.dto';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { MatDialog } from '@angular/material/dialog';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';

@Component({
  selector: 'app-suspense-member',
  templateUrl: './member.component.html',
  styleUrls: ['./suspense-management.component.scss'],

})
export class MemberComponent extends BaseComponent implements OnInit {
  @Input()
  depositdate
  @Input()
  suspenseAccountId
  @Input()
  needSelectDate = false;
  @Input()
  entityId;
  @Input()
  entityClientId;
  @Input()
  clientId;
  @Input()
  payDate;
  @Input()
  set memberId(memberId) {
    if (memberId != null) {
      this._memberId = memberId;
      if (this.needSelectDate) {

      } else { 
        const url = 'Payment/ParticipantOutstandingBillDetails?entityId=' + this.entityId + '&clientId=' + this.clientId + '&payDate=' + this.payDate + '&ParticipantId=' + memberId + '&entityClientId=' + this.entityClientId;
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe(data => {
          this.Benefit = [];
          if (data != null) {
            data.forEach(element => {
              // tslint:disable-next-line:max-line-length
              this.Benefit.push({
                BenefitName: element.benefitEntityPolicyName,
                OutStanding: element.outstandingBillAmount,
                Amount: element.outstandingBillAmount,
                participantBenefitId: element.participantBenefitId
              });
            });
          }

        });
      }
    }
  }
  @Output() refresh = new EventEmitter<any>();
  @ViewChild(NzTableComponent)
  memberComponent: NzTableComponent<any>;
  _memberId; dates; selectedDate; member$;
  expandable = true;
  suspenseComments;
  Benefit: any;
  totalBillAmount: number = 0
  constructor(public dialog: MatDialog, protected clientService: ClientService, private notification: NzNotificationService) {
    super();
  }

  onChange($event) {
    this.Benefit = [];
    // tslint:disable-next-line:max-line-length
    const url = 'Payment/ParticipantOutstandingBillDetails?entityId=' + this.entityId + '&clientId=' + this.clientId + '&payDate=' + this.selectedDate + '&ParticipantId=' + this._memberId + '&entityClientId=' + this.entityClientId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe(data => {
      this.totalBillAmount = data.reduce((acc, member) => {
        acc += member.outstandingBillAmount
        return acc
      }, 0)

      this.Benefit = [];
      if (data != null) {
        data.forEach(element => {
          // tslint:disable-next-line:max-line-length
          this.Benefit.push({ BenefitName: element.benefitEntityPolicyName, OutStanding: element.outstandingBillAmount, Amount: element.outstandingBillAmount, participantBenefitId: element.participantBenefitId });
        });
      }
    });
  }

  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');
  onApplyAll() {
    if (!this.needSelectDate) {
      this.selectedDate = this.payDate;
    }
    const url = 'Payment/AdjustParticipantSuspenseBalance';
    const ParticipantBenefitSuspenseDetails = this.memberComponent.data.map(x => ({ 'participantId': this._memberId, 'participantBenefitId': x.participantBenefitId, 'amount': x.Amount, 'isWriteOff': x.WriteOff }));
    const dto = {
      'clientId': this.clientId,
      'entityClientId': this.entityClientId,
      'entityId': this.entityId,
      'payDate': this.selectedDate,
      'participantId': this._memberId,
      'ParticipantBenefitSuspenseDetails': ParticipantBenefitSuspenseDetails
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe(data => {
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(data);
    }, err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  onApply(data) {
    if (!this.needSelectDate) {
      this.selectedDate = this.payDate;
    }
    const url = 'Payment/AdjustParticipantBenefitSuspenseBalance';
    const dto = {
      'entityId': this.entityId,
      'clientId': this.clientId,
      'entityClientId': this.entityClientId,
      'participantId': this._memberId,
      'amount': data.Amount,
      'participantBenefitId': data.participantBenefitId,
      'payDate': this.selectedDate,
      'isWriteOff': data.WriteOff
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe((res) => {
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(res);
    }, err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  ngOnInit() {
    if (this._memberId != null) {
      const url = 'Payment/PayDateDetails?entityId=' + this.entityId + '&clientId=' + this.clientId + '&entityClientId=' + this.entityClientId + '&participantId=' + this._memberId;
      const observ$ = this.clientService.getWithUrl(url, {});
      observ$.subscribe((data) => {
        this.dates = [];
        if (data != null && data.billDetails != null) {
          data.billDetails.forEach(element => {
            this.dates.push(element.payDate.toLocaleDateString());
          });
        }
      });
    }
    this.getSuspenseComments();
  }

  moveSuspense(data) {
    const url = 'Payment/MoveSuspenseCommentBalance';
    const dto = {
      'suspenseAccountId': data.suspenseAccountId,
      'entityId': this.entityId,
      'clientId': this.clientId,
      'entityClientId': this.entityClientId,
      'participantId': this._memberId
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe((res) => {
      // this.member$ = this.getMember(this.selectedDate);
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(res);
      // this.getSuspenseComments() ;
    },
      err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  addSuspenseComment() {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: false,
        item: {
          'entityId': this.entityId, 'ClientId': this.clientId, 'suspenseAccountId': this.suspenseAccountId, 'depositdate': this.depositdate,
          'entityClientId': this.entityClientId, 'participantId': this._memberId, 'suspenseType': 'Member'
        },
        formid: 'suspensecomment',
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSuspenseComments();
      this.refresh.emit();
    });
  }

  getSuspenseComments() {
    // tslint:disable-next-line:max-line-length
    const url = 'Payment/SuspenseAccountCommentBalance?suspenseType=member&entityId=' + this.entityId + '&clientId=null&entityClientId=' + this.entityClientId + '&participantId=' + this._memberId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.suspenseComments = data.suspenseAmountBalanceDTO;
    });
  }

}
