import { AddressDTO } from '@models/shared/address.dto';
import { PhoneDTO } from '@models/shared/phone.dto';
import { EmailDTO } from '@models/shared/email.dto';

export class ContactDTO {
  lastName: string;
  firstName: string;
  address: AddressDTO;
  phones: PhoneDTO[];
  emails: EmailDTO[];
}
