import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ClientService } from '@services/client/client.service';
import { forkJoin } from 'rxjs';
import { MemberState } from '@store/main/member/member.state';
import { getMemberState } from '@store/main/member/member.selector';
import { getMainState, isMainStateMutating } from '@store/main/main.selector';
import { ActivatedRoute } from '@angular/router';
import { SelectionState } from '@store/selection/selection.state';
import { getSelectionState } from '@store/selection/selection.selector';
import { BaseComponent } from '@shared/components/base.component';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { MainState } from '@store/main/main.state';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SpinnerService } from '@shared/services/spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { MemberSearchComponent } from '@modules/main/client-management/suspense-management/membersearch.component';
import { getAuthState } from '@store/auth/auth.selector';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { AuthService } from '@services/auth/auth.service';
@Component({
  selector: 'app-suspense-management',
  templateUrl: './suspense-management.component.html',
  styleUrls: ['./suspense-management.component.scss'],
})
export class SuspenseManagementComponent
  extends BaseComponent
  implements OnInit
{
  // tslint:disable-next-line:max-line-length
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    protected clientService: ClientService,
    protected spinner: SpinnerService,
    public store: Store<AppState>,
    private notification: NzNotificationService
  ) {
    super();
  }

  mapOfExpandData: { [key: string]: boolean } = {};
  entities;
  selectionState?: SelectionState;
  listOfData: any[] = [];
  rowTotal = 20;
  curIndex = 1;
  bordered = false;
  loading = false;
  sizeChanger = false;
  pagination = true;
  header = true;
  title = true;
  footer = true;
  fixHeader = false;
  size = 'small';
  expandable = true;
  checkbox = true;
  allChecked = false;
  searchFormValue;
  searchUrl;
  indeterminate = false;
  searchForm = 'suspensesearch';
  item;
  id;
  displayData: any[] = [];
  simple = false;
  memberState;
  noResult = false;
  searchData;
  position = 'bottom';
  entityList;
  currentEntityId;
  currentEntityClientId;
  sortName: string | null = null;
  sortValue: string | null = null;
  sortType;
  searchValue = '';
  from;
  filters: any = [];
  orilistOfData: any = [];
  hasTypeMember: boolean = false;

  pageIndexChange(e) {
    this.reload();
  }

  subscribeMainState() {
    this.store
      .select(getMainState)
      .pipe(untilComponentDestroyed(this))
      .subscribe((x) => this.MainStateChange(x));
  }

  private MainStateChange(state: MainState) {
    if (!isMainStateMutating(state)) {
    }
  }

  currentPageDataChange(
    $event: Array<{
      name: string;
      age: number;
      address: string;
      checked: boolean;
      expand: boolean;
      description: string;
    }>
  ): void {
    this.displayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.displayData.filter((value) => !value.disabled);
    const allChecked =
      validData.length > 0 &&
      validData.every((value) => value.checked === true);
    const allUnChecked = validData.every((value) => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = !allChecked && !allUnChecked;
  }

  checkAll(value: boolean): void {
    this.displayData.forEach((data) => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  expand(data, event) {
    if (event === true) {
      this.mapOfExpandData = {};
      this.mapOfExpandData[data.Id] = true;
    }
  }

  refresh(data) {
    this.reload();
  }

  suspenseAmountChange($event, data) {
    this.reload();
  }

  onMoveToMember(data) {
    let dialogRef;
    dialogRef = this.dialog.open(MemberSearchComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: false,
        formid: 'membersuspensesearch',
        item: data,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.reload();
      }
      //  this.loadMemberData();
      // this.GetUrlAction();
    });
  }

  reload() {
    const url = 'Payment/SuspenseAccount-Lookup';
    if (this.searchFormValue == null) {
      this.searchFormValue = {
        EntityId: '4E4D0CD3-830A-4D3D-A41F-E9CF0740A75D',
      };
    }

    this.hasTypeMember = false;
    this.searchFormValue.pageSize = 10;
    this.searchFormValue.pageIndex = this.curIndex - 1;
    this.spinner.show();
    const observ$ = this.clientService.postWithUrl({
      url,
      dto: this.searchFormValue,
    });
    observ$.subscribe(
      (data) => {
        this.spinner.hide();
        const oriList = [...this.listOfData];
        if (this.listOfData.length === 0) {
          this.listOfData = [];
        } else {
          this.listOfData = [];
          // this.listOfData = [...this.listOfData];
        }
        this.rowTotal = data.totalRowsCount;
        this.curIndex = data.pageIndex + 1;
        // this.listOfData = [];
        // this.listOfData = data;
        data.suspenseInformation.forEach((element) => {
          if (element.suspenseAccountType === 'MEMBER') {
            this.hasTypeMember = true;
          }

          if (
            element.entityClientNumber !== '' &&
            element.entityClientNumber !== null
          ) {
            element.entityClientNumber = ' - ' + element.entityClientNumber;
          }
          if (element.entityClientNumber == null) {
            element.entityClientNumber = '';
          }
          let found;
          switch (element.suspenseAccountType) {
            case 'GROUP':
              this.listOfData.push({
                Id: element.clientId,
                Name: element.clientName + ' - ' + element.clientNumber,
                Date: element.depositdate,
                Balance: element.amount,
                LastActionDate: element.lastActionDate,
                EntityId: element.entityId,
                ClientId: element.clientId,
                SuspenseCommentAmount: element.suspenseCommentAmount,
                SuspenseComment: element.comment,
                Type: 'Group',
              });
              break;
            case 'SECTION':
              this.listOfData.push({
                Id: element.entityClientId,
                Name:
                  element.entityClientName +
                  ' - ' +
                  element.clientNumber +
                  element.entityClientNumber,
                Date: element.depositdate,
                Balance: element.amount,
                EntityId: element.entityId,
                ClientId: element.clientId,
                EntityClientId: element.entityClientId,
                SuspenseCommentAmount: element.suspenseCommentAmount,
                SuspenseComment: element.comment,
                Type: 'Group-Section',
              });
              break;
            case 'ENTITY':
              found = oriList.find(function (el) {
                return el.Id === element.suspenseAccountId;
              });

              if (found) {
                found.Balance = element.amount;
                found.SuspenseCommentAmount = element.suspenseCommentAmount;
                found.Id = element.suspenseAccountId;
                found.suspenseAccountId = element.suspenseAccountId;
              } else {
                found = oriList.find(function (el) {
                  return el.Id === element.parentSuspenseAccountId;
                });
                if (found) {
                  this.mapOfExpandData[element.suspenseAccountId] =
                    this.mapOfExpandData[found.Id];
                  found.Balance = element.amount;
                  found.SuspenseCommentAmount = element.suspenseCommentAmount;
                  found.Id = element.suspenseAccountId;
                  found.suspenseAccountId = element.suspenseAccountId;
                  found.Date = element.depositdate;
                }
              }
              this.listOfData.push({
                Id: element.suspenseAccountId,
                Name: element.entityName,
                Date: element.depositdate,
                Balance: element.amount,
                EntityId: element.entityId,
                suspenseAccountId: element.suspenseAccountId,
                SuspenseCommentAmount: element.suspenseCommentAmount,
                SuspenseComment: element.comment,
                Type: 'Entity',
              });
              break;
            case 'MEMBER':
              this.listOfData.push({
                Id: element.participantGuid,
                Name: element.firstName + ' ' + element.lastName,
                groupNumber: element.clientNumber + element.entityClientNumber,
                memberNumber: element.memberNumber,
                Date: element.depositdate,
                EntityId: element.entityId,
                ClientId: element.clientId,
                SuspenseCommentAmount: element.suspenseCommentAmount,
                Balance: element.amount,
                SuspenseAccountId: element.suspenseAccountId,
                Type: 'Member',
                participantGuid: element.participantGuid,
                EntityClientId: element.entityClientId,
                SuspenseComment: element.comment,
              });
              break;
            default:
              break;
          }
        });

        this.listOfData.sort(function (a: any, b: any) {
          return a.Type > b.Type ? 1 : -1;
        });
        this.orilistOfData = [...this.listOfData];
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  searchCust(key, obj) {
    // obj.filterType = 'in';
    const index = this.filters.findIndex((x) => x.key === key);
    if (index === -1) {
      this.filters.push({
        value: this.searchValue,
        key,
        type: 'search',
        columnType: obj.columnType,
      });
    } else {
      this.filters[index] = {
        value: this.searchValue,
        key,
        type: 'search',
        columnType: obj.columnType,
      };
    }
    this.search();
  }

  resetCust(key) {
    const index = this.filters.findIndex((x) => x.key === key);
    if (index === -1) {
      // this.filters.push({value: this.searchValue, key , type: 'search'});
    } else {
      this.filters[index] = { value: '', key, type: 'search' };
      this.search();
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.sortType = 'text';
    this.search();
  }

  filter(value, key, type): void {
    const index = this.filters.findIndex((x) => x.key === key);
    if (index === -1) {
      this.filters.push({ value, key, type });
    } else {
      this.filters[index] = { value, key, type };
    }
    // this.filters.
    /* this.filterKey = key;
    this.filterVal = value; */
    this.search();
  }

  search(): void {
    /** filter data **/
    this.listOfData = [...this.orilistOfData];
    this.filters.forEach((element) => {
      if (element.type === 'in') {
        if (element.value.length !== 0) {
          this.listOfData = this.listOfData.filter((item) => {
            const index = element.value.findIndex(
              (e) => e === item[element.key]
            );
            return index !== -1;
          });
        }
      }
      if (element.type === 'search') {
        if (element.value !== '') {
          this.listOfData = this.listOfData.filter((item) => {
            if (item[element.key] == null) {
              return false;
            }
            if (element.columnType === 'date') {
              const mydate = new Date(element.value);
              if (item[element.key].getTime() === mydate.getTime()) {
                return true;
              }
              return false;
            } else {
              const index = item[element.key]
                .toLowerCase()
                .indexOf(element.value.toLowerCase());
              return index !== -1;
            }
          });
        }
      }
    });
    const filteredData = [...this.listOfData];
    /* const filterFunc = (item: { name: string; age: number; address: string }) =>
      (this.searchAddress ? item.address.indexOf(this.searchAddress) !== -1 : true) &&
      (this.listOfSearchName.length ? this.listOfSearchName.some(name => item.name.indexOf(name) !== -1) : true);
    const data = this.listOfData.filter(item => filterFunc(item)); */
    /** sort data **/

    if (this.sortName && this.sortValue) {
      this.listOfData = this.listOfData.sort((a, b) =>
        this.sortValue === 'ascend'
          ? // tslint:disable-next-line:no-non-null-assertion
            a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : // tslint:disable-next-line:no-non-null-assertion
          b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
      );
      this.listOfData = [...this.listOfData];
    } else {
      // this.rows = this.rows;
      if (filteredData != null) {
        this.listOfData = [...filteredData];
      }
    }
  }

  onTransfer(data) {
    const url = 'Payment/SuspenseTransfer';
    let dto;
    switch (data.Type) {
      case 'Entity':
        dto = {
          suspenseType: 'ENTITY',
          suspenseAccountId: data.suspenseAccountId,
          entityId: data.EntityId,
          Amount: data.Balance,
        };
        break;
      case 'Group':
        dto = {
          suspenseType: 'GROUP',
          suspenseAccountId: data.suspenseAccountId,
          entityId: data.EntityId,
          clientId: data.ClientId,
          Amount: data.Balance,
        };
        break;
      case 'Group-Section':
        dto = {
          suspenseType: 'SECTION',
          suspenseAccountId: data.suspenseAccountId,
          entityId: data.EntityId,
          clientId: data.ClientId,
          entityClientId: data.EntityClientId,
          Amount: data.Balance,
        };
        break;
      case 'Member':
        dto = {
          suspenseType: 'MEMBER',
          suspenseAccountId: data.suspenseAccountId,
          entityId: data.EntityId,
          clientId: data.ClientId,
          entityClientId: data.EntityClientId,
          participantId: data.participantGuid,
          Amount: data.Balance,
        };
        break;
    }
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe(
      (res) => {
        this.notification.blank('Transfer  successfully', ' ');
        this.listOfData = [];
        this.reload();
      },
      (err) => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  onRefund(data) {
    let formId = 'suspenserefund';
    let url, url1;
    data.entityId = data.EntityId;
    data.refundAmount = data.Balance;
    data.name = data.Name;
    data.suspenseAmount = data.Balance;
    switch (data.Type) {
      case 'Entity':
        data.suspenseType = 'entity';
        url =
          'Payment/SuspenseBalance?suspenseType=entity&entityId=' +
          data.EntityId;
        break;
      case 'Group':
        data.suspenseType = 'group';
        data.clientId = data.ClientId;
        url =
          'Payment/SuspenseBalance?suspenseType=group&entityId=' +
          data.EntityId +
          '&clientId=' +
          data.ClientId;
        break;
      case 'Group-Section':
        data.suspenseType = 'section';
        data.clientId = data.ClientId;
        data.entityClientId = data.EntityClientId;
        url =
          'Payment/SuspenseBalance?suspenseType=section&entityId=' +
          data.EntityId +
          '&clientId=' +
          data.ClientId +
          '&entityClientId=' +
          data.EntityClientId;
        break;
      case 'Member':
        data.suspenseType = 'member';
        data.clientId = data.ClientId;
        data.entityClientId = data.EntityClientId;
        data.participantId = data.participantGuid;
        formId = 'suspenserefundmember';
        url1 =
          'Participant/' +
          data.EntityId +
          '/00000000-0000-0000-0000-000000000000/' +
          data.participantGuid +
          '/Profile';
        // tslint:disable-next-line:max-line-length
        url =
          'Payment/SuspenseBalance?suspenseType=member&entityId=' +
          data.EntityId +
          '&clientId=' +
          data.ClientId +
          '&entityClientId=' +
          data.EntityClientId +
          '&participantid=' +
          data.participantGuid;
        break;
    }
    const observ$ = this.clientService.getWithUrl(url, {});

    if (data.Type === 'Member') {
      const addressObv$ = this.clientService.getWithUrl(url1, {});
      forkJoin(observ$, addressObv$).subscribe(([balance, address]) => {
        data['RefundAmount'] = Number(data['Balance']);
        data['address1'] = address['address1'];
        data['address2'] = address['address2'];
        data['city'] = address['city'];
        data['state'] = address['state'];
        data['zip'] = address['zip'];
        const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
          width: '90%',
          data: {
            addNew: false,
            item: data,
            formid: formId,
            prePath: '',
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.listOfData = [];
          this.reload();
        });
      });
    } else {
      observ$.subscribe((bal) => {
        const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
          width: '90%',
          data: {
            addNew: false,
            item: data,
            formid: formId,
            prePath: '',
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.reload();
        });
      });
    }
  }

  private subscribeSelectionState() {
    this.store
      .select(getSelectionState)
      .pipe(untilComponentDestroyed(this))
      .subscribe((x) => {
        this.selectionState = x;
        if (
          x != null &&
          x.entity != null &&
          this.currentEntityId !== x.entity.id
        ) {
          this.currentEntityId = x.entity.id;
          if (this.searchFormValue == null) {
            this.searchFormValue = {};
          }

          if (this.from == null) {
            this.searchFormValue.filterByType = 'Group';
            this.searchFormValue.filterByName = x.client.entityClientName;
            this.searchFormValue.EntityId = this.currentEntityId;
            this.currentEntityClientId = x.client.entityClientId;
            this.listOfData = [];
            this.reload();
          } else {
            this.searchFormValue.filterByType = 'Member';
            this.searchFormValue.EntityId = this.currentEntityId;
            this.currentEntityClientId = x.client.entityClientId;
            this.listOfData = [];
            this.reload();
          }
        }
      }
    )
  }

  private subscribeMemberState() {
    this.store
      .select(getMemberState)
      .pipe(untilComponentDestroyed(this))
      .subscribe((x) => this.memberStateChange(x));
  }

  private memberStateChange(state: MemberState) {
    this.memberState = state;
    if (this.searchFormValue == null) {
      this.searchFormValue = {};
    }

    // For APWU, Cobra and UTC(RTX) ID Number replaced with External ID
    switch (state.member.entityId) {
      case 'ac586073-69d6-41bf-875c-eb754f750457': // UTC
        this.searchFormValue.MemberNumber = state.member.profile.externalId;
        break;
      case 'd6a2ff7e-bba0-48fa-9396-a6b202631669': // DBS Cobra
        this.searchFormValue.MemberNumber = state.member.profile.externalId;
        break;
      case '68bc62e6-dd96-4428-91e8-efe285bd23a0': // APWU
        this.searchFormValue.MemberNumber = state.member.profile.externalId;
        break;
      default:
        this.searchFormValue.MemberNumber = state.member.profile.idNumber;
    }
    // if(state.member.entityId === 'd6a2ff7e-bba0-48fa-9396-a6b202631669' || state.member.entityId === 'ac586073-69d6-41bf-875c-eb754f750457') {
    //   this.searchFormValue.MemberNumber = state.member.profile.externalId;
    // } else {
    //   this.searchFormValue.MemberNumber = state.member.profile.idNumber;
    // }

    if (this.from == 'memb') {
      /* this.listOfData = [];
      this.reload(); */
    }
  }

  ngOnInit() {
    this.from = this.route.snapshot.data.from;
    if (this.from == 'memb') {
      this.subscribeMemberState();
    }
    this.subscribeSelectionState();
    const url = 'Entity/Entities';
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.entities = data;
      this.entityList = data.map((x) => {
        const container = {};
        container['entityId'] = x.id;
        container['entityName'] = x.entityName;
        return container;
      });
    });
  }

  onCancel() {
    history.back();
  }

  public onSearch() {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        paraArr: this.entityList,
        addNew: false,
        item: this.searchFormValue,
        formid: this.searchForm,
        prePath: '',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        //  this.searchData = result;
        this.searchFormValue = result;
        this.listOfData = [];
        this.reload();
      }
    });
  }

  noResultChange(status: boolean): void {
    this.listOfData = [];
    if (!status) {
      this.ngOnInit();
    }
  }
}
