import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '@services/client/client.service';
// import { SecAnswerForm } from '../shared/interfaces';

@Component({
  selector: 'app-retrieve-username-submit',
  templateUrl: './retrieve-username-submit.component.html',
  styleUrls: ['./retrieve-username-submit.component.scss']
})
export class RetrieveUsernameSubmitComponent implements OnInit {
  config = window['___alliant-config-eniac-APP___']
  primaryColor = this.config.primaryColor
  bgImage = this.config.bgImage
  resetForm: UntypedFormGroup
  userName: string = ''
  email: any;
  questionId: any;
  question: any;
   // entityClientId: string = '42445106-54b5-44d3-a134-a2dfa7837c17'   //LEEBA  hardcoded value
  // entityId: string = 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b'   //LEEBA ClientID hardcoded value
  entityId: string = '9808F856-F047-4843-BEC8-277E414FC649'   //TMAIT  hardcoded value
  entityClientId: string = '00000000-0000-0000-0000-000000000000'   //TMAIT ClientID hardcoded value
  alertRed: boolean = false
  alertGreen: boolean = false
  alertMessage: any = ''


  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    protected clientService: ClientService
  ) { }

  ngOnInit(): void {
    this.email = this.route.snapshot.queryParams["ml"]
    this.questionId = this.route.snapshot.queryParams["QId"]
    this.question = this.route.snapshot.queryParams["sqt"]

    this.resetForm = new UntypedFormGroup({
      securityAnswer: new UntypedFormControl('', Validators.required),
    })
  }

  onSubmit() {

    if (this.resetForm.valid) {
      const user = {
        userName: this.userName,
        email: this.email,
        SecurityQuestions: [
          {
            securityQuestionId: this.questionId,
            securityAnswer: this.resetForm.value.securityAnswer
          }
        ]

      }
      const observ$ = this.clientService.postWithUrl({ url: `/CaseManagement/RetrieveUser/${this.entityId}/${this.entityClientId}`, dto: user });
      observ$.subscribe( res => {
          this.alertGreen = true
      }, err => {
        this.alertRed = true
        this.alertMessage = err.error
      })
    } else {
      this.resetForm.markAllAsTouched()
    }
  }
}

