import { PipeTransform, Pipe } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(items: any[], filterText: string, pathsToSearch?: string[]): any[] {
    return filterProps(items, filterText, pathsToSearch);
  }
}

export function filterProps(
  items: any[],
  filterText: string,
  pathsToSearch?: string[]
): any[] {
  if (!items || !filterText) {
    return items;
  }

  filterText = filterText.toLowerCase();

  pathsToSearch = _.filter(pathsToSearch, p => !!p);
  // if paths are not provided search all own properties
  const paths = _.isEmpty(pathsToSearch) ? _.keys(items[0]) : pathsToSearch;
  return _.filter(items, item => isMatch(item, filterText, paths));
}

export function isMatch(
  item: any,
  filterText: string,
  pathsToSearch: string[]
): boolean {
  if (!item || !filterText) {
    return false;
  }

  return _.some(pathsToSearch, function(path) {
    const value = _.get(item, path);
    if (!value) {
      return false;
    }

    return _.includes(value.toString().toLowerCase(), filterText);
  });
}

@Pipe({ name: 'search' })
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (searchText==null || searchText==''){
      return items;
    }
      
    return items.filter(item=>{
      for (const [key, value] of Object.entries(item)) {
        if(value!=null && value.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
        {
          return true;
        }
      //  return false;
      }
      return false;
    });
  }
  searchValue(it){

    return true;
  }
}