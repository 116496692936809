import { Action } from '@ngrx/store';
export enum MainActionTypes {

    RESET_MAIN = '[MAIN] RESET_MAIN',
    GET_MAIN_URL = '[MAIN] GET_MAIN_URL',
    GET_MAIN_URL_SUCCESS = '[MAIN] GET_MAIN_URL_SUCCESS',
    GET_MAIN_URL_FAIL = '[MAIN] GET_MAIN_URL_FAIL',
    POST_MAIN_URL = '[MAIN] POST_MAIN_URL',
    POST_MAIN_URL_SUCCESS = '[MAIN] POST_MAIN_URL_SUCCESS',
    POST_MAIN_URL_FAIL = '[MAIN] POST_MAIN_URL_FAIL',
    SAVE_MAIN= '[MAIN] SAVE_MAIN',
    SAVE_MAIN_SUCCESS= '[MAIN] SAVE_MAIN_SUCCESS',
    SAVE_MAIN_FAIL = '[MAIN] SAVE_MAIN_FAIL',
}
export class SaveMainAction implements Action {
    readonly type = MainActionTypes.SAVE_MAIN;
    constructor(
      public payload: {
        Url: string;
        Data: any;
      }
    ) {}
  }

export class SaveMainSuccessAction implements Action {
    readonly type = MainActionTypes.SAVE_MAIN_SUCCESS;
    constructor(public payload: { dto: any }) {}
  }
export class GetMainUrlAction implements Action {
    readonly type = MainActionTypes.GET_MAIN_URL;
    constructor(
      public payload: {
        Url: string;
        Prop: string;

      }
    ) {}
  }
  export class PostMainUrlAction implements Action {
    readonly type = MainActionTypes.POST_MAIN_URL;
    constructor(
      public payload: {
        Url: string;
        Prop: string;
        Data: any;
      }
    ) {}
  }
  export class SaveMainFailAction implements Action {
    readonly type = MainActionTypes.SAVE_MAIN_FAIL;
    constructor(public payload: {data:any, error: any }) {}
  }

  export class GetMainUrlSuccessAction implements Action {
    readonly type = MainActionTypes.GET_MAIN_URL_SUCCESS;
    constructor(public payload: {prop: string, member: any }) {}
  }
  export class PostMainUrlSuccessAction implements Action {
    readonly type = MainActionTypes.POST_MAIN_URL_SUCCESS;
    constructor(public payload: {prop: string, member: any }) {}
  }
  export class GetMainUlrFailAction implements Action {
    readonly type = MainActionTypes.GET_MAIN_URL_FAIL;
    constructor(public payload: { error: any }) {}
  }
  export class PostMainUlrFailAction implements Action {
    readonly type = MainActionTypes.POST_MAIN_URL_FAIL;
    constructor(public payload: { error: any }) {}
  }
export type AllMainActions =
| SaveMainSuccessAction
| GetMainUrlAction
| GetMainUrlSuccessAction
| GetMainUlrFailAction
| SaveMainFailAction
| PostMainUrlAction
| PostMainUrlSuccessAction
| PostMainUlrFailAction
;
