import { Component, OnInit, Input, Inject } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { Observable } from 'rxjs';
import { HttpService } from '@shared/api/http.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AddNewLevel2Component } from './addnewlevel2.component';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { ExcelService } from '@services/excel/excel.service';
import { ClientService } from '@services/client/client.service';

@Component({
  selector: 'app-coverageterminationrule',
  templateUrl: './coveragetermination.component.html',
  styleUrls: ['./benefit.component.scss']
})

export class CoverageTerminationComponent extends BaseComponent implements OnInit {
  @Input()
  coverageTerminationDTO;
  @Input()
  benefitEntityPolicyId;
  _jsonURL = 'coverageterminationgrid';
  gridConfigs: any;
  entityId;
  loading = false;
  constructor(
    private httpser: HttpService,
    public dialog: MatDialog,
    protected routeHelper: RouteHelperService,
    protected route: ActivatedRoute,
    protected excelService: ExcelService,
    protected clientService: ClientService
  ) {
    super();
  }

  ngOnInit() {
    this.entityId = this.routeHelper.getEntityId(this.route);
    this.getJSON().subscribe(data1 => {
      //  this.spinner.hide();
      const json = JSON.parse((<any>data1.jsonText) as string);
      if (data1 === null) {
        this.gridConfigs = [];
      } else {
        this.readJson(json);
      }
    });
  }

  download() {
    const excelrows: any = [];
    this.coverageTerminationDTO.forEach(row => {
      const obj = {};
      this.gridConfigs.forEach(col => {
        obj[col.columnLabel] = row[col.columnName];
      });
      excelrows.push(obj);
    });
    this.excelService.exportAsExcelFile(excelrows, 'coveragetermination');
  }

  onEdit(data) {
    const item = { ...data, entityId: this.entityId }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: false,
        item: item,
        formid: 'coveragetermination',
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitEntityPolicyTerminationRules/?entityId=${this.entityId}&benefitEntityPolicyId=${this.benefitEntityPolicyId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe((data1) => {
          this.coverageTerminationDTO = data1;
        },
        );
      }
    });
  }

  readJson(data1) {
    this.gridConfigs = (<any>data1).GridConfig;
  }

  public getJSON(): Observable<any> {
    // return this.http.get(this._jsonURL);
    return (
      this.httpser.get('json?path=' + this._jsonURL, {
        urlParams: null,
        options: {
          isMock: false
        }
      })
    );
  }

  onAdd(){
    let formid = 'newcoveragetermination';
    const dialogRef = this.dialog.open(AddNewLevel2Component, {
      width: '90%',
      data: {
        addNew: true,
        formid,
        item: {
          entityId: this.entityId,
          benefitEntityPolicyId: this.benefitEntityPolicyId
        },
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitEntityPolicyTerminationRules/?entityId=${this.entityId}&benefitEntityPolicyId=${this.benefitEntityPolicyId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe((data1) => {
          this.coverageTerminationDTO = data1;
        },
        );
      }
    }); 
  }
}
