<h6>Waiver History:<br/></h6>
<div *ngIf="history == null || history == false" style="margin-bottom: 15px; padding: 15px;border:1px solid rgba(16, 140, 146, 0.5);background: rgba(16, 140, 146, 0.02)">
<table  >
    <tr>
        <td>
            Bill Waiver Effective Start Date:
        </td>
        <td>&nbsp;{{waiver[0].billWaiverEffectiveStartDate  | date}}</td>
    </tr>
    <tr>
        <td>
            Bill Waiver Effective End Date:
        </td>
        <td>
            <div *ngIf='waiver[0].isBillWaiver===false'>&nbsp;{{waiver[0].billWaiverEffectiveEndDate | date}}</div>
           
        </td>
    </tr>
    <tr>
        <td>Is BillWaiver:</td>
        <td>
            &nbsp;{{waiver[0].isBillWaiver}}
        </td>

    </tr>
</table>
<a class="PatchFont" style="float: right" *ngIf="waiver!=null && (waiver.length > 1 )" (click)="history = true" >History</a>

</div>

<div  *ngIf="waiver!=null && waiver.length > 0 && (history != null && history == true)"  style="margin-bottom: 15px; padding: 15px;border:1px solid rgba(16, 140, 146, 0.5);background: rgba(16, 140, 146, 0.02)">
    <table *ngFor = "let item of waiver" >
        <tr >
            <td>
                Bill Waiver Effective Start Date:
            </td>
            <td>&nbsp;{{item.billWaiverEffectiveStartDate  | date}}</td>
        </tr>
        <tr>
            <td>
                Bill Waiver Effective End Date:
            </td>
            <td>
                <div *ngIf='item.isBillWaiver===false'>&nbsp;{{item.billWaiverEffectiveEndDate | date}}</div>
                
            </td>
        </tr>
        <tr>
            <td>Is Bill Waiver:</td>
            <td>
                &nbsp;{{item.isBillWaiver}}
            </td>
    
        </tr>
        <tr>
            <td><br /></td>
        </tr>
    </table>
    <br/>
    <a class="PatchFont" style="float: right"  (click)="history = false" >Collapse</a>
    <br />
</div>

