import { Component, OnInit } from '@angular/core';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { DynamicDialogFormComponent, } from '@shared/components/dynamic_form/dynamic-form.component';
@Component({
  selector: 'app-underwriting',
  templateUrl: '../dynamic_form/dynamic-dialog-form.component.html'
})

export class UnderWritingComponent extends DynamicDialogFormComponent {

  public onchangeObvSub(event) {
    // tslint:disable-next-line:max-line-length
    const myurl = 'Enrollment/Underwritting?entityId=' + this.formValue.entityId + '&participantId=' + this.formValue.participantId + '&BenefitEntityPolicyId=' + event;
    const observ$ = this.clientService.getWithUrl(myurl, {}).pipe(untilComponentDestroyed(this));
    observ$.subscribe((data) => {
      const curval = this.memberComponent.getFormValue();
      if (data != null && data.currentStatus !== 'Declined' && data.currentStatus !== 'Approved') {
        if (this.id === 'enrollmentdecline') {
          // tslint:disable-next-line:max-line-length
          this.formValue = { ...data, entityId: curval['entityId'], currentStatusId: curval['currentStatusId'], actionDate: new Date(), reasonId: '', campaignCode: '', entityClientId: curval['entityClientId'] };
          // tslint:disable-next-line:max-line-length
          //              this.formValue = {...curval, appDate: data.appDate, remarks: data.remarks , status: data.currentStatus,  responseDate: data.responseDate, appReceiveDate: data.appReceiveDate, actionDate: new Date(), appId: data.appId, reasonId: '', campaignCode: '', createdDate: data.createdDate};
        } else {
          this.formValue = { ...data, status: data.currentStatus, entityId: curval['entityId'], entityClientId: curval['entityClientId'] };
          // tslint:disable-next-line:max-line-length
          // this.formValue = {...curval, appDate: data.appDate, remarks: data.remarks , status: data.currentStatus, responseDate: data.responseDate, AppReceiveDate: data.appReceiveDate, actionDate: data.actionDate, appId: data.appId, reasonId: data.reasonId, campaignCode: data.campaignCode, source: data.source};
          // this.saveButton = 'false';
        }
      } else {
        // tslint:disable-next-line:max-line-length
        this.formValue = { ...curval, remarks: '', status: '', actionDate: new Date(), AppReceiveDate: new Date(), appId: '00000000-0000-0000-0000-000000000000', reasonId: '' };
        if (this.id === 'enrollmentdecline') {
          // this.saveButton = 'Save';
        } else {
          // this.saveButton = 'Save';
        }
      }

    });
  }
}
