<div [formGroup]='coverageForm'>
    <div *ngIf="ratemessage !=null && ratemessage != ''" style="margin-top: -10px;margin-bottom: 10px">
        <span class="PatchFontRed">{{ratemessage}} <br /></span>  
    </div>  
    <ng-container *ngIf="coverages.length>0" >
      <span> Select Coverage Amount</span> <br />
      <select class="form-control  input-sm col-4" style="margin-bottom: 10px"
              formControlName="coverage"
              (change)="onChange($event)">
              <option></option>     
        <option *ngFor="let opt of  coverages "
                [value]="opt.benefitEntityPolicyDetailCoverageId">{{opt.benefitEntityPolicyDetailCoverageName}}</option>
      </select>
    </ng-container>

  </div>