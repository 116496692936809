<div class="ant-popover-content">
    <div class="ant-popover-arrow" *ngIf="nzPopconfirmShowArrow"></div>
    <div class="ant-popover-inner">
      <div>
        <div class="ant-popover-inner-content">
          <div class="ant-popover-message">
          
          <div class="ant-popover-message-title">{{ nzTitle }}</div>
          </div>
          <div class="ant-popover-buttons">
            <button nz-button [nzSize]="'small'" (click)="onCancel()">
              <ng-container *ngIf="nzCancelText">{{ nzCancelText }}</ng-container>
              <ng-container *ngIf="!nzCancelText">{{ 'Modal.cancelText' | nzI18n }}</ng-container>
            </button>
            <button nz-button [nzSize]="'small'" [nzType]="nzOkType" (click)="onConfirm()">
              <ng-container *ngIf="nzOkText">{{ nzOkText }}</ng-container>
              <ng-container *ngIf="!nzOkText">{{ 'Modal.okText' | nzI18n }}</ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>