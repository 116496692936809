<div class="w-100">
  <img style="width: 100%; position: fixed; object-fit: fit;" src="{{bgImage}}" alt="">
</div>

<div class="row container loginscreen" style="padding: 0px !important; margin: 0 auto;">
  <div class="col-xl-2 col-lg-2 col-md-2"></div>
  <div style="padding: 10% 0px 0px 0px; margin: 0px;"
    class="row col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 LoginPanel">
    <div class="row" style="margin: 0px; background: rgba(255, 255, 255, 0.9) none repeat scroll 0% 0%;">
      <div class="row no-gutters" style="padding: 20px;">
        <form [formGroup]="changePasswordForm" class="col-lg-7 col-md-7 col-sm-7 col-xs-12" (submit)="onSubmit()"
          autocomplete="off">
          <div style="border-radius: 6px; margin: 0 25px 0 0; background: rgba(255, 255, 255, 0.95)">
            <div class="description">
              <p class="text-center">Please enter your current and <br> new password to update</p>
            </div>
            <div style="padding: 3% 3% 3% 7%; margin: 0px auto" class="row">
              <div class="row no-gutters" style="margin: 0px 0px 0px -10px; padding: 10px; width: 100%;">

                <div class="col-12"><span style="font-family: Calibri; font-size: 14px">Temporary Password</span></div>
                <input autocomplete="off" class="form-control RegisterClass" formControlName="currentPassword" type="text" required>
                <div class="validation">
                  <span
                    *ngIf="changePasswordForm.get('currentPassword').touched && changePasswordForm.get('currentPassword').invalid">Please
                    enter temporary password</span>
                </div>

                <div class="col-12"><span style="font-family: Calibri; font-size: 14px">New Password</span></div>
                <input autocomplete="off" class="form-control RegisterClass" formControlName="newPassword" type="password" required>
                <div class="validation">
                  <span
                    *ngIf="changePasswordForm.get('newPassword').touched && changePasswordForm.get('newPassword').invalid">Please
                    enter valid Password</span>
                </div>

                <div class="col-12"><span style="font-family: Calibri; font-size: 14px">Re-enter New Password</span>
                </div>
                <input autocomplete="off" class="form-control RegisterClass" formControlName="repeatPassword" type="password" required>
                <div class="validation"
                  *ngIf="changePasswordForm.errors?.mismatch && (changePasswordForm.get('newPassword').touched || changePasswordForm.get('newPassword').invalid)">
                  Passwords must match.
                </div>
              </div>
              <div>
                <div style="display: inline; padding-top: 3px">
                  <button mat-flat-button type="submit"
                    [style.background-color]="primaryColor"
                    style="border: 2px solid; float: left; margin: 0 15px 15px 0; width: 100%; width: 180px; border: none; font-size: 18px; color: #fff">
                    Update password
                  </button>

                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12" style="height: 100%;">
          <div id="divIntroduction" style="padding-right: 10px; padding-left: 10px; background-size: cover;">
            <div style="background-color: transparent;">

              <div style="text-align: center;">
                <div>
                  <div style="padding: 10px 0 60px;">
                    <h3><u>Update password</u></h3>
                  </div>
                </div>
                <div style="text-align: left;">
                  <span style="text-align: left; font-size:16px; font-weight: 550;">
                    Passwords must be at least 10 characters and contain at least one character from the following: <br>
                    upper case (A-Z), <br> lower case (a-z), <br> number (0-9), <br> special character ( @ # $ % + * ! =
                    )
                  </span>
                </div>
                <div style="text-align: left; font-size:16px; margin-top: 30px; ">
                  <p>
                    <br>
                    Questions on enrollment or program offerings?
                    <br>
                    <b style="font-family: Calibri; font-size: 18px">Call: {{phoneNum}}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>