import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import InputMask from 'inputmask';

export const PHONE_INPUT_MASK = '(999) 999-9999';
const PHONE_DASH_REGEX = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
const PHONE_REGEX = /^\d{10}$/;
const DASH = '-';

@Injectable({
  providedIn: 'root'
})
export class PhoneFormatterService {
  format(phone: string): string {
    if (!this.isValidPhone(phone)) {
      return phone;
    }

    // verify if its already formatted
    if (_.includes(phone, DASH)) {
      return phone;
    }

    return InputMask.format(phone, { mask: PHONE_INPUT_MASK });
  }

  parse(value: string): string {
    return this.isValidPhone(value)
      ? InputMask.unmask(value, { mask: PHONE_INPUT_MASK })
      : '';
  }

  isValidPhone(phone: string): boolean {
    return phone && _.includes(phone, DASH)
      ? PHONE_DASH_REGEX.test(phone)
      : PHONE_REGEX.test(phone);
  }
}
