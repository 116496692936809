import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ClientMngState, StateProp } from '@store/main/client-management/client-management.state';
import { ClientState } from '@store/main/client-management/client/client/client.state';
import { ClientDTO } from '@models/client/client.dto';
import { AppState } from '@store/app.state';



///

export const getClientMngState = createFeatureSelector<ClientMngState>(StateProp);

export const getClientState = createSelector(
  getClientMngState,
  (state: ClientMngState) => {
    return state.client;
  }
);
export const isClientStateMutating = createSelector(
  getClientState,
  (state: ClientState) =>
    state && (state.loading || state.saving || state.deleting)
);
export const isClientStateValid = createSelector(
  getClientState,
  (state: ClientState) =>
    state && !state.loadError && !state.saveError && !state.deleteError
);

export const getClientListState = createSelector(
  getClientMngState,
  (state: ClientMngState) => state.clients
);

export const getClient = createSelector(
  getClientState,
  (state: ClientState) => state.client
);

export const getClientInfo = createSelector(
  getClient,
  (dto: ClientDTO) => dto.info
);

export const getClientContact = createSelector(
  getClient,
  (dto: ClientDTO) => dto.contact
);

export const getClientConfig = createSelector(
  getClient,
  (dto: ClientDTO) => dto.config
);

export const getClientBenefitList = createSelector(
  getClient,
  (dto: ClientDTO) => dto.benefits
);

export const getClientBenefit = createSelector(
  getClient,
  (dto: ClientDTO) => dto.benefit
);
