<div *ngIf="show && formGroup.invalid"
     class="alert alert-danger"
     role="alert">
     <h5 class="col-12">Please fix the errors below and try again.</h5>
  <ul>

    <li *ngFor="let error of errors">
      <span [innerHtml]="error.message"></span>
    </li>
  </ul>
</div>

