import { Component, OnInit, Input, Inject } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { Observable } from 'rxjs';
import { AddNewLevel2Component } from './addnewlevel2.component';
import { HttpService } from '@shared/api/http.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { ExcelService } from '@services/excel/excel.service';
import { ClientService } from '@services/client/client.service';

@Component({
  selector: 'app-benefitreductionsrule',
  templateUrl: './reductionsrule.component.html',
  styleUrls: ['./benefit.component.scss']
})
export class BenefitReductionRuleComponent extends BaseComponent implements OnInit {
  @Input()
  coverageReductionsRuleDTO;
  @Input()
  benefitEntityPolicyId;
  _jsonURL = 'reductionsrulegrid'; gridConfigs: any; entityId; loading = false
  constructor(
    private httpser: HttpService, 
    public dialog: MatDialog,
    protected routeHelper: RouteHelperService, 
    protected route: ActivatedRoute, 
    protected excelService: ExcelService,
    protected clientService: ClientService
  ) {
    super();
  }

  readJson(data1) {
    this.gridConfigs = (<any>data1).GridConfig;
  }

  public getJSON(): Observable<any> {
    if (this.entityId == '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
      this._jsonURL = 'hmreductionsrulegrid';
    } else {
      this._jsonURL = 'reductionsrulegrid';
    }
    return (
      this.httpser.get('json?path=' + this._jsonURL, {
        urlParams: null,
        options: {
          isMock: false
        }
      })
    );
  }

  ngOnInit() {
    this.entityId = this.routeHelper.getEntityId(this.route);
    this.getJSON().subscribe(data1 => {
      //  this.spinner.hide();
      const json = JSON.parse((<any>data1.jsonText) as string);

      if (data1 === null) {
        this.gridConfigs = [];
      } else {
        this.readJson(json);
      }
    });
  }

  download() {
    const excelrows: any = [];
    this.coverageReductionsRuleDTO.forEach(row => {
      const obj = {};
      this.gridConfigs.forEach(col => {
        obj[col.columnLabel] = row[col.columnName];
      });
      excelrows.push(obj);
    });
    this.excelService.exportAsExcelFile(excelrows, 'reductionsrule');
  }

  onEdit(data) {
    let formid;
    if (this.entityId == '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
      formid = 'hmreductionsrule';
    } else {
      formid = 'reductionsrule';
    }
    const item = { ...data, entityId: this.entityId }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: false,
        item: item,
        formid: formid,
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitEntityPolicyReductionRules/?entityId=${this.entityId}&benefitEntityPolicyId=${this.benefitEntityPolicyId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe(data1 => {
          this.coverageReductionsRuleDTO = data1;
        },
        );
      }
    });
  }

  onAdd(){
    let formid;
    formid = 'newreductionsrule';
    const dialogRef = this.dialog.open(AddNewLevel2Component, {
      width: '90%',
      data: {
        addNew: true,
        formid,
        item:{entityId:this.entityId,benefitEntityPolicyId:this.benefitEntityPolicyId},
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitEntityPolicyReductionRules/?entityId=${this.entityId}&benefitEntityPolicyId=${this.benefitEntityPolicyId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe((data1) => {
          this.coverageReductionsRuleDTO = data1;
        },
        );
      }
    });
     
  }
}
