import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[app-matinputssn]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, 
      useExisting: MatInputSSNDirective },
    { provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputSSNDirective),
      multi: true,}
  ]
})

export class MatInputSSNDirective {
  private _value: string | null;
  ctrlDown = false;
  constructor(private elementRef: ElementRef<HTMLInputElement>,
  ) { }

  get value(): string | null {
    return this._value;
  }

  @Input('value')
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  // private formatValue(value: string | null) {
  //   if (value !== null && value != '') {
  //     this.elementRef.nativeElement.value = 'xxxxxxxxxx';
  //   } else {
  //     this.elementRef.nativeElement.value = '';
  //   }
  // }

  //REFACTORED PART
  private formatValue(value: string | null) {
    this.elementRef.nativeElement.value = value ? 'xxxxxxxxxx' : '';
  }

  // private unFormatValue() {
  //   if (this._value) {
  //     this.elementRef.nativeElement.value = this._value;
  //   } else {
  //     this.elementRef.nativeElement.value = '';
  //   }
  // }

  //REFACTORED PART (the nullish coalescing operator ?? handels the default value in case this._value is null or undefined:)
  private unFormatValue() {
    this.elementRef.nativeElement.value = this._value ?? '';
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    const ctrlKey = 17,
      cmdKey = 91,
      vKey = 86,
      cKey = 67;
    if (event.keyCode === ctrlKey || event.keyCode === cmdKey) {
      this.ctrlDown = true;
    } else {
      if (this.ctrlDown === true) {
        if (event.keyCode === vKey) {
          this.ctrlDown = false;
          return;
        }
        this.ctrlDown = false;
      }
    }

    const specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Control'];

    if (specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (event.keyCode >= 48 && event.keyCode <= 57 || (event.keyCode >= 96 && event.keyCode <= 105)) {
    } else {
      event.preventDefault();
    }

    const current: string = this.elementRef.nativeElement.value;
    if (current.length >= 9 && this.elementRef.nativeElement.selectionEnd == this.elementRef.nativeElement.selectionStart) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    // here we cut any non numerical symbols
    this._value = value.replace(/[^\d.-]/g, '');
    this._onChange(this._value); // here to notify Angular Validators
  }

  @HostListener('mouseover') 
  onMouseOver() { }

  @HostListener('mouseout') 
  onMouseOut() { }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue();
  }

  _onChange(value: any): void { }

  writeValue(value: any) {
    this._value = value;
    this.formatValue(this._value); // format Value
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() { }
}

@Directive({
  selector: 'span[appssn]',

})

export class MatSSNDirective {
  constructor(private elementRef: ElementRef<HTMLInputElement>,
  ) { }

  @Input() appssn: string;

  @HostListener('mouseover') onMouseOver() {
    this.elementRef.nativeElement.textContent = this.appssn;
  }

  @HostListener('mouseout') onMouseOut() {
    this.elementRef.nativeElement.textContent = 'xxx-xx-xxxx';
  }
}

