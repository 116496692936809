import { HostListener, Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {
  Router,
  NavigationError,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel
} from '@angular/router';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BaseComponent } from '@shared/components/base.component';
import { SpinnerService } from '@shared/services/spinner.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { LogoutAction } from '@store/auth/auth.actions';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent extends BaseComponent implements OnInit {
  @ViewChild('defaultTabButtons')
  private defaultTabButtonsTpl: TemplateRef<any>;
  loading = false;
  ref; 
  logintype: any;
  title = 'eniac-admin';
  year; 
  userActivity; 
  logouting = false;
  userInactive: Subject<any> = new Subject();
  config

  constructor(private modal: NzModalService,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private spinner: SpinnerService,
    private ngxSpinner: NgxSpinnerService,
    // private logger: LoggerService
  ) {
    super();

    // this.logger = this.logger.create(this);
    this.setTimeout();
    this.userInactive.subscribe(() => {
      if (this.logouting == true) {
        this.logouting = false;
        this.ref.close();
        if (this.router.url != '/login') {
          this.store.dispatch(new LogoutAction());
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }
      }
      else {
        if (this.router.url != '/login') {
          this.showConfirm();
        }
      }
    }
    );
    this.spinner.registerCallbacks({
      onShow: () => this.ngxSpinner.show(),
      onHide: () => this.ngxSpinner.hide()
    });
  }
  showConfirm(): void {
    this.logouting = true;
    clearTimeout(this.userActivity);
    this.setTimeout();
    this.ref = this.modal.confirm({
      nzTitle: '<i>Application will log out in 1 minute</i>',
      nzContent: '<b>Click OK to continue</b>',
      nzOnOk: () => {
        this.logouting = false;
        clearTimeout(this.userActivity);
        this.setTimeout();
      }
    });
  }

  ngOnInit(): void {
    this.isLogintype()
    var d = new Date();
    this.year = d.getFullYear();
    /* this.router.events
      .pipe(untilComponentDestroyed(this))
      .subscribe((event: RouterEvent) => this.handleRouterEvent(event)); */
  }

  isLogintype() {
    this.config = window['___alliant-config-eniac-APP___']
    this.logintype = this.config.logintype
    if (typeof this.logintype === "undefined") {
      this.logintype = 'admin'
    } 
  }

  private handleRouterEvent(event: RouterEvent) {
    if (!event) {
      return;
    }

    switch (event.constructor) {
      case NavigationStart:
        return this.handleNavigationStart(event);
      case NavigationEnd:
        return this.handleNavigationEnd(event);
      case NavigationCancel:
        return this.handleNavigationCancel(event);
      case NavigationError:
        return this.handleNavigationError(event);
      default:
      // do nothing
    }
  }

  setTimeout() {
    if (this.logouting == true) {
      this.userActivity = setTimeout(() => this.userInactive.next(undefined), 60000);
    } else {
      this.userActivity = setTimeout(() => this.userInactive.next(undefined), 60000 * 14);
    }

  }

  @HostListener('window:mousemove') refreshUserState() {
    if (this.logouting == false) {
      clearTimeout(this.userActivity);
      this.setTimeout();
    }

  }
  private handleNavigationStart(event: RouterEvent) {
    // this.logger.debug('NAV START: ' + event.url);
    this.updateLoadingState(true);
  }

  private handleNavigationEnd(event: RouterEvent) {
    // this.logger.debug('NAV END: ' + event.url);
    this.updateLoadingState(false);
  }

  private handleNavigationCancel(event: RouterEvent) {
    // this.logger.debug('NAV CANCEL: ' + event.url);
    this.updateLoadingState(false);
  }

  private handleNavigationError(event: RouterEvent) {
    // this.logger.debug('NAV ERROR. VERIFY ROUTE: ' + event.url);
    this.updateLoadingState(false);
  }

  private updateLoadingState(loading: boolean) {
    // setTimeout(() => {
    // this.logger.debug('this.loading:' + loading);
    // this.loading = loading;
    // }, 0);
  }
}
