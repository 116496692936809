import { Component, OnInit, Input, Inject } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '@shared/services/spinner.service';
import { Observable } from 'rxjs';
import { HttpService } from '@shared/api/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
@Component({
  selector: 'app-benefitcoverage',
  templateUrl: './coverage.component.html',
  styleUrls: ['./benefit.component.scss']
})
export class BenefitCoverageComponent extends BaseComponent implements OnInit {

  @Input()
  benefitEntityPolicyCoverageDTO;
  @Input()
  benefitEntityPolicyId;
  mapOfExpandData: { [key: string]: boolean } = {};
  expandable = true; gridConfigs: any; _jsonURL = 'coveragesgrid';
  editorOptions: JsonEditorOptions = new JsonEditorOptions();
  EntityClientId: string
  EntityId: string

  constructor(private httpser: HttpService, 
    protected spinner: SpinnerService,
    protected route: ActivatedRoute,
    protected routeHelper: RouteHelperService,
    public dialog: MatDialog, 
    protected clientService: ClientService) {
    super();
  }

  public getJSON(): Observable<any> {
    // return this.http.get(this._jsonURL);
    return (
      this.httpser.get('json?path=' + this._jsonURL, {
        urlParams: null,
        options: {
          isMock: false
        }
      })
    );
  }

  ngOnInit() {
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
    this.getJSON().subscribe(data1 => {
      //  this.spinner.hide();
      const json = JSON.parse((<any>data1.jsonText) as string);
      if (data1 === null) {
        this.gridConfigs = [];
      } else {
        this.readJson(json);
      }
    });
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
  }

  onAdd() {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: true,
        item: { benefitEntityPolicyId: this.benefitEntityPolicyId },
        formid: 'benefitcoverage',
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const url = `CaseManagement/BenefitEntityPolicyDetailCoverages/?entityId=${this.EntityId}&benefitEntityPolicyId=${this.benefitEntityPolicyId}`
        const  observ$ = this.clientService.getWithUrl(url, { });
        observ$.subscribe( data1 => {
          this.benefitEntityPolicyCoverageDTO = data1;
          },
          );
      }
  });
  }

  onEdit(data) {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: false,
        item: data,
        formid: 'benefitcoverage',
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitEntityPolicyDetailCoverages/?entityId=${this.EntityId}&benefitEntityPolicyId=${this.benefitEntityPolicyId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe(data1 => {
          this.benefitEntityPolicyCoverageDTO = data1;
        },
        );
      }
    });
  }

  expand(data, event) {
    if (event === true) {
      this.mapOfExpandData = {};
      this.mapOfExpandData[data.benefitEntityPolicyDetailCoverageId] = true;
    }
  }

  getJson(data) {
    return JSON.parse(data);
  }

  readJson(data1) {
    this.gridConfigs = (<any>data1).GridConfig;
  }
}
