<app-navbar></app-navbar>
<router-outlet></router-outlet>

<router-outlet name="print"></router-outlet>
<!-- <button
      class="btn btn-outline-secondary"
      mwlConfirmationPopover
      [popoverTitle]="popoverTitle"
      [popoverMessage]="popoverMessage"
      placement="left"
      (confirm)="confirmClicked = true"
      (cancel)="cancelClicked = true">
      Click me!
    </button> -->