import { Component, OnInit, Input, Inject } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { ActivatedRoute } from '@angular/router';
import { MemberState } from '@store/main/member/member.state';
//import { MemberDTO } from '@models/shared/member.dto';
import { HistoryComponent } from '@shared/components/dynamicplan/history.component';

import { Store } from '@ngrx/store';
import { getMemberState } from '@store/main/member/member.selector';
import { AppState } from '@store/app.state';
import { SpinnerService } from '@shared/services/spinner.service';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { GetMemberAction } from '@store/main/member/member.actions';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicDialogGridComponent } from '@shared/components/dynamic_grid/dynamic-grid.component';
@Component({

  templateUrl: './benefitsummary.component.html',
})

export class BenefitSummaryComponent extends BaseComponent implements OnInit {
  EntityId: string;
  MemberId: string;
  EntityClientId;
  currentEnrollment;
  unenrolledList: string[] = [];
  dependentsInfo;
  benefitEntityList: any = [];
  qualCodeText: string = 'Qualification Code: '
  paths;
  loading = false; 
  ClientId;
  memberState: MemberState;
  constructor(
    private store: Store<AppState>,
    public spinner: SpinnerService,
    protected clientService: ClientService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private routeHelper: RouteHelperService) {
    super();
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.MemberId = this.routeHelper.getMemberId(this.route);
    this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
  }

  get memberName(): string {
    if (
      !this.memberState ||
      !this.memberState.member ||
      !this.memberState.member.profile
    ) {
      return '';
    }
    return `${this.memberState.member.profile.firstName} ${this.memberState.member.profile.lastName
      }`;
  }

  history(item) {
    const dialogRef = this.dialog.open(HistoryComponent, {
      width: '90%',
      data: {
        entityId: this.routeHelper.getEntityId(this.route),
        entityClientId: this.routeHelper.getEntityClientId(this.route),
        clientId: this.routeHelper.getClientId(this.route),
        participantId: this.routeHelper.getMemberId(this.route),
        benefitEntityPolicyId: item.benefitEntityPolicyId,
        benefitEntityId: item.benefitEntityId,
        // item: item,
        formid: 'activity'
      }
    });
  }

  totalCost() {
    let cost = 0;
    if (this.currentEnrollment != null && this.currentEnrollment.enrollmentSummary != null) {
      this.currentEnrollment.enrollmentSummary.forEach(element => {
        if (element.currentEnrollment != null && element.currentEnrollment.rate != null && element.currentEnrollment.rate.length > 0) {
          cost = cost + element.currentEnrollment.rate[0].rate;
        }
      });
    }
    return cost;
  }

  ngOnInit() { 
    this.dynamicEntityValue()
    this.ClientId = '00000000-0000-0000-0000-000000000000'; // this.routeHelper.getClientId(this.route);
    this.store.dispatch(new GetMemberAction({ EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId }));
    this.subscribeMemberState();
    let url = 'Enrollment/EnrollmentSummary?entityid=' + this.EntityId + '&entityclientid=' + this.EntityClientId
      + '&participantId=' + this.MemberId + '&benefitEntityId';

    this.loading = true;
    this.spinner.show();
    const obv$ = this.clientService.getWithUrl(url, {}).pipe(untilComponentDestroyed(this));
    obv$.subscribe((data) => {
      this.currentEnrollment = data;
      if (this.currentEnrollment != null && this.currentEnrollment.enrollmentSummary != null) {
        this.currentEnrollment.enrollmentSummary.forEach(element => {
          // if (element.currentEnrollment != null) {
          // tslint:disable-next-line:max-line-length
          const myurl = 'Enrollment/UnderwrittingAll?entityId=' + this.EntityId + '&participantId=' + this.MemberId + '&BenefitEntityPolicyId=' + element.benefitEntityPolicyId;
          const observ$ = this.clientService.getWithUrl(myurl, {}).pipe(untilComponentDestroyed(this));
          observ$.subscribe((data1) => {
            element.underWriting = data1;
            if (element.underWriting != null && element.underWriting.length > 0) {
              element.underWritingStatus = element.underWriting[0].enrollmentApplicationDetails[0].statusId;
              element.underWritingId = element.underWriting[0].appId;
            }
          });
          // tslint:disable-next-line:max-line-length
          const myurl1 = 'genericapi/Query?typeName=Waiver&entityId=' + this.EntityId + '&participantId=' + this.MemberId + '&benefitEntityPolicyId=' + element.benefitEntityPolicyId;
          const observ11$ = this.clientService.getWithUrl(myurl1, {}).pipe(untilComponentDestroyed(this));
          observ11$.subscribe((data1) => {
            element.waiver = data1.details;
          });
          // } else {
          if (element.currentEnrollment == null) {
            this.unenrolledList.push(element.benefitEntityName);
          }
          // }
        });
      }
      this.loading = false;
      this.spinner.hide();
      this.benefitEntityList = new Set<string>(this.unenrolledList);
    }, err => {
        this.loading = false;
      });
    url = 'Entity/ClientProfile?entityClientId=' + this.EntityClientId;
    const observ1$ = this.clientService.getWithUrl(url, {}).pipe(untilComponentDestroyed(this));
    observ1$.subscribe((data: any) => {
      this.paths = [{
        name: 'Home',
        path: '/main/user-dashboard'
      },
      {
        name: data.entityName,
        path: '/main/clients/' + this.EntityId
      },
      {
        name: data.entityClientName,
        path: '/main/clients/' + this.EntityId + '/' + this.EntityClientId
      },
      {
        name: 'Dashboard',
        path: '../dashboard'
      },
      {
        name: 'Enrollment Summary',
        path: ''
      }
      ];
    });
    url = 'Participant/' + this.EntityId + '/' + this.MemberId + '/DependentLists';
    const obv1$ = this.clientService.getWithUrl(url, {}).pipe(untilComponentDestroyed(this));
    obv1$.subscribe((data) => { 
      this.dependentsInfo = data.dependentInformationDTO;
      // this.populatedepinfo(this.dependentsInfo);
    });
  }
  /*     populatedepinfo(dependentsInfo) {
        dependentsInfo.forEach(element => {
  
        });
      } */
  private subscribeMemberState() {
    this.store
      .select(getMemberState)
      .pipe(untilComponentDestroyed(this))
      .subscribe(x => this.memberStateChange(x));
  }
  private memberStateChange(state: MemberState) {
    this.memberState = state;
  }

  dynamicEntityValue() {
    if (this.EntityId === 'd6a2ff7e-bba0-48fa-9396-a6b202631669') {
      this.qualCodeText = 'Volume: '
    }
  }

  onInfoClick(PlanName) {
    // tslint:disable-next-line: triple-equals
    const dualScreenLeft = window.screenLeft;
    // tslint:disable-next-line: triple-equals
    const dualScreenTop = window.screenTop;
    // tslint:disable-next-line: max-line-length
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    // tslint:disable-next-line: max-line-length
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    const w = width * .9;
    const h = height * .8;
    const left = ((width / 2) - (w / 2)) + dualScreenLeft;
    // $('.ProductOverViewBlock').css('display', 'block');
    /* const element = document.getElementById('ProductOverViewBlock');
    element.style.display = 'block' */
    // tslint:disable-next-line: max-line-length
    const newWindow = window.open('https://staging.abcsys.com/theboss/Documents/' + PlanName + '.pdf', 'Video link', 'location=no,toolbar=no,menubar=no,resizable=no,scrollbars=yes, width=' + w + ', height=' + h + ', top=' + 140 + ', left=' + left);
    // Puts focus on the newWindow
    if (newWindow !== null && window.focus) {
      newWindow.focus();
    }
  }
}
