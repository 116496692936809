import { Action } from '@ngrx/store';
import { ClientHeaderDTO } from '@models/client/genericclient.dto';

export enum ClientListActionTypes {
  GET_CLIENT_LIST = '[GENERIC] GET_CLIENT_LIST',
  GET_CLIENT_LIST_SUCCESS = '[GENERIC] GET_CLIENT_LIST_SUCCESS',
  GET_CLIENT_LIST_FAIL = '[GENERIC] GET_CLIENT_LIST_FAIL'
}

export class GetClientListAction implements Action {
  readonly type = ClientListActionTypes.GET_CLIENT_LIST;
  constructor(public payload: { entityId: string }) {}
}

export class GetClientListSuccessAction implements Action {
  readonly type = ClientListActionTypes.GET_CLIENT_LIST_SUCCESS;
  constructor(public payload: { clients: ClientHeaderDTO[] }) {}
}

export class GetClientListFailAction implements Action {
  readonly type = ClientListActionTypes.GET_CLIENT_LIST_FAIL;
  constructor(public payload: { error: any }) {}
}

export type AllClientListActions =
  | GetClientListAction
  | GetClientListSuccessAction
  | GetClientListFailAction;
