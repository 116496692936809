import { Component, OnInit } from '@angular/core';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { DynamicDialogFormComponent, } from '@shared/components/dynamic_form/dynamic-form.component';
@Component({
  selector: 'app-simplifiedunderwriting',
  templateUrl: './simplifieduw.component.html'
})

export class SimplifiedUWComponent extends DynamicDialogFormComponent {
  uwresponse; resAction; actionText; code;
  formValue1; formValue1save; reloadUW = false; actionUW; mode = {};
  onActionComplete1() {
    this.reloadUW = true;
  }
  onCancel() {
    this.dialogRef.close(this.reloadUW);
  }
  onClick() {
    this.formValue1 = { ...this.formValue1save };
  }
  onAction1() {
    const curval: any = this.memberComponent.getFormValue();
    const dto1: any = {};
    dto1.heightFeet = curval.heightFeet;
    dto1.heightInches = curval.heightInches;
    dto1.weight = curval.weight;
    dto1.participantId = curval.participantId;
    dto1.entityId = curval.entityId;
    dto1.entityClientId = curval.entityClientId;
    dto1.benefitEntityPolicyId = curval.benefitEntityPolicyId;
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    const appDate = mm + '/' + dd + '/' + yyyy;
    dto1.appDate = appDate;
    const observ$ = this.clientService.postWithUrl({ url: 'Enrollment/SimplifiedUnderwritting', dto: dto1 }).pipe(untilComponentDestroyed(this));
    observ$.subscribe((res) => {

      this.resAction = res.action;
      if (this.resAction === 'Refer to NYL') {
        this.actionUW = 'pending';
      } else {
        this.actionUW = 'decline';
      }
      this.code = res.code;
      this.actionText = res.actionText;
      // tslint:disable-next-line:max-line-length
      const myurl = 'Enrollment/Underwritting?entityId=' + curval.entityId + '&participantId=' + curval.participantId + '&BenefitEntityPolicyId=' + curval.benefitEntityPolicyId;
      const observInner1$ = this.clientService.getWithUrl(myurl, {}).pipe(untilComponentDestroyed(this));

      observInner1$.subscribe((data) => {
        if (data != null && data.currentStatus !== 'Declined' && data.currentStatus !== 'Approved') {

          // tslint:disable-next-line:max-line-length
          this.formValue1 = { createdBy: 'vw', entityClientId: curval.entityClientId, entityId: curval.entityId, participantId: curval.participantId, benefitEntityPolicyId: curval.benefitEntityPolicyId, remarks: data.remarks, status: data.currentStatus, actionDate: data.actionDate, appId: data.appId, reasonId: '', benefitEntityId: curval.benefitEntityId };
          this.formValue1save = { ...this.formValue1 };
        } else {
          // tslint:disable-next-line:max-line-length
          this.formValue1 = { createdBy: 'vw', entityClientId: curval.entityClientId, entityId: curval.entityId, participantId: curval.participantId, benefitEntityPolicyId: curval.benefitEntityPolicyId, remarks: '', status: '', actionDate: '', appId: '00000000-0000-0000-0000-000000000000', reasonId: '', benefitEntityId: curval.benefitEntityId };
          this.formValue1save = { ...this.formValue1 };
        }
        this.uwresponse = true;
      });

    },
      err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

}
