import { Component, Input } from '@angular/core';
import {PopconfirmRef} from '../confirmoverlay/popconfirm-ref'
@Component({
  selector: 'confirm-overlay',
  templateUrl: './notificationoverlay.component.html',
  styleUrls: ['./notificationoverlay.component.scss']

})
export class NotificationOverlayComponent  { 

  nzOkText="OK";
  nzCancelText="Cancel";
  nzOkType = 'primary';
  nzPopconfirmShowArrow = false;
  nzTitle ='';
  messageType='';
  constructor(private popconfirmRef:PopconfirmRef
    ) {
   this.nzTitle=popconfirmRef._message;
   this.messageType=popconfirmRef._messageType;
   setTimeout(()=>this.popconfirmRef.close('ok') , 10000);
  }
  onConfirm(){
    this.popconfirmRef.close('ok');
  }
  onCancel(){
    this.popconfirmRef.close('cancel');
  }
}