<div class="col-12">
  <app-form-error [formGroup]="form" *ngIf="form!=null" [show]="submitted">
  </app-form-error>

  <h3><br />{{name}} <a style="float:right" class="mat-flat-button mat-primary"
      [routerLink]="['../']">COBRA Dashboard</a></h3>
  <form [formGroup]="form" *ngIf="form!=null">

    <div class="row">
      <ng-container *ngFor="let data of reportControls">
        <div class='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
          <span *ngIf="data.text != null">{{data.text}}</span>
          <ng-container [ngSwitch]="data.controlCode">
            <!-- <app-date-input *ngSwitchCase="'DATEPICK'" [formControlName]="data.text">
                                </app-date-input> -->
            <div class="date_wrap" *ngSwitchCase="'DATEPICK'">
              <input [matDatepicker]="picker" [formControlName]="data.text" class="input date_wrap__input form-control">
              <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <input class="form-control" *ngSwitchCase="'TEXTBOX'" type="text" [formControlName]="data.text" />
            <select class="form-control" *ngSwitchCase="'DROPDOWN'" [formControlName]="data.text">
              <option></option>
              <option *ngFor="let opt of convtojson(data.value ).values" [value]="opt.key">{{opt.value}}</option>
            </select>
            <nz-select nzMode="tags" *ngSwitchCase="'MSDROPDOWN'" style="width: 100%;" [formControlName]="data.text">

              <nz-option *ngFor="let option of data.value" [nzLabel]="option.value" [nzValue]="option.key">
              </nz-option>
            </nz-select>
          </ng-container>
        </div>
      </ng-container>
    </div>

  </form>
  <!-- <div *ngIf="form!=null">
            {{form.value | json}}
    </div> -->
  <br />
  <div class="col-12 row no-gutters pull-right " style="text-align: right;margin: 0px;padding: 0px;">
    <button class="mat-flat-button mat-primary" (click)="onGenerateReport()">Generate
      Report</button>&nbsp;&nbsp;
    <button class="mat-flat-button mat-primary" (click)="onGenerateTemplate()"
      *ngIf='template!=null'>Generate Document</button>&nbsp;&nbsp;
    <button class="mat-flat-button mat-primary" (click)="PrintReport()"
      *ngIf='data !=null'>Print</button>
    <button class="mat-flat-button mat-primary" (click)="onMarkPrinted()">Mark as
      Printed</button>&nbsp;&nbsp;
  </div>
  <br><br>
  <div *ngIf="data != null" class="col-12 row no-gutters "
    style="width:100%; overflow-x:visible  ;margin: 0px;padding: 0px;border:1px solid #48c5cb !important ">
    <br />
    <div class="col-12 row no-gutters " style="padding:15px;" *ngIf="headerColumnNames != null">
      <ng-container *ngFor="let header of headerColumnNames; let i = index">
        <div class='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
          <label>
            <div>{{header}}</div>
            <h6>{{headerData[0][i]}}</h6>
          </label>
        </div>

      </ng-container>
    </div>
    <div style="width:100%;overflow-x: auto;">
      <nz-table nzSize="small" [nzFrontPagination]="isPagingEnabled" [nzPageSize]="pagelength"
        [nzShowPagination]="isPagingEnabled" #basicTable class="table table-striped table-hover"
        style="margin-top: 10px" [nzData]="data">
        <thead class="thead-dark">
          <tr>
            <th [(nzChecked)]="isAllDisplayDataChecked" [nzIndeterminate]="isIndeterminate"
              (nzCheckedChange)="checkAll($event)"></th>
            <ng-container *ngFor="let access of columnNames;index as i;">
              <th nowrap="" *ngIf=' i!=0'>
                {{access}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                  ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
              </th>
            </ng-container>
            <th>Action</th>

          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of basicTable.data; index as i;">
            <tr class="ng-scope">
              <td nzShowCheckbox (nzCheckedChange)="checkedChange($event,i)" [(nzChecked)]="item.checked"></td>
              <ng-container *ngFor="let access of item.item;index as i1;">
                <td class="ng-binding" *ngIf=' i1!=0'>{{access}}</td>
              </ng-container>
              <td>


                <a (click)="printone(item)" class="PatchFont">Print</a>
              </td>

            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
    <br />
    <div class="col-12 row no-gutters " style="padding:15px;" *ngIf="footerColumnNames != null">
      <ng-container *ngFor="let header of footerColumnNames; let i = index">
        <div class='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
          <label>
            <div>{{header}}</div>
            <h6>{{footerData[0][i]}}</h6>
          </label>
        </div>
      </ng-container>
    </div>
  </div>
  <div id="ReportMainDiv" *ngIf="data != null" class="col-12 row no-gutters "
    style="width:100%; overflow-x:visible  ;margin: 0px;padding: 0px;border:1px solid #48c5cb !important;display: none ">
    <br />
    <div class="col-12 row no-gutters " style="padding:15px;" *ngIf="headerColumnNames != null">
      <ng-container *ngFor="let header of headerColumnNames; let i = index">
        <div class='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
          <label>
            <div>{{header}}</div>
            <h6>{{headerData[0][i]}}</h6>
          </label>
        </div>

      </ng-container>
    </div>
    <div style="width:100%;overflow-x: auto;">
      <div style="width:100%;overflow-x: auto;">
        <table class="table table-striped table-hover" style="margin-top: 10px">
          <thead class="thead-dark">
            <tr>
              <th *ngFor="let access of columnNames" nowrap="">
                {{access}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                  ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of data">
              <tr class="ng-scope" *ngIf="item.checked==true">
                <ng-container *ngFor="let access of item.item">
                  <td class="ng-binding">{{access}}</td>
                </ng-container>


              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <br />
    <div class="col-12 row no-gutters " style="padding:15px;" *ngIf="footerColumnNames != null">
      <ng-container *ngFor="let header of footerColumnNames; let i = index">
        <div class='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
          <label>
            <div>{{header}}</div>
            <h6>{{footerData[0][i]}}</h6>
          </label>
        </div>

      </ng-container>
    </div>



  </div>
</div>