import { Component, ViewChild, OnInit, AfterViewInit  } from '@angular/core';
import { take, finalize, catchError, defaultIfEmpty } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';
import { RouteHelperService } from '@shared/route/route-helper.service';
import {
  MemberListDTO,
} from '@models/member-list/member-list.dto';
import { ClientService } from '@services/client/client.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MemberService } from '@services/client/member.service';
import { SelectionService } from '@store/selection/selection.service';
import {
  MEMBER_ROUTE_SEGMENT,
  CLIENT_ROUTE_SEGMENT
} from '@shared/route/constants';
import {  combineLatest } from 'rxjs';
import {  GetMemberAction } from '@store/main/member/member.actions';
import * as _ from 'lodash';
import { EntityIdClientIdDTO } from '@models/entity/entity.dto';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DynamicSearchComponent} from '@shared/components/dynamic_search/dynamic_search.component';
import { EntityService } from '@services/client/entity.service';
import { ActivatedRoute } from '@angular/router';
import {UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import { getSelectionState } from '@store/selection/selection.selector';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { SelectionState } from '@store/selection/selection.state';
import { SpinnerService } from '@shared/services/spinner.service';
import { GetMemberUrlAction } from '@store/main/member/member.actions';
import {EnrollmentService} from './enrollment.service';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { BenefitEntityBandComponent } from '@shared/components/dynamicplan/benefitentityband.component';
@Component({
    selector: 'app-member-quickenrollment',
    templateUrl: './quickenrollment.component.html',
    styleUrls: ['./../dynamic.component.scss']
  })
  export class QuickEnrollmentComponent extends BaseComponent implements OnInit, AfterViewInit  {
    @ViewChild(BenefitEntityBandComponent)
    memberComponent: BenefitEntityBandComponent;
    memberList?: MemberListDTO | null; campaignCode;
  filterText: string;
  loadError: any;
  pageIndex = 0;
  pageSize = 10;
  searchResult; loading = false;
  participantId; entityId; clientId; entityClientId;  lastName; firstName; selectedArray;
  ///
  entityListWithClients: EntityIdClientIdDTO[] = [];
  selectedEntityClient: EntityIdClientIdDTO;
  ///
  addMemberRoute: string | null;
  selectionState?: SelectionState;
 // Temp coding
 BenefitsArray ; selectedBenefit; showconfirm;
 entityForm: UntypedFormGroup;
 initBenfitBandValue: any = {};
 initFormValue: any = {};
    constructor(
      private notification: NzNotificationService,
        protected fb: UntypedFormBuilder,
        private clientService: ClientService,
        private store: Store<AppState>,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private routeHelper: RouteHelperService,
        private selectionService: SelectionService,
        private memberService: MemberService,
        private entityService: EntityService,
        private spinner: SpinnerService, public enrollmentService: EnrollmentService
      ) {
        super();
      }
     /*  showconfirm() {
        if (this.selectedEntityClient.entityId !== '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
          return true;
        }
        return false;
      } */
    onCampaignCodeChanged() {

      }
    ngOnInit() {
        this.entityForm = this.fb.group({   });
        // this.subscribeSelectionState();
        this.initializeDropdown();
        /* if (this.selectedEntityClient.entityId !== '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
            this.showconfirm = true;
        } */
        this.showconfirm = true;
    }
    formInitialized(name: string, form: UntypedFormGroup) {
        setTimeout(() => {
            this.entityForm = this.fb.group({   });
            this.entityForm.setControl(name, form);

        });
    }
    onLoadingComplete(bandObj) {
      this.initBenfitBandValue = Object.assign({}, bandObj)
      const id = bandObj.benefitEntityId
      this.enrollmentService.LoadingComplete(bandObj, this.initFormValue , id) 


    }

    CheckExpandorCollapse(Ristrictions) {
        if (Ristrictions != null && Ristrictions.length() > 0 ) {
          return true
        } else {
          return false
        }
    }

    private initializeDropdown() {
        const entityListWithClients$ = this.entityService.getEntityListWithClients()
        const selectedEntityClients$ = this.selectionService.getSelectedEntityIdClientId()

        combineLatest(entityListWithClients$, selectedEntityClients$)
          .pipe(take(1))
          .pipe(untilComponentDestroyed(this))
          .subscribe(all => {

            const entityListWithClients: any = []; // all[0];
            all[0].forEach(item => {
                    if (item.entityClientId != null) {
                        entityListWithClients.push(item)
                    }
            });
            const selectedEntityClient = all[1];

            this.entityListWithClients = entityListWithClients
            this.selectDefaultDropdownItem(selectedEntityClient)
            this.selectedEntityClientChange(this.selectedEntityClient)
          });
      }
      private selectDefaultDropdownItem(
        selectedEntityClient: EntityIdClientIdDTO | null
      ) {
        const items = this.getMatchingDropdownItems(selectedEntityClient)
        if (!items || _.isEmpty(items)) {
          this.selectedEntityClient = this.entityListWithClients.sort()[0]
        } else {
          this.selectedEntityClient = items[0];
        }
      }
      private getMatchingDropdownItems(
        selectedEntityClient: EntityIdClientIdDTO | null
      ) {
        if (!selectedEntityClient || !selectedEntityClient.entityId) {
          return null;
        }

        let filtered = _.filter(
          this.entityListWithClients,
          x => x.entityId === selectedEntityClient.entityId
        );

        if (!selectedEntityClient.clientId) {
          return filtered;
        }

        filtered = _.filter(
          filtered,
          x => x.clientId === selectedEntityClient.clientId
        );

        if (!selectedEntityClient.entityClientNumber) {
          return filtered;
        }

        return _.filter(
          filtered,
          x => x.entityClientNumber === selectedEntityClient.entityClientNumber
        );
      }

    private subscribeSelectionState() {
        this.store
          .select(getSelectionState)
          .pipe(untilComponentDestroyed(this))
          .subscribe(x => {
            this.selectionState = x;
            this.addMemberRoute = this.getAddMemberRoute()
          });
      }
      generateRandNumber() {
        return Math.floor(Math.random() * 4) + 1;
      }
      private getAddMemberRoute() {
        if (
          !this.selectionState ||
          !this.selectionState.entity ||
          !this.selectionState.client
        ) {
          return '';
        }

        const clientsRoute = this.routeHelper.getClientsRoute({
          EntityId: this.selectionState.entity.id,
          ClientId: this.selectionState.client.clientId
        });
        return `${clientsRoute}/${CLIENT_ROUTE_SEGMENT.member}/dynamicform/addmember`;
      }

    getEntityClientDisplayText(entityClient: EntityIdClientIdDTO) {
        return _.compact([
          entityClient.entityName,
          entityClient.clientName,
          entityClient.clientCode,
          entityClient.entityClientNumber
        ]).join(', ');
      }

      ngAfterViewInit() { }

      isChanged(bandid, policyId, curtform) {
        const tt = this.initFormValue[bandid][policyId]
        // check effactiveDate
        if (tt == null) {
        }
        return this.enrollmentService.isChanged(tt, curtform)
      }

      onConfirm() {
        const cartItems: any = [];
        this.confirm(cartItems);
        if (cartItems.length > 0) {
          this.posturl('Enrollment/Enrollment', cartItems, false);
        } else {
          this.notification.blank( 'There is no changes in Enrollment to Submit', ' ')
        }
      }

      posturl(url, cartItems, refresh) {
        const apiObj = {
          entityId: this.selectedEntityClient.entityId,
          participantId: this.participantId,
          entityClientId: this.entityClientId,
          cartItems
        };
        const observ$ = this.clientService.postWithUrl({url, dto: apiObj}).pipe(untilComponentDestroyed(this))
        let error = false;
        this.loading = true;
        observ$.subscribe((data) => {
          this.loading = false;
          if (data.cartItems != null) {
            data.cartItems.forEach(element => {
              if (element.messages != null && element.messages.length > 0) {
                this.notification.blank( element.messages[0].message, ' ')
                error = true;
                return;
              }
            });
          }
          if (error === false && (data.messages == null || data.messages.length === 0)) {
            this.notification.blank( ' Posted successfully', ' ')
            this.initFormValue = JSON.parse(JSON.stringify(this.entityForm.value))
            if (refresh) {
              if ( this.selectedEntityClient.entityId != null) {
              this.store.dispatch(new GetMemberAction({
                EntityId: this.selectedEntityClient.entityId, 
                ClientId: this.clientId, 
                MemberId: this.participantId })
                )
              }
            }
          }
        }, error => {
          this.loading = false;
          this.notification.blank( ' Posted failed', ' ')
          }
        );
      }

      confirm( cartItems) {
        const enrollmententity = this.entityForm.value;
        this.enrollmentService.confirm(cartItems, enrollmententity, this.initFormValue)
      }

      edit() {
        let dialogRef ;
        this.searchResult.EntityClientId = this.entityClientId;
        dialogRef = this.dialog.open(DynamicDialogFormComponent, {
          width: '90%',
          data: {
            addNew: false,
            entityId: this.entityId,
            clientId: this.clientId,
            participantId: this.participantId,
            entityClientId: this.entityClientId,
            item: {...this.searchResult, EntityId: this.entityId, ClientId: this.clientId, EntityClientId: this.entityClientId},
            formid: 'profile'
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          // this.loadMemberData();
         /*  this.store.dispatch(new GetMemberUrlAction({
            Url: 'Participant/:EntityId/:ParticipantId/DependentLists', Prop: 'dependents',
            EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId
          })); */
        });
      }

      getProfile() {
        const url = 'Participant/' + this.entityId + '/' + this.clientId + '/' + this.participantId + '/Profile';
        const observ$ = this.clientService.getWithUrl(url, {}).pipe(untilComponentDestroyed(this))
        observ$.subscribe( data => {
          this.searchResult = data;
        });
      }

      onSearch() {
        let dialogRef ;

        dialogRef = this.dialog.open(DynamicSearchComponent, {
          width: '90%',
          data: {
            addNew: false,
            formid: 'membersearch',
            item: {entityClientId: this.selectedEntityClient.entityClientId, entityId: this.selectedEntityClient.entityId}
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.participantId = result.participantId
          this.firstName = result.firstName
          this.lastName = result.lastName
          this.clientId = result.clientId
          this.entityId = result.entityId
          this.entityClientId = result.entityClientId
         // this.searchResult = result;
          this.getProfile();
        });
      }
      selectedBenefitChange(benefit) {
        this.selectedArray = [];
        this.selectedArray.push(benefit);
      }
      selectedEntityClientChange(entityClient) {
        if (!entityClient) {
          return;
        }
        if (this.selectedEntityClient.entityId !== '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
          this.showconfirm = true;
        } else {
          this.showconfirm = false;
        }
        const myurl = 'Enrollment/BenefitVendorBenefitEntities?entityId=' + entityClient.entityId + '&entityclientId=' + entityClient.entityClientId
        const observ$ = this.clientService.getWithUrl(myurl, {}).pipe(untilComponentDestroyed(this))
        observ$.subscribe((data) => {
          this.BenefitsArray = data.benefitEntities
          this.selectedBenefit = null
          this.participantId = null
          this.firstName = null
          this.lastName = null
        });
      }
  }
