import * as _ from 'lodash';

export const KNOWN_ENTITY_NAME = {
  horace: 'Horace Mann',
  apwu: 'Postal Union',
  tenet: 'Tenet',
  cobra: 'DBS (Direct Billing System)',
  enterprise: 'Enterprise',
  texas: 'TMA Insurance Trust',
  Anthem: 'Anthem',
  TMAIT: 'TMA Insurance Trust',
  UNUM: 'UNUM',
  Essex: 'Essex Corporation',
  AGENT: 'Agent Management',
  LEEBA: 'Law Enforcement Employees Benevolent Association',
  FireFighters: 'Fire Fighters'
};
