<div class="row col-12">
  <ng-container *ngFor="let item of entities">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 p-2">
      <mat-card>
        <div class="d-flex flex-row">
          <img mat-card-sm-image src={{item.icon}}>
          <div class="px-4">
            <div style="font-weight: 550; font-size: 24px;">{{item.title}}</div>
            <div class="px-4" style="font-size: 18px; font-weight: 490; color: rgb(117, 117, 117)">{{item.vendors}}</div>
            <div class="py-2">
              <div style="font-size: 16px; font-weight: 450; color: black"><span>Enrollments: </span><span style="color:darkcyan">{{item.numEnrollments}}</span></div>
              <div style="font-size: 16px; font-weight: 450; color: black"><span>Dependents Enrolled: </span><span style="color:darkcyan">{{item.numDependents}}</span></div>
              <div style="font-size: 16px; font-weight: 450; color: black"><span>Last Total Bill Amount: </span><span style="color:darkcyan">{{item.lastBillAmt}}</span></div>
              <div style="font-size: 16px; font-weight: 450; color: black"><span>Is Evergreen Plan: </span><span style="color:darkcyan">{{item.isEvergreen}}</span></div>
              <div style="font-size: 16px; font-weight: 450; color: black"><span>Is ABC Enrollment: </span><span style="color:darkcyan">{{item.isABC}}</span></div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
</ng-container>
</div>