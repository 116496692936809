<div>
  <br />
  If you don't see your dependent listed, please <a class="default-font-Color" href="javascript:void(0)"
    (click)="addNew()"><u>click</u></a> here to add dependent.

  <br /><br />
  <div style="display:none">

  </div>
  <div>
    <div *ngFor=' let msg of dependentValidation()'>
      <font color="red"> {{msg}}</font>
    </div>

    <span *ngFor="let item of rows">
      <span class="default-font-Color-dark">
        <label nz-checkbox [ngModel]="'true'" *ngIf='selectEnrolled(item)==true'
          (ngModelChange)="enrollDependent($event,item)"></label>
        <label nz-checkbox [ngModel]="'false'" *ngIf='selectEnrolled(item)==false'
          (ngModelChange)="enrollDependent($event,item)"></label>
        <!-- <mat-checkbox  (change)="enrollDependent($event,item)">Check me!</mat-checkbox> -->
        &nbsp; <i title="{{item.genderText}}" *ngIf="item.gender == 'F'" class="fa fa-female"
          aria-hidden="true">&nbsp;</i>
        <i title="{{item.genderText}}" *ngIf="item.gender == 'M'" class="fa fa-male" aria-hidden="true">&nbsp;</i>
        <i title="{{item.relationShipText}}" *ngIf="item.relationship == 'C'" class="fa fa-child"
          aria-hidden="true">&nbsp;</i>
        <i title="{{item.relationShipText}}" *ngIf="item.relationship == 'S'" class="fa fa-user-plus"
          aria-hidden="true">&nbsp;</i>
        {{item.fullName}} ({{item.birthDate | date }}) <span style="color:gray;font-size: 12px">&nbsp; <u><a
              (click)="edit(item)">Edit</a></u>&nbsp;<u><a nz-popconfirm nzPopconfirmTitle="Are you sure delete this ?"
              (nzOnConfirm)="onDelete(item)">Delete</a></u></span> </span>
      <br>
    </span>
  </div>

</div>