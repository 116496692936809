<ng-container *ngIf="benefitEntities">
  <div class="row col-lg-12 col-md-12 col-sm-12 col-xs-12"
       style="padding: 0px; margin: 0px">
    <h5 class="col-lg-8 col-md-8 col-sm-8 col-xs-8 BenefitsCart"><i class="fa fa-shopping-cart BenefitsCart"></i>&nbsp;&nbsp;Benefits Cart</h5>
    <span></span>
  </div>
  <div *ngFor="let item of benefitEntities; index as i"
       style="border-bottom: 1px solid rgb(230, 230, 230)">
    <a id="v-pills-tab-{{item.benefitEntityId}}"
       class="nav-link"
       style="font-size:16px !important;font-weight: 400"
       [ngClass]="{'active show': (i === selected)}"
       (click)="onSelect(i)"
       data-toggle="pill"
       href="#v-pills-{{item.benefitEntityId}}"
       role="tab">{{item.benefitEntityDescription}} <br>
      <div *ngFor="let p of getPolicies(item.benefitEntityId) as policies">
        <div *ngIf="policies.length > 0 && benefitValue[p]?.tiers?.tierName!=null">
          <span class="SubPlan">{{benefitValue? benefitValue[p]?.benefitEntityPolicyDescription :'' }} - {{benefitValue? benefitValue[p]?.tiers?.tierName :'' }}</span>
          <div class="row" style="padding-top:0px">
            <div style="display:inline;text-align: left;padding-right: 0px" class="col-8 CostLabel">Effective Date:<span class="CartSubCost"> 04/01/2019</span></div>
            <div  style="display:inline;text-align: right;padding-right: 15px;padding-left: 0px" class="col-4 "><div class="CostLabel">Cost :<span class="CartSubCost"> ${{(benefitValue? benefitValue[p]?.tiers?.rate  :'' )?(benefitValue? benefitValue[p]?.tiers?.rate  :'' ) : '5.67'  | currency}}</span></div></div> 
          </div>
          <div class="row">
            <div class="col-5">
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
  <br>
  <div class="row CostText">
    <div class="col-6">
      <span style="font-weight: 400;">Total Cost</span>
    </div>
    <div class="col-5"
         style="text-align: right;color: green;font-weight: 400;">
      {{getSum() | currency}}
    </div>
  </div>
  <div>
    <span style="font-size: 14px; color: #808080">
    </span>
    <br>
  </div>
  <button title="Enrollment Submission" mat-flat-button color="primary" 
          (click)='onEnrollment()'>Confirm Enrollment <i class="fa fa-arrow-right"></i></button>
</ng-container>
