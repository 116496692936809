import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { AuthGuard } from '@core/guards/auth-guard.service';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CoreRoutingModule } from '@core/core-routing.module';
import { AuthEffects } from '@store/auth/auth.effects';
import { UiModule } from '@shared/ui.module';
import { LoginComponent } from '@core/login/login.component';
import { LoginUserComponent } from '@core/loginuser/loginuser.component';
import { LoginSSOComponent } from '@core/loginsso/loginsso.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { DialogElementsFinishPopupRegister, RegistrationComponent } from './loginuser/registration/registration.component';
import { DialogElementsResetPopup, ResetValidationComponent } from './loginuser/reset-validation/reset-validation.component';
import { DialogElementsFinishPopup, SubmitRegistrationComponent } from './loginuser/registration/submit-registration.component';
import { DialogElementsRetrivePopup, RetrieveUsernameComponent } from './loginuser/retrieve-username/retrieve-username.component';
import { ChangePasswordComponent, DialogElementsChangePasswordPopup } from './loginuser/registration/change-password/change-password.component';
import { RetrieveUsernameSubmitComponent } from './loginuser/retrieve-username-submit/retrieve-username-submit.component';
import { ResetSubmitComponent } from './loginuser/reset-submit/reset-submit.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginConsoleUserComponent } from './login_consoleuser/login_consoleuser.component';
import { LoginClientTrackingComponent } from './login_clienttracking/login_clienttracking.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    //NgZorroAntdModule,
    NzPopconfirmModule,
    UiModule,
    HttpClientModule,
    CoreRoutingModule,
    EffectsModule.forFeature([AuthEffects]),
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    LoginComponent, 
    LoginUserComponent,
    LoginConsoleUserComponent, 
    LoginClientTrackingComponent,
    LoginSSOComponent,
    RegistrationComponent,
    SubmitRegistrationComponent ,
    ResetValidationComponent,
    RetrieveUsernameComponent,
    ChangePasswordComponent,
    RetrieveUsernameSubmitComponent,
    DialogElementsFinishPopup,
    DialogElementsFinishPopupRegister,
    DialogElementsRetrivePopup,
    DialogElementsResetPopup,
    ResetSubmitComponent,
    ResetPasswordComponent,
    DialogElementsChangePasswordPopup
    
  ],exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [AuthGuard]
})
export class CoreModule {
  constructor() {
    console.log('CoreModule loaded.');
  }
}
