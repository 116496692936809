<div class="phone phone-icon">

  <div class="icon phone__icon">
    <span class="fa fa-phone"></span>
  </div>

  <input #input
         type="text"
         class="input form-control phone__input-icon"
         (focus)="inputFocus($event.target.value)"
         (blur)="inputBlur($event.target.value)">
</div>