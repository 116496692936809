import { Component, OnInit } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { ExcelService } from '@services/excel/excel.service';

const PRD = [
  {
    "key":"GENREAL", 
    "value": [
      {
        "key":"Client Config - GENREAL",
      },
      {
        "key": "URL",
        "prdvalue": "www.abc.com",
        "stgvalue": "www.abc.com"
      },
      {
        "key": "Toll Free Number",
        "prdvalue": "201-000-0000",
        "stgvalue": "111-111-1111"

      },
      {
        "key": "Call Center Support Email",
        "prdvalue": "abc@gmail.com",
        "stgvalue": "abc@gmail.com"

      },
      {
        "key": "Progranm Name",
        "prdvalue": "Added Benefits",
        "stgvalue": "No Benefits"
      }
    ]
  },
  {
    "key":"SSO", 
    "value": [
      {
        "key":"Client Config - SSO",
      },
      {
        "key": "Configure SSO?",
        "prdvalue": "Yes",
        "stgvalue": "Yes"
      },
      {
        "key": "SSO Timeout Link",
        "prdvalue": "www.timeout.com",
        "stgvalue": "www.timeout.com"
      }
    ]
  },
  {
    "key":"LOGIN", 
    "value": [
      {
        "key":"Client Config - LOGIN",
      },
      {
        "key": "On Demand Login",
        "prdvalue": "Yes",
        "stgvalue": "Yes"
      },
      {
        "key": "Background Image",
        "prdvalue": "../assets/img/aa.jpg",
        "stgvalue": "../assets/img/aa.jpg",
      },
      {
        "key": "Welcome Message",
        "prdvalue": "These voluntary benefit offerings are separate from your health care benefits. The Added Benefits Program for American Airlines is not sponsored, administered, or endorsed by American Airlines. Added Benefits is a third party vendor’s trademark name for the platform of voluntary programs discussed herein and should not be interpreted to mean that American Airlines has established or maintains the programs as a benefit arrangement. American Airlines receives no consideration in connection with the program. Participation is optional and all costs are paid by you. American Airlines makes this program available to eligible employees for convenience and potential cost savings. You must call 1-855-550-0706, if you have any questions about the program or you wish to enroll. American Airlines Human Resources and the Payroll Department will not be able to assist you with questions about the program. The companies offering these benefits reserve the right to modify or terminate the coverage at any time. If the coverage is modified or terminated, participants will be notified.",
        "stgvalue": "These voluntary benefit offerings are separate from your health care benefits. The Added Benefits Program for American Airlines is not sponsored, administered, or endorsed by American Airlines. Added Benefits is a third party vendor’s trademark name for the platform of voluntary programs discussed herein and should not be interpreted to mean that American Airlines has established or maintains the programs as a benefit arrangement. American Airlines receives no consideration in connection with the program. Participation is optional and all costs are paid by you. American Airlines makes this program available to eligible employees for convenience and potential cost savings. You must call 1-855-550-0706, if you have any questions about the program or you wish to enroll. American Airlines Human Resources and the Payroll Department will not be able to assist you with questions about the program. The companies offering these benefits reserve the right to modify or terminate the coverage at any time. If the coverage is modified or terminated, participants will be notified."
      },
      {
        "key": "Custom Welcome Message",
        "prdvalue": "Welcome to American Airlines",
        "stgvalue": "Welcome to American Airlines"
      }
    ]
  }, 
  {
    "key":"LIFE EVENTS",
    "value": [
      {
        "key":"Client Config - LIFE EVENTS",
      },
      {
        "key": "Allow Employee Enrollment due to Life Event",
        "prdvalue": "Yes",
        "stgvalue": "No"
      },
      {
        "key": "DK",
        "prdvalue": "Yes",
        "stgvalue": "Yes"
      },
    ]
  },
  {
    "key":"OPEN ENROLLMENT (Implementation Only)",
    "value": [
      {
        "key":"Benefits Config - OPEN ENROLLMENT",
      },
      {
        "key": "Kick-Off Open Enrollment Start Date",
        "prdvalue": "01/01/2022",
        "stgvalue": "01/01/2022"
      },
      {
        "key": "Kick-Off Open Enrollment End Date",
        "prdvalue": "02/02/2022",
        "stgvalue": "02/02/2022"
      },
      {
        "key": "Kick-Off Enrollment Effective Date",
        "prdvalue": "01/02/2022",
        "stgvalue": "01/02/2022"
      },
      {
        "key": "Silent Period Start Date",
        "prdvalue": "02/02/2022",
        "stgvalue": "02/02/2022"
      },
      {
        "key": "Silent Period End Date",
        "prdvalue": "03/02/2022",
        "stgvalue": "03/02/2022"
      },
    ]
  },
  {
    "key":"NEW HIRE CONFIGURATIONS",
    "value": [
      {
        "key":"Benefits Config - NEW HIRE CONFIGURATIONS",
      },
      {
        "key": "New Hire Open Enrollment Period (days)",
        "prdvalue": "29 days",
        "stgvalue": "29 days"
      },
      {
        "key": "New Hire Waiting Days",
        "prdvalue": "10 days",
        "stgvalue": "10 days"
      },
      {
        "key": "New Hire Enrollment Restriction (Days)",
        "prdvalue": "10 days",
        "stgvalue": "20 days"
      },
      {
        "key": "New Hire Identification",
        "prdvalue": "0129090",
        "stgvalue": "0129090"
      },
    ]
  },
]

const STG = [
  {
    "key":"GENREAL", 
    "value": [
      {
        "key": "URL",
        "value": "www.xyz.com"
      },
      {
        "key": "Toll Free Number",
        "value": "201-201-1111"
      },
      {
        "key": "Call Center Support Email",
        "value": "xyz@gmail.com"
      },
      {
        "key": "Progranm Name",
        "value": "No Benefits"
      }
    ]
  },
  {
    "key":"SSO", 
    "value": [
      {
        "key": "Configure SSO?",
        "value": "Yes"
      },
      {
        "key": "SSO Timeout Link",
        "value": "www.timeout.com"
      }
    ]
  },
  {
    "key":"LOGIN", 
    "value": [
      {
        "key": "On Demand Login",
        "value": "Yes"
      },
      {
        "key": "Background Image",
        "value": "../assets/img/aa.jpg"
      },
      {
        "key": "Welcome Message",
        "value": "These voluntary benefit offerings are separate from your health care benefits. The Added Benefits Program for American Airlines is not sponsored, administered, or endorsed by American Airlines. Added Benefits is a third party vendor’s trademark name for the platform of voluntary programs discussed herein and should not be interpreted to mean that American Airlines has established or maintains the programs as a benefit arrangement. American Airlines receives no consideration in connection with the program. Participation is optional and all costs are paid by you. American Airlines makes this program available to eligible employees for convenience and potential cost savings. You must call 1-855-550-0706, if you have any questions about the program or you wish to enroll. American Airlines Human Resources and the Payroll Department will not be able to assist you with questions about the program. The companies offering these benefits reserve the right to modify or terminate the coverage at any time. If the coverage is modified or terminated, participants will be notified."
      },
      {
        "key": "Custom Welcome Message",
        "value": "Welcome to American Airlines"
      }
    ]
  }, 
  {
    "key":"SITE BRANDING & CONFIGURATIONS",
    "value": [
      {
        "key": "Content Document",
        "value": "..."
      },
      {
        "key": "Key Value Pairs by Product",
        "value": "..."
      },
      {
        "key": "Enroll/Mangage Benefits",
        "value": "..."
      },
    ]
  },
  {
    "key":"PROFILE",
    "value": [
      {
        "key": "Profile Page Image",
        "value": "../abc/png"
      },
    ]
  },
  {
    "key":"LIFE EVENTS",
    "value": [
      {
        "key": "Allow Employee Enrollment due to Life Event",
        "value": "Yes"
      },
      {
        "key": "DK",
        "value": "Yes"
      },
    ]
  },
]

@Pipe({name: 'tablefilter'})
export class TableFilterPipe implements PipeTransform {
  transform(items: any, searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(item => {
      const key = item.key.toLowerCase().includes(searchText);
      let prd = false
      let stg = false
      if(item.prdvalue) {
        prd = item.prdvalue.toLowerCase().includes(searchText);
      }
      if(item.stgvalue) {
        stg = item.stgvalue.toLowerCase().includes(searchText);
      }
      return key || prd || stg;
    });
  }
}

@Component({
  selector: 'app-global-config',
  templateUrl: './global-config.component.html',
  styleUrls: ['./global-config.component.scss']
})
export class GlobalConfigComponent implements OnInit {

  entities =[]

  constructor(
    protected excelService: ExcelService,
  ) { }

  ngOnInit(): void {
    this.entities = [
      {
        clientName: 'American Airlines',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/19c1aeed-7028-4270-9f5b-47492019b4be/Images/Client/29700b15-5a3a-4c56-9903-2fd21d453bef_aalogo.png'
      },
      {
        clientName: 'Barclays',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/f20a7472-798b-438c-b93b-72a46404b33f/Images/Client/c013a261-5c51-40cf-87fd-ab77ee05fe66_1280px-Barclays_logo_Layer%201.png'
      },
      {
        clientName: 'BlackRock',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/b3d43e4a-fe1a-4a54-9689-0fe45f20d319/Images/Client/1211d2fa-2de3-4d00-a1e2-4092c2ea2b4a_logo.jpg'
      },
      {
        clientName: 'Bloomberg L.P.',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/8209ca98-d819-481d-bc71-fde267f0e2d6/Images/Client/5a4bfc03-f52d-4b88-9cf1-9496e3ee8c5d_logoBBGblck_Reg.png'
      },
      {
        clientName: 'CoreLogic',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/39914dab-c2b6-4ebc-9558-cb8baf542c8e/Images/Client/8f48708a-cf76-418f-a45b-f7e8815578cc_1200px-CoreLogic_logo.svg.png'
      },
      {
        clientName: 'Johnson & Johnson',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/18ca0ffa-3fee-4873-85b0-de590ad99d98/Images/Client/d213f144-7d0c-49c7-9bd1-700e6a659faf_oie_transparent.png'
      },
      {
        clientName: 'Liberty Mutual',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/534f9b98-55ec-45f7-80ad-e5eb40a6e81b/Images/Client/380c95d8-d16f-4c77-bfce-8f2873da8ebf_LM_Logo_200x54.png'
      },
      {
        clientName: 'NY Times',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/2d909879-9600-4157-b8a4-f677d3a7ba04/Images/Client/2dfc8e99-7314-4c90-ba90-6495b8d7d730_90f0c673-7920-4805-8641-cf2498fbc151_logo-new%20-%20Copy.png'
      },
      {
        clientName: 'RiteAid',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/87e4d4cd-d4f3-4922-9a68-f3c8b77cd806/Images/Client/92a5d989-e53a-43ec-80ee-98490a596545_1200px-Rite_Aid_logo.svg.png'
      },
      {
        clientName: 'T-Mobile',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/f1fbe376-c812-11e0-ba33-c50d4824019b/Images/Client/6a70eaf8-9775-4532-890b-4582b296274c_e25baca7-918c-4b1d-b3f3-2ce98a04aeb8_logo.png'
      },
      {
        clientName: 'Walgreens',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/e7d2c2a8-8f74-458a-af8c-1cf821fe7ba0/Images/Client/a65750c4-6a51-4839-9287-af31cd0b4baa_WAG_Signature_logo_RGB-Staging.png'
      },
      {
        clientName: 'XPO Logistics Inc',
        clientLogo: 'https://apps.abcsys.com/EnterpriseWebAPI/Content/5f2e52b8-97aa-486f-8a50-638e70ed4fea/Images/Client/1c10ff56-4ff9-4fca-a963-07342d121816_logoimage.png'
      },

    ]
  }
}
