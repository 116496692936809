import {
  RequestInfo,
  RequestInfoUtilities
} from 'angular-in-memory-web-api/interfaces';

export abstract class MockRequestHandler<T> {
  abstract handleRequest(params: MockRequestHandlerParams): T;
}

export class MockRequestHandlerParams {
  reqInfo: RequestInfo;
  urlTemplate: string;
  httpParams: any;
}
