import { Action } from '@ngrx/store';
import { LookupDTO } from '@models/shared/lookup.dto';

export enum LookupActionTypes {
  LOAD_LOOKUP = '[LOOKUP] LOAD_LOOKUP',
  LOAD_LOOKUP_SUCCESS = '[LOOKUP] LOAD_LOOKUP_SUCCESS',
  LOAD_LOOKUP_FAIL = '[LOOKUP] LOAD_LOOKUP_FAIL'
}

export class LoadLookupAction implements Action {
  readonly type = LookupActionTypes.LOAD_LOOKUP;
  constructor(public payload: { lookupCode: string, isMock: boolean }) {}
}

export class LoadLookupSuccessAction implements Action {
  readonly type = LookupActionTypes.LOAD_LOOKUP_SUCCESS;
  constructor(public payload: { lookupDTO: LookupDTO }) {}
}

export class LoadLookupFailAction implements Action {
  readonly type = LookupActionTypes.LOAD_LOOKUP_FAIL;
  constructor(public payload: { lookupCode: string; error: any }) {}
}

export type AllLookupActions =
  | LoadLookupAction
  | LoadLookupSuccessAction
  | LoadLookupFailAction;
