<div class="w-100">
  <img style="width: 100%; position: fixed; object-fit: fit;" src="{{bgImage}}" alt="">
</div>

<div class="row container loginscreen" style="padding: 0px !important; margin: 0 auto;">
  <div class="col-xl-2 col-lg-2 col-md-2"></div>
  <div style="padding: 10% 0px 0px 0px; margin: 0px;"
    class="row col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 LoginPanel">
    <div class="row" style="margin: 0px; background: rgba(255, 255, 255, 0.9) none repeat scroll 0% 0%;">

      <div class="row no-gutters" style="padding: 20px;">
        <form class="col-lg-7 col-md-7 col-sm-7 col-xs-12" [formGroup]="retriveUsernameForm" (ngSubmit)="onSubmit()"
          autocomplete="off">
          <div style="border-radius: 6px; margin: 0 25px 0 0; background: rgba(255, 255, 255, 0.95)">
            <div style="padding: 3% 3% 3% 7%; margin: 0px auto" class="row">
              <br />
              <div class="row no-gutters" style="margin: 0px 0px 0px -10px; padding: 10px; width: 100%;">
                <div class="col-12"><span style="font-family: Calibri; font-size: 14px">Email</span></div>
                <input formControlName="email" type="text" class="form-control RegisterClass">
                <div class="validation">
                  <span *ngIf="retriveUsernameForm.get('email').touched && retriveUsernameForm.get('email').invalid">Please enter valid email</span>
                </div>
              </div>
              <div>
                <div style="display: inline; padding-top: 3px">
                  <button mat-flat-button routerLink='/' [style.background-color]="primaryColor"
                    style="color:#fff; float: left; margin: 0 15px 15px 0; width: 100%; width: 150px; border: none; font-size: 18px;">
                    Go to Log in
                  </button>
                  <button mat-flat-button type="submit" [style.background-color]="primaryColor"
                    style="color: #fff; float: left; margin: 0 15px 15px 0; width: 100%; width: 150px; border: none; font-size: 18px;">
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <br />
          </div>
        </form>

        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12" style="height: 100%;">
          <div id="divIntroduction" style="padding-right: 10px; padding-left: 10px; background-size: cover;">
            <div style="background-color: transparent;">
              <div style="text-align: center;">
                <div>
                  <div style="padding-top: 10px;">
                    <h3><u>Retrieve Username</u></h3>
                    <br />
                    <br />
                  </div>
                </div>
                <p style="text-align: left; font-size:16px;">
                  Questions on enrollment or program offerings?
                  <br>
                  <b style="font-family: Calibri; font-size: 18px">Call: {{phoneNum}}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>