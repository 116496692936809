import { DynamicMainFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DynamicSearchComponent } from '@shared/components/dynamic_search/dynamic_search.component';
@Component({
  templateUrl: './addnewlevel2.component.html',
})
export class AddNewLevel2Component implements OnInit {
  entityId;
  formValue;
  closeEvent = true;
  formid;
  benefitEntityPolicyId;

  @ViewChild(DynamicMainFormComponent)
  memberComponent: DynamicMainFormComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddNewLevel2Component>,
    public dialog: MatDialog
  ) {
    this.entityId = data.item.entityId;
    this.formid = data.formid;
    this.benefitEntityPolicyId = data.item.benefitEntityPolicyId;
  }
  ngOnInit() {}
  onCancel() {
    this.dialogRef.close();
  }
  onSearch() {
    let dialogRef;
    let searchFormid;
    if (this.formid == 'newcoveragetermination') {
      searchFormid = 'coverageterminationsearch';
    } else {
      searchFormid = 'reductionrulesearch';
    }
    dialogRef = this.dialog.open(DynamicSearchComponent, {
      width: '90%',
      data: {
        addNew: false,
        formid: searchFormid,
        autoSearch: true,
        item: { entityId: this.entityId },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.formValue = result;
      this.formValue.benefitEntityPolicyId = this.benefitEntityPolicyId;
      this.memberComponent.updateForm(this.formValue);
    });
  }
}
