import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';

export const HTTP_PREFIXES: string[] = ['http://', 'https://'];
export const FORWARD_SLASH = '/';

/**
 * We need our custom method because encodeURIComponent is too aggressive and doesn't follow
 * http://www.ietf.org/rfc/rfc3986.txt with regards to the character set (pchar) allowed in path
 * segments:
 *    segment       = *pchar
 *    pchar         = unreserved / pct-encoded / sub-delims / ":" / "@"
 *    pct-encoded   = "%" HEXDIG HEXDIG
 *    unreserved    = ALPHA / DIGIT / "-" / "." / "_" / "~"
 *    sub-delims    = "!" / "$" / "&" / "'" / "(" / ")"
 *                     / "*" / "+" / "," / ";" / "="
 */
export function encodeUriSegment(val) {
  return encodeUriQuery(val, true)
    .replace(/%26/gi, '&')
    .replace(/%3D/gi, '=')
    .replace(/%2B/gi, '+');
}

/**
 * This method is intended for encoding *key* or *value* parts of query component. We need a custom
 * method because encodeURIComponent is too aggressive and encodes stuff that doesn't have to be
 * encoded per http://tools.ietf.org/html/rfc3986:
 *    query         = *( pchar / "/" / "?" )
 *    pchar         = unreserved / pct-encoded / sub-delims / ":" / "@"
 *    unreserved    = ALPHA / DIGIT / "-" / "." / "_" / "~"
 *    pct-encoded   = "%" HEXDIG HEXDIG
 *    sub-delims    = "!" / "$" / "&" / "'" / "(" / ")"
 *                     / "*" / "+" / "," / ";" / "="
 */
export function encodeUriQuery(val, pctEncodeSpaces) {
  return encodeURIComponent(val)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%3B/gi, ';')
    .replace(/%20/g, pctEncodeSpaces ? '%20' : '+');
}

export function createUriEncoded(params: any) {
  if (!params) {
    return undefined;
  }

  return _.reduce(
    params,
    function(result, value, key) {
      return result + (key + '=' + encodeUriSegment(value)) + '&';
    },
    ''
  );
}

// Joins path segments with '/' (forward slash)
export function joinPaths(...segmentsToJoin: string[]): string {
  let segments = _.filter(segmentsToJoin, function(segment) {
    return !!segment;
  });

  if (_.isEmpty(segments)) {
    return '';
  }

  // a segment starting with 'http' can appear only as the first segment (at index 0); Otherwise throw error
  // validate that a 'http' or 'https' segment does not appear in the middle
  const httpSegmentIndex = getNonZeroHttpPathSegmentIndex(segments);
  if (httpSegmentIndex > 0) {
    // TODO: Move the message to a template in a 'message resources' file.
    throw new Error(
      'Found a path segment starting with "http" at index ' +
        httpSegmentIndex +
        '. ' +
        'Path segments starting with "http" can appear only once at index 0 in the argument list.'
    );
  }

  const firstSegment = segments[0];
  const lastSegment = segments[segments.length - 1];

  // 1. if the first segment is 'http://' or 'https://' remove it from the segments array. We will append it later.
  const httpSegment = startsWithHttpPrefix(firstSegment) ? firstSegment : null;
  if (!!httpSegment) {
    // remove item at index 0
    segments = segments.slice(1);
  }

  // 2. trim the '/' from the segments. This makes joining them easier.
  const segmentsWithoutSlash = _.map(segments, function(segment) {
    return _.trim(segment, FORWARD_SLASH);
  });

  const joinedPathSegments = segmentsWithoutSlash.join(FORWARD_SLASH);

  const hasLeadingSlash = firstSegment.charAt(0) === FORWARD_SLASH;
  const hasTrailingSlash =
    lastSegment.charAt(lastSegment.length - 1) === FORWARD_SLASH;

  // do not add a leading '/' if the path starts with 'http' or 'https'
  if (!!httpSegment) {
    return (
      httpSegment + joinedPathSegments + (hasTrailingSlash ? FORWARD_SLASH : '')
    );
  }

  // if the first segment had a leading '/' add it back
  // similarly, if the last segment had a trailing '/' add it back
  return (
    (hasLeadingSlash ? FORWARD_SLASH : '') +
    joinedPathSegments +
    (hasTrailingSlash ? FORWARD_SLASH : '')
  );
}

export function getNonZeroHttpPathSegmentIndex(segments: string[]) {
  return _.findIndex(segments, function(segment, index) {
    return index > 0 && startsWithHttpPrefix(segment);
  });
}

export function startsWithHttpPrefix(path: string) {
  return _.some(HTTP_PREFIXES, function(prefix) {
    return _.startsWith(path, prefix);
  });
}

/*
Each key value in the parameter object is first bound to url template if present.
Any excess key/value pairs are returned.
Given a url template 'api/clients/:userId/:clientId' and parameters
{ userId:'poweruser', clientId: '9017', query: 'foo' }
will result in...
{
  url: 'api/clients/poweruser/9017',
  excessParams: { query: 'foo' }
}
The placeholder IS NOT replaced if...
1. the param does NOT have a key that matches the url placeholder
2. the param value is null or undefined
*/
export function getUrlAndExcessParams(
  url: string,
  params: any
): {
  url: string;
  excessParams?: any;
} {
  if (!params) {
    return { url };
  }

  // only string and number can be used in the url path. Ex: api/client/:clientId/employees
  // exclude everything else
  const paramsToProcess = _.omitBy(params, (value, key) => {
    return !_.isUndefined(value) && _.isNumber(value) && _.isString(value);
  });

  const paramKeysUsedInUrl: string[] = [];

  _.each(paramsToProcess, (value, key) => {
    const modifiedUrl = replaceUrlParameter(url, key, value);

    // if the url was modified add the key to the list of 'used' keys
    if (modifiedUrl !== url) {
      paramKeysUsedInUrl.push(key);
      url = modifiedUrl;
    }
  });

  // return excess params if any
  const excessParams = _.omit(params, paramKeysUsedInUrl);

  return { url, excessParams };
}

// replace 'api/employees/:clientId' with 'api/employees/100'
export function replaceUrlParameter(
  url: string,
  key: string,
  value: string | number
) {
  const encodedValue = encodeUriSegment(value);
  let patternToFind = '';

  // 1. replace occurrences WITH a leading AND a trailing slash. Ex: clients/:clientId/benefits
  // do not replace if encodedValue is empty string because it will create a malformed url like 'clients//benefits'
  // it will be easier to debug the issue with the url from developer toolbar
  if (encodedValue) {
    patternToFind = FORWARD_SLASH + ':' + key + FORWARD_SLASH;
    while (_.includes(url, patternToFind)) {
      const replacement = FORWARD_SLASH + encodedValue + FORWARD_SLASH;
      url = _.replace(url, patternToFind, replacement);
    }
  }

  // 2. replace occurrence (at the end of the url) WITHOUT a trailing slash. Ex: clients/:clientId
  patternToFind = FORWARD_SLASH + ':' + key;
  if (_.endsWith(url, patternToFind)) {
    url =
      url.substring(0, url.length - patternToFind.length) +
      FORWARD_SLASH +
      encodedValue;
  }
// 3
patternToFind = '=' + ':' + key;
while (_.includes(url, patternToFind)) {
  const replacement = '=' + encodedValue ;
  url = _.replace(url, patternToFind, replacement);
}
  return url;
}

export function extractError(error: any, originalError?: any): string {
  originalError = originalError || error;

  if (!error) {
    return '';
  }

  if (_.isString(error)) {
    return error;
  }

  if (error.error) {
    return extractError(error.error, originalError);
  }

  if (originalError instanceof HttpErrorResponse) {
    const httpError = originalError as HttpErrorResponse;
    return `${httpError.status} - ${httpError.statusText}`;
  }

  return '';
}
