import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { LogoutAction } from '@store/auth/auth.actions';
import { CLIENT_ROUTE_SEGMENT } from '@shared/route/constants';
import { KNOWN_ENTITY_NAME } from '@shared/contants/contants';
import { BaseComponent } from '@shared/components/base.component';
import { AuthService } from '@services/auth/auth.service';
import { ClientService } from '@services/client/client.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss']
})

export class AppNavbarComponent extends BaseComponent implements OnInit {
  public isNavbarCollapsed = true; legal; tools; qenroll; admin;
  readonly CLIENT_ROUTE_SEGMENT = CLIENT_ROUTE_SEGMENT;
  readonly KNOWN_ENTITY_NAME = KNOWN_ENTITY_NAME;
  entityList;
  userName;
  entities;
  logintype: any
  // logo = './assets/img/AEBSYSlogo1.png'
  logo = './assets/img/AIS_White_crop.png'

  private _jsonURL = 'assets/users.json';
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private store: Store<AppState>,
    protected clientService: ClientService
  ) {
    super();
  }

  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  ngOnInit() {
    this.isLogintype()
    this.entityList = this.authService.getEntityList();
    this.userName = this.authService.getUsername();
    this.getJSON().subscribe(usersdata => {
      let found = usersdata.legalusers.find(e => e == this.userName)
      if (found != null) {
        this.legal = true;
      }
      found = usersdata.toolsUsers.find(e => e == this.userName)
      if (found != null) {
        this.tools = true;
      }
      found = usersdata.adminUsers.find(e => e == this.userName)
      if (found != null) {
        this.admin = true;
      }
    });

    const url = 'Entity/Entities';
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.entities = data.filter(x => x.parentId == null);
      const found = this.entities.find(e => e.id == '68bc62e6-dd96-4428-91e8-efe285bd23a0')
      if (found != null) {
        this.qenroll = true;
      }
    });
  }

  isEntity(entityId) {
  }

  isLogintype() {
    let config = window['___alliant-config-eniac-APP___']
    this.logintype = config.logintype
    if (typeof this.logintype === "undefined") {
      this.logintype = 'admin'
    }
  }

  logout() {
    this.store.dispatch(new LogoutAction());
  }
}
