import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private onShowCallback: Function;
  private onHideCallback: Function;

  constructor() {}

  registerCallbacks(callbacks: { onShow: Function; onHide: Function }) {
    this.onShowCallback = callbacks.onShow;
    this.onHideCallback = callbacks.onHide;
  }

  show() {
    if (_.isFunction(this.onShowCallback)) {
      this.onShowCallback();
    }
  }

  hide() {
    if (_.isFunction(this.onHideCallback)) {
      this.onHideCallback();
    }
  }
}
