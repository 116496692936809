import { Component,   OnInit   } from '@angular/core';
 
import { BaseComponent } from '@shared/components/base.component';
 
import * as _ from 'lodash';
 

@Component({
    selector: 'app-filesetup',
    templateUrl: './filesetup.component.html',

  })
  export class FileSetUpComponent extends BaseComponent implements OnInit {
    ngOnInit() {

      }

  }
