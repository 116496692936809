import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndpointService } from '@shared/api/api-endpoint.service';
import { ConfigService } from '@shared/config.service';
import { HttpBaseService } from '@shared/api/http-base.service';
import { HttpHelperService } from '@shared/api/http-helper.service';

export function SampleHttpFactory(injector: Injector): SampleHttpService {
  const configService = injector.get(ConfigService);
  const config = configService.config;
  const serviceConfig = config.httpServices.sampleService;

  const endpointService = new ApiEndpointService({
    baseUrl: serviceConfig.baseUrl,
    mockBaseUrl: serviceConfig.mockBaseUrl,
    useMock: serviceConfig.useMock
  });

  const httpClient = injector.get(HttpClient);
  const httpHelperService = injector.get(HttpHelperService);

  return new SampleHttpService(httpClient, httpHelperService, endpointService);
}

export class SampleHttpService extends HttpBaseService {}
