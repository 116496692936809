import { Component, Input, OnInit } from '@angular/core';

import { BaseComponent } from '@shared/components/base.component';
@Component({
    selector: 'app-print-content',
    templateUrl: './dynamic-print-content.component.html'
  })
  export class DynamicPrintContentComponent extends BaseComponent implements OnInit {

    ngOnInit() {

    }
  }
