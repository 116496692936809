import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth-guard.service';
import { LoginComponent } from '@core/login/login.component';
import { LoginUserComponent } from '@core/loginuser/loginuser.component';
import { LoginSSOComponent } from '@core/loginsso/loginsso.component';
import { RegistrationComponent } from './loginuser/registration/registration.component';
import { SubmitRegistrationComponent } from './loginuser/registration/submit-registration.component';
import { ResetSubmitComponent } from './loginuser/reset-submit/reset-submit.component';
import { ResetValidationComponent } from './loginuser/reset-validation/reset-validation.component';
import { RetrieveUsernameSubmitComponent } from './loginuser/retrieve-username-submit/retrieve-username-submit.component';
import { RetrieveUsernameComponent } from './loginuser/retrieve-username/retrieve-username.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginConsoleUserComponent } from './login_consoleuser/login_consoleuser.component';
import { LoginClientTrackingComponent } from './login_clienttracking/login_clienttracking.component';
import { ChangePasswordComponent } from './loginuser/registration/change-password/change-password.component';

let ROUTES: Routes

(function () {
  var config = window['___alliant-config-eniac-APP___'];

  switch (config.logintype) {

    case 'clienttracking': //CAT Client Activity Tracking Tool 
      ROUTES = [
        { path: 'login', component: LoginClientTrackingComponent },
        {
          path: 'main',
          loadChildren: () => import('app/modules/client-tracking-app/client-tracking-app.module').then(m => m.ClientTrackingAppModule),
          canActivate: [AuthGuard]
        },
        { path: '**', component: LoginClientTrackingComponent }
      ];
      break;
  
    case 'consoleuser': // Central console APP
      ROUTES = [
        { path: 'login', component: LoginConsoleUserComponent },
        {
          path: 'main',
          loadChildren: () => import('app/modules/console-app/console-app.module').then(m => m.ConsoleAppModule),
          canActivate: [AuthGuard]
        },
        { path: '**', component: LoginConsoleUserComponent }
      ];
      break;
  
    case 'enterpriseadmin': //Vasability Project 
      ROUTES = [
        { path: 'login', component: LoginSSOComponent },
        {
          path: 'main',
          loadChildren: () => import('app/modules/enterprise-app/enterprise-dashboard.module').then(m => m.EnterpriseDashboardModule),
          canActivate: [AuthGuard]
        },
        { path: '**', component: LoginSSOComponent }
      ];
      break;

    case 'user': // LEEBA WebSite
      ROUTES = [
        { path: 'login', component: LoginUserComponent },
        { path: 'register', component: RegistrationComponent },
        { path: 'submitregister', component: SubmitRegistrationComponent },
        { path: 'reset_password', component: ResetValidationComponent },
        { path: 'retrieve_username', component: RetrieveUsernameComponent },
        { path: 'retrieve_submit', component: RetrieveUsernameSubmitComponent },
        { path: 'reset_confirm', component: ResetSubmitComponent },
        { path: 'main',
          // loadChildren: 'app/modules/main/main.module#MainModule',
          loadChildren: () => import('app/modules/user-app/user-dashboard.module').then(m => m.UserDashboardModule),
          canActivate: [AuthGuard]
        },
        { path: '**', component: LoginUserComponent }
      ];
      break;

    case 'portalApp': // The Portal APP
      ROUTES = [
        { path: 'login', component: LoginUserComponent },
        { path: 'register', component: RegistrationComponent },
        { path: 'submitregister', component: SubmitRegistrationComponent },
        { path: 'reset_password', component: ResetValidationComponent },
        { path: 'retrieve_username', component: RetrieveUsernameComponent },
        { path: 'retrieve_submit', component: RetrieveUsernameSubmitComponent },
        { path: 'reset_confirm', component: ResetSubmitComponent },
        { path: 'ResetPwd', component: ChangePasswordComponent },
        {
          path: 'main',
          loadChildren: () => import('app/modules/portal-app/portal-app.module').then(m => m.PortalAppModule),
          canActivate: [AuthGuard]
        },
        { path: '**', component: LoginUserComponent }
      ];
      break;

    case 'testusertool': // TestUser creation TOOL 
      ROUTES = [
        { path: 'login', component: LoginConsoleUserComponent },
        {
          path: 'main',
          loadChildren: () => import('app/modules/usertool-app/usertool-app.module').then(m => m.UsertoolAppModule) ,
          canActivate: [AuthGuard]
        },
        { path: '**', component: LoginConsoleUserComponent }
      ];
      break;
  
    default: // Admin APP (Cheshire) - Default is case the loginType is missing in the config
      ROUTES = [
        { path: 'login', component: LoginComponent },
        { path: 'changepassword', component: ResetPasswordComponent },
        {
          path: 'main',
          // loadChildren: 'app/modules/main/main.module#MainModule',
          loadChildren: () => import('app/modules/main/main.module').then(m => m.MainModule),
          canActivate: [AuthGuard]
        },
        { path: '**', component: LoginComponent }
      ];
      break;
  }
    
})()

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      enableTracing: false,
      useHash: true,
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})

export class CoreRoutingModule { }
