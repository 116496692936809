import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { AuthService } from '@services/auth/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService: AuthService;

  /*
  IMPORTANT: params must be injected using the "deps" property in the provider
  IMPORTANT: "src\app\shared\interceptors\providers\index.ts"
  */
  constructor(private injector: Injector) {
    this.authService = this.injector.get(AuthService);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if(request.url.indexOf('token') == -1)
    {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getAccessToken()}`
        }
      });
  
    }
    
    return next.handle(request);
  }
}
