import { Component, OnInit, Input, Inject } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ExcelService } from '@services/excel/excel.service';
import { ActivatedRoute } from '@angular/router';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { HttpService } from '@shared/api/http.service';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';

@Component({
  selector: 'app-benefitbillconfig',
  templateUrl: './billconfig.componet.html',
  styleUrls: ['./benefit.component.scss']
})

export class BenefitBillConfigComponent extends BaseComponent implements OnInit {
  @Input() set benefitEntityPolicyBillConfigDTO(benefitEntityPolicyBillConfigDTO) {
    this.rows = benefitEntityPolicyBillConfigDTO;
  }
  @Input() EntityId; rows; _jsonURL = 'billconfiggrid'; gridConfigs: any;
  @Input()benefitEntityId;
  // benefitEntityPolicyId = '3623d0dd-4ecc-4369-8909-4df330832167';
  @Input()
  set benefitEntityPolicyId(benefitEntityPolicyId) {
    if (benefitEntityPolicyId != null) {
      this._benefitEntityPolicyId = benefitEntityPolicyId;
    }
  }
  _benefitEntityPolicyId;
  EntityClientId
  
  constructor(
    private httpser: HttpService,
    protected routeHelper: RouteHelperService,
    protected route: ActivatedRoute,
    public dialog: MatDialog,
    protected clientService: ClientService,
    private notification: NzNotificationService,
    protected excelService: ExcelService) {
    super();
  }

  public getJSON(): Observable<any> {
    if (this.EntityId == '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
      this._jsonURL = 'hmbillconfiggrid';
    } else {
      this._jsonURL = 'billconfiggrid';
    }
    return (
      this.httpser.get('json?path=' + this._jsonURL, {
        urlParams: null,
        options: {
          isMock: false
        }
      })
    );
  }

  ngOnInit() {
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
    this.getJSON().subscribe(data1 => {
      //  this.spinner.hide();
      const json = JSON.parse((<any>data1.jsonText) as string);
      if (data1 === null) {
        this.gridConfigs = [];
      } else {
        this.readJson(json);
      }
    });
  }
  
  readJson(data1) {
    this.gridConfigs = (<any>data1).GridConfig;
  }

  download() {
    const excelrows: any = [];
    this.rows.forEach(row => {
      const obj = {};
      this.gridConfigs.forEach(col => {
        obj[col.columnLabel] = row[col.columnName];
      });
      excelrows.push(obj);
    });
    this.excelService.exportAsExcelFile(excelrows, 'billconfigure');
  }

  onDelete(data) {
    let url = 'Benefits/DeleteBenefitEntityPolicyBillConfig';
    const observ$ = this.clientService.postWithUrl({ url, dto: data });
    observ$.subscribe((res) => {
      this.notification.blank('Deleted  successfully', ' ');
      url = 'Benefits/getBenefitEntityPolicyBillConfig?benefitEntityPolicyId=' + this._benefitEntityPolicyId;
      const observ1$ = this.clientService.getWithUrl(url, {});
      observ1$.subscribe((data1) => {
        this.rows = data1;
      })
    }, err => {}
  )}

  onAdd() {
    let formid;
    if (this.EntityId == '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
      formid = 'hmbenefitbillconfig';
    } else {
      formid = 'benefitbillconfig';
    }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: true,
        item: {
          entityClientId: this.EntityClientId,
          entityId: this.EntityId,
          benefitEntityId: this.benefitEntityId,
          benefitEntityPolicyId: this._benefitEntityPolicyId
        },
        formid,
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitEntityPolicyBillConfigs/?entityId=${this.EntityId}&benefitEntityPolicyId=${this._benefitEntityPolicyId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe((data1) => {
          this.rows = data1;
        },
        );
      }
    });
  }

  onEdit(data) {
    let formid;
    if (this.EntityId == '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
      formid = 'hmbenefitbillconfig';
    } else {
      formid = 'benefitbillconfig';
    }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: false,
        item: data,
        formid: formid,
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitEntityPolicyBillConfigs/?entityId=${this.EntityId}&benefitEntityPolicyId=${this._benefitEntityPolicyId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe((data1) => {
          this.rows = data1;
        },
        );
      }
    });
  }
}
