import {
  RequestInfo,
  RequestInfoUtilities
} from 'angular-in-memory-web-api/interfaces';
import {
  MockRequestHandler,
  MockRequestHandlerParams
} from '@mock/mock-data/shared/mock-handler.interface';
import { AuthSuccessDTO } from '@models/auth';

export class AuthMockHandler extends MockRequestHandler<AuthSuccessDTO> {
  handleRequest(params: MockRequestHandlerParams): AuthSuccessDTO {
    return {
      access_token:
        // tslint:disable-next-line:max-line-length
        'CjsfnekCd_9TJkac8FzUMn_di8iwVYpxY4wWsmBbHcXq0IMB6FYb5DivtsrdbhCGGurQMpWfZyL2z_OBNPNNM0byDELNTLp_F5HcZqEgRuMbCxBX3YZ6KDAT_gzmrGwpdJEGzY73pp_gPObHCsRtVqgu21Gs-iQ-hJYTJkoS0YVNTkOzyoyw-a7EZLuVqKqd54ypGs4b3PM5_UCXGynFbWKy0PspbVp1qiHidOcw5ADHbiSLP481wu2QWmV2VHaWn6g-ma1XMdX0a-bBB4nD-XQIUc0TbhrM8wKxI_1BR2h6caflqcbU3JTrY7OtgS7S6EyL-y5ZZDmlgULj9ADr4cXV62l1F0px5PzHzkFUGTj1_lxkZ8Q0a0Rbep8kdBTvn9sZLgOa6mIvOtgLo_h5_p9yXepf2VKMVvFyiYD3uGuE556v9D5_c7IUGTLgZP7mkJpGD1dl1hn4qBu1sHIZTRZs4hNsc5tkdM_Z2pW6EPC2ZEZkJ5WK036dBDlImV7lwGeOMV2aOe1sNPNzt7lX-6Y2kWdj6dG5knWY6zL38JM',
      token_type: 'bearer',
      expires_in: 17999,
      refresh_token: '37ddb8247cd74691ada8ae264338f08d',
      username: 'TestUser002',
      Userid: 'e870db46-5a08-46fb-a756-a8bac0cd52e9',
      employeeGuid: '24c1f66c-9ab9-4284-a115-94d5e6a5b23a',
      clientId: '19c1aeed-7028-4270-9f5b-47492019b4be',
      sessionId: '68e504de-340b-4cfd-811d-6fc70813b955',
      entityList: [],
      IsPasswordReset: false
    };
  }
}
