
<h4>Suspense Move</h4>
<div class="row">
  <div class="col-12">
    <app-dynamic-fields #fields [formConfigs]='formConfigs' [formValue]="formValue" (cancle)="onCancel()"
      [saveButton]='saveButton' (save)="onSearch($event)"></app-dynamic-fields>
  </div>
</div>
<div class="row" *ngIf="rows !=null">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <app-form-group-wrapper>

          <nz-table #basicTable [nzData]="rows" nzSize="small" [nzPageSize]='3' class="table table-hover"
            style="margin-top: 10px" *ngIf='gridConfigs!=null'>
            <thead (nzSortChange)="sort($event)" nzSingleSort class="thead-dark">
              <tr>
                <th *ngIf="actionButton!=='none'" style="width:10%"><b>&nbsp;Action</b></th>
                <ng-container *ngFor="let access of gridConfigs">

                  <th nowrap="" nzShowSort [nzSortKey]="access.columnName"
                    (nzFilterChange)="filter($event,access.columnName,access.filterType )" [nzFilterMultiple]="'false'"
                    nzShowFilter [nzFilters]="access.filterList"
                    *ngIf="access.sort!=null && access.filter!=null && access.filterType==='in'">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>

                  </th>
                  <th nowrap="" nzShowSort [nzSortKey]="access.columnName" nzCustomFilter
                    *ngIf="access.sort!=null && access.filter!=null && access.filterType==='search'">
                    {{access?.columnLabel}}&nbsp;
                    <nz-filter-trigger [nzVisible]="visible" [nzActive]="'true'" [nzDropdownMenu]="menu">
                      <i nz-icon [nzType]="'search'"></i>
                    </nz-filter-trigger>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <div class="ant-table-filter-dropdown">
                        <div class="search-box">
                          <input type="text" nz-input placeholder={{access.columnLabel}} [(ngModel)]="searchValue" />
                          <button nz-button nzSize="small" nzType="primary"
                            (click)="searchCust(access.columnName,access)" class="search-button">
                            Search
                          </button>
                          <button nz-button nzSize="small" (click)="resetCust(access.columnName)">Reset</button>
                        </div>
                      </div>
                    </nz-dropdown-menu>
                  </th>
                  <th nowrap="" (nzFilterChange)="filter($event,access.columnName,access.filterType )"
                    [nzFilterMultiple]="'false'" nzShowFilter [nzFilters]="access.filterList"
                    *ngIf="access.sort==null && access.filter!=null  && access.filterType==='in'">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>

                  </th>
                  <th nowrap="" nzShowSort [nzSortKey]="access.columnName"
                    *ngIf="access.sort!=null && access.filter==null">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>

                  </th>
                  <th nowrap="" *ngIf="access.sort==null &&  access.filter==null">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>

                  </th>
                </ng-container>

              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of basicTable.data">
                <tr class="ng-scope">
                  <td class="default-font-Color"><span style="cursor: pointer" (click)="selection(item)"
                      class="btnEdit"><i class="fa fa-eye" aria-hidden="true"></i>&nbsp;Select</span></td>
                  <ng-container *ngFor="let access of gridConfigs">
                    <ng-container [ngSwitch]="access.columnType">
                      <td class="ng-binding" *ngSwitchCase="'text'">{{item[access.columnName]}}
                      </td>
                      <td class="ng-binding" *ngSwitchCase="'date'">{{item[access.columnName]|
                        date:'M/d/yy, h:mm a'}}</td>
                    </ng-container>
                  </ng-container>

                </tr>
              </ng-container>
            </tbody>
          </nz-table>
          <!-- pager row -->

        </app-form-group-wrapper>
      </div>
    </div>
  </div>
</div>
<app-form-group-wrapper>
  <h6>{{formValue.Name}} Suspense Amount : <span style="font-weight: 400;color:green">{{balance | currency}}</span>
  </h6>

  <div *ngIf='selected!=null'>
    <h5>{{selected?.firstName}} {{ selected?.lastName}}</h5> <br />
    Suspense Balance: {{selected?.suspenseAmount | currency}} <br />
    Enter Amount to Amount: <nz-input-number [(ngModel)]="amount" [nzStep]="1" [nzFormatter]="formatterDollar"
      [nzParser]="parserDollar"></nz-input-number>
    &nbsp;<input type="checkbox" [(ngModel)]="autoPost" />AutoPost <br /><br />
    <a class="PatchFont" [attr.href]="butdisable ? null : '#'" [class.disabled]="butdisable"
      (click)='!butdisable && onMoveToMember()'>Move to Member</a>
    <a class="PatchFont" [attr.href]="butdisable ? null : '#'" [class.disabled]="butdisable"
      (click)='!butdisable && onMoveToEntity()'>Move to Entity</a><a class="PatchFont" (click)=onClose()>Close</a>
  </div>
</app-form-group-wrapper>