import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
@Component({
    selector: 'app-stripe-payment',
    templateUrl: './stripe-payment.component.html',
})
export class StripePaymentComponent implements OnDestroy, AfterViewInit {
    @ViewChild('cardInfo') cardInfo: ElementRef;
_totalAmount: number;
_token: string;
    card: any; 
    stripe;
    cardHandler = this.onChange.bind(this);
    cardError: string|null;
constructor(
        private cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<StripePaymentComponent>,
    ) {
        this._totalAmount = data['totalAmount'];
    }
ngOnDestroy() {
        if (this.card) {
            this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
        }
    }
ngAfterViewInit() {
        this.initiateCardElement();
    }
initiateCardElement() {
         this.card = undefined;
         this.card = elements.create('card');
         this.card.mount(this.cardInfo.nativeElement);
this.card.addEventListener('change', this.cardHandler);
    }
onChange({error}) {
        if (error) {
            this.cardError = error.message;
        } else {
            this.cardError = null;
        }
        this.cd.detectChanges();
    }
async createStripeToken() {
        // const {token, error} = await stripe.createToken(this.card);
        // if (token) {
        //     this.onSuccess(token);
        // } else {
        //     this.onError(error);
        // }
    }
onSuccess(token) {
        // this.dialogRef.close({token}); 
        this._token = token;
    }
onError(error) {
        if (error.message) {
            this.cardError = error.message;
        }
    }
}