<div [formGroup]='planForm'>
  <div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
    <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
    height:200px; 
    position: fixed; 
    
    top: 50%; 
    left: 50%; 
    margin-top: -100px; 
    margin-left: -100px;"></nz-spin>
    <span class="default-font-Color-dark">Loading ...</span>
  </div>
  <div *ngIf="ratemessage !=null && ratemessage != ''" style="margin-top: -10px;margin-bottom: 10px">
    <span class="PatchFontRed">{{ratemessage}}</span> <br />
  </div>
  <div *ngIf="checkRestriction(benefitEntityPolicy) != ''"><span
      style="color: red;">{{checkRestriction(benefitEntityPolicy)}}</span></div>
  <div *ngIf="checkRestriction(benefitEntityPolicy) == ''">

    <ng-container *ngIf="benefitEntityPolicy">
      <div class="row" style="margin: 0px">
        <!-- <span class="PolicyText">{{benefitEntityPolicy.benefitEntityPolicyName}} </span>
      <br /><br /> -->
        <div class="col-9" style="padding: 0px">
          <div *ngIf="benefitEntityPolicy.healthPlanOptionType=='f2ae7ce0-efcf-442a-872a-e5d1c7354859'">
            Health Plan Option
            <nz-radio-group formControlName="healthPlanOptionVal" [nzButtonStyle]="'solid'" class="col-12">
              <div *ngFor="let item of benefitEntityPolicy.healthPlanOptions; ">
                <label nz-radio nzValue={{item.value}}><span class="PlanText">&nbsp;{{item.name}}</span></label>
              </div>
            </nz-radio-group>
          </div>
          <div *ngIf="benefitEntityPolicy.healthPlanOptionType==='5bd97b23-dd57-4f84-949d-9bd45fbc7f90'">
            Health Plan Option: {{benefitEntityPolicy.healthPlanOptions[0].name}}
          </div>

          <app-member-coverage-runtime
            *ngIf="benefitEntityPolicy.coverages!=null && benefitEntityPolicy.coverages[0]?.enrollmentCoverageObjectType==='EnrollmentRuntimeCoverageObject' "
            (formReady)="formInitialized('coverages', $event)" [coverages]='benefitEntityPolicy.coverages[0]'
            (changeCov)='onChangeCov($event)' [coverageValue]='coverage'>
          </app-member-coverage-runtime>
          <app-member-coverage
            *ngIf="benefitEntityPolicy.coverages!=null && benefitEntityPolicy.coverages[0]?.enrollmentCoverageObjectType!=='EnrollmentRuntimeCoverageObject' "
            [coverages]='benefitEntityPolicy.coverages' [coverageValue]='coverage'
            (formReady)="formInitialized('coverages', $event)" (changeCov)='onChangeCov($event)'></app-member-coverage>

          <div class="row" style="margin: 0px;">
            <ng-container *ngIf='benefitEntityPolicy.areaCodeRequired==true'>
              Area Code: &nbsp;
              <select class="form-control  input-sm col-2" formControlName="areaCode" ng-reflect-is-disabled="true" (change)="onChangeCov()">
                <option *ngFor="let item of areas;" [value]='item'>{{item}}</option>
              </select>&nbsp;
            </ng-container>

            <ng-container *ngIf='benefitEntityPolicy.locationCodeRequired==true && EntityId!="ac586073-69d6-41bf-875c-eb754f750457"' style="display: inline;">
              <span>Location Code: &nbsp; </span>
              <select class="form-control  input-sm col-2" formControlName="locationCode" (change)="onChangeCov()">
                <option *ngFor="let item of locations;" [value]='item'>{{item}}</option>
              </select>&nbsp;
            </ng-container>
            <ng-container style="display: inline;" *ngIf='benefitEntityPolicy.planCodeRequired==true && EntityId!="ac586073-69d6-41bf-875c-eb754f750457"'>
              Plan Code:&nbsp;
              <select class="form-control  input-sm col-2" formControlName="planCode" (change)="onChangeCov()">
                <option *ngFor="let item of planCodes;" [value]='item'>{{item}}</option> 
              </select>&nbsp;
            </ng-container>

            <ng-container style="display: inline;"
              *ngIf="benefitEntityPolicy.coverages!=null && benefitEntityPolicy.coverages[0]?.enrollmentCoverageObjectType!=null  && benefitEntityPolicy.coverages[0]?.enrollmentCoverageObjectType==='EnrollmentRuntimeCoverageObject' && EntityId!='ac586073-69d6-41bf-875c-eb754f750457' && enrollmentCoverageObject.IsCovCodeRequired==true ">
              Coverage Code:&nbsp;
              <select class="form-control  input-sm col-2" formControlName="CovCode" (change)="onChangeCov()">
                <option *ngFor="let item of enrollmentCoverageObject.CovCodes;" [value]='item'>{{item}}</option>
              </select>&nbsp;
            </ng-container>
            <ng-container *ngIf='EntityId=="4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d" ' class="col-1"
              style="display: inline;">
              Qualification Code:&nbsp;
              <app-typeahead [mask]='"000"' [init]='qualificationCode' style="padding: 0px;"
                (changed)="modelChanged($event)"></app-typeahead>
            </ng-container>

            <ng-container *ngIf=' EntityId == "d6a2ff7e-bba0-48fa-9396-a6b202631669"' class="col-1"
              style="display: inline;">
              Volume:&nbsp;
              <input class="form-control col-2" [formControlName]="'qualificationCode'" />
            </ng-container>
          </div>

          <app-member-tier [dropReason]='dropReason' [tiers]='benefitEntityPolicy.tiers'
            [entityClientId]='EntityClientId' [currentEnrollment]='currentEnrollment'
            [benefitEntityPolicy]='benefitEntityPolicy' [tierValue]='tier'
            (formReady)="formInitialized('tiers', $event)" (change)='onChange1($event)'
            (changeCoverage)='onTierChangeCov($event)' (tierChange)='onTierChange()' [coverage]='coverage'
            [rate]='rates' [rateLookpType]='benefitEntityPolicy.rateLookpType' [entityId]='EntityId'
            [clientId]='ClientId' [memberId]='MemberId'
            *ngIf="benefitEntityPolicy.enrollmentUITierDisplay==='Standard'"></app-member-tier>

          <app-member-tier-coverage [dropReason]='dropReason' [tiers]='benefitEntityPolicy.tiers'
            [benefitEntityPolicy]='benefitEntityPolicy' [currentEnrollment]='currentEnrollment'
            (formReady)="formInitialized('tiercoverage', $event)" [coverage]='coveragevals' [entityId]='EntityId'
            [clientId]='ClientId' [memberId]='MemberId' [entityClientId]='EntityClientId'
            (change1)='onChangeCov($event)' [rate]='rates' [tierValue]='tier' [inTier]="'false'"
            *ngIf="benefitEntityPolicy.enrollmentUITierDisplay==='HideTier'">
          </app-member-tier-coverage>

        </div>
        <div class="col-3" style="margin-top:-50px ">
          <div *ngIf="currentEnrollment!=null && currentEnrollment.latestEnrollmentDateTime!= null">
            Last Enrollment Date:<br /> {{currentEnrollment.latestEnrollmentDateTime | date }}
          </div>
          <div
            *ngIf="currentEnrollment!=null && currentEnrollment.originalIssueState != null && EntityId!=='d6a2ff7e-bba0-48fa-9396-a6b202631669'">
            Issue State: {{currentEnrollment.originalIssueState}} <br />
          </div>
          <div *ngIf="currentEnrollment!=null && currentEnrollment.originalEnrollmentEffectiveDate != null">
            Original Effective Date:<br /> {{currentEnrollment.originalEnrollmentEffectiveDate | date}} <br />
          </div>
          <div
            *ngIf="EntityId!=='a11ee4de-9b44-4bcf-a01e-04e1a2fc6028' && EntityId!=='ac586073-69d6-41bf-875c-eb754f750457' && EntityId !== '9808f856-f047-4843-bec8-277e414fc649' && EntityId!=='d6a2ff7e-bba0-48fa-9396-a6b202631669' && EntityId!=='4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d'  && EntityId!=='3b7805a6-10fa-48b5-b0ee-ea5365258341' && EntityId!=='f9d5f5e4-9e28-4a23-9cc3-9bb9d81d15e4'">
            Campaign Code: <input class="form-control" [formControlName]="'campaignCode'" />
          </div>
          <div *ngIf="getEffdatedisp()=='Effective Date' && EntityId != 'd6a2ff7e-bba0-48fa-9396-a6b202631669'">
            Effective Date
            <!-- <app-date-input  [formControlName]="'effactiveDate'"></app-date-input> -->
            <div class="date_wrap">
              <input [matDatepicker]="picker" [formControlName]="'effactiveDate'" class="input date_wrap__input ">
              <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
          <div *ngIf="getEffdatedisp()=='Drop Date' && EntityId != 'd6a2ff7e-bba0-48fa-9396-a6b202631669' && EntityId != 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028'">
            Drop Date
            <!-- <app-date-input  [formControlName]="'dropEffectiveDate'"></app-date-input> -->
            <div class="date_wrap">
              <input [matDatepicker]="picker" [formControlName]="'dropEffectiveDate'" class="input date_wrap__input ">
              <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>

          </div>
          <div *ngIf="EntityId == 'd6a2ff7e-bba0-48fa-9396-a6b202631669'">
            Effective Date
            <!-- <app-date-input  [formControlName]="'effactiveDate'"></app-date-input> -->
            <div class="date_wrap">
              <input [matDatepicker]="picker" [formControlName]="'effactiveDate'" class="input date_wrap__input ">
              <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
          <div *ngIf="EntityId == 'd6a2ff7e-bba0-48fa-9396-a6b202631669' || EntityId =='a11ee4de-9b44-4bcf-a01e-04e1a2fc6028'">
            Drop Date
            <!-- <app-date-input  [formControlName]="'dropEffectiveDate'"></app-date-input> -->
            <div class="date_wrap">
              <input [matDatepicker]="picker" [formControlName]="'dropEffectiveDate'" class="input date_wrap__input ">
              <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
          <div *ngIf="EntityId == 'd6a2ff7e-bba0-48fa-9396-a6b202631669'">
            Notify Date:
            <!-- <app-date-input  [formControlName]="'appDate'" disabled></app-date-input> -->
            <div class="date_wrap">
              <input matInput [matDatepicker]="picker" [formControlName]="'appDate'" class="input date_wrap__input ">
              <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
          <div *ngIf="EntityId != 'd6a2ff7e-bba0-48fa-9396-a6b202631669' && currentEnrollment==null">
            App Received Date:
            <!-- <app-date-input  [formControlName]="'appDate'" disabled></app-date-input> -->
            <div class="date_wrap">
              <input [matDatepicker]="picker" [formControlName]="'appDate'" class="input date_wrap__input ">
              <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>

          </div>
          <br />
          <div *ngIf='benefitEntityPolicy?.originalConfigurationSettings!=null'>
            Override Original Effective Date:<br />
            <label nz-checkbox formControlName="selectOriffDate" (ngModelChange)="Check($event)"></label>
            <!-- <app-date-input *ngIf='isSelected()==true' [formControlName]="'oriEffDate'" disabled></app-date-input> 
            -->
            <div *ngIf='isSelected()==true' class="date_wrap">
              <input [matDatepicker]="picker" [formControlName]="'oriEffDate'" class="input date_wrap__input ">
              <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>

          <br />
          <a (click)="onSimplifiedUW()"
            *ngIf="benefitEntityPolicy.underWritingType=='be95dfe8-48aa-42a8-90fc-a4260cc9fa83'">
            <u class="PatchFont">Check Enrollment Eligibility</u>
          </a>
        </div>
      </div>
      <div style="padding-top: 10px;">
        Remarks <br />
        <textarea rows="2" class="form-control" [formControlName]="'remark'"></textarea>
      </div>

      <!-- <pre>
      {{planForm.value | json}}
    </pre> -->

    </ng-container>
  </div>
</div>
