import { MemberState } from '@store/main/member/member.state';
import {
  AllMemberActions,
  MemberActionTypes
} from '@store/main/member/member.actions';
import { MemberDTO } from '@models/shared/member.dto';

export const initialState = new MemberState();

const resetErrorProps = {
  loadError: null,
  saveError: null,
  deleteError: null
};

export function memberReducer(
  state: MemberState = initialState,
  action: AllMemberActions
): MemberState {
  switch (action.type) {
    case MemberActionTypes.GET_MEMBER: {
      return {
        ...state,
        ...resetErrorProps,
        member: undefined,
        loading: true
      };
    }
    case MemberActionTypes.RESET_MEMBER: {
      const member1: MemberDTO = {...state.member};
      member1[action.payload.MemberProp] = null;
      return { ...state,  member: member1 };
    }
    case MemberActionTypes.GET_MEMBER_URL: {
      return {
        ...state,
        ...resetErrorProps,
        loading: true
      };
    }
    case MemberActionTypes.GET_MEMBER_URL_SUCCESS: {
      const member1: MemberDTO = {...state.member};
      member1[action.payload.prop] = action.payload.member;
      return { ...state, loading: false , member: member1 };
    }
    case MemberActionTypes.GET_MEMBER_SUCCESS: {
      return { ...state, loading: false, member: action.payload.member };
    }
    case MemberActionTypes.GET_MEMBER_FAIL: {
      return { ...state, loading: false, loadError: action.payload.error };
    }
    case MemberActionTypes.GET_MEMBER_PAGING: {
      return {
        ...state,
        ...resetErrorProps,
        loading: true
      };
    }
    /* case MemberActionTypes.GET_MEMBER_LOG: {
      return {
        ...state,
        ...resetErrorProps,
        loading: true
      };
    } */
    case MemberActionTypes.GET_MEMBER_PAGING_SUCCESS: {
      if (!state.member) {
        return state;
      }
      const memberClone: any = {
        ...state.member
      };
      memberClone[action.payload.prop] = action.payload.memberList;
      return { ...state, loading: false, member: memberClone };
    }

    ///
    case MemberActionTypes.SAVE_MEMBER: {
      return { ...state, saving: true };
    }
    case MemberActionTypes.SAVE_MEMBER_TYPE: {
      return { ...state, saving: true };
    }
    case MemberActionTypes.SAVE_MEMBER_SUCCESS: {
      return { ...state, saving: false, member: action.payload.member };
    }
    case MemberActionTypes.SAVE_MEMBER_TYPE_SUCCESS: {

      return { ...state, saving: false, objupdate: action.payload.dto,saveError:null };
    }
    case MemberActionTypes.SAVE_MEMBER_FAIL: {
      return { ...state, saving: false, saveError: action.payload };
      //return state;
    }
    case MemberActionTypes.DELETE_MEMBER: {
      return { ...state, deleting: true };
    }
    case MemberActionTypes.DELETE_MEMBER_SUCCESS: {
      return { ...state, deleting: false };
    }
    case MemberActionTypes.DELETE_MEMBER_FAIL: {
      return { ...state, deleting: false, deleteError: action.payload.error };
    }
    default:
      return state;
  }
}
