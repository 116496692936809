import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { BaseComponent } from '@shared/components/base.component';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth/auth.service';
import { Router } from '@angular/router';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { getAuthState } from '@store/auth/auth.reducer';
import { AuthState } from '@store/auth/auth.state';
import { LoginAction } from '@store/auth/auth.actions';
import { AppState } from '@store/app.state';
import { Store } from '@ngrx/store';
import { SpinnerService } from '@shared/services/spinner.service';
import { ActivatedRoute } from '@angular/router';
import { UtilitiesService } from '@services/utilities/utilities.service';

@Component({
  selector: 'app-login-consoleuser',
  templateUrl: './login_consoleuser.component.html',
  styleUrls: ['./login_consoleuser.component.scss']
})

export class LoginConsoleUserComponent extends BaseComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup
  authState: AuthState
  currentYear: number = new Date().getFullYear()
  loading = false
  card
  return = ''
  logintype
  appParameters
  isForceSso
  ssoRedirectLink
  appid
  ABCSSOToken
  queryStringParams: any

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private store: Store<AppState>,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private utiltyService: UtilitiesService,
  ) {
    super();
  }

  ngOnInit() {
    this.isLogintype()
    this.initializeForm();
    this.subscribeAuthState();
   
    this.route.queryParams
      .subscribe(params => {
        this.queryStringParams = params
      }
      );
    if (this.authService.isAuthenticated()) {
      //window.location.href = window.location.href + '/#/main/console-dashboard';

      if (this.logintype === 'consoleuser') {
        let appId = this.queryStringParams.appid
        if (appId === undefined || appId === null) {
          this.return = '/main/console-dashboard'
        }
        else {
          this.return = `/main/console-dashboard?appid=${appId}`
        }
      }
      this.router.navigateByUrl(this.return);
    }
    this.route.queryParams
      .subscribe(params => {
        this.queryStringParams = params
        console.log(params)
      });

    if (this.queryStringParams.ABCSSOToken != null) {
      this.appid = this.queryStringParams.appid
      this.ABCSSOToken = this.queryStringParams.ABCSSOToken
      this.spinner.show();
      
      this.store.dispatch(
        new LoginAction({
          request: {
            appid: this.appid,
            ABCSSOToken: this.ABCSSOToken,
          }
        })
      );
    } 
    
    else {
      if (this.logintype === 'testusertool') {
        this.utiltyService.getWithUrl('Login/LoginSettings', {}).subscribe(
          res => {
            this.isForceSso = res.LoginMethod,
            this.ssoRedirectLink = res.LoginCallBackURLForSSO
            if (!this.authService.isAuthenticated() && this.isForceSso === "ForceSSO") {
              window.location.href = this.ssoRedirectLink
            }
          }
        )
      }
    }


  }

  ngOnDestroy() {
    this.spinner.hide()
    super.ngOnDestroy()
  }

  onSubmit() {
    if (this.form.valid) {
      this.login()
    }
  }

  login() {
    this.spinner.show()
    const dto = this.form.value
    this.store.dispatch(
      new LoginAction({
        request: {
          username: dto.username,
          password:  encodeURIComponent(dto.password),
          appid: 'DEF4CDCB-F5B7-401C-97AC-1F8DC55AF5BC'
        }
      })
    );

    setTimeout(() => {
      if (this.authService.isAuthenticated()) {
        this.refreshtoken()
      }
    }, 5000);
   
  }

  
  loginRefreshToken() {
    const dto = this.form.value
    this.store.dispatch(
      new LoginAction({
        request: {
          refresh_token: this.authService.getRefreshToken()
        }
      })
    );
  }

  refreshtoken ()
  {
    setTimeout(() => {
      if(this.authService.getRefreshToken() != null)
      {
        this.loginRefreshToken();
      }
     this.refreshtoken();
    }, this.authService.gettokenexpirytime() * 1000);
  }


  isLogintype() {
    let config = window['___alliant-config-eniac-APP___']
    this.logintype = config.logintype;
  }

  private subscribeAuthState() {
    this.store
      .select(getAuthState)
      .pipe(untilComponentDestroyed(this))
      .subscribe(x => this.authStateChange(x))
  }

  public authStateChange(state: AuthState) {
    this.authState = state;
    if (state.loading === false && state.success != null) {
      if (this.logintype === 'testusertool') {
        this.return = '/main/usertool-dashboard'
      }
      if (this.logintype === 'consoleuser') {
        let appId = this.queryStringParams.appid
        console.log(appId);
        if (appId === undefined || appId ===null)
        {
          this.return = '/main/console-dashboard';
        }
        else {
          this.return = `/main/console-dashboard?appid=${appId}`
        }
      }
      if (this.logintype === 'testusertool') {
        this.return = '/main/usertool-dashboard'
      }
      if (this.logintype === 'testusertool') {
        this.return = '/main/usertool-dashboard'
      }
      this.router.navigateByUrl(this.return)
    }
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: ['', { validators: [Validators.required] }],
      password: ['', { validators: [Validators.required] }]
    });
  }
}