<table class="table table-bordered">
  <tbody>
    <tr>
      <td>On Demand Login</td>
      <td class="value">No</td>
    </tr>
    <tr>
      <td>Background Image</td>
      <td class="value"></td>
    </tr>
    <tr>
      <td>Welcome Message</td>
      <td class="value">N/A</td>
    </tr>
    <tr>
      <td>Custom Welcome text</td>
      <td class="value">Welcome to the Added Benefits Program for American Airlines!</td>
    </tr>
    <tr>
      <td>Login Credentials</td>
      <td class="value">Username</td>
    </tr>
    <tr>
      <td>Site Font Size</td>
      <td class="value">18px</td>
    </tr>
    <tr>
      <td>First Deduction (Paycheck) Date by Pay Frequency</td>
      <td class="value">Bi-weekly</td>
    </tr>
    <tr>
      <td>Type</td>
      <td class="value">Login</td>
    </tr>
    <tr>
      <td>Dependent Registration</td>
      <td class="value">Yes</td>
    </tr>
    <tr>
      <td>Retiree Registration</td>
      <td class="value">No</td>
    </tr>
    <tr>
      <td>Should 'registration elements' match exactly?</td>
      <td class="value">Yes</td>
    </tr>
    <tr>
      <td>Registration Employee ID Message</td>
      <td class="value"></td>
    </tr>
    <tr>
      <td>Registration Employee ID Custom Message Text</td>
      <td class="value">N/A</td>
    </tr>
    <tr>
      <td>Use Employee ID as User Name</td>
      <td class="value">Yes</td>
    </tr>
  </tbody>
</table>