import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { catchError, switchMap, tap, map } from 'rxjs/operators';
import { AuthSuccessDTO } from '@models/auth';
import { ApiErrorDTO } from '@models/shared/api-error.dto';
import {
  LoginAction,
  LoginSuccessAction,
  AuthActionTypes,
  LogoutAction,
  LoginFailAction
} from '@store/auth/auth.actions';
import { AuthService } from '@services/auth/auth.service';
import { LoggerService } from '@shared/logging/logger.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { LocalStorageService } from '@shared/storage/local-storage.service';
@Injectable()
export class AuthEffects {
  constructor(
    private notification: NzNotificationService,
    private logger: LoggerService,
    private actions$: Actions, protected spinner: SpinnerService,
    private authService: AuthService,
    private router: Router, private storageService: LocalStorageService
  ) {}

  @Effect()
  Login$ = this.actions$.pipe(
    ofType<LoginAction>(AuthActionTypes.LOGIN),
    switchMap(action =>
      this.authService.authenticate(action.payload.request).pipe(
        switchMap<AuthSuccessDTO, any>(result => {
          return of(new LoginSuccessAction({ result }));
        }
        ),
        catchError(error => {
            this.spinner.hide();
            if (error.error.error_description) {
              this.notification.blank( error.error.error_description, ' ');
             } else if (error.message) {
               this.notification.blank( error.message, ' ');
            } else {
              this.notification.blank( 'Technical error during login. Please contact administrator.', ' ');
            }
            
            return of(new LoginFailAction({ error: this.createError(error) }));
        })
      )
    )
  );

  /* Login$ = this.actions$.pipe(
    ofType<LoginAction>(AuthActionTypes.LOGIN),
    switchMap((action) => {
       return this.authService.authenticate(action.payload.request);
    }
    ),
    map((result: AuthSuccessDTO) => {
      return new LoginSuccessAction({ result });
  }),
    catchError(error => {
      this.spinner.hide();
      this.notification.blank( 'Login failed: Invalid username or password.', ' ');
      return of(new LoginFailAction({ error: this.createError(error) }));
    })
    ); */

  @Effect({ dispatch: false })
  LoginSuccess: Observable<any> = this.actions$.pipe(
    ofType<LoginSuccessAction>(AuthActionTypes.LOGIN_SUCCESS),
    tap((action: LoginSuccessAction) => {
      if (action.payload.result.username)
      {
        if (action.payload.result.username.toLowerCase() === 'tmaituser') {
          // tslint:disable-next-line:max-line-length
          action.payload.result = {...action.payload.result, entityList: [{entityId: '9808f856-f047-4843-bec8-277e414fc649', entityName: 'TMA Insurance Trust'}]};
        }
        
      }
      this.authService.setAuth(action.payload.result);
      // this.router.navigate(['/main/user-dashboard']);
    })
  );

  @Effect({ dispatch: false })
  Logout: Observable<any> = this.actions$.pipe(
    ofType<LogoutAction>(AuthActionTypes.LOGOUT),
    tap(() => {
      this.authService.removeAuth();
      this.router.navigate(['/login']);
      
    })
  );

  private createError(error: any): ApiErrorDTO {
    this.logger.error(error);

    return {
      message: 'Login failed',
      code: '100',
      originalError: error
    };
  }
}
