import {
  Component,
  OnInit,
  ViewChild,
  Input,
  forwardRef,
  Renderer2,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import {
  SsnFormatterService,
  SSN_INPUT_MASK
} from '@shared/formatters/ssn-formatter.service';
import InputMask from 'inputmask';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'app-ssn-input',
  templateUrl: './ssn-input.component.html',
  styleUrls: ['./ssn-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SsnInputComponent),
      multi: true
    }
  ]
})
export class SsnInputComponent extends BaseComponent
  implements OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild('input')
  input: ElementRef;

  @Input()
  allowInvalid = true;
  @Input()
  maskModelValue = true;
  @Input()
  revealIcon = false;

  constructor(
    private renderer: Renderer2,
    private formatter: SsnFormatterService
  ) {
    super();
  }

  // callbacks for ControlValueAccessor
  onChange: any;
  onTouched: any;

  private _rawValue?: string;
  get rawValue(): any {
    return this._rawValue;
  }

  private _modelValue?: string;
  get modelValue(): any {
    return this._modelValue;
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    InputMask({ mask: SSN_INPUT_MASK }).mask(this.input.nativeElement);
  }

  // ControlValueAccessor method
  // This method will be called by the forms API to write to the view
  // when programmatic (model -> view) changes are requested.
  writeValue(value: any): void {
    this.updateModelToView(value);
  }

  // ControlValueAccessor method
  // Registers a callback function that should be called when the control's value changes in the UI.
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // ControlValueAccessor method
  // This is called by the forms API on initialization so it can update the form model when
  // values propagate from the view (view -> model).
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  inputFocus(value: string) {
    this.onTouched();
  }

  inputBlur(value: string) {
    this.updateViewToModel(value);
  }

  private updateModelToView(value: any): void {
    if (this.modelValue === value) {
      return;
    }

    const { valid, ssn } = this.validateAndParse(value);

    if (valid) {
      this.formatAndUpdateDOM(ssn);
    } else {
      this.updateDOMWithInvalid(value);
    }
  }

  private updateViewToModel(value: string): void {
    if (this.rawValue === value) {
      return;
    }

    const { valid, ssn } = this.validateAndParse(value);

    if (valid) {
      this.formatAndUpdateDOM(ssn);
    } else {
      this.updateDOMWithInvalid(value);
    }

    this.raiseOnChange(value, ssn, valid);
  }

  private formatAndUpdateDOM(ssn: string) {
    const formatted = this.format(ssn);
    this.updateDOM(formatted);
  }

  private updateDOMWithInvalid(value: any) {
    if (this.invalidAllowed()) {
      this.updateDOM(value);
    } else {
      this.updateDOM('');
    }
  }

  private updateDOM(value: string) {
    this._rawValue = value;
    this.renderer.setProperty(this.input.nativeElement, 'value', value);
  }

  private validateAndParse(
    value: any
  ): {
    valid: boolean;
    ssn: string;
  } {
    const ssn = this.parse(value);
    return { valid: !!ssn, ssn };
  }

  private format(value: string): string {
    return this.formatter.format(value);
  }

  private parse(value: any) {
    return this.formatter.parse(value);
  }

  private raiseOnChange(viewValue: string, ssn: string, valid: boolean) {
    this._modelValue = this.getModelValue(viewValue, ssn, valid);
    this.onChange(this._modelValue);
  }

  private getModelValue(viewValue: string, ssn: string, valid: boolean): any {
    const maskModelValue = this.isTruthy(this.maskModelValue);

    if (valid) {
      return maskModelValue ? viewValue : ssn;
    }

    if (this.invalidAllowed()) {
      return maskModelValue
        ? viewValue
        : InputMask.unmask(viewValue, { mask: SSN_INPUT_MASK });
    }

    return undefined;
  }

  private invalidAllowed(): boolean {
    return super.isTruthy(this.allowInvalid);
  }
}
