<div style="padding: 0px 30px;">
  <app-selected-member-header-share [memberState]="memberState" [entityClientId]='EntityClientId'>
  </app-selected-member-header-share>
</div>
<div class="row app-form-group-wrapper">
  <div class="col-10">
    <h3>Reinstatement</h3>
    <h5> SuspenseBalance: <span style="color: green;">{{balance | currency}}</span></h5>
    <nz-table #rowSelectionTable [nzFrontPagination]='false' [nzShowPagination]='false' [nzData]="listOfAllData"
      nzSize="small">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Benefit Name</th>
          <th>Tier</th>
          <th>Effective Date</th>
          <th>Benefit Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of rowSelectionTable.data">
          <td *ngIf="data.benefitStatus==='Active' || data.benefitStatus==='PendingActive'"
            [(nzChecked)]="mapOfCheckedId[data.benefitEntityPolicyId+data.benefitEntityPolicyDetailId]"></td>
          <td nzShowCheckbox *ngIf="data.benefitStatus!=='Active' && data.benefitStatus!=='PendingActive'"
            [(nzChecked)]="mapOfCheckedId[data.benefitEntityPolicyId+data.benefitEntityPolicyDetailId]"></td>
          <td>{{ data.firstName+' '+ data.lastName}}</td>
          <td>{{ data.benefitPolicyName}}</td>
          <td>{{ data.benefitPolicyDetailName}}</td>
          <td>{{ data.effectiveDate | date }}</td>
          <td>{{ data.benefitStatus }}</td>
        </tr>
      </tbody>
    </nz-table>
    <br />
    <div class="col-12" style="padding: 0px;text-align: right;">
      <button class="mat-flat-button mat-primary" (click)=onReinstatement()>Reinstate</button>
      &nbsp;&nbsp;
      <button class="mat-flat-button mat-primary" (click)=onCancel()>Cancel</button>
    </div>
  </div>
  <div class="col-2">
    <app-member-quick-links-share [prePath]="'../'" [entityId]='EntityId'></app-member-quick-links-share>
  </div>
</div>