import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '@shared/window.service';

// ***IMPORTANT*** This must match the key in "src/config/dev.config.js" and "src/config/prod.config.js"
export const APP_CONFIG_KEY = '___alliant-config-eniac-APP___';
// ***IMPORTANT*** This must match the key in "src/config/api-endpoints.config.js"
export const API_CONFIG_KEY = '___alliant-config-eniac-API___';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _config: any;
  private _configApi: any;

  constructor(@Inject(WINDOW) window: Window) {
    this._config = window[APP_CONFIG_KEY];
    this._configApi = window[API_CONFIG_KEY];
  }

  get httpServices(): any {
    return this._config.httpServices;
  }

  get config(): any {
    return this._config;
  }

  get api(): any {
    return this._configApi;
  }
}
