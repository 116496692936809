import { Injectable } from '@angular/core';
import {
  ResponseOptions,
  STATUS,
  getStatusText
} from 'angular-in-memory-web-api';
import {
  RequestInfo,
  RequestInfoUtilities
} from 'angular-in-memory-web-api/interfaces';
import * as _ from 'lodash';
import { ConfigService } from '@shared/config.service';
import { REQUEST_HEADERS } from '@shared/api/shared';
import { HttpHeaders } from '@angular/common/http';
import { LoggerService } from '@shared/logging/logger.service';
import { MockRequestHandler } from '@mock/mock-data/shared/mock-handler.interface';
import { AuthMockHandler } from '@mock/mock-data/auth/auth.mock.handler';
// import { registerClientUrls } from '@mock/services/client-mock-registration';
import { registerLookupUrls } from '@mock/services/lookup-mock-registration';
// import { registerBenefitsAndVendors } from '@mock/services/benefits-vendors-mock-registration';
import { registerMemberUrls } from '@mock/services/member-mock-registration';

const URL_HANDLER_MAP: { [key: string]: Function } = {};

@Injectable()
export class MockHelperService {
  constructor(
    private logger: LoggerService,
    private configService: ConfigService
  ) {
    this.logger = this.logger.create(this);
  }

  registerUrls() {
    this.addPostHandler('token', new AuthMockHandler());
    registerLookupUrls(this);
    // registerClientUrls(this);
    registerMemberUrls(this);
    // registerBenefitsAndVendors(this);
  }

  addGetHandler(url: string, handler: MockRequestHandler<any>) {
    this.addHandler({ url, method: 'GET', handler });
  }

  addPostHandler(url: string, handler: MockRequestHandler<any>) {
    this.addHandler({ url, method: 'POST', handler });
  }

  private addHandler(params: {
    url: string;
    method: string;
    handler: MockRequestHandler<any>;
  }) {
    if (!params || !params.method || !params.url || !params.handler) {
      throw Error(
        `'params' parameter is required along with 'url', 'method' and 'handler'.`
      );
    }

    if (!(params.handler instanceof MockRequestHandler)) {
      throw new Error(
        `'params.handler' must be a class that inherits from MockRequestHandler`
      );
    }

    const key = this.createHandlerKey(params);

    if (URL_HANDLER_MAP[key]) {
      throw Error(
        `A handler is already registered for ${params.method}:${params.url}.`
      );
    }

    URL_HANDLER_MAP[key] = (handlerParams: {
      reqInfo: RequestInfo;
      urlTemplate: string;
      httpParams: any;
    }) =>
      this.createSuccessResponse(
        handlerParams.reqInfo,
        params.handler.handleRequest(handlerParams)
      );
  }

  handleRequest(reqInfo: RequestInfo): any {
    const httpParams = this.getHttpParams(reqInfo);

    // Try getting the handler for the EXACT URL
    const handler = this.getMockHandlerForExactUrl(reqInfo);
    if (handler) {
      this.logger.debug(`handle using mock [Exact URL] ${reqInfo.url}`);
      return handler({ reqInfo, urlTemplate: undefined, httpParams });
    }

    // Try getting the handler for the RELATIVE URL
    const handlerParams = this.getMockHandlerInfoForRelativeUrl(reqInfo);
    if (handlerParams && handlerParams.handler) {
      this.logger.debug(`handle using mock [urlTemplate] ${reqInfo.url}`);
      return handlerParams.handler({
        reqInfo,
        urlTemplate: handlerParams.urlTemplate,
        httpParams
      });
    }

    this.logger.debug(
      `no mock handler registered for ${reqInfo.url}. fallback to XHR request.`
    );

    // returning 'undefined' falls back to XHR request
    return undefined;
  }

  private createHandlerKey(params: { url: string; method: string }): string {
    return `${params.method}|${params.url}`;
  }

  private getMethodAndUrlFromHandlerKey(key: string): any {
    if (!key) {
      return undefined;
    }

    const parts = key.split('|');
    return {
      method: parts[0],
      url: parts[1]
    };
  }

  private getMockHandlerForExactUrl(reqInfo: RequestInfo): any {
    let requestUrl = reqInfo.url;
    if (!requestUrl) {
      return;
    }
    requestUrl = this.removeMockBasePath(requestUrl);

    // We want to retrieve the mock handler that closely matches the url we are currently intercepting.
    // i.e. if there are two handlers registered with the mock urls..
    // #1 clients/C1234 and
    // #2 clients/C1234/benefits/B9017
    // ...and we are intercepting 'clients/C1234/benefits/B9017'
    // this method should return the handler for #2 'clients/C1234/benefits/B9017'

    // convert to lower case to make string comparions faster
    const requestMethodLowerCase = reqInfo.method.toLowerCase();

    // get the handlers that match the request Method (GET, POST etc.)
    const handlerKeysForMethod = _.filter(_.keys(URL_HANDLER_MAP), key => {
      const { method, url } = this.getMethodAndUrlFromHandlerKey(key);
      return (
        requestMethodLowerCase === method.toLowerCase() &&
        requestUrl.startsWith(url)
      );
    });
    // sort and take the longest url (appears last after sort)
    const sortedHandlerKeys = _.sortBy(handlerKeysForMethod, key => key.length);
    const handlerKey = sortedHandlerKeys[sortedHandlerKeys.length - 1];

    return URL_HANDLER_MAP[handlerKey];
  }

  private getMockHandlerInfoForRelativeUrl(reqInfo: RequestInfo): any {
    // get the url template before assigning params ex: api/clients/:clientId/benefits/:benefitId
    const urlTemplate = this.getUrlTemplate(reqInfo);
    if (!urlTemplate) {
      return;
    }

    // We want to retrieve the mock handler that closely matches the url we are currently intercepting.
    // i.e. if there are two handlers registered with the mock urls..
    // #1 clients/:clientId and
    // #2 clients/:clientId/benefits/:benefitId
    // ...and we are intercepting 'clients/:clientId/benefits/9017'
    // this method should return the handler for #2 'clients/:clientId/benefits/:benefitId'

    // convert to lower case to make string comparions faster
    const urlTemplateLowerCase = urlTemplate.toLowerCase();
    const requestMethodLowerCase = reqInfo.method.toLowerCase();

    // get the handlers that match the request Method (GET, POST etc.)
    const handlerKeysForMethod = _.filter(_.keys(URL_HANDLER_MAP), key => {
      const { method, url } = this.getMethodAndUrlFromHandlerKey(key);
      return (
        requestMethodLowerCase === method.toLowerCase() &&
        urlTemplateLowerCase.startsWith(url.toLowerCase())
      );
    });
    // sort and take the longest url (appears last after sort)
    const sortedHandlerKeys = _.sortBy(handlerKeysForMethod, key => key.length);
    const handlerKey = sortedHandlerKeys[sortedHandlerKeys.length - 1];

    // log only if different
    // Ex: 'clients/:clientId' becomes 'clients/a1578f3f-eec8-4502-ba32-8dd4239aa4aa'
    const urlWithParams = this.removeMockBasePath(reqInfo.url);
    if (urlTemplate !== urlWithParams) {
      this.logger.debug(`urlTemplate: ${urlTemplate} => ${urlWithParams}`);
    }

    return {
      handler: URL_HANDLER_MAP[handlerKey],
      urlTemplate
    };
  }

  private getUrlTemplate(reqInfo: RequestInfo): string {
    const requestHeaders = reqInfo.req['headers'] as HttpHeaders;
    return requestHeaders
      ? requestHeaders.get(REQUEST_HEADERS.URL_TEMPLATE) || ''
      : '';
  }

  private getHttpParams(reqInfo: RequestInfo): any {
    const requestHeaders = reqInfo.req['headers'] as HttpHeaders;
    const jsonHttpParams = requestHeaders
      ? requestHeaders.get(REQUEST_HEADERS.HTTP_PARAMS)
      : '';
    if (!jsonHttpParams) {
      return {};
    }

    try {
      return JSON.parse(jsonHttpParams);
    } catch (e) {
      this.logger.warn(e);
      return {};
    }
  }

  private createSuccessResponse(reqInfo: RequestInfo, data: any) {
    return this.createResponse(reqInfo, data, STATUS.OK);
  }

  private createResponse(reqInfo: RequestInfo, data: any, httpStatus: number) {
    return reqInfo.utils.createResponse$(() => {
      const dataEncapsulation = reqInfo.utils.getConfig().dataEncapsulation;
      const options: ResponseOptions = {};

      if (data) {
        options.body = dataEncapsulation ? { data } : data;
      }
      options.status = httpStatus;
      options.statusText = getStatusText(httpStatus);
      options.headers = reqInfo.headers;
      options.url = reqInfo.url;

      return options;
    });
  }

  private removeMockBasePath(url: string) {
    const mockBasePath = this.configService.config.mockBaseUrl;
    if (url.startsWith(mockBasePath)) {
      url = url.substring(mockBasePath.length);
    }
    return url;
  }
}
