import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { ApiEndpointService } from '@shared/api/api-endpoint.service';
import { Observable } from 'rxjs';
import { HttpHelperService } from '@shared/api/http-helper.service';
import { HttpRequestOptions } from '@shared/api/shared';

export abstract class HttpBaseService {
  constructor(
    private http: HttpClient,
    private httpHelper: HttpHelperService,
    private endpointService: ApiEndpointService
  ) {}

  get(
    relativeUrl: string,
    params?: {
      urlParams?: any;
      options?: HttpRequestOptions;
    }
  ): Observable<any> {
    if (_.isEmpty(relativeUrl)) {
      throw Error(`'relativeUrl' parameter is required.`);
    }

    const { urlParams = {}, options = {} } = params || {};
    const { url, httpOptions } = this.prepareUrlAndHttpOptions(
      relativeUrl,
      urlParams,
      options
    );

    return this.http.request('GET', url, httpOptions);
  }

  post(
    relativeUrl: string,
    params?: {
      body?: any | null;
      urlParams?: any;
      options?: HttpRequestOptions;
    }, headers?
  ): Observable<any> {
    if (_.isEmpty(relativeUrl)) {
      throw Error(`'relativeUrl' parameter is required.`);
    }

    const { body = {}, urlParams = {}, options = {} } = params || {};
    const { url, httpOptions } = this.prepareUrlAndHttpOptions(
      relativeUrl,
      urlParams,
      options
    );
    httpOptions.body = body;
    if (headers) {
      httpOptions.headers = headers;
    }

    return this.http.request('POST', url, httpOptions);
  }

  put(
    relativeUrl: string,
    params?: {
      body?: any | null;
      urlParams?: any;
      options?: HttpRequestOptions;
    }, headers?
  ): Observable<any> {
    if (_.isEmpty(relativeUrl)) {
      throw Error(`'relativeUrl' parameter is required.`);
    }

    const { body = {}, urlParams = {}, options = {} } = params || {};
    const { url, httpOptions } = this.prepareUrlAndHttpOptions(
      relativeUrl,
      urlParams,
      options
    );
    httpOptions.body = body;
    if (headers) {
      httpOptions.headers = headers;
    }

    return this.http.request('PUT', url, httpOptions);
  }

  delete(
    relativeUrl: string,
    params?: {
      body?: any | null;
      urlParams?: any;
      options?: HttpRequestOptions;
    }
  ): Observable<any> {
    if (_.isEmpty(relativeUrl)) {
      throw Error(`'relativeUrl' parameter is required.`);
    }

    const { body = {}, urlParams = {}, options = {} } = params || {};
    const { url, httpOptions } = this.prepareUrlAndHttpOptions(
      relativeUrl,
      urlParams,
      options
    );
    httpOptions.body = body;

    return this.http.request('DELETE', url, httpOptions);
  }

  private prepareUrlAndHttpOptions(
    relativeUrl: string,
    params: any,
    options?: HttpRequestOptions
  ): {
    url: string;
    httpOptions: any;
  } {
    options = options || {};

    let headers = this.httpHelper.createHttpHeaders(options.headers);
    headers = this.httpHelper.addCacheHeaders(headers, options);
    headers = this.httpHelper.addSkipErrorInterceptorHeaders(headers, options);
    headers = this.httpHelper.addSuppressErrorHeaders(headers, options);
    headers = this.httpHelper.addDuplicateHandlerHeader(headers, options);
    headers = this.httpHelper.addHttpParamsHeader(headers, params);
    headers = this.httpHelper.addUrlTemplateHeader(headers, relativeUrl);
    headers = this.httpHelper.addUseMockHeader(
      headers,
      options,
      this.endpointService.configUseMock
    );

    const { url, excessParams } = this.endpointService.buildUrl(
      relativeUrl,
      params,
      options
    );

    const httpOptions = {
      headers: headers,
      params: this.httpHelper.createHttpParams(excessParams)
    };

    return { url, httpOptions };
  }
  public buildUrl(
    url: string,
    params: any,
    options: HttpRequestOptions
  ): {
    url: string;
    excessParams?: any;
  } {
    return this.endpointService.buildUrl(
      url,
      params,
      options
    );
  }
}
