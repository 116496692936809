
import { Component } from '@angular/core';
import { ReportingComponent } from '@shared/components/dynamic-reporting/dynamic-reporting.component';

@Component({
  templateUrl: './reportprint.component.html',
  styleUrls: ['./reportprint.component.scss']
})

export class CobraReportPrintComponent extends ReportingComponent {

  getEntityID() {
    return 'd6a2ff7e-bba0-48fa-9396-a6b202631669';
  }

  onMarkPrinted() {
    const dto: any = [];
    this.data.forEach(element => {
      if (element.checked) {
        dto.push(element.item[0]);
      }
    });

    const url = 'reports/cobra/mark?entityId=' + 'd6a2ff7e-bba0-48fa-9396-a6b202631669';
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe(res => {
      this.notification.blank('Marked  successfully', ' ')
    }, err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }
}
