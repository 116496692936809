<div class="main-login">
  <div class="shape1"></div>
  <div class="shape2"></div>
  <div class="shape3"></div>
  <div class="form-container">
    <form class="login-form" [formGroup]="form" (submit)="onSubmit()">
      <mat-card>
        <div class="d-flex justify-content-center my-2">
          <img style="width: 80px; margin: 10px;" src="{{logo}}" alt="AEBSys.com logo">
        </div>
        <mat-card-content>
          <p>

            <mat-form-field>
              <span style="position: absolute;top:8px; left:5px;font-size: 18px; color: #acacac;"><i class="fa fa-user"
                  aria-hidden="true"></i></span>
              <input matInput autocomplete="off"
                placeholder="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type your username"
                formControlName="username" style="padding-left: 30px;" required />
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <span style="position: absolute;top:8px; left:5px;font-size: 18px;  color: #acacac;"><i class="fa fa-lock"
                  aria-hidden="true"></i></span>
              <input matInput autocomplete="off" type="password"
                placeholder="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type your password"
                formControlName="password" style="padding-left: 30px;" required />
            </mat-form-field>
          </p>
        </mat-card-content>
        <mat-card-actions>
          <div *ngIf="authState as auth">
            <div *ngIf="!auth.loading && (auth.error || !auth.success)">
              <button mat-flat-button type="submit" color="primary">LOGIN</button>
            </div>
            <ng-container *ngIf="auth.loading && !auth.error">
              <p class="login__loading">Signing in...</p>
            </ng-container>
            <ng-container *ngIf="auth.error">
              <div class="login__error">
                <p>{{auth.error.Message}}</p>
              </div>
            </ng-container>
          </div>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>