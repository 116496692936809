import { ErrorType } from '@store/app.state';
import { ClientDTO } from '@models/client/client.dto';

export class ClientState {
  loading = false;
  saving = false;
  deleting = false;
  client: ClientDTO;
  loadError?: ErrorType;
  saveError?: ErrorType;
  deleteError?: ErrorType;
}
