import { Component, OnInit, Inject, Input , EventEmitter, Output } from '@angular/core';
import { DynamicGridComponent, DynamicMainGridComponent, DynamicMainGridHeaderComponent} from '@shared/components/dynamic_grid/dynamic-grid.component';
import { DynamicTemplateComponent } from '@shared/components/dynamic_template/dynamic_template.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { BaseComponent } from '@shared/components/base.component';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '@store/app.state';
import { Store } from '@ngrx/store';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { LoggerService } from '@shared/logging/logger.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { MemberService } from '@services/client/member.service';
import { ExcelService } from '@services/excel/excel.service';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import {
  GetMainUrlAction,
} from '@store/main/main.actions';
import { MainState } from '@store/main/main.state';
import * as _ from 'lodash';
import {
  getMemberState,
  isMemberStateMutating
} from '@store/main/member/member.selector';
import {
  getMainState,
  isMainStateMutating
} from '@store/main/main.selector';
import { HttpService } from '@shared/api/http.service';
import { ClientService } from '@services/client/client.service';
import { AuthService } from '@services/auth/auth.service';
import { DynamicFormWithGridContainnerComponent} from '@shared/components/dynamic_form_with_grid/dynamic_form_with_grid.component';

@Component({
    selector: 'app-dynamicletters',
    templateUrl: './dynamic-grid.component.html',
    styleUrls: ['./../dynamic.component.scss']
  })
  export class DynamicLettersComponent extends DynamicGridComponent implements OnInit {
    columnNames; template; loading;
    constructor(
        protected authService: AuthService,  
        http: HttpClient, 
        public dialog: MatDialog,
        logger: LoggerService, 
        protected clientService: ClientService,
        protected store: Store<AppState>,
        protected routeHelper: RouteHelperService,
        protected route: ActivatedRoute,
        protected memberService: MemberService,
        protected router: Router,
        protected spinner: SpinnerService,  httpser: HttpService, protected excelService: ExcelService) {
        super(authService, http, dialog, logger, clientService, store, routeHelper, route, memberService, router, spinner, httpser, excelService);

    }
    ngOnInit() {
        this.EntityId = this.routeHelper.getEntityId(this.route);
        this.ClientId = '00000000-0000-0000-0000-000000000000' ; // this.routeHelper.getClientId(this.route);
        this.MemberId = this.routeHelper.getMemberId(this.route);
        this.entityClientId = this.routeHelper.getEntityClientId(this.route);
        this.init();
        if(this.MemberId==''){
          this.disablememberquick=true;
        }
      }
    protected    action(item: any): void {
        let dialogRef ;
        this.columnNames=JSON.parse(item.columnNames);
        dialogRef = this.dialog.open(DynamicTemplateComponent, {
            width: '90%',
            height: '90%',
            data: {
                data:JSON.parse(item.data),
                columnNames : this.columnNames,
                template : item.template
            }
          });
    }
}