<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  <br />
  <h2 class="col-12 member-function-header">B2B Dashboard</h2>
  <div class="row" style="margin: 0px">
    <div class="col-2">
      <ng-select [(ngModel)]="selectedEntity">
        <ng-container *ngFor="let item of entities">
          <ng-option value='{{item.id}}'>{{item.entityName}}</ng-option>
        </ng-container>
      </ng-select>
    </div>
    <div class="col-2">
      <ng-select [(ngModel)]="selectedType">
        <ng-option *ngFor='let item of listOfData' value={{item.name}}>{{item.description}}</ng-option>
        <ng-option value='All'>All</ng-option>
      </ng-select>
    </div>
    <div class="col-8" style="padding-left: 0px">
      <nz-range-picker [nzFormat]="dateFormat" [(ngModel)]="selectedDateRange"></nz-range-picker>
      <button mat-flat-button color="primary" style="margin-left: 15px" (click)="onSearch()"><i
          nz-icon [nzType]="'search'"></i>Search</button>
    </div>
  </div>
  <div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
    <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
    height:200px; 
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -100px;"></nz-spin>
    <span class="default-font-Color-dark">Loading ...</span>
  </div>
  <app-dynamic-main-grid (actionPopup)="actionPopup($event)" pageSize=50 class="col-12" gridid='fileprocess'
    [inputRows]='rows'></app-dynamic-main-grid>
</div>