import { Logger } from '@shared/logging/logger';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class LoggerService implements Logger {
  abstract create(loggerName?: any): LoggerService;
  abstract trace(message: any, ...additional: any[]): void;
  abstract debug(message: any, ...additional: any[]): void;
  abstract info(message: any, ...additional: any[]): void;
  abstract log(message: any, ...additional: any[]): void;
  abstract warn(message: any, ...additional: any[]): void;
  abstract error(message: any, ...additional: any[]): void;
}
