import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take, finalize, catchError, defaultIfEmpty } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';
import { RouteHelperService } from '@shared/route/route-helper.service';
import {
  MemberListDTO,
  MemberListItemDTO,
} from '@models/member-list/member-list.dto';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MemberService } from '@services/client/member.service';
import { SelectionService } from '@store/selection/selection.service';
import {
  MEMBER_ROUTE_SEGMENT,
  CLIENT_ROUTE_SEGMENT,
} from '@shared/route/constants';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { empty, combineLatest } from 'rxjs';
import { extractError } from '@shared/api/url-utils';
import * as _ from 'lodash';
import { EntityIdClientIdDTO } from '@models/entity/entity.dto';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import {
  SetSelectedEntityByIdAction,
  SetSelectedClientByIdAction,
} from '@store/selection/selection.action';
import { EntityService } from '@services/client/entity.service';
import { ActivatedRoute } from '@angular/router';
import { getSelectionState } from '@store/selection/selection.selector';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { SelectionState } from '@store/selection/selection.state';
import { SpinnerService } from '@shared/services/spinner.service';
import { Observable } from 'rxjs';
import { HttpService } from '@shared/api/http.service';
import { ClientService } from '@services/client/client.service';
import { AuthService } from '@services/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-member-lookup',
  templateUrl: './member-lookup.component.html',
  styleUrls: ['./member-lookup.component.scss'],
})
export class MemberLookupComponent extends BaseComponent implements OnInit {
  searchValue = '';
  formConfigs;
  saveButton;
  gridConfigs;
  searchUrl;
  searchProp;
  sortName: string | null = null;
  sortValue: string | null = null;
  sortType;
  searchFormValue;
  filters: any = [];
  orirows: any = [];
  entityListWithClients: any = [];
  selectedEntityClient: EntityIdClientIdDTO;
  addMemberRoute: string | null;
  selectionState?: SelectionState;
  rows;
  entityList;
  from;
  formValue = {};
  currentEntityId;
  loading;
  isAddNewMemberAllowed: boolean = true;

  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private httpser: HttpService,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private routeHelper: RouteHelperService,
    private selectionService: SelectionService,
    private memberService: MemberService,
    private entityService: EntityService,
    private router: Router,
    protected notification: NzNotificationService,
    private spinner: SpinnerService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    this.from = this.route.snapshot.data.from;
    this.entityList = this.authService.getEntityList();
    this.subscribeSelectionState();
    this.initializeDropdownnew();
  }

  selection(item) {
    return this.routeHelper.getMemberRoute({
      EntityId: this.selectedEntityClient.entityId,
      ClientId: item.clientId,
      MemberId: item.participantId,
      Segment: MEMBER_ROUTE_SEGMENT.dashboard,
      EntityClientId: item.entityClientId,
    });
  }

  convertPseudo(str) {
    var newstr = '';
    for (var i = 0; i < str.length; i++) {
      var c = str.charAt(i);
      if (c >= '0' && c <= '9') {
        var cc = 9 - c;
        newstr = newstr + cc.toString();
      }
    }
    return newstr;
  }

  convertdate(obj) {
    this.formConfigs[0]['Search Condition'].forEach((element) => {
      if (element['controlType'] == 'date') {
        const date = obj[element['controlName']];
        if (date != '' && date != null) {
          const tt =
            date.getMonth() +
            1 +
            '/' +
            date.getDate() +
            '/' +
            date.getFullYear();
          obj[element['controlName']] = tt;
        }
      }
    });
  }

  onSave(obj) {
    this.convertdate(obj);
    if (obj['pseudossNumber'] != '' && obj['pseudossNumber'] != null) {
      obj['ssNumber'] = this.convertPseudo(obj['pseudossNumber']);
    }
    obj.PageSize = 100;
    if (
      this.selectedEntityClient == null ||
      this.selectedEntityClient.entityId === ''
    ) {
      this.notification.blank('Please select a client', ' ');
      return;
    }
    obj.entityId = this.selectedEntityClient.entityId;
    obj.clientId = this.selectedEntityClient.clientId;
    obj.entityClientId = this.selectedEntityClient.entityClientId;

    this.loading = true;
    const observ$ = this.clientService
      .postWithUrl({ url: this.searchUrl, dto: obj })
      .pipe(untilComponentDestroyed(this));
    observ$.subscribe(
      (data) => {
        this.loading = false;
        if (this.searchProp != null) {
          this.rows = data[this.searchProp];
        } else {
          this.rows = data;
        }
        this.orirows = [...this.rows];
      },
      (err) => {
        this.loading = false;
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  searchCust(key, obj) {
    // obj.filterType = 'in';
    const index = this.filters.findIndex((x) => x.key === key);
    if (index === -1) {
      this.filters.push({
        value: this.searchValue,
        key,
        type: 'search',
        columnType: obj.columnType,
      });
    } else {
      this.filters[index] = {
        value: this.searchValue,
        key,
        type: 'search',
        columnType: obj.columnType,
      };
    }
    this.search();
  }

  resetCust(key) {
    const index = this.filters.findIndex((x) => x.key === key);
    if (index === -1) {
      // this.filters.push({value: this.searchValue, key , type: 'search'});
    } else {
      this.filters[index] = { value: '', key, type: 'search' };
      this.search();
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    // need to find data type
    if (this.gridConfigs != null) {
      this.gridConfigs.forEach((element) => {
        if (element['columnName'] === this.sortName) {
          this.sortType = element.columnType;
        }
      });
    }
    this.search();
  }

  filter(value, key, type): void {
    const index = this.filters.findIndex((x) => x.key === key);
    if (index === -1) {
      this.filters.push({ value, key, type });
    } else {
      this.filters[index] = { value, key, type };
    }
    // this.filters.
    /* this.filterKey = key;
    this.filterVal = value; */
    this.search();
  }

  search(): void {
    /** filter data **/
    this.rows = [...this.orirows];
    this.filters.forEach((element) => {
      if (element.type === 'in') {
        if (element.value.length !== 0) {
          this.rows = this.rows.filter((item) => {
            const index = element.value.findIndex(
              (e) => e === item[element.key]
            );
            return index !== -1;
          });
        }
      }
      if (element.type === 'search') {
        if (element.value !== '') {
          this.rows = this.rows.filter((item) => {
            if (item[element.key] == null) {
              return false;
            }
            if (element.columnType === 'date') {
              const mydate = new Date(element.value);
              if (item[element.key].getTime() === mydate.getTime()) {
                return true;
              }
              return false;
            } else {
              const index = item[element.key]
                .toLowerCase()
                .indexOf(element.value.toLowerCase());
              return index !== -1;
            }
          });
        }
      }
    });
    const filteredData = [...this.rows];
    /* const filterFunc = (item: { name: string; age: number; address: string }) =>
      (this.searchAddress ? item.address.indexOf(this.searchAddress) !== -1 : true) &&
      (this.listOfSearchName.length ? this.listOfSearchName.some(name => item.name.indexOf(name) !== -1) : true);
    const data = this.listOfData.filter(item => filterFunc(item)); */
    /** sort data **/

    if (this.sortName && this.sortValue) {
      this.rows = this.rows.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
      );
      this.rows = [...this.rows];
    } else {
      // this.rows = this.rows;
      if (filteredData != null) {
        this.rows = [...filteredData];
      }
    }
  }

  public getJSON(entityId): Observable<any> {
    let url = 'membersearchformgrid';
    if (entityId === '9808f856-f047-4843-bec8-277e414fc649') {
      url = 'membersearchtmformgrid';
    }
    if (entityId === '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
      url = 'membersearchhmformgrid';
    }
    if (entityId === 'c935affd-0e7c-4b2c-ae8b-1c9d0e53905b') {
      url = 'membersearchtenetformgrid';
    }
    if (entityId === 'd6a2ff7e-bba0-48fa-9396-a6b202631669') {
      url = 'membersearchdbsformgrid';
    }
    if (entityId === 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028') {
      url = 'membersearchanthemformgrid';
    }
    if (entityId === '31e56172-ac96-487c-a6b3-d5847954f3b2') {
      url = 'membersearchunumformgrid';
    }
    if (entityId === '3b7805a6-10fa-48b5-b0ee-ea5365258341') {
      url = 'membersearchessexformgrid';
    }
    if (entityId === 'ac586073-69d6-41bf-875c-eb754f750457') {
      url = 'membersearchutcformgrid';
    }
    if (entityId === 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b') {
      url = 'membersearchleebaformgrid';
    }
    return this.httpser.get('json?path=' + url, {
      urlParams: null,
      options: {
        isMock: false,
      },
    });
  }
  /*  trackMember(index: number, member: MemberListItemDTO): any {
     return member.participantId;
   } */

  generateRandNumber() {
    return Math.floor(Math.random() * 4) + 1;
  }
  /* onKey(key) {
    if (key.key === 'Enter') {
      this.loadMembers();
    }
  } */
  getMemberDefaultRoute(member: MemberListItemDTO) {
    return this.routeHelper.getMemberRoute({
      EntityId: this.selectedEntityClient.entityId,
      ClientId: member.clientId,
      MemberId: member.participantId,
      Segment: MEMBER_ROUTE_SEGMENT.dashboard,
      EntityClientId: member.entityClientId,
    });
  }

  selectedEntityClientChange(entityClient) {
    if (!entityClient) {
      // this.memberList = null;
      return;
    }
    this.setSelectedEntityClient(entityClient);
    if (
      this.selectedEntityClient.entityId ==
      'ac586073-69d6-41bf-875c-eb754f750457'
    ) {
      // Hide AddNew member for RTX
      this.isAddNewMemberAllowed = false;
    } else {
      this.isAddNewMemberAllowed = true;
    }
    // this.loadMembers();
  }

  getEntityClientDisplayText(entityClient: EntityIdClientIdDTO) {
    const n = entityClient.clientName
      ? entityClient.clientName.indexOf('&')
      : -1;
    return _.compact([
      entityClient.entityName,
      entityClient.entityClientName,
      entityClient.clientCode,
      entityClient.entityClientNumber,
    ]).join(', ');
  }

  private subscribeSelectionState() {
    this.store
      .select(getSelectionState)
      .pipe(untilComponentDestroyed(this))
      .subscribe((x) => {
        this.selectionState = x;
        if (
          x != null &&
          x.entity != null &&
          this.currentEntityId !== x.entity.id
        ) {
          this.currentEntityId = x.entity.id;
          this.getJSON(x.entity != null ? x.entity.id : null)
            .pipe(untilComponentDestroyed(this))
            .subscribe((data1) => {
              const json = JSON.parse((<any>data1.jsonText) as string);
              if (data1 === null) {
                this.formConfigs = [];
              } else {
                const json = JSON.parse((<any>data1.jsonText) as string);
                this.formConfigs = json.FormConfig;
                this.saveButton = json.saveButton;
                this.gridConfigs = json.GridConfig;
                this.searchUrl = json.searchUrl;
                this.searchProp = json.searchProp;
              }
            });
        }
      });
  }

  private initializeDropdownnew() {
    const selectedEntity$ = this.selectionService.getEntity();
    selectedEntity$.pipe(take(1)).subscribe((data) => {
      if (data.id == '') {
        this.initializeDropdown();
      } else {
        const entityWithClients$ = this.entityService.getEntityClientList({
          entityId: data.id,
        });
        entityWithClients$.subscribe((clients) => {
          const array = clients.map((client) => {
            const dto = new EntityIdClientIdDTO();
            dto.entityId = data.id;
            dto.entityName = client.entityName;
            dto.clientId = client.clientId;
            dto.clientCode = client.clientCode;
            dto.clientName = client.clientName;
            dto.entityClientNumber = client.entityClientNumber;
            dto.entityClientId = client.entityClientId;
            dto.entityClientName = client.entityClientName;
            dto.id = _.compact([
              dto.entityId,
              dto.clientId,
              dto.entityClientNumber,
            ]).join('|');
            return dto;
          });
          const entityDto = new EntityIdClientIdDTO();
          entityDto.entityId = data.id;
          entityDto.entityName = array[0].entityName;
          entityDto.id = [entityDto.entityId].join('|');
          array.unshift(entityDto);
          this.entityListWithClients = array;
          this.selectedEntityClient = this.entityListWithClients.sort()[0];
        });
      }
    });
  }

  private initializeDropdown() {
    const entityListWithClients$ =
      this.entityService.getEntityListWithClients();
    const selectedEntityClients$ =
      this.selectionService.getSelectedEntityIdClientId();
    const selectedEntity$ = this.selectionService.getEntity();
    combineLatest(
      entityListWithClients$,
      selectedEntityClients$,
      selectedEntity$
    )
      .pipe(take(1))
      .pipe(untilComponentDestroyed(this))
      .subscribe((all: any) => {
        const entityListWithClients = all[0];
        const emptyitem: EntityIdClientIdDTO = {
          entityId: '',
          entityName: '',
          id: '',
        };
        entityListWithClients.unshift(emptyitem);
        const selectedEntityClient = all[1];
        const selectedEntity = all[2];
        this.entityListWithClients = entityListWithClients;
        if (selectedEntity != null && selectedEntity.id !== '') {
          const filtered = _.filter(
            entityListWithClients,
            (x) => x.entityId === selectedEntity.id
          );
          this.entityListWithClients = filtered;
        } else {
          if (this.entityList != null) {
            const arr = this.entityList.map((x) => x.entityId);
            const filtered = _.filter(this.entityListWithClients, (x) =>
              arr.includes(x.entityId)
            );
            this.entityListWithClients = filtered;
          }
        }
        this.selectDefaultDropdownItem(selectedEntityClient);
        //  this.loadMembers();
      });
  }

  private selectDefaultDropdownItem(
    selectedEntityClient: EntityIdClientIdDTO | null
  ) {
    this.selectedEntityClient = this.entityListWithClients.sort()[0];
  }

  private getMatchingDropdownItems(
    selectedEntityClient: EntityIdClientIdDTO | null
  ) {
    if (!selectedEntityClient || !selectedEntityClient.entityId) {
      return null;
    }
    let filtered = _.filter(
      this.entityListWithClients,
      (x) => x.entityId === selectedEntityClient.entityId
    );
    if (!selectedEntityClient.clientId) {
      return filtered;
    }
    filtered = _.filter(
      filtered,
      (x) => x.clientId === selectedEntityClient.clientId
    );
    if (!selectedEntityClient.entityClientNumber) {
      return filtered;
    }
    return _.filter(
      filtered,
      (x) => x.entityClientNumber === selectedEntityClient.entityClientNumber
    );
  }

  addMember() {
    let formid;
    if (this.selectedEntityClient.entityClientId == null) {
      this.notification.blank('Must select a client', ' ');
      return;
    }
    switch (this.selectedEntityClient.entityId) {
      case '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d':
        formid = 'addmemberhm';
        break;
      case '68bc62e6-dd96-4428-91e8-efe285bd23a0':
        formid = 'addmemberapw';
        break;
      case 'd6a2ff7e-bba0-48fa-9396-a6b202631669':
        formid = 'addmembercobra';
        break;
      case '9808f856-f047-4843-bec8-277e414fc649':
        formid = 'addmembertexas';
        break;
      case '3b7805a6-10fa-48b5-b0ee-ea5365258341':
        formid = 'addmemberessex';
        break;
      case 'c935affd-0e7c-4b2c-ae8b-1c9d0e53905b':
        formid = 'addmembertenet';
        break;
      case 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b':
        formid = 'addmemberleeba';
        break;
      case '9256dc20-03ae-469f-a45f-f66c5122f9ca':
        formid = 'addmemberfirefighters';
        break;
      case 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028':
        formid = 'addmemberanthem';
        break;
      case 'ac586073-69d6-41bf-875c-eb754f750457':
        formid = 'addmemberrtx';
        break;
      default:
        formid = 'addmemberapw';
        break;
    }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: true,
        entityId: this.selectedEntityClient.entityId,
        clientId: this.selectedEntityClient.clientId,
        item: {
          EntityId: this.selectedEntityClient.entityId,
          ClientId: this.selectedEntityClient.clientId,
          EntityClientId: this.selectedEntityClient.entityClientId,
        },
        formid,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        if (
          !result.EntityId ||
          !result.EntityClientId ||
          !result.participantId
        ) {
          this.router.navigateByUrl('/main/user-dashboard');
        } else {
          const url =
            '/main/clients/' +
            result.EntityId +
            '/' +
            result.EntityClientId +
            '/member/' +
            result.participantId +
            '/dashboard';
          // this.router.navigate([url], { relativeTo: this.route });
          this.router.navigateByUrl(url);
        }
      } else {
        // this.router.navigateByUrl('/main/user-dashboard');
      }
    });
  }

  private getAddMemberRoute() {
    if (
      !this.selectionState ||
      !this.selectionState.entity ||
      !this.selectionState.client
    ) {
      return '';
    }
    const clientsRoute = this.routeHelper.getClientsRoute({
      EntityId: this.selectionState.entity.id,
      ClientId: this.selectionState.client.clientId,
    });
    return `${clientsRoute}/${CLIENT_ROUTE_SEGMENT.member}/dynamicform/addmember`;
  }

  private setSelectedEntityClient(entityClient: EntityIdClientIdDTO) {
    if (entityClient.entityId) {
      this.store.dispatch(
        new SetSelectedEntityByIdAction({ entityId: entityClient.entityId })
      );
    }
    if (entityClient.entityClientId) {
      this.store.dispatch(
        new SetSelectedClientByIdAction({
          entityClientId: entityClient.entityClientId,
        })
      );
    }
  }
}
