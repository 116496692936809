<div class="decimal"
     [ngClass]="icon ? 'decimal-icon' : ''">

  <div class="icon decimal__icon"
       *ngIf="icon">
    <span class="fa fa-{{icon}}"></span>
  </div>

  <input #input
         type="text"
         class="input form-control"
         [ngClass]="icon ? 'decimal__input-icon' : ''"
         (focus)="inputFocus($event.target.value)"
         (blur)="inputBlur($event.target.value)">
</div>