<div [formGroup]='coverageForm'>

        <ng-container *ngIf="coverages.length>0" >
            
            {{uiControlText}}<br />
                <select class="form-control  input-sm col-2" style="margin-bottom: 10px"
                        formControlName="coverage"  
                        (change)="onChange($event)">
                        <option></option>     
                  <option *ngFor="let opt of  coverages "
                          [value]="opt.benefitEntityPolicyDetailCoverageId">{{opt.benefitEntityPolicyDetailCoverageName}}</option>
                </select>
                <app-member-eisdetail  [eisDetails]='eisDetails' (changeValue)="onChange($event)"
            (formReady)="formInitialized('eisDetail', $event)"
            >

            </app-member-eisdetail><br />
            </ng-container>


</div>