import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import InputMask from 'inputmask';

export const SSN_INPUT_MASK = '999999999';
const SSN_DASH_REGEX = /^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/;
const SSN_REGEX = /^\d{9}$/;
const DASH = '-';

@Injectable({
  providedIn: 'root'
})
export class SsnFormatterService {
  format(ssn: string): string {
    if (!this.isValid(ssn)) {
      return '';
    }

    // verify if its already formatted
    if (_.includes(ssn, DASH)) {
      return ssn;
    }

    // format in ###-##-####
    return _.map(ssn, (char, index) => {
      if (index === 3 || index === 5) {
        return `-${char}`;
      }
      return char;
    }).join('');
  }

  parse(value: string): string {
    return this.isValid(value)
      ? InputMask.unmask(value, { mask: SSN_INPUT_MASK })
      : '';
  }

  isValid(ssn: string): boolean {
    return ssn && _.includes(ssn, DASH)
      ? SSN_DASH_REGEX.test(ssn)
      : SSN_REGEX.test(ssn);
  }
}
