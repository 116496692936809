<div class="row no-gutters mt-2">

  <div class="card-wraper col-md-3 col-sm-6 col-xs-12 p-2" *ngFor="let item of mockData">
    
      <!-- <div *ngFor="let keyValuePair of item | value">
        <h2>{{ keyValuePair.key }}</h2>
        <div *ngFor="let obj of keyValuePair.value">
          <div *ngFor="let prop of obj | value">
            <p>{{ prop.key }}: {{ prop.value }}</p>
          </div>
        </div>
      </div> -->

    <div class="card-body">
      <div class="label">{{item.title}} 
         <i class="{{item.icon}}" aria-hidden="true"></i> 
      </div>
      <div class="p-1">
        <div class="main-data" *ngFor="let data of item.mainData | keyvalue">
          <div>
            <span class="key">{{data.key}}: </span>
            <span class="value">{{data.value}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>