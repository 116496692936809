import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { GetMemberUrlAction } from '@store/main/member/member.actions';
import { getMemberState, isMemberStateMutating } from '@store/main/member/member.selector';
import { MemberState } from '@store/main/member/member.state';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { MatDialog } from '@angular/material/dialog';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';

@Component({
  selector: 'app-plan-beneficiary',
  templateUrl: './beneficiary.component.html',
})

export class DynamicPlanBeneficiaryComponent extends BaseComponent implements OnInit {
  EntityId: string;
  ClientId: string;
  MemberId: string;
  memberState?: MemberState;
  rows;
  total: number;
  @Input()
  set entityId(entityId) {
    if (entityId != null) {
      this.EntityId = entityId;
    }
  }

  @Input()
  set clientId(clientId) {
    if (clientId != null) {
      this.ClientId = clientId;
    }
  }

  @Input()
  set memberId(memberId) {
    if (memberId != null) {
      this.MemberId = memberId;
    }
  }

  @Input()
  beneficiaries;
  @Output() changeBen = new EventEmitter<any>();
  formatterPercent = value => `${value} %`;
  parserPercent = value => value.replace(' %', '');

  constructor(public changeDetectorRef: ChangeDetectorRef, public dialog: MatDialog, private route: ActivatedRoute, private routeHelper: RouteHelperService, protected clientService: ClientService, private store: Store<AppState>, protected notification: NzNotificationService) {
    super();
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.ClientId = this.routeHelper.getClientId(this.route);
    this.MemberId = this.routeHelper.getMemberId(this.route);
  }

  ngOnInit() {
    this.store.dispatch(new GetMemberUrlAction({
      Url: 'Participant/:EntityId/:ParticipantId/BeneficiaryLists', Prop: 'beneficiaries',
      EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId
    }));
    this.subscribeMemberState();
  }

  private subscribeMemberState() {
    this.store
      .select(getMemberState)
      .pipe(untilComponentDestroyed(this))
      .subscribe(x => this.memberStateChange(x));
  }

  private memberStateChange(state: MemberState) {
    if (!isMemberStateMutating(state)) {
      this.memberState = state;
      if (this.memberState && this.memberState.member) {
        if (this.memberState.member) {
          if (this.memberState.member['beneficiaries'] != null) {
            const srows = this.memberState.member['beneficiaries']['beneficiaryDTO'];
            this.rows = [];
            srows.forEach(element => {
              // const obj = Object.assign({}, element);
              const ce = { ...element };
              this.rows.push(ce);
            });
            this.rows = this.applyPercentage(this.rows);
            this.changeDetectorRef.detectChanges();
          }
        }
      }
    }
  }

  percentChange(item) {
    const obj = {
      beneficiaryId: item.beneficiaryId,
      percentage: item.percentage,
      beneficiaryTypeId: item.beneficiaryTypeId,
    };
    if (this.beneficiaries == null) {
      this.beneficiaries = [];
    }
    if (this.beneficiaries) {
      const found = this.beneficiaries.find(function (element) {
        return element.beneficiaryId === item.beneficiaryId;
      });
      if (found) {
        found.percentage = item.percentage;
        found.beneficiaryTypeId = item.beneficiaryTypeId;
      } else {
        this.beneficiaries.push(obj);
      }
    }
    this.calcTotal();
    this.changeBen.emit();
  }

  calcTotal() {
    this.total = 0;
    this.beneficiaries.forEach(element => {
      this.total = this.total + element.percentage;

    });
  }

  applyPercentage(rows: any): any {
    const ret: any = [];
    rows.forEach(element => {
      if (this.beneficiaries) {
        const found = this.beneficiaries.find(function (elementf) {
          return elementf.beneficiaryId === element.beneficiaryId;
        });
        if (found) {
          element.percentage = found.percentage;
          element.beneficiaryTypeId = found.beneficiaryTypeId.toUpperCase();
        } else {
          element.percentage = '';
          element.beneficiaryTypeId = '';
        }
      } else {
        element.percentage = '';
        element.beneficiaryTypeId = '';
      }
      ret.push(element);
    });
    return ret;
  }

  addNew() {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: true,
        entityId: this.EntityId,
        clientId: this.ClientId,
        participantId: this.MemberId,
        item: {},
        formid: 'beneficiaries'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.loadMemberData();
      // this.store.dispatch(new GetMemberUrlAction({
      //   Url: 'Participant/:EntityId/:ParticipantId/BeneficiaryLists', Prop: 'beneficiaries',
      //   EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId
      // }));
    });
  }

  edit(item) {
    let dialogRef;
    dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: false,
        entityId: this.routeHelper.getEntityId(this.route),
        clientId: this.routeHelper.getClientId(this.route),
        participantId: this.routeHelper.getMemberId(this.route),
        item: item,
        formid: 'beneficiaries'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.loadMemberData();
      this.store.dispatch(new GetMemberUrlAction({
        Url: 'Participant/:EntityId/:ParticipantId/BeneficiaryLists', Prop: 'beneficiaries',
        EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId
      }));
    });
  }

  onDelete(item) {
    item.entityId = this.EntityId;
    item.participantId = this.MemberId;
    const url = 'Beneficiary/Delete';
    const observ$ = this.clientService.postWithUrl({ url, dto: item }).pipe(untilComponentDestroyed(this));
    observ$.subscribe((res) => {
      this.store.dispatch(new GetMemberUrlAction({
        Url: 'Participant/:EntityId/:ParticipantId/BeneficiaryLists', Prop: 'beneficiaries',
        EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId
      }));
      this.notification.blank('Deleted  successfully', ' ');
    }, error => {
        if (error != null && error.error.length > 0) {
          this.notification.blank(error.error[0], ' ');
        } else {
          this.notification.blank(error, ' ');
        }
      }
    );
  }
}
