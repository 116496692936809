import * as faker from 'faker';
import * as _ from 'lodash';
import {
  MockRequestHandler,
  MockRequestHandlerParams
} from '@mock/mock-data/shared/mock-handler.interface';
import { MaritalStatusDTO } from '@models/shared/marital-status.dto';

export class MaritalStatusLookupMockHandler extends MockRequestHandler<
  MaritalStatusDTO[]
> {
  handleRequest(params: MockRequestHandlerParams): MaritalStatusDTO[] {
    return [
      {
        id: faker.random.uuid(),
        shortCode: 'S',
        description: 'Single'
      },
      {
        id: faker.random.uuid(),
        shortCode: 'M',
        description: 'Married'
      },
      {
        id: faker.random.uuid(),
        shortCode: 'D',
        description: 'Divorced'
      },
      {
        id: faker.random.uuid(),
        shortCode: 'U',
        description: 'Unknown'
      }
    ];
  }
}
