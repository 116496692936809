<div class="main">
  <app-selected-client-header-share></app-selected-client-header-share>
  <h3>Benefit Management</h3>
  <!--     <div class="row col-12" style="height:50px"> <button nz-button nzType="default"  (click)="onSearch()"><i nz-icon type="search"></i>Search</button></div>
 -->
  <button class="add-btn" (click)=onAdd()>Add</button><br />
  <nz-table [nzPageSize]='50' #dynamicTable [nzScroll]="fixHeader ? { y: '240px' } : null" [nzData]="listOfData"
    [nzBordered]="bordered" [nzSimple]="simple" [nzLoading]="loading" [nzShowSizeChanger]="sizeChanger"
    [nzFrontPagination]="pagination" [nzShowPagination]="false" [nzSize]="size">
    <thead>
      <tr *ngIf="header">
        <th nzWidth="50px" nzShowExpand *ngIf="expandable"></th>
        <th>BenefitEntityDescription</th>
        <th>VendorName</th>
        <th nzWidth="100px"><b>Action</b></th>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-data [ngForOf]="dynamicTable.data">
        <tr>
          <td nzShowExpand *ngIf="expandable" [(nzExpand)]="mapOfExpandData[data.benefitEntityId]"
            (nzExpandChange)="expand(data,$event)"></td>
          <td>{{ data.benefitEntityDescription }}</td>
          <td>{{ data.vendorName }}</td>
          <td>
            <!-- <a class="PatchFont" app-popconfirm abcTitle="Are you sure you want to delete this?"
              (onOK)="onDelete(data)">Delete</a> -->
              <a class="edit-btn" (click)=onEdit(data)>Edit</a>
          </td>
        </tr>
        <tr [nzExpand]="mapOfExpandData[data.benefitEntityId] && expandable"
          *ngIf='mapOfExpandData[data.benefitEntityId]'>
          <td></td>
          <td [attr.colspan]="2" style="width: 100% !important;">
            <app-benefit-policy [entityId]='EntityId' [entityclientId]='EntityClientId'
              [benefitEntityId]='data.benefitEntityId'>
            </app-benefit-policy>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </nz-table>

</div>
<div class="row no-gutters pull-right cancel-btn-box mr-4">
  <div class="col form-button-bar ">
    <button class="add-btn" (click)="onCancel()">Cancel</button>
  </div>
</div>