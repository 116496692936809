import { Component, OnInit,Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { AppState } from '@store/app.state';
import { GetClientListAction } from '@store/main/client-management/client-list/client-list.actions';
import { BaseComponent } from '@shared/components/base.component';
import { RouteHelperService, ROUTE_PARAM_NAME } from '@shared/route/route-helper.service';
import { SetSelectedEntityByIdAction } from '@store/selection/selection.action';
import { ClientListState } from '@store/main/client-management/client-list/client-list.state';
import { ClientHeaderDTO } from '@models/client/genericclient.dto';
import { SelectionService } from '@store/selection/selection.service';
import { getClientListState } from '@store/main/client-management/client-management.selector';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientService } from '@services/client/client.service';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})

export class ClientListComponent extends BaseComponent implements OnInit {
   @Input()
   set entityIdLink(entityIdLink){
    this._entityIdLink=entityIdLink;
    if(this._entityIdLink!=null){
      const url = 'Entity/EntityLink?entityClientId='+this._entityIdLink;
      const observ$ = this.clientService.getWithUrl(url, { });
      observ$.subscribe( data => {
        this.clientListState = {
         clients:data,
          loading: false,
          entityId:this.entityId
      
          };
          // this.store.dispatch(new SetSelectedEntityByIdAction({ entityId }));
          // this.loadClients();
         });
        /*  this.subscribeClientListState();
    this.subscribeRouteParam(); */
    } 
   }
   _entityIdLink
  readonly ROUTE_PARAM_NAME = ROUTE_PARAM_NAME
  clientListState: ClientListState
  filterText: string
  addNew = true
  memberLookupRoute: string
  entityId
  path;
  path1
  path2
  constructor(
    private store: Store<AppState>,
    private clientService:ClientService,
    private route: ActivatedRoute, public dialog: MatDialog,
    private routeHelper: RouteHelperService,
    private router: Router
  ) {
    super();
    this.loadClients();
  }
  getRouterLink(client) {
    var path= '/main/clients/'+client.entityId+'/'+client.entityClientId;
    return path;
  }

  addClient() {
    var formid='addclient';
    if(this.entityId=='d6a2ff7e-bba0-48fa-9396-a6b202631669'){
      formid='addclientcobra';
    }
    if(this.entityId=='4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d'){
      formid='addclienthm';
    }
    if(this.entityId=='9808f856-f047-4843-bec8-277e414fc649'){
      formid='addclienttexas';
    }
    if(this.entityId=='9256dc20-03ae-469f-a45f-f66c5122f9ca'){
      formid='addclientfirefighters';
    }
    if(this.entityId=='a11ee4de-9b44-4bcf-a01e-04e1a2fc6028'){
      formid='addclientanthem';
    }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: true,
        item: { entityId: this.entityId },
        prePath: '',
        formid: formid
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result!=null){
        const url='./'+result.entityClientId;
        this.router.navigate([url], { relativeTo: this.route });
      }

    });
  }
  
  ngOnInit() {
    const entityId = this.routeHelper.getEntityId(this.route);
    if(this._entityIdLink!=null){
      // const url = 'Entity/EntityLink?entityClientId='+this._entityIdLink;
      // const observ$ = this.clientService.getWithUrl(url, { });
      // observ$.subscribe((data) => {
      //   this.clientListState = {
      //    clients:data,
      //     loading: false,
      //     entityId:this.entityId
      
      //     };
      //     // this.store.dispatch(new SetSelectedEntityByIdAction({ entityId }));
      //     // this.loadClients();
      //    });
        /*  this.subscribeClientListState();
    this.subscribeRouteParam(); */
    
    } else{
        this.entityId = this.routeHelper.getEntityId(this.route);
    
    
    this.memberLookupRoute = this.routeHelper.getMemberLookupRoute();
    this.subscribeClientListState();
    this.subscribeRouteParam();
    
    const entityList=['ac586073-69d6-41bf-875c-eb754f750457','3b7805a6-10fa-48b5-b0ee-ea5365258341','31e56172-ac96-487c-a6b3-d5847954f3b2','68bc62e6-dd96-4428-91e8-efe285bd23a0']
    const found=entityList.find(x=>x==this.entityId);
    if (found!=null){
      this.addNew=false;
    }
    this.store.dispatch(new SetSelectedEntityByIdAction({ entityId }));
    const tt = '/main/clients/' + this.entityId + '/reports';
    this.path = [tt];
    const tt1 = '/main/clients/' + this.entityId + '/commisionscheduleentity';
    this.path1 = [tt1];
    const tt2 = '/main/clients/' + this.entityId + '/agent/hmagent';
    this.path2 = [tt2];
    }
  }

  trackClient(index: number, client: ClientHeaderDTO): any {
    return client.clientId;
  }

  private subscribeClientListState() {
    this.store
      .select(getClientListState)
      .pipe(untilComponentDestroyed(this))
      .subscribe(state => {
        this.clientListState = state;
      });
  }

  private subscribeRouteParam() {
    this.route.paramMap
      .pipe(untilComponentDestroyed(this))
      .subscribe(x => this.loadClients());
  }

  private loadClients() {
    this.entityId = this.routeHelper.getEntityId(this.route);
    this.store.dispatch(new GetClientListAction({ entityId: this.entityId }));
  }
}
