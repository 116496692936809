<app-selected-member-header-share *ngIf='isInner===false'
  [memberState]="memberState"></app-selected-member-header-share>

<div style="padding:10px 0px 10px 0px">
  <h2 class="member-function-header">{{title}}</h2>
</div>
<div class="row no-gutters">
  <div class="col-10">
    <form>
      <div class="row no-gutters">
        <div class="col-12">
          <app-form-group-wrapper>
            <div class="col-12 no-padding" *ngIf='addNewButton=="true"'>
              <div style="text-align: right;">
                <button mat-flat-button color="primary" color="primary" href="javascript:void(0)"
                  (click)="addNew()">Add New</button>
              </div>
            </div>
            <br>
            <nz-table #basicTable [nzData]="rows" [nzPageSize]='pageSize' nzSize="small" class="table table-hover"
              style="margin-top: 10px" *ngIf='gridConfigs!=null'>
              <thead (nzSortChange)="sort($event)" nzSingleSort class="thead-dark">
                <tr>
                  <ng-container *ngFor="let access of gridConfigs">
                    <th nowrap="" nzShowSort [nzSortKey]="access.columnName"
                      (nzFilterChange)="filter($event,access.columnName,access.filterType )"
                      [nzFilterMultiple]="'false'" nzShowFilter [nzFilters]="access.filterList"
                      *ngIf="access.sort!=null && access.filter!=null && access.filterType==='in'">
                      {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                        ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                    </th>
                    <th nowrap="" nzShowSort [nzSortKey]="access.columnName" nzCustomFilter
                      *ngIf="access.sort!=null && access.filter!=null && access.filterType==='search'">
                      {{access?.columnLabel}}&nbsp;
                      <nz-filter-trigger [nzVisible]="visible" [nzActive]="'true'" [nzDropdownMenu]="menu">
                        <i nz-icon [nzType]="'search'"></i>
                      </nz-filter-trigger>
                      <nz-dropdown-menu #menu="nzDropdownMenu">
                        <div class="ant-table-filter-dropdown">
                          <div class="search-box">
                            <input type="text" nz-input placeholder={{access.columnLabel}} [(ngModel)]="searchValue" />
                            <button nz-button nzSize="small" nzType="primary"
                              (click)="searchCust(access.columnName,access)" class="search-button">
                              Search
                            </button>
                            <button nz-button nzSize="small" (click)="resetCust(access.columnName)">Reset</button>
                          </div>
                        </div>
                      </nz-dropdown-menu>
                    </th>
                    <th nowrap="" (nzFilterChange)="filter($event,access.columnName,access.filterType )"
                      [nzFilterMultiple]="'false'" nzShowFilter [nzFilters]="access.filterList"
                      *ngIf="access.sort==null && access.filter!=null  && access.filterType==='in'">
                      {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                        ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>

                    </th>
                    <th nowrap="" nzShowSort [nzSortKey]="access.columnName"
                      *ngIf="access.sort!=null && access.filter==null">
                      {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                        ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>

                    </th>
                    <th nowrap="" *ngIf="access.sort==null &&  access.filter==null">
                      {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                        ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>

                    </th>
                  </ng-container>

                  <th *ngIf="actionButton!=='none'" style="width:10%"><b>Action</b></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of basicTable.data">
                  <tr class="ng-scope">
                    <ng-container *ngFor="let access of gridConfigs">
                      <ng-container [ngSwitch]="access.columnType">
                        <td class="ng-binding" *ngSwitchCase="'text'">{{item[access.columnName]}}</td>
                        <td class="ng-binding" *ngSwitchCase="'date'">{{item[access.columnName]| date:'M/d/yy'}}</td>
                        <td class="ng-binding" *ngSwitchCase="'datetime'">{{item[access.columnName]| date:'M/d/yy, h:mm
                          a'}}</td>
                        <td class="ng-binding" *ngSwitchCase="'phone'">{{ item[access.columnName] | slice:0:3 }}-{{
                          item[access.columnName] | slice:3:6 }}-{{ item[access.columnName] | slice:6:10 }}</td>
                      </ng-container>
                    </ng-container>
                    <td *ngIf="actionButton==='selection'" class="default-font-Color"><span style="cursor: pointer"
                        (click)="selection(item)" class="btnEdit"><i class="fa fa-eye"
                          aria-hidden="true"></i>&nbsp;View</span></td>
                    <td *ngIf="actionButton==='View'" class="default-font-Color"><span style="cursor: pointer"
                        (click)="action(item)" class="btnEdit"><i class="fa fa-eye"
                          aria-hidden="true"></i>&nbsp;View</span></td>
                    <td *ngIf="actionButton==='true'" class="default-font-Color"><span style="cursor: pointer"
                        (click)="action(item)" class="btnEdit"><i class="fa fa-pencil-square-o "
                          aria-hidden="true"></i>&nbsp;Edit</span></td>
                    <td *ngIf="actionButton!=='true' && actionButton!=='none' && actionButton!=='View'"
                      class="default-font-Color"><span style="cursor: pointer" (click)="action(item)" class="btnEdit"><i
                          class="fa fa-pencil-square-o " aria-hidden="true"></i>&nbsp;{{actionButton}}</span></td>

                  </tr>
                </ng-container>
              </tbody>
            </nz-table>


          </app-form-group-wrapper>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col form-button-bar">
          <button mat-flat-button color="primary" (click)="onCancel()">Cancel</button>
        </div>
      </div>
    </form>
  </div>

</div>