import { createSelector } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { AuthActionTypes, AllAuthActions } from '@store/auth/auth.actions';
import { AuthState } from '@store/auth/auth.state';

export const initialState: AuthState = {
  loading: false,
  success: null,
  error: null
};

export function authReducer(
  state: AuthState = initialState,
  action: AllAuthActions
): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN: {
      return { ...state, loading: true, success: null, error: null };
    }
    case AuthActionTypes.LOGIN_SUCCESS: {
      return { ...state, loading: false, success: action.payload.result };
    }
    case AuthActionTypes.LOGIN_FAIL: {
      return { ...state, loading: false, error: action.payload.error };
    }
    case AuthActionTypes.LOGOUT: {
      return { ...state, loading: false, success: null, error: null };
    }
    default:
      return state;
  }
}

export const getAuthState = createSelector(
  (state: AppState) => state,
  (state: AppState) => state.auth
);
