<div class="w-100">
  <img style="width: 100%; position: fixed; object-fit: fit;" src="{{bgImage}}" alt="">
</div>

<div class="row container loginscreen" style="padding: 0px !important; margin: 0 auto;">
  <div class="col-xl-2 col-lg-2 col-md-2"></div>
  <!-- <div id="BlockLogin" class="ProductBackground" style="width: 100%; height: 5000px; position: fixed; z-index: 10000; top: 0px; left: 0px"></div> -->
  <div style="padding: 10% 0px 0px 0px; margin: 0px;"
    class="row col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 LoginPanel">
    <div class="row" style="margin: 0px; background: rgba(255, 255, 255, 0.9) none repeat scroll 0% 0%;">

      <div class="row no-gutters" style="padding: 20px;">
        <form class="col-lg-7 col-md-7 col-sm-7 col-xs-12" [formGroup]="checkParticipantForm" (ngSubmit)="onSubmit()"
          autocomplete="off">
          <div style="border-radius: 6px; margin: 0 25px 0 0; background: rgba(255, 255, 255, 0.95)">
            <div style="padding: 3% 3% 3% 5%; margin: 0px auto" class="row">
              <div><span style="font-family: Calibri; font-size: 22px; margin-bottom: 40px;">Sign up for an
                  account</span>
                <p style="text-align: left;font-size:14px; color: rgb(78, 78, 78)">
                  Please fill in the required fields below in order to create an account.
                </p>
              </div>
              <br />

              <div class="row no-gutters" style="margin: 0px 0px 0px -10px; padding: 10px;">

                <div class="col-12"><span style="font-family: Calibri; font-size: 14px">Last Name</span></div>
                <input maxlength="30" formControlName="lastName" type="text" class="form-control input-sm RegisterClass"
                  required>
                <div class="validation">
                  <span
                    *ngIf="checkParticipantForm.get('lastName').touched && checkParticipantForm.get('lastName').invalid">Please
                    enter Last Name</span>
                </div>

                <!-- <div class="col-12"><span style="font-family: Calibri; font-size: 14px">Last Four SSN Didgits</span>
                </div>
                <input maxlength="10" formControlName="ssnDigits" type="text"
                  class="form-control input-sm RegisterClass" required>
                <div class="validation">
                  <span
                    *ngIf="checkParticipantForm.get('ssnDigits').touched && checkParticipantForm.get('ssnDigits').invalid">Please
                    enter Last Four Didgits of SSN</span>
                </div> -->

                <div class="col-12"><span style="font-family: Calibri; font-size: 14px">Cert ID</span></div>
                <input maxlength="30" formControlName="MemberId" type="text" class="form-control input-sm RegisterClass"
                  required>
                <div class="validation" >
                  <span
                    *ngIf="checkParticipantForm.get('MemberId').touched && checkParticipantForm.get('MemberId').invalid">Please
                    enter Cert ID</span>
                </div>

                <div class="col-12" style="padding-top: 5px"><span style="font-family: Calibri; font-size: 14px">Date Of
                    Birth</span></div>
                <input type="date" formControlName="DateOfBirth"
                  class="form-control input-sm RegisterClass" id="DateOfBirth" required>
                <div class="validation">
                  <span
                    *ngIf="checkParticipantForm.get('DateOfBirth').touched && checkParticipantForm.get('DateOfBirth').invalid">Please
                    enter Date Of Birth (MM/DD/YYYY)</span>
                </div>

                <div class="col-12" style="margin: 0px auto; padding: 0px"><span
                    style="font-family: Calibri; font-size: 14px">ZIP code</span></div>
                <input maxlength="12" formControlName="zipCode" type="text" class="form-control input-sm RegisterClass"
                  required>
                <div class="validation">
                  <span
                    *ngIf="checkParticipantForm.get('zipCode').touched && checkParticipantForm.get('zipCode').invalid">Please
                    enter valid ZIP code</span>
                </div>
              </div>


                <div style="padding: 10px 0">
                  <button routerLink='/' mat-flat-button type="button" [style.background-color]="primaryColor"
                    style="border: 1px solid white !important; color: #fff; float: left; margin: 0 15px 15px 0; width: 100%; width: 150px; border: none; font-size: 18px;">
                    Cancel
                  </button>
                  <button mat-flat-button type="submit" [style.background-color]="primaryColor"
                    style="border: 1px solid white !important;color: #fff; float: left; margin: 0 15px 15px 0; width: 100%; width: 150px; border: none; font-size: 18px;">
                    Next
                  </button>
                </div>


            </div>
          </div>

        </form>

        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12" style="height: 100%;">
          <div id="divIntroduction" style="padding-right: 10px; padding-left: 10px; background-size: cover;">
            <div style="background-color: transparent;">

              <div style="text-align: center;">
                <div>
                  <div style="padding-top: 10px;">
                    <h3 ng-if="WelcomeText == null || WelcomeText == ''" [style.color]="primaryColor"
                      style="font-family: Calibri; font-size: 28px; font-weight: bold; text-align: left">
                      {{welcomeText}}</h3>
                    <h3 ng-if="WelcomeText != null && WelcomeText != ''"
                      style="font-family: Calibri; font-size: 28px; font-weight: bold; text-align: left"
                      dynamic="WelcomeText"></h3>
                    <br />
                    <br />
                  </div>
                </div>

                <p style="text-align: left; font-size:16px;">
                  <br>
                  <br>
                  Questions on enrollment or program offerings?
                  <br>
                  <b style="font-family: Calibri; font-size: 18px">Call: {{phoneNum}}</b>
                </p>

                <!-- <a class="FontColor" style="float: right; cursor: pointer" href="http://www.alliant.com/Legal-Notices/Pages/Terms-And-Conditions.aspx" target="_blank"><u>Terms & Conditions</u></a>-->
              </div>
            </div>
          </div>
        </div>

      </div>

      <div id="SpeciallinksDiv" style="position: absolute; bottom: 10px; right: 10px;">
        <a class="FontColor" style="cursor: pointer; font-family: Calibri; font-size: 14px"
          href="https://cdn.abcsys.com/Documents/Legal/terms.html" target="_blank"><u>Terms of Use
          </u></a><span class="FontColor">&nbsp; | &nbsp;</span>
        <a class="FontColor" style="cursor: pointer; font-family: Calibri; font-size: 14px"
          href="https://cdn.abcsys.com/Documents/Legal/privacy.html" target="_blank"><u>Privacy
            Policy</u></a><span class="FontColor"> &nbsp; </span>
      </div>
    </div>
  </div>
</div>