import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  entities =[]

  constructor() { }

  ngOnInit(): void {
    this.entities = [
      {
       icon: './assets/img/AH_icon.png',
       title: 'Auto and Home',
       vendors: 'Travelers, Farmers',
       numEnrollments: '12312',
       numDependents: '10000',
       lastBillAmt: '321398',
       isEvergreen: 'Yes',
       isABC: 'No'
      },
      {
        icon: './assets/img/ACC_icon.png',
        title: 'Accident',
        vendors: 'The Hartfort',
        numEnrollments: '92392',
        numDependents: '5633',
        lastBillAmt: '10239',
        isEvergreen: 'No',
        isABC: 'Yes'
       },
       {
        icon: './assets/img/Legal_icon.png',
        title: 'Legal',
        vendors: 'MetLife',
        numEnrollments: '2316623',
        numDependents: '34461',
        lastBillAmt: '8291',
        isEvergreen: 'No',
        isABC: 'No'
       },
       {
        icon: './assets/img/CI_icon.png',
        title: 'Critical Illness',
        vendors: 'The Hartfort',
        numEnrollments: '20111',
        numDependents: '9072',
        lastBillAmt: '70119',
        isEvergreen: 'Yes',
        isABC: 'No'
       },
       {
        icon: './assets/img/Dental_icon.png',
        title: 'Dental Discount',
        vendors: 'Careington',
        numEnrollments: '15032',
        numDependents: '11583',
        lastBillAmt: '66590',
        isEvergreen: 'No',
        isABC: 'No'
       },
       {
        icon: './assets/img/Pet_icon.png',
        title: 'Pet Insurance',
        vendors: 'Met Life',
        numEnrollments: '80293',
        numDependents: '20009',
        lastBillAmt: '14040',
        isEvergreen: 'Yes',
        isABC: 'Yes'
       },
       {
        icon: './assets/img/IDT_icon.png',
        title: 'ID Theft Protection',
        vendors: 'LifeLock',
        numEnrollments: '52019',
        numDependents: '17998',
        lastBillAmt: '8990',
        isEvergreen: 'No',
        isABC: 'Yes'
       },
       {
        icon: './assets/img/HI_item.png',
        title: 'Hospital Indemnity',
        vendors: 'The Hartfort',
        numEnrollments: '12093',
        numDependents: '9112',
        lastBillAmt: '8100',
        isEvergreen: 'No',
        isABC: 'Yes'
       }
            
    ]
  }

}
