import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BaseComponent } from '@shared/components/base.component';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';

@Component({
  selector: 'app-billcreation',
  templateUrl: './bill-creation.component.html',

})
export class BillCreationComponent extends BaseComponent implements OnInit {
  billcreation = 'billcreation' 
  loading
  entities
  rows
  selectedEntity
  entityClients
  selectedEntityClient

  constructor(
    private clientService: ClientService, 
    private notification: NzNotificationService
    ) {
    super();
  }

  ngOnInit() {
    const url = 'Entity/Entities';
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe(data => {
      this.entities = data;
    });
  }

  fileChange(e) {
    var file: File = e.target.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      var data = (<any>e.target).result;

      let workbook = XLSX.read(data, {
        type: 'binary'
      });
      let json_object;
      workbook.SheetNames.forEach((sheetname) => {
        let XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname], {
          raw: false,
        });
        json_object = XL_row_object;//JSON.stringify(XL_row_object);  
      })
      this.rows = json_object;
    }
    myReader.onerror = function (ex) {
      console.log(ex);
    };
    myReader.readAsBinaryString(file);
  }

  selectedEntityChange(entityId) {

  }

  actionPopup($event) {

  }

  checkduplicate() {
    const newrows: any = [];
    this.rows.forEach(item => {
      const it = {
        ClientId: item.ClientId,
        SectionId: item.SectionId,
        SSN: item.SSN == null ? '' : item.SSN.trim(),
        Id: item.Id,
        BenefitCode: item.BenefitCode == null ? '' : item.BenefitCode.trim(),
        PlanCode: item.PlanCode,
        PlanSubCode: item.PlanSubCode == null ? '' : item.PlanSubCode.trim(),
        CovCode: item.CovCode,
        PackageCode: item.PackageCode,
        PayDate: item.PayDate,
        PayFromDate: item.PayFromDate,
        PayToDate: item.PayToDate,
      }
      newrows.push(JSON.stringify(it));
    });

    const tt = new Set(newrows);
    return new Set(newrows).size !== newrows.length;
  }

  onSubmit() {
    if (this.selectedEntity == null) {
      this.notification.blank('must select an client', ' ');
      return;
    }
    if (this.checkduplicate()) {
      this.notification.blank('there are duplicate records ', ' ');
      return;
    }
    const details: any = [];
    let error = false;
    this.rows.forEach(item => {
      if (item.PayFromDate == null || item.PayToDate == null || item.PayFromDate.trim() == '') {
        this.notification.blank('PayFromDate or PayToDate can not be empty ', ' ');
        error = true;
      } else {
        if (Date.parse(item.PayFromDate) > Date.parse(item.PayToDate)) {
          this.notification.blank('PayFromDate can not be greater than PayToDate  ', ' ');
          error = true;
        }
      }

      const obj = {
        ClientId: item.ClientId,
        SectionId: item.SectionId,
        SSN: item.SSN,
        Id: item.Id,
        BenefitCode: item.BenefitCode,
        PlanCode: item.PlanCode,
        PlanSubCode: item.PlanSubCode,
        CovCode: item.CovCode,
        PackageCode: item.PackageCode,
        PayDate: item.PayDate,
        PayFrequency: item.PayFrequency,
        BillAmount: item.BillAmount,
        AdminFee: item.AdminFee,
        ManualAmount: item.ManualAmount,
        RemitAmount: item.RemitAmount,
        WriteOffAmount: item.WriteOffAmount,
        OpenDate: item.OpenDate,
        CloseDate: item.CloseDate,
        PayFromDate: item.PayFromDate,
        PayToDate: item.PayToDate,
        DueDate: item.DueDate,
        IsBillWaiver: item.IsBillWaiver,
        CollectionMethod: item.CollectionMethod,
        IsHoldBill: item.IsHoldBill,
        DepositDate: item.DepositDate,
        RateCode: item.RateCode,
        Age: item.Age
      };
      details.push(obj);
    });
    if (error) {
      return;
    }
    const url = 'billcreation';
    const dto = {
      EntityId: this.selectedEntity,
      details
    };
    this.loading = true;
    const observ$ = this.clientService.postWithUrl({ url, dto }).pipe(untilComponentDestroyed(this));
    observ$.subscribe((res) => {
      this.notification.blank('Saved Successfully', ' ');
      this.loading = false;
    }, err => {
      this.notification.blank(err.error[0], ' ');
      this.loading = false;
    });
  }
}