import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PrintService} from './print.service';

@Component({
  selector: 'app-testprint',
  templateUrl: './testprint.component.html',

})
export class TestPrintComponent implements OnInit {
    params;
  invoiceDetails: Promise<any>[];

  constructor(route: ActivatedRoute,
              private printService: PrintService) {
    this.params = route.snapshot.params['params'];

  }

  ngOnInit() {
    // window.print();
    this.printService.onDataReady();
  }



}
