import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
// import { NgOnChangesFeature } from '@angular/core/src/render3';
import { MemberDTO, MemberProfileDTO } from '@models/shared/member.dto';
@Component({
  selector: 'app-member-plans-cartnew',
  templateUrl: './cartnew.component.html',
  styleUrls: ['./../dynamic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartNewComponent extends BaseComponent implements OnInit {
  @Input()
  memberState;
  @Input()
  entityId;
  @Input()
  benefitEntities;
  @Input()
  benefitValue;
  @Input()
  policies;
  @Output()
  OnChange: EventEmitter<any> = new EventEmitter();
  @Output()
  OnConfirm: EventEmitter<any> = new EventEmitter();
  @Output()
  OnActivate: EventEmitter<any> = new EventEmitter();
  showconfirm;
  ngOnInit() {
    this.showconfirm = true;
    /* if (this.entityId !== '68bc62e6-dd96-4428-91e8-efe285bd23a0') {
      this.showconfirm = true;
  } */
  }
  get member(): MemberDTO | undefined {
    if (this.memberState && this.memberState.member) {
      return this.memberState.member;
    }
    return;
  }
  get profile(): MemberProfileDTO | undefined {
    if (this.member && this.member.profile) {
      return this.member.profile;
    }
    return;
  }

  getPolicies(entityId) {
    const ret: string[] = [];
    for (const propertyentity in this.benefitValue) {
      if (propertyentity === entityId) {
        for (const property in this.benefitValue[propertyentity]) {
          if (this.benefitValue[propertyentity].hasOwnProperty(property)) {
            if (this.benefitValue[propertyentity][property].benefitEntityId === entityId) {
              ret.push(property);
            }
          }
        }
      }
    }

    return ret;
  }

  subCost(benefitId) {
    let sum = 0;
    if (this.benefitValue.hasOwnProperty(benefitId)) {
      for (const property in this.benefitValue[benefitId]) {
        if (this.benefitValue[benefitId].hasOwnProperty(property)) {
          const tiers = this.benefitValue[benefitId][property].tiers;
          if (tiers) {
            if (tiers.uiControlType != null) {
              for (const tierproperty in tiers) {
                if (tierproperty !== 'uiControlType') {
                  if (tiers[tierproperty] != null && tiers[tierproperty].selected === true) {
                    const rate = tiers[tierproperty].rate;
                    sum = sum + rate;
                  }
                }
              }
            } else {
              const tier = tiers.tier;
              const rate = tiers.rate;
              if (tier && rate) {
                sum = sum + rate;
              }
            }


          }
          const tiercoverage = this.benefitValue[benefitId][property].tiercoverage;
          if (tiercoverage) {
            if (tiercoverage.enrolled === true) {
              const rate = tiercoverage.rate;
              if (rate !== null) {
                sum = sum + rate;
              }
            }
            /* for (const propertycover in tiercoverage) {
              if (propertycover !== 'uiControlType') {

                const coverage = tiercoverage[propertycover];
                const rate = coverage.rate;
                if (rate !== '') {
                  sum = sum + rate;
                }
              }

          } */
          }
        }
      }
    }
    if (sum === 0) {
      return '';
    }
    return sum;
  }

  getSum() {
    let sum = 0;
    for (const propertyentity in this.benefitValue) {
      if (this.benefitValue.hasOwnProperty(propertyentity)) {
        for (const property in this.benefitValue[propertyentity]) {
          if (this.benefitValue[propertyentity].hasOwnProperty(property)) {
            const tiers = this.benefitValue[propertyentity][property].tiers;
            if (tiers) {
              if (tiers.uiControlType != null) {
                for (const tierproperty in tiers) {
                  if (tierproperty !== 'uiControlType') {
                    if (tiers[tierproperty] != null && tiers[tierproperty].selected === true) {
                      const rate = tiers[tierproperty].rate;
                      sum = sum + rate;
                    }
                  }
                }
              } else {
                const tier = tiers.tier;
                const rate = tiers.rate;
                if (tier && rate) {
                  sum = sum + rate;
                }
              }


            }
            const tiercoverage = this.benefitValue[propertyentity][property].tiercoverage;
            if (tiercoverage) {
              if (tiercoverage.enrolled === true) {
                const rate = tiercoverage.rate;
                if (rate !== null) {
                  sum = sum + rate;
                }
              }
              // tslint:disable-next-line:forin
              /* for (const propertycover in tiercoverage) {
                if (propertycover !== 'uiControlType') {
                  const coverage = tiercoverage[propertycover];
                  const rate = coverage.rate;
                  if (rate !== '') {
                    sum = sum + rate;
                  }
                }

            } */
            }
          }
        }
      }
    }
    return sum;
  }

  onEnrollment() {
    this.OnConfirm.emit();
  }
  
  onCobraActivate() {
    this.OnActivate.emit();
  }

}
