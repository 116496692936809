import { Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { HttpHelperService } from '@shared/api/http-helper.service';
import { LoggerService } from '@shared/logging/logger.service';
import { REQUEST_HEADERS } from '@shared/api/shared';

const REQUEST_HEADERS_TO_REMOVE = [
  REQUEST_HEADERS.HTTP_ERROR_INTERCEPTOR,
  REQUEST_HEADERS.CACHE,
  REQUEST_HEADERS.URL_TEMPLATE,
  REQUEST_HEADERS.HTTP_PARAMS,
  REQUEST_HEADERS.USE_MOCK,
  REQUEST_HEADERS.DUPLICATE_REQUEST
];

export class CleanupRequestInterceptor implements HttpInterceptor {
  private logger: LoggerService;
  private httpHelper: HttpHelperService;

  /*
  IMPORTANT: params must be injected using the "deps" property in the provider
  IMPORTANT: "src\app\shared\interceptors\providers\index.ts"
  */
  constructor(private injector: Injector) {
    this.logger = this.injector.get(LoggerService);
    this.httpHelper = this.injector.get(HttpHelperService);

    this.logger = this.logger.create(this);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.removeRequestHeaders(request);
    this.removeBodyProps(request);

    return next.handle(request);
  }

  private removeRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
    if (!this.shouldRemoveRequestHeaders(request)) {
      return request;
    }

    this.logger.debug(
      `'${request.url}' Remove request headers:  ${REQUEST_HEADERS_TO_REMOVE}`
    );

    _.each(REQUEST_HEADERS_TO_REMOVE, name => {
      if (!request.headers.has(name)) {
        return;
      }

      request = request.clone({
        headers: request.headers.delete(name)
      });
    });

    return request;
  }

  private shouldRemoveRequestHeaders(request: HttpRequest<any>) {
    // do not remove custom request headers if mock is enabled in the configuration
    // if (this.configService.isMockEnabled) {
    //   return false;
    // }

    // ** DO NOT REMOVE ANY REQUEST HEADERS IF MOCK IN ENABLED **
    return (
      this.httpHelper.getRequestHeader(
        request.headers,
        REQUEST_HEADERS.USE_MOCK
      ) !== true.toString()
    );
  }

  private removeBodyProps(request: HttpRequest<any>) {
    if (request.body) {
      this.removeKeys(request.body, ['$']);
    }
  }

  private removeKeys(value: any, keyPrefixes: string[], values?: any[]) {
    values = values || [];

    if (!value) {
      return;
    }

    if (_.includes(values, value)) {
      // circular object reference found
      this.logger.debug(value);
      return;
    }

    for (const key in value) {
      if (!value.hasOwnProperty(key)) {
        continue;
      }

      const hasPrefix = _.some(keyPrefixes, prefix =>
        _.startsWith(key, prefix)
      );

      if (hasPrefix) {
        delete value[key];
        continue;
      }

      if (typeof value[key] === 'object') {
        values.push(value);
        this.removeKeys(value[key], keyPrefixes, values);
      }
    }
  }
}
