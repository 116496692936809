<div class="col-12">
  <app-screen-content>
    <app-form-error [formGroup]="form" *ngIf="form!=null" [show]="submitted">
    </app-form-error>
    <div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
      <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
                        height:200px; 
                        position: fixed; 
                        
                        top: 50%; 
                        left: 50%; 
                        margin-top: -100px; 
                        margin-left: -100px;"></nz-spin>
      <span class="default-font-Color-dark">Loading ...</span>
    </div>
    <h2><br />{{name}} <button style="float:right" mat-flat-button color="primary" [routerLink]="['../']">Reports
        Dashboard</button></h2>
    <form [formGroup]="form" *ngIf="form!=null">

      <div class="row no-gutters">
        <ng-container *ngFor="let data of reportControls">
          <div class='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
            <span *ngIf="data.text != null">{{data.text}}</span>
            <ng-container [ngSwitch]="data.controlCode">
              <!-- <app-date-input *ngSwitchCase="'DATEPICK'" [formControlName]="data.text">
                                        </app-date-input> -->
              <div class="date_wrap" *ngSwitchCase="'DATEPICK'">
                <input [matDatepicker]="picker" [formControlName]="data.text">
                <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <input class="form-control" *ngSwitchCase="'TEXTBOX'" type="text" [formControlName]="data.text" />
              <!-- <select class="form-control" *ngSwitchCase="'DROPDOWN'" [formControlName]="data.text">
                <option></option>
                <ng-container *ngFor="let opt of data.value">
                  <option [value]="opt.key">{{opt.value}}</option>
                </ng-container>
              </select> -->
              <ng-select class="mx-2" *ngSwitchCase="'DROPDOWN'" [formControlName]="data.text">
                <ng-option *ngFor="let opt of data.value" [value]='opt.key'>{{opt.value}}
                </ng-option>
              </ng-select>

              <nz-select nzMode="tags" *ngSwitchCase="'MSDROPDOWN'" style="width: 100%;" [formControlName]="data.text">
                <nz-option *ngFor="let option of data.value" [nzLabel]="option.value" [nzValue]="option.key">
                </nz-option>
              </nz-select>
            </ng-container>
          </div>
        </ng-container>
      </div>

    </form>
    <!-- <div *ngIf="form!=null">
                        {{form.value | json}}
                </div>  -->
    <br />
    <div class="col-12 row no-gutters pull-right " style="text-align: right;margin: 0px;padding: 0px;">
      <button mat-flat-button color="primary" (click)="onGenerateReport()">Generate
        Report</button>&nbsp;&nbsp;
      <button mat-flat-button color="primary" (click)="onGenerateTemplate()" *ngIf='template!=null'>Generate
        Document</button> <span *ngIf='template!=null'>&nbsp;&nbsp;</span>
      <button mat-flat-button color="primary" (click)="PrintReport()" *ngIf='data !=null'>Print</button><span
        *ngIf='data !=null'>&nbsp;&nbsp;</span>
      <button mat-flat-button color="primary" (click)="downloadReport()">Download
        Only</button>&nbsp;&nbsp;
      <button mat-flat-button color="primary" (click)="onHistory()">History</button>&nbsp;&nbsp;
    </div>
    <br><br>
    <div *ngIf="data != null" class="col-12 row no-gutters "
      style="width:100%; overflow-x:visible  ;margin: 0px;padding: 0px;border:1px solid #48c5cb !important ">
      <br />
      <div class="col-12 row no-gutters " style="padding:15px;" *ngIf="headerColumnNames != null">
        <ng-container *ngFor="let header of headerColumnNames; let i = index">
          <div class='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
            <label>
              <div>{{header}}</div>
              <h6>{{headerData[0][i]}}</h6>
            </label>
          </div>

        </ng-container>
      </div>
      <div style="width:100%;overflow-x: auto;">
        <div class="col-12" style="display: inline-block ; text-align: right; padding:10px ;">
          <a color="primary" href="javascript:void(0)" (click)="download()"><i class="fa fa-file-excel-o"
              style="color: green; font-size: 28px; " aria-hidden="true"></i></a>
        </div>
        <nz-table [nzLoading]='loading' nzSize="small" [nzFrontPagination]="isPagingEnabled" [nzPageSize]="pagelength"
          [nzShowPagination]="isPagingEnabled" #basicTable (nzCurrentPageDataChange)="currentPageDataChange($event)"
          class="table table-striped table-hover" style="margin-top: 10px" [nzData]="data">
          <thead (nzSortChange)="sort($event)" nzSingleSort class="thead-dark">
            <tr>
              <th *ngIf="template!=null && !isPagingEnabled" [(nzChecked)]="isAllDisplayDataChecked"
                [nzIndeterminate]="isIndeterminate" (nzCheckedChange)="checkAll($event)"></th>
              <ng-container *ngFor="let access of columnNames; index as i;">
                <th nzShowSort [nzSortKey]="access" nzCustomFilter *ngIf='i<lastindex' nowrap="">
                  {{access}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                    ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                  <nz-filter-trigger [nzVisible]="visible" [nzActive]="'true'" [nzDropdownMenu]="menu">
                    <i nz-icon [nzType]="'search'"></i>
                  </nz-filter-trigger>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <div class="ant-table-filter-dropdown">
                      <div class="search-box">
                        <input type="text" nz-input placeholder={{access.columnLabel}} [(ngModel)]="searchValue" />
                        <button nz-button nzSize="small" nzType="primary" (click)="searchCust(access,access)"
                          class="search-button">
                          Search
                        </button>
                        <button nz-button nzSize="small" (click)="resetCust(access)">Reset</button>
                      </div>
                    </div>
                  </nz-dropdown-menu>
                </th>
              </ng-container>

              <th *ngIf="template!=null">Action</th>

            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of basicTable.data; ">
              <tr class="ng-scope">
                <td *ngIf="template!=null && !isPagingEnabled" nzShowCheckbox
                  (nzCheckedChange)="checkedChange($event,i)" [(nzChecked)]="item.checked"></td>
                <ng-container *ngFor="let access of item.item;index as i;">
                  <td *ngIf='i<lastindex' class="ng-binding">{{access}}</td>
                </ng-container>
                <td *ngIf="template!=null">
                  <a (click)="printone(item)" class="PatchFont">Print</a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </div>
      <br />
      <div class="col-12 row no-gutters " style="padding:15px;" *ngIf="footerColumnNames != null">
        <ng-container *ngFor="let header of footerColumnNames; let i = index">
          <div class='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
            <label>
              <div>{{header}}</div>
              <h6>{{footerData[0][i]}}</h6>
            </label>
          </div>
        </ng-container>
      </div>
    </div>
  </app-screen-content>
  <app-print-content>

    <div id="ReportMainDiv" *ngIf="data != null" class="col-12 row no-gutters "
      style="width:100%; overflow-x:visible  ;margin: 0px;padding: 0px;border:1px solid #48c5cb !important; ">
      <br />
      <div style="width:100%;overflow-x: auto;">
        <div style="width:100%;overflow-x: auto;">
          <table class="table table-striped table-hover" style="margin-top: 10px">
            <thead class="thead-dark">
              <tr>
                <ng-container *ngFor="let access of columnNames; index as i">
                  <th *ngIf="i<lastindex " nowrap="">
                    {{access}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of data">
                <tr class="ng-scope" *ngIf="item.checked==true">
                  <ng-container *ngFor="let access of item.item; index as i">
                    <td *ngIf='i<lastindex ' class="ng-binding">{{access}}</td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <div class="col-12 row no-gutters " style="padding:15px;" *ngIf="footerColumnNames != null">
        <ng-container *ngFor="let header of footerColumnNames; let i = index">
          <div class='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
            <label>
              <div>{{header}}</div>
              <h6>{{footerData[0][i]}}</h6>
            </label>
          </div>
        </ng-container>
      </div>
    </div>
  </app-print-content>
</div>