import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
// import { PopoverParams } from './popover.service';
import { TemplateRef, Type } from '@angular/core';

export type PopConfirmEvent<T = any> = {
  type: 'backdropClick' | 'close';
  data: T;
}



export class PopconfirmRef<T = any> {
  private afterClosed = new Subject<PopConfirmEvent<T>>();
  afterClosed$ = this.afterClosed.asObservable();
_message;_messageType;
  constructor(message,messageType=''
    ) {
   this._message=message;
   this._messageType=messageType;
  }

  public close(data?: T) {
    this._close('close', data);
  }

  private _close(type: PopConfirmEvent['type'], data?: T) {
    
    this.afterClosed.next({
      type,
      data
    });
    this.afterClosed.complete();
  }
}
