import { PipeTransform, Pipe, LOCALE_ID, Inject } from '@angular/core';
import * as _ from 'lodash';
import { formatDate } from '@angular/common';

@Pipe({ name: 'abcDate' })
export class DatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(
    value: string | number | Date,
    format: string,
    defaultValue: string
  ): string {
    if (!value) {
      return '';
    }
    return formatDate(value, format, this.locale) || defaultValue;
  }
}
