import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewChild,
  Output,
  ChangeDetectorRef,
  EventEmitter,
} from '@angular/core';
import { FormConfig } from '../model/inputcontrol';
import { MemberState } from '@store/main/member/member.state';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { MainState } from '@store/main/main.state';
import { HttpHeaders } from '@angular/common/http';
import { DynamicSearchComponent } from '@shared/components/dynamic_search/dynamic_search.component';
import {
  isMemberStateMutating,
  getMemberState,
} from '@store/main/member/member.selector';
import { DynamicFieldsComponent } from '@shared/components/dynamic_fields/dynamic_fields.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { getMainState, isMainStateMutating } from '@store/main/main.selector';
import {
  SaveMemberTypeAction,
  ResetMemberAction,
  GetMemberUrlAction,
} from '@store/main/member/member.actions';
import { SaveMainAction } from '@store/main/main.actions';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base.component';
import { AppState } from '@store/app.state';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { LoggerService } from '@shared/logging/logger.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { NotificationService } from '@shared/services/notification.service';
import { HttpService } from '@shared/api/http.service';
import { ClientService } from '@services/client/client.service';
import { DynamicDialogGridComponent } from '@shared/components/dynamic_grid/dynamic-grid.component';
import { SetSelectedClientByIdAction } from '@store/selection/selection.action';
import { NzModalService } from 'ng-zorro-antd/modal';

//import { errorHandler } from '@angular/platform-browser/src/browser';
@Component({
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./../dynamic.component.scss'],
})
export class DynamicFormComponent extends BaseComponent implements OnInit {
  @Output()
  formChange: EventEmitter<any> = new EventEmitter();
  @ViewChild(DynamicFieldsComponent)
  memberComponent: DynamicFieldsComponent;
  formConfigs: FormConfig[] = [];
  validators;
  validatorsarr;
  actionButton1Popup;
  actionButton2Popup;
  actionButton2PopupGrid;
  memberState: MemberState | null;
  EntityId: string;
  entityId: string;
  ClientId: string;
  MemberId: string;
  EntityClientId;
  entityClientId;
  actionSave: string;
  postUrl: string;
  putUrl: string;
  idfield: string;
  actionAdd: string;
  formType: string;
  innerDto: string;
  addNew = false;
  deleteButton;
  saveButton;
  cancelButton;
  searchForm;
  action1RefreshUrl;
  formValue;
  fetchFormValue;
  curFormValue;
  id;
  hiddenFields;
  storeSelector;
  actionDelete;
  deleteId;
  action1;
  actionButton1;
  actionButton1NatUrl;
  actionButton2;
  _jsonURL;
  searchobvUrl;
  action1Close;
  action2Close;
  upload;
  preventrefresh = 'false';
  searchFields;
  loading = false;
  reAddButton;
  saveCondition;
  saving = false;
  isLegalUpload = false;
  saveSuccess = false;
  successMessage = 'Record saved successfully';
  getUrl;
  paraArr;
  savingObj;
  savedObj;
  deleted;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private http: HttpClient,
    public store: Store<AppState>,
    public route: ActivatedRoute,
    public routeHelper: RouteHelperService,
    private logger: LoggerService,
    public router: Router,
    public spinner: SpinnerService,
    private httpser: HttpService,
    public clientService: ClientService,
    public notification: NzNotificationService,
    public notify: NotificationService
  ) {
    super();
  }

  transurl(url, curval) {
    let myurl = url;
    while (true) {
      let n = myurl.search(/:/i);
      if (n === -1) {
        break;
      }
      let res = myurl.substring(n + 1);
      n = res.search(/&/i);
      if (n === -1) {
        n = res.search(/\//i);
        if (n === -1) {
          res = res.substring(0);
        } else {
          res = res.substring(0, n);
        }
      } else {
        res = res.substring(0, n);
      }
      const val = curval[res];
      const pt = ':' + res;
      if (val != null) {
        myurl = myurl.replace(pt, val);
      } else {
        break;
      }
    }
    return myurl;
  }

  public ngOnInit() {
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.ClientId = '00000000-0000-0000-0000-000000000000'; // this.routeHelper.getClientId(this.route);
    this.MemberId = this.routeHelper.getMemberId(this.route);
    this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
    this.route.params.subscribe((params) => {
      this.id = params['formid'];
      if (this.id) {
        // this._jsonURL = 'assets/member/' + this.id.toLowerCase() + 'form.json';
        this._jsonURL = 'member/' + this.id.toLowerCase() + 'form';
        this.subscribeJson();
      }
    });
  }

  public onchangeObvSub(event) {
    // this.formChange.emit(this.formValue);
    this.curFormValue = this.memberComponent.getFormValue();
  }

  public subscribeJson(): void {
    // this.spinner.show();
    this.getJSON()
      .pipe(untilComponentDestroyed(this))
      .subscribe((data1) => {
        //    this.spinner.hide();
        if (data1 === null) {
          this.formConfigs = [];
        } else {
          const json = JSON.parse((<any>data1.jsonText) as string);
          this.formConfigs = json.FormConfig;
          this.validators = json.Validators;
          this.validatorsarr = json.Validatorsarr;
          this.actionSave = json.actionSave;
          this.postUrl = json.postUrl;
          this.putUrl = json.putUrl;
          this.idfield = json.idfield;
          this.actionDelete = json.actionDelete;
          this.actionButton1 = json.actionButton1;
          this.actionButton2 = json.actionButton2;
          this.actionButton1NatUrl = json.actionButton1NatUrl;
          this.formType = json.memberProperty;
          this.actionAdd = json.actionAdd;
          this.deleteId = json.deleteId;
          this.getUrl = json.getUrl;
          this.deleteButton = json.deleteButton;
          this.saveButton = json.saveButton;
          this.cancelButton = json.cancelButton;
          this.reAddButton = json.reAddButton;
          this.storeSelector = json.storeSelector;
          this.action1 = json.action1;
          this.actionButton1Popup = json.actionButton1Popup;
          this.actionButton2Popup = json.actionButton2Popup;
          this.actionButton2PopupGrid = json.actionButton2PopupGrid;
          this.searchForm = json.searchForm;
          this.searchobvUrl = json.searchobvUrl;
          this.action1RefreshUrl = json.action1RefreshUrl;
          this.action1Close = json.action1Close;
          this.action2Close = json.action2Close;
          this.saveCondition = json.saveCondition;
          this.upload = json.upload;
          this.isLegalUpload = json.isLegalUpload;
          this.preventrefresh = json.preventrefresh;
          this.searchobv();
          if (this.addNew) {
            //reset
            this.store.dispatch(
              new ResetMemberAction({ MemberProp: this.formType })
            );
          }
          this.subscribeState();
          if (this.getUrl && this.addNew != true) {
            this.getUrlAction();
          } else {
            if (this.getUrl == null && this.addNew != true) {
              this.noUrlAction();
            }
          }
        }
      });
  }
  public noUrlAction() {}
  public getUrlAction() {
    //const curval = this.memberComponent.getFormValue();
    let Url = this.getUrl;
    if (this.formValue != null) {
      Url = this.transurl(this.getUrl, this.formValue);
    }
    const observ$ = this.clientService.getWithUrl(Url, {});
    observ$.subscribe((data) => {
      this.formValue = { ...this.formValue, ...data };
    });
    // this.store.dispatch(new GetMemberUrlAction({
    //   Url, Prop: this.formType,
    //   EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId, entityclientId: this.EntityClientId
    // }));
  }

  public searchobv() {
    if (this.searchobvUrl != null) {
      this.memberComponent.onUpdateForm(this.formValue, this.searchobvUrl);
    }
  }

  public getJSON(): Observable<any> {
    // return this.http.get(this._jsonURL);
    return this.httpser.get('json?path=' + this._jsonURL, {
      urlParams: null,
      options: {
        isMock: false,
      },
    });
  }

  subscribeMainState() {
    this.store
      .select(getMainState)
      .pipe(untilComponentDestroyed(this))
      .subscribe((x) => this.MainStateChange(x));
  }

  subscribeState() {
    if (this.storeSelector === 'main') {
      this.subscribeMainState();
    } else {
      this.subscribeMemberState();
    }
  }

  sameDay(d1, d2) {
    if (d1 == null || d2 == null) {
      return false;
    }
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  isSaveButton() {
    if (this.saveCondition == null || this.addNew) {
      return this.saveButton;
    }
    if (this.saveCondition[0].op === 'sameday') {
      if (this.formValue != null) {
        const today = new Date();
        const ret = this.sameDay(
          this.formValue[this.saveCondition[0].field],
          today
        );
        if (ret === true) {
          return this.saveButton;
        }
        return 'false';
      }
    }
  }

  subscribeMemberState() {
    this.store
      .select(getMemberState)
      .pipe(untilComponentDestroyed(this))
      .subscribe((x) => {
        this.memberStateChange(x);
      });
  }
  public onMainSaveSuccess() {}

  private MainStateChange(state: MainState) {
    if (!isMainStateMutating(state)) {
      if (this.saving && !state.saving && state.saveError == null) {
        this.saveSuccess = true;
        this.onMainSaveSuccess();
        this.saving = false;
        // this.formValue = {...state.savedObj};
        if (this.preventrefresh != 'true') {
          this.memberComponent.updateFormValue(state.savedObj.dto);
        }
        this.notify.show('Record saved successfully!', 'Success');
        //this.notification.blank( 'Record saved successfully', ' ');
        this.savedObj = state.savedObj.dto;
      }
      if (this.saving && !state.saving && state.saveError != null) {
        this.saving = false;
        this.memberComponent.updateFormValue(state.savedObj.dto);
        if (Array.isArray(state.saveError.error)) {
          this.notify.show(state.saveError.error[0], 'Fail');
        } else {
          if (state.saveError.error.modelState != null) {
            this.notify.show(
              state.saveError.error.modelState.comments[0],
              'Fail'
            );
          }
          if (state.saveError.message != null) {
            this.notify.show(state.saveError.message, 'Fail');
          }
          this.notify.show(state.saveError.error.message, 'Fail');
        }
      }
      // this.spinner.hide();
    }
  }

  memberStateChange(state: MemberState) {
    this.memberState = state;
    if (this.memberState && this.memberState.member) {
      if (
        this.saving &&
        !this.memberState.saving &&
        this.memberState.saveError == null
      ) {
        //this.saveSuccess = true;
        this.saving = false;
        //this.notification.blank('Record saved successfully', ' ');
        this.savedObj = this.savingObj;
        if (this.getUrl) {
          this.store.dispatch(
            new GetMemberUrlAction({
              Url: this.getUrl,
              Prop: this.formType,
              EntityId: this.EntityId,
              ClientId: this.ClientId,
              MemberId: this.MemberId,
            })
          );
        }
      } else if (this.memberState.saveError != null) {
        this.saveSuccess = false;
        if (this.getUrl) {
          this.store.dispatch(
            new GetMemberUrlAction({
              Url: this.getUrl,
              Prop: this.formType,
              EntityId: this.EntityId,
              ClientId: this.ClientId,
              MemberId: this.MemberId,
            })
          );
        }
      }
      this.formValue = { ...this.memberState.member[this.formType] };
      if (this.formValue != null) {
        this.formValue.EntityId = this.EntityId;
        this.formValue.ClientId = this.ClientId;
        this.formValue.EntityClientId = this.EntityClientId;
        if (this.formValue.ParticipantId == null) {
          this.formValue.ParticipantId = this.MemberId;
        }
      }
    }
    if (!isMemberStateMutating(this.memberState)) {
      this.spinner.hide();
    }
  }

  onCancel(obj) {
    this.router.navigate(['../../dashboard'], { relativeTo: this.route });
  }

  onAction1() {
    if (this.actionButton1Popup != null) {
      const obj = this.memberComponent.getFormValue();
      const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
        width: '90%',
        // height: '90%',
        data: {
          addNew: true,
          item: obj,
          formid: this.actionButton1Popup,
          prePath: '',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        // if (result != null) {
        if (this.action1RefreshUrl != null) {
          let myurl = this.action1RefreshUrl;
          const curval = this.memberComponent.getFormValue();
          myurl = this.transurl(myurl, curval);
          const Obv$ = this.clientService.getWithUrl(myurl, {});
          Obv$.pipe(untilComponentDestroyed(this)).subscribe((data) => {
            this.formValue = { ...curval, ...data };
            this.changeDetectorRef.detectChanges();
          });
        }
        if (this.actionButton1NatUrl != null) {
          let naturl = this.actionButton1NatUrl;
          naturl = this.transurl(naturl, result);
          this.router
            .navigate([naturl], { relativeTo: this.route })
            .then(() => {
              window.location.reload();
            });
        }
        // }
      });
    } else {
      this.loading = true;
      this.spinner.show();
      const obj = this.memberComponent.getFormValue();
      const observ$ = this.clientService
        .postWithUrl({ url: this.action1, dto: obj })
        .pipe(untilComponentDestroyed(this));
      observ$.subscribe(
        (data) => {
          this.loading = false;
          this.spinner.hide();
          this.notification.blank(this.actionButton1 + ' success', ' ');
          //this.action1Complete.emit();
        },
        (err) => {
          this.loading = false;
          this.spinner.hide();
          this.notification.blank(err.error[0], err.message);
        }
      );
    }
  }

  public onSave(obj) {
    this.savingObj = obj;
    if (this.upload === true) {
      const formData = new FormData();
      if (this.isLegalUpload) {
        formData.append('file', obj['file']);
        formData.append('LegalCaseId', obj.legalCaseId);
        formData.append('ParticipantId', obj.participantId);
        formData.append('documentId', obj.documentId);
      } else {
        formData.append('file', obj['file']);
        if (this.EntityId != null) {
          formData.append('EntityId', this.EntityId);
        } else {
          formData.append('EntityId', obj.entityId);
        }
        if (this.MemberId != null) {
          formData.append('ParticipantId', this.MemberId);
        } else {
          formData.append('ParticipantId', obj.ParticipantId);
        }
        formData.append('EntityClientId', obj.entityClientId);
        formData.append('documentId', obj.documentId);
        formData.append('BenefitEntityId', obj.benefitEntityId);
        formData.append('documentName', obj.documentName);
        formData.append('documentDetail', obj.documentDetail);
      }
      const observ$ = this.clientService
        .postWithUrl({ url: this.actionAdd, dto: formData })
        .pipe(untilComponentDestroyed(this));
      observ$.subscribe(
        (res) => {
          this.notification.blank('Saved successfully', ' ');
        },
        (error) => {
          if (error != null && Array.isArray(error.error) && error.error.length > 0) {
            this.notification.blank(error.error[0], ' ');
          } else if(error != null && error.error.message!=null){
            this.notification.blank( error.error.message, ' ');
          } else if(error != null && error.error!=null){
            this.notification.blank( error.error, ' ');
          }
          else{
            this.notification.blank(error, ' ');
          }
        }
      );
      return;
    }
    if (
      this.actionAdd == null &&
      this.actionSave == null &&
      this.idfield != null
    ) {
      this.formValue = { ...this.formValue, ...obj };
      if (this.formValue[this.idfield] == null) {
        //post
        let url = this.transurl(this.postUrl, this.formValue);
        const observ$ = this.clientService
          .postWithUrl({ url, dto: this.formValue })
          .pipe(untilComponentDestroyed(this));
        observ$.subscribe(
          (data) => {
            if (data != null && data != '') {
              this.notification.blank('Saved successfully', ' ');
            } else {
              this.notification.blank('Saved successfully', ' ');
            }
          },
          (error) => {
            if (error != null && error.error.length > 0) {
              this.notification.blank(error.error[0], ' ');
            } else {
              this.notification.blank(error, ' ');
            }
          }
        );
      } else {
        //put
        let url = this.transurl(this.putUrl, this.formValue);
        const observ$ = this.clientService
          .putWithUrl({ url, dto: this.formValue })
          .pipe(untilComponentDestroyed(this));
        observ$.subscribe(
          (data) => {
            if (data != null && data != '') {
              this.notification.blank('Saved successfully', ' ');
            } else {
              this.notification.blank('Saved successfully', ' ');
            }
          },
          (error) => {
            if (error != null && error.error.length > 0) {
              this.notification.blank(error.error[0], ' ');
            } else {
              this.notification.blank(error, ' ');
            }
          }
        );
      }
      return;
    }
    // this.spinner.show();
    // this.store.dispatch({type: this.actionSave, payload: payobj});
    if (this.storeSelector === 'main') {
      if (this.addNew) {
        this.store.dispatch(
          new SaveMainAction({ Url: this.actionAdd, Data: obj })
        );
      } else {
        obj['EntityId'] = this.EntityId; // '68BC62E6-DD96-4428-91E8-EFE285BD23A0'; //  this.EntityId;
        if (obj['ClientId'] == null) {
          obj['ClientId'] = this.ClientId;
        }
        obj['ParticipantId'] = this.MemberId; // '550B039B-4EAE-42D4-B20B-B4FB3EF52E3E'; // this.MemberId;
        if (this.EntityClientId != null) {
          obj['EntityClientId'] = this.EntityClientId;
        }
        const myurl = this.transurl(this.actionSave, obj);
        this.store.dispatch(new SaveMainAction({ Url: myurl, Data: obj }));
      }
    } else {
      if (this.EntityId != null) {
        obj['EntityId'] = this.EntityId; // '68BC62E6-DD96-4428-91E8-EFE285BD23A0'; //  this.EntityId;
      }
      if (obj['clientId'] == null) {
        obj['ClientId'] = this.ClientId;
      }
      if (this.MemberId != null) {
        obj['ParticipantId'] = this.MemberId; // '550B039B-4EAE-42D4-B20B-B4FB3EF52E3E'; // this.MemberId;
      }
      if (this.EntityClientId != null) {
        obj['EntityClientId'] = this.EntityClientId;
      }
      if (this.addNew) {
        //Called for Add New Member Anthem
        this.store.dispatch(
          new SaveMemberTypeAction({ Url: this.actionAdd, Data: obj })
        );
        this.formValue = obj;
      } else {
        const myurl = this.transurl(this.actionSave, obj);
        this.store.dispatch(
          new SaveMemberTypeAction({ Url: myurl, Data: obj })
        );
        this.formValue = obj;
      }
    }
    this.saving = true;
  }

  public onDelete(obj) {
    var url = this.actionDelete;
    url = this.transurl(url, obj);
    const observ$ = this.clientService
      .postWithUrl({ url, dto: obj })
      .pipe(untilComponentDestroyed(this));
    observ$.subscribe(
      (data) => {
        if (data != null && data != '') {
          this.notification.blank(data, ' ');
        } else {
          this.notification.blank('Deleted successfully', ' ');
        }

        this.deleted = true;
      },
      (error) => {
        if (error != null && error.error.length > 0) {
          this.notification.blank(error.error[0], ' ');
        } else {
          this.notification.blank(error, ' ');
        }
      }
    );
  }
}

@Component({
  templateUrl: './dynamic-dialog-form.component.html',
})
export class DynamicDialogFormComponent
  extends DynamicFormComponent
  implements OnInit
{
  // @ViewChild(DynamicFieldsComponent)
  // private memberComponent: DynamicFieldsComponent;
  saved = false;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<DynamicFormComponent>,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    http: HttpClient,
    store: Store<AppState>,
    route: ActivatedRoute,
    routeHelper: RouteHelperService,
    logger: LoggerService,
    router: Router,
    spinner: SpinnerService,
    httpser: HttpService,
    clientService: ClientService,
    notification: NzNotificationService,
    notify: NotificationService
  ) {
    super(
      changeDetectorRef,
      dialog,
      http,
      store,
      route,
      routeHelper,
      logger,
      router,
      spinner,
      httpser,
      clientService,
      notification,
      notify
    );

    if (data) {
      this.addNew = data.addNew;
      if (data.prePath == null) {
        data.prePath = 'member/';
      }
      // this._jsonURL = 'assets/member/' + data.formid.toLowerCase() + 'form.json';
      this._jsonURL = data.prePath + data.formid.toLowerCase() + 'form';
      this.id = data.prePath + data.formid.toLowerCase();
      this.EntityId = this.data.entityId;
      this.MemberId = this.data.participantId;
      this.ClientId = '00000000-0000-0000-0000-000000000000';
      this.EntityClientId = this.data.entityClientId;
      this.formValue = this.data.item;
      this.fetchFormValue = this.data;
      this.paraArr = this.data.paraArr;
      this.subscribeJson();
    }
  }

  onGetFields(obj) {
    let localUrl = this.transurl(obj.url, this.fetchFormValue);

    if (!this.EntityId) {
      this.EntityId = this.formValue.EntityId;
      this.EntityClientId = this.formValue.EntityClientId;
    }

    if (
      localUrl.includes(':EntityClientId') ||
      localUrl.includes(':EntityId')
    ) {
      localUrl = localUrl.replace(':EntityClientId', this.EntityClientId);
      localUrl = localUrl.replace(':EntityId', this.EntityId);
    }

    const observ$ = this.clientService
      .getWithUrl(localUrl, {})
      .pipe(untilComponentDestroyed(this));
    observ$.subscribe((data) => {
      if (obj.fields != null) {
        const objform = this.memberComponent.getFormValue();
        obj.fields.forEach((element) => {
          objform[element] = data[0][element];
        });
        // this.formValue = Object.assign(this.formValue, objform);
        this.memberComponent.updateFormValue(objform);
      }
    });
  }

  public searchobv() {}
  public onchangeObvSub(event) {}
  convertPseudo(str) {
    var newstr = '';
    for (var i = 0; i < str.length; i++) {
      var c = str.charAt(i);
      if (c >= '0' && c <= '9') {
        var cc = 9 - c;
        newstr = newstr + cc.toString();
      }
    }
    return newstr;
  }

  onAction1() {
    const obj = this.memberComponent.getFormValue();
    if (obj['pseudossNumber'] != '' && obj['pseudossNumber'] != null) {
      obj['SSNumber'] = this.convertPseudo(obj['pseudossNumber']);
      obj['pseudossNumber'] = null;
    }
    if (this.actionButton1Popup != null) {
      const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
        width: '90%',
        data: {
          addNew: false,
          item: obj,
          formid: this.actionButton1Popup,
          prePath: '',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (this.action1Close === 'true') {
          this.onCancel(null);
        }
      });
    } else {
      this.dialogRef.close(obj);
    }
  }

  onAction2() {
    const obj = this.memberComponent.getFormValue();
    if (this.actionButton2Popup != null) {
      const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
        width: '90%',
        data: {
          addNew: false,
          item: obj,
          formid: this.actionButton2Popup,
          prePath: '',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (this.action2Close === 'true') {
          this.onCancel(null);
        }
      });
    } else {
      this.dialogRef.close(obj);
    }
  }

  onSearch(e) {
    let dialogRef;
    let innerForm = this.id + 'search';
    if (e != null) {
      innerForm = e.id + 'search';
      this.searchFields = e.fields;
    }
    dialogRef = this.dialog.open(DynamicSearchComponent, {
      width: '90%',
      data: {
        item: { entityId: this.EntityId, entityClientId: this.EntityClientId },
        addNew: false,
        formid: innerForm,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.memberComponent.onUpdateForm(result, this.searchobvUrl);
    });
  }
  public ngOnInit() {}

  memberStateChange(state: MemberState) {
    this.memberState = state;
    if (this.memberState) {
      if (
        this.saving &&
        !this.memberState.saving &&
        this.memberState.saveError == null
      ) {
        this.saveSuccess = true;
        this.saving = false;
        if (this.memberState.objupdate != null) {
          this.savedObj = this.memberState.objupdate;
        } else {
          this.savedObj = this.savingObj;
        }
      }
    }
    if (
      this.memberState.member != null &&
      this.memberState.member[this.formType] != null
    ) {
      this.formValue = {
        ...this.formValue,
        ...this.memberState.member[this.formType],
      };
    }

    if (!isMemberStateMutating(this.memberState)) {
      this.spinner.hide();
    }
  }

  onCancel(obj) {
    if (this.deleted) {
      this.dialogRef.close(this.deleted);
    } else {
      this.dialogRef.close(this.savedObj); //
    }
  }
}

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-main-form.component.html',
})
export class DynamicMainFormComponent
  extends DynamicFormComponent
  implements OnInit
{
  @Input()
  hideButton = false;
  @Input()
  addNew = false;
  @Input()
  closeEvent = false;
  @Input()
  isPrint;
  @Input()
  formid;
  @Input()
  formIntiValue;
  @Input()
  saveButtonInit;
  @Input()
  paraArrInput;
  @Output()
  delete: EventEmitter<any> = new EventEmitter();
  @Output()
  action1Complete: EventEmitter<any> = new EventEmitter();
  @Output()
  formChange: EventEmitter<any> = new EventEmitter();
  @Output()
  cancel: EventEmitter<any> = new EventEmitter();
  constructor(
    private modal: NzModalService,
    public changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    http: HttpClient,
    public store: Store<AppState>,
    route: ActivatedRoute,
    routeHelper: RouteHelperService,
    logger: LoggerService,
    router: Router,
    spinner: SpinnerService,
    httpser: HttpService,
    clientService: ClientService,
    notification: NzNotificationService,
    notify: NotificationService
  ) {
    super(
      changeDetectorRef,
      dialog,
      http,
      store,
      route,
      routeHelper,
      logger,
      router,
      spinner,
      httpser,
      clientService,
      notification,
      notify
    );
  }

  updateForm(obj) {
    this.memberComponent.updateFormValue(obj);
  }

  public validate() {
    return this.memberComponent.validate();
  }

  public getUrlAction() {
    this.loading = true;
    this.spinner.show();
    let myurl;
    if (this.formIntiValue != null) {
      myurl = this.transurl(this.getUrl, this.formIntiValue);
    } else {
      const obj = {
        EntityId: this.EntityId,
        entityclientId: this.EntityClientId,
        entityClientId: this.EntityClientId,
      };
      myurl = this.transurl(this.getUrl, obj);
    }
    const Obv$ = this.clientService.getWithUrl(myurl, {});
    Obv$.pipe(untilComponentDestroyed(this)).subscribe(
      (data) => {
        this.loading = false;
        this.spinner.hide();
        this.formValue = { ...this.formIntiValue, ...data };
        this.formChange.emit(this.formValue);
      },
      (error) => {
        this.loading = false;
        this.spinner.hide();
        this.notification.blank(error.message, ' ');
      }
    );
  }
  public noUrlAction() {
    this.formValue = { ...this.formIntiValue };
  }
  public onchangeObvSub(event) {
    this.formChange.emit();
  }

  // showConfirm(): void {
  //   this.logouting = true;
  //   clearTimeout(this.userActivity);
  //   this.setTimeout();
  //   this.ref = this.modal.confirm({
  //     nzTitle: '<i>Application will log out in 1 minute</i>',
  //     nzContent: '<b>Click OK to continue</b>',
  //     nzOnOk: () => {
  //       this.logouting = false;
  //       clearTimeout(this.userActivity);
  //       this.setTimeout();
  //     }
  //   });
  // }

  public onSave(obj) {
    // this.spinner.show();
    if (this.upload === true) {
      const formData = new FormData();
      formData.append('file', obj['file']);
      formData.append('EntityId', this.EntityId);
      formData.append('ParticipantId', this.MemberId);
      formData.append('EntityClientId', this.EntityClientId);
      formData.append('documentId', obj.documentId);
      formData.append('BenefitEntityId', obj.benefitEntityId);
      formData.append('documentName', obj.documentName);
      const observ$ = this.clientService
        .postWithUrl({ url: this.actionAdd, dto: formData })
        .pipe(untilComponentDestroyed(this));
      observ$.subscribe(
        (res) => {
          this.notification.blank('Saved successfully', ' ');
        },
        (err) => {
          //   this.notification.blank(err.error[0], ' ');
        }
      );
      return;
    }
    if (this.addNew) {
      this.store.dispatch(
        new SaveMainAction({ Url: this.actionAdd, Data: obj })
      );
    } else {
      if (obj != null) {
        if (obj.category == 'Letter' && (obj.body == '' || obj.body == null)) {
          this.modal.confirm({
            nzTitle: '<b>The Letter template is empty</b>',
            nzCancelDisabled: true,
            // nzContent: '<b>Click OK to continue</b>',
            // nzOnOk: () => {
            //   this.store.dispatch(new SaveMainAction({ Url: this.actionSave, Data: obj }));
            //   this.formValue = obj;
            // }
          });
        } else {
          this.store.dispatch(
            new SaveMainAction({ Url: this.actionSave, Data: obj })
          );
          this.formValue = obj;
        }
      }
      //obj -> had template paratem check if tempate is null - give message
      // if tempalte parameter is not tehre then dont pop up message
    }
    this.saving = true;
  }

  triggerSave() {
    this.memberComponent.onSave();
  }

  triggerDelete() {
    this.memberComponent.onDelete();
  }

  onAction1() {
    if (this.actionButton1Popup != null) {
      const obj = this.memberComponent.getFormValue();
      const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
        width: '90%',
        data: {
          addNew: false,
          item: obj,
          formid: this.actionButton1Popup,
          prePath: '',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        // if (result != null) {
        if (this.action1RefreshUrl != null) {
          let myurl = this.action1RefreshUrl;
          const curval = this.memberComponent.getFormValue();
          myurl = this.transurl(myurl, curval);
          const Obv$ = this.clientService.getWithUrl(myurl, {});
          Obv$.pipe(untilComponentDestroyed(this)).subscribe((data) => {
            this.formValue = { ...curval, ...data };
            this.changeDetectorRef.detectChanges();
          });
        }
      });
    } else {
      this.loading = true;
      this.spinner.show();
      const obj = this.memberComponent.getFormValue();
      const observ$ = this.clientService
        .postWithUrl({ url: this.action1, dto: obj })
        .pipe(untilComponentDestroyed(this));
      observ$.subscribe(
        (data) => {
          this.loading = false;
          this.spinner.hide();
          this.notification.blank(this.actionButton1 + ' success', ' ');
          this.action1Complete.emit();
        },
        (err) => {
          this.loading = false;
          this.spinner.hide();
          this.notification.blank(err.error[0], err.message);
        }
      );
    }
  }

  onAction2() {
    if (this.actionButton2Popup != null) {
      const obj = this.memberComponent.getFormValue();
      const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
        width: '90%',
        data: {
          addNew: false,
          item: obj,
          formid: this.actionButton2Popup,
          prePath: '',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        // if (result != null) {
        if (this.action1RefreshUrl != null) {
          let myurl = this.action1RefreshUrl;
          const curval = this.memberComponent.getFormValue();
          myurl = this.transurl(myurl, curval);

          const Obv$ = this.clientService.getWithUrl(myurl, {});
          Obv$.pipe(untilComponentDestroyed(this)).subscribe((data) => {
            this.formValue = { ...curval, ...data };
            this.changeDetectorRef.detectChanges();
          });
        }
      });
    } else if (this.actionButton2PopupGrid != null) {
      const obj = this.memberComponent.getFormValue();
      const dialogRef = this.dialog.open(DynamicDialogGridComponent, {
        width: '90%',
        data: {
          addNew: false,
          item: obj,
          formid: this.actionButton2PopupGrid,
          prePath: '',
        },
      });
    } else {
      this.loading = true;
      this.spinner.show();
      const obj = this.memberComponent.getFormValue();
      const observ$ = this.clientService
        .postWithUrl({ url: this.action1, dto: obj })
        .pipe(untilComponentDestroyed(this));
      observ$.subscribe(
        (data) => {
          this.loading = false;
          this.spinner.hide();
          this.notification.blank(this.actionButton1 + ' success', ' ');
          this.action1Complete.emit();
        },
        (err) => {
          this.loading = false;
          this.spinner.hide();
          this.notification.blank(err.error[0], err.message);
        }
      );
    }
  }

  getFormValue() {
    const obj = this.memberComponent.getFormValue();
    return obj;
  }

  getSaveButton() {
    if (this.saveButton === 'false' || this.saveButtonInit === 'false') {
      return 'false';
    } else {
      return this.saveButton;
    }
  }

  onCancel(obj) {
    if (this.closeEvent == true) {
      this.cancel.emit();
      return;
    }
    if (this.cancelButton !== 'false') {
      this.router.navigate(['../'], { relativeTo: this.route });
    } else {
      this.delete.emit();
    }
  }

  onSearch(e) {
    let dialogRef;
    let innerForm = this.id + 'search';
    if (e != null) {
      innerForm = e.id + 'search';
      this.searchFields = e.fields;
    }
    dialogRef = this.dialog.open(DynamicSearchComponent, {
      width: '90%',
      data: {
        addNew: false,
        formid: innerForm,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (this.searchFields != null) {
        var newret = {};
        this.searchFields.forEach((element) => {
          newret[element] = result[element];
        });
        result = newret;
      }
      this.memberComponent.onUpdateForm(result, this.searchobvUrl);
    });
  }

  public ngOnInit() {
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
    if (this.formid) {
      // this._jsonURL = 'assets/member/' + this.id.toLowerCase() + 'form.json';
      this._jsonURL = this.formid.toLowerCase() + 'form';
      this.subscribeJson();
    } else {
      this.route.params.subscribe((params) => {
        this.id = params['formid'];
        if (this.id) {
          // this._jsonURL = 'assets/member/' + this.id.toLowerCase() + 'form.json';
          this._jsonURL = this.id.toLowerCase() + 'form';
          this.subscribeJson();
        } else {
          this.route.data.subscribe((data) => {
            this.id = data.formid;
          });
          this._jsonURL = this.id.toLowerCase() + 'form';
          this.subscribeJson();
        }
      });
    }
    if (this.formIntiValue) {
      this.formValue = this.formIntiValue;
    }
  }
}

@Component({
  templateUrl: './dynamic-main-form-header.component.html',
})
export class DynamicMainFormHeaderComponent
  extends DynamicMainFormComponent
  implements OnInit
{
  public onMainSaveSuccess() {
    if (this.EntityClientId !== '') {
      this.store.dispatch(
        new SetSelectedClientByIdAction({ entityClientId: this.EntityClientId })
      );
    }
  }
}
