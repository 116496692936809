import { Component } from '@angular/core';
import {PrintService} from './print.service';
import { DomSanitizer} from '@angular/platform-browser';
// import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
@Component({
  selector: 'app-root',
  templateUrl: './dynamic_print.component.html',

})
export class DynamicPrintComponent {

  constructor(public printService: PrintService, private sanitizer: DomSanitizer) {

   }
   download() {
       const div: any = document.getElementById('results');
    
  }
  onPrint() {

    this.printService
      .printDocument('test', 'testprint');
  }
}
