
    <div class="row col-12" style="height:50px"> <button nz-button nzType="default" (click)="onSearch()"><i nz-icon
        [nzType]="'search'"></i>Search</button></div>


<app-dynamic-form
[formid]="formid" [formIntiValue]='formValue' [closeEvent]='closeEvent'
(cancel)='onCancel()'
>

</app-dynamic-form>