import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { ActivatedRoute } from '@angular/router';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { SpinnerService } from '@shared/services/spinner.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ResetMemberAction } from '@store/main/member/member.actions';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MemberService } from '@services/client/member.service';
import { ConfigService } from '@shared/config.service';
import { ClientService } from '@services/client/client.service';
import { MatDialog } from '@angular/material/dialog';
import { EnrollmentService } from './enrollment.service';
import { UnderWritingComponent } from '@shared/components/dynamicplan/underwriting.component';
import { WaiverComponent } from './waiver.component';

@Component({
  selector: 'app-member-plans-benefitentity',
  templateUrl: './benefitentityband.component.html',
  styleUrls: ['./../dynamic.component.scss']
})

export class BenefitEntityBandComponent extends BaseComponent implements OnInit {
  @Input()
  index;
  @Input()
  memberProfile;
  @Input()
  set entityClient(entityClient) {
    if (entityClient != null) {
      this.EntityId = entityClient.entityId;
      this.entityclientId = entityClient.entityClientId;
      this.ClientId = entityClient.clientId;
      this.load();
    }
  }
  @Input()
  set participantId(participantId) {
    this.MemberId = participantId;
    this.load();
  }
  @Input()
  set initExpand(initExpand) {
    this._initExpand = initExpand;
    this.state = initExpand;
  }
  _initExpand;
  @Input()
  campaignCode;
  @Input()
  set benefitEntity(benefitEntity) {
    this._benefitEntity = benefitEntity;
    // tslint:disable-next-line:max-line-length
    this.load();
  }

  _benefitEntity;

  policies;
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() loadingComplete = new EventEmitter<any>();
  policiesForm: UntypedFormGroup;
  EntityId: string;
  ClientId: string;
  MemberId: string;
  state = 'close'; initFormValue;
  loading = false; entityclientId;
  inwaiver;
  dropReason;
  private memberApiUrls: any;
  constructor(public dialog: MatDialog, private configService: ConfigService,
    protected clientService: ClientService,
    protected fb: UntypedFormBuilder,
    private http: HttpClient, public store: Store<AppState>,
    private notification: NzNotificationService,
    private memberService: MemberService,
    private route: ActivatedRoute,
    private routeHelper: RouteHelperService,
    public spinner: SpinnerService, public enrollmentService: EnrollmentService) {
    super();
    this.store.dispatch(new ResetMemberAction({ MemberProp: 'benefitEntityPolicies' }));
    this.memberApiUrls = this.configService.api.memberService;
    this.EntityId = this.routeHelper.getEntityId(this.route);
    //this.ClientId = this.routeHelper.getClientId(this.route);
    this.MemberId = this.routeHelper.getMemberId(this.route);
    this.entityclientId = this.routeHelper.getEntityClientId(this.route);
  }

  waiverTitle() {
    if (this.inwaiver) {
      return 'In Waiver';
    } else {
      return 'Waiver';
    }
  }

  onWaiver() {
    const enrolledPolicies = this.policies.filter((p) => {
      if (p.currentEnrollment == null) {
        return false;
      } else {
        return true;
      }
    });

    if (enrolledPolicies.length === 0) {
      return;
    }

    const dialogRef = this.dialog.open(WaiverComponent, {
      width: '60%',
      // height: '90%',
      data: {
        addNew: true,
        paraArr: enrolledPolicies,
        entityId: this.EntityId,
        entityclientId: this.entityclientId,
        participantId: this.MemberId,
        item: {
          entityId: this.EntityId,
          entityClientId: this.entityclientId,
          participantId: this.MemberId,
          benefitEntityId: this._benefitEntity.benefitEntityId,
          entityclientId: this.entityclientId,
          clientId: this.ClientId
        },
        formid: 'waiver'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadWaiver();
    });
  }

  getRate(x) {
    return x;
  }

  onInfoClick(PlanName) {
    const dualScreenLeft = window.screenLeft;
    const dualScreenTop = window.screenTop;
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    const w = width * .9;
    const h = height * .8;
    const left = ((width / 2) - (w / 2)) + dualScreenLeft;
    const newWindow = window.open('https://staging.abcsys.com/theboss/Documents/' + PlanName + '.pdf', 'Video link', 'location=no,toolbar=no,menubar=no,resizable=no,scrollbars=yes, width=' + w + ', height=' + h + ', top=' + 140 + ', left=' + left);
    // Puts focus on the newWindow
    if (newWindow !== null && window.focus) {
      newWindow.focus();
    }
  }

  load() {
    if (this._benefitEntity == null) {
      return;
    }
    this.loading = true;
    const myurl = 'Enrollment/BenefitEntityPolicies?entityId=' + this.EntityId + '&entityclientId=' + this.entityclientId + '&participantId=' + this.MemberId + '&benefitEntityId=' + this._benefitEntity.benefitEntityId + '&enrollmentTypeCode';
    const observ$ = this.clientService.getWithUrl(myurl, {}).pipe(untilComponentDestroyed(this));
    observ$.subscribe((data) => {
      this.loading = false;
      const sortedPolicies: any = [];
      //pick enrolled
      data.benefitEntityPolicies.forEach(el => {
        if (el.enrolled == true) {
          sortedPolicies.push(el);
        }
      });
      //pick unenrolled
      data.benefitEntityPolicies.forEach(el => {
        if (el.enrolled == false) {
          sortedPolicies.push(el);
        }
      });
      this.policies = sortedPolicies;//data.benefitEntityPolicies;
      this.loadingComplete.emit(data);
      const id = data.benefitEntityId;
      // this.initFormValue[id] = {};
      this.initFormValue = {};
      this.enrollmentService.LoadingComplete(data, this.initFormValue, id);
      this.loadUnderWriting();
      this.loadWaiver();
    },
      err => {
        this.loading = false;
      });
  }

  loadWaiver() {
    this.policies.forEach(element => {
      const myurl = 'genericapi/Query?typeName=Waiver&entityId=' + this.EntityId + '&benefitEntityPolicyId=' + element.benefitEntityPolicyId + '&participantId=' + this.MemberId;
      const observ$ = this.clientService.getWithUrl(myurl, {}).pipe(untilComponentDestroyed(this));
      observ$.subscribe((data) => {
        element.waiver = data.details;
      });
    });
  }

  loadUnderWriting() {
    this.policies.forEach(element => {
      const myurl = 'Enrollment/UnderwrittingAll?entityId=' + this.EntityId + '&participantId=' + this.MemberId + '&BenefitEntityPolicyId=' + element.benefitEntityPolicyId;
      // const myurl = 'test/QueryWithDetails?typeName=EnrollmentApplication&entityId=' + this.EntityId + '&ParticipantId=' + this.MemberId + '&BenefitEntityPolicyId=' + element.benefitEntityPolicyId ;
      const observ$ = this.clientService.getWithUrl(myurl, {}).pipe(untilComponentDestroyed(this));
      observ$.subscribe((data) => {
        element.underWriting = data;
        // element.underWriting = data.details;
        if (element.underWriting != null && element.underWriting.length > 0) {
          element.underWritingStatus = element.underWriting[0].enrollmentApplicationDetails[0].statusId;
          element.underWritingId = element.underWriting[0].appId;
        }
      });
    });
  }

  onDecline() {
    // get current app
    const dialogRef = this.dialog.open(UnderWritingComponent, {
      width: '90%',
      data: {
        addNew: false,
        paraArr: this.policies,
        item: { createdBy: 'vw', currentStatusId: '7006a7ba-17f0-4820-a213-ec95621021be', entityClientId: this.entityclientId, entityId: this.EntityId, participantId: this.MemberId, benefitEntityId: this._benefitEntity.benefitEntityId },
        formid: 'enrollmentdecline',
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadUnderWriting();
    });
  }

  onPending() {
    const dialogRef = this.dialog.open(UnderWritingComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: false,
        paraArr: this.policies,
        item: { createdBy: 'vw', currentStatusId: 'cc129ea0-5a10-470f-adf3-a14fe395b631', entityClientId: this.entityclientId, entityId: this.EntityId, participantId: this.MemberId, benefitEntityId: this._benefitEntity.benefitEntityId },
        formid: 'enrollmentpending',
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadUnderWriting();
    });
  }

  onClick() {
    if (this.state === 'close') {
      this.state = 'expand';
    } else {
      this.state = 'close';
    }
  }

  ngOnInit() {
    this.policiesForm = this.fb.group({
    });
    this.formReady.emit(this.policiesForm);
    const url = 'Enrollment/EntityCodesLookup/' + this.EntityId + '/EnrollmentTerminationCode';
    const observ$ = this.clientService.getWithUrl(url, {}).pipe(untilComponentDestroyed(this));
    observ$.subscribe((data) => {
      this.dropReason = data;
    });
  }

  getObjects(obj) {
    const ret: any = [];
    // tslint:disable-next-line:forin
    for (const property in obj) {
      if (property !== 'uiControlType') {
        ret.push(obj[property]);
      }
    }
    return ret;
  }

  getLevel(level) {
    if (level === 'b99eca96-0b42-493c-85c5-406b698570a1') {
      return 'Standard';
    }
    if (level === '0756c22b-276f-406c-801b-8e87725eb8a0') {
      return 'Orthodontics';
    }
    return null;
  }

  check(obj) {
    if (this.policiesForm.value[obj.benefitEntityPolicyId] != null) {
      if (this.policiesForm.value[obj.benefitEntityPolicyId].tiers != null && this.policiesForm.value[obj.benefitEntityPolicyId].tiers.rate != null) {
        // return this.policiesForm.value[obj.benefitEntityPolicyId].tiers.rate;
        return this.policiesForm.value[obj.benefitEntityPolicyId];
      }
      if (this.policiesForm.value[obj.benefitEntityPolicyId].tiercoverage != null) {
        for (const property1 in this.policiesForm.value[obj.benefitEntityPolicyId].tiercoverage) {
          if (property1 !== 'uiControlType' && property1 !== 'benefitEntityPolicyDetailId') {
            if (property1 === 'enrolled' && this.policiesForm.value[obj.benefitEntityPolicyId].tiercoverage[property1] === true) {
              return this.policiesForm.value[obj.benefitEntityPolicyId];
            }
          }
        }
      }
    }
    return null;
  }

  checkAccess(Policy, type) {
    let result = true;
    // tslint:disable-next-line: max-line-length
    if (Policy.reistrictedOperation != null && Policy.reistrictedOperation.length > 0) {
      if (type === 'modify') {
        for (const entry in Policy.reistrictedOperation) {
          if (entry === 'change') {
            result = false;
          }
        }
      } else if (type === 'add') {
        for (const entry in Policy.reistrictedOperation) {
          if (entry === 'add') {
            result = false;
          }
        }
      } else if (type === 'drop') {
        for (const entry in Policy.reistrictedOperation) {
          if (entry === 'drop') {
            result = false;
          }
        }
      } else if (type === 'all') {
        for (const entry in Policy.reistrictedOperation) {
          if (entry === 'all') {
            result = false;
          }
        }
      }
    }
    return result;
  }

  checkCurrentEnrolled() {
    const enrolledPolicies = this.policies.filter((p) => {
      if (p.currentEnrollment == null) {
        return false;
      } else {
        return true;
      }
    });
    if (enrolledPolicies.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  checkEnrolled() {
    let enrolled: boolean | null = false;
    const enrollment = this.policiesForm.value;
    for (const property in enrollment) {
      if ((enrollment[property].tiers != null) && (enrollment[property].tiers.tier || enrollment[property].tiers.rate != null)) {
        enrolled = true;
        break;
      }
      if ((enrollment[property].tiers != null) && enrollment[property].tiers.uiControlType != null) {
        for (const tierproperty in enrollment[property].tiers) {
          if (tierproperty !== 'uiControlType') {
            if (enrollment[property].tiers[tierproperty] != null && enrollment[property].tiers[tierproperty].selected === true) {
              enrolled = true;
              break;
            }
          }
        }
      }
      if ((enrollment[property].tiercoverage != null)) {
        for (const property1 in enrollment[property].tiercoverage) {
          if (property1 !== 'uiControlType' && property1 !== 'benefitEntityPolicyDetailId') {
            if (property1 === 'enrolled') {
              if (enrollment[property].tiercoverage[property1] === true) {
                enrolled = true;
                break;
              }
            } else {
              /* if (enrollment[property].tiercoverage[property1].rate !== '') {
                enrolled = true;
                break;
              } */
            }
          }
        }
      }
    }
    return enrolled;
  }

  checkEntity(obj) {
    if (this.policiesForm.value[obj.benefitEntityPolicyId] != null) {
      if (this.policiesForm.value[obj.benefitEntityPolicyId].tiers.rate != null) {
        // return this.policiesForm.value[obj.benefitEntityPolicyId].tiers.rate;
        return this.policiesForm.value[obj.benefitEntityPolicyId];
      }
    }
    return null;
  }

  formInitialized(name: string, form: UntypedFormGroup) {
    setTimeout(() => {
      this.policiesForm.setControl(name, form);
    });
  }

  subCost() {
    let sum = 0;
    for (const property in this.policiesForm.value) {
      if (this.policiesForm.value.hasOwnProperty(property)) {
        const tiers = this.policiesForm.value[property].tiers;
        if (tiers) {
          if (tiers.uiControlType != null) {
            for (const tierproperty in tiers) {
              if (tierproperty !== 'uiControlType') {
                if (tiers[tierproperty] != null && tiers[tierproperty].selected === true) {
                  const rate = tiers[tierproperty].rate;
                  sum = sum + rate;
                }
              }
            }
          } else {
            const tier = tiers.tier;
            const rate = tiers.rate;
            if (tier && rate) {
              sum = sum + rate;
            }
          }
        } else {
          const tiercoverage = this.policiesForm.value[property].tiercoverage;
          if (tiercoverage) {
            if (tiercoverage.enrolled === true) {
              sum = sum + tiercoverage.rate;
            }
          }
        }
      }
    }
    if (sum === 0) {
      return '';
    }
    return sum;
  }
}