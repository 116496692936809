import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClientService } from '@services/client/client.service';
// import { ResetPasswordUser } from '../shared/interfaces';

@Component({
  selector: 'app-retrieve-username',
  templateUrl: './retrieve-username.component.html',
  styleUrls: ['./retrieve-username.component.scss']
})
export class RetrieveUsernameComponent implements OnInit {
  config = window['___alliant-config-eniac-APP___']
  phoneNum = this.config.phoneNum
  primaryColor = this.config.primaryColor
  bgImage = this.config.bgImage
  retriveUsernameForm: UntypedFormGroup
  validUser:any;
  SecurityQuestionId: string
  SecurityQuestionText: string
  username: string = ''
  email: any;
  alertRed: boolean = false
  alertMessage: any = ''
  // entityClientId: string = '42445106-54b5-44d3-a134-a2dfa7837c17'   //LEEBA  hardcoded value
  // entityId: string = 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b'   //LEEBA ClientID hardcoded value
  entityId: string = '9808F856-F047-4843-BEC8-277E414FC649'   //TMAIT  hardcoded value
  entityClientId: string = '00000000-0000-0000-0000-000000000000'   //TMAIT ClientID hardcoded value


  constructor(
    private router: Router,
    protected clientService: ClientService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.retriveUsernameForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [ Validators.required, Validators.email])
    })
  }

  openDialog() {
    this.dialog.open(DialogElementsRetrivePopup, {
      data: {
        alertMessage: this.alertMessage
      }
    });
  }

  onSubmit() {
    const userToRetrive = {
      username: '',
      email: this.retriveUsernameForm.value.email
    }
      
    if (this.retriveUsernameForm.valid) {
      // const observ$ = this.clientService.postWithUrl({ url: `/CaseManagement/SecurityQuestion/${this.entityId}/${this.entityClientId}`, dto: userToRetrive });
      const observ$ = this.clientService.postWithUrl({ url: `/CaseManagement/SecurityQuestion/${this.entityId}/${this.entityClientId}`, dto: userToRetrive });
      observ$.subscribe(res => {
        this.SecurityQuestionId = res[0].securityQuestionId
        this.SecurityQuestionText = res[0].securityQuestionText
        this.email = this.retriveUsernameForm.value.email
        this.router.navigate(['/retrieve_submit'], { queryParams: { QId: this.SecurityQuestionId, sqt: this.SecurityQuestionText,  ml: this.email}} )
      }, err => {
        this.alertMessage = err.error
        this.openDialog()
      })
  } else {
    this.retriveUsernameForm.markAllAsTouched()
  }
}
}

@Component({
  selector: 'finish-popup',
  templateUrl: 'finish-popup.html',
})
export class DialogElementsRetrivePopup { 
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}
}  
