import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SpinnerService } from '@shared/services/spinner.service';
import { TestTabsComponent } from '@modules/main/client-management/filemanagement/tab.component';

@Component({
  selector: 'app-filelist',
  templateUrl: './filemanagement.component.html',
})

export class FileListComponent extends BaseComponent implements OnInit {
  dateFormat = 'MM/dd/yyyy';
  selectedDateRange;
  rows;
  listOfData;
  selectedEntity;
  loading;
  selectedType;
  entities;
  isVisible = false;
  selectedProcessTypeId;
  constructor(
    private notification: NzNotificationService,
    private clientService: ClientService,
    public dialog: MatDialog,
    private spinner: SpinnerService
  ) {
    super();
  }
  addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }
  calDate() {
    var today = new Date();
    const startDayAdjust = (today.getDay() - 1) * -1;
    let endDayAdjust = 0;
    switch (today.getDay()) {
      case 1:
        endDayAdjust = 6;
        break;
      case 2:
        endDayAdjust = 5;
        break;
      case 3:
        endDayAdjust = 4;
        break;
      case 4:
        endDayAdjust = 3;
        break;
      case 5:
        endDayAdjust = 2;
        break;
      case 6:
        endDayAdjust = 1;
        break;
    }
    var startDate = this.addDays(today, startDayAdjust);
    var endDate = this.addDays(today, endDayAdjust);
    return [startDate, endDate];
  }
  ngOnInit() {
    const curr = new Date(); // get current date
    const first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day + 6
    const firstday = new Date(curr.setDate(first));
    const lastday = new Date(curr.setDate(last));
    //this.selectedDateRange = [firstday, lastday];
    this.selectedDateRange = this.calDate();
    let url = 'FileManageMent/getProcessType';

    const observ$ = this.clientService
      .getWithUrl(url, {})
      .pipe(untilComponentDestroyed(this));
    observ$.subscribe((data) => {
      this.listOfData = data.details;
    });
    url = 'Entity/Entities';
    const observ1$ = this.clientService.getWithUrl(url, {});
    observ1$.subscribe((data) => {
      this.entities = data;
    });
    url = 'FileManageMent/getStartEndDate';

    const observ2$ = this.clientService
      .getWithUrl(url, {})
      .pipe(untilComponentDestroyed(this));
    observ2$.subscribe((data) => {
      // this.listOfData = data.details;
      const first = new Date(data[0]);
      const last = new Date(data[1]);
      this.selectedDateRange = [first, last];
    });
  }
  actionPopup(item) {
    // this.isVisible = true;
    // return;
    this.selectedProcessTypeId = item.processTypeId;
    const dialogRef = this.dialog.open(TestTabsComponent, {
      width: '95%',
      height: '95%',
      data: {
        processTypeId: this.selectedProcessTypeId,
        scheduleRunnerId: item.scheduleRunnerId,
        processId: item.processId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
    // this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  onSearch() {
    if (this.selectedDateRange != null && this.selectedEntity != null) {
      // tslint:disable-next-line:max-line-length
      const myurl =
        'FileManageMent/getView?entityId=' +
        this.selectedEntity +
        '&From=' +
        this.selectedDateRange[0].toLocaleDateString('en-US') +
        '&To=' +
        this.selectedDateRange[1].toLocaleDateString('en-US') +
        '&processType=' +
        this.selectedType;

      const observ$ = this.clientService
        .getWithUrl(myurl, {})
        .pipe(untilComponentDestroyed(this));
      this.loading = true;
      observ$.subscribe(
        (data) => {
          this.rows = data.details;
          this.loading = false;
        },
        (err) => {
          this.loading = false;
        }
      )
    }
  }
}
