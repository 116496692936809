import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-suspense-comment',
  templateUrl: './suspense-comment.component.html',
  styleUrls: ['./suspense-comment.component.scss']
})
export class SuspenseCommentComponent implements OnInit {
   
  @Input()
  suspenseComments;
  @Output()
  AddSuspensecomment = new EventEmitter<string>();
  @Output()
  moveSuspense = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

  }

  AddSuspensecommentComplete() {
    this.AddSuspensecomment.emit();
  }

  MoveSuspensecommentComplete(item) {
    this.moveSuspense.emit(item);
  }

}
