<div [formGroup]='tierForm'>
  <ng-container *ngIf="tiers ">
    <div *ngIf="benefitEntityPolicy.level && benefitEntityPolicy.level.length>1" style="padding: 10px 0px">
      <select class="form-control  input-sm col-3" formControlName="level" (change)="onChangeLevel($event)">
        <option></option>
        <option *ngFor="let opt of  benefitEntityPolicy.level " [value]="opt.levelId">{{opt.levelName}}</option>
      </select>
    </div>
    <nz-radio-group formControlName="tier" [nzButtonStyle]="'solid'" class="col-12">
      <div *ngFor="let item of tiers; ">
        <ng-container
          *ngIf='item.levelId===selectedLevel || benefitEntityPolicy.level.length===1 && EntityId!="ac586073-69d6-41bf-875c-eb754f750457"'>
          <div class="row">
            <div class="col-12" style="padding-bottom: 5px">
              <label nz-radio nzValue={{item.benefitEntityPolicyDetailId}}
                (click)='getSelected(item,item.benefitEntityPolicyDetailId,_rate)'><span
                  class="PlanText">&nbsp;{{item.benefitEntityPolicyDetailName}}</span>
              </label>
              <ng-container *ngIf="_rate?.benefitEntityPolicyDetailRates!=null">
                <ng-container *ngFor="let el of _rate?.benefitEntityPolicyDetailRates;">
                  <ng-container *ngIf="item.benefitEntityPolicyDetailId==el.benefitEntityPolicyDetailId">
                    <span style="font-size: 14px; color: rgb(155, 155, 155)"> {{payFrequency}}
                      Cost({{el.contributorCode}}): </span>
                    <span *ngIf='findSelected(item.benefitEntityPolicyDetailId)===true && (rateLookpType=="MTRT" || rateLookpType=="MT")'>($)
                      <app-typeahead formControlName='{{"rate"+el.contributorCode}}' [noclass]='"yes"'
                        [init]='getRate(el,item.benefitEntityPolicyDetailId,item.tierName)'
                        (changed)="modelChanged($event)" [size]="6">
                      </app-typeahead>
                      <!-- <input type="text" [value]='getRate(el,item.benefitEntityPolicyDetailId,item.tierName)'  (change)="modelChanged($event.target.value)"> -->
                    </span>
                    <!-- <span *ngIf='rateLookpType!="MTRT" && rateLookpType!="MT"' style="font-size: 16px; color:green"> {{getRate(_rate,item.benefitEntityPolicyDetailId,item.tierName) | currency}}</span> -->
                    <span *ngIf='rateLookpType!="MTRT" && rateLookpType!="MT"' style="font-size: 16px; color:green">
                      {{el.rate | currency}}</span>&nbsp;
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="_rate?.benefitEntityPolicyDetailRates==null">
                <span *ngIf='findSelected(item.benefitEntityPolicyDetailId)===true && (rateLookpType=="MTRT" || rateLookpType=="MT")'>($)
                  <app-typeahead formControlName='ratePR' [noclass]='"yes"' (changed)="modelChanged($event)" [size]="6">
                  </app-typeahead>
                  <!-- <input type="text" [value]='getRate(el,item.benefitEntityPolicyDetailId,item.tierName)'  (change)="modelChanged($event.target.value)"> -->
                </span>

                <!-- <span *ngIf='rateLookpType!="MTRT" && rateLookpType!="MT"' style="font-size: 16px; color:green"> {{getRate(_rate,item.benefitEntityPolicyDetailId,item.tierName) | currency}}</span> -->
                <!-- <span *ngIf='rateLookpType!="MTRT" && rateLookpType!="MT"' style="font-size: 16px; color:green"> {{el.rate | currency}}</span>&nbsp; -->
              </ng-container>
            </div>
          </div>

          <div *ngIf='findSelected(item.benefitEntityPolicyDetailId)===true'>
            <!--   <div *ngIf = 'item.dependentRules!=null'>
                   {{checkdeprule(item.dependentRules)}}
                 </div> -->
            <app-member-coverage *ngIf='item.coverages && (item.uiControlType===null || item.uiControlType=="dropdown")'
              [coverages]='item.coverages' [coverageValue]='coverage' (changeCov)='onChange($event)'>
            </app-member-coverage>
            <div *ngIf='item.coverages && item.uiControlType=="object"'>
              <app-member-tier-coverage [dropReason]='dropReason' [tiers]='[item]'
                [currentEnrollment]='currentEnrollment' [benefitEntityPolicy]='benefitEntityPolicy'
                (formReady)="formInitialized('tiercoverage', $event)" [coverage]='coverage' [inTier]="'true'"
                (change1)='onChangeTierCoverage($event)'>
              </app-member-tier-coverage>
            </div>
            <div
              *ngIf='item.coverages && item.uiControlType=="checkbox" && item.uiControlText=="self-benefitentitypolicydetailcoveragename"'>
              <app-member-tier-coverage [dropReason]='dropReason' [tiers]='[item]'
                [currentEnrollment]='currentEnrollment' [benefitEntityPolicy]='benefitEntityPolicy'
                (formReady)="formInitialized('tiercoverage', $event)" [coverage]='coverage' [inTier]="'true'"
                (change1)='onChangeTierCoverage($event)'>
              </app-member-tier-coverage>
            </div>
            <app-dependentbeneficiaries [tierName]='item.tierName' [entityId]='EntityId' [clientId]='ClientId'
              [memberId]='MemberId' [enrolled]='enrolled' [dependentRules]='item.dependentRules'
              [benefitEntityPolicy]='benefitEntityPolicy' (changeDep)='onChangeDep(item)'
              [beneficiaries]='beneficiaries'>
            </app-dependentbeneficiaries>
          </div>
        </ng-container>
      </div>
      <label nz-radio nzValue='' (click)='getOpted()'><span class="PlanText">&nbsp;Opt out of the plan</span></label>
      <div *ngIf='findSelected("")===true'>
        Reason: <select class="form-control  input-sm col-3" formControlName="optReason">
          <option></option>
          <option *ngFor="let item of dropReason | orderBy : 'name'; " [value]="item.codeLookupId">{{item.name}}
          </option>
        </select>
      </div>
    </nz-radio-group>
    <!--  <h2 class='mat-h2'>Form Value tier</h2>
      <pre>
        {{tierForm.value | json}}
      </pre> -->
  </ng-container>
</div>