import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DirectivesModule } from '@shared/directives/directives.module';
// import { AMatAllModule } from './angular-material/amat-all.module';
import { ComponentsModule  } from '@shared/components/components.module';

const MODULES = [
  CommonModule,
  FormsModule,
  // AMatAllModule,
  ReactiveFormsModule,
  NgSelectModule,
  PipesModule,
  DirectivesModule,
  ComponentsModule,
  
];

const COMPONENTS = [];

@NgModule({
  imports: [MODULES],
  declarations: [COMPONENTS],
  exports: [...MODULES, ...COMPONENTS]
})
export class UiModule {}
