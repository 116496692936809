<h1>Angular 7 FormData (multipart/data-form) Example</h1>
<div>
    <form [formGroup] = "uploadForm" (ngSubmit)="onSubmit()">      
      <div>
        <input type="file" name="profile" (change)="onFileSelect($event)" />
      </div>
      <div>
        <button type="submit">Upload</button>
      </div>
    </form>
  </div>
