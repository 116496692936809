import { ErrorType } from '@store/app.state';
export class MainState {
    loading = false;
    saving = false;
    deleting = false;
    loadError?: ErrorType;
    saveError?: ErrorType;
    deleteError?: ErrorType;
    savedObj?: any;
}
