import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthRequestDTO, AuthSuccessDTO } from '@models/auth';
import { LocalStorageService } from '@shared/storage/local-storage.service';
import { StorageKeys } from '@shared/storage/storage-keys';
import { createUriEncoded } from '@shared/api/url-utils';
import { HttpService } from '@shared/api/http.service';
import { mergeMap } from 'rxjs/operators';
import { HttpRequestOptions } from '@shared/api/shared';
import { ConfigService } from '@shared/config.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private authenticateUrl = 'token'
  logintype: string
  authService: any
  constructor(
    private http: HttpService,
    private storageService: LocalStorageService,
    private configService: ConfigService
  ) {
  }
  authenticateSSO(request: AuthRequestDTO): Observable<any> {
    let contetType = 'application/json'
    const options: HttpRequestOptions = {
      isMock: false,
      skipErrorInterceptor: true,
      headers: {
        'Content-Type': contetType,
      }
    };
    return this.http.get('Login/ConsoleSSO?abcssotoken='+ request.ABCSSOToken +'&appId=' + request.appid,{ options })
  }
  authenticate(request: AuthRequestDTO): Observable<any> {
    this.isLogintype()
    let body
    let contetType
    this.authService = this.configService.api.authService
    if (this.logintype === 'clienttracking') {
      body = {
        email: request.email,
        password: request.password
      }
      contetType = 'application/json'
      this.authenticateUrl =  this.authService.authenticateCatUrl
    } else {
      if (request.refresh_token != null) {
        body = createUriEncoded({ grant_type: 'refresh_token', ...request })
      }
      else{
        body = createUriEncoded({ grant_type: 'password', ...request })
      }
      contetType = 'application/x-www-form-urlencoded'
      this.authenticateUrl =  this.authService.authenticateUrl
    }
   
    const options: HttpRequestOptions = {
      isMock: false,
      skipErrorInterceptor: true,
      headers: {
        'Content-Type': contetType,
      }
    };

    if (this.logintype === 'testusertool'  ) {
      if(request.refresh_token != null){
        body = createUriEncoded({ grant_type: 'refresh_token', ...request })
      } else{
        return this.http.get('Login/ConsoleSSO?abcssotoken='+ request.ABCSSOToken +'&appId=' + request.appid,{ options })
      }
    }else{
      return this.http.post(this.authenticateUrl, { body, options })
    }
   
  }

  checkCredentials(request: AuthRequestDTO): Observable<boolean> {
    return this.authenticate(request).pipe(
      mergeMap(response => {
        return of(
          !!response &&
          !!response.access_token &&
          !!response.userName &&
          !!response.employeeguid
        );
      })
    );
  }

  getClientId() {
    const auth = this.getAuth()
    return auth ? auth.clientId : null
  }

  isAuthenticated(): boolean {
    return !!this.getAccessToken()
  }

  getUserId(): string | null {
    const auth = this.getAuth()
    return auth ? auth.Userid : null
  }

  getParticipantId(): string | null {
    const auth = this.getAuth()
    return auth ? auth.ParticipantId : null
  }

  getUserRole(): string | null {
    const auth = this.getAuth()
    return auth ? auth.role : null
  }

  getUsername(): string | null {
    const auth = this.getAuth()
    return auth ? auth.username : null
  }

  getAccessToken(): string | null {
    const auth = this.getAuth()
    return auth ? auth.access_token : null
  }

  getRefreshToken(): string | null {
    const auth = this.getAuth()
    return auth ? auth.refresh_token : null
  }

  gettokenexpirytime(): number {
    const auth = this.getAuth()
    return auth ? auth.expires_in : 900
  }


  getEntityList(): string | null {
    const auth = this.getAuth()
    return auth ? auth.entityList : null
  }

  getIsPasswordReset(): string | null {
    const auth = this.getAuth()
    return auth ? auth.IsPasswordReset : null
  }

  setAuth(token: AuthSuccessDTO) {
    this.storageService.setItem(
      StorageKeys.AUTH_RESPONSE,
      JSON.stringify(token)
    );
  }

  getAuth(): AuthSuccessDTO | null {
    const authResponse = this.storageService.getItem(StorageKeys.AUTH_RESPONSE)
    if (!authResponse) {
      return null
    }

    // JSON.parse might fail if the value being parsed is not a valid json
    try {
      const auth = JSON.parse(authResponse) as AuthSuccessDTO
      return auth || null
    } catch (ex) {
      return null
    }
  }

  removeAuth() {
    this.storageService.removeItem(StorageKeys.AUTH_RESPONSE)
  }

  isLogintype() {
    let config = window['___alliant-config-eniac-APP___']
    this.logintype = config.logintype
  }
}