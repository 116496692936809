<ng-container [formGroup]="emailFormGroup">
  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label>Email:
        <input type="text"
               class="form-control"
               formControlName="emailAddress">
      </label>
    </div>
  </div>
</ng-container>