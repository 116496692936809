import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { LoggerService } from '@shared/logging/logger.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { MemberState } from '@store/main/member/member.state';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import {
  SaveMemberTypeAction,
  GetMemberUrlAction,
  ResetMemberAction,
} from '@store/main/member/member.actions';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  isMemberStateMutating,
  getMemberState,
} from '@store/main/member/member.selector';
import { MemberService } from '@services/client/member.service';
import { ConfigService } from '@shared/config.service';
import { ClientService } from '@services/client/client.service';
import { EnrollmentService } from './enrollment.service';

@Component({
  selector: 'app-member-plans',
  templateUrl: './dynamicplancontainner.component.html',
  styleUrls: ['./../dynamic.component.scss'],
})
export class DynamicPlansContainnerComponent
  extends BaseComponent
  implements OnInit
{
  memberState: MemberState | null;
  EntityId: string;
  ClientId: string;
  MemberId: string;
  entityclientId;
  _jsonURL;
  plans;
  saving;
  saveSuccess;
  formValue;
  policies;
  initLoad = true;
  policypart;
  policiesForm: UntypedFormGroup;
  formJasonValue;
  successMessage = 'Record saved successfully';
  initloadpolicy = true;
  numloaded = 0;
  _this;

  private memberApiUrls: any;
  constructor(
    private configService: ConfigService,
    protected fb: UntypedFormBuilder,
    protected http: HttpClient,
    public store: Store<AppState>,
    protected notification: NzNotificationService,
    private memberService: MemberService,
    private route: ActivatedRoute,
    private routeHelper: RouteHelperService,
    private logger: LoggerService,
    private router: Router,
    protected clientService: ClientService,
    public spinner: SpinnerService,
    public enrollmentService: EnrollmentService
  ) {
    super();
    this.store.dispatch(
      new ResetMemberAction({ MemberProp: 'benefitEntityPolicies' })
    );
    this.memberApiUrls = this.configService.api.memberService;
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.ClientId = '00000000-0000-0000-0000-000000000000'; // this.routeHelper.getClientId(this.route);
    this.MemberId = this.routeHelper.getMemberId(this.route);
    this.entityclientId = this.routeHelper.getEntityClientId(this.route);
    this.subscribeMemberState();
    const url = this.memberApiUrls.benefitVendorBenefitEntities;
    this.store.dispatch(
      new GetMemberUrlAction({
        Url: url,
        Prop: 'benefitEntities',
        EntityId: this.EntityId,
        ClientId: this.ClientId,
        MemberId: this.MemberId,
        entityclientId: this.entityclientId,
      })
    );
    this._this = this;
  }

  subscribeMemberState() {
    this.store
      .select(getMemberState)
      .pipe(untilComponentDestroyed(this))
      .subscribe((x) => this.memberStateChange(x));
  }
  
  memberStateChange(state: MemberState) {
    this.memberState = state;
    if (this.memberState && this.memberState.member) {
      if (
        this.saving &&
        !this.memberState.saving &&
        this.memberState.saveError == null
      ) {
        this.saveSuccess = true;
        this.saving = false;
        this.notification.blank(
          'Enrollment is successfully Submitted and Saved!',
          ' '
        );
      }
      if (this.memberState.member['benefitEntities']) {
        this.formValue =
          this.memberState.member['benefitEntities'].benefitEntities;

        if (this.memberState.member['benefitEntityPolicies']) {
          this.policies =
            this.memberState.member[
              'benefitEntityPolicies'
            ].benefitEntityPolicies;
        }
        if (this.initLoad) {
          this.initLoad = false;
          const url = this.memberApiUrls.benefitEntityPolicies;
          /*  this.store.dispatch(new GetMemberUrlAction({ Url: url,
           Prop: 'benefitEntityPolicies',
           EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId, entityclientId: this.entityclientId }));*/
        }
        if (this.policies && this.initloadpolicy) {
        }
      }
    }
    if (!isMemberStateMutating(this.memberState)) {
      this.spinner.hide();
    }
  }

  ngOnInit() {
    this.policiesForm = this.fb.group({});
  }

  formInitialized(name: string, form: UntypedFormGroup) {
    if (this.policiesForm.value[name] == null) {
      // get form init value
      const url = this.memberApiUrls.enrollmentSummary;
      this.memberService
        .getMemberUrl(url, {
          EntityId: this.EntityId,
          ClientId: this.ClientId,
          ParticipantId: this.MemberId,
          BenefitEntityPolicyId: name,
        })
        .pipe(untilComponentDestroyed(this))
        .subscribe(
          (rep) => {
            this.numloaded++;
            const cartItems = rep.enrollmentSummary;
            if (cartItems.length !== 0) {
              const dependents = cartItems[0].dependentBenefits;
              const enrolled: any = [];
              if (dependents) {
                dependents.forEach((element) => {
                  if (element.dependentId) {
                    enrolled.push(element.dependentId);
                  }

                  // enrolled.push('a3515323-868c-450c-a78b-eef58219fe22');
                });
              }
              setTimeout(() => {
                this.policiesForm.value[name].tiers = {
                  oldTier: cartItems[0].benefitEntityPolicyDetailId,
                  tier: cartItems[0].benefitEntityPolicyDetailId,
                  tierName: cartItems[0].tierName,
                  rate: cartItems[0].rate,
                  enrolled: enrolled,
                  beneficiaries: [],
                  coverages: {
                    coverage: cartItems[0].coverageValue,
                  },
                };
                this.policiesForm.value[name].coverages = {
                  coverage: cartItems[0].coverageValue,
                };
                this.policiesForm.patchValue(this.policiesForm.value);
              });
              if (this.initloadpolicy) {
                if (
                  this.numloaded === this.policies.length &&
                  this.initloadpolicy
                ) {
                  const bEntity = this.formValue[0].benefitEntityId;
                  this.onEntityChange(bEntity);
                }
              }
            } else {
              if (
                this.policies.length === this.numloaded &&
                this.initloadpolicy
              ) {
                const bEntity = this.formValue[0].benefitEntityId;
                this.onEntityChange(bEntity);
              }
            }
          },
          (err) => {
            this.numloaded++;
            if (
              this.policies.length === this.numloaded &&
              this.initloadpolicy
            ) {
              const bEntity = this.formValue[0].benefitEntityId;
              this.onEntityChange(bEntity);
            }
          }
        );
    } else {
      if (this.initloadpolicy) {
        setTimeout(() => {
          this.initloadpolicy = false;
        });
      }
    }

    setTimeout(() => {
      this.policiesForm.setControl(name, form);
    });
  }

  onConfirm() {
    const cartItems: any = [];
    const enrollment = this.policiesForm.value;
    let DependentIds: any = [];
    for (const property in enrollment) {
      if (enrollment.hasOwnProperty(property)) {
        let benefitEntityPolicyDetailId = null;
        let mode = 'add';
        let oldTier = null;
        let IsEmployeeOptedOut = false;
        let IsUseMemberTableRate = false;
        let CustomRate = '';
        if (
          enrollment[property].tiers.tier ||
          enrollment[property].tiers.tier === ''
        ) {
          benefitEntityPolicyDetailId = enrollment[property].tiers.tier;
          DependentIds = enrollment[property].tiers.enrolled;
          if (benefitEntityPolicyDetailId === '') {
            IsEmployeeOptedOut = true;
          }
          const rate = enrollment[property].tiers.rate;
          const custrate = enrollment[property].tiers.custrate;
          if (rate !== custrate) {
            IsUseMemberTableRate = true;
            CustomRate = custrate;
          }
          oldTier = enrollment[property].tiers.oldTier;
          if (oldTier) {
            mode = 'change';
          }
        }
        const coverageValue = enrollment[property].coverages.coverage;
        const policy = {
          benefitEntityPolicyId: property,
          oldBenefitEntityPolicyDetailId: oldTier,
          benefitEntityPolicyDetailId: benefitEntityPolicyDetailId,
          coverageValue,
          enrollmentTypeCode: mode,
          IsEmployeeOptedOut: IsEmployeeOptedOut,
          IsUseMemberTableRate,
          CustomRate,
          DependentIds,
          remarkTypeId: '',
          remarks: '',
          enrollmentEffectiveDate: '',
        };
        if (benefitEntityPolicyDetailId || benefitEntityPolicyDetailId === '') {
          cartItems.push(policy);
        }
      }
    }
    const apiObj = {
      entityId: this.EntityId,
      participantId: this.MemberId,
      clientId: this.ClientId,
      cartItems,
    };
    this.store.dispatch(
      new SaveMemberTypeAction({ Url: 'Enrollment//Enrollment', Data: apiObj })
    );
    this.saving = true;
  }

  onEntityChange(id) {
    this.policiesForm.patchValue(this.policiesForm.value);
    const url = this.memberApiUrls.benefitEntityPolicies;
    this.store.dispatch(
      new GetMemberUrlAction({
        Url: url,
        Prop: 'benefitEntityPolicies',
        EntityId: this.EntityId,
        ClientId: this.ClientId,
        MemberId: this.MemberId,
        BenefitEntityId: id,
      })
    );
  }
}
