import { HttpRequest } from '@angular/common/http';
import * as faker from 'faker';
import * as _ from 'lodash';
import {
  MockRequestHandler,
  MockRequestHandlerParams
} from '@mock/mock-data/shared/mock-handler.interface';
import {
  MemberListItemDTO,
  MemberListDTO
} from '@models/member-list/member-list.dto';

export class MemberListMockHandler extends MockRequestHandler<MemberListDTO> {
  handleRequest(params: MockRequestHandlerParams): MemberListDTO {
    const req = params.reqInfo.req as HttpRequest<any>;
    const body = req.body;
    const entityId = body.entityId;
    const clientId = body.clientId;
    const pageIndex = body.pageIndex || 0;
    const pageSize = body.pageSize || 10;
    const totalRowsCount = body.totalRowsCount || 1000;
    const totalPageCount =
      body.totalPageCount || Math.ceil(totalRowsCount / pageSize);

    return {
      entityId,
      pageSize,
      pageIndex,
      totalRowsCount,
      totalPageCount,
      participantList: _.range(pageSize).map(() =>
        createMockMemberLookupDTO({ entityId, clientId })
      )
    };
  }
}

export function createMockMemberLookupDTO(params: {
  entityId: string;
  clientId: string;
}): MemberListItemDTO {
  const myArrayStatus = ['Active', 'Retiree', 'Leave'];
  const myArrayBen = [
    'Auto home, CI and Accident',
    'Hospital Indemnity, Legal',
    'Vision',
    'Life',
    'Auto Home'
  ];

  return {
    participantId: faker.random.uuid(),
    idNumber: faker.random.uuid(),
    entityId: params.entityId,
    clientId: params.clientId || faker.random.uuid(),
    entityClientId: faker.random.uuid(),
    entityClientName: 'Lennox School District',
    clientNumber: '040574',
    entityClientNumber: '0001',
    ssnumber: faker.random
      .number({ min: 100000000, max: 999999999 })
      .toString(),
    lastName: faker.name.lastName(1),
    firstName: faker.name.firstName(1),
    middleInitial: 'X',
    birthDate: faker.date.past(50),
    hireDate: faker.date.past(10),
    gender: 'M',
    employmentStatus:
      myArrayStatus[Math.floor(Math.random() * myArrayStatus.length)],
    employmentStatusText: 'Active',
    employmentTitle: '',
    benefitSummary: ''
  };
}
