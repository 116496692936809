import * as _ from 'lodash';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormArray,
  AbstractControl,
  UntypedFormControl
} from '@angular/forms';
import { Injectable } from '@angular/core';

export const FORM_CONTROL_ERROR_KEY = 'validationErrors';
export class FormError {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {
  constructor(private fb: UntypedFormBuilder) {}

  createBankAccountFormGroup(): UntypedFormGroup {
    return this.fb.group({
      bankId: [''],
      accountType: [null],
      accountNumber: ['']
    });
  }

  createPhoneFormGroup(): UntypedFormGroup {
    return this.fb.group({
      phoneNumber: [''],
      phoneType: [null],
      extension: ['']
    });
  }

  createEmailFormGroup(): UntypedFormGroup {
    return this.fb.group({
      emailAddress: [''],
      emailType: []
    });
  }

  createAddressFormGroup(): UntypedFormGroup {
    return this.fb.group({
      address1: [''],
      address2: [''],
      city: [''],
      state: [null],
      zip: [''],
      stateCode: [],
      countyCode: []
    });
  }

  createSalaryMultiplierFormGroup(): any {
    return this.fb.group({
      orderNumber: [''],
      multiplier: ['']
    });
  }

  createAgeReductionFormGroup(): any {
    return this.fb.group({
      age: [''],
      value: [''],
      percent: [''],
      minimum: ['']
    });
  }

  getFormError(form: UntypedFormGroup | UntypedFormArray): FormError | null {
    if (!form || form.valid) {
      return null;
    }

    const formError: FormError = {};

    _.each(form.controls, (control: AbstractControl, controlName: string) => {
      if (control instanceof UntypedFormControl && control.errors) {
        formError[controlName] = {
          [FORM_CONTROL_ERROR_KEY]: control.errors
        };
        return;
      }

      if (control instanceof UntypedFormGroup) {
        const errors = this.getFormError(control);
        if (errors) {
          formError[controlName] = errors;
        }
        return;
      }

      if (control instanceof UntypedFormArray) {
        const errors = this.getFormError(control);
        if (errors) {
          formError[controlName] = errors;
        }
        return;
      }
    });

    return formError;
  }
}
