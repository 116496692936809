import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DynamicDialogGridComponent } from '@shared/components/dynamic_grid/dynamic-grid.component';
@Component({

  templateUrl: './rpthistory.component.html',
})
export class RptHistoryComponent extends DynamicDialogGridComponent {
  protected selection(item) {
    this.dialogRef.close(item);
  }
  protected onload() {
    const tt = this.rows;
    this.rows = this.rows.slice(1, 1000);
    this.rows = this.rows.map(x => {
      const json = JSON.parse((<any>x.objectValue) as string);
      const obj = {
        filter: json.ReportFilters,
        userId: x.userId,
        createdDate: x.createdDate
      };
      this.orirows = [...this.rows];
      return obj;
    })
  }
}