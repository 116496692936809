import { Component, OnInit } from '@angular/core';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { CLIENT_ROUTE_SEGMENT } from '@shared/route/constants';
import { BaseComponent } from '@shared/components/base.component';
import { AppState } from '@store/app.state';
import { Store } from '@ngrx/store';
import { AuthService } from '@services/auth/auth.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { ClientService } from '@services/client/client.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})

export class AdminDashboardComponent extends BaseComponent implements OnInit {
  readonly CLIENT_ROUTE_SEGMENT = CLIENT_ROUTE_SEGMENT;
  IsPasswordReset;
  clientsRoute: string;
  memberLookupRoute: string;
  entityList;
  userName;
  userId;
  entities;
  legal;
  onlyone;
  reportpath;
  logintype;
  filterText: string
  private _jsonURL = 'assets/users.json';
  constructor(
    private http: HttpClient,
    protected spinner: SpinnerService,
    protected clientService: ClientService,
    private authService: AuthService,
    private routeHelper: RouteHelperService,
    private store: Store<AppState>) {
    super();
  }
  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  ngOnInit() {
    const config = window['___alliant-config-eniac-APP___']
    this.logintype = config.logintype
    this.memberLookupRoute = this.routeHelper.getMemberLookupRoute();
    this.entityList = this.authService.getEntityList();
    this.IsPasswordReset = this.authService.getIsPasswordReset();
    this.userName = this.authService.getUsername();
    this.spinner.show();

    this.getJSON().subscribe(usersdata => {
      let found = usersdata.legalusers.find(e => e == this.userName)
      if (found != null) {
        this.legal = true;
      }
    });
    const url = 'Entity/Entities';
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe(data => {
      this.spinner.hide();
      this.entities = data.sort((a, b) => a.entityName.localeCompare(b.entityName)).filter(x => x.parentId == null);
      if (data.length == 1) {
        this.onlyone = true;
        this.reportpath = ['/main/clients/' + data[0].id + '/reports'];
      }
    });
  }

  trackClient(index: number, item): any {
    return item;
  }

}
