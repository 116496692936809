<div style="padding:20px 0px 10px 20px" >
    <h2 class="member-function-header">Benefit History</h2>
</div>
<div style="padding: 0px 20px;">
      <div  *ngFor = "let item of HistoryData; " style=" padding: 25px; margin-bottom: 10px;background: rgba(72, 196, 203, 0.048) !important; border-radius: 5px;border: 1px solid rgb(86, 186, 253) ; ">
        <div style="background: rgba(72, 196, 203, 0.123) !important; padding: 10px;">
            <div *ngIf="item.benefitEntityPolicyDetailNCO != null && item.benefitEntityPolicyDetailDCO == null">
                Policy : <b>{{item.benefitEntityPolicyDetailNCO.benefitEntityPolicyDetailName}} ({{item.benefitEntityPolicyDetailNCO.levelName}})</b>
            </div>
            <div *ngIf="item.benefitEntityPolicyDetailNCO === null && item.benefitEntityPolicyDetailDCO != null">
                Policy : <b>{{item.benefitEntityPolicyDetailDCO.benefitEntityPolicyDetailName}} ({{item.benefitEntityPolicyDetailDCO.levelName}})</b>
            </div>
            <div *ngIf="item.benefitEntityPolicyDetailNCO != null && item.benefitEntityPolicyDetailDCO != null">
                Policy change from  <b>{{item.benefitEntityPolicyDetailDCO.benefitEntityPolicyDetailName}} ({{item.benefitEntityPolicyDetailDCO.levelName}})</b> to  <b>{{item.benefitEntityPolicyDetailNCO.benefitEntityPolicyDetailName}} ({{item.benefitEntityPolicyDetailNCO.levelName}}) </b>
            </div>
            <div>
                Original Effective Date : <b>{{item.originalEnrollmentEffectiveDate | date}} </b>,  
                Effective Date : <b>{{item.enrollmentEffectiveDate | date}}</b>, 
                Enrollment Date : <b>{{item.enrollmentDateTime | date}}</b>, 
                Created By : <b>{{item.createdBy}}</b>, 
                Created Date : <b>{{item.createdDate | date}}</b> <br />
                <span *ngIf="item.remarks != null && item.remarks.trim() != ''">Remarks : <b>{{item.remarks}}</b></span>
                <span *ngIf="item.remarkName != null && item.remarkName != ''">Remarks Details : <b>{{item.remarkName}}</b></span>
            </div>
        </div>
        <hr style="margin: 7px;" />
        <div *ngIf="item.participantDepedentPolicyCoverageDetails != null">
            <div   *ngFor = "let itemInner of item.participantDepedentPolicyCoverageDetails; " >
                <div style="padding: 7px 0px;border-bottom: 1px solid rgb(173, 173, 173) ;">
                    Effective Date : <b>{{itemInner.effectiveDate | date}} </b>,  Enrollment Date : <b>{{itemInner.enrollmentDate | date}} </b>,  From Date : <b>{{itemInner.fromDate | date}} </b>, To Date : <b>{{itemInner.toDate | date}} </b> <br />
                    <span *ngIf="item.isBindCoverageBindable">
                        Enrolled Coverage Value: <b>{{itemInner.enrolledCoverageValue}}</b>, Current Coverage Value: <b>{{itemInner.currentCoverageValue}}</b>, Billable Coverage Value: <b>{{itemInner.billableCoverageValue}}</b>
                    </span> <br />
                    <span *ngIf="item.isHealthPlanBindable && itemInner.isHealthPlan != null && itemInner.isHealthPlan != ''">
                        Health Plan: <b>{{itemInner.isHealthPlan}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isEmployementStatusBindable  && itemInner.employementStatus != null && itemInner.employementStatus != ''">
                        Employement Status: <b>{{itemInner.employementStatus}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isSmokerBindable  && itemInner.isSmoker != null && itemInner.isSmoker != ''">
                        Smoker: <b>{{itemInner.isSmoker}}</b>,&nbsp;
                    </span >
                    <span *ngIf="item.isSalaryBindable && itemInner.salary != null  && itemInner.salary != ''">
                        Salary: <b>{{itemInner.salary}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isRelationshipBindable && itemInner.relationship != null  && itemInner.relationship != ''">
                        Relationship: <b>{{itemInner.relationship}}</b>
                    </span>,&nbsp;
                    <span *ngIf="item.isStateBindable && itemInner.state != null && itemInner.state != ''">
                        Issue State: <b>{{itemInner.state}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isPackageCodeBindable && itemInner.packageCode != null && itemInner.packageCode != ''">
                        Package Code: <b>{{itemInner.packageCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isClassCodeBindable && itemInner.classCode != null && itemInner.classCode != ''">
                        Class Code: <b>{{itemInner.classCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isCoverageCodeBindable && itemInner.coverageCode != null  && itemInner.coverageCode != ''">
                        Coverage Code: <b>{{itemInner.coverageCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isAreaCodeBindable  && itemInner.areaCode != null  && itemInner.areaCode != ''">
                        Area Code: <b>{{itemInner.areaCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isQualificationCodeBindable && itemInner.qualificationCode != null && itemInner.qualificationCode != ''">
                        {{qualCodeText}}<b>{{itemInner.qualificationCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isPlanCodeBindable && itemInner.planCode != null  && itemInner.planCode != ''">
                        Plan Code: <b>{{itemInner.planCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isLocationCodeBindable  && itemInner.locationCode != null && itemInner.locationCode != ''">
                        Location Code: <b>{{itemInner.locationCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isCustomCovCodeBindable  && itemInner.customCovCode != null && itemInner.customCovCode != ''">
                        Custom Cov Code: <b>{{itemInner.customCovCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf="itemInner.remarks != null && itemInner.remarks != ''">Remarks : <b>{{itemInner.remarks}}</b></span>
                    <span *ngIf="itemInner.remarkName != null && itemInner.remarkName != ''">Remarks Details : <b>{{itemInner.remarkName}}</b></span>
              </div>
            </div>
        </div>
        <div *ngIf="item.participantDepedentPolicyCoverageRiders != null">
            <div   *ngFor = "let itemInner of item.participantDepedentPolicyCoverageRiders; " >
                <div style="padding: 7px 0px;border-bottom: 1px solid rgb(173, 173, 173) ;">
                    RiderName : <b>{{itemInner.riderName }} </b>,  RiderCode : <b>{{itemInner.riderCode }} </b>,  From Date : <b>{{itemInner.fromDate | date}} </b>, To Date : <b>{{itemInner.toDate | date}} </b> <br />
                    <span *ngIf="itemInner.enrolledCoverageValue">
                        Enrolled Coverage Value: <b>{{itemInner.enrolledCoverageValue}}</b>, Current Coverage Value: <b>{{itemInner.currentCoverageValue}}</b>, Billable Coverage Value: <b>{{itemInner.billableCoverageValue}}</b>
                    </span> <br />
                    
                    <span *ngIf="itemInner.employementStatus != null && itemInner.employementStatus != ''">
                        Employement Status: <b>{{itemInner.employementStatus}}</b>,&nbsp;
                    </span>
                    <span *ngIf=" itemInner.isSmoker != null && itemInner.isSmoker != ''">
                        Smoker: <b>{{itemInner.isSmoker}}</b>,&nbsp;
                    </span >
                    <span *ngIf="itemInner.salary != null  && itemInner.salary != ''">
                        Salary: <b>{{itemInner.salary}}</b>,&nbsp;
                    </span>
                    <span *ngIf="itemInner.relationship != null  && itemInner.relationship != ''">
                        Relationship: <b>{{itemInner.relationship}}</b>
                    </span>,&nbsp;
                    <span *ngIf=" itemInner.state != null && itemInner.state != ''">
                        Issue State: <b>{{itemInner.state}}</b>,&nbsp;
                    </span>
                    <span *ngIf=" itemInner.packageCode != null && itemInner.packageCode != ''">
                        Package Code: <b>{{itemInner.packageCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf=" itemInner.classCode != null && itemInner.classCode != ''">
                        Class Code: <b>{{itemInner.classCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf=" itemInner.coverageCode != null  && itemInner.coverageCode != ''">
                        Coverage Code: <b>{{itemInner.coverageCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf=" itemInner.areaCode != null  && itemInner.areaCode != ''">
                        Area Code: <b>{{itemInner.areaCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf=" itemInner.qualificationCode != null && itemInner.qualificationCode != ''">
                        {{qualCodeText}}<b>{{itemInner.qualificationCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf="item.isPlanCodeBindable && itemInner.planCode != null  && itemInner.planCode != ''">
                        Plan Code: <b>{{itemInner.planCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf=" itemInner.locationCode != null && itemInner.locationCode != ''">
                        Location Code: <b>{{itemInner.locationCode}}</b>,&nbsp;
                    </span>
                    <span *ngIf=" itemInner.customCovCode != null && itemInner.customCovCode != ''">
                        Custom Cov Code: <b>{{itemInner.customCovCode}}</b>,&nbsp;
                    </span>
              </div>
            </div>
        </div>

        <u *ngIf="item.enrollmentHistoryDendents != null && item.enrollmentHistoryDendents != ''">
            <br />Dependents
        </u>
        <div *ngIf="item.enrollmentHistoryDendents != null">
            <div *ngFor ="let itemInnerDep of item.enrollmentHistoryDendents;">
                <div>
                    First Name: <b>{{itemInnerDep.dependent.firstName}} </b>, 
                    Last Name: <b>{{itemInnerDep.dependent.lastName}} </b>, 
                    Birth Date: <b>{{itemInnerDep.dependent.birthDate | date}} </b>, 
                    Gender: <b>{{itemInnerDep.dependent.gender}}</b>, 
                    Relationship: <b>{{itemInnerDep.dependent.relationshipName}}</b>, 
                    Student: <b>{{itemInnerDep.dependent.isStudent}}</b>, 
                    Disabled: <b>{{itemInnerDep.dependent.isDisabled}}</b>, 
                    Operation: <b>{{itemInnerDep.enrollmentHistoryOperation}}</b>, 
                    Effective Date: <b>{{itemInnerDep.enrollmentEffectiveDate | date}}</b>, 
                    Enrollment Date: <b>{{itemInnerDep.enrollmentDateTime | date}}</b>
                    Remarks: <b>{{itemInnerDep.remarks}}</b>
                </div>
            </div>
        </div>

        <u *ngIf="item.enrollmentHistorEISTables != null && item.enrollmentHistorEISTables != ''"><br /> EIS Details</u>
        <div *ngIf="item.enrollmentHistorEISTables != null && item.enrollmentHistorEISTables != ''">
                <table  class="table table-bordered table-sm">
                    <tr>
                        <td>
                            Type
                        </td>
                        <td>
                            Name
                        </td>
                        <td>
                            Factor 
                        </td>
                        <td>
                            Code  
                        </td>
                        <td>
                            From Date
                        </td>
                        <td>
                            To Date
                        </td>
                        <td>
                            From Coverage Code
                        </td>
                        <td>
                            To Coverage Code
                        </td>  
                        <td>
                            From Age
                        </td>  
                        <td>
                            To Age
                        </td>  
                        <td>
                            State Code
                        </td>  
                        <td>
                            State Code Operator Id
                        </td>  
                    </tr>
                    <tr  *ngFor ="let itemInnerEIS of item.enrollmentHistorEISTables;">
                        <td>{{itemInnerEIS.configurationTypeName}} </td> 
                        <td>{{itemInnerEIS.configurationName}} </td> 
                        <td>{{itemInnerEIS.factor}} </td> 
                        <td>{{itemInnerEIS.configurationCode}}</td> 
                        <td>{{itemInnerEIS.fromDate | date}}</td> 
                        <td>{{itemInnerEIS.toDate  | date}}</td> 
                        <td>{{itemInnerEIS.fromCoverageCode}}</td> 
                        <td>{{itemInnerEIS.toCoverageCode}}</td> 
                        <td>{{itemInnerEIS.fromAge}}</td> 
                        <td>{{itemInnerEIS.toAge}}</td>
                        <td>{{itemInnerEIS.stateCode}}</td>
                        <td>{{itemInnerEIS.stateCodeOperatorId}}</td>
                    </tr>
                </table>
        </div>
      </div>
    <!-- {{HistoryData | json}}  -->
</div>
