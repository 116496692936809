<div [formGroup]='tierForm'>

  <ng-container *ngIf="tiers[0].uiControlType==='radio'">
    <nz-radio-group formControlName="tier" [nzButtonStyle]="'solid'" class="col-12">
      <div *ngFor="let item of tiers[0].coverages; ">
        <div *ngIf="tiers[0].uiControlType==='radio'">
          <label (click)='radioClick(item)' nz-radio nzValue={{item.benefitEntityPolicyDetailCoverageId}}></label><span
            class="PlanText">{{item.benefitEntityPolicyDetailCoverageName}}</span><span
            style="font-size: 14px; color: rgb(155, 155, 155)">cost:</span> <span style="font-size: 16px; color:green">
            {{getRate(item) | currency}}</span>
        </div>
        <div style="padding-left: 40px ">
          <app-member-coveragetier [coverages]='item.childCoverages'
            [enrollmentCoverageObject]='item.enrollmentCoverageObject'
            [benefitEntityPolicyDetailCoverageId]='item.benefitEntityPolicyDetailCoverageId'
            [coverageValue]='item.coverageValue' [benefitEntityPolicy]='benefitEntityPolicy'
            [benefitEntityPolicyDetailId]='tiers[0].benefitEntityPolicyDetailId'
            [name]='item.benefitEntityPolicyDetailCoverageName' [inTier]='inTier' [entityId]='EntityId'
            [clientId]='ClientId' [memberId]='MemberId' [entityClientId]='EntityClientId'
            [coverageRelationShipCodes]='item.coverageRelationShipCodes'
            (formReady)="formInitialized(item.benefitEntityPolicyDetailCoverageId, $event)"
            (change)='onChange($event, item)'></app-member-coveragetier>
        </div>
      </div>
    </nz-radio-group>
  </ng-container>

  <ng-container *ngIf="tiers[0].uiControlType!=='radio'">

    <div *ngFor="let item of tiers[0].coverages; ">

      <input type="checkbox" checked *ngIf="tiers[0].uiControlType==='checkbox' && checked(item)===true"
        (change)="checkClick($event,item)" />
      <input type="checkbox" *ngIf="tiers[0].uiControlType==='checkbox' && checked(item)===false"
        (change)="checkClick($event,item)" />
      <span class="PlanText">{{item.benefitEntityPolicyDetailCoverageName}} </span>

      <app-member-coverage-runtime
        (formReady)="formInitialized('coverages'+item.benefitEntityPolicyDetailCoverageId, $event)" [coverages]='item'
        (changeCov)='onChangeCov($event)' (changeCovInit)='onChangeCovInit($event)' [coverageValue]='item.coverageValue'
        *ngIf="item.enrollmentCoverageObjectType==='EnrollmentRuntimeCoverageObject'">

      </app-member-coverage-runtime>

      <app-dependentbeneficiaries [tierName]=' tiers[0].tierName' [entityId]='EntityId' [clientId]='ClientId'
        [memberId]='MemberId' [enrolled]='deps' [dependentRules]=' tiers[0].dependentRules'
        [benefitEntityPolicy]='benefitEntityPolicy' (changeDep)='onChangeDep(item)' [beneficiaries]='beneficiaries'
        *ngIf='inTier==="false"'>
      </app-dependentbeneficiaries>

      <app-member-coveragetier [coverages]='item.childCoverages'
        [enrollmentCoverageObject]='item.enrollmentCoverageObject'
        [benefitEntityPolicyDetailCoverageId]='item.benefitEntityPolicyDetailCoverageId'
        [benefitEntityPolicyDetailCoverageValue]='item.benefitEntityPolicyDetailCoverageValue'
        [coverageValue]='item.coverageValue' [benefitEntityPolicy]='benefitEntityPolicy'
        [benefitEntityPolicyDetailId]='tiers[0].benefitEntityPolicyDetailId'
        [name]='item.benefitEntityPolicyDetailCoverageName' [inTier]='inTier' [entityId]='EntityId'
        [clientId]='ClientId' [memberId]='MemberId' [entityClientId]='EntityClientId'
        [coverageRelationShipCodes]='item.coverageRelationShipCodes' [initChecked]='checked(item)'
        (formReady)="formInitialized('coverages'+item.benefitEntityPolicyDetailCoverageId, $event)"
        (change)='onChange($event, item)' *ngIf="item.enrollmentCoverageObjectType!=='EnrollmentRuntimeCoverageObject'">
      </app-member-coveragetier>
    </div>

    <div *ngIf='tiers[0].coverages==null || tiers[0].coverages==""'>

      <app-dependentbeneficiaries [tierName]='tiers[0].tierName' [entityId]='EntityId' [clientId]='ClientId'
        [memberId]='MemberId' [enrolled]='deps' [dependentRules]='tiers[0].dependentRules'
        [benefitEntityPolicy]='benefitEntityPolicy' (changeDep)='onChangeDep(item)' [beneficiaries]='beneficiaries'
        *ngIf='inTier=="false"'></app-dependentbeneficiaries>
    </div>
    <div *ngIf='inTier==="false"'>
      <ng-container *ngIf='_rate?.benefitEntityPolicyDetailRates!=null && EntityId!="ac586073-69d6-41bf-875c-eb754f750457"'>
        <span style="font-size: 14px; color: rgb(155, 155, 155)">Pay Frequency: <b
            style="color: black;">{{_rate?.payFrequency}}</b><br /> </span>
        <div *ngFor="let item of _rate?.benefitEntityPolicyDetailRates;">

          <span *ngIf="item.rate != null" style="font-size: 14px; color: rgb(155, 155, 155)">
            <span *ngIf="item.contributorCode == 'PR'">Member</span>
            <span *ngIf="item.contributorCode == 'ER'">Employer</span>
            Paid Premium: </span> <span style="font-size: 16px; color:green">{{item.rate |
            currency}}</span> <br />
        </div>
      </ng-container>
      <div style="padding-top:15px;">
        <input type="checkbox" formControlName="enrolled" (change)="enrollCheck($event)">
        <span *ngIf="tierForm.value.enrolled" style="color:green; font-size: 18px"> Enrolled in the
          plan, Uncheck to Opt-out of the plan</span>
        <ng-container *ngIf="tierForm.value.enrolled == false">

          <span *ngIf="tierForm.value.enrolled == false" style="color:black; font-size: 18px;font-weight: 400"> Please
            click here to add plan
            to shopping cart</span>
          <br /> Reason: <select class="form-control  input-sm col-3" formControlName="optReason">
            <option></option>
            <option *ngFor="let item of dropReason; " [value]="item.codeLookupId">{{item.name}}
            </option>
          </select>
        </ng-container>

      </div>
    </div>

  </ng-container>
</div>