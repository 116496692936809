import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input,ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ui-config',
  templateUrl: './ui-configure.component.html',
  styleUrls: ['./ui-configure.component.scss'], 
})
export class UIConfigureComponent implements OnInit {
    private _jsonURL = 'assets/config.json';
    config:any;   
    @Input()
    website;

    @Input() 
    set clientName(clientName){
        this._clientName=clientName;
        if(clientName!=null&&clientName!=''){
            this.readfile();
        }
        
    }
    _clientName;

    constructor(
        private http: HttpClient){
            
        }
    ngOnInit(): void {
        
      //this.readfile();
      }
      public getJSON(): Observable<any> {
        return this.http.get(this._jsonURL);
       // return this.http.get(this.website+'/singleweb.js');
      }
    readfile(){
    this.getJSON()    
    .subscribe((data) => {
        let index = data.findIndex(myObj => myObj.ClientName==this._clientName);
        this.config=data[index];

    },err => {
        
      }
    
    );
    }
}