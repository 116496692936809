import { Component, OnInit } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@services/auth/auth.service';
import { ClientService } from '@services/client/client.service';
import { ExcelService } from '@services/excel/excel.service';
import { HttpService } from '@shared/api/http.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Pipe({name: 'tablefilter'})
export class TableFilterPipe implements PipeTransform {
  transform(items: any, searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(item => {
      const key = item.key.toLowerCase().includes(searchText);
    });
  }
}

@Component({
  selector: 'app-enterprise-admin-dashboard',
  templateUrl: './enterprise-admin-dashboard.component.html',
  styleUrls: ['./enterprise-admin-dashboard.component.scss'],
})
export class EnterpriseAdminDashboardComponent implements OnInit {
  
  entities =[]
  filterText: string
  abcArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  selectedLetter = '';
  filteredClients = []
  maxtry=2;
  curtry=0;
  constructor(
    private http: HttpService,private notification: NzNotificationService,
    protected excelService: ExcelService,
    protected spinner: SpinnerService, 
    protected clientService: ClientService,private authService: AuthService,  
  ) { }

  searchForm = new UntypedFormGroup({
    searchInput: new UntypedFormControl(''),
  });

  get searchInput() { 
    return this.searchForm.get('searchInput')
  }
  
  ngOnInit(): void {
  
    const url = `v1/generic/dwh?typeName=Entity`;
    const observ$ = this.clientService.getWithUrl(url, {});
    
    observ$.subscribe( response  => {
      
      this.entities = response.results;
      this.spinner.hide();
      this.filteredClients = []
    },
    err=>{
      this.notification.blank( err.message, ' ',{ nzDuration: 10000 });
      // this.curtry++;
      // if(this.curtry<this.maxtry){
      //   setTimeout(() => {
      //     const url = `v1/generic/dwh?typeName=Entity`;
      //     const observ$ = this.clientService.getWithUrl(url, {});
      //     observ$.subscribe( response  => {
            
      //       this.entities = response.results;
      //       this.spinner.hide();
      //       this.filteredClients = []
      //     })
      //   }, 600);
      // }
    }
    );
  }

  onSearchReset() {
    this.searchInput.reset()
  }

  filterByFirstLetter(letterItem) {
    this.onSearchReset()
    this.filteredClients = this.entities.filter(obj => obj.name.charAt(0) === letterItem);
  }

  trackClient(client): any {
    return client.name;
  }

  onShowAll(){
    this.filteredClients = this.entities
  }

  onKey(event: any){
    event.target.value ? this.onShowAll() : null
  }
}
