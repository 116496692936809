import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { HttpService } from '@shared/api/http.service';
import { BaseComponent } from '@shared/components/base.component';
@Component({
  selector: 'app-dynamic-json',
  templateUrl: './dynamicjsoneditor.component.html',
  styleUrls: ['./dynamicjsoneditor.component.scss'],
})
export class DynamicJsonEditorComponent
  extends BaseComponent
  implements OnInit
{
  json;
  type;
  formConfigs;
  gridConfigs;
  pathNames;
  curdata;
  paging;
  currentFile;
  public editorOptions: JsonEditorOptions;
  constructor(
    private http: HttpClient,
    private notification: NzNotificationService,
    public dialog: MatDialog,
    private httpser: HttpService
  ) {
    super();
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
  }
  getData(data) {
    if (data.target) {
      return;
    }
    this.curdata = data;
    if (data.FormConfig) {
      this.formConfigs = data.FormConfig;
    }
    if (data.GridConfig) {
      this.gridConfigs = data.GridConfig;
    }
    if (data.paging) {
      this.paging = data.paging;
    }
  }
  ngOnInit() {
    this.httpser
      .get('getPathNames', {
        urlParams: null,
        options: {
          isMock: false,
        },
      })
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.pathNames = data;
      });
  }
  onAdd(type) {
    this.json = {};
    this.curdata = this.json;
    this.formConfigs = this.json.FormConfig;
    this.gridConfigs = this.json.GridConfig;
    this.paging = this.json.paging;
    this.currentFile = '';
    this.type = type;
  }
  onOpen() {
    this.openDialog();
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '600px',
      height: '400px',
      data: { title: 'Select a Json file', data: this.pathNames },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == null) {
        return;
      }
      this.currentFile = result.fileName;
      this.httpser
        .get('json?path=' + this.currentFile, {
          urlParams: null,
          options: {
            isMock: false,
          },
        })
        .pipe(untilComponentDestroyed(this))
        // this.http.get('http://localhost:65158/api/json?path=' + this.currentFile)
        .subscribe((data) => {
          this.json = JSON.parse(data['jsonText'] as string);
          this.curdata = this.json;
          this.formConfigs = this.json.FormConfig;
          this.gridConfigs = this.json.GridConfig;
          this.paging = this.json.paging;
          this.type = (data['type'] as string).trim();
        });
    });
  }

  onSave() {
    const jsonstr = JSON.stringify(this.curdata);
    if (this.currentFile === '') {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '250px',
        data: { title: 'Save As', type: this.type },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.currentFile = result.fileName;
        const type = result.fileType;
        this.httpser
          .post(
            'addjson?path=' + this.currentFile + '&type=' + type,
            {
              urlParams: null,
              body: JSON.stringify(jsonstr),
              options: {
                isMock: false,
              },
            },
            new HttpHeaders({
              'Content-Type': 'application/json',
            })
          )
          .pipe(untilComponentDestroyed(this))
          .subscribe(() => {
            this.notification.blank(
              'JSON add ' + this.currentFile + ' file saved successfully',
              ' '
            );
          });
      });
      return;
    }

    this.httpser
      .post(
        'json?path=' + this.currentFile,
        {
          urlParams: null,
          body: JSON.stringify(jsonstr),
          options: {
            isMock: false,
          },
        },
        new HttpHeaders({
          'Content-Type': 'application/json',
        })
      )
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.notification.blank(
          'JSON ' + this.currentFile + ' file saved successfully',
          ' '
        );
      });
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
})
export class DialogComponent extends BaseComponent implements OnInit {
  fileName;
  fileType;
  pathNames;
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpser: HttpService
  ) {
    super();
    this.fileType = data.type;
    //this.pathNames = data.data;
  }
  ngOnInit() {
    this.loading = true;
    this.httpser
      .get('getPathNames', {
        urlParams: null,
        options: {
          isMock: false,
        },
      })
      .pipe(untilComponentDestroyed(this))
      .subscribe((data) => {
        this.loading = false
        this.pathNames = data
      });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    const ret = { fileName: this.fileName, fileType: this.fileType }
    this.dialogRef.close(ret)
  }
}
