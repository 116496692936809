import { Component, OnInit, Input,  ChangeDetectorRef ,  Output, EventEmitter , ChangeDetectionStrategy
} from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import {UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { MemberService } from '@services/client/member.service';
import { ActivatedRoute } from '@angular/router';
import {   Observable  } from 'rxjs';
import { ClientService } from '@services/client/client.service';
@Component({
    selector: 'app-member-eiscoverage',
    templateUrl: './eiscoverage.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./../dynamic.component.scss']
  })
  export class EisCoverageComponent extends BaseComponent implements OnInit {
    @Input()
    isSelected;
    @Input()
    eisDetails;
    @Input()
    set entityId(entityId) {
      if (entityId != null) {
        this.EntityId = entityId;
      }

    }
    @Input()
    set clientId(clientId) {
      if (clientId != null) {
        this.ClientId = clientId;
      }

    }
    @Input()
    set memberId(memberId) {
      if (memberId != null) {
        this.MemberId = memberId;
      }

    }
    @Input()
    benefitEntityPolicyDetailCoverageId;
    @Input()
    inTier;
    @Input()
    benefitEntityPolicyDetailId;
    @Input()
    name;
    @Input()
    coverages;
    @Input()
    benefitEntityPolicy;
    @Input()
    set enrollmentCoverageObject(enrollmentCoverageObject) {
      if (enrollmentCoverageObject) {
        const json = JSON.parse((<any>enrollmentCoverageObject) as string);
        const rows: any = [];
        let from = json['from'];
        const to = json.to;
        const step = json.step;
        while (from <= to) {
          // tslint:disable-next-line:max-line-length
          const obj = {benefitEntityPolicyDetailCoverageId: from, benefitEntityPolicyDetailCoverageValue: from, benefitEntityPolicyDetailCoverageName: from};
          rows.push(obj);
          from = from + step;
        }
        this.coverages = rows;
      }
    }
    @Input()
    set coverageValue(coverageValue) {
      this._coverageValue = coverageValue;
      if (coverageValue) {
        this.coverage = coverageValue['coverage'];
        if (this.coverage != null && this.coverage !== 0) {
          this.coverage = this.coverage.toString();
          this.coverageForm.value['coverage'] = this.coverage;
          this.coverageForm.patchValue(this.coverageForm.value);
           // this.changeCommon(this.coverage);
        }
      }

    }
    @Input()
    uiControlText;
    _coverageValue;
    coverage;
    @Output() formReady = new EventEmitter<UntypedFormGroup>();
    @Output() changeValue = new EventEmitter<number>();
    coverageForm: UntypedFormGroup;
    EntityId: string;
    ClientId: string;
    MemberId: string; rate$: Observable<any>; rates;
    constructor(public changeDetectorRef: ChangeDetectorRef, protected clientService: ClientService, private routeHelper: RouteHelperService,
        private route: ActivatedRoute, private memberService: MemberService, private fb: UntypedFormBuilder) {
      super();
      this.EntityId = this.routeHelper.getEntityId(this.route);
     // this.ClientId = this.routeHelper.getClientId(this.route);
      this.MemberId = this.routeHelper.getMemberId(this.route);
    }
    isRequired() {
        if (this.coverageForm.value.selected === true) {
            if (this.coverageForm.value.coverage == null || this.coverageForm.value.coverage === '' ) {
                return true;
            }
        }
        return false;
    }
    ngOnInit() {
        if (this.coverageValue) {
            this.coverage = this.coverageValue['coverage'];
        }
        this.coverageForm = this.fb.group({
            coverage: this.coverage,
            coverageValue: '',
            rate: '',
            name: name,
            selected: false
          });

          // Emit the form group to the father to do whatever it wishes
          this.formReady.emit(this.coverageForm);
    }
    formInitialized(name: string, form: UntypedFormGroup) {
        // this.planForm.setControl(name, form);
        setTimeout(() => {
         this.coverageForm.setControl(name, form);
     });
    }
    onChange(event) {
      const selectV = event.target.value;
      const f = this.coverages.find(cov => cov.benefitEntityPolicyDetailCoverageId === selectV);
      if (f != null) {
        this.coverageForm.value.coverageValue = f.benefitEntityPolicyDetailCoverageValue;
        this.coverageForm.patchValue(this.coverageForm.value);
      }
        this.changeValue.emit(event);

    }
  }
