import { Component, OnInit, Input,ViewChild } from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'], 
})

export class ClientComponent implements OnInit {
  
  // @Input()
  // clientDataSorted;

  mockData = [
    {
      'title': 'Basic',
      'icon': 'fa fa-book',
      'mainData': {
        'Address': 'Fort Worth, Texas',
        'Website': 'https://www.aa.com',
        'Phone': '000-888-42-42',
        'Is Consolidated': 'Yes',
        'Active': 'Yes',
        'Domain': 'aaaddedbenefits',
        'ABC Website': 'https://www.aaaddedbenfits.com',
        'Toll-Free Number': '855-550-0706'
      }
    },
    {
      'title': 'New Hire',
      'icon': 'fa fa-user-circle-o',
      'mainData': {
        'Address': 'Yes',
        'Website': 'Yes',
        'Phone': '90',
        'Is Consolidated': '60',
        'Active': '90',
        'Domain': 'Yes',
        'ABC Website': 'Yes',
      }
    },
    {
      'title': 'Security',
      'icon': 'fa fa-lock',
      'mainData': {
        'Address': 'Fort Worth, Texas',
        'Website': 'https://www.aa.com',
        'Phone': '000-888-42-42',
        'Is Consolidated': 'Yes',
        'Active': 'Yes',
        'Domain': 'aaaddedbenefits',
        'ABC Website': 'https://www.aaaddedbenfits.com',
        'Toll-Free Number': '855-550-0706'
      }
    },
    {
      'title': 'Enrollment',
      'icon': 'fa fa-file-text-o',
      'mainData': {
        'Address': 'Fort Worth, Texas',
        'Website': 'https://www.aa.com',
        'Phone': '000-888-42-42',
        'Is Consolidated': 'Yes',
        'Active': 'Yes',
        'Domain': 'aaaddedbenefits',
        'ABC Website': 'https://www.aaaddedbenfits.com',
        'Toll-Free Number': '855-550-0706'
      }
    },
    {
      'title': 'Spouse and Dependent Rules',
      'icon': 'fa fa-user-plus',
      'mainData': {
        'Address': 'Fort Worth, Texas',
        'Website': 'https://www.aa.com',
        'Phone': '000-888-42-42',
        'Is Consolidated': 'Yes',
        'Active': 'Yes',
        'Domain': 'aaaddedbenefits',
        'ABC Website': 'https://www.aaaddedbenfits.com',
        'Toll-Free Number': '855-550-0706'
      }
    },
    {
      'title': 'SMTP Config',
      'icon': 'fa fa-envelope-o',
      'mainData': {
        'Address': 'Fort Worth, Texas',
        'Website': 'https://www.aa.com',
        'Phone': '000-888-42-42',
        'Is Consolidated': 'Yes',
        'Active': 'Yes',
        'Domain': 'aaaddedbenefits',
        'ABC Website': 'https://www.aaaddedbenfits.com',
        'Toll-Free Number': '855-550-0706'
      }
    },
    {
      'title': 'Registration Elements',
      'icon': 'fa fa-sign-in ',
      'mainData': {
        'Address': 'Fort Worth, Texas',
        'Website': 'https://www.aa.com',
        'Phone': '000-888-42-42',
        'Is Consolidated': 'Yes',
        'Active': 'Yes',
        'Domain': 'aaaddedbenefits',
        'ABC Website': 'https://www.aaaddedbenfits.com',
        'Toll-Free Number': '855-550-0706'
      }
    },
    {
      'title': 'Payroll',
      'icon': 'fa fa-calendar-check-o',
      'mainData': {
        'Address': 'Fort Worth, Texas',
        'Website': 'https://www.aa.com',
        'Phone': '000-888-42-42',
        'Is Consolidated': 'Yes',
        'Active': 'Yes',
        'Domain': 'aaaddedbenefits',
        'ABC Website': 'https://www.aaaddedbenfits.com',
        'Toll-Free Number': '855-550-0706'
      }
    },
    {
      'title': 'Basic',
      'icon': 'fa fa-book',
      'mainData': {
        'Address': 'Fort Worth, Texas',
        'Website': 'https://www.aa.com',
        'Phone': '000-888-42-42',
        'Is Consolidated': 'Yes',
        'Active': 'Yes',
        'Domain': 'aaaddedbenefits',
        'ABC Website': 'https://www.aaaddedbenfits.com',
        'Toll-Free Number': '855-550-0706'
      }
    }
  ]

  ngOnInit(): void {
    // console.log ('clientDataSorted', this.clientDataSorted)
  }

}