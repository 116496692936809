import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState } from '@store/app.state';
import { SpinnerService } from '@shared/services/spinner.service';
import { Store } from '@ngrx/store';
import { DynamicDialogGridComponent } from '@shared/components/dynamic_grid/dynamic-grid.component';
import { RptHistoryComponent } from '@shared/components/dynamic-reporting/rpthistory.component';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { HttpClient } from '@angular/common/http';
import { ClientService } from '@services/client/client.service';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FormErrorComponent } from '@shared/components/form-error/form-error.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicTemplateComponent } from '@shared/components/dynamic_template/dynamic_template.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { ExcelService } from '@services/excel/excel.service';

@Component({
  templateUrl: './dynamic-reporting.component.html',
  styleUrls: ['./../dynamic.component.scss']
})

export class ReportingComponent extends BaseComponent
  implements OnInit {
  searchValue = '';
  EntityId: string; ClientId: string; id; name; reportControls;
  form: UntypedFormGroup; columnNames; data; oridata; footerColumnNames; footerData;
  headerColumnNames; headerData; lastindex;
  sortName: string | null = null;
  sortValue: string | null = null;
  sortType; filters: any = []; 
  loading = false;
  submitted = false; template;
  isPagingEnabled: boolean = false;
  pagelength: number = 100;
  isAllDisplayDataChecked = false;
  isIndeterminate = false; mapOfCheckedId: { [key: string]: boolean } = {}; actionButton; searchForm; searchFormValue;
  listOfDisplayData: any[] = []; rowid = 'id'; listOfAllData: any[] = [];
  @ViewChild(FormErrorComponent)
  formError: FormErrorComponent;

  constructor(
    private http: HttpClient, public store: Store<AppState>,
    protected route: ActivatedRoute,
    protected notification: NzNotificationService,
    private routeHelper: RouteHelperService,
    protected router: Router,
    public spinner: SpinnerService, protected clientService: ClientService, public dialog: MatDialog, protected excelService: ExcelService) {
    super();
  }

  checkedChange(obj, id) {
    // this.mapOfCheckedId[id] = obj;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => item.checked);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => item.checked) && !this.isAllDisplayDataChecked;
  }

  currentPageDataChange($event: any): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => {
      // this.mapOfCheckedId[item.item[this.lastindex]] = value;
      item.checked = value;
    });
    this.refreshStatus();
  }
  protected getEntityID() {
    return this.routeHelper.getEntityId(this.route);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    // need to find data type
    this.sortType = 'text';
    this.search();
  }

  search(): void {
    this.data = this.oridata;
    this.filters.forEach(element => {
      const f = this.columnNames.findIndex(x => x === element.key);
      if (element.value !== '') {
        this.data = this.data.filter(item => {
          if (item.item[f] == null) {
            return false;
          }
          var tt = typeof item.item[f];
          if (tt == 'number') {
            if (item.item[f] == element.value) {
              return true;
            }
            return false;
          }
          else {
            const index = item.item[f].toString().toLowerCase().indexOf(element.value.toLowerCase());
            return index !== -1;
          }
        });
      }
    });
    if (this.sortName && this.sortValue) {
      this.data = this.data.sort((a, b) => {
        const f = this.columnNames.findIndex(x => x === this.sortName);
        return this.sortValue === 'ascend'
          // tslint:disable-next-line:no-non-null-assertion
          ? a.item[f] > b.item[f]
            ? 1
            : -1
          // tslint:disable-next-line:no-non-null-assertion
          : b.item[f] > a.item[f]
            ? 1
            : -1;
      });
      this.data = [...this.data];
    } else {
      // this.rows = this.rows;
      /* if (filteredData != null) {
        this.rows = [...filteredData];
      } */

    }
  }

  searchCust(key, obj) {
    // obj.filterType = 'in';
    const index = this.filters.findIndex(x => x.key === key);
    if (index === -1) {
      this.filters.push({ value: this.searchValue, key, type: 'search' });
    } else {
      this.filters[index] = { value: this.searchValue, key, type: 'search' };
    }
    this.search();
  }

  resetCust(key) {
    const index = this.filters.findIndex(x => x.key === key);
    if (index === -1) {
      // this.filters.push({value: this.searchValue, key , type: 'search'});
    } else {
      this.filters[index] = { value: '', key, type: 'search' };
      this.search();
    }
  }

  ngOnInit() {
    this.EntityId = this.getEntityID();
    // this.ClientId = this.routeHelper.getClientId(this.route);
    this.route.params.subscribe(params => {
      this.id = params['reportid'];
      const url = 'ReportManagement/Report/Report?reportid=' + this.id;
      const observ$ = this.clientService.getWithUrl(url, {
      }).pipe(untilComponentDestroyed(this));
      this.loading = true;
      observ$.subscribe((data) => {
        this.loading = false;
        const group: any = {};
        let init: any = '';
        this.name = data.name;
        this.reportControls = data.reportControls;
        this.template = data.body;
        // this.isPagingEnabled = data.isPagingEnabled;
        // this.pagelength = data.pagelength;
        this.reportControls.forEach((control) => {
          if (control.controlCode === 'MSDROPDOWN' || control.controlCode === 'DROPDOWN') {
            control.value = this.convtojson(control.value).values;
            init = [];
          } else {
            init = '';
          }
          if (control.isRequired) {
            const validators = [Validators.required];
            group[control.text] = new UntypedFormControl(init, validators);
          } else {
            group[control.text] = new UntypedFormControl(init);
          }
        });
        this.form = new UntypedFormGroup(group);
        /* if (this.reportControls.length === 0) {
                this.onGenerateReport();
        } */
      },
        err => {
          this.loading = false;
        });
    });
  }

  findid(text) {
    const r = this.reportControls.find(control => control.text === text);
    if (r != null) {
      return r.reportControlId;
    }
  }

  downloadReport() {
    this.submitted = true;
    if (this.form.invalid) {
      this.formError.update();
      return;
    }
    this.loading = true;
    const controls = this.form.value;
    const ReportFilters: any = [];
    for (const property in controls) {
      if (controls.hasOwnProperty(property)) {
        const id = this.findid(property);
        const obj = { ReportFilterId: id, InputValue: controls[property] };
        ReportFilters.push(obj);
      }
    }
    const repobj = {
      ReportId: this.id,
      EntityId: this.EntityId,
      ReportFilters: ReportFilters
    };
    const url = 'ReportManagement/Report/ReportData';
    const observ$ = this.clientService.postWithUrl({ url, dto: repobj }).pipe(untilComponentDestroyed(this));
    observ$.subscribe((data) => {
      this.loading = false;
      const convData: any = [];
      this.columnNames = data.columnNames;
      data.data.forEach(element => {
        const obj = { checked: true, item: element };
        convData.push(obj);
      });
      const excelrows: any = [];
      convData.forEach(element => {
        const obj = {};
        for (let i = 0; i < element.item.length; i++) {
          const n = this.columnNames[i].endsWith('_HTML');
          if (n === true) {
            break;
          }
          obj[this.columnNames[i]] = element.item[i];
        }
        excelrows.push(obj);
      });
      this.excelService.exportAsExcelFile(excelrows, 'report');
    },
      err => {
        this.loading = false;
      });
  }

  download() {
    // convert rows
    const excelrows: any = [];
    this.data.forEach(element => {
      const obj = {};
      for (let i = 0; i < element.item.length; i++) {
        const n = this.columnNames[i].endsWith('_HTML');
        if (n === true) {
          break;
        }
        obj[this.columnNames[i]] = element.item[i];
      }
      excelrows.push(obj);
    });
    this.excelService.exportAsExcelFile(excelrows, 'report');
  }

  convtojson(text) {
    const json = JSON.parse((<any>text) as string);
    return json;
  }

  onGenerateReport() {
    this.submitted = true;
    if (this.form.invalid) {
      this.formError.update();
      return;
    }
    this.loading = true;
    const controls = this.form.value;
    const ReportFilters: any = [];
    for (const property in controls) {
      if (controls.hasOwnProperty(property)) {
        const id = this.findid(property);
        const obj = { ReportFilterId: id, InputValue: controls[property] };
        ReportFilters.push(obj);
      }
    }
    const repobj = {
      ReportId: this.id,
      EntityId: this.EntityId,
      ReportFilters: ReportFilters
    };
    const url = 'ReportManagement/Report/ReportData';
    const observ$ = this.clientService.postWithUrl({ url, dto: repobj }).pipe(untilComponentDestroyed(this));
    observ$.subscribe( data => {
      this.isPagingEnabled = (data.count > 100) ? true : false
      this.headerData = data.headerData;
      this.headerColumnNames = data.headerColumnNames;
      this.footerData = data.footerData;
      this.footerColumnNames = data.footerColumnNames;
      const convData: any = [];
      data.data.forEach(element => {
        const obj = { checked: true, item: element };
        convData.push(obj);
      });
      this.data = convData;
      this.oridata = this.data;
      this.columnNames = data.columnNames;
      let i;
      for (i = 0; i < this.columnNames.length; i++) {
        const n = this.columnNames[i].endsWith('_HTML');
        if (n === true) {
          break;
        }
      }
      this.lastindex = i;
      this.loading = false;
    }, err => {
        this.notification.blank(err.error[0], ' ');
        this.loading = false;
      });
  }

  onHistory() {
    const dialogRef = this.dialog.open(RptHistoryComponent, {
      width: '90%',
      // height: '90%',
      data: {
        entityId: this.EntityId,
        reportId: this.id,
        clientId: '',

        formid: 'rpthistory'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        const controls = this.form.value;
        for (const property in controls) {
          if (controls.hasOwnProperty(property)) {
            const id = this.findid(property);
            const it = result.filter.find(x => x.ReportFilterId == id)
            if (it != null) {
              var reUTC = /^\/Date\((d|-|.*)\)[\/|\\]$/;
              var a = reUTC.exec(it.InputValue);
              if (a) {
                var b = a[1].split(/[-+,.]/);
                controls[property] = new Date(b[0] ? +b[0] : 0 - +b[1]);
              } else {
                controls[property] = it.InputValue;
              }
            }
          }
        }
        this.form.patchValue(controls);
      }
    });
  }

  onGenerateTemplate() {
    if (this.data != null) {
      const url = 'addletters?EntityId=' + this.EntityId;
      const arr: any = [];
      this.data.forEach(item => {
        let repobj = {};
        if (item.checked == true) {
          const columnNamesupcase = this.columnNames.map(function (x) { return x.toUpperCase(); });
          let index = columnNamesupcase.indexOf('PARTICIPANTID_HTML');
          //const index = this.columnNames.indexOf('ParticipantId_HTML');
          if (index != -1) {
            repobj['ParticipantId'] = item['item'][index];
            repobj['Template'] = this.template;
            repobj['LetterName'] = this.name;
            repobj['ColumnNames'] = JSON.stringify(this.columnNames);
            repobj['Data'] = JSON.stringify([{ checked: true, item: item['item'] }]);
            repobj['entityId'] = this.EntityId;
            arr.push(repobj);
          }

          index = columnNamesupcase.indexOf('ENTITYCLIENTID_HTML');
          if (index != -1) {
            repobj = {};
            repobj['EntityClientId'] = item['item'][index];
            repobj['Template'] = this.template;
            repobj['LetterName'] = this.name;
            repobj['ColumnNames'] = JSON.stringify(this.columnNames);
            repobj['Data'] = JSON.stringify([{ checked: true, item: item['item'] }]);
            repobj['entityId'] = this.EntityId;
            arr.push(repobj);
          }
        }
      });
      if (arr.length > 0 && this.EntityId != '9808f856-f047-4843-bec8-277e414fc649') {
        const observ$ = this.clientService.postWithUrl({ url, dto: arr }).pipe(untilComponentDestroyed(this));
        this.loading = true;
        observ$.subscribe((data) => {
          this.loading = false;
          this.notification.blank('Letter has been attached successfully!', ' ');
        },
          err => {
            this.loading = false;
          });
      }
    }
    let dialogRef;
    dialogRef = this.dialog.open(DynamicTemplateComponent, {
      width: '90%',
      height: '90%',
      data: {
        data: this.data,
        columnNames: this.columnNames,
        template: this.template
      }
    });
  }

  printone(item) {
    const url = 'genericapi/Add?typeName=nypnet_ParticipantLetter';
    const repobj = {};
    const columnNamesupcase = this.columnNames.map(function (x) { return x.toUpperCase(); });
    let index = columnNamesupcase.indexOf('PARTICIPANTID_HTML');
    if (index != -1) {
      repobj['ParticipantId'] = item.item[index];
      repobj['Template'] = this.template;
      repobj['LetterName'] = this.name;
      repobj['ColumnNames'] = JSON.stringify(this.columnNames);
      repobj['Data'] = JSON.stringify([{ checked: true, item: item.item }]);
      repobj['entityId'] = this.EntityId;
    }
    index = columnNamesupcase.indexOf('ENTITYCLIENTID_HTML');
    if (index != -1) {
      repobj['EntityClientId'] = item['item'][index];
      repobj['Template'] = this.template;
      repobj['LetterName'] = this.name;
      repobj['ColumnNames'] = JSON.stringify(this.columnNames);
      repobj['Data'] = JSON.stringify([{ checked: true, item: item['item'] }]);
      repobj['entityId'] = this.EntityId;
    }
    const observ$ = this.clientService.postWithUrl({ url, dto: repobj }).pipe(untilComponentDestroyed(this));
    if (this.EntityId != '9808f856-f047-4843-bec8-277e414fc649') {
      observ$.subscribe((data) => {
        this.loading = false;
        this.notification.blank('Letter has been attached successfully!', ' ');
      });
    }
    let dialogRef;
    dialogRef = this.dialog.open(DynamicTemplateComponent, {
      width: '90%',
      height: '90%',
      data: {
        data: [{ checked: true, item: item.item }],
        columnNames: this.columnNames,
        template: this.template
      }
    });
  }

  PrintReport() {
    window.print();
    /* const mywindow = window.open('', 'PRINT', 'height=700,width=800');
    if (mywindow != null) {
    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + this.name  + '</h1>');
    if (document.getElementById('ReportMainDiv') != null)  {
        // tslint:disable-next-line:no-non-null-assertion
        mywindow.document.write(document.getElementById('ReportMainDiv')!.innerHTML);
    }
    mywindow.document.write('</body><style>@media print { table th, table td {border:1px solid #848484;}}</style></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10
    mywindow.print();
    mywindow.close();
} */
  }
}
