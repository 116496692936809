import { Injectable } from '@angular/core';
import * as moment from 'moment';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const MIN_DATE = new Date(1753, 0, 1);
export const MAX_DATE = new Date(9999, 11, 31, 23, 59, 59, 999);

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {
  format(date: Date, format?: string): string {
    return moment(date).format(format || DATE_FORMAT);
  }

  parse(value: string, format: string, defaultValue?: Date): Date | undefined {
    const date = moment(value, format || DATE_FORMAT, true).toDate();
    return this.isValidDate(date) ? date : defaultValue;
  }

  isValidDate(date: any): boolean {
    return (
      date &&
      Object.prototype.toString.call(date) === '[object Date]' &&
      !isNaN(date.valueOf())
    );
  }
}
