import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@shared/api/http.service';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BaseComponent } from '@shared/components/base.component';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-entitylevel',
  templateUrl: './entitylevel.component.html',
})

export class EntityLevelComponent extends BaseComponent implements OnInit {
  dto = {};
  params;
  entities;
  selectedEntity;
  _jsonURL = 'reductionsrulegrid';
  title;

  constructor(
    private httpser: HttpService, 
    private clientService: ClientService, 
    route: ActivatedRoute) {
    super();
    this.params = route.snapshot.data['id'];
    if (this.params == 'reductions') {
      this.title = "Coverage reductions rule";
      this._jsonURL = 'entityreductionsrule';
    }
    if (this.params == 'coverageTermination') {
      this.title = "Coverage Termination";
      this._jsonURL = 'entitycoveragetermination';
    }
  }

  ngOnInit() {
    const url = 'Entity/Entities';
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.entities = data;
    });
  }

  selectedEntityChange(entityId) {
    if (!entityId) {
      return;
    }
    this.dto = {
      //'entityClientId': entityClient.entityClientId,
      'EntityId': entityId
    };
  }

  public getJSON(): Observable<any> {
    // return this.http.get(this._jsonURL);
    if (this.selectedEntity == '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
      this._jsonURL = 'hmreductionsrulegrid';
    } else {
      this._jsonURL = 'reductionsrulegrid';
    }
    return (
      this.httpser.get('json?path=' + this._jsonURL, {
        urlParams: null,
        options: {
          isMock: false
        }
      })
    );
  }
}