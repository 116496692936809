import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppState } from '@store/app.state';
import { Store } from '@ngrx/store';
import {
  GetMainUrlAction,
} from '@store/main/main.actions';
import { BaseComponent } from '@shared/components/base.component';
import { MainState } from '@store/main/main.state';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { RouteHelperService } from '@shared/route/route-helper.service';

import { ActivatedRoute } from '@angular/router';
import {
  getMainState,
  isMainStateMutating
} from '@store/main/main.selector';
@Component({
  selector: 'app-report-client',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent  extends BaseComponent implements OnInit {
  getUrl = 'ReportManagement/Report/Reports'; memberProp = 'ReportGroups' ;
  title='Report Dashboard';
  rows; groups: any; EntityId;searchValue="";
  groupname;
  constructor(private http: HttpClient, private routeHelper: RouteHelperService, private route: ActivatedRoute,
    private store: Store<AppState>
    ) {
      super();
    }

  ngOnInit() {
    this.EntityId = this.routeHelper.getEntityId(this.route);
    if(this.EntityId=='00000000-0000-0000-0000-000000000000'){
      this.title='Global Report Dashboard';
    }
    this.route.params.subscribe(params => {
      this.groupname = params['gruopname'];
         

           });
    this.getUrl = 'ReportManagement/Report/Reports?entityId=' + this.EntityId;
    this.store
          .select(getMainState)
          .pipe(untilComponentDestroyed(this))
          .subscribe(x => this.MainStateChange(x));
    this.store.dispatch(new GetMainUrlAction({ Url: this.getUrl, Prop: this.memberProp,
    }));
  }
  getRows(g) {
   return  this.rows.filter(x => {
    const index = x.name.toLowerCase().indexOf(this.searchValue.toLowerCase());
     return x.groupName === g && index !== -1
    });
  }
  private MainStateChange(state: MainState) {
    if (!isMainStateMutating(state)) {
      const pp = this.memberProp;
      if (pp) {
         this.rows = state[pp];
        //  const v = new Set(this.rows.map(x => x.groupName));
        if ( this.rows != null) {
         this.groups = new Set(this.rows.map(x => {
                  if(this.groupname==null){
                      return x.groupName;
                  } else{
                    if (x.groupName==this.groupname){
                      return x.groupName;
                    }
                  }
                    }));
      }
      }
    }

  }
}
