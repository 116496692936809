import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '@services/client/client.service';
import { BaseComponent } from '@shared/components/base.component';
import { Observable } from 'rxjs';
import { HttpService } from '@shared/api/http.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { DynamicFieldsComponent } from '@shared/components/dynamic_fields/dynamic_fields.component';

@Component({
  templateUrl: './dynamic_search.component.html',
})

export class DynamicSearchComponent extends BaseComponent implements OnInit {
  @ViewChild(DynamicFieldsComponent)
  memberComponent: DynamicFieldsComponent;
  autoSearch = false;
  formValue; _jsonURL; formConfigs; saveButton; gridConfigs;
  rows; searchUrl; searchProp; deleteButton; cancelButton;
  searchMethod;
  sortName: string | null = null;
  sortValue: string | null = null;
  sortType; 
  searchFormValue;
  searchValue = ''; 
  filters: any = []; 
  orirows: any = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    protected clientService: ClientService,
    public dialogRef: MatDialogRef<DynamicSearchComponent>,
    private route: ActivatedRoute, 
    protected notification: NzNotificationService,
    private routeHelper: RouteHelperService, 
    private httpser: HttpService, 
    private spinner: SpinnerService) {
    super();
    this.formValue = data.item;
    this._jsonURL = data.formid.toLowerCase() + 'formgrid';
    this.autoSearch = data.autoSearch;
  }

  public getJSON(): Observable<any> {
    return (
      this.httpser.get('json?path=' + this._jsonURL, {
        urlParams: null,
        options: {
          isMock: false
        }
      })
    );
  }

  public onSave(obj) {
    obj.PageSize = 100;
    let observ$;
    if (this.searchMethod == 'get') {
      observ$ = this.clientService.getWithUrl(this.transurl(this.searchUrl, this.formValue), {}).pipe(untilComponentDestroyed(this));
    } else {
      observ$ = this.clientService.postWithUrl({ url: this.searchUrl, dto: obj }).pipe(untilComponentDestroyed(this));
    }

    observ$.subscribe(data => {
      if (this.searchProp != null) {
        this.rows = data[this.searchProp];
        this.orirows = [...this.rows];
      } else {
        this.rows = data;
        this.orirows = [...this.rows];
      }

    }, err => {
        //   this.notification.blank(err.error[0], ' ');
      }
    );
  }

  protected selection(item) {
    this.dialogRef.close(item);
  }

  onCancel() {
    this.dialogRef.close();
  }

  protected init() {

  }

  searchCust(key, obj) {
    const index = this.filters.findIndex(x => x.key === key);
    if (index === -1) {
      this.filters.push({ value: this.searchValue, key, type: 'search', columnType: obj.columnType });
    } else {
      this.filters[index] = { value: this.searchValue, key, type: 'search', columnType: obj.columnType };
    }
    this.search();
  }

  resetCust(key) {
    const index = this.filters.findIndex(x => x.key === key);
    if (index === -1) {
    } else {
      this.filters[index] = { value: '', key, type: 'search' };
      this.search();
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    // need to find data type
    if (this.gridConfigs != null) {
      this.gridConfigs.forEach(element => {
        if (element['columnName'] === this.sortName) {
          this.sortType = element.columnType;
        }
      });
    }
    this.search();
  }

  filter(value, key, type): void {
    const index = this.filters.findIndex(x => x.key === key);
    if (index === -1) {
      this.filters.push({ value, key, type });
    } else {
      this.filters[index] = { value, key, type };
    }
    this.search();
  }

  search(): void {
    /** filter data **/
    this.rows = [...this.orirows];
    this.filters.forEach(element => {
      if (element.type === 'in') {
        if (element.value.length !== 0) {
          this.rows = this.rows.filter(item => {
            const index = element.value.findIndex(e => e === item[element.key]);
            return index !== -1;
          });
        }
      }
      if (element.type === 'search') {
        if (element.value !== '') {
          this.rows = this.rows.filter(item => {
            if (element.value.toLowerCase() == '"null"') {
              if (item[element.key] == null) {
                return true;
              }
              if (item[element.key] == '') {
                return true;
              }
            }
            if (item[element.key] == null) {
              return false;
            }
            if (element.columnType === 'date') {
              const mydate = new Date(element.value);
              if (item[element.key].getTime() === mydate.getTime()) {
                return true;
              }
              return false;
            } else if (element.columnType === 'number') {
              //const index = item[element.key]==element.value;
              return item[element.key] == element.value;
            } else {
              const index = item[element.key].toLowerCase().indexOf(element.value.toLowerCase());
              return index !== -1;
            }
          });
        }
      }
    });

    const filteredData = [...this.rows];
    /* const filterFunc = (item: { name: string; age: number; address: string }) =>
      (this.searchAddress ? item.address.indexOf(this.searchAddress) !== -1 : true) &&
      (this.listOfSearchName.length ? this.listOfSearchName.some(name => item.name.indexOf(name) !== -1) : true);
    const data = this.listOfData.filter(item => filterFunc(item)); */
    /** sort data **/

    if (this.sortName && this.sortValue) {
      this.rows = this.rows.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
      this.rows = [...this.rows];
    } else {
      if (filteredData != null) {
        this.rows = [...filteredData];
      }
    }
  }

  ngOnInit() {
    this.init();
    this.getJSON().pipe(untilComponentDestroyed(this)).subscribe(data1 => {
      const json = JSON.parse((<any>data1.jsonText) as string);

      if (data1 === null) {
        this.formConfigs = [];
      } else {
        const json = JSON.parse((<any>data1.jsonText) as string);
        this.formConfigs = json.FormConfig;
        this.saveButton = json.saveButton;
        this.gridConfigs = json.GridConfig;
        this.searchUrl = json.searchUrl;
        this.searchProp = json.searchProp;
        this.searchMethod = json.searchMethod;
        if (this.autoSearch) {
          setTimeout(() => {
            const obj = this.memberComponent.getFormValue();
            this.onSave(obj);
          });
        }
      }
    });
  }

}
