import { NgModule, ErrorHandler, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '@env/environment';
import { AppComponent } from '@root/app.component';
import { CoreModule } from '@core/core.module';
import {ConsoleAppModule} from '@modules/console-app/console-app.module';
import * as reducers from '@store/app.state';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { WINDOW_PROVIDERS } from '@shared/window.service';
import {
  NoopAnimationsModule,
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import { HTTP_SERVICE_PROVIDERS } from '@shared/api/providers';
import { HTTP_INTERCEPTOR_PROVIDERS } from '@shared/interceptors/providers';
import { CACHE_PROVIDERS } from '@shared/cache/providers';
import { JIT_COMPILER_PROVIDER } from '@shared/jit-compiler.provider';
import { GlobalErrorHandler } from '@shared/error-handler/global-error-handler';
import { EffectsModule } from '@ngrx/effects';
import { SelectionEffects } from '@store/selection/selection.effects';
import { UiModule } from '@shared/ui.module';
import { MockModule } from '@mock/mock.module';
import { NgJsonEditorModule } from 'ang-jsoneditor' ;
/** config angular i18n **/
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import {NgxMaskModule} from 'ngx-mask';
registerLocaleData(en);

/** config ng-zorro-antd i18n **/
//import { NZ_I18N, en_US } from 'ng-zorro-antd';
import { IConfig } from 'ngx-mask';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { FormsModule } from '@angular/forms';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS} from 'ng-pick-datetime';
import {EnterpriseDashboardModule} from 'app/modules/enterprise-app/enterprise-dashboard.module';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
export const MY_NATIVE_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule,
    // NoopAnimationsModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    StoreModule.forRoot(reducers.ROOT_REDUCERS),
    EffectsModule.forRoot([SelectionEffects]),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    HttpClientModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
      /*
    IMPORTANT: Always import the HttpClientInMemoryWebApiModule after the HttpClientModule to ensure that
    the in-memory backend provider supersedes the Angular version.
    https://github.com/angular/in-memory-web-api Do not import MockModule in production
    */
   environment.production ? [] : MockModule,
    UiModule,
    //ConsoleAppModule,
    CoreModule,
    NgJsonEditorModule,
    NgxMaskModule.forRoot(options),
    NzLayoutModule,
    NzMenuModule,
    FormsModule,
    NgbModule,
    EnterpriseDashboardModule
  ],
  exports: [],
  providers: [
    WINDOW_PROVIDERS,
    JIT_COMPILER_PROVIDER,
    HTTP_SERVICE_PROVIDERS,
    HTTP_INTERCEPTOR_PROVIDERS,
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS},
    { provide: NZ_I18N, useValue: en_US },
    CACHE_PROVIDERS,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
      deps: [Injector]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
