<div class="main-container">
  <router-outlet></router-outlet>
</div>
<app-spinner></app-spinner>
<app-screen-content>
  <footer *ngIf='logintype !== "enterpriseadmin" && logintype !== "consoleuser" && logintype !== "testusertool" && logintype !== "user" && logintype !== "portalApp"'
    class="page-footer footer-background">
    <div>
      <div class="white text-center pt-2">{{config.footerText.title}}</div>
      <!-- <div class="white footer-copyright text-center text-black-50">
        We'll show you progressive market ideas with the financial validation to back them
          up.
          We learn what’s inside. Rather than simply bid the business, we analyzed all the components of risk and
          developed strategies to mitigate it.
      </div> -->
      <div class="white footer-copyright text-center text-black-50">{{config.footerText.yearStart}}{{year}} 
        <a>{{config.footerText.domain}}</a><br />
      </div>
    </div>
  </footer>
</app-screen-content>