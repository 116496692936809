<mat-dialog-content>
  <h2 class="member-function-header">Benefit Waiver</h2>

  <div class="row">
    <div class="col-4">
      <ng-container *ngIf='paraArr!=null && paraArr.length>1'>
        Select a policy<span style="color: red">*</span>:<select class="form-control"
          [(ngModel)]="benefitEntityPolicyId" (change)="onChange($event)">
          <option></option>
          <option *ngFor="let opt of paraArr" [value]="opt['benefitEntityPolicyId']">
            {{opt['benefitEntityPolicyDescription']+'-'+opt['benefitEntityPolicyCode']}}</option>
        </select>
      </ng-container>
      <ng-container *ngIf='paraArr!=null && paraArr.length==1'>
        Policy : <br>{{paraArr[0].benefitEntityPolicyDescription}}<br />
      </ng-container>
    </div>

    <div class="col-3">
      Start Date <span style="color: red">*</span>: <br />
      <nz-date-picker [(ngModel)]="billWaiverEffectiveStartDate" (ngModelChange)="onChange($event)">
      </nz-date-picker>
      <div class="col-3" *ngIf='addNew===false || exist===true'>
      </div> <br />
      End Date: <br />
      <nz-date-picker [(ngModel)]="billWaiverEffectiveEndDate" (ngModelChange)="onChange($event)">
      </nz-date-picker>
    </div>
  </div>
  <br />
  <button nz-button nzType="primary" app-popconfirm abcTitle="Are you sure enable waiver ?" (onOK)='onEnableWaiver()'
    *ngIf='addNew===true'>Enable Waiver</button><span *ngIf='addNew===true'>&nbsp;&nbsp;</span>
  <button nz-button nzType="primary" app-popconfirm abcTitle="Are you sure disable waiver ?" (onOK)='onDisableWaiver()'
    *ngIf='addNew===false'>Disable Waiver</button><span *ngIf='addNew===false'>&nbsp;&nbsp;</span>
  <button nz-button nzType="primary" (click)="onCancel()">Cancel</button>&nbsp;&nbsp;
</mat-dialog-content>