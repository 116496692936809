import * as faker from 'faker';
import * as _ from 'lodash';
import { PhoneDTO } from '@models/shared/phone.dto';
import { AddressDTO } from '@models/shared/address.dto';
import { ContactDTO } from '@models/shared/contact.dto';
import { EmailDTO } from '@models/shared/email.dto';
import { BankDTO } from '@models/shared/bank.dto';
import { BankAccountDTO } from '@models/shared/bank-account.dto';
import { DependentDTO } from '@models/shared/dependent.dto';

export function createMockContactDTO(): ContactDTO {
  const contact = new ContactDTO();

  contact.firstName = faker.name.firstName();
  contact.lastName = faker.name.lastName();
  contact.address = createMockAddressDTO();

  contact.phones = [];
  for (let index = 0; index < 2; index++) {
    contact.phones.push(createMockPhoneDTO());
  }

  contact.emails = [];
  for (let index = 0; index < 2; index++) {
    contact.emails.push(createMockEmailDTO());
  }

  return contact;
}

export function createMockAddressDTO(): AddressDTO {
  return {
    AddressId: faker.random.uuid(),
    Address1: faker.address.streetAddress(),
    Address2: faker.address.secondaryAddress(),
    State: faker.random.number(50),
    City: faker.address.city(),
    Zip: faker.address.zipCode(),
    CountyCode: faker.address.county()
  };
}

export function createMockPhoneDTO(): PhoneDTO {
  return {
    phoneNumber: faker.phone.phoneNumberFormat(),
    phoneType: faker.random.number({ min: 0, max: 3 }),
    extension: faker.random.number({ min: 1000, max: 5555 }).toString()
  };
}

export function createMockEmailDTO(): EmailDTO {
  return {
    emailAddress: faker.internet.email(),
    emailType: faker.random.number({ min: 0, max: 3 })
  };
}

export function createMockBankDTO(): BankDTO {
  return {
    bankId: faker.random.uuid(),
    bankName: WELL_KNOWN_BANK.citibank_ny.bankName,
    routingNumber: WELL_KNOWN_BANK.citibank_ny.routingNumber
  };
}

export function createMockBankAccountDTO(): BankAccountDTO {
  return {
    bankAccountId: faker.random.uuid(),
    bankId: WELL_KNOWN_BANK.citibank_ny.bankId,
    bankAccountTypeId: WELL_KNOWN_BANK_ACCOUNT_TYPE.checking.bankAccountTypeId,
    accountNumber: '7654980765'
  };
}

// export function createMockDependentDTO(): DependentDTO {
//   return {
//     DependentId: faker.random.uuid(),
//     firstName: faker.name.firstName(1),
//     middleName: 'D',
//     lastName: faker.name.lastName(1),
//     relationshipTypeId: '49ca56f7-c3d0-485f-b849-89a05b2d9054',
//     dateOfBirth: faker.date.past(50),
//     ssn: faker.random.number({ min: 100000000, max: 999999999 }).toString(),
//     gender: 'F',
//     address: createMockAddressDTO(),
//     phones: [createMockPhoneDTO()],
//     emails: [createMockEmailDTO()]
//   };
// }

export const WELL_KNOWN_BANK: { [key: string]: any } = {
  citibank_ny: {
    bankName: 'Citibank',
    bankId: '799113cd-8fa4-411e-b974-3f8517c75bb5',
    routingNumber: '021000089'
  },
  bankOfAmerica_ny: {
    bankName: 'Bank of America',
    bankId: '60c786e3-3787-492f-97f9-c240b706f75b',
    routingNumber: '021000322'
  }
};

export const WELL_KNOWN_BANK_ACCOUNT_TYPE: { [key: string]: any } = {
  checking: {
    bankAccountTypeId: '2d0fe0be-5a17-4318-8ebc-6d534923dbff',
    description: 'Checking'
  },
  savings: {
    bankAccountTypeId: 'fc39a645-9e82-473b-bbc6-dd32e9656a7e',
    description: 'Savings'
  }
};

export const WELL_KNOWN_DEPENDENT: { [key: string]: any } = {
  spouse: {
    relationshipTypeId: '22BA3FE5-3A3D-40C0-8FD8-1E0476864811',
    description: 'Spouse'
  },
  spouseDomesticPartner: {
    relationshipTypeId: '741097DE-2B85-4EF4-AEF3-6120E9EF51B4',
    description: 'Domestic Partner'
  },
  child: {
    relationshipTypeId: '7A1B8029-5072-449B-BEB1-DC6848DA0185',
    description: 'Child'
  },
  other: {
    relationshipTypeId: '66AE5716-BBD2-4D2B-B4D4-563173FFB0CF',
    description: 'Other'
  }
};

export const WELL_KNOWN_GENDER: { [key: string]: any } = {
  male: {
    genderId: '2ce574f0-692f-40a3-9b56-54e3d3893756',
    description: 'Male'
  },
  female: {
    genderId: 'c1e9a7fc-2288-4fda-a377-ef8354d323e3',
    description: 'Female'
  }
};
