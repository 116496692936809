import { Action } from '@ngrx/store';
import { MemberDTO, MemberProfileDTO, AddMemberDTO } from '@models/shared/member.dto';
export enum MemberActionTypes {
  ///
  RESET_MEMBER = '[SHARED] RESET_MEMBER',
   ///
   GET_MEMBER_BENEFITDATA = '[SHARED] GET_MEMBER_BENEFITDATA',
   SAVE_MEMBER_BENEFITDATA = '[SHARED] SAVE_MEMBER_BENEFITDATA',
   GET_MEMBER_URL = '[SHARED] GET_MEMBER_URL',
   GET_MEMBER_URL_SUCCESS = '[SHARED] GET_MEMBER_URL_SUCCESS',
   GET_MEMBER_URL_FAIL = '[SHARED] GET_MEMBER_URL_FAIL',
  SAVE_MEMBER_TYPE_SUCCESS= '[SHARED] SAVE_MEMBER_TYPE_SUCCESS',
  SAVE_MEMBER_TYPE_FAIL = '[SHARED] SAVE_MEMBER_TYPE_FAIL',
  SAVE_MEMBER_TYPE = '[SHARED] SAVE_MEMBER_TYPE',
  GET_MEMBER_PAGING = '[SHARED] GET_MEMBER_PAGING',
  GET_MEMBER_PAGING_SUCCESS = '[SHARED] GET_MEMBER_PAGING_SUCCESS',
  GET_MEMBER_PAGING_FAIL = '[SHARED] GET_MEMBER_PAGING_FAIL',
  ///
  GET_MEMBER = '[SHARED] GET_MEMBER',
  GET_MEMBER_SUCCESS = '[SHARED] GET_MEMBER_SUCCESS',
  GET_MEMBER_FAIL = '[SHARED] GET_MEMBER_FAIL',
  SAVE_MEMBER_BENEFIT = '[SHARED] SAVE_MEMBER_BENEFIT',
  SAVE_MEMBER_BENEFIT_SUCCESS = '[SHARED] SAVE_MEMBER_BENEFIT_SUCCESS',
  DELETE_MEMBER_BENEFIT = '[SHARED] DELETE_MEMBER_BENEFIT',
  DELETE_MEMBER_BENEFIT_SUCCESS = '[SHARED] DELETE_MEMBER_BENEFIT_SUCCESS',
  ///
  ///
  SAVE_MEMBER = '[SHARED] SAVE_MEMBER',
  SAVE_MEMBER_SUCCESS = '[SHARED] SAVE_MEMBER_SUCCESS',
  SAVE_MEMBER_FAIL = '[SHARED] SAVE_MEMBER_FAIL',
  ///
  DELETE_MEMBER = '[SHARED] DELETE_MEMBER',
  DELETE_MEMBER_SUCCESS = '[SHARED] DELETE_MEMBER_SUCCESS',
  DELETE_MEMBER_FAIL = '[SHARED] DELETE_MEMBER_FAIL'
}
export class ResetMemberAction implements Action {
  readonly type = MemberActionTypes.RESET_MEMBER;
  constructor(
    public payload: {
      MemberProp: any;
    }
  ) {}
}
export class SaveMemberBenefitDataAction implements Action {
  readonly type = MemberActionTypes.SAVE_MEMBER_BENEFITDATA;
  constructor(
    public payload: {

      Data: any;
    }
  ) {}
}

export class SaveMemberTypeAction implements Action {
  readonly type = MemberActionTypes.SAVE_MEMBER_TYPE;
  constructor(
    public payload: {
      Url: string;
      Data: any;
    }
  ) {}
}
export class SaveMemberTypeSuccessAction implements Action {
  readonly type = MemberActionTypes.SAVE_MEMBER_TYPE_SUCCESS;
  constructor(public payload: { dto: any }) {}
}
export class GetMemberAction implements Action {
  readonly type = MemberActionTypes.GET_MEMBER;
  constructor(
    public payload: {
      EntityId: string;
      ClientId: string;
      MemberId: string;
    }
  ) {}
}

export class GetMemberUrlAction implements Action {
  readonly type = MemberActionTypes.GET_MEMBER_URL;
  constructor(
    public payload: {
      Url: string;
      Prop: string;
      EntityId: string;
      ClientId: string;
      MemberId: string;
      BenefitEntityId?: string;
      entityclientId?: string;
    }
  ) {

  }
}
export class GetMemberUrlSuccessAction implements Action {
  readonly type = MemberActionTypes.GET_MEMBER_URL_SUCCESS;
  constructor(public payload: {prop: string, member: any }) {}
}
export class GetMemberSuccessAction implements Action {
  readonly type = MemberActionTypes.GET_MEMBER_SUCCESS;
  constructor(public payload: { member: MemberDTO }) {}
}
export class GetMemberFailAction implements Action {
  readonly type = MemberActionTypes.GET_MEMBER_FAIL;
  constructor(public payload: { error: any }) {}
}

export class GetMemberPagingAction implements Action {
  readonly type = MemberActionTypes.GET_MEMBER_PAGING;
  constructor(
    public payload: {
      Url: string;
      Prop: string;
      EntityId: string;
      ParticipantId: string;
      PageSize?: number;
      PageIndex?: number;
    }
  ) {}
}

export class GetMemberPagingSuccessAction implements Action {
  readonly type = MemberActionTypes.GET_MEMBER_PAGING_SUCCESS;
  constructor(public payload: { prop: string, memberList?: any }) {}
}
export class GetMemberPagingFailAction implements Action {
  readonly type = MemberActionTypes.GET_MEMBER_PAGING_FAIL;
  constructor(public payload: { error: any }) {}
}


export class SaveMemberAction implements Action {
  readonly type = MemberActionTypes.SAVE_MEMBER;
  constructor(public payload: { member: MemberDTO }) {}
}
export class SaveMemberSuccessAction implements Action {
  readonly type = MemberActionTypes.SAVE_MEMBER_SUCCESS;
  constructor(public payload: { member: MemberDTO }) {}
}
export class SaveMemberFailAction implements Action {
  readonly type = MemberActionTypes.SAVE_MEMBER_FAIL;
  constructor(public payload: { error: any }) {}
}

export class DeleteMemberAction implements Action {
  readonly type = MemberActionTypes.DELETE_MEMBER;
  constructor(public payload: { MemberId: string }) {}
}
export class DeleteMemberSuccessAction implements Action {
  readonly type = MemberActionTypes.DELETE_MEMBER_SUCCESS;
}
export class DeleteMemberFailAction implements Action {
  readonly type = MemberActionTypes.DELETE_MEMBER_FAIL;
  constructor(public payload: { error: any }) {}
}


export class SaveMemberBenefitAction implements Action {
  readonly type = MemberActionTypes.SAVE_MEMBER_BENEFIT;
  constructor(public payload: { participantId: string; benefit: any }) {}
}
export class SaveMemberBenefitSuccessAction implements Action {
  readonly type = MemberActionTypes.SAVE_MEMBER_BENEFIT_SUCCESS;
  constructor(public payload: { participantId: string; benefit: any }) {}
}
export class DeleteMemberBenefitAction implements Action {
  readonly type = MemberActionTypes.DELETE_MEMBER_BENEFIT;
  constructor(public payload: { memberBenefitId: string }) {}
}
export class DeleteMemberBenefitSuccessAction implements Action {
  readonly type = MemberActionTypes.DELETE_MEMBER_BENEFIT_SUCCESS;
}

export type AllMemberActions =
  | GetMemberAction
  | GetMemberSuccessAction
  | GetMemberFailAction
  | SaveMemberAction
  | SaveMemberSuccessAction
  | SaveMemberFailAction
  | DeleteMemberAction
  | DeleteMemberSuccessAction
  | DeleteMemberFailAction
  | SaveMemberBenefitAction
  | SaveMemberBenefitSuccessAction
  | DeleteMemberBenefitAction
  | SaveMemberTypeAction
  |SaveMemberTypeSuccessAction
  |GetMemberPagingAction
  |GetMemberPagingSuccessAction
  |GetMemberUrlAction
  |GetMemberUrlSuccessAction
  |SaveMemberBenefitDataAction
  |ResetMemberAction
  | DeleteMemberBenefitSuccessAction;
