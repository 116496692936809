import { LoggerService } from '@shared/logging/logger.service';
import { ConsoleLoggerService } from '@shared/logging/console-logger.service';
import { COMPILER_OPTIONS, CompilerFactory, Compiler } from '@angular/core';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';

export const JIT_COMPILER_PROVIDER = [
  {
    provide: LoggerService,
    useClass: ConsoleLoggerService
  },
  {
    provide: COMPILER_OPTIONS,
    useValue: {},
    multi: true
  },
  {
    provide: CompilerFactory,
    useClass: JitCompilerFactory,
    deps: [COMPILER_OPTIONS]
  },
  {
    provide: Compiler,
    useFactory: createCompiler,
    deps: [CompilerFactory]
  }
];

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}
