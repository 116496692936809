import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { BaseComponent } from '@shared/components/base.component';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth/auth.service';
import { Router } from '@angular/router';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { getAuthState } from '@store/auth/auth.reducer';
import { AuthState } from '@store/auth/auth.state';
import { LoginAction } from '@store/auth/auth.actions';
import { AppState } from '@store/app.state';
import { Store } from '@ngrx/store';
import { SpinnerService } from '@shared/services/spinner.service';


@Component({
  selector: 'app-login-cient-tracking',
  templateUrl: './login_clienttracking.component.html',
  styleUrls: ['./login_clienttracking.component.scss']
})

export class LoginClientTrackingComponent extends BaseComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  authState: AuthState;
  currentYear: number = new Date().getFullYear();
  loading = false;
  card;
  return = '';
  logintype;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private store: Store<AppState>,
    private spinner: SpinnerService
  ) {
    super();
  }

  ngOnInit() {
    this.isLogintype()
    this.initializeForm();
    this.subscribeAuthState();
  }

  ngOnDestroy() {
    this.spinner.hide();
    super.ngOnDestroy();
  }

  onSubmit() {
    if (this.form.valid) {
      this.login();
    }
  }

  login() {
    this.spinner.show();
    const dto = this.form.value;
    this.store.dispatch(
      new LoginAction({
        request: {
          email: dto.username,
          password: dto.password,
        }
      })
    );
  }

  isLogintype() {
    let config = window['___alliant-config-eniac-APP___']
    this.logintype = config.logintype
  }

  private subscribeAuthState() {
    this.store
      .select(getAuthState)
      .pipe(untilComponentDestroyed(this))
      .subscribe(x => this.authStateChange(x));
  }

  public authStateChange(state: AuthState) {
    
    this.authState = state;
    if (state.loading === false && state.success != null) {
      if (this.logintype === 'clienttracking') {
        this.return = '/main/clienttracking-dashboard';
      }
      this.router.navigate([this.return]);
    }
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: ['', { validators: [Validators.required] }],
      password: ['', { validators: [Validators.required] }]
    });
  }
}