import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientService } from '@services/client/client.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { CheckParticipant } from '../../../shared/Interfaces/user-screens-interfaces'
import { RegisterService } from '../../../shared/services/register.service';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  checkParticipantForm: UntypedFormGroup
  checkParticipant
  secQuestions: any
  alertRed: boolean = false
  alertGreen: boolean = false
  alertMessage: any = ''
  clientId: string
  url: any
  participantId: any
  entityClientId: any

  config = window['___alliant-config-eniac-APP___']
  logintype = this.config.logintype
  welcomeText = this.config.welcomeText
  phoneNum = this.config.phoneNum
  primaryColor = this.config.primaryColor
  logoImage = this.config.logoImage
  bgImage = this.config.bgImage
  registrationParam = this.config.registrationParam

  constructor(
    private registerService: RegisterService,
    private router: Router,
    protected clientService: ClientService,
    protected spinner: SpinnerService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.checkParticipantForm = new UntypedFormGroup({
      lastName: new UntypedFormControl('', Validators.required),
      // ssnDigits: new UntypedFormControl('', [Validators.required,
      // Validators.minLength(4),
      // Validators.maxLength(4),
      // Validators.pattern("^[0-9]*$")]),
      zipCode: new UntypedFormControl('', [Validators.required,
      Validators.pattern("^[0-9]*$")]),
      MemberId: new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      DateOfBirth: new UntypedFormControl('', [Validators.required]),
    })

  }

  openDialog() {
    this.dialog.open(DialogElementsFinishPopupRegister, {
      data: {
        alertMessage: this.alertMessage,
        alertRed: this.alertRed,
        alertGreen: this.alertGreen
      }
    });
  }


  onSubmit() {
    if(this.logintype === 'user') {
      this.checkParticipant = {
        entityId: 'E3C3E77D-D76E-4BB8-AF30-AC165CA9807B', //LEEBA
        clientId: null,
        entityClientId: null,
        LastName: this.checkParticipantForm.value.lastName,
        LastFourSSN: this.checkParticipantForm.value.ssnDigits,
        ZipCode: this.checkParticipantForm.value.zipCode,
      }
    } else {
      this.checkParticipant = {
        entityId: '9808f856-f047-4843-bec8-277e414fc649', //TMAIT
        clientId: null,
        entityClientId: null,
        LastName: this.checkParticipantForm.value.lastName,
        ZipCode: this.checkParticipantForm.value.zipCode,
        LastFourSSN: null,
        MemberId: this.checkParticipantForm.value.MemberId,
        DateOfBirth: this.checkParticipantForm.value.DateOfBirth,
      }
    }
    

    if (this.checkParticipantForm.valid) {
      const observ$ = this.clientService.postWithUrl({ url: '/CaseManagement/validateuser', dto: this.checkParticipant });
      observ$.subscribe(res => {
        if (res.participantId) {
          this.alertGreen = true
          this.alertRed = false
          this.participantId = res.participantId
          this.entityClientId = res.entityClientId
          this.router.navigate(['/submitregister'], { queryParams: { ptd: this.participantId, ecid: this.entityClientId  } })
        }
      }, err => {
        this.alertGreen = false
        this.alertRed = true
        this.alertMessage = err.error
        this.openDialog()
      })
    } else {
      this.checkParticipantForm.markAllAsTouched()
    }
  }
}

@Component({
  selector: 'finish-popup-register',
  templateUrl: 'finish-popup-register.html',
})
export class DialogElementsFinishPopupRegister {
  constructor(@Inject(MAT_DIALOG_DATA) public data) { }
}  