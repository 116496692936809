<app-screen-content>
  <div class="app-container">
    <br />
    <h2 class="col-6 member-function-header" style="padding-left: 0px;">Legal Case Management</h2>
    <div class="row col-12" style="height:50px"> <button nz-button nzType="default" (click)="onSearch()"><i nz-icon
          [nzType]="'search'"></i>Search</button></div>
    <nz-table nzSize="small" [nzShowPagination]="true" #basicTable [nzData]="listOfData">
      <thead>
        <tr>
          <th nzWidth="50px" nzShowExpand *ngIf="expandable"></th>
          <th nzWidth="315px"> First Name</th>
          <th nzWidth="315px"> Last Name</th>
          <th nzWidth="200px" class="text-cell">Plan</th>
          <th class="text-cell">SSN</th>
          <th class="text-cell">Phone</th>
          <th class="text-cell">Member Number</th>
          <th class="text-cell">Action</th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-data1 [ngForOf]="basicTable.data">
          <tr>
            <td nzShowExpand *ngIf="expandable" [(nzExpand)]="mapOfExpandData[data1.Id] "
              (nzExpandChange)="expand(data1,$event)"></td>
            <td>{{ data1.firstName }}</td>
            <td>{{ data1.lastName }}</td>
            <td class="text-cell">{{ data1.benefitEntityPolicyDescription}}</td>
            <td class="text-cell">{{ data1.ssNumber}}</td>
            <td class="text-cell">{{ data1.phone}}</td>
            <td class="text-cell">{{ data1.memberNumber}}</td>
            <td><a class="PatchFont" (click)=onCallLog(data1)>Call Log</a></td>
          </tr>
          <tr [nzExpand]="mapOfExpandData[data1.Id] && expandable" *ngIf='mapOfExpandData[data1.Id]'>
            <td></td>
            <td colspan="7">
              <div>
                <app-dynamic-main-grid pageSize=50 class="co-12" gridid='legal' [addInit]='data1'
                  (onprint)='onPrint($event)'>
                </app-dynamic-main-grid>
              </div>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>
  </div>
</app-screen-content>

<app-print-content>
  <!--   <app-dynamic-form  [formid]='printformid' [formIntiValue]='formValue' [isPrint]='true'
         [paraArrInput]='paraArr'   >
         
        </app-dynamic-form> -->
  <app-dynamic-printfields [formConfigs]='formConfigs' [formValue]='formValue'>
  </app-dynamic-printfields>
</app-print-content>