<div style="padding:20px;">
  <div class="row col-12">
    <div class="mx-3"><h2 style="font-weight: 300">{{title}}</h2></div>
    <div class="col" style="justify-content:end">
      <input class="custom-control" type="text" placeholder='Search' [(ngModel)]="searchValue" />
      <span style="position: absolute;top:6px; left:28px; font-size: 18px; color: #acacac; z-index: 1000"><i class="fa fa-search"  aria-hidden="true"></i></span>
    </div>
    
    <div class="row col-12">
      <div class="col-12 row no-gutters">

        <ng-container *ngFor="let g of groups">
          <div *ngIf='g!=null' class="col-4 " style="padding:15px;line-height: 1.75">
            <div style="padding:15px; border: 1px solid #c4c4c4 !important;border-radius: 1px ">
              <h4 class="Font-Patch p-1" style="width: none">{{g}}</h4>
              <span *ngFor="let item of getRows(g)" class="report-link"> <a [routerLink]="item.reportId"
                   routerLinkActive="active">{{item.name}}<br /></a></span>
              <br />
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>