import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MatDialog } from '@angular/material/dialog';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';


@Component({
  selector: 'app-suspense-group',
  templateUrl: './group.component.html',
  styleUrls: ['./suspense-management.component.scss'],
})
export class GroupComponent extends BaseComponent implements OnInit {
  mapOfExpandData: { [key: string]: boolean } = {};
  listOfData1; applyAmt;
  suspenseComments;
  @Input()
  entityId;
  @Input()
  ToSendClientId;
  @Output() suspenseAmount = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();
  @Input()
  set clientId(clientId) {
    if (clientId != null) {
      this._clientId = clientId;
      if (clientId === '') {
        this.listOfData1 = [];
      } else {
        this.load();
      }
    }
  }
  _clientId;
  expandable = true;
  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');
  constructor(public dialog: MatDialog, protected clientService: ClientService, private notification: NzNotificationService) {
    super();
  }
  onRefresh() {
    this.load();
    this.refresh.emit();
  }

  load() {
    const url = 'Payment/SuspenseAccountBalance?entityId=' + this.entityId + '&clientId=' + this._clientId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.suspenseAmount.emit(data);
      this.listOfData1 = [];
      data.billAndSuspenseAmountDTO.forEach(element => {
        this.listOfData1.push({
          Id: element.entityClientId,
          clientId: element.clientId,
          entityClientId: element.entityClientId,
          SectionName: element.entityClientName + ' ' + element.entityClientNumber,
          OutStanding: element.outstandingBillAmount,
          autoPost: true,
          SuspenceBalance: element.suspenseAmount,
          Amount: (element.outstandingBillAmount - element.suspenseAmount) > 0 ? element.outstandingBillAmount - element.suspenseAmount : ''
        });
      });
    });
    this.getSuspenseComments();
  }
  expand(data, event) {
    if (event === true) {
      this.mapOfExpandData = {};
      this.mapOfExpandData[data.Id] = true;
      localStorage.setItem('section', data.Id);
    }
  }
  getList() {
    return this.listOfData1;
  }
  onMoveUp(data) {
    const url = 'Payment/ReshuffleSectionSuspense';
    const dto = {
      'amount': data.Amount,
      // 'participantId' : data.participantGuid,
      'entityId': this.entityId,
      'clientId': data.clientId,
      'entityClientId': data.entityClientId,
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe((res) => {
      this.load();
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(res);
    },
      err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }
  onApply(data) {
    const url = 'Payment/SplitGroupSectionSuspenseBalance';
    const dto = {
      'suspenseAccountType': 'ENTITY',
      'suspenseAccountId': '',
      'entityId': this.entityId,
      'clientId': this._clientId,
      'entityClientId': data.entityClientId,
      'amount': data.Amount,
      'isAutoPost': false, // data.autoPost,
      // 'userName' : data.SectionName
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe((res) => {
      this.load();
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(res);
    },
      err => {
        this.notification.blank(err.error[0], ' ');
      });
  }

  ngOnInit() {
    this.getSuspenseComments();
    const index = localStorage.getItem('section');
    if (index != null) {
      this.mapOfExpandData = {};
      this.mapOfExpandData[index] = true;
    }
  }

  moveSuspense(data) {
    const url = 'Payment/MoveSuspenseCommentBalance';
    const dto = {
      'suspenseAccountId': data.suspenseAccountId,
      'entityId': this.entityId,
      'clientId': this._clientId,
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe((res) => {
      // this.member$ = this.getMember(this.selectedDate);
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(res);
      // this.getSuspenseComments() ;
    },
      err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  addSuspenseComment() {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: false,
        item: { 'entityId': this.entityId, 'ClientId': this._clientId, 'suspenseType': 'Group' },
        formid: 'suspensecomment',
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSuspenseComments();
      this.refresh.emit();
    });
  }

  getSuspenseComments() {
    // tslint:disable-next-line:max-line-length
    this.suspenseComments = 0;
    if (this._clientId != null) {
      const url = 'Payment/SuspenseAccountCommentBalance?suspenseType=group&entityId=' + this.entityId + '&clientId=' + this._clientId;
      const observ$ = this.clientService.getWithUrl(url, {});
      observ$.subscribe((data) => {
        this.suspenseComments = data.suspenseAmountBalanceDTO;
      });
    }
  }
}