import { Component , Input } from '@angular/core';
@Component({
    selector: 'app-breadcrumb-router',
    templateUrl: './dynamic_path.component.html',
  })
  export class BreadcrumbRouterComponent {
    @Input()
    paths;


  }
