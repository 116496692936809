import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectionService } from '@store/selection/selection.service';
import { combineLatest, Observable } from 'rxjs';
import * as _ from 'lodash';
import { EntityIdClientIdDTO } from '@models/entity/entity.dto';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EntityService } from '@services/client/entity.service';
import { UntypedFormBuilder } from '@angular/forms';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { HttpService } from '@shared/api/http.service';
import { ExcelService } from '@services/excel/excel.service';

@Component({
  selector: 'app-ratelist',
  templateUrl: './rateapilist.component.html',
  styleUrls: ['./rateapilist.component.scss']
})

export class RateListComponent extends BaseComponent implements OnInit {
  entityListWithClients: EntityIdClientIdDTO[] = [];
  selectedEntityClient: EntityIdClientIdDTO;
  BenefitsArray;
  selectedBenefit;
  PolicyArray;
  rows;
  selectedPolicy;
  policyIds;
  EntityId: string | null
  EntityClientId: string | null
  BenefitEntityId: string | null
  BenefitEntityPolicyId: string | null
  BenefitEntityPolicyCode: string | null
  BenefitEntityPolicySubCode: string | null
  _jsonURL = 'ratelistgrid'; 
  gridConfigs: any;
  searchValue = '';
  icon = 'search';
  sortName: string | null = null;
  sortValue: string | null = null;
  sortType;
  searchFormValue;
  filters: any = [];
  orirows: any = [];

  constructor(
    private notification: NzNotificationService,
    protected fb: UntypedFormBuilder,
    private clientService: ClientService,
    public dialog: MatDialog,
    private selectionService: SelectionService,
    private entityService: EntityService,
    private httpser: HttpService,
    protected excelService: ExcelService
  ) {
    super();
  }

  ngOnInit() {
    this.initializeDropdown();
    this.getJSON().subscribe(data1 => {
      const json = JSON.parse((<any>data1.jsonText) as string);
      if (data1 === null) {
        this.gridConfigs = [];
      } else {
        this.readJson(json);
      }
    });
  }

  selectedEntityClientChange(entityClient) {
    if (!entityClient) {
      return;
    }
    this.EntityId = entityClient.entityId
    this.EntityClientId = entityClient.entityClientId
    const myurl = `Enrollment/BenefitVendorBenefitEntities?entityId=${entityClient.entityId}&entityclientId=${entityClient.entityClientId}`
    const observ$ = this.clientService.getWithUrl(myurl, {})
    observ$.subscribe(data => {
      this.BenefitsArray = data.benefitEntities
      this.selectedBenefit = null
      this.selectedPolicy = null
      this.rows = []
    });
  }

  selectedBenefitChange(benefit): void {
    const url = `Enrollment/BenefitEntityPolicyList?entityId=${this.selectedEntityClient.entityId}&entityclientId=${this.selectedEntityClient.entityClientId}&benefitEntityId=${benefit.benefitEntityId}`
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe(data => {
      this.PolicyArray = data
      this.selectedPolicy = null
      this.rows = []
    });
  }

  selectedPolicyChange(policy) {
    const url = `Enrollment/BenefitEntityPolicyRatePropertListRate?entityId=${this.selectedEntityClient.entityId}&entityclientId=${this.selectedEntityClient.entityClientId}&benefitEntityId=${this.selectedBenefit.benefitEntityId}&benefitEntityPolicyId=${policy.benefitEntityPolicyId}`
    const observ$ = this.clientService.getWithUrl(url, {})
    observ$.subscribe(data => {
      this.BenefitEntityPolicyId = data[0].benefitEntityPolicyId
      this.BenefitEntityPolicyCode = data[0].benefitEntityPolicyCode
      this.BenefitEntityPolicySubCode = data[0].benefitEntityPolicySubCode
      this.BenefitEntityId = data[0].benefitEntityId
      this.rows = (data[0].rateTable === 'RateTableGlobal') ? data[0].rateTableGlobal : data[0].rateTableA
      this.orirows = [...this.rows]
      this.policyIds = {
        EntityId: this.EntityId,
        EntityClientId: this.EntityClientId,
        BenefitEntityPolicyId: this.BenefitEntityPolicyId,
        BenefitEntityPolicyCode: this.BenefitEntityPolicyCode,
        BenefitEntityPolicySubCode: this.BenefitEntityPolicySubCode,
        BenefitEntityId: this.BenefitEntityId
      }
    }, error => {
      this.notification.blank(error.error[0], ' ')
      this.rows = []
    });
  }

  private getMatchingDropdownItems(selectedEntityClient: EntityIdClientIdDTO | null) {
    if (!selectedEntityClient || !selectedEntityClient.entityId) {
      return null;
    }
    let filtered = _.filter(
      this.entityListWithClients,
      x => x.entityId === selectedEntityClient.entityId
    );
    if (!selectedEntityClient.clientId) {
      return filtered;
    }
    filtered = _.filter(
      filtered,
      x => x.clientId === selectedEntityClient.clientId
    );
    if (!selectedEntityClient.entityClientNumber) {
      return filtered;
    }
    return _.filter(
      filtered,
      x => x.entityClientNumber === selectedEntityClient.entityClientNumber
    );
  }

  private selectDefaultDropdownItem(selectedEntityClient: EntityIdClientIdDTO | null) {
    const items = this.getMatchingDropdownItems(selectedEntityClient);
    if (!items || _.isEmpty(items)) {
      this.selectedEntityClient = this.entityListWithClients.sort()[0];
    } else {
      this.selectedEntityClient = items[0];
    }
  }

  addNewRates() {
    if (this.EntityId && this.BenefitEntityPolicyId) {
      var formid = 'addnewrates'
      const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
        width: '90%',
        data: {
          addNew: true,
          item: {
            EntityId: this.EntityId,
            EntityClientId: this.EntityClientId,
            BenefitEntityId: this.BenefitEntityId,
            BenefitEntityPolicyId: this.BenefitEntityPolicyId,
            PolicyCode: this.BenefitEntityPolicyCode,
            PolicySubCode: this.BenefitEntityPolicySubCode
          },
          prePath: '',
          formid: formid
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result !== null && typeof result !== 'undefined') {
          const url = `Enrollment/BenefitEntityPolicyRatePropertListRate?entityId=${this.EntityId}&entityclientId=${this.EntityClientId}&benefitEntityId=${this.BenefitEntityId}&benefitEntityPolicyId=${this.BenefitEntityPolicyId}`
          this.clientService.getWithUrl(url, {})
            .subscribe(data => {
              this.rows = data[0].rateTable === 'RateTableGlobal' ? data[0].rateTableGlobal : data[0].rateTableA;
              this.orirows = [...this.rows];
            }, error => console.error(error))
        }
      })
    } else {
      this.notification.blank('Please select all the dropdowns', ' ')
    }
  }

  editRates(data) {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: false,
        item: data,
        formid: 'ratelist',
        prePath: ''
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && typeof result !== 'undefined') {
        const url = `Enrollment/BenefitEntityPolicyRatePropertListRate?entityId=${this.EntityId}&entityclientId=${this.EntityClientId}&benefitEntityId=${this.BenefitEntityId}&benefitEntityPolicyId=${this.BenefitEntityPolicyId}`
        this.clientService.getWithUrl(url, {})
          .subscribe(data => {
            this.rows = data[0].rateTable === 'RateTableGlobal' ? data[0].rateTableGlobal : data[0].rateTableA
            this.orirows = [...this.rows]
          }, error => console.error(error))
      }
    })
  }

  readJson(data1) {
    this.gridConfigs = (<any>data1).GridConfig;
  }

  public getJSON(): Observable<any> {
    return (
      this.httpser.get('json?path=' + this._jsonURL, {
        urlParams: null,
        options: {
          isMock: false
        }
      })
    );
  }

  download() {
    const excelrows: any = [];
    this.rows.forEach(row => {
      const obj = {};
      this.gridConfigs.forEach(col => {
        obj[col.columnLabel] = row[col.columnName];
      });
      excelrows.push(obj);
    });
    this.excelService.exportAsExcelFile(excelrows, 'Rates_list');
  }

  searchCust(key, obj) {
    // obj.filterType = 'in';
    const index = this.filters.findIndex(x => x.key === key);
    if (index === -1) {
      this.filters.push({ value: this.searchValue, key, type: 'search', columnType: obj.columnType });
    } else {
      this.filters[index] = { value: this.searchValue, key, type: 'search', columnType: obj.columnType };
    }
    this.search();
  }

  resetCust(key) {
    const index = this.filters.findIndex(x => x.key === key);
    if (index === -1) {
      // this.filters.push({value: this.searchValue, key , type: 'search'});
    } else {
      this.filters[index] = { value: '', key, type: 'search' };
      this.search();
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    // need to find data type
    if (this.gridConfigs != null) {
      this.gridConfigs.forEach(element => {
        if (element['columnName'] === this.sortName) {
          this.sortType = element.columnType;
        }
      });
    }
    this.search();
  }

  filter(value, key, type): void {
    const index = this.filters.findIndex(x => x.key === key);
    if (index === -1) {
      this.filters.push({ value, key, type });
    } else {
      this.filters[index] = { value, key, type };
    }
    // this.filters.
    /* this.filterKey = key;
    this.filterVal = value; */
    this.search();
  }

  search(): void {
    this.rows = [...this.orirows];
    this.filters.forEach(element => {
      if (element.type === 'in') {
        if (element.value.length !== 0) {
          this.rows = this.rows.filter(item => {
            const index = element.value.findIndex(e => e === item[element.key]);
            return index !== -1;
          });
        }
      }
      if (element.type === 'search') {
        if (element.value !== '') {
          this.rows = this.rows.filter(item => {
            if (item[element.key] == null) {
              return false;
            }
            if (element.columnType === 'date') {
              const mydate = new Date(element.value);
              if (item[element.key].getTime() === mydate.getTime()) {
                return true;
              }
              return false;
            } else {
              const index = item[element.key].toLowerCase().indexOf(element.value.toLowerCase());
              return index !== -1;
            }

          });
        }
      }
    });
    const filteredData = [...this.rows];
    /* const filterFunc = (item: { name: string; age: number; address: string }) =>
      (this.searchAddress ? item.address.indexOf(this.searchAddress) !== -1 : true) &&
      (this.listOfSearchName.length ? this.listOfSearchName.some(name => item.name.indexOf(name) !== -1) : true);
    const data = this.listOfData.filter(item => filterFunc(item)); */
    /** sort data **/

    if (this.sortName && this.sortValue) {
      this.rows = this.rows.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
      this.rows = [...this.rows];

    } else {
      // this.rows = this.rows;
      if (filteredData != null) {
        this.rows = [...filteredData];
      }
    }
  }
  
  getEntityClientDisplayText(entityClient: EntityIdClientIdDTO) {
    return _.compact([
      entityClient.entityName,
      entityClient.clientName,
      entityClient.clientCode,
      entityClient.entityClientNumber
    ]).join(', ');
  }

  private initializeDropdown() {
    const entityListWithClients$ = this.entityService.getEntityListWithClients();
    const selectedEntityClients$ = this.selectionService.getSelectedEntityIdClientId();
    combineLatest([entityListWithClients$, selectedEntityClients$])
      .pipe(take(1))
      .subscribe(all => {
        const entityListWithClients: any = [];
        all[0].forEach(item => {
          if (item.entityClientId != null) {
            entityListWithClients.push(item);
          }
        });
        const selectedEntityClient = all[1];
        this.entityListWithClients = entityListWithClients;
        this.selectDefaultDropdownItem(selectedEntityClient);
        this.selectedEntityClientChange(this.selectedEntityClient);
      });
  }

}
