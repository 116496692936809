import { Component, OnInit, OnChanges, Input, ViewChild, Output, EventEmitter, SimpleChanges, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AsyncValidator, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { FormConfig } from '../model/inputcontrol';
import { FormErrorComponent } from '@shared/components/form-error/form-error.component';
import { ConfirmDialogComponent } from '@shared/components/ConfirmDialog/confirmDialog.component';
import { ClientService } from '@services/client/client.service';
import { CustAsyncValidator } from './asyncvalidator';
// import { makeParamDecorator } from '@angular/core/src/util/decorators';
import { AuthService } from '@services/auth/auth.service';
import * as fileSaver from 'file-saver';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders } from '@angular/common/http';
import {
  MAT_DATE_FORMATS, DateAdapter,
  MatDateFormats
} from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppDateAdapter, APP_DATE_FORMATS } from '../dateadapter';

@Component({
  selector: 'app-dynamic-fields',
  templateUrl: './dynamic_fields.component.html',
  styleUrls: ['./dynamic_fields.component.scss']
})
export class DynamicFieldsComponent implements OnInit, OnChanges {
  @Input() 
  hideButton=false
  @Input()
  noBoard=false;
  @Input()
  saving;
  @Input()
  saveSuccess;
  @Input()
  searchobvUrl;
  @Input()
  paraArr;
  @Input()
  set saveButton(saveButton) {
    if (saveButton == null || saveButton === 'true') {
      saveButton = 'Save';
    }
    this._saveButton = saveButton;
  }
  _saveButton;
  @Input()
  reAddButton;
  @Input()
  cancelButton;
  @Input()
  deleteButton;
  @Input()
  formConfigs: FormConfig[];
  @Input()
  addNew;
  @Input()
  validators: any;
  @Input()
  validatorsarr: any;
  @Input()
  actionButton1;
  @Input()
  actionButton2;
  @Input()
  set formValue(formValue: any) {
    if (formValue) {
      this._formValue = { ...formValue };
      if (this.form) {
        this.form.patchValue(formValue);
        /*  setTimeout(function() {
          this.form.patchValue(formValue);
         }, 3000); */
      }
    }
  }
  @Output()
  search: EventEmitter<any> = new EventEmitter();
  @Output()
  action1: EventEmitter<any> = new EventEmitter();
  @Output()
  action2: EventEmitter<any> = new EventEmitter();
  @Output()
  save: EventEmitter<any> = new EventEmitter();
  @Output()
  delete: EventEmitter<any> = new EventEmitter();
  @Output()
  cancle: EventEmitter<any> = new EventEmitter();
  @Output()
  changeObvSub: EventEmitter<any> = new EventEmitter();
  @Output()
  getFields: EventEmitter<any> = new EventEmitter();
  private _formValue: any = {};
  @ViewChild('obv') obv: ElementRef;
  @ViewChild('obv1') obv1: ElementRef;
  @ViewChild(FormErrorComponent)
  formError: FormErrorComponent;
  @ViewChild('name') nameField: ElementRef;
  submitted = false;
  form: UntypedFormGroup;classExpression='row app-form-group-wrapper';
  obv$; obv1$; searchObv$;
  crossfieldsmsg; curUser;
  zipPattern = '\d{5}$';
  formatterDollar = (value: number) => value ? `$ ${value}` : '$ ';
  parserDollar = (value: string) => value.replace('$ ', '');
  popoverTitle = 'Popover title';
  popoverMessage = 'Popover description';
  confirmClicked = false;
  cancelClicked = false;
  constructor(public dialog: MatDialog, protected auth: AuthService, private clientService: ClientService, private asyncValidator: CustAsyncValidator,
    private http: HttpClient) {
    this.curUser = auth.getUsername();
  }

  /* crossFieldsValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const f1 = control.get('primary');
    const f2 = control.get('dependentId');
      return f1 && f2 && f2.value === '' && f1.value === 'Dependent' ? { 'crossfield': this.crossfieldsmsg } : null;
  } */
  crossFieldsValidator(corssfields): ValidatorFn {
    const msg = corssfields[3];
    return (control: UntypedFormGroup): ValidationErrors | null => {
      const f1 = control.get(corssfields[0]);
      const f2 = control.get(corssfields[1]);
      return f1 && f2 && (f2.value == null || f2.value === '') && f1.value !== '' && corssfields[2].includes(f1.value) === true ? { 'crossfield': msg } : null;
    };
  }
  crossFieldsNoNullValidator(corssfields): ValidatorFn {
    const msg = corssfields[3];
    return (control: UntypedFormGroup): ValidationErrors | null => {
      const f1 = control.get(corssfields[0]);
      const f2 = control.get(corssfields[1]);
      return f1 && f2 && (f2.value == null || f2.value === '') && f1.value !== '' && f1.value !== null ? { 'crossfield': msg } : null;
    };
  }
  crossFieldsValidatorGT(corssfields): ValidatorFn {
    const msg = corssfields[3];
    return (control: UntypedFormGroup): ValidationErrors | null => {
      const f1 = control.get(corssfields[0]);
      const f2 = control.get(corssfields[1]);

      const datatype = corssfields[2];
      if (datatype === 'date' && f1 && f2) {
        const value1 = new Date(f1.value.toString());
        const value2 = new Date(f2.value.toString());
        return f1 && f2 && (value1 > value2) ? { 'crossfield': msg } : null;
      }
      // tslint:disable-next-line:max-line-length
      return f1 && f2 && (f1.value > f2.value) ? { 'crossfield': msg } : null;
    };
  }
  crossFieldsNotEqValidator(corssfields): ValidatorFn {
    const msg = corssfields[3];
    return (control: UntypedFormGroup): ValidationErrors | null => {
      const f1 = control.get(corssfields[0]);
      const f2 = control.get(corssfields[1]);
      return f1 && f2 && (f2.value == null || f2.value === '') && f1.value !== corssfields[2] ? { 'crossfield': msg } : null;
    };

  }
  /* crossFieldsNotEqValidator2(corssfields): ValidatorFn {
    const msg =  corssfields[3];
    return (control: FormGroup): ValidationErrors | null => {
    const f1 = control.get(corssfields[0]);
    const f2 = control.get(corssfields[1]);
      return f1 && f2 && (f2.value == null || f2.value === '') && f1.value !== corssfields[2] ? { 'crossfield': msg } : null;
  };
  
  } */
  dateValidate(fromDate, toDate): ValidatorFn {
    return (control: UntypedFormGroup): ValidationErrors | null => {
      const fDate = control.get(fromDate);
      const tDate = control.get(toDate);
      return fDate && tDate && fDate.value > tDate.value ? { 'dateValidate': fromDate + '>' + toDate } : null;
    };
  }

  testcond(access) {
    if (this.form != null) {
      if (this.form.value[access['conditionField']] === access['conditionValue']) {
        return false;
      }
    }
    return true;
  }

  testcondNotEqual(access) {
    if (this.form != null) {
      if (this.form.value[access['conditionField']] != access['conditionValue']) {
        return true;
      }
    }
    return false;
  }

  calcuage(bod) {
    return bod;
  }

  test111(tt) {

  }

  findinlist(lookuplist, value, valuename) {
    const obj = lookuplist.find(el => el[valuename] == value)
    if (obj == null) {
      return false;
    } else {
      return true;
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.saving != null && changes.saving.currentValue === true) {
      return;
    }
    if (changes.saveSuccess != null && changes.saveSuccess.currentValue === true) {
      return;
    }
    const group: any = {};
    if (this.formConfigs != null) {
      this.formConfigs.forEach((formConfig) => {
        Object.keys(formConfig).forEach(key => {
          const inputControls = formConfig[key];
          // formConfigLines.forEach(inputControls=>{
          inputControls.forEach(access => {
            if (access.css == null) {
              access.css = 'col-lg-3 col-md-3 col-sm-12 col-xs-12';
            }
            let validators = [];
            if (access.validator_required) {
              validators = [...validators, Validators.required];
            }
            if (access.validator_email) {
              validators = [...validators, Validators.email];
            }
            if (access.validator_minlength) {
              validators = [...validators, Validators.minLength(access.validator_minlength)];
            }
            if (access.validator_maxlength) {
              validators = [...validators, Validators.maxLength(access.validator_maxlength)];
            }
            if (access.defaultValue != null && this._formValue != null) {
              if (!this._formValue[access.controlName]) {
                this._formValue[access.controlName] = access.defaultValue;
              }
            }
            if (access.defaultType == 'today' && this._formValue != null) {
              if (!this._formValue[access.controlName]) {
                this._formValue[access.controlName] = new Date();
              }
            }
            if ((access.controlType === 'dropdownobv' || access.controlType === 'lookupApiobv') && this._formValue != null) {
              if (access.bindValue != null) {
                let myurl = access.obvUrl + this._formValue[access.controlName];
                myurl = this.transformUrl(this._formValue, myurl);
                this.obv$ = this.clientService.getWithUrl(myurl, {});
              }

            }
            if (access.controlType === 'dropdownfrompara' && access.singleDefaultValue === 'true' && this._formValue != null) {
              if (this.paraArr != null && this.paraArr.length === 1) {
                if (access.bindValue != null) {
                  this._formValue[access.controlName] = this.paraArr[0][access.bindValue];
                }

              }
            }
            if (access.controlType === 'lookupHttpobv' && this._formValue != null) {
              if (access.bindValue != null) {
                let myurl = access.obvUrl + this._formValue[access.controlName];
                myurl = this.transformUrl(this._formValue, myurl);
                this.obv$ = this.clientService.getWithUrl(myurl, {});
              }

            }
            if ((access.controlType === 'lookupHttpobv1' || access.controlType === 'lookupApiobv1') && this._formValue != null) {
              if (access.bindValue != null) {
                let myurl = access.obvUrl + this._formValue[access.controlName];
                myurl = this.transformUrl(this._formValue, myurl);
                this.obv1$ = this.clientService.getWithUrl(myurl, {});
              }

            }
            if (access.controlType === 'hiddenobv' && this._formValue != null) {
              const myurl = this.transformUrl(this._formValue, access.obvUrl);

              this.obv$ = this.clientService.getWithUrl(myurl, {});
            }
            if (access.controlType === 'currentusername' && this._formValue != null) {
              this._formValue[access.controlName] = this.curUser;
            }
            if (access.asyncValidator != null) {
              // tslint:disable-next-line:max-line-length
              group[access.controlName] = new UntypedFormControl('', validators, this.asyncValidator.userValidator('LegalManagement/LegalCaseValidation'));
            } else {
              group[access.controlName] = new UntypedFormControl('', validators);
            }

          });
          // })
        });
      });
    }

    let validator: ValidatorFn; // =this.dateValidate("From Date","To Date");
    const validatorArr: ValidatorFn[] = [];
    let compDate, date1, date2;
    let corssfields; 
    let corssfields1; 
    let corssfields2; 
    let corssfieldsGT; 
    let corssfieldsGT1; 
    let corssfieldsGT2; 
    let corssfieldsGT3;
    let corssfieldsNotNull;
    let corssfieldsNotNull1;
    if (this.validators) {
      compDate = this.validators['dateComp'];
      corssfields = this.validators['crossfields'];
      corssfields1 = this.validators['crossfields1'];
      corssfields2 = this.validators['crossfields2'];
      corssfieldsGT = this.validators['crossfieldsGT'];
      corssfieldsGT1 = this.validators['crossfieldsGT1'];
      corssfieldsGT2 = this.validators['crossfieldsGT2'];
      corssfieldsGT3 = this.validators['crossfieldsGT3'];
      corssfieldsNotNull = this.validators['corssfieldsNotNull'];
      corssfieldsNotNull1 = this.validators['corssfieldsNotNull1'];
      // this.crossfieldsmsg = corssfields[3];
    }
    if (this.validatorsarr) {
      this.validatorsarr.forEach(element => {
        validator = this.crossFieldsValidator(element);
        validatorArr.push(validator);
      });
    }
    if (compDate) {
      date1 = compDate['date1'];
      date2 = compDate['date2'];
      validator = this.dateValidate(date1, date2);
      this.form = new UntypedFormGroup(group, validator);
    } else if (corssfields || corssfieldsGT || this.validatorsarr) {
      if (corssfields) {
        validator = this.crossFieldsValidator(corssfields);
        validatorArr.push(validator);
      }
      if (corssfields1) {
        validatorArr.push(this.crossFieldsValidator(corssfields1));
      }
      if (corssfields2) {
        validatorArr.push(this.crossFieldsValidator(corssfields2));
      }
      if (corssfieldsGT) {
        validatorArr.push(this.crossFieldsValidatorGT(corssfieldsGT));
      }
      if (corssfieldsGT1) {
        validatorArr.push(this.crossFieldsValidatorGT(corssfieldsGT1));
      }
      if (corssfieldsGT2) {
        validatorArr.push(this.crossFieldsValidatorGT(corssfieldsGT2));
      }
      if (corssfieldsGT3) {
        validatorArr.push(this.crossFieldsValidatorGT(corssfieldsGT3));
      }
      if (corssfieldsNotNull) {
        validatorArr.push(this.crossFieldsNoNullValidator(corssfieldsNotNull));
      }
      if (corssfieldsNotNull1) {
        validatorArr.push(this.crossFieldsNoNullValidator(corssfieldsNotNull1));
      }
      this.form = new UntypedFormGroup(group, validatorArr);
    } else {
      this.form = new UntypedFormGroup(group);
    }
    if (this._formValue) {
      if (this.form) {
        this.form.patchValue(this._formValue);
      }
    }
    if (this.searchobvUrl != null) {
      const myurl = this.transformUrl(this._formValue, this.searchobvUrl);
      this.searchObv$ = this.clientService.getWithUrl(myurl, {});
    }
  }
  ngOnInit() {
    if(this.noBoard==true){
      this.classExpression='row';
    }
    setTimeout(() => {
      this.form.valueChanges.subscribe(val => {
        if (this.form.invalid) {
          this.formError.update();
          
        }
                  });
                }, 300);
  }
  getControlName(name) {
    return this.form.get(name);
  }
  onCancel() {
    this.cancle.emit();
  }
  onClose() {

    let val = '';
    const retObj = {};
    this.formConfigs.forEach((formConfig) => {
      Object.keys(formConfig).forEach(key => {
        const inputControls = formConfig[key];
        inputControls.forEach(access => {
          val = val + access.controlName + ':' + (this.form.get(access.controlName) as UntypedFormControl).value + '\n';
          retObj[access.controlName] = (this.form.get(access.controlName) as UntypedFormControl).value;
        });
      });
    });
    this.cancle.emit(retObj);
  }
  onReset() {
    this.formConfigs.forEach(formConfig => {
      // tslint:disable-next-line:forin
      for (const key in formConfig) {
        const item = formConfig[key];
        item.forEach(access => {
          if (access.controlType !== 'hidden') {
            this.form.value[access.controlName] = access.defaultValue;
          } else {
            if (access.reset === 'true') {
              this.form.value[access.controlName] = access.defaultValue;
            }
          }
          /*   if(access.controlType == 'date') {
              this.form.value[access.controlName] = access.defaultValue;
          } */
        });
      }
    });
    this.form.patchValue(this.form.value);
  }
  onDelete() {
    let val = '';
    const retObj = {};
    this.formConfigs.forEach((formConfig) => {
      Object.keys(formConfig).forEach(key => {
        const inputControls = formConfig[key];
        inputControls.forEach(access => {
          val = val + access.controlName + ':' + (this.form.get(access.controlName) as UntypedFormControl).value + '\n';
          retObj[access.controlName] = (this.form.get(access.controlName) as UntypedFormControl).value;
        });
      });
    });
    this.delete.emit(retObj);
  }
  onDownload(access) {
    const myurl = this.transformUrl(this._formValue, access.controlUrl);
    let fname = this._formValue['documentName'];
    const { url, excessParams } = this.clientService.buildUrl(myurl, {}, { isMock: false });
    //window.location.href = url;
    this.http.get(url,
      { responseType: 'blob' }).subscribe(fileData => {
        let b: any = new Blob([fileData], { type: fileData.type });
        // const  url1 = window.URL.createObjectURL(b);
        if (fname == null) {
          fname = 'download.' + fileData.type
        }
        fileSaver.saveAs(b, fname.replace(/"/g, ''));
      });
  }

  onGetFields(url, fields) {
    this.getFields.emit({ url, fields });
  }

  updateFormValue(formValue) {
    if (formValue) {
      this._formValue = formValue;
      if (this.form) {
        this.form.patchValue(formValue);
      }
    }
  }
  onFileSelect(event, access) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const tt = this.form.get(access.controlName);
      if (tt != null) {
        tt.setValue(file);
      }
      const dname=this.form.get('documentName');
      if(dname!=null){
        dname.setValue(file.name)
      }
    }
  }
  getFormValue() {
    let val = '';
    const retObj = {};
    this.formConfigs.forEach((formConfig) => {
      Object.keys(formConfig).forEach(key => {
        const inputControls = formConfig[key];
        inputControls.forEach(access => {
          val = val + access.controlName + ':' + (this.form.get(access.controlName) as UntypedFormControl).value + '\n';
          retObj[access.controlName] = (this.form.get(access.controlName) as UntypedFormControl).value;
        });
      });
    });
    return retObj;
  }
  onAction1() {
    this.submitted = true;
    if (this.form.invalid) {
      this.formError.update();
      return;
    }
    this.action1.emit();
  }
  onAction2() {
    this.action2.emit();
  }
  onSelectionChange() {
  }
  checkform(obj, access) {

    if (access.checked1 == null && obj != null) {
      setTimeout(() => {
        this.form.patchValue(this._formValue);
      });
      access.checked1 = true;
    }
    return true;
  }
  onconfirm() {
    if (confirm("Are you sure you want to save?") == false) {
      return;
    }
    this.onSave();
  }

  public validate(){
    this.submitted = true;
    if (this.form.invalid) {
      this.formError.update();
      return false;
    }
    return true;
  }

  onSave(): void {
    // this.form.patchValue(this._formValue);
    // return;
    // this.obv.nativeElement.dispatchEvent(new Event('change'));

    // if (this.nameField != null) {
    //   this.nameField.nativeElement.focus();
    // }
    this.submitted = true;
    if (this.form.invalid) {
      this.formError.update();
      return;
    }

    // create Dto base on configure
    let val = '';
    const retObj = {};
    this.formConfigs.forEach((formConfig) => {
      Object.keys(formConfig).forEach(key => {
        const inputControls = formConfig[key];
        inputControls.forEach(access => {
          val = val + access.controlName + ':' + (this.form.get(access.controlName) as UntypedFormControl).value + '\n';
          retObj[access.controlName] = (this.form.get(access.controlName) as UntypedFormControl).value;
        });
      });
    });
    //update link fields
    this.formConfigs.forEach((formConfig) => {
      Object.keys(formConfig).forEach(key => {
        const inputControls = formConfig[key];
        inputControls.forEach(access => {
          if (access.linkfield != null) {
            retObj[access.controlName] = retObj[access.linkfield];
          }
        });
      });
    });
    this.save.emit(retObj);
  }
  onSearch(id, fields) {
    this.search.emit({ id, fields });
  }
  onChangeObvSub(access, obj) {
    if (access.obvUrl != null) {
      let myurl = access.obvUrl + obj.target.value;
      myurl = this.transformUrl(this._formValue, myurl);
      this.obv$ = this.clientService.getWithUrl(myurl, {});
    }
    if (access.obvUrl1 != null) {
      let myurl = access.obvUrl1 + obj.target.value;
      myurl = this.transformUrl(this._formValue, myurl);
      this.obv1$ = this.clientService.getWithUrl(myurl, {});
    }
    this.changeObvSub.emit(obj.target.value);
  }
  onChange(access, obj) {
    let myurl = access.obvUrl + obj[access.bindValue];
    myurl = this.transformUrl(this._formValue, myurl);
    this.obv$ = this.clientService.getWithUrl(myurl, {});
  }
  onChange1(access, obj) {
    let myurl = access.obvUrl + obj[access.bindValue];
    myurl = this.transformUrl(this._formValue, myurl);
    this.obv1$ = this.clientService.getWithUrl(myurl, {});
  }
  onChangeobv(url, value) {
    let myurl = url + value;
    myurl = this.transformUrl(this._formValue, myurl);
    this.obv$ = this.clientService.getWithUrl(myurl, {});
    /*  if (value === 'Service Type1') {
       this.obv$ = of([{key: '', value : ''}, {key: '3', value : '3'}, {key: '4', value : '4'}]);
     } else {
       this.obv$ = of([{key: '', value : ''}, {key: '1', value : '1'}, {key: '2', value : '2'}]);
     } */

  }
  transformUrl(obj, url) {
    let myurl = url;
    while (true) {
      let n = myurl.search(/:/i);
      if (n === -1) {
        break;
      }
      let res = myurl.substring(n + 1);
      n = res.search(/&/i);
      if (n === -1) {
        n = res.search(/\//i);
        if (n === -1) {
          res = res.substring(0);
        } else {
          res = res.substring(0, n);
        }

      } else {
        res = res.substring(0, n);
      }

      const val = obj[res];
      const pt = ':' + res;
      if (val != null) {
        myurl = myurl.replace(pt, val);
      } else {
        break;
      }
    }
    return myurl;
  }
  onUpdateForm(obj, url) {
    this.form.patchValue(obj);
    const myurl = this.transformUrl(this.form.value, url);
    this.searchObv$ = this.clientService.getWithUrl(myurl, {});
    // this.searchObv$ =  of([{key: '', value : ''}, {key: '1', value : '1'}, {key: '2', value : '2'}]);
  }
  onSearchobv(url, value) {
    const myurl = url + value;
  }
}
