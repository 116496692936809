import {
  Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, ChangeDetectionStrategy
} from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { MemberService } from '@services/client/member.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientService } from '@services/client/client.service';

@Component({
  selector: 'app-member-eisdetail',
  templateUrl: './eisdetail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./../dynamic.component.scss']
})

export class EisDetailComponent extends BaseComponent implements OnInit {
  eisDetailsArr; eisDetailForm: UntypedFormGroup;
  @Input()
  eisDetailsVal;
  @Input()
  set eisDetails(eisDetails) {
    if (eisDetails) {
      this.eisDetailsArr = [];
      // tslint:disable-next-line:forin
      for (const prop in eisDetails) {

        this.eisDetailsArr.push({ name: prop, arr: eisDetails[prop] });
      }
    }
  }

  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() changeValue = new EventEmitter();
  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    const obj = {};
    this.eisDetailsArr.forEach(element => {
      // tslint:disable-next-line:max-line-length
      obj[element.name] = this.eisDetailsVal != null && this.eisDetailsVal[element.name] != null ? this.eisDetailsVal[element.name].eisRateTableId : '';
    });
    /* if(this.eisDetailsVal!=null) {
    } */
    this.eisDetailForm = this.fb.group(obj);
    this.formReady.emit(this.eisDetailForm);
  }

  onChange(event, name) {
    if (!event) {
      return;
    }
    this.changeValue.emit('Detail');
  }
}
