<div class="main">
  <h3>Suspense Account Management</h3>
  <div class="row col-12 search-row"> <button nz-button nzType="default" (click)="onSearch()"><i nz-icon
        [nzType]="'search'"></i>Search</button></div>
  <nz-table [nzPageSize]='10' #dynamicTable [nzScroll]="fixHeader ? { y: '240px' } : null" [nzData]="listOfData"
    [nzBordered]="bordered" [nzSimple]="simple" [nzLoading]="loading" [nzShowSizeChanger]="sizeChanger"
    [nzShowPagination]="pagination" [nzSize]="size" [nzTotal]="rowTotal" [(nzPageIndex)]="curIndex"
    [nzFrontPagination]='false' (nzPageIndexChange)="pageIndexChange($event)"
    (nzCurrentPageDataChange)="currentPageDataChange($event)">
    <thead (nzSortChange)="sort($event)" nzSingleSort class="thead-dark">
      <tr *ngIf="header">
        <th nzWidth="50px" nzShowExpand *ngIf="expandable"></th>
        <th class="th-line">Type</th>
        <th>Name</th>
        <th *ngIf="hasTypeMember">Employer Code</th>
        <th>Id (If Any)</th>
        <th class="th-line">Recorded date</th>
        <th class="th-line align-right">Suspense Balance</th>
        <th class="th-line align-right">Suspense Comment Balance</th>
        <th>Suspense Comment</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-data [ngForOf]="dynamicTable.data">
        <tr>
          <td nzShowExpand *ngIf="expandable" [(nzExpand)]="mapOfExpandData[data.Id]"
            (nzExpandChange)="expand(data,$event)"></td>
          <!-- <td nzShowCheckbox *ngIf="checkbox" [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td> -->
          <td class="th-line">{{ data.Type }}</td>
          <td>{{ data.Name }}</td>
          <td *ngIf="hasTypeMember">{{ data.groupNumber }}</td>
          <td>{{ data.memberNumber }}</td>
          <td>{{ data.Date | date}}</td>
          <!-- <td>{{ data.LastActionDate | date}}</td> -->
          <td class="align-right">{{ data.Balance | currency }}</td>
          <td class="align-right">{{ data.SuspenseCommentAmount | currency }}</td>
          <td class="th-line">{{ data.SuspenseComment }}</td>
          <td>
            <a class="PatchFont" *ngIf="data.Type == 'Entity'" (click)=onMoveToMember(data)>Move to Member</a>&nbsp;
            <a class="PatchFont" (click)=onRefund(data)>Refund/NSF</a>&nbsp;
            <a *ngIf='searchFormValue!=null && searchFormValue.EntityId=="4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d"'
               class="PatchFont" nz-popconfirm nzPopconfirmTitle="Are you sure transfer this  ?"
               (nzOnConfirm)="onTransfer(data)">Transfer</a>
          </td>
          <!-- <td>
						<a href="#">Action {{ data.name }}</a>
						<nz-divider nzType="vertical"></nz-divider>
						<a href="#">Delete</a>
					</td> -->
        </tr>
        <tr [nzExpand]="mapOfExpandData[data.Id] && expandable">
          <td></td>
          <td [attr.colspan]="8" *ngIf="data.Type == 'Entity'">
            <app-suspense-entity (refresh)="refresh($event)" [entityId]="data.EntityId"
              [suspenseAccountId]='data.suspenseAccountId' *ngIf="mapOfExpandData[data.Id]===true">
            </app-suspense-entity>
          </td>
          <td [attr.colspan]="8" *ngIf="data.Type == 'Group'">
            <div class="expand-container">
              <app-suspense-group (setChildParatmers)="setChildParatmers($event)" (refresh)="refresh($event)"
                [ToSendClientId]='ToSendClientId' [entityId]='data.EntityId' [clientId]='data.ClientId'
                *ngIf="mapOfExpandData[data.Id]===true"></app-suspense-group>
            </div>
          </td>
          <td [attr.colspan]="8" *ngIf="data.Type == 'Group-Section'">
            <div class="expand-container">
              <app-suspense-section (refresh)="refresh($event)" [entityClientId]='data.EntityClientId'
                [entityId]='data.EntityId' [clientId]='data.ClientId' [entityClientId]="data.EntityClientId"
                *ngIf="mapOfExpandData[data.Id]===true"></app-suspense-section>
            </div>
          </td>
          <td [attr.colspan]="8" *ngIf="data.Type == 'Member'">
            <div>
              <app-suspense-member (refresh)="refresh($event)" [depositdate]='data.Date'
                [suspenseAccountId]='data.SuspenseAccountId' [entityId]='data.EntityId' [clientId]='data.ClientId'
                [entityClientId]='data.EntityClientId' [memberId]="data.participantGuid" [needSelectDate]="true"
                *ngIf="mapOfExpandData[data.Id]===true"></app-suspense-member>
            </div>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </nz-table>
  <div class="row col-12 my-3"> <button mat-flat-button color="primary" (click)="onCancel()">  Cancel  </button></div>
</div>