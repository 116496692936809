import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import * as _ from 'lodash';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SpinnerService } from '@shared/services/spinner.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-testtabs',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TestTabsComponent extends BaseComponent implements OnInit {
  tabs;
  selectedIndex;
  nzTabPosition = 'top';
  processTypeId;
  scheduleRunnerId;
  processId;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TestTabsComponent>,
    private notification: NzNotificationService,
    private clientService: ClientService,
    private spinner: SpinnerService
  ) {
    super();
    if (data) {
      this.processTypeId = data.processTypeId;
      this.scheduleRunnerId = data.scheduleRunnerId;
      this.processId = data.processId;
    }
  }
  ngOnInit() {
    // const url = 'FileManageMent/getTabs?ProcessTypeId=' + this.processTypeId +  '&processId=' + this.processId;
    const url =
      'genericapi/Query1?typeName=ProcessTypeTab&processTypeId=' +
      this.processTypeId +
      '&processId=' +
      this.processId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.tabs = data.details;
    });
  }
  log(args: any[]): void {}

  onClose() {
    this.dialogRef.close();
  }
}
