import { MainState } from '@store/main/main.state';
import {
    AllMainActions,
    MainActionTypes
  } from '@store/main/main.actions';
export const initialState = new MainState();


export function mainReducer(
    state: MainState = initialState,
    action: AllMainActions
  ): MainState {
    switch (action.type) {
        case MainActionTypes.SAVE_MAIN_SUCCESS: {
            return { ...state, saving: false , savedObj: action.payload, saveError: null};
          }
        case MainActionTypes.GET_MAIN_URL_SUCCESS : {
            const obj =  {...state, loading: false , saveError: null};
            obj[action.payload.prop] = action.payload.member;
            return obj;
          }
          case MainActionTypes.SAVE_MAIN_FAIL: {
            return { ...state, loading: false,savedObj: {dto:action.payload.data}, saveError: action.payload.error };
          }
          case MainActionTypes.GET_MAIN_URL_FAIL: {
            return { ...state, loading: false, saveError: action.payload.error };
          }
          case MainActionTypes.GET_MAIN_URL: {
            const obj =  {...state, loading: false , saveError: null};
            obj[action.payload.Prop] = null;
            return obj;
            // return {
            //   ...state,
              
            //   loading: true
            // };
          }
          default:
          return state;
  }
}
