import { NgModule } from '@angular/core';
import { DecimalInputComponent } from '@shared/components/decimal-input/decimal-input.component';
import { DateInputComponent } from '@shared/components/date-input/date-input.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { SsnInputComponent } from '@shared/components/ssn-input/ssn-input.component';
import { SelectedClientHeaderComponent } from '@modules/main/client-management/client/selected-client-header/selected-client-header.component';
import { CommonModule } from '@angular/common';
import { PhoneInputComponent } from '@shared/components/phone-input/phone-input.component';
import { FormGroupWrapperComponent } from '@shared/components/form-group-wrapper/form-group-wrapper.component';
import { IntegerInputComponent } from '@shared/components/integer-input/integer-input.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzRadioModule } from 'ng-zorro-antd/radio';
// tslint:disable-next-line:max-line-length
import { ContactAddressComponent } from '@shared/components/form-group/contact-address/contact-address.component';
// tslint:disable-next-line:max-line-length
import { ContactPhoneComponent } from '@shared/components/form-group/contact-phone/contact-phone.component';
// tslint:disable-next-line:max-line-length
import { ContactEmailComponent } from '@shared/components/form-group/contact-email/contact-email.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TextMaskModule } from 'angular2-text-mask';
import { PagerComponent } from '@shared/components/pager/pager.component';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { RDLCReportComponent } from '@shared/components/rdlcreport/rdlcreport.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxCurrencyModule } from 'ngx-currency';
import { FormErrorComponent } from '@shared/components/form-error/form-error.component';
import { NzSpinModule } from 'ng-zorro-antd/spin'
// tslint:disable-next-line:max-line-length
import { DynamicGridComponent, DynamicMainGridComponent, DynamicMainGridHeaderComponent } from '@shared/components/dynamic_grid/dynamic-grid.component';
import { DynamicDialogGridComponent } from '@shared/components/dynamic_grid/dynamic-grid.component';
import { RptHistoryComponent } from '@shared/components/dynamic-reporting/rpthistory.component';
import { DynamicGridPreviewComponent } from '@shared/components/dynamic_grid/dynamic-grid.preview.component';
import { DynamicLettersComponent } from '@shared/components/dynamic_grid/dynamic_letters.component';
import { ClientListComponent } from '@modules/main/client-management/client/client-list/client-list.component';
// tslint:disable-next-line:max-line-length
import { DynamicFormComponent, DynamicMainFormComponent, DynamicMainFormHeaderComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { UploadComponent } from '@shared/components/dynamic_upload/upload.component';
import { SelectedMemberHeaderComponent } from '@shared/components/selected-member-header/selected-member-header.component';
import { MemberQuickLinksComponent } from '@shared/components/member-quick-links/member-quick-links.component';
import { RouterModule } from '@angular/router';
import { DynanicDashboardComponent } from '@shared/components/dynamic-dashboard/dynamic-dashboard.component';
import { DynamicPlansContainnerComponent } from '@shared/components/dynamicplan/dynamicplancontainner.component';
import { DynamicPlanContainerNewComponent } from '@shared/components/dynamicplan/dynamicplancontainnernew.component';
import { CartComponent } from '@shared/components/dynamicplan/cart.component';
import { CartNewComponent } from '@shared/components/dynamicplan/cartnew.component';
import { BenefitEntityBandComponent } from '@shared/components/dynamicplan/benefitentityband.component';
import { TierComponent } from '@shared/components/dynamicplan/tier.component';
import { QuickEnrollmentComponent } from '@shared/components/dynamicplan/quickenrollment.component';
import { BenefitSummaryLink } from '@shared/components/dynamicplan/benefitsummarylink.component';
import { CoverageComponent } from '@shared/components/dynamicplan/coverage.component';
import { CoverageRuntimeComponent } from '@shared/components/dynamicplan/coverageruntime.component';
import { CoverageTierComponent } from '@shared/components/dynamicplan/coveragetier.component';
import { DynamicMemberContainnerComponent } from '@shared/components/dynamicmembercontainner/dynamicmembercontainner.component';
import { DynamicPlanComponent } from '@shared/components/dynamicplan/dynamicplan.component';
import { DynamicPlanDependentBeneficiariesComponent, DynamicPlanDependentComponent } from '@shared/components/dynamicplan/dependent.component';
import { DynamicJsonEditorComponent, DialogComponent } from '@shared/components/dynamicjsoneditor/dynamicjsoneditor.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { CreditCardComponent } from '@shared/components/creditcard/creditcard.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { DynamicPlanBeneficiaryComponent } from '@shared/components/dynamicplan/beneficiary.component';
// import { DocumentsComponent } from '@modules/main/client-management/client/shared/components/member/documents/documents.component';
import { DynamicFormWithGridContainnerComponent } from '@shared/components/dynamic_form_with_grid/dynamic_form_with_grid.component';
import { PrintLayoutComponent } from '@shared/components/dynamic_print/print-layout.component';
import { DynamicPrintComponent } from '@shared/components/dynamic_print/dynamic_print.component';
import { TestPrintComponent } from '@shared/components/dynamic_print/test_print.component';
import { StripePaymentComponent } from '@shared/components/stripe-payment/stripe-payment.component';
import { ReportComponent } from '@modules/main/client-management/client/report/report.component';
import { DynamicFieldsComponent } from '@shared/components/dynamic_fields/dynamic_fields.component';
// import { NgxEditorModule } from 'ngx-editor';
import { LegalClaimComponent } from '@modules/main/legal-claim/legal-claim.component';
import { FileExportGridComponent } from '@modules/main/file-export/fileexportgrid.component';
import { FileFormGridComponent } from '@modules/main/file-export/fileformgrid.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { SuspenseManagementComponent } from '@modules/main/client-management/suspense-management/suspense-management.component';
import { DynamicTemplateComponent } from '@shared/components/dynamic_template/dynamic_template.component';
import { DynamicSearchComponent } from '@shared/components/dynamic_search/dynamic_search.component';
import { TypeaHeadComponent } from '@shared/components/typeahead/typeahead.component';
import { DynamicSearchActionComponent } from '@shared/components/dynamic_search_action/dynamic_search_action.component';
import { EntityComponent } from '@modules/main/client-management/suspense-management/entity.component';
import { GroupComponent } from '@modules/main/client-management/suspense-management/group.component';
import { SectionComponent } from '@modules/main/client-management/suspense-management/section.component';
import { MemberComponent } from '@modules/main/client-management/suspense-management/member.component';
import { SuspenseCommentComponent } from '@modules/main/client-management/suspense-management/suspense-comment/suspense-comment.component';
import { EisPlanComponent } from '@shared/components/dynamicplan/eisplan.component';
import { EisCoverageComponent } from '@shared/components/dynamicplan/eiscoverage.component';
import { AdminDashboardComponent } from '@modules/main/admin-dashboard/admin-dashboard.component';
import { DynamicPrintFormComponent } from '@shared/components/dynamic-print-form/dynamic-print-form.component';
import { StepperComponent } from '@shared/components/dynamic_steppers/dynamic_steppers.component';
import { DialogElementsFinishPopup } from '@shared/components/dynamic_steppers/dynamic_steppers.component';
import { DynamicPrintContentComponent } from '@shared/components/dynamic-print-content/dynamic-print-content.component';
import { DynamicScreenContentComponent } from '@shared/components/dynamic-screen-content/dynamic-screen-content.component';
import { WaiverComponent } from '@shared/components/dynamicplan/waiver.component';
import { WaiverDisplayComponent } from '@shared/components/dynamicplan/waiverdisplay.component';
import { TierEisCoverageComponent } from '@shared/components/dynamicplan/tier-eis-coverage.component';
import { EisDetailComponent } from '@shared/components/dynamicplan/eisdetail.component';
import { UnderWritingComponent } from '@shared/components/dynamicplan/underwriting.component';
// import { SimplifiedUWComponent} from '@shared/components/dynamicplan/SimplifiedUW.component';
import { SimplifiedUWComponent } from '@shared/components/dynamicplan/SimplifiedUW.component';
import { RateListComponent } from '@modules/main/client-management/rateapilist/rateapilist.component';
import { BillCreationComponent } from '@modules/main/client-management/bill-creation/bill-creation.component';
import { FileListComponent } from '@modules/main/client-management/filemanagement/filemanagement.component';
import { FileSetUpComponent } from '@modules/main/client-management/filemanagement/filesetup.component';
import { FileTabsComponent } from '@modules/main/client-management/filemanagement/filetabs.component';
import { TestTabsComponent } from '@modules/main/client-management/filemanagement/tab.component';
import { MemberSearchComponent } from '@modules/main/client-management/suspense-management/membersearch.component';
import { CobraReportComponent } from '@modules/main/client-management/client/cobraadmin/cobrareport.component';
import { CobraReportPrintComponent } from '@modules/main/client-management/client/cobraadmin/reportprint.component';
import { BreadcrumbRouterComponent } from '@shared/components/dynamic_path/dynamic_path.component';
import { BenefitSummaryComponent } from '@shared/components/dynamicplan/benefitsummary.component';
import { ConfirmOverlayComponent } from '@shared/components/confirmoverlay/confirmoverlay.component';
import { NotificationOverlayComponent } from '@shared/components/notificationoverlay/notificationoverlay.component';
import { BenefitManagementComponent } from '@modules/main/client-management/benefit/benefit-management.component';
import { BenefitPolicyComponent } from '@modules/main/client-management/benefit/benefitpolicy.component';
import { AddNewLevel2Component } from '@modules/main/client-management/benefit/addnewlevel2.component';
import { BenefitPolicyDetailComponent } from '@modules/main/client-management/benefit/benefitpolicydetail.component';
import { CoverageTerminationComponent } from '@modules/main/client-management/benefit/coveragetermination.component';
import { BenefitTiersComponent } from '@modules/main/client-management/benefit/tiers.component';
import { BenefitBillConfigComponent } from '@modules/main/client-management/benefit/billconfig.componet';
import { BenefitCoverageComponent } from '@modules/main/client-management/benefit/coverage.component';
import { SimpleRangeCoverageComponent } from '@modules/main/client-management/benefit/simplerangecoverageobject.component';
import { BenefitCoverageRuleComponent } from '@modules/main/client-management/benefit/coveragerule.component';
import { BenefitReductionRuleComponent } from '@modules/main/client-management/benefit/reductionsrule.component';
import { EntityLevelComponent } from '@modules/main/client-management/benefit/entitylevel.component';
import { AssignCheckComponent } from '@modules/main/client-management/assigncheck/assigncheck.component';
import { AssignGroupCheckComponent } from '@modules/main/client-management/assigncheck/assigngroupcheck.component';
import { ReinstateComponent } from '@modules/main/client-management/reinstate/reinstate.component';
import { DependentViewComponent } from '@shared/components/dynamicplan/dependentview.component';
import { HistoryComponent } from '@shared/components/dynamicplan/history.component';
import { AppNavbarComponent } from '@modules/main/app-navbar/app-navbar.component';
import { OrderModule } from 'ngx-order-pipe';
import { LegalCaseComponent } from '@modules/main/legal-case/legal-case.component';
import { DynamicPrintFieldsComponent } from '@shared/components/dynamic-print-fields/dynamic-print-fields.component';
import { MemberLookupComponent } from '@modules/main/client-management/client/member-lookup/member-lookup.component';
import { DynamicAutoCompleteComponent } from '@shared/components/dynamic_autocomplete/dynamic_autocomplete.component';
import { DynamicAutoCompleteClientComponent } from './dynamic_autocomplete_client/dynamic_autocomplete_cleint.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ModuleWithProviders } from "@angular/core";
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatDividerModule } from '@angular/material/divider';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ReportingComponent } from '@shared/components/dynamic-reporting/dynamic-reporting.component';
import { NgxMaskModule } from 'ngx-mask';
import { ConfirmDialogComponent } from '@shared/components/ConfirmDialog/confirmDialog.component';
import { TierCoverageComponent } from '@shared/components/dynamicplan/tier-coverage.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { RatesUploadComponent } from '@modules/main/client-management/ratesupload/ratesupload.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';
const COMPONENTS = [
  FormGroupWrapperComponent,
  DecimalInputComponent,
  IntegerInputComponent,
  DateInputComponent,
  SsnInputComponent,
  PhoneInputComponent,
  ContactAddressComponent,
  ContactPhoneComponent,
  ContactEmailComponent,
  FormErrorComponent,
  PagerComponent,
  SpinnerComponent,
  DynamicPlanContainerNewComponent,
  DynamicFieldsComponent,
  DynamicGridComponent,
  DynamicFormComponent,
  DynamicDialogFormComponent,
  DynamicDialogGridComponent,
  SelectedMemberHeaderComponent,
  MemberQuickLinksComponent,
  DynanicDashboardComponent,
  DynamicPlansContainnerComponent,
  RptHistoryComponent,
  CartComponent,
  DynamicPlanComponent, StripePaymentComponent,
  TierComponent,
  CoverageComponent,
  CoverageRuntimeComponent,
  CoverageTierComponent,
  DynamicMemberContainnerComponent,
  DynamicJsonEditorComponent,
  DialogComponent,
  DynamicGridPreviewComponent,
  DynamicPlanDependentBeneficiariesComponent,
  DynamicPlanDependentComponent,
  DynamicPlanBeneficiaryComponent,
  // DocumentsComponent,
  DynamicMainGridComponent, CoverageTerminationComponent,
  DynamicFormWithGridContainnerComponent,
  DynamicMainFormComponent, DynamicMainFormHeaderComponent,
  RDLCReportComponent,
  PrintLayoutComponent,
  DynamicPrintComponent,
  TestPrintComponent,
  ReportComponent,
  LegalClaimComponent, CreditCardComponent,
  
  ReportingComponent,
  DynamicTemplateComponent,
  TierCoverageComponent,
  DynamicSearchComponent,
  BenefitEntityBandComponent,
  CartNewComponent,
  DynamicSearchActionComponent,
  EntityComponent,
  GroupComponent,
  SectionComponent, AdminDashboardComponent, AddNewLevel2Component,
   ClientListComponent, NotificationOverlayComponent,
  SuspenseCommentComponent, ConfirmOverlayComponent,
  QuickEnrollmentComponent, ConfirmDialogComponent,
  EisPlanComponent, AppNavbarComponent, MemberLookupComponent,
  EisCoverageComponent, DynamicLettersComponent,
  TierEisCoverageComponent, DynamicPrintFormComponent, DynamicPrintFieldsComponent,
  EisDetailComponent, WaiverComponent, DynamicScreenContentComponent, DynamicPrintContentComponent,
  UnderWritingComponent, UploadComponent, AssignCheckComponent,AssignGroupCheckComponent, DynamicAutoCompleteComponent, DynamicAutoCompleteClientComponent,
  FileExportGridComponent, DynamicMainGridHeaderComponent, LegalCaseComponent, WaiverDisplayComponent,
  FileFormGridComponent, SelectedClientHeaderComponent, ReinstateComponent, DependentViewComponent,
  SimplifiedUWComponent, BreadcrumbRouterComponent, BenefitSummaryComponent, BenefitPolicyComponent, BenefitPolicyDetailComponent,
  RateListComponent, MemberSearchComponent, TypeaHeadComponent, BenefitManagementComponent, BenefitTiersComponent, BenefitBillConfigComponent,
  BenefitCoverageComponent, SimpleRangeCoverageComponent, BenefitCoverageRuleComponent, BenefitReductionRuleComponent,
  FileListComponent, FileSetUpComponent, FileTabsComponent, TestTabsComponent, CobraReportComponent, CobraReportPrintComponent,
  BillCreationComponent, HistoryComponent, BenefitSummaryLink, EntityLevelComponent, StepperComponent, DialogElementsFinishPopup, RatesUploadComponent
];
const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgSelectModule,
  DirectivesModule,
  PipesModule,
  TextMaskModule,
  NgxSpinnerModule,
  NgJsonEditorModule,
  MatFormFieldModule,
  NzSelectModule,
  NzIconModule, NzModalModule,
  NzSpinModule, NzPopconfirmModule, NzCheckboxModule,
  NzTableModule,
  NzInputNumberModule,
  NzTabsModule, NzButtonModule,
  NzDatePickerModule,
  NzBreadCrumbModule,
  NzNotificationModule,
  NzTimePickerModule,
  NzBackTopModule,
  NzRadioModule,
  MatDialogModule,
  MatInputModule,
  MatTabsModule,
  MatRadioModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatCardModule,
  // CobraModule,
  CKEditorModule,
  CdkAccordionModule,
  NgxCurrencyModule,
  OrderModule, NzDropDownModule,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
];
@NgModule({
    imports: [
        MODULES, NgxMaskModule.forChild(),
    ],
    declarations: [...COMPONENTS, SuspenseManagementComponent, MemberComponent, PagerComponent, DynamicDialogFormComponent],
    exports: [...MODULES, ...COMPONENTS, DynamicDialogFormComponent]
})
export class ComponentsModule { }
