<div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
  <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
    height:200px; 
    position: fixed;
    top: 50%; 
    left: 50%; 
    margin-top: -100px; 
    margin-left: -100px;"></nz-spin>
  <span class="default-font-Color-dark">Loading ...</span>
</div>
<div style="padding:20px 0px 10px 20px">
  <h2 class="member-function-header">Rates Upload</h2>
</div>
<div class="row  col-20" style="margin: 0px">

  <div class="col-2">
    <ng-select (change)="selectedEntityChange($event)" [(ngModel)]="selectedEntity">
      <ng-container *ngFor="let item of entities">
        <ng-option value='{{item.id}}'>{{item.entityName}}</ng-option>
      </ng-container>
    </ng-select>
  </div>

  <div class="col-8" style="padding-left: 0px">
    <button [disabled]="!rows" class="mat-flat-button mat-primary" style="margin-left: 15px" app-popconfirm
      abcTitle="Are you sure you want to save?" (onOK)="onSubmit()">Save </button> &nbsp;
    <input type="file" id="fileUploader" name="fileUploader" accept=".xls, .xlsx" (change)="fileChange($event)" />
  </div>

</div>
<div style=" overflow: auto; white-space: nowrap; ">
  <app-dynamic-main-grid 
    (actionPopup)="actionPopup($event)" 
    pageSize=50 class="col-12" 
    gridid='ratesfileupload'
    [inputRows]='rows'>
  </app-dynamic-main-grid>
</div>