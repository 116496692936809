import { Component, OnInit, Input , Inject} from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { JsonEditorOptions } from 'ang-jsoneditor';
@Component({
    selector: 'app-simplerangecoverage',
    templateUrl: './simplerangecoverageobject.component.html',
    styleUrls: ['./simplerangecoverageobject.component.scss']
  })
  export class SimpleRangeCoverageComponent extends BaseComponent implements OnInit {
    @Input()
    jsonStr;
    json;
    editorOptions: JsonEditorOptions = new JsonEditorOptions();

    ngOnInit() {
        this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
        const str = this.jsonStr.replace(/\'/g, '"');
        this.json = JSON.parse(str);
    }
  }
