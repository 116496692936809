import { Injectable } from '@angular/core';
import { map , catchError  } from 'rxjs/operators';
import { of , Observable } from 'rxjs';
import { ClientService } from '@services/client/client.service';
import {   AsyncValidator,  ValidationErrors , AbstractControl, AsyncValidatorFn} from '@angular/forms';
@Injectable({ providedIn: 'root' })
export class CustAsyncValidator implements AsyncValidator {
  url = 'LegalManagement/LegalCaseValidation'; dto = {}; form;
  constructor( protected clientService: ClientService) {}
  userValidator(url): AsyncValidatorFn {
    this.url = url;

    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control != null && control.parent != null && control.parent.controls != null ) {

        this.dto = {
          ...control.parent.value,
          legalCategoryServiceId: control.value
        };
      }

      return this.clientService.postWithUrl({ url: this.url, dto: this.dto})
        .pipe(
          map(isTaken => (isTaken != null ? { asyncValidator: true } : null)),
          catchError(() => of(null) )
        );

    };

  }

  validate(
    ctrl: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return of('false').pipe(
      map(isTaken => (isTaken === 'true' ? { asyncValidator: true } : null)),
      catchError(() => of(null) )
    );
  }
}
