import { NgModule } from '@angular/core';
import { MatInputSSNDirective,MatSSNDirective} from '@shared/directives/matinputssn/mat-input-ssn.directive';
import { MatInputAchAccountDirective} from '@shared/directives/inputAchAccount/mat-input-ach-account.directive';
import { PopConfirmDirective} from '@shared/directives/popupconfirm.directive';
const DIRECTIVES = [MatInputSSNDirective,PopConfirmDirective,MatSSNDirective,MatInputAchAccountDirective];

@NgModule({
  declarations: [DIRECTIVES],
  exports: [DIRECTIVES]
})
export class DirectivesModule {}
