<ng-container [formGroup]="addressFormGroup">
  <div class="row">
    <div class="col-12">
      <label>Address 1:
        <input type="text"
               class="form-control"
               formControlName="Address1">
      </label>
    </div>
    <div class="col-12">
      <label>Address 2:
        <input type="text"
               class="form-control"
               formControlName="Address2">
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-3 col-md-6 col-sm-12 col-xs-12">
      <label>City:
        <input type="text"
               class="form-control"
               formControlName="City">
      </label>
    </div>
    <div class="col-xl-6 col-lg-3 col-md-6 col-sm-12 col-xs-12">
      <label>State:
        <ng-container *ngIf="('STATES' | lookup) as statesLookup">
          <ng-select [items]="statesLookup.items"
                     bindLabel="name"
                     bindValue="id"
                     formControlName="State"
                     class="contact-custom">
          </ng-select>
        </ng-container>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12 col-xs-12">
      <label>Zip:
        <input type="text"
               class="form-control"
               formControlName="Zip">
      </label>
    </div>
  </div>
</ng-container>
