<!-- stripe-payment.component.html -->
<form (ngSubmit)="createStripeToken()" class="checkout">
	<div style="width: 100%; text-align:center;">
		<img src="https://cdn.abcsys.com/Library/Eniac/alliant.png" style="width: 100px;" />
		<h2 style="font-size:16px" class="member-function-header">Payment Service</h2>
	</div>
	<br />
	<div style="padding: 15px;border-radius: 10px;background: #eef8ff;border: 1px solid rgb(180, 215, 255); ">
		Your monthly recurring charge will be <b>${{_totalAmount}}</b>. <br /> Your first payment is due on <b>December
			1<sup>st</sup> 2020 </b>
		<br /> <br />

		<div id="form-field">
			<div style="padding: 5px ; background: rgb(243, 243, 243); border: 1px solid rgb(124, 124, 124); ">
				<div id="card-info" #cardInfo></div>
				<mat-error id="card-errors" role="alert" *ngIf="cardError">
					<mat-icon style="color: #f44336">cancel</mat-icon>
					&nbsp;{{ cardError }}
				</mat-error>
			</div>
			<br />
			<div>
				<input type="checkbox" />&nbsp; I agree for monthly payment
			</div>
			<br />
			<div style="width: 100%; text-align:center;">
				<button id="submit-button" class="mat-flat-button mat-primary" type="submit">
					Submit Payment Enrollment
				</button>
				<br />
				<span style="font-size: 10px; color: grey; ">Powered by Alliant</span>
			</div>
			<br *ngIf="_token != null" />
			<span *ngIf="_token != null" style="color:green;font-weight: bold;">Your stripe Transaction is successful. Stripe
				token is {{_token.id}}</span>
		</div>
	</div>

</form>