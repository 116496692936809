<nz-back-top></nz-back-top>
<div class="app-container">
  <br />
  <h2 class="col-12 member-function-header title">Check Assignment</h2>
  <div class="row">
    <div class=" col-6 top-dropdown">
      <ng-select (change)="selectedEntityChange($event)" [(ngModel)]="selectedEntity">
        <ng-container *ngFor="let item of entities">
          <ng-option value='{{item.id}}'>{{item.entityName}}</ng-option>
        </ng-container>
      </ng-select>
  
      <!--  <ng-select  (change)="selectedEntityClientChange($event)"
          [(ngModel)]="selectedEntityClient">
          <ng-option *ngFor="let item of entityListWithClients" 
                  [value]='item'>{{ getEntityClientDisplayText(item) }}</ng-option>
          </ng-select> -->
    </div>
    <div class="col-6 amount-box">
      <span style="padding: 0 100px">Total Amount: {{totAmt | currency}}</span>
        <button mat-flat-button color="primary" (click)="addRow()" nzType="primary">Add New Check
        </button>
    </div>
    
  </div>
  
  <nz-table nzSize="small" #editRowTable nzBordered [nzData]="listOfData" [nzHideOnSinglePage]='true'
    [nzPageSize]='200'>
    <thead>
      <tr>
        <th nzWidth="20%">Select a User</th>
        <th>Employer Code </th>
        <th>SSN</th>
        <th>Member Number</th>
        <th>Suspense Balance</th>
        <th>Outstanding</th>
        <th>Auto Post</th>
        <th>Check Amount</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of editRowTable.data">
        <td>
          <ng-container *ngIf="!editCache[data.id].edit; else nameInputTpl">
            {{ data.name }}
          </ng-container>
          <ng-template #nameInputTpl>
            <!-- <input type="text" nz-input [(ngModel)]="editCache[data.id].data.name" /> -->
            <app-dynamic-autocomplete [dto]='dto' (selected)='selected($event,data.id)'>
            </app-dynamic-autocomplete>
          </ng-template>
        </td>

        <td>
          <ng-container *ngIf="!editCache[data.id].edit; else employerCodeInputTpl">
            {{ data.employerCode }}
          </ng-container>
          <ng-template #employerCodeInputTpl>
            {{ editCache[data.id].data.employerCode }}
          </ng-template>
        </td>

        <td>
          <ng-container *ngIf="!editCache[data.id].edit; else ssNumberInputTpl">
            {{ data.ssNumber }}
          </ng-container>
          <ng-template #ssNumberInputTpl>
            {{ editCache[data.id].data.ssNumber }}
          </ng-template>
        </td>

        <!-- <td>
          <ng-container *ngIf="!editCache[data.id].edit; else idNumberInputTpl">
            {{ data.externalId }}
            <span *ngIf="data.entityId == 'ac586073-69d6-41bf-875c-eb754f750457'">{{ data.externalId }}</span>
          </ng-container>
          <ng-template #idNumberInputTpl>
            {{ editCache[data.id].data.externalId }}
            {{ editCache[data.id].data.entityId }}
            <span *ngIf="data.entityId == 'ac586073-69d6-41bf-875c-eb754f750457'">{{ editCache[data.id].data.externalId }}</span>
          </ng-template>
        </td> -->

        <td>
          <ng-container *ngIf="!editCache[data.id].edit; else idNumberInputTpl">
            <span *ngIf="editCache[data.id].data.entityId != 'ac586073-69d6-41bf-875c-eb754f750457'">{{ data.idNumber
              }}</span>
            <span *ngIf="editCache[data.id].data.entityId == 'ac586073-69d6-41bf-875c-eb754f750457'">{{ data.externalId
              }}</span>
          </ng-container>
          <ng-template #idNumberInputTpl>
            <span *ngIf="editCache[data.id].data.entityId != 'ac586073-69d6-41bf-875c-eb754f750457'">{{
              editCache[data.id].data.idNumber }}</span>
            <span *ngIf="editCache[data.id].data.entityId == 'ac586073-69d6-41bf-875c-eb754f750457'">{{
              editCache[data.id].data.externalId }}</span>
          </ng-template>
        </td>

        <td>
          <ng-container *ngIf="!editCache[data.id].edit; else suspenseInputTpl">
            {{ data.suspense }}
          </ng-container>
          <ng-template #suspenseInputTpl>
            {{ editCache[data.id].data.suspense }}
          </ng-template>
        </td>

        <td>
          <ng-container *ngIf="!editCache[data.id].edit; else outstandingInputTpl">
            {{ data.outstanding }}
          </ng-container>
          <ng-template #outstandingInputTpl>
            {{ editCache[data.id].data.outstanding }}
          </ng-template>
        </td>

        <td>
          <ng-container *ngIf="!editCache[data.id].edit; else autopostInputTpl">
            {{ data.autopost }}
          </ng-container>
          <ng-template #autopostInputTpl>
            <label nz-checkbox [(ngModel)]="editCache[data.id].data.autopost"></label>
          </ng-template>
        </td>

        <td>
          <ng-container *ngIf="!editCache[data.id].edit; else amountInputTpl">
            {{ data.amount }}
          </ng-container>
          <ng-template #amountInputTpl>
            <input type="text" nz-size="small" nz-input [(ngModel)]="editCache[data.id].data.amount" />
          </ng-template>
        </td>

        <td>
          <div class="editable-row-operations">
            <ng-container *ngIf="!editCache[data.id].edit; else saveTpl">
              <a (click)="startEdit(data.id)" class="PatchFont">Edit</a> &nbsp;<a nz-popconfirm
                nzPopconfirmTitle="Are you sure you want to delete this?" (nzOnConfirm)="delete(data.id)"
                class="PatchFont">Delete</a>
            </ng-container>
            <ng-template #saveTpl>
              <a (click)="saveEdit(data.id)" class="PatchFont">Save</a> &nbsp;
              <a app-popconfirm abcTitle="Are you sure you want to cancel this?" class="PatchFont"
                (onOK)="cancelEdit(data.id)">Cancel</a>
              <!-- <a nz-popconfirm nzTitle="Are you sure you want to cancel this?" class="PatchFont" (nzOnConfirm)="cancelEdit(data.id)">Cancel</a> -->
            </ng-template>
          </div>
        </td>

      </tr>
    </tbody>
  </nz-table>

  <div class="col-12 submit-btn-box">
    <button mat-flat-button color="primary" (click)="submit()"
      nzType="primary">Submit</button>
  </div>
</div>