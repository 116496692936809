import { createSelector } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { SelectionState } from '@store/selection/selection.state';

export const getSelectionState = (state: AppState) => state.selection;

export const getSelectedEntity = createSelector(
  getSelectionState,
  (state: SelectionState) => state.entity
);

export const getSelectedClient = createSelector(
  getSelectionState,
  (state: SelectionState) => state.client
);
