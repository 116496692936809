import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '@shared/window.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements Storage {
  private storage: Storage
  logintype: string | undefined
  storagetype: string | undefined
  config: any
  [key: string]: any;
  length: number;

  constructor(
    @Inject(WINDOW) window: Window
    ) {
    if (!window) {
      throw Error('Browser window object is not available.');
    }

    this.config = window['___alliant-config-eniac-APP___']
    this.logintype = this.config.logintype
    this.storagetype=this.config.storagetype
    // fallback to sessionStorage if localStorage is not available
    this.storage = (this.storagetype === 'session' ) ? window.sessionStorage : window.localStorage;
  }

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string {
    return this.storage.getItem(key) || '';
  }

  key(index: number): string {
    return this.storage.key(index) || '';
  }

  removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    return this.storage.setItem(key, value);
  }
}
