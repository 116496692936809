import {Overlay,  OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import { ConnectionPositionPair, PositionStrategy } from '@angular/cdk/overlay';
import {
    ComponentPortal,
    PortalInjector,
    
  } from '@angular/cdk/portal';
import {Directive, ElementRef, HostListener,EventEmitter, Injector,Output,Input} from '@angular/core';

import { ConfirmOverlayComponent} from '@shared/components/confirmoverlay/confirmoverlay.component';
import { PopconfirmRef} from '@shared/components/confirmoverlay/popconfirm-ref';
@Directive({

    // tslint:disable-next-line:directive-selector
    selector: '[app-popconfirm]',
    
    })
    export class PopConfirmDirective {
      @Input()
      abcTitle;
      @Output()
      onOK: EventEmitter<any> = new EventEmitter();
        constructor(public overlay: Overlay,
            private hostElement: ElementRef,private injector: Injector
           ) {
           
          }

            @HostListener('click', ['$event.target'])
            onClick(btn) {
 
              const width='250px'
              const height='100px'
              const origin=this.hostElement;
              let  config=this.getOverlayConfig({ origin, width, height });           
                config.hasBackdrop = true;                
                let overlayRef = this.overlay.create(config);           
                overlayRef.backdropClick().subscribe(() => {
                  overlayRef.dispose();
                });
                const popoverRef=new PopconfirmRef(this.abcTitle);
                const myinjector = this.createInjector(popoverRef, this.injector);
                const popconfirmPortal = new ComponentPortal(ConfirmOverlayComponent,null,myinjector);

    // Attach ComponentPortal to PortalHost
                overlayRef.attach(popconfirmPortal);
                popoverRef.afterClosed$.subscribe(res => {
                  overlayRef.dispose();
                  if(res.data=='ok'){
                    this.onOK.emit();
                  }
                  
                  
              })
                return;
   
           }
           createInjector(popconfirmRef: PopconfirmRef, injector: Injector) {
            const tokens = new WeakMap([[PopconfirmRef, popconfirmRef]]);
            return new PortalInjector(injector, tokens);
          }
           private getOverlayConfig({ origin, width, height }): OverlayConfig {
            return new OverlayConfig({
              hasBackdrop: true,
              width,
              height,
              backdropClass: 'popover-backdrop',
              positionStrategy: this.getOverlayPosition(origin),
              scrollStrategy: this.overlay.scrollStrategies.reposition()
            });
          }
        
          private getOverlayPosition(origin: HTMLElement): PositionStrategy {
            const positionStrategy = this.overlay.position()
              .flexibleConnectedTo(origin)
              .withPositions(this.getPositions())
              .withFlexibleDimensions(false)
              .withPush(false);
        
            return positionStrategy;
          } 
          private getPositions(): ConnectionPositionPair[] {
            return [
              {
                originX: 'end',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom'
              },
              {
                originX: 'end',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
              },
              {
                originX : 'start',
                originY : 'center',
                overlayX: 'end',
                overlayY: 'center',
              },
              {
                originX : 'center',
                originY : 'bottom',
                overlayX: 'center',
                overlayY: 'top',
              }
            ]
          }
    }