<div style="padding: 0  0 40px 20px; border-left:1px solid rgb(200, 224, 224)">
    <!-- <h3>Quick links</h3> -->
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.bank)" class="member-quick-link"><i class="fa fa-university"></i>&nbsp;&nbsp;ACH Account</a><br>
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.beneficiaries)" class="member-quick-link"><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;Beneficiaries</a><br>
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.benefits)" class="member-quick-link"><i class="fa fa-list-alt"></i>&nbsp;&nbsp;Benefits</a><br>
    <a [routerLink]="getRouterLink('benefitssummary')" class="member-quick-link"><i class="fa fa-th-list"></i>&nbsp;&nbsp;Benefits Summary</a><br>
    <a [routerLink]="path1" class="member-quick-link"><i class="fa fa-list-ul"></i>&nbsp;&nbsp;Client List</a><br>
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.contacts)" class="member-quick-link"><i class="fa fa-address-book"></i>&nbsp;&nbsp;Contacts</a><br>
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.callLog)" class="member-quick-link"><i class="fa fa-user-circle-o" aria-hidden="true"></i>&nbsp;&nbsp;Customer Service</a><br>
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.deductions)" class="member-quick-link"><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;&nbsp;Deductions</a><br>
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.dependents)" class="member-quick-link"><i class="fa fa-user-plus"></i>&nbsp;Dependent</a><br>
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.documents)" class="member-quick-link"><i class="fa fa-folder-open-o" aria-hidden="true"></i>&nbsp;Documents</a><br>
    <a [routerLink]="[employerRoute]" class="member-quick-link"><i class="fa fa-bars" aria-hidden="true"></i>&nbsp;&nbsp;Employer Dashboard</a><br>
    <a [routerLink]="getRouterLink('letters/letters')" class="member-quick-link"><i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;Letter and Notices </a><br>
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.profile)" class="member-quick-link"><i class="fa fa-user" aria-hidden="true"></i>&nbsp;&nbsp;Member</a><br>
    <a [routerLink]="[memberLookupRoute]" class="member-quick-link"><i class="fa fa-search" aria-hidden="true"></i>&nbsp;Member Lookup</a><br>
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.refund)" class="member-quick-link"><i class="fa fa-reply" aria-hidden="true"></i>&nbsp;Refund/NSF</a><br>
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.dynamicReinstate)" class="member-quick-link"><i class="fa fa-retweet" aria-hidden="true"></i> Reinstate</a><br>
    <a [routerLink]="path" class="member-quick-link"><i class="fa fa-file-archive-o" aria-hidden="true"></i>&nbsp;&nbsp;Reports</a><br>
    <a [routerLink]="suspense" class="member-quick-link"><i class="fa fa-history" aria-hidden="true"></i>&nbsp;&nbsp;Suspense Mgmt</a><br>
    <!-- <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.adjustments)" class="member-quick-link">Adjustments</a><br> -->
    <a [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.ledger)" class="member-quick-link"><i class="fa fa-exchange" aria-hidden="true"></i> Transactions</a><br>
</div>