import { createSelector } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { MemberState } from '@store/main/member/member.state';

export const getMemberState = (state: AppState) => state.member;
const getDummyState = (state: MemberState) => state;
export const isMemberStateMutating = createSelector(
  getDummyState,
  (state: MemberState) => state.loading || state.saving || state.deleting
);

export const isMemberStateValid = createSelector(
  getDummyState,
  (state: MemberState) =>
    !state.loadError && !state.saveError && !state.deleteError
);
