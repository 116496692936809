import {
  Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, ChangeDetectionStrategy
} from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { MemberService } from '@services/client/member.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientService } from '@services/client/client.service';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';

@Component({
  selector: 'app-member-eisplan',
  templateUrl: './eisplan.component.html',
  //  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./../dynamic.component.scss']
})

export class EisPlanComponent extends BaseComponent implements OnInit {
  @Input()
  dropReason;
  @Input()
  currentEnrollment;
  @Input()
  set underWritingVal(underWritingVal) {
    this._underWritingVal = underWritingVal;
    if (underWritingVal != null && this.planForm != null) {
      this.planForm.value.underWritingVal = underWritingVal;
      this.planForm.patchValue(this.planForm.value);
    }
  }

  _underWritingVal;
  @Input()
  benefitEntityPolicy;
  @Input()
  set policyValue(policyValue) {
    if (policyValue) {
      this.coverage = policyValue['coverages'];
      this.tier = policyValue['tiers'];
      if (this.coverage != null && this.coverage.coverage != null && this.coverage.coverage !== 0) {
        if (this.initcoverage) {
          this.initcoverage = false;
          // this.getRate() ;
        }
      }
    }
  }

  @Input()
  set entityClientId(entityClientId) {
    if (entityClientId != null) {
      this.EntityClientId = entityClientId;
    }
  }

  EntityClientId;
  @Input()
  set entityId(entityId) {
    if (entityId != null) {
      this.EntityId = entityId;
    }
  }

  @Input()
  set clientId(clientId) {
    if (clientId != null) {
      this.ClientId = clientId;
    }
  }

  @Input()
  set memberId(memberId) {
    if (memberId != null) {
      this.MemberId = memberId;
    }
  }

  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  planForm: UntypedFormGroup;
  coverage;
  tier;
  EntityId: string;
  ClientId: string;
  MemberId: string;
  rate$: Observable<any>;
  eis$: Observable<any>;
  rates; eisDetails; ratemessage;
  initcoverage = true;
  constructor(public changeDetectorRef: ChangeDetectorRef, protected clientService: ClientService, private routeHelper: RouteHelperService,
    private route: ActivatedRoute, private memberService: MemberService, private fb: UntypedFormBuilder) {
    super();
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.ClientId = this.routeHelper.getClientId(this.route);
    this.MemberId = this.routeHelper.getMemberId(this.route);
  }

  Check(e) {
    // this.usedropdown = !e.target.checked;
    if (this.planForm.value.selectOriffDate == false) {
      this.planForm.value.oriEffDate = null;
      this.planForm.patchValue(this.planForm.value);
    }
  }

  getEffdatedisp() {
    if (this.planForm != null && this.planForm.value.tiers != null) {
      if (this.planForm.value.tiers.tierName === 'opt') {
        // this.changeDetectorRef.detectChanges() ;
        return 'Drop Date';
      }
    }
    if (this.planForm != null && this.planForm.value.tiercoverage != null) {
      if (this.planForm.value.tiercoverage.enrolled === false) {
        // this.changeDetectorRef.detectChanges() ;
        return 'Drop Date';
      }
    }
    return 'Effective Date';
  }

  onInitEis() {
    if (this.currentEnrollment != null) {
      const coverages: any = [];
      let childCov;
      let spauseCov;
      const childCovList: any = [];
      const spauseCovList: any = [];
      coverages.push({
        benefitEntityPolicyDetailCoverageValue: this.currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageValue,
        benefitEntityPolicyDetailCoverageId: this.currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageId,
        packageCode: this.currentEnrollment.coverages[0].packageCode,
        // coverageRelationShipCodes: this.currentEnrollment.coverages[0].coverageRelationShipCodes,
        classCode: this.currentEnrollment.coverages[0].classCode
      });
      const val = this.currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageValue;
      const code = this.currentEnrollment.coverages[0].coverageRelationShipCodes;
      const id = this.currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageId;
      if (code === 'C') {
        childCov = {};
        childCov.id = id;
        childCov.val = val;
        childCovList.push(childCov);
      }
      if (code === 'S') {
        spauseCov = {};
        spauseCov.id = id;
        spauseCov.val = val;
        spauseCovList.push(spauseCov);
      }
      const benefitEntityPolicyDetailId = this.currentEnrollment.benefitEntityPolicyDetailId;
      const EISDetail = {
        // tslint:disable-next-line:max-line-length
        'MinimumBenefit': { eisRateTableId: this.currentEnrollment.eisDetails.minimumBenefit != null ? this.currentEnrollment.eisDetails.minimumBenefit.eisRateTableId : '' },
        // tslint:disable-next-line:max-line-length
        'Integration': { eisRateTableId: this.currentEnrollment.eisDetails.integration != null ? this.currentEnrollment.eisDetails.integration.eisRateTableId : '' },
        // tslint:disable-next-line:max-line-length
        'survivor': { eisRateTableId: this.currentEnrollment.eisDetails.survivor != null ? this.currentEnrollment.eisDetails.survivor.eisRateTableId : '' },
        // tslint:disable-next-line:max-line-length
        'cola': { eisRateTableId: this.currentEnrollment.eisDetails.cola != null ? this.currentEnrollment.eisDetails.cola.eisRateTableId : '' },
      };
      const dtorate = {
        'clientId': this.clientId,
        'entityId': this.EntityId,
        'participantId': this.MemberId,
        'entityClientId': this.EntityClientId,
        'benefitEntityId': this.benefitEntityPolicy.benefitEntityId,
        'benefitEntityPolicyId': this.benefitEntityPolicy.benefitEntityPolicyId,
        benefitEntityPolicyDetailId,
        'effectiveDate': this.currentEnrollment.latestEnrollmentEffectiveDate,
        'appDate': this.currentEnrollment.latestEnrollmentDateTime,
        'Coverages': coverages,
        EISDetail
      };
      // tslint:disable-next-line:max-line-length
      this.rate$ = this.clientService.postWithUrl({ url: 'Enrollment/BenefitEntityPolicyDetailsRate', dto: dtorate }).pipe(untilComponentDestroyed(this));
      this.rate$.subscribe((data) => {
        if (data.isValid === false) {
          this.ratemessage = data.messages[0].message;
        } else {
          if (data.benefitEntityPolicyDetailRates.length === 0) {
            this.ratemessage = 'Please enter required fields. If issue persists, please contact admin team.';
          } else {
            this.rates = { ...data };
            this.ratemessage = '';
          }
        }
      });


      const dto = {
        'entityId': this.EntityId,
        'entityClientId': this.EntityClientId,
        'participantId': this.MemberId,
        'benefitEntityId': this.benefitEntityPolicy.benefitEntityId,
        'benefitEntityPolicyId': this.benefitEntityPolicy.benefitEntityPolicyId,
        benefitEntityPolicyDetailId,
        'effectiveDate': this.currentEnrollment.latestEnrollmentEffectiveDate,
        'appDate': this.currentEnrollment.latestEnrollmentDateTime,
        CoverageNumber: this.currentEnrollment.coverages[0].coverageCode,
        // CoverageNumber: this.planForm.value.coverages != null ? this.planForm.value.coverages.CoverageNumber : coverages[0].CoverageNumber,
        packageCode: this.currentEnrollment.coverages[0].packageCode,
        classCode: this.currentEnrollment.coverages[0].classCode,
        // tslint:disable-next-line:max-line-length
        CoverageAmount: this.currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageValue,
      };
      this.eis$ = this.clientService.postWithUrl({ url: 'Enrollment/BenefitEntityPolicyEISDetails', dto }).pipe(untilComponentDestroyed(this));
      this.eis$.subscribe((data) => {
        this.eisDetails = data.eisDetails;
      });
    }
  }
  QueryRate() {
    const coverages: any = [];
    let childCov;
    let spauseCov;
    const childCovList: any = [];
    const spauseCovList: any = [];
    if (this.planForm.value.coverages != null) {
      coverages.push({
        benefitEntityPolicyDetailCoverageValue: this.planForm.value.coverages.coverageValue,
        benefitEntityPolicyDetailCoverageId: this.planForm.value.coverages.coverage,
        packageCode: this.planForm.value.coverages.packageCode,
        CoverageNumber: this.planForm.value.coverages.CoverageNumber,
        classCode: this.planForm.value.coverages.classCode
      });
    } else {
      if (this.planForm.value.tiercoverage != null) {
        for (const property in this.planForm.value.tiercoverage) {
          if (property.startsWith('coverages')) {
            const val = this.planForm.value.tiercoverage[property].coverageValue;
            const code = this.planForm.value.tiercoverage[property].coverageRelationShipCodes;
            const id = this.planForm.value.tiercoverage[property].coverage;
            const packageCode = this.planForm.value.tiercoverage[property].packageCode;
            const classCode = this.planForm.value.tiercoverage[property].classCode;
            const selected = this.planForm.value.tiercoverage[property].selected;
            const CoverageNumber = this.planForm.value.tiercoverage[property].CoverageNumber;
            if (val != null && val !== '' && code === 'E' && (selected === true || selected == null)) {
              coverages.push({
                benefitEntityPolicyDetailCoverageValue: val,
                benefitEntityPolicyDetailCoverageId: id,
                packageCode,
                classCode,
                CoverageNumber
              });
            }
            if (code === 'C') {
              childCov = {};
              childCov.id = id;
              childCov.val = val;
              childCovList.push(childCov);
            }
            if (code === 'S') {
              spauseCov = {};
              spauseCov.id = id;
              spauseCov.val = val;
              spauseCovList.push(spauseCov);
            }
          }
        }
      }
    }

    const benefitEntityPolicyDetailId = (this.planForm.value.tiers != null) ? this.planForm.value.tiers.benefitEntityPolicyDetailId : null;
    let EISDetail = {};
    if (this.planForm.value.tiers != null) {
      EISDetail = {
        // tslint:disable-next-line:max-line-length
        'MinimumBenefit': [{ eisRateTableId: this.planForm.value.tiers.eisDetail.minimumBenefit }],
        'Integration': [{ eisRateTableId: this.planForm.value.tiers.eisDetail.integration }],
        'survivor': [{ eisRateTableId: this.planForm.value.tiers.eisDetail.survivor }],
        'cola': [{ eisRateTableId: this.planForm.value.tiers.eisDetail.cola }],
      };
    } else {
      if (this.planForm.value.tiercoverage != null && this.planForm.value.tiercoverage.eisDetail != null) {
        EISDetail = {
          // tslint:disable-next-line:max-line-length
          'MinimumBenefit': { eisRateTableId: this.planForm.value.tiercoverage.eisDetail.minimumBenefit },
          'Integration': { eisRateTableId: this.planForm.value.tiercoverage.eisDetail.integration },
          'survivor': { eisRateTableId: this.planForm.value.tiercoverage.eisDetail.survivor },
          'cola': { eisRateTableId: this.planForm.value.tiercoverage.eisDetail.cola },
        };
      }
    }

    const dtorate = {
      'clientId': this.clientId,
      'entityId': this.EntityId,
      'participantId': this.MemberId,
      'entityClientId': this.EntityClientId,
      'benefitEntityId': this.benefitEntityPolicy.benefitEntityId,
      'benefitEntityPolicyId': this.benefitEntityPolicy.benefitEntityPolicyId,
      benefitEntityPolicyDetailId,
      'effectiveDate': this.planForm.value.effactiveDate,
      'appDate': this.planForm.value.appDate,
      'Coverages': coverages,
      EISDetail
    };

    // Emit the form group to the father to do whatever it wishes
    this.formReady.emit(this.planForm);
    // tslint:disable-next-line:max-line-length
    this.rate$ = this.clientService.postWithUrl({ url: 'Enrollment/BenefitEntityPolicyDetailsRate', dto: dtorate }).pipe(untilComponentDestroyed(this));
    this.rate$.subscribe((data) => {
      if (data.isValid === false) {
        this.ratemessage = data.messages[0].message;
      } else {
        if (data.benefitEntityPolicyDetailRates.length === 0) {
          this.ratemessage = 'Please enter required fields. If issue persists, please contact admin team.';
        } else {
          this.rates = { ...data };
          this.ratemessage = '';
        }
      }
    });
    return coverages;
  }

  onChangeEis(e) {
    if (e === 'Detail') {
      this.QueryRate();
      return;
    }
    const coverages = this.QueryRate();
    let benefitEntityPolicyDetailId = (this.planForm.value.tiers != null) ? this.planForm.value.tiers.benefitEntityPolicyDetailId : null;
    if (benefitEntityPolicyDetailId == null) {
      // tslint:disable-next-line:max-line-length
      benefitEntityPolicyDetailId = (this.planForm.value.tiercoverage != null) ? this.planForm.value.tiercoverage.benefitEntityPolicyDetailId : null;
    }
    const dto = {
      'entityId': this.EntityId,
      'entityClientId': this.EntityClientId,
      'participantId': this.MemberId,
      'benefitEntityId': this.benefitEntityPolicy.benefitEntityId,
      'benefitEntityPolicyId': this.benefitEntityPolicy.benefitEntityPolicyId,
      benefitEntityPolicyDetailId,
      'effectiveDate': this.planForm.value.effactiveDate,
      'appDate': this.planForm.value.appDate,
      CoverageNumber: this.planForm.value.coverages != null ? this.planForm.value.coverages.CoverageNumber : coverages[0].CoverageNumber,
      packageCode: this.planForm.value.coverages != null ? this.planForm.value.coverages.packageCode : coverages[0].packageCode,
      classCode: this.planForm.value.coverages != null ? this.planForm.value.coverages.classCode : coverages[0].classCode,
      // tslint:disable-next-line:max-line-length
      CoverageAmount: this.planForm.value.coverages != null ? this.planForm.value.coverages.coverageValue : coverages[0].benefitEntityPolicyDetailCoverageValue,
    };
    this.eis$ = this.clientService.postWithUrl({ url: 'Enrollment/BenefitEntityPolicyEISDetails', dto }).pipe(untilComponentDestroyed(this));
    this.eis$.subscribe((data) => {
      this.eisDetails = data.eisDetails;
    });
  }
  onChangeCov(obj) {
    if (this.planForm == null) {
      return;
    }
    let benefitEntityPolicyDetailId = (this.planForm.value.tiers != null) ? this.planForm.value.tiers.benefitEntityPolicyDetailId : null;
    if (benefitEntityPolicyDetailId == null) {
      // tslint:disable-next-line:max-line-length
      benefitEntityPolicyDetailId = (this.planForm.value.tiercoverage != null) ? this.planForm.value.tiercoverage.benefitEntityPolicyDetailId : null;
    }
    const dto = {
      'entityId': this.EntityId,
      'entityClientId': this.EntityClientId,
      'participantId': this.MemberId,
      'benefitEntityId': this.benefitEntityPolicy.benefitEntityId,
      'benefitEntityPolicyId': this.benefitEntityPolicy.benefitEntityPolicyId,
      benefitEntityPolicyDetailId,
      'effectiveDate': this.planForm.value.effactiveDate,
      'appDate': this.planForm.value.appDate,
      CoverageNumber: this.planForm.value.coverages != null ? this.planForm.value.coverages.CoverageNumber : obj.CoverageNumber,
      packageCode: this.planForm.value.coverages != null ? this.planForm.value.coverages.packageCode : obj.packageCode,
      classCode: this.planForm.value.coverages != null ? this.planForm.value.coverages.classCode : obj.classCode,
      CoverageAmount: this.planForm.value.coverages != null ? this.planForm.value.coverages.coverageValue : obj.value,
    };
    this.eis$ = this.clientService.postWithUrl({ url: 'Enrollment/BenefitEntityPolicyEISDetails', dto }).pipe(untilComponentDestroyed(this));
    this.eis$.subscribe((data) => {
      this.eisDetails = data.eisDetails;
    });
    const coverages: any = [];
    if (obj != null) {
      coverages.push({
        benefitEntityPolicyDetailCoverageValue: obj.value,
        benefitEntityPolicyDetailCoverageId: obj.id,
        packageCode: obj.packageCode,
        classCode: obj.classCode
      });
      const dtorate = {
        'clientId': this.clientId,
        'entityId': this.EntityId,
        'participantId': this.MemberId,
        'entityClientId': this.EntityClientId,
        'benefitEntityId': this.benefitEntityPolicy.benefitEntityId,
        'benefitEntityPolicyId': this.benefitEntityPolicy.benefitEntityPolicyId,
        benefitEntityPolicyDetailId,
        'effectiveDate': this.planForm.value.effactiveDate,
        'appDate': this.planForm.value.appDate,
        'Coverages': coverages,

      };
      // tslint:disable-next-line:max-line-length
      this.rate$ = this.clientService.postWithUrl({ url: 'Enrollment/BenefitEntityPolicyDetailsRate', dto: dtorate }).pipe(untilComponentDestroyed(this));
      this.rate$.subscribe((data) => {
        if (data.isValid === false) {
          this.ratemessage = data.messages[0].message;
        } else {
          if (data.benefitEntityPolicyDetailRates.length === 0) {
            this.ratemessage = 'Please enter required fields. If issue persists, please contact admin team.';
          } else {
            this.rates = { ...data };
            this.ratemessage = '';
          }
        }
      });
    }
  }

  ngOnInit() {
    if (this.currentEnrollment != null && this.currentEnrollment.coverages[0] != null) {
      this.coverage = {
        coverage: this.currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageId,
        coverageValue: this.currentEnrollment.coverages[0].benefitEntityPolicyDetailCoverageValue,
        packageCode: this.currentEnrollment.coverages[0].packageCode,
        classCode: this.currentEnrollment.coverages[0].classCode,
        CoverageNumber: this.currentEnrollment.coverages[0].coverageCode,
      };
      this.onInitEis();
    }

    this.planForm = this.fb.group({
      'benefitEntityId': this.benefitEntityPolicy.benefitEntityId,
      'benefitEntityPolicyDescription': this.benefitEntityPolicy.benefitEntityPolicyDescription,
      'effactiveDate': this.benefitEntityPolicy.effectiveDate,
      'dropEffectiveDate': this.benefitEntityPolicy.dropEffectiveDate,
       'oriEffDate': null,
      underWritingVal: this._underWritingVal,
      isEISEnabled: true
    });
    // Emit the form group to the father to do whatever it wishes
    this.formReady.emit(this.planForm);
  }

  isSelected() {
    if (this.planForm != null) {
      return this.planForm.value.selectOriffDate
    }
    return false;
  }

  formInitialized(name: string, form: UntypedFormGroup) {
    // this.planForm.setControl(name, form);
    setTimeout(() => {
      this.planForm.setControl(name, form);
    });
  }
}
