import { Component, OnInit, Input } from '@angular/core';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { BaseComponent } from '@shared/components/base.component';
import { MEMBER_ROUTE_SEGMENT } from '@shared/route/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-member-quick-links-share',
  templateUrl: './member-quick-links.component.html',
  styleUrls: ['./member-quick-links.component.scss']
})
export class MemberQuickLinksComponent extends BaseComponent implements OnInit {
  readonly MEMBER_ROUTE_SEGMENT = MEMBER_ROUTE_SEGMENT;
  memberLookupRoute: string;
  employerRoute: string;
  @Input()
  prePath: string | null;
  @Input()
  entityId: string | null;
  path; 
  path1;
  suspense: string = '/main/suspense-management/memb';
  constructor(
    protected router: Router,
    private routeHelper: RouteHelperService) {
    super();
  }

  ngOnInit() {
    this.setEmplDashboardLink()
    this.memberLookupRoute = this.routeHelper.getMemberLookupRoute();
    this.memberLookupRoute = this.memberLookupRoute + '/memb';
    const tt = '/main/clients/' + this.entityId + '/reports';
    this.path = [tt];
    const tt1 = '/main/clients/' + this.entityId;
    this.path1 = [tt1];
  }

  setEmplDashboardLink() {
    if (this.router.url.includes('benefitssummary')) {
      this.employerRoute = '../../../'
    } else if (this.router.url.includes('member') ) {
      this.employerRoute= '../../../../';
    } else {
      this.employerRoute = '../../';
    }
  }

  getRouterLink(segment: string) {
    if (this.prePath == null) {
      this.prePath = '../../';
    }

    switch (segment) {
      case 'benefitssummary':
      case MEMBER_ROUTE_SEGMENT.dynamicReinstate:
        return this.prePath + `${segment}`;
      case MEMBER_ROUTE_SEGMENT.profile:
        if (this.entityId == null)
          return this.prePath + `dynamicform/${segment}`;
        if (this.entityId == 'ac586073-69d6-41bf-875c-eb754f750457')
          return this.prePath + `dynamicform/utcprofile`;
        if (this.entityId == '68bc62e6-dd96-4428-91e8-efe285bd23a0')
          return this.prePath + `dynamicform/apwuprofile`;
        if (this.entityId == '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d')
          return this.prePath + `dynamicform/horaceprofile`;
        if (this.entityId == '3b7805a6-10fa-48b5-b0ee-ea5365258341')
          return this.prePath + `dynamicform/essexprofile`;
        if (this.entityId == 'd6a2ff7e-bba0-48fa-9396-a6b202631669')
          return this.prePath + `dynamicform/cobraprofile`;
        if (this.entityId == 'c935affd-0e7c-4b2c-ae8b-1c9d0e53905b')
          return this.prePath + `dynamicform/tenetprofile`;
        if (this.entityId == 'f9d5f5e4-9e28-4a23-9cc3-9bb9d81d15e4')
          return this.prePath + `dynamicform/cscprofile`;
        if (this.entityId == 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028')
          return this.prePath + `dynamicform/profileanthem`;
        if (this.entityId == '9256dc20-03ae-469f-a45f-f66c5122f9ca')
          return this.prePath + `dynamicform/firefightersprofile`;
        if (this.entityId == 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b')
          return this.prePath + `dynamicform/leebaprofile`;
        if (this.entityId == '9808f856-f047-4843-bec8-277e414fc649')
          return this.prePath + `dynamicform/texasprofile`;
        return this.prePath + `dynamicform/${segment}`;
      case MEMBER_ROUTE_SEGMENT.bank:
        return this.prePath + `dynamicform/${segment}`;
      case MEMBER_ROUTE_SEGMENT.benefits:
        return this.prePath + `${segment}`;
      case MEMBER_ROUTE_SEGMENT.deductions:
        if (this.entityId == 'd6a2ff7e-bba0-48fa-9396-a6b202631669')
        return this.prePath + `dynamicgrid/cobradeductions`;
      case MEMBER_ROUTE_SEGMENT.dependents:
      case MEMBER_ROUTE_SEGMENT.contacts:
      case MEMBER_ROUTE_SEGMENT.beneficiaries:
      case MEMBER_ROUTE_SEGMENT.adjustments:
      case MEMBER_ROUTE_SEGMENT.documents:
      case MEMBER_ROUTE_SEGMENT.callLog:
      case MEMBER_ROUTE_SEGMENT.ledger:
        return this.prePath + `dynamicgrid/${segment}`;
      case MEMBER_ROUTE_SEGMENT.refund:
        return this.prePath + `/${segment}`;
      case 'letters/letters':
        return this.prePath + `/${segment}`;
    }
    return '';
  }
}

