<div class="app-form-group-wrapper">
  <div class="app-form-group-title"
       *ngIf="title">
    <div class="app-form-group-title-spacer">{{title}}</div>
  </div>
  <div class="app-form-group-body">
    <div class="container-fluid">
      <ng-content>
      </ng-content>
    </div>
  </div>
</div>