import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, combineLatest } from 'rxjs';
import {FormConfig} from '@shared/components/model/inputcontrol';
import { take, finalize, catchError, defaultIfEmpty } from 'rxjs/operators';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '@shared/components/base.component';
import { HttpService } from '@shared/api/http.service';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { DynamicDialogGridComponent} from '@shared/components/dynamic_grid/dynamic-grid.component';
import {  DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { SpinnerService } from '@shared/services/spinner.service';
import { ClientService } from '@services/client/client.service';
@Component({
    selector: 'app-legalcase',
    templateUrl: './legal-case.component.html',
    styleUrls: ['./legal-case.component.scss']

  })
  export class LegalCaseComponent extends BaseComponent  implements OnInit {
    searchFormValue; searchForm = 'legalSearch';
    _jsonURL = 'legalprintform';
    listOfData: any[] = [  ];
    memberInfo = {  };
    formConfigs: FormConfig[]; formValue;

    expandable = true; mapOfExpandData: { [key: string]: boolean } = {};
    // tslint:disable-next-line:max-line-length
    constructor(public dialog: MatDialog, private httpser: HttpService, protected spinner: SpinnerService, protected clientService: ClientService, private route: ActivatedRoute, private routeHelper: RouteHelperService ) {
      super();
    }

    public getJSON(): Observable<any> {
      // return this.http.get(this._jsonURL);
      return (
        this.httpser.get('json?path=' + this._jsonURL, {
              urlParams: null,
              options: {
                isMock: false
              }
            })
         );
    }
    onPrint(item) {
      this.spinner.show();
      const json$ = this.getJSON();
      const url = 'LegalManagement/LegalCaseV2?LegalCaseId=' + item.legalCaseId;
      const getForm$ = this.clientService.getWithUrl(url, {  });
      combineLatest(json$, getForm$)
      .pipe(take(1))
      .pipe(untilComponentDestroyed(this))
      .subscribe(all => {
        const json = JSON.parse((<any>all[0].jsonText) as string);
        this.formConfigs = json.FormConfig;
      this.formValue = all[1];
      this.spinner.hide();
      setTimeout(function () {
        window.print();
      }, 20);

      });

    }
    ngOnInit() {

    }
    expand(data, event) {
        if (event === true) {
          this.mapOfExpandData = {};
          this.mapOfExpandData[data.Id] = true;
        }
      }
      reload() {
        const url = 'LegalManagement/LegalParticipantsSearchNew';
        if (this.searchFormValue !=  null) {
            this.spinner.show();
            const observ$ = this.clientService.postWithUrl({url, dto: this.searchFormValue});
            observ$.subscribe((data: any) => {
              this.spinner.hide();
              this.listOfData = [...data];
              this.listOfData.map( x => {
                //  x.participantId = '79d56e26-2e1f-494c-897f-f7a089edfe66';
                  x.Id = x.participantId ;
                });

          },
          err=>{
            this.spinner.hide();
            
          }
          
          );

        }

      }
      onCallLog(data) {
        const  dialogRef = this.dialog.open(DynamicDialogGridComponent, {
          width: '90%',
         // height: '90%',
          data: {
            entityId: '68bc62e6-dd96-4428-91e8-efe285bd23a0',
            entityClientId: data.entityClientId,
            clientId: '',
            participantId: data.participantId,
            formid: 'call-log'
          }
        });
      }
    public onSearch() {
        const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
         width: '90%',
         // height: '90%',
           data: {
             addNew: false,
             item: this.searchFormValue,
             formid: this.searchForm,
             prePath: '',
           }
         });
         dialogRef.afterClosed().subscribe(result => {
           if (result != null) {
           //  this.searchData = result;
             this.searchFormValue = result;
             this.listOfData = [];
             this.reload();

           }

         });

         }
  }
