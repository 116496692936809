import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefits-config',
  templateUrl: './benefits-config.component.html',
  styleUrls: ['./benefits-config.component.scss']
})
export class BenefitsConfigComponent implements OnInit {

  entities

  constructor() { }

  ngOnInit(): void {
    this.entities = 
    {
      icon: './assets/img/CI_icon.png',
      title: 'Critical Illness',
      vendors: 'The Hartfort',
      numEnrollments: '20111',
      numDependents: '9072',
      lastBillAmt: '70119',
      isEvergreen: 'Yes',
      isABC: 'No'
    } 
  }
}
