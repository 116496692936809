import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiModule} from '@shared/ui.module';
import {EnterpriseDashboardRoutingModule} from './enterprise-dashboard-routing.module';
import {EnterpriseAdminDashboardComponent, TableFilterPipe} from './enterprise-admin-dashboard.component';
import {BenefitsConfigComponent} from './entrprise-components/benefits-config/benefits-config.component';
import {CompareScreenComponent} from './entrprise-components/compare-screen/compare-screen.component';
import {BenefitComponent} from './entrprise-components/benefit/benefit.component';
import {ClientComponent} from './entrprise-components/client/client.component';
import {GeneralComponent} from './entrprise-components/general/general.component';
import {UIConfigureComponent} from './entrprise-components/ui-configure/ui-configure.component';

import {GlobalConfigComponent} from './entrprise-components/global-config/global-config.component';
import {LoginRegistrationComponent} from './entrprise-components/login-registration/login-registration.component';
import {ProductsComponent} from './entrprise-components/products/products.component';
import {EnterpriseMainComponent} from './entrprise-components/enterprise-main.component';
import {EnterpriseDashboardMainComponent} from './enterprise-dashboard-main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    TableFilterPipe,
    EnterpriseAdminDashboardComponent,
    BenefitsConfigComponent,
    CompareScreenComponent,
    GeneralComponent,
    GlobalConfigComponent,
    LoginRegistrationComponent,
    ProductsComponent,
    EnterpriseMainComponent,
    EnterpriseDashboardMainComponent,
    BenefitComponent,
    ClientComponent,
    UIConfigureComponent
  ],
  imports: [
    UiModule,
    CommonModule,
    EnterpriseDashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class EnterpriseDashboardModule { }
