<table class="table table-bordered">
    <!-- <thead>
      <tr>
        <th scope="col-6">test</th>
        <th scope="col-6">test</th>
      </tr>
    </thead> -->
    <tbody>
      <tr>
        <td>Configure SSO?</td>
        <td class="value">YES</td>
      </tr>
      <tr>
        <td>SSO Timeout Link</td>
        <td class="value">https://idp.aa.com/idp/startSSO.ping?PartnerSpId=ABC_AA_AA</td>
      </tr>
      <tr>
        <td>Profile Page Image</td>
        <td class="value">N/A</td>
      </tr>
      <tr>
        <td>Program Name</td>
        <td class="value">Added Benefits</td>
      </tr>
      <tr>
        <td>New Hire Message</td>
        <td class="value">Welcome to AA Added Benefits Program</td>
      </tr>
      <tr>
        <td>Custom New Hire Message Text</td>
        <td class="value">N/A</td>
      </tr>
      <tr>
        <td>OE Message</td>
        <td class="value">Welcome to the Added Benefits Program for American Airlines!</td>
      </tr>
      <tr>
        <td>Custom OE Message Text</td>
        <td class="value">N/A</td>
      </tr>
      <tr>
        <td>Standard Email Template</td>
        <td class="value">AmericanAirlines@addedbenefits.com</td>
      </tr>
      <tr>
        <td>Allow Employee Enlrollment due to QLE</td>
        <td class="value">YES</td>
      </tr>  
    </tbody>
  </table>