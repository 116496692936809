import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'clientlist' })
export class ClientList implements PipeTransform {
  transform(value): string {
    var listnames = '';
    value.forEach(it => {
      switch (it) {
        case 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b':
          listnames = listnames + ' LEEBA,';
          break;
        case '9808f856-f047-4843-bec8-277e414fc649':
          listnames = listnames + ' TMAIT,';
          break;
        case 'c935affd-0e7c-4b2c-ae8b-1c9d0e53905b':
          listnames = listnames + ' Tenet,';
          break;
        case '3b7805a6-10fa-48b5-b0ee-ea5365258341':
          listnames = listnames + ' Essex,';
          break;
        case 'ac586073-69d6-41bf-875c-eb754f750457':
          listnames = listnames + ' UTC,';
          break;
        case '68bc62e6-dd96-4428-91e8-efe285bd23a0':
          listnames = listnames + ' APWU,';
          break;
        case 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028':
          listnames = listnames + ' Anthem,';
          break;
        case 'd6a2ff7e-bba0-48fa-9396-a6b202631669':
          listnames = listnames + ' COBRA,';
          break;
        case '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d':
          listnames = listnames + ' Horace Mann,';
          break;
        case '31e56172-ac96-487c-a6b3-d5847954f3b2':
          listnames = listnames + ' UNUM,';
          break;
        case 'f9d5f5e4-9e28-4a23-9cc3-9bb9d81d15e4':
          listnames = listnames + ' SafetyNet,';
          break;
        default:
          listnames = listnames + ' ';
      }
    })

    return listnames;
  }
}

@Pipe({ name: 'rolelist' })
export class RoleList implements PipeTransform {
  transform(value): string {
    var rolenames = '';
    value.forEach(it => {
      switch (it) {
        case '3a6b9f38-e8c2-4fc1-a6e1-04b55139f83f':
          rolenames = rolenames + ' APIUser,';
          break;
        case '6fe1f373-f810-43f0-883e-2d21abc513f0':
          rolenames = rolenames + ' Configuration Manager,';
          break;
        case '581f538a-89db-4c00-a678-6818f8cc3593':
          rolenames = rolenames + ' Administration Manager,';
          break;
        case '110c9594-b146-4344-bf82-9034c66d8bda':
          rolenames = rolenames + ' Admin Global,';
          break;
        case '9f332003-c38e-4751-8f14-9d86074cf937':
          rolenames = rolenames + ' LegalUser,';
          break;
        case 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028':
          rolenames = rolenames + ' Anthem,';
          break;
        case '9eba25ef-56b8-4d5a-8e91-c82d3f3d776b-bba0-48fa-9396-a6b202631669':
          rolenames = rolenames + ' B2BJob,';
          break;

        default:
          rolenames = rolenames + ' ';
      }
    })
    return rolenames;
  }
}