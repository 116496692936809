<div class="row no-gutters">
  <div class="col-12 mt-2">
    <h4>
      <ng-container *ngIf="client!=null && _entityId==null">
      <span >{{client?.entityShortName}}&nbsp;<span *ngIf="client?.entityClientName != null"> /&nbsp;</span> {{client?.entityClientName}}<span *ngIf="client?.clientNumber!= null && client?.clientNumber != ''">({{client?.clientNumber}})</span><span *ngIf="client?.entityClientNumber != null && client?.entityClientNumber != ''"> /</span> {{client?.entityClientNumber}}</span>
      </ng-container>
      <div *ngIf="_entityId!=null">
          <span > {{entityName}}</span>
      </div>
    </h4>

  </div>
</div>

