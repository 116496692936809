import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicFormWithGridContainnerComponent } from '@shared/components/dynamic_form_with_grid/dynamic_form_with_grid.component';
import { DynamicMainFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';

@Component({
  selector: 'app-fileformgrid',
  templateUrl: './fileformgrid.component.html',

})
export class FileFormGridComponent extends DynamicFormWithGridContainnerComponent implements OnInit {
  detail; header; trailer; fileTranslatorId; entityId; addHeader = false; addTrailer = false; addDetail = false; newHeader: any = {};
  newTrailer: any = {}; newDetail: any = {};
  editCache: { [key: string]: any } = {};
  editHeaderCache: { [key: string]: any } = {};
  editTrailerCache: { [key: string]: any } = {};
  saved;
  addHeaderRow() {

    this.addHeader = true;
  }
  addDetailRow() {

    this.addDetail = true;
  }
  addTrailerRow() {

    this.addTrailer = true;
  }
  cancelHeaderNew() {
    this.newHeader = {};
    this.addHeader = false;
  }
  cancelDetailNew() {
    this.newDetail = {};
    this.addDetail = false;
  }
  cancelTrailerNew() {
    this.newTrailer = {};
    this.addTrailer = false;
  }
  getMaxIndex(list) {
    return Math.max(...list.map(d => d.index));
  }
  saveHeaderNew() {

    const lastIndex = this.getMaxIndex(this.header) + 1; // this.header.length + 1 ;
    this.newHeader.index = lastIndex;
    this.header = [...this.header, { ...this.newHeader }];
    this.editHeaderCache[lastIndex] = {
      edit: false,
      data: { ...this.newHeader }
    };
    this.newHeader = {};
    this.addHeader = false;
  }
  saveDetailNew() {

    const lastIndex = this.getMaxIndex(this.detail) + 1; // this.header.length + 1 ;
    this.newDetail.index = lastIndex;
    this.detail = [...this.detail, { ...this.newDetail }];
    this.editCache[lastIndex] = {
      edit: false,
      data: { ...this.newDetail }
    };
    this.newDetail = {};
    this.addDetail = false;
  }
  saveTrailerNew() {

    const lastIndex = this.getMaxIndex(this.detail) + 1; // this.header.length + 1 ;
    this.newTrailer.index = lastIndex;
    this.trailer = [...this.trailer, { ...this.newTrailer }];
    this.editTrailerCache[lastIndex] = {
      edit: false,
      data: { ...this.newTrailer }
    };
    this.newTrailer = {};
    this.addTrailer = false;
  }
  startTrailerEdit(id: string): void {
    this.editTrailerCache[id].edit = true;
  }
  startHeaderEdit(id: string): void {
    this.editHeaderCache[id].edit = true;
  }
  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }
  cancelTrailerEdit(id: string): void {
    const index = this.header.findIndex(item => item.ID === id);
    this.editTrailerCache[id] = {
      data: { ...this.trailer[index] },
      edit: false
    };
  }
  cancelHeaderEdit(id: string): void {
    const index = this.header.findIndex(item => item.ID === id);
    this.editHeaderCache[id] = {
      data: { ...this.header[index] },
      edit: false
    };
  }
  cancelEdit(id: string): void {
    const index = this.detail.findIndex(item => item.index === id);
    this.editCache[id] = {
      data: { ...this.detail[index] },
      edit: false
    };
  }
  saveTrailerEdit(id: string): void {
    const index = this.trailer.findIndex(item => item.ID === id);
    Object.assign(this.trailer[index], this.editTrailerCache[id].data);
    this.editTrailerCache[id].edit = false;
  }
  saveHeaderEdit(id: string): void {
    const index = this.header.findIndex(item => item.ID === id);
    Object.assign(this.header[index], this.editHeaderCache[id].data);
    this.editHeaderCache[id].edit = false;
  }
  saveEdit(id: string): void {
    const index = this.detail.findIndex(item => item.index === id);
    Object.assign(this.detail[index], this.editCache[id].data);
    this.editCache[id].edit = false;
  }
  deleteTrailerRow(id: string): void {
    this.trailer = this.trailer.filter(d => d.index !== id);
  }
  deleteHeaderRow(id: string): void {
    this.header = this.header.filter(d => d.index !== id);
  }
  deleteRow(id: string): void {
    this.detail = this.detail.filter(d => d.index !== id);
  }
  ngOnInit() {
    const url = 'getFileTranslatorById?id=' + this.formValue.fileId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      const json = JSON.parse((<any>data.layout) as string);
      this.detail = json.Detail;
      this.header = json.Header;
      this.trailer = json.Trailer;
      this.fileTranslatorId = data.fileTranslatorId;
      if (this.detail != null) {
        let index = 1;
        this.detail.forEach(element => {
          element.index = index;
          index = index + 1;
        });
        this.detail.forEach(item => {
          this.editCache[item.index] = {
            edit: false,
            data: { ...item }
          };
        });
      }
      if (this.header != null) {
        let index = 1;
        this.header.forEach(element => {
          element.index = index;
          index = index + 1;
        });
        this.header.forEach(item => {
          this.editHeaderCache[item.index] = {
            edit: false,
            data: { ...item }
          };
        });
      }
      if (this.trailer != null) {
        let index = 1;
        this.trailer.forEach(element => {
          element.index = index;
          index = index + 1;
        });
        this.trailer.forEach(item => {
          this.editTrailerCache[item.index] = {
            edit: false,
            data: { ...item }
          };
        });
      }
    });
  }
  onCancel() {
    this.dialogRef.close(this.saved);
  }
  Save() {
    const obj = this.memberComponent.getFormValue();
    const jsonobj: any = {};
    jsonobj.Detail = this.detail;
    jsonobj.Header = this.header;
    jsonobj.Trailer = this.trailer;
    const myJSON = JSON.stringify(jsonobj);
    const dto: any = {};
    dto.Name = obj['fileName'];
    dto.FileTranslatorId = obj['fileId'];
    dto.processTypeId = obj['processTypeId'];
    dto.Layout = myJSON;
    const observ$ = this.clientService.postWithUrl({ url: 'file/updateFileB2B', dto });
    observ$.subscribe( res => {

      this.notification.blank('Saved  successfully', ' ');
      this.saved = true;
    }, err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }
}
