import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, Pipe, PipeTransform, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ClientService } from '@services/client/client.service';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-member-tier',
  templateUrl: './tier.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./../dynamic.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class TierComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input()
  dropReason;
  @Input()
  rateLookpType;
  @Input()
  currentEnrollment;
  @Input()
  set entityId(entityId) {
    if (entityId != null) {
      this.EntityId = entityId;
    }

  }
  @Input()
  set clientId(clientId) {
    if (clientId != null) {
      this.ClientId = clientId;
    }

  }
  @Input()
  set memberId(memberId) {
    if (memberId != null) {
      this.MemberId = memberId;
    }

  }
  @Input()
  set entityClientId(entityClientId) {
    if (entityClientId != null) {
      this.EntityClientId = entityClientId;
    }

  }
  @Input()
  benefitEntityPolicy;
  @Input()
  coverage;
  @Input()
  tiers;
  @Input()
  set tierValue(tierValue) {
    this._tierValue = tierValue;
    if (tierValue) {
      this.tier = tierValue['tier'];
      this.enrollmentEffectiveDate = tierValue['enrollmentEffectiveDate'];
      this.tierName = tierValue['tierName'];
      this.enrolled = tierValue['enrolled'];
      this.beneficiaries = tierValue['beneficiaries'];
      if (this.beneficiaries == null) {
        this.beneficiaries = [];
      }
      this.oldTier = tierValue['oldTier'];
      if (this.tier) {
        this.tiers.forEach(element => {
          if (element.benefitEntityPolicyDetailId === this.tier) {
            this._tierValue['tierName'] = element.benefitEntityPolicyDetailName;
            this.tierName = element.benefitEntityPolicyDetailName;
          }
        });
      } else {
        this._tierValue['tierName'] = null;
      }

    }
  }
  _tierValue;
  @Input()
  set rate(rate) {
    this._rate = rate;
    if (rate != null) {
      this.payFrequency = rate.payFrequency;
    }
    setTimeout(() => {

      this.setrate();
    });

  }
  _rate;
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() change = new EventEmitter<any>();
  @Output() changeCoverage = new EventEmitter<any>();
  @Output() tierChange = new EventEmitter<any>();
  payFrequency = '';
  EntityId: string;
  ClientId: string;
  MemberId: string; EntityClientId;
  selectedLevel;
  // dropReason;
  selected; tier; tierName; enrollmentEffectiveDate; enrolled; currate;
  coverages; beneficiaries; oldTier; tierForm: UntypedFormGroup;
  constructor(protected clientService: ClientService, private fb: UntypedFormBuilder, private routeHelper: RouteHelperService, private route: ActivatedRoute) {
    super();
    this.EntityId = this.routeHelper.getEntityId(this.route);
  }
  ngAfterViewInit() {
    // child is set

  }
  onChangeLevel(event) {
    if (event.target != null) {
      this.selectedLevel = event.target.value;
      // this.tierForm.value['tier'] = '';
      // this.tierForm.patchValue(this.tierForm.value);
    }
  }
  getOpted() {
    setTimeout(() => {
      this.tierForm.value['tierName'] = 'opt';
      this.tierForm.patchValue(this.tierForm.value);
    });
  }
  getSelected(item, benefitEntityPolicyDetailId, rate): boolean | null {
    let tierName;
    if (benefitEntityPolicyDetailId != null) {
      // get name
      // tslint:disable-next-line:max-line-length
      const f = this.benefitEntityPolicy.tiers.find(t => t.benefitEntityPolicyDetailId === benefitEntityPolicyDetailId);
      if (f != null) {
        tierName = f.benefitEntityPolicyDetailName;
        this.tierName = f.benefitEntityPolicyDetailName;
        setTimeout(() => {
          this.tierForm.value['tierName'] = tierName;
          this.tierForm.patchValue(this.tierForm.value);
          this.setrate();
        });
      }
    }
    let find = false;
    this.enrolled.length = 0;
    // adjust rate
    if (rate == null) {

      this.tierChange.emit();
      /* if(item.coverages==null){
        this.tierChange.emit();
      } */

      return true;
    }
    rate.benefitEntityPolicyDetailRates.forEach(element => {
      if (element.benefitEntityPolicyDetailId === benefitEntityPolicyDetailId) {
        find = true;
        setTimeout(() => {
          this.tierForm.value['rate'] = element.rate;
          this.tierForm.value['custrate'] = element.rate;
        });

      }
    });
    if (!find) {
      this.rate = null;
    }
    this.tierChange.emit();
    /* if(item.coverages==null){
      this.tierChange.emit();
    } */

    return true;

  }
  findSelected(benefitEntityPolicyDetailId) {
    if (this.tierForm.value['tier'] === benefitEntityPolicyDetailId) {
      return true;
    }
    return false;
  }
  getRate(rate, benefitEntityPolicyDetailId, tierName) {
    let ret;
    if (rate != null) {
      if (rate.benefitEntityPolicyDetailId === benefitEntityPolicyDetailId) {
        if (rate.isUseMemberTableRate) {
          ret = rate.customRate;
        } else {
          ret = rate.rate;
        }

      }

    }
    return (ret != null) ? ret : '';

  }
  setrate() {
    if (this._rate != null) {
      if (this.tierForm != null) {
        const benefitEntityPolicyDetailRates = this._rate.benefitEntityPolicyDetailRates;
        let totrate = null;
        benefitEntityPolicyDetailRates.forEach(element => {
          if (this.tierForm.value['tier'] === element.benefitEntityPolicyDetailId && (element.contributorCode == 'PR' || element.contributorCode == '' || element.contributorCode == null)) {
            if (element.isUseMemberTableRate == true) {
              totrate = (totrate == null ? 0 : totrate) + element.customRate;
            } else {
              totrate = (totrate == null ? 0 : totrate) + element.rate;
            }

            if (this.tierForm.value.ratePR != '') {
              if (element.isUseMemberTableRate == true) {
                this.tierForm.value['ratePR'] = element.customRate;
              } else {
                this.tierForm.value['ratePR'] = element.rate;
              }

            }
          }
          if (this.tierForm.value['tier'] === element.benefitEntityPolicyDetailId && element.contributorCode == 'ER') {

            if (this.tierForm.value.rateER != '') {
              this.tierForm.value['rateER'] = element.rate;
            }
          }
        });
        if (totrate != null) {
          this.tierForm.value['rate'] = totrate;
          this.tierForm.patchValue(this.tierForm.value);
        }

      }

    }
  }

  ngOnInit() {
    let level;
    if (this.enrolled == null) {
      this.enrolled = [];
    }
    if (this.beneficiaries == null) {
      this.beneficiaries = [];
    }
    // get level id
    if (this.benefitEntityPolicy.level.length > 1) {
      if (this.tier != null && this.tier !== '') {
        const f = this.tiers.find(t => t.benefitEntityPolicyDetailId === this.tier);
        if (f != null) {
          level = f.levelId;
          this.selectedLevel = level;
        }
      }
    }
    this.tierForm = this.fb.group({
      tier: this.tier,
      level,
      optReason: '',
      rate: this.currate,
      ratePR: '',
      rateER: '',
      custrate: this.currate,
      tierName: this.tierName,
      enrolled: [this.enrolled],
      beneficiaries: [this.beneficiaries],
      oldTier: this.oldTier,
      enrollmentEffectiveDate: this.enrollmentEffectiveDate,
    });
    this.tierForm.valueChanges.subscribe(val => {

    });
    this.setrate();
    // Emit the form group to the father to do whatever it wishes
    this.formReady.emit(this.tierForm);
    // get drop reason code
    // this.tierChange.emit();
    /* const url = 'Enrollment/EntityCodesLookup/' + this.EntityId + '/EnrollmentTerminationCode'  ;
      const observ$ = this.clientService.getWithUrl(url, { });
      observ$.subscribe((data) => {
          this.dropReason = data;
      }); */
  }
  formInitialized(name: string, form: UntypedFormGroup) {

    setTimeout(() => {
      this.tierForm.setControl(name, form);
      // this.tierChange.emit();
    });

  }
  onChangeTierCoverage(event) {

    /* if (event.id != null) {
      this.changeCoverage.emit(event);
    } */
    this.changeCoverage.emit(event);
  }
  onChangeDep(item) {
    if (item.coverages && item.uiControlType === 'object') {
      this.changeCoverage.emit(event);
    } else {
      this.tierChange.emit();
    }
  }
  onChange(event) {
    this.change.emit(event);

  }
  modelChanged(e) {
    const ratePr = this.tierForm.value.ratePR == '' ? 0 : parseFloat(this.tierForm.value.ratePR);
    const rateEr = this.tierForm.value.rateER == '' ? 0 : parseFloat(this.tierForm.value.rateER);

    this.tierForm.value.rate = ratePr;//+rateEr;
    this.tierForm.patchValue(this.tierForm.value);
  }
}


