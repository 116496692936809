import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-email',
  templateUrl: './contact-email.component.html',
  styleUrls: ['./contact-email.component.scss']
})
export class ContactEmailComponent implements OnInit {
  @Input()
  emailFormGroup: UntypedFormGroup;

  constructor() {}

  ngOnInit() {}
}
