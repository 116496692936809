import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '@services/auth/auth.service';
import { ClientService } from '@services/client/client.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { AppState } from '@store/app.state';
import { LogoutAction } from '@store/auth/auth.actions';
import { AuthState } from '@store/auth/auth.state';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetform: UntypedFormGroup;
  authState: AuthState;
  loading = false;
  userId: string
  prevPassword: string
  newPassword: string
  IsPasswordReset: any
  isPasswordLength: boolean
  isPasswordUppercase: boolean
  isPasswordDidgit: boolean
  isPasswordSpecial: boolean

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private spinner: SpinnerService,
    protected clientService: ClientService,
    private store: Store<AppState>,
    private notification: NzNotificationService
  ) {

  }

  ngOnInit() {
    this.initializeForm()
    this.userId = this.authService.getUserId()
    this.IsPasswordReset = this.authService.getIsPasswordReset()
  }

  onSubmit() {
    if (this.resetform.valid) {
      const pwdPayload = {
        userId: this.userId,
        OldPassword: this.resetform.value.prevPassword,
        NewPassword: this.resetform.value.newPassword
      }

      const observ$ = this.clientService.postWithUrl({ url: `/Security/ResetPassword`, dto: pwdPayload });
      observ$.subscribe( res => {
        this.logout()
        this.notification.blank(res, ' ');
        this.router.navigateByUrl('/login');
      }, err => {
        this.notification.blank(err.error[0], ' ');
      })
    } else {
      this.resetform.markAllAsTouched()
    }
  }

  passwordMatchValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    let password = control.get('newPassword').value;
    let repeatedPassword = control.get('repeatPassword').value;
    return password === repeatedPassword ? null : { 'mismatch': true }
  }

  private initializeForm() {
    this.resetform = this.fb.group({
      prevPassword: ['', { validators: [Validators.required] }],
      newPassword: ['', {
        validators: [
          Validators.required,
          Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$+*!=])[A-Za-z0-9@#$+*!=].{15,}')
        ]
      }],
      repeatPassword: ['', { validators: [Validators.required] }]
    }, { validators: this.passwordMatchValidator });
  }

  logout() {
    this.store.dispatch(new LogoutAction());
  }

  onPasswordValidation(event: any) {
    //Length validation
    if (this.resetform.get('newPassword').value.length >= 16) {
      this.isPasswordLength = true
    }
    else {
      this.isPasswordLength = false
    }

    // Validation for uppercase and lowercase
    if ((/^(?=.*[a-z]).+$/.test(this.resetform.get('newPassword').value.trim()) == true) && (/^(?=.*[A-Z]).+$/.test(this.resetform.get('newPassword').value.trim()) == true)) {
      this.isPasswordUppercase = true
    }
    else {
      this.isPasswordUppercase = false
    }

    //Validation for one letter and one number
    if ((/^(?=.*\d).+$/.test(this.resetform.get('newPassword').value.trim()) == true)) {
      this.isPasswordDidgit = true
    }
    else {
      this.isPasswordDidgit = false
    }

    // Validation For special characters
    if (/^(?=.*?[@#$+*!=])/.test(this.resetform.get('newPassword').value.trim()) == true) {
      this.isPasswordSpecial = true
    }
    else {
      this.isPasswordSpecial = false
    }
  }
}
