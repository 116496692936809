import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-group-wrapper',
  templateUrl: './form-group-wrapper.component.html',
  styleUrls: ['./form-group-wrapper.component.scss']
})
export class FormGroupWrapperComponent implements OnInit {
  @Input('title')
  title: string;

  constructor() {}

  ngOnInit() {}
}
