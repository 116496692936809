<div style="padding: 15px 15px 5px 15px">
  <!-- <div class="top-dropdown">
    <ng-select placeholder="Select a Client" [(ngModel)]="filterText">
      <ng-container *ngFor="let item of entities">
        <ng-option value='{{item.entityId}}'><a [routerLink]="['/main/enterprise-client', item.entityId]">{{item.name}}</a></ng-option>
      </ng-container>
    </ng-select>
  </div> -->
  <div class="col-12 mt-15" style="max-width: 500px;">
    <form [formGroup]="searchForm">
      <input class="form-control" formControlName="searchInput"
        style="padding: 0 20px; border-radius: 20px !important; box-shadow: 2px 4px 8px 0px rgba(0,0,0,0.2); background-color: rgb(244, 244, 244);"
        placeholder="Search" (keyup)="onKey($event)" [(ngModel)]="filterText">
    </form>
  </div>
</div>
<div style="padding: 5px 30px; display: flex; flex-direction: row;">
  <div *ngFor="let letterItem of abcArr"
    style="cursor: pointer; margin: 0 5px; font-size: 16px; font-weight: 450; color:#3c7c9f"
    (click)="filterByFirstLetter(letterItem)">
    <u>{{letterItem}}</u>
  </div>
  <div style="cursor: pointer; margin: 0 15px; font-size: 16px; font-weight: 450; color:#3c7c9f"
    (click)="onShowAll();onSearchReset()">
    <u>Show All</u>
  </div>
</div>
<div class="col-12" style="background: rgba(72, 196, 203) !important;height: 1px; ">
</div>


<div class="col-12" style="padding: 0px 15px;">
  <div class="row col-12">
    <ng-container *ngFor="let item of filteredClients | filter:filterText; trackBy:trackClient;">
      <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12">
        <div>
          <a [routerLink]="['/main/enterprise-client', item.entityId]">
            <mat-card class="tile-card-format">
              <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <div *ngIf="item.logoPath && item.logoPath !=''"><img
                    style="width: 100px; height: 40px; object-fit:contain;"
                    src="https://staging.abcsys.com/EnterpriseWebAPI/content/{{item.enterpriseClientId}}/Images/Client/{{item.logoPath}}"
                    alt="Client logo for {{item.name}}"></div>
                <div *ngIf="item.logoPath && item.logoPath !=''; else elseBlock">
                  <span style="font-size: 1rem; font-weight: 450;">{{item.name}}</span>
                </div>
                <ng-template #elseBlock>
                  <span style="margin: 0  auto; font-size: 1.25rem; font-weight: 500;">{{item.name}}</span>
                </ng-template>
              </div>
            </mat-card>
          </a>
        </div>
      </div>
    </ng-container>
    <!-- <div class="col-12" style="border-top: 1px solid #48c4cb;"></div> -->
    <div *ngIf="filteredClients.length == 0" class="col-12 mt-5"
      style="display: flex; align-items:center; justify-content:center; flex-direction: column;">
      <div>
        <h2>Welcome to the Added Benefits</h2>
      </div>
      <div>
        <h2>Administrative Console</h2>
      </div>
    </div>
  </div>
</div>