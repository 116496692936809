import {
  Component,
  OnInit,
  ViewChild,
  Input,
  forwardRef,
  Renderer2,
  ElementRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { DecimalFormatterService } from '@shared/formatters/decimal-formatter.service';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'app-decimal-input',
  templateUrl: './decimal-input.component.html',
  styleUrls: ['./decimal-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DecimalInputComponent),
      multi: true
    }
  ]
})
export class DecimalInputComponent extends BaseComponent
  implements OnInit, ControlValueAccessor {
  // readonly Icon = DecimalInputIcon;

  @ViewChild('input')
  input: ElementRef;

  // digitsInfo: Decimal representation options, specified by a string in the following format:
  // {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.
  @Input()
  digitsInfo = '1.2-2';
  @Input()
  allowInvalid = false;
  @Input()
  emptyToZero = true;
  @Input()
  icon: string;

  constructor(
    private renderer: Renderer2,
    private formatter: DecimalFormatterService
  ) {
    super();
  }

  // callbacks for ControlValueAccessor
  onChange: any;
  onTouched: any;

  private _rawValue: any;
  get rawValue(): any {
    return this._rawValue;
  }

  private _modelValue: any;
  get modelValue(): any {
    return this._modelValue;
  }

  ngOnInit(): void {
    this.setDefaultIcon();
  }

  // ControlValueAccessor method
  // This method will be called by the forms API to write to the view
  // when programmatic (model -> view) changes are requested.
  writeValue(value: any): void {
    this.updateModelToView(value);
  }

  // ControlValueAccessor method
  // Registers a callback function that should be called when the control's value changes in the UI.
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // ControlValueAccessor method
  // This is called by the forms API on initialization so it can update the form model when
  // values propagate from the view (view -> model).
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  inputFocus(value: string) {
    this.onTouched();
    this.removeFormatting(value);
  }

  inputBlur(value: string) {
    this.updateViewToModel(value);
  }

  private removeFormatting(value: string) {
    const { valid, decimal } = this.validateAndParse(value);
    if (valid) {
      this.updateDOM(decimal.toString());
    }
  }

  private updateModelToView(value: any) {
    const { valid, decimal } = this.validateAndParse(value);

    if (valid) {
      this.formatAndUpdateDOM(decimal);
    } else {
      this.updateDOMWithInvalid(value);
    }
  }

  private updateViewToModel(value: any) {
    const { valid, decimal } = this.validateAndParse(value);

    if (valid) {
      this.formatAndUpdateDOM(decimal);
    } else {
      this.updateDOMWithInvalid(value);
    }

    this.raiseOnChange(value, valid, decimal);
  }

  private formatAndUpdateDOM(decimal: number) {
    const formatted = this.format(decimal);
    this.updateDOM(formatted);
  }

  private updateDOMWithInvalid(value: any) {
    if (this.invalidAllowed()) {
      this.updateDOM(value);
    } else {
      this.updateDOM('');
    }
  }

  private updateDOM(value: string) {
    this._rawValue = value;
    this.renderer.setProperty(this.input.nativeElement, 'value', value);
  }

  private validateAndParse(
    value: any
  ): {
    valid: boolean;
    decimal: number;
  } {
    if (_.isEmpty(value) && this.emptyToZero) {
      return { valid: true, decimal: 0 };
    }

    const decimal = this.parse(value);
    if (_.isFinite(decimal)) {
      // force decimal to number type to prevent typescript from complaining
      return { valid: true, decimal: decimal || 0 };
    }

    return { valid: false, decimal: Number.NEGATIVE_INFINITY };
  }

  private format(value: number): string {
    return this.formatter.format(value, this.digitsInfo);
  }

  private parse(value: any) {
    return this.formatter.parse(value, this.digitsInfo);
  }

  private raiseOnChange(inputValue: string, valid: boolean, decimal: number) {
    this._modelValue = this.getModelValue(inputValue, valid, decimal);
    this.onChange(this._modelValue);
  }

  private getModelValue(
    inputValue: string,
    valid: boolean,
    decimal: number
  ): any {
    if (valid) {
      return decimal;
    }

    if (this.invalidAllowed()) {
      return inputValue;
    }

    return undefined;
  }

  private setDefaultIcon() {
    if (!this.icon && this.digitsInfo) {
      const parts = this.digitsInfo.split('.');
      if (parts.length > 1 && (parts[1] + '').length > 1) {
        this.icon = 'usd';
      }
    }
  }

  private invalidAllowed(): boolean {
    return super.isTruthy(this.allowInvalid);
  }
}
