import { HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import * as _ from 'lodash';
import { LoggerService } from '@shared/logging/logger.service';
import { Injector, Injectable } from '@angular/core';
import * as objectHash from 'object-hash';
import { REQUEST_HEADERS, HttpRequestOptions } from '@shared/api/shared';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  private logger: LoggerService;

  constructor(injector: Injector) {
    this.logger = injector.get(LoggerService);
  }

  getRequestHeader(
    headers: HttpHeaders,
    name: string,
    defaultValue?: string | null
  ): string | null {
    defaultValue = defaultValue || null;
    if (!headers.has(name)) {
      return defaultValue;
    }
    return headers.get(name) || defaultValue;
  }

  // adds the http header to the http headers collection and returns the new collection
  setRequestHeader(
    headers: HttpHeaders,
    name: string,
    value: string | string[]
  ): HttpHeaders {
    return headers.set(name, value);
  }

  // Sets the 'Http-Error-Interceptor' request header
  // 1. when 'httpStatus' is provided, the error interceptor will rethrow errors for the specific status.
  // The header value is set to 'skip-error-{{status}}'
  // 2. when 'httpStatus' is not provided the error interceptor not handle any errors.
  // It will rethrow all errors. The header value is set to 'skip-error'
  skipErrorInterceptor(headers: HttpHeaders, httpStatus?: number): HttpHeaders {
    const value = httpStatus
      ? REQUEST_HEADERS.TEMPLATE_SKIP_HTTP_ERROR({ status: httpStatus })
      : REQUEST_HEADERS.SKIP_HTTP_ERROR_INTERCEPTOR;
    return this.setRequestHeader(
      headers,
      REQUEST_HEADERS.HTTP_ERROR_INTERCEPTOR,
      value
    );
  }

  // Sets the 'Http-Error-Interceptor' request header
  // 1. when 'httpStatus' is provided, the error interceptor will suppress errors for the specific status.
  // The header value is set to 'suppress-error-{{status}}'
  // 2. when 'httpStatus' is not provided the error interceptor will suppress all errors.
  // The header value is set to 'suppress-error'
  suppressError(headers: HttpHeaders, httpStatus?: number): HttpHeaders {
    const value = httpStatus
      ? REQUEST_HEADERS.TEMPLATE_SUPPRESS_HTTP_ERROR({ status: httpStatus })
      : REQUEST_HEADERS.SUPPRESS_HTTP_ERROR;
    return this.setRequestHeader(
      headers,
      REQUEST_HEADERS.HTTP_ERROR_INTERCEPTOR,
      value
    );
  }

  // Gets the numeric max age value in seconds from 'Cache-Control:max-age=<seconds>' request header
  getCacheMaxAge(headers: HttpHeaders): number {
    const cacheValue = headers.get(REQUEST_HEADERS.CACHE);
    if (!cacheValue) {
      return 0;
    }

    const maxAgeSeconds = _.parseInt(
      cacheValue.substr(REQUEST_HEADERS.CACHE_MAX_AGE_PREFIX.length)
    );
    // convert to seconds
    return maxAgeSeconds > 0 ? maxAgeSeconds / 1000 : 0;
  }

  // Sets the 'Cache-Control:max-age=<seconds>' request header
  // Specifies the maximum amount of time a resource will be considered fresh.
  // This directive is relative to the time of the request
  setCacheMaxAge(headers: HttpHeaders, maxAgeSeconds?: number): HttpHeaders {
    // convert to milliseconds
    const maxAge =
      (maxAgeSeconds || REQUEST_HEADERS.DEFAULT_MAX_AGE_SECONDS) * 1000;
    const value = REQUEST_HEADERS.TEMPLATE_CACHE_MAX_AGE({ maxAge });

    return this.setRequestHeader(headers, REQUEST_HEADERS.CACHE, value);
  }

  // Sets the 'Cache-Control:no-cache' request header
  setNoCache(headers: HttpHeaders): HttpHeaders {
    return this.setRequestHeader(
      headers,
      REQUEST_HEADERS.CACHE,
      REQUEST_HEADERS.NO_CACHE
    );
  }

  // Create HttpParams from an object hash ({param1: value1, param2: value2})
  createHttpParams(obj: any): HttpParams {
    const params = new HttpParams();
    return this.appendHttpParams(params, obj);
  }

  // Appends the object hash ({param1: value1, param2: value2}) to the HttpParams
  // and returns a new HttpParams instance
  appendHttpParams(params: HttpParams, obj: any): HttpParams {
    if (_.isEmpty(obj)) {
      return params;
    }

    // HttpParams are immutable. The 'append' method returns a new HttpParams object
    _.each(obj, function(value, key) {
      params = params.append(key, value);
    });

    return params;
  }

  // Create HttpHeaders from an object hash ({param1: value1, param2: value2})
  createHttpHeaders(obj: any): HttpHeaders {
    const httpHeaders = new HttpHeaders();
    return this.appendHttpHeaders(httpHeaders, obj);
  }

  // Appends the object hash ({header1: value1, header2: value2}) to the HttpHeaders
  // and returns a new HttpHeaders instance
  appendHttpHeaders(headers: HttpHeaders, obj: any): HttpHeaders {
    if (_.isEmpty(obj)) {
      return headers;
    }

    // HttpHeaders are immutable. The 'append' method returns a new HttpHeaders object
    _.each(obj, function(value, key) {
      headers = headers.append(key, value);
    });

    return headers;
  }

  logAllRequestHeaders(request: HttpRequest<any>) {
    if (!request.headers.keys().length) {
      return;
    }

    this.logger.debug('-----ALL REQUEST HEADERS-----');
    const headers = _.map(request.headers.keys(), function(key) {
      const allValues = request.headers.getAll(key);
      const values = allValues ? allValues.join(',') : '';
      return `${key}:${values}`;
    });

    if (!_.isEmpty(headers)) {
      this.logger.debug(headers.join(';'));
    }
  }

  addSuppressErrorHeaders(headers: HttpHeaders, options: HttpRequestOptions) {
    if (options.suppressError === true) {
      return this.suppressError(headers);
    }

    if (_.isNumber(options.suppressError)) {
      return this.skipErrorInterceptor(headers, <number>options.suppressError);
    }

    return headers;
  }

  addSkipErrorInterceptorHeaders(
    headers: HttpHeaders,
    options: HttpRequestOptions
  ) {
    if (options.skipErrorInterceptor === true) {
      return this.skipErrorInterceptor(headers);
    }

    if (_.isNumber(options.skipErrorInterceptor)) {
      return this.skipErrorInterceptor(headers, <number>options.skipErrorInterceptor);
    }

    return headers;
  }

  addCacheHeaders(
    headers: HttpHeaders,
    options: HttpRequestOptions
  ): HttpHeaders {
    if (options.cache === true) {
      return this.setCacheMaxAge(
        headers,
        REQUEST_HEADERS.DEFAULT_MAX_AGE_SECONDS
      );
    }

    if (_.isNumber(options.cache)) {
      return this.setCacheMaxAge(headers, <number>options.cache);
    }

    return headers;
  }

  addDuplicateHandlerHeader(headers: HttpHeaders, options: HttpRequestOptions) {
    if (!options.duplicateRequest) {
      return headers;
    }

    return this.setRequestHeader(
      headers,
      REQUEST_HEADERS.DUPLICATE_REQUEST,
      options.duplicateRequest
    );
  }

  addUrlTemplateHeader(headers: HttpHeaders, url: string) {
    return this.setRequestHeader(headers, REQUEST_HEADERS.URL_TEMPLATE, url);
  }

  addHttpParamsHeader(headers: HttpHeaders, params: any) {
    return this.setRequestHeader(
      headers,
      REQUEST_HEADERS.HTTP_PARAMS,
      JSON.stringify(params || {})
    );
  }

  addUseMockHeader(
    headers: HttpHeaders,
    options: HttpRequestOptions,
    configUseMock: boolean
  ) {
    // options.isMock might be undefined. so we explicitly check for true or false
    if (options.isMock === false) {
      return headers;
    }

    if (options.isMock === true || configUseMock === true) {
      return this.setRequestHeader(
        headers,
        REQUEST_HEADERS.USE_MOCK,
        true.toString()
      );
    }

    return headers;
  }

  shouldMockRequest(headers: HttpHeaders) {
    const value = this.getRequestHeader(headers, REQUEST_HEADERS.USE_MOCK);
    return value === true.toString();
  }

  // create a hash based on select HttpRequest properties
  createRequestHash(request: HttpRequest<any>) {
    const requestProps = _.pick(request, ['method', 'url', 'params', 'body']);
    return objectHash.MD5(requestProps);
  }
}
