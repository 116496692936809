import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { HttpService } from '@shared/api/http.service';
import { ConfigService } from '@shared/config.service';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  private apiUrls: any;

  constructor(
    private configService: ConfigService,
    private http: HttpService) {
    this.apiUrls = this.configService.api.clientService;
  }

  getWithUrl(url: string, params: {
    EntityId?: string
    ClientId?: string
    ParticipantId?: string
  }): Observable<any> {
    return this.http.get(url, {
      urlParams: params,
      options: {
        isMock: false
      }
    });
  }

  postWithUrl(params: { url: string, dto: any }): Observable<any> {
    return this.http.post(params.url, {
      body: params.dto,
      options: {
        isMock: false
      }
    });
  }

  putWithUrl(params: { url: string, dto: any }): Observable<any> {
    return this.http.put(params.url, {
      body: params.dto,
      options: {
        isMock: false
      }
    });
  }

  getWithStaticUrl(url: string, params: { EntityId?: string; ClientId?: string;ParticipantId?: string;
  }): Observable<any> {
    return this.http.get(url, { urlParams: params });
  }

  postWithStaticUrl(params: { url: string, dto }): Observable<any> {
    return this.http.post(params.url, { body: params.dto });
  }
}
