import { Component, OnInit } from '@angular/core';
import { DynamicFormWithGridContainnerComponent} from '@shared/components/dynamic_form_with_grid/dynamic_form_with_grid.component';
import { DynamicMainGridComponent} from '@shared/components/dynamic_grid/dynamic-grid.component';
import { FileFormGridComponent } from '@modules/main/file-export/fileformgrid.component';

@Component({
    selector: 'app-fileexportgrid',
    templateUrl: './fileexportgrid.component.html',

  })
  export class FileExportGridComponent extends DynamicMainGridComponent  {

    protected    action(item: any): void {
        let dialogRef ;
        if (this.popup === 'formandgrid' && (item.type === 'OutBound' || item.type === 'Job'|| item.type === 'DynamicJob')) {
            dialogRef = this.dialog.open(DynamicFormWithGridContainnerComponent, {
                width: '90%',
                // height: '90%',
                data: {
                  addNew: false,
                  innerformid: this.innerform,
                  item: item,
                  formid: this.id
                }
              });

        } else {
            dialogRef = this.dialog.open(FileFormGridComponent, {
                width: '90%',
                // height: '90%',
                data: {
                  addNew: false,
                  innerformid: this.innerform,
                  item: item,
                  formid: 'b2bfilesys'
                }
              });
        }
        dialogRef.afterClosed().subscribe(result => {
          //if (result === true) {
            this.GetUrlAction();
         // }
            //  this.loadMemberData();
            // this.GetUrlAction();
           });


    }
  }
