import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rdlc',
  templateUrl: './rdlcreport.component.html',

})
export class RDLCReportComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
