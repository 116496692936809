import { NgModule } from '@angular/core';
import { FilterPipe,SearchPipe } from '@shared/pipes/filter.pipe';
import { GenderPipe } from '@shared/pipes/gender.pipe';
import { ZipPipe  } from '@shared/pipes/zipcode.pipe';
import { SafeHtmlPipe } from '@shared/pipes/safe-html-pipe';
import { CalcuPipe,LookupPipe, LookupHttpPipe , LookupApiPipe, TransUrlPipe,ReplacePipe} from '@shared/pipes/lookup.pipe';
import { DatePipe } from '@shared/pipes/date.pipe';
import { SSNPipe } from '@shared/pipes/ssn.pipe';
import { MaskingPipe } from '@shared/pipes/masking.pipe';
import { ClientList,RoleList } from '@shared/pipes/clientlist.pipe';
const PIPES = [
    DatePipe,
    FilterPipe,SearchPipe,
    SafeHtmlPipe,
    LookupPipe,
    LookupHttpPipe,CalcuPipe,
    LookupApiPipe, TransUrlPipe,ReplacePipe,
    GenderPipe,RoleList,
    ZipPipe,SSNPipe,ClientList,MaskingPipe
];

@NgModule({
    declarations: [PIPES],
    exports: [PIPES]
})
export class PipesModule { }
