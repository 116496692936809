import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { SelectionService } from '@store/selection/selection.service';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { ClientDTO } from '@models/client/client.dto';
import { ClientService } from '@services/client/client.service';
@Component({
  selector: 'app-selected-client-header-share',
  templateUrl: './selected-client-header.component.html',
  styleUrls: ['./selected-client-header.component.scss']
})
export class SelectedClientHeaderComponent extends BaseComponent
  implements OnInit {
    @Input()
    noClient;
    @Input()
    set entityId(entityId) {
        if (entityId) {
          this._entityId = entityId;
          const url = 'Entity/Entity?entityId=' + entityId ;
          const observ$ = this.clientService.getWithUrl(url, { }).pipe(untilComponentDestroyed(this));
          observ$.subscribe((data) => {
              this.entityName = data.entityName;
          });
        }
    }
    _entityId;
    entityName;
    client?: ClientDTO | null;

  constructor(private clientService: ClientService, private selectionService: SelectionService) {
    super();
  }

  ngOnInit() {
    if (this.noClient !== true) {
      this.subscribeSelectedClient();
    }
  }

  private subscribeSelectedClient() {
    return this.selectionService
      .getClient()
      .pipe(untilComponentDestroyed(this))
      .subscribe((x: any) => {
        this.client = x;
      //  console.log(this.client);
      });
  }
}
