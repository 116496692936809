import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { LoginAction } from '@store/auth/auth.actions';
import { getAuthState } from '@store/auth/auth.reducer';
import { AppState } from '@store/app.state';
import { AuthState } from '@store/auth/auth.state';
import { BaseComponent } from '@shared/components/base.component';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { SpinnerService } from '@shared/services/spinner.service';
import { AuthService } from '@services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  authState: AuthState;
  currentYear: number = new Date().getFullYear();
  loading = false;
  card;
  return = '';
  logintype;
  logo = './assets/img/AIS_crop.png'
 
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private store: Store<AppState>,
    private spinner: SpinnerService,
    private authService: AuthService,  private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    
    
    this.isLogintype()
    this.initializeForm();
    this.subscribeAuthState();
    if (this.authService.isAuthenticated()) {
      if (this.logintype == 'admin') {
        this.return = '/main/user-dashboard';
      }
      this.router.navigate([this.return]);
    }
   
  }

  ngOnDestroy() {
    this.spinner.hide();
    super.ngOnDestroy();
  }

  onSubmit() {
    if (this.form.valid) {
      this.login();
    }
  }

  login() {
    this.spinner.show();
    const dto = this.form.value;
    if (this.logintype === 'user') {
      this.store.dispatch(
        new LoginAction({
          request: {
            username: dto.username,
            password: dto.password,
            clientid: 'E3C3E77D-D76E-4BB8-AF30-AC165CA9807B',
            appcode: 'mmweb'
          }
        })
      );
    } else if (this.logintype === 'portalApp') {
      this.store.dispatch(
        new LoginAction({
          request: {
            username: dto.username,
            password: dto.password,
            clientid: '9808F856-F047-4843-BEC8-277E414FC649',
            appcode: 'mmweb'
          }
        })
      );
    } else {
      this.store.dispatch(
        new LoginAction({
          request: {
            username: dto.username,
            password: dto.password,
            clientid: 'BC4134FF-B7D2-40CC-8ED6-C032A72FCAA7'
          }
        })
      );
    }
  }

  isLogintype() {
    let config = window['___alliant-config-eniac-APP___']
    this.logintype = config.logintype
  }

  private subscribeAuthState() {
    this.store
      .select(getAuthState)
      .pipe(untilComponentDestroyed(this))
      .subscribe(x => this.authStateChange(x));
  }

  public authStateChange(state: AuthState) {
    this.authState = state;

    if (state.loading === false && state.success != null) {
      if (this.logintype == 'enterpriseadmin') {
        this.return = '/main/enterprise-dashboard';
      } else if (this.logintype == 'portalApp') {
        this.return = '/main/dashboard';
      } else if (state.success.IsPasswordReset) {
        this.return = '/changepassword';
      } else {
        this.return = '/main/user-dashboard';
      }
      this.router.navigateByUrl(this.return);
    }
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: ['', { validators: [Validators.required] }],
      password: ['', { validators: [Validators.required] }]
    });
  }

}
