import { Component, OnInit, Inject } from '@angular/core';
import { ClientService } from '@services/client/client.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { BaseComponent } from '@shared/components/base.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  templateUrl: './waiver.component.html'
})

export class WaiverComponent extends BaseComponent implements OnInit {
  paraArr; 
  entityId; 
  participantId; 
  benefitEntityPolicyId; 
  billWaiverEffectiveStartDate; 
  addNew;
  billWaiverEffectiveEndDate; 
  participantPolicyWaiverId; 
  createdDate; 
  createdBy; 
  exist;
  entityclientId;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any, 
    public dialogRef: MatDialogRef<WaiverComponent>,
    public dialog: MatDialog, 
    protected clientService: ClientService, 
    protected notification: NzNotificationService) {
    super();
    this.paraArr = this.data.paraArr;
    this.entityId = this.data.entityId;
    this.participantId = this.data.participantId;
    this.entityclientId = this.data.entityclientId;
  }

  onDisableWaiver() {
    if (this.benefitEntityPolicyId == null) {
      this.notification.blank('Please select a policy', ' ');
      return;
    }
    if (this.billWaiverEffectiveEndDate == null) {
      this.notification.blank('Please select a end date', ' ');
      return;
    }

    var billMonthStart = this.billWaiverEffectiveStartDate.getMonth()  + 1;
    var billMonthEnd = this.billWaiverEffectiveEndDate.getMonth()  + 1;

    const dto = {
      ParticipantPolicyWaiverId: this.participantPolicyWaiverId,
      entityId: this.entityId,
      participantId: this.participantId,
      benefitEntityPolicyId: this.benefitEntityPolicyId,
      isBillWaiver: false,
      billWaiverEffectiveStartDate: this.billWaiverEffectiveStartDate.getFullYear() + '-'  + billMonthStart + '-' + this.billWaiverEffectiveStartDate.getDate(),
      billWaiverEffectiveEndDate:this.billWaiverEffectiveEndDate.getFullYear() + '-'  + billMonthEnd + '-' + this.billWaiverEffectiveEndDate.getDate(),
      entityclientId: this.entityclientId,
      createdBy: this.createdBy,
      createdDate: this.createdDate
    };
    const waiver$ = this.clientService.postWithUrl({ url: 'genericapi/update?typeName=Waiver', dto }).pipe(untilComponentDestroyed(this));
    waiver$.subscribe((data) => {
      this.notification.blank('Posted Successfully', ' ');
      this.addNew = null;
    },
      err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  ngOnInit() {
    if (this.paraArr.length === 1) {
      this.benefitEntityPolicyId = this.paraArr[0].benefitEntityPolicyId;
      this.onChange(null);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onEnableWaiver() {
    if (this.benefitEntityPolicyId == null) {
      this.notification.blank('Please select a policy', ' ');

      return;
    }
    if (this.billWaiverEffectiveStartDate == null) {
      this.notification.blank('Please select a start date', ' ');

      return;
    }

    const obj = this.paraArr.find(x => x.benefitEntityPolicyId === this.benefitEntityPolicyId);
    var billMonthStart = this.billWaiverEffectiveStartDate.getMonth()  + 1;
    var billMonthEnd = new Date(2999, 11, 17).getMonth() + 1;
    var futureDate = new Date(2999, 11, 17)
    const dto = {
      entityId: this.entityId,
      participantId: this.participantId,
      benefitEntityPolicyId: this.benefitEntityPolicyId,
      isBillWaiver: true,
      billWaiverEffectiveStartDate: this.billWaiverEffectiveStartDate.getFullYear() + '-'  + billMonthStart + '-' + this.billWaiverEffectiveStartDate.getDate(),
      billWaiverEffectiveEndDate: futureDate.getFullYear() + '-'  + billMonthEnd + '-' + futureDate.getDate(), 
      effectiveDate: obj.currentEnrollment.latestEnrollmentEffectiveDate,
      entityclientId: this.entityclientId,
      benefitEntityPolicyDetailId: obj.currentEnrollment.benefitEntityPolicyDetailId
    };
    // tslint:disable-next-line:max-line-length
    const waiver$ = this.clientService.postWithUrl({ url: 'genericapi/add?typeName=Waiver', dto }).pipe(untilComponentDestroyed(this));
    waiver$.subscribe((data) => {
      if (data === 'OK') {
        this.notification.blank('Posted Successfully', ' ');
        this.addNew = null;
      }
    },
      err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  onChange(e) {
    if (this.benefitEntityPolicyId === '') {
      return;
    }
    // tslint:disable-next-line:max-line-length
    const url = 'genericapi/Query?typeName=Waiver&entityId=' + this.entityId + '&benefitEntityPolicyId=' + this.benefitEntityPolicyId + '&participantId=' + this.participantId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      if (data.details.length === 0) {
        this.addNew = true;
      } else {
        const obj = data.details.find(d => d.isBillWaiver === true);
        if (obj != null) {

          this.addNew = false;
          this.billWaiverEffectiveStartDate = obj.billWaiverEffectiveStartDate;
          this.participantPolicyWaiverId = obj.participantPolicyWaiverId;
          this.createdDate = obj.createdDate;
          this.createdBy = obj.createdBy;
        } else {
          this.addNew = true;
          /* this.billWaiverEffectiveStartDate = data.details[0].billWaiverEffectiveStartDate;
          this.billWaiverEffectiveEndDate = data.details[0].billWaiverEffectiveEndDate;
          this.exist = true; */
        }
      }
    });
  }
}
