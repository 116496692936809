import {
  SelectionActionTypes,
  AllSelectionActions
} from '@store/selection/selection.action';
import { SelectionState } from '@store/selection/selection.state';

export const initialState = new SelectionState();

export function selectionReducer(
  state: SelectionState = initialState,
  action: AllSelectionActions
): any {
  switch (action.type) {
    case SelectionActionTypes.SET_SELECTED_ENTITY: {
      return { ...state, entity: action.payload.entity, client: null };
    }
    case SelectionActionTypes.CLEAR_SELECTED_ENTITY: {
      return { ...state, entity: null, client: null };
    }
    case SelectionActionTypes.SET_SELECTED_CLIENT: {
      return { ...state, client: action.payload.client };
    }
    case SelectionActionTypes.CLEAR_SELECTED_CLIENT: {
      return { ...state, client: null };
    }
    default:
      return state;
  }
}
