import { Component, Input, OnInit } from '@angular/core';

import { BaseComponent } from '@shared/components/base.component';
@Component({
    selector: 'app-screen-content',
    templateUrl: './dynamic-screen-content.component.html'
  })
  export class DynamicScreenContentComponent extends BaseComponent implements OnInit {

    ngOnInit() {

    }
  }
