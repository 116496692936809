import { Component, Input } from '@angular/core';
import {PopconfirmRef} from './popconfirm-ref'
@Component({
  selector: 'confirm-overlay',
  templateUrl: './confirmoverlay.component.html',

})
export class ConfirmOverlayComponent  { 

  nzOkText="OK";
  nzCancelText="Cancel";
  nzOkType = 'primary';
  nzPopconfirmShowArrow = false;
  nzTitle ='Are you sure you want to save?';
  constructor(private popconfirmRef:PopconfirmRef
    ) {
   this.nzTitle=popconfirmRef._message;
  }
  onConfirm(){
    this.popconfirmRef.close('ok');
  }
  onCancel(){
    this.popconfirmRef.close('cancel');
  }
}
