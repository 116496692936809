<div class="w-100">
  <img style="width: 100%; position: fixed; object-fit: fit;" src="{{bgImage}}" alt="">
</div>

<div class="row container loginscreen" style="padding: 0px !important; margin: 0 auto;">
  <div class="col-xl-2 col-lg-2 col-md-2"></div>
  <div style="padding: 10% 0px 0px 0px; margin: 0px;"
    class="row col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 LoginPanel">
    <div class="row" style="margin: 0px; background: rgba(255, 255, 255, 0.9) none repeat scroll 0% 0%;">

      <div class="row no-gutters" style="padding: 20px;">
        <form class="col-lg-7 col-md-7 col-sm-7 col-xs-12" [formGroup]="registerForm" (submit)="onSubmit()"
          autocomplete="off">
          <div style="border-radius: 6px; margin: 0 25px 0 0; background: rgba(255, 255, 255, 0.95)">
            <div style="padding: 3% 3% 3% 5%; margin: 0px auto" class="row">

              <div><span style="font-family: Calibri; font-size: 22px; margin-bottom: 40px;">Sign up for an
                  account</span>
                <p style="text-align: left;font-size:14px; color: rgb(78, 78, 78)">
                  Please fill in the required fields below in order to create an account.
                </p>
              </div>

              <br />

              <div class="row no-gutters" style="margin: 0px 0px 0px -10px; padding: 10px;">

                <div class="col-12">
                  <span style="font-family: Calibri; font-size: 14px">Username (6-12 letters only)</span>
                </div>
                <input maxlength="20" formControlName="username" type="text" class="form-control input-sm RegisterClass"
                  required>
                <div class="validation">
                  <span *ngIf="registerForm.get('username').touched && registerForm.get('username').invalid">Please
                    enter Username</span>
                </div>

                <div class="col-12" style="padding-top: 5px">
                  <span style="font-family: Calibri; font-size: 14px">Password</span>
                </div>
                <input maxlength="20" type="password" formControlName="password"
                  class="form-control input-sm RegisterClass" id="userpassword" required>
                <div class="validation">
                  <span *ngIf="registerForm.get('password').touched && registerForm.get('password').invalid">Please
                    enter valid Password</span>
                </div>

                <div class="col-12" style="padding-top: 5px">
                  <span style="font-family: Calibri; font-size: 14px">Re-enter Password</span>
                </div>
                <input maxlength="20" type="password" formControlName="reEnterPassword"
                  class="form-control input-sm RegisterClass" id="userpassword" required>
                <div class="validation">
                  <div
                    *ngIf="registerForm.errors?.mismatch && (registerForm.get('reEnterPassword').touched || registerForm.get('reEnterPassword').invalid)">
                    Passwords must match.
                  </div>
                </div>

                <div class="col-12" style="margin: 0px auto; padding: 0px">
                  <span style="font-family: Calibri; font-size: 14px">Email</span>
                </div>
                <input formControlName="email" type="text" class="form-control input-sm RegisterClass" required>
                <div class="validation">
                  <span *ngIf="registerForm.get('email').touched && registerForm.get('email').invalid">Please enter
                    valid Email</span>
                </div>

                <div class="col-12" style="margin: 0px auto; padding: 0px">
                  <span style="font-family: Calibri; font-size: 14px">Re-enter Email</span>
                </div>
                <input formControlName="reEnterEmail" type="text" class="form-control input-sm RegisterClass" required>
                <div class="validation">
                  <span
                    *ngIf="registerForm.errors?.emailmismatch && (registerForm.get('reEnterEmail').touched || registerForm.get('reEnterEmail').invalid)">Emails
                    must match</span>
                </div>

                <div class="col-12" style="margin: 0px auto; padding: 0px">
                  <span style="font-family: Calibri; font-size: 14px">Security Question</span>
                </div>
                <select id="inputQuestion" class="form-control" formControlName="secQuestion">
                  <option *ngFor="let secQuestion of secQuestions" [value]="secQuestion.securityQuestionId">
                    {{secQuestion.securityQuestionText}} </option>
                </select>
                <div class="validation">
                  <span
                    *ngIf="registerForm.get('secQuestion').touched && registerForm.get('secQuestion').invalid">Please
                    pick a security question</span>
                </div>


                <div class="col-12" style="margin: 0px auto; padding: 0px">
                  <span style="font-family: Calibri; font-size: 14px">Security Answer</span>
                </div>
                <input maxlength="20" formControlName="secAnswer" type="text"
                  class="form-control input-sm RegisterClass" required>
                <div class="validation">
                  <span *ngIf="registerForm.get('secAnswer').touched && registerForm.get('secAnswer').invalid">Please
                    enter security answer</span>
                </div>

              </div>

              <div>
                <div style="display: inline; padding-top: 3px">
                  <button routerLink='/' mat-flat-button type="button" [style.background-color]="primaryColor"
                    style="color: #fff; float: left; margin: 0 15px 15px 0; width: 100%; width: 150px; border: none; font-size: 18px;">
                    Cancel
                  </button>
                  <button mat-flat-button type="submit" [style.background-color]="primaryColor"
                    style="color: #fff;  float: left; margin: 0 15px 15px 0; width: 100%; width: 150px; border: none; font-size: 18px;">
                    Register
                  </button>
                </div>
              </div>

            </div>
            <br />
          </div>
        </form>

        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12" style="height: 100%;">
          <div id="divIntroduction" style="padding-right: 10px; padding-left: 10px; background-size: cover;">
            <div style="background-color: transparent;">

              <div style="text-align: center;">

                <div>
                  <div style="padding-top: 10px;">
                    <h3 ng-if="WelcomeText == null || WelcomeText == ''"
                      style="font-family: Calibri; font-size: 28px; font-weight: bold; text-align: left">
                      {{welcomeText}}</h3>
                    <h3 ng-if="WelcomeText != null && WelcomeText != ''"
                      style="font-family: Calibri; font-size: 28px; font-weight: bold; text-align: left"
                      dynamic="WelcomeText"></h3>
                    <br />
                    <br />
                  </div>
                </div>

                <div style="text-align: left;">
                  <span style="text-align: left; font-size:16px; font-weight: 550;">
                    Passwords must be at least 10 characters and contain at least one character from the following: <br>
                    upper case (A-Z), <br> lower case (a-z), <br> number (0-9), <br> special character ( @ # $ % + * ! =
                    )
                  </span>
                </div>

                <p style="text-align: left; font-size:16px;">
                  <br>
                  <br>
                  Questions on enrollment or program offerings?
                  <br>
                  <b style="font-family: Calibri; font-size: 18px">Call: {{phoneNum}}</b>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div id="SpeciallinksDiv" style="position: absolute; bottom: 10px; right: 10px;">
        <a *ngIf="isTermsLink" class="FontColor" style="cursor: pointer; font-family: Calibri; font-size: 14px"
          href="https://cdn.abcsys.com/Documents/Legal/terms.html" target="_blank"><u>Terms of Use
          </u></a><span class="FontColor">&nbsp; | &nbsp;</span>
        <a *ngIf="isPrivacyLink" class="FontColor" style="cursor: pointer; font-family: Calibri; font-size: 14px"
          href="https://cdn.abcsys.com/Documents/Legal/privacy.html" target="_blank"><u>Privacy
            Policy</u></a><span class="FontColor"> &nbsp; </span>
      </div>
    </div>
  </div>
</div>