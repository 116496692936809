import { Component, OnInit } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '@services/client/client.service';
import { ExcelService } from '@services/excel/excel.service';
import { HttpService } from '@shared/api/http.service';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { SelectionService } from '@store/selection/selection.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';



@Pipe({name: 'tablefilter'})
export class TableFilterPipe implements PipeTransform {
  transform(items: any, searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(item => {
      const key = item.key.toLowerCase().includes(searchText);
      let prd = false
      let stg = false
      if(item.prdvalue) {
        prd = item.prdvalue.toLowerCase().includes(searchText);
      }
      if(item.stgvalue) {
        stg = item.stgvalue.toLowerCase().includes(searchText);
      }
      return key || prd || stg;
    });
  }
}

@Component({
  selector: 'app-enterprise-main',
  templateUrl: './enterprise-main.component.html',
  styleUrls: ['./enterprise-main.component.scss'],
})

export class EnterpriseMainComponent implements OnInit {
  STAGE_DATA = []
  searchText: string
  showDiff = false
  PROD_DATA = []
  showPrd: boolean
  showStg: boolean
  entityId: string
  logoImagePath: string
  clientData: any
  clientName: string = '';
  website:any;
  logoPath: string = ''
  headerData: any = []
  tableData: any = []
  benefitData:any=[];
  filterKey:any="";
  filterKeyChange:any="";
  view='card'
  constructor(
    protected excelService: ExcelService,
    private http: HttpService,
    protected spinner: SpinnerService, 
    protected clientService: ClientService,
    private route: ActivatedRoute,
    private routeHelper: RouteHelperService,
  ) { }

  ngOnInit(): void {
    this.entityId = this.routeHelper.getEntityId(this.route);
    const url = `v1/generic/dwh?typeName=EntityConfiguration&typeId=`+this.entityId;
    const observ$ = this.clientService.getWithUrl(url, {});
    //const observ$ = this.http.get(`https://staging.abcsys.com/test/VisibilityAPI/api/v1/generic/dwh?typeName=EntityConfiguration&typeId=${this.entityId}`); 
    observ$.subscribe( response  => {
      this.clientData = response.client[0];
      this.website=this.clientData.Basic[0].Website;
      this.logoImagePath = `https://staging.abcsys.com/EnterpriseWebAPI/content/${this.clientData.EnterpriseClientId}/Images/Client/${this.clientData.LogoPath}`
      this.clientName = this.clientData.ClientName;
      
      this.logoPath = this.clientData.LogoPath
      this.clientDataSort()
      this.spinner.hide();
      this.benefitData=this.clientData.Benefits;
    });
    fromEvent(document, 'input')
      .pipe(debounceTime(1000),distinctUntilChanged())
      .subscribe(() => {
        this.filterKeyChange=this.filterKey;
      });
  }

  clientDataSort() {
    let sortedObj = Object.entries(this.clientData)
    sortedObj.map(x => (x[1] instanceof Array ? this.tableData.push(x) : this.headerData.push(x)))
  }

  download() {
    const excelrows: any = [];
    const allrecord: any = [];
    const obj = {};
    this.PROD_DATA.forEach(row => allrecord.push(row.value));
    allrecord.flat().forEach(row => obj[row.key] = row.prdvalue);
    excelrows.push(obj);
    this.excelService.exportAsExcelFile(excelrows, 'config');
  }
  onView(view){
    this.view=view;
  }
}
