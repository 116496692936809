import { Component, OnInit, Input, Inject } from '@angular/core';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { BaseComponent } from '@shared/components/base.component';
import { ActivatedRoute } from '@angular/router';
import { MEMBER_ROUTE_SEGMENT } from '@shared/route/constants';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { HttpService } from '@shared/api/http.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from '@shared/services/spinner.service';

@Component({

  templateUrl: './dynamic_template.component.html',
})
export class DynamicTemplateComponent extends BaseComponent implements OnInit {
  ckeditorContent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DynamicTemplateComponent>,
    public store: Store<AppState>, private route: ActivatedRoute,
    private routeHelper: RouteHelperService, private httpser: HttpService, private spinner: SpinnerService) {
    super();
    // this.formid = data.formid;

  }
  ngOnInit() {

    this.createText();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  replaceFields(element) {
    let i: number;
    let result = this.data.template;
    for (i = 0; i < element.length; i++) {
      const field = this.data.columnNames[i];
      const re = new RegExp('{{' + field + '}}', 'g');
      result = result.replace(re, element[i]);
    }
    return result;
  }
  createText() {
    let cont = '';
    if (this.data.template != null) {
      this.data.data.forEach(element => {
        if (element.checked === true) {
          const r = this.replaceFields(element.item);
          cont = cont + r;
        }

      });
    }
    this.ckeditorContent = cont;
  }
}
