import { Component, OnInit, Input, Inject } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
@Component({
  selector: 'app-benefitdetail-policy',
  templateUrl: './benefitpolicydetail.component.html',
  styleUrls: ['./benefit.component.scss']
})
export class BenefitPolicyDetailComponent extends BaseComponent implements OnInit {

  devUser: boolean = false
  clientHM: boolean = false
  userName: any
  entityId; entityClientId;
  coverageTerminationRuleDTO; 
  benefitEntityPolicyDetailDTO; 
  benefitEntityPolicyBillConfigDTO; 
  benefitEntityPolicyCoverageDTO; 
  coverageReductionsRuleDTO; 
  benefitEntityPolicyCoverageRuleDTO;
 // @Input() benefitEntityPolicyId ;//= '3623d0dd-4ecc-4369-8909-4df330832167';
  
 @Input()
  set benefitEntityPolicyId(benefitEntityPolicyId) {
    if (benefitEntityPolicyId != null) {
      this._benefitEntityPolicyId = benefitEntityPolicyId;
    }
  }
  _benefitEntityPolicyId;

  constructor(
    protected clientService: ClientService,
    protected routeHelper: RouteHelperService,
    protected route: ActivatedRoute,
    private authService: AuthService,
    private notification: NzNotificationService) {
    super();
  }

  ngOnInit() {
    this.entityId = this.routeHelper.getEntityId(this.route)
    this.entityClientId = this.routeHelper.getEntityClientId(this.route)
    this.userName = this.authService.getUsername();
    this.getPolicyTiers()
    this.isDevUser()
    this.isClientHM()
    this.getPolicyBillConfig()
    this.getPolicyCoverage()
    this.getPolicyTiers()
    this.getPolicyCoverageRule()
    this.getReductionsRule()
    this.getTerminationRule()

    // const url = 'CaseManagement/BenefitEntityPolicyDetails?benefitEntityPolicyId=' + this.benefitEntityPolicyId ;
    // const  observ$ = this.clientService.getWithUrl(url, {});
    // observ$.subscribe((data) => {
    //     this.benefitEntityPolicyDetailDTO = data.benefitEntityPolicyDetailDTO;
    //     this.benefitEntityPolicyBillConfigDTO = data.benefitEntityPolicyBillConfigDTO;
    //     this.benefitEntityPolicyCoverageDTO = data.benefitEntityPolicyCoverageDTO;
    //     this.benefitEntityPolicyCoverageRuleDTO = data.benefitEntityPolicyCoverageRuleDTO;
    //     this.coverageReductionsRuleDTO = data.coverageReductionsRuleDTO;
    //     this.coverageTerminationRuleDTO=data.coverageTerminationRuleDTO;
    // },
    //   );
  }

  isDevUser() {
    if (this.userName === 'kgudigar' || 'AAstremskyi' || 'isingh' || 'vqwang') {
      this.devUser = true
    }
  }
  
  isClientHM() {
    if (this.entityId === '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d') {
      this.clientHM = true
    }
  }

  getPolicyBillConfig() {
    const url = `CaseManagement/BenefitEntityPolicyBillConfigs/?entityId=${this.entityId}&benefitEntityPolicyId=${this._benefitEntityPolicyId}`
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe(data => {
      this.benefitEntityPolicyBillConfigDTO = data
    });
  }

  getPolicyCoverage() {
    const url = `CaseManagement/BenefitEntityPolicyDetailCoverages/?entityId=${this.entityId}&benefitEntityPolicyId=${this._benefitEntityPolicyId}`
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe(data => {
      this.benefitEntityPolicyCoverageDTO = data
    });
  }

  getPolicyTiers() {
    const url = `CaseManagement/BenefitEntityPolicyDetails/?entityId=${this.entityId}&benefitEntityPolicyId=${this._benefitEntityPolicyId}`
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe(data => {
      this.benefitEntityPolicyDetailDTO = data
    });
  }

  getPolicyCoverageRule() {
    const url = `CaseManagement/BenefitEntityPolicyCoverageRules/?entityId=${this.entityId}&benefitEntityPolicyId=${this._benefitEntityPolicyId}`
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe(data => {
      this.benefitEntityPolicyCoverageRuleDTO = data
    });
  }

  getTerminationRule() {
    const url = `CaseManagement/BenefitEntityPolicyTerminationRules/?entityId=${this.entityId}&benefitEntityPolicyId=${this._benefitEntityPolicyId}`
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe(data => {
      this.coverageTerminationRuleDTO = data
    });
  }

  getReductionsRule() {
    const url = `CaseManagement/BenefitEntityPolicyReductionRules/?entityId=${this.entityId}&benefitEntityPolicyId=${this._benefitEntityPolicyId}`
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe(data => {
      this.coverageReductionsRuleDTO = data
    });
  }
  
}
