<div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
  <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
    height:200px; 
    position: fixed; 
    z-index:2;
    top: 50%; 
    left: 50%; 
    margin-top: -100px; 
    margin-left: -100px;"></nz-spin>
  <span class="default-font-Color-dark">Loading ...</span>
</div>
<div class="app-container">
  <app-selected-member-header-share 
    [memberState]="memberState" 
    [entityClientId]='EntityClientId'
    [formValue]='curFormValue'>
  </app-selected-member-header-share>
  <div class="alert alert-success" role="alert" *ngIf="saveSuccess">
    {{successMessage}}
  </div>
  <div>
    <h6 class="member-function-header">{{formType}}</h6>
  </div>
  <div class="row">
    <div class="col-10">
      <app-dynamic-fields 
        [saveButton]='saveButton'
        [cancelButton]='cancelButton'
        [deleteButton]='deleteButton'
        [formConfigs]='formConfigs'
        [formValue]="formValue"
        [validators]='validators'
        [validatorsarr]='validatorsarr'
        [actionButton1]='actionButton1'
        (cancle)="onCancel($event)"
        (changeObvSub)="onchangeObvSub($event)"
        (action1)="onAction1()" 
        (save)="onSave($event)">
      </app-dynamic-fields>
    </div>
    <div class="col-2">
      <app-member-quick-links-share [entityId]='EntityId'></app-member-quick-links-share>
    </div>
  </div>

</div>