<div class="outer-stepper-container">
  <mat-horizontal-stepper [linear]="true" [labelPosition]="'bottom'" class="stepper" #stepper>
    <ng-template matStepperIcon="edit" let-index="index">
      {{ index + 1 }}
    </ng-template>
    <mat-step>
      <ng-template matStepLabel>Your Profile Information</ng-template>
      <app-dynamic-form [addNew]=false [formIntiValue]='initForm' [formid]="'member/leebauserprofile'"
        [hideButton]=true>
      </app-dynamic-form>
    </mat-step>
    <mat-step class="col-lg-12">
      <ng-template matStepLabel>Add Dependents</ng-template>
      <div style="padding-left: 20px; font-weight: 650; font-size: 18px;"> If you do not have dependents to add click
        "Save and Continue" button </div>
      <div style="padding: 20px;">
        <p>L.E.E.B.A. will be providing coverage to you and your eligible dependents at no premium cost. Please enter
          any dependents you would like covered under the plans here. Eligible dependents include:
        </p>
        <ul>
          <li>Spouse or domestic partner</li>
          <li>Children, step-children and children of domestic partner up to the age of 26</li>
          <li>Disabled children over the age of 26</li>
        </ul>
      </div>

    <app-dynamic-main-grid pageSize=50 class="co-12" [params]='param'
      gridid='member/leebadependents' [hideButton]=true>
      </app-dynamic-main-grid>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Add Beneficiaries</ng-template>

      <div style="padding-left: 20px; font-weight: 650; font-size: 18px;"> If you do not have beneficiaries to add click
        "Save and Continue" button </div>
      <!-- <div style="padding-left: 20px;"> <input class="form-control" style="width: 50px; display: inline-block;"
          type="checkbox" /><span style="font-weight: 650; font-size: 18px;">No Dependents to Add</span></div> -->
      <div style="padding: 20px;">
        <p>Make sure that you name a beneficiary for your L.E.E.B.A. provided life and AD&D insurance benefits. This
          simple but critical step ensures that if you should die, your benefits will go directly where you wish. If 
          multiple beneficiaries are added, the benefit will be divided equally between the beneficiaries named.

        </p>
        <p>Please note that a life event, such as marriage, divorce, or legal separation will not automatically affect a
          beneficiary designation, so we encourage you to periodically review your beneficiary election(s) to ensure it
          accurately reflects your wishes. You may review or change your beneficiary information at any time by calling
          the customer care center at 1-855-251-0137
        </p>
      </div>
      
      <app-dynamic-main-grid pageSize=50 class="co-12" 
      [params]='param'
      gridid='member/leebabeneficiaries' [hideButton]=true>
      </app-dynamic-main-grid>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Upload Supporting Documents</ng-template>
      <!-- <div style="padding-left: 20px;"> <input class="form-control"
          style="width: 50px;padding-left: 20px ; display: inline-block;" type="checkbox" /><span style="font-weight: 650; font-size: 18px;">No Documents to Add</span></div> -->
      <div style="padding-left: 20px;">
        <p>In order for your dependents to be covered under the L.E.E.B.A. plans, please upload supporting documentation
          to verify your relationship to the eligible person. A list of acceptable documentation is included below. All
          documentation is required by October 31st. <strong>If documentation is not received by October 31st then your
            dependents will not be covered.</strong>&nbsp;</p>

        <p>Spouses/Domestic Partners:</p>

        <ul style="list-style-type: disc;">
          <li>Marriage certificate</li>
          <li>Domestic Partner Attestation</li>
        </ul>

        <!-- <p>AND</p>

        <ul style="list-style-type: disc;">
          <li>Federal Tax Return within last 2 years listing your spouse&nbsp;
            <ul>
              <li>Send only the first page of your Form 1040 and black out all financial information and SSNs</li>
            </ul>
          </li>
          <li>Proof of Joint Ownership within the last 6 months
            <ul>
              <li>Mortgage statements, bank statements, credit card statements, rental/lease agreements or property tax
                statements with both parties&rsquo; names as co-owner</li>
            </ul>
          </li>
        </ul> -->

        <p>Children</p>

        <ul style="list-style-type: disc;">
          <li>Birth certificate, including all parent names</li>
          <li>Adoption certificate or Legal adoption placement document</li>
          <li>Legal documentation from the state court or federal government documenting legal guardianship<br />
            &nbsp;</li>
        </ul>

      </div>
      <app-dynamic-main-grid pageSize=50 class="co-12" gridid='member/leebadocuments'
      [params]='param1'
      [hideButton]=true>
      </app-dynamic-main-grid>
    </mat-step>
  </mat-horizontal-stepper>
  <div class="stepper-footer">

    <div fxLayout="row" fxLayoutAlign="start" style="width: 950px">
      <button
        style="border: 2px solid; padding: 5px ; border-radius: 3px; margin: 15px 8px 15px 0;  width: 140px; border: none; font-size: 18px;"
        mat-flat-button color="primary" (click)="onCancel()">Cancel</button>
      <button
        style="border: 2px solid; padding: 5px ; border-radius: 3px; margin: 15px 8px;  width: 140px; border: none; font-size: 18px;"
        mat-flat-button color="primary" *ngIf="stepper.selectedIndex !== 0"
        (click)="onPreviousStepper($event)">Previous</button>
      <button
        style="border: 2px solid; padding: 5px 15px; border-radius: 3px; margin: 15px 8px;   border: none; font-size: 18px;"
        *ngIf="stepper.selectedIndex !== 3" mat-flat-button color="primary" (click)="onNextStepper($event)">Save and
        Continue</button>
      <button
        style="border: 2px solid; padding: 5px ; border-radius: 3px; margin: 15px 8px; width: 140px; border: none; font-size: 18px;"
        *ngIf="stepper.selectedIndex == 3" mat-flat-button color="primary"
        (click)="openDialog()">Finish</button>
    </div>
  </div>
</div>