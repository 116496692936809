import { Component, OnInit, Input, Inject } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SpinnerService } from '@shared/services/spinner.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { MemberState } from '@store/main/member/member.state';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { isMemberStateMutating, getMemberState } from '@store/main/member/member.selector';

@Component({
  templateUrl: './reinstate.component.html',
  styleUrls: ['./reinstate.component.scss'],
})

export class ReinstateComponent extends BaseComponent implements OnInit {
  EntityId: string;
  ClientId: string;
  MemberId: string;
  EntityClientId;
  balance;
  memberState: MemberState | null;
  listOfAllData;
  mapOfCheckedId: { [key: string]: boolean } = {};
  rowid = 'benefitEntityPolicyId';
  constructor(
    public store: Store<AppState>,
    protected route: ActivatedRoute,
    protected routeHelper: RouteHelperService,
    protected router: Router,
    public spinner: SpinnerService,
    protected clientService: ClientService,
    protected notification: NzNotificationService
  ) {
    super();
  }
  onCancel() {
    this.router.navigate(['../dashboard'], { relativeTo: this.route });
  }
  onReinstatement() {
    const benefitsReinstateDTO: any = [];
    for (const id in this.mapOfCheckedId) {
      if (this.mapOfCheckedId[id] === true) {
        const obj1 = this.listOfAllData.find(
          (x) => x.benefitEntityPolicyId + x.benefitEntityPolicyDetailId === id
        );
        const obj = {
          benefitEntityPolicyId: obj1.benefitEntityPolicyId,
          benefitEntityPolicyDetailId: obj1.benefitEntityPolicyDetailId,
        };
        benefitsReinstateDTO.push(obj)
      }
    }
    const dto = {
      entityId: this.EntityId,
      participantId: this.MemberId,
      entityClientId: this.EntityClientId,
      benefitsReinstateDTO,
    };
    const url = 'Payment/ReinstateBenefit'
    const observ$ = this.clientService.postWithUrl({ url, dto })
    observ$.subscribe(
      (res) => {
        this.notification.blank('Reinstate  successfully', ' ')
        this.load();
        this.mapOfCheckedId = {}
      },
      (err) => {
        this.notification.blank(err.error[0], ' ')
      }
    )
  }

  load() {
    const url =
      'Enrollment/ParticipantBenefits?entityId=' +
      this.EntityId +
      '&entityclientId=' +
      this.EntityClientId +
      '&participantId=' +
      this.MemberId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.listOfAllData = data.sort(function (a, b) {
        const x = a.benefitStatus.toLowerCase();
        const y = b.benefitStatus.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    })
    const url1 =
      'Payment/ParticipantSuspenseBalance?entityId=' +
      this.EntityId +
      '&entityclientId=' +
      this.EntityClientId +
      '&participantId=' +
      this.MemberId;
    const observ1$ = this.clientService.getWithUrl(url1, {});
    observ1$.subscribe((data) => {
      this.balance = data;
    });
  }

  memberStateChange(state: MemberState) {
    this.memberState = state;
    if (!isMemberStateMutating(this.memberState)) {
      this.spinner.hide();
    }
  }

  subscribeState() {
    this.subscribeMemberState();
  }

  subscribeMemberState() {
    this.store
      .select(getMemberState)
      .pipe(untilComponentDestroyed(this))
      .subscribe((x) => this.memberStateChange(x));
  }

  ngOnInit() {
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.MemberId = this.routeHelper.getMemberId(this.route);
    this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
    this.load();
    this.subscribeState();
  }
}
