import { Component, OnInit, Inject } from '@angular/core';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { StripePaymentComponent} from '@shared/components/stripe-payment/stripe-payment.component';
@Component({
    
    templateUrl: './creditcard.component.html',
    
  })
  export class CreditCardComponent   implements OnInit  {
    constructor(public dialog: MatDialog,private notification: NzNotificationService){
        
      }
      onCheckout(){
        const dialogRef = this.dialog.open(StripePaymentComponent, {
          width: '450px',
          // opening dialog here which will give us back stripeToken
          data: {totalAmount: 50},
      });
  dialogRef.afterClosed()
          // waiting for stripe token that will be given back
          .subscribe((result: any) => {
              if (result) {
                  //this.createOrder(result.token.id);
                  this.notification.blank( ' Success, Topen='+result.token.id, ' ');
              }
          });
      }
    ngOnInit() {
        
      
    }
  }