import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { HttpService } from '@shared/api/http.service';
import { ConfigService } from '@shared/config.service';
import { ClientConfigDTO } from '@models/client/client-config.dto';
import { ClientContactDTO } from '@models/client/client-contact.dto';
import {
  ClientHeaderDTO,
  ClientDTO
} from '@models/client/client.dto';
import { HttpRequestOptions } from '@shared/api/shared';
import { ClientInfoDTO } from '@models/client/client-info.dto';
import { ClientBenefitDTO } from '@models/client/client-benefit.dto';
import { ClientBenefitConfigDTO } from '@models/client/client-benefit-config.dto';

@Injectable({
  providedIn: 'root'
})

export class ClientService {
  private apiUrls: any;

  constructor(
    private configService: ConfigService, 
    private http: HttpService) {
      this.apiUrls = this.configService.api.clientService;
    }

  public buildUrl(
    url: string,
    params: any,
    options: HttpRequestOptions
  ): {
    url: string;
    excessParams?: any;
  } {
    return this.http.buildUrl(
      url,
      params,
      options
    );
  }
  
  getWithUrlPost(body: {
    Url: string;
    EntityId?: string;
  }): Observable<any> {
  return this.http.post(body.Url, {
      body,
      options: {
        isMock: true
        // cache: 7 * 60 * 60 // 7 hours
      }
    });
  }

  getWithUrl(url: string, params: {
    EntityId?: string;
    ClientId?: string;
    ParticipantId?: string;

  }): Observable<any> {
    return this.http.get(url, {
      urlParams: params,
      options: {
        isMock: false
      }
    });
  }

  postWithUrl(params: {url: string, dto: any }): Observable<any> {
    return this.http.post(params.url, {
      body: params.dto,
      options: {
        isMock: false
      }
    });
  }
  putWithUrl(params: {url: string, dto: any }): Observable<any> {
    return this.http.put(params.url, {
      body: params.dto,
      options: {
        isMock: false
      }
    });
  }

  deleteWithUrl(url): Observable<any> {
    return this.http.delete(url, {
      options: {
      isMock: false
      }
    });
  }

  updateMain(params: {url: string, dto: any }): Observable<any> {
    return this.http.post(params.url, {
      body: params.dto,
      options: {
        isMock: false
      }
    });
  }

  getClientList(params: {
    entityId: string;
  }): Observable<ClientHeaderDTO[] | any[]> {
    return this.http.get(this.apiUrls.clientList, {
      urlParams: params,
      options: {
        isMock: false,
        cache: 7 * 60 * 60 // 7 hours
      }
    });
  }

  getClient(params: { entityClientId: string }): Observable<ClientDTO | any> {
    const url = 'Entity/ClientProfile?entityClientId=' + params.entityClientId;
    return this.http.get(url, {
      options: {
        isMock: false,
      }
    });
  }

  getClientInfo(params: {
    clientId: string;
  }): Observable<ClientInfoDTO | any> {
    return this.http.get(this.apiUrls.clientInfo, { urlParams: params });
  }

  saveClientInfo(params: {
    clientInfo: ClientInfoDTO | any;
  }): Observable<ClientInfoDTO | any> {
    return this.http.post(this.apiUrls.clientInfo, {
      body: params.clientInfo,
      urlParams: {
        clientId: params.clientInfo.clientId
      }
    });
  }

  getClientContact(params: {
    clientId: string;
  }): Observable<ClientContactDTO | any> {
    return this.http.get(this.apiUrls.clientContact, { urlParams: params });
  }

  saveClientContact(params: {
    clientContact: ClientContactDTO | any;
  }): Observable<any> {
    return this.http.post(this.apiUrls.clientContact, {
      body: params.clientContact,
      urlParams: {
        clientId: params.clientContact.clientId
      }
    });
  }

  getClientConfig(params: {
    clientId: string;
  }): Observable<ClientConfigDTO | any> {
    return this.http.get(this.apiUrls.clientConfig, { urlParams: params });
  }

  saveClientConfig(params: {
    clientConfig: ClientConfigDTO | any;
  }): Observable<ClientConfigDTO | any> {
    return this.http.post(this.apiUrls.clientConfig, {
      body: params.clientConfig,
      urlParams: {
        clientId: params.clientConfig.clientId
      }
    });
  }

  getClientBenefitList(params: {
    clientId: string;
  }): Observable<ClientBenefitDTO[] | any[]> {
    return this.http.get(this.apiUrls.clientBenefitList, { urlParams: params });
  }

  getClientBenefit(params: {
    clientBenefitId: string;
  }): Observable<ClientBenefitDTO | any> {
    return this.http.get(this.apiUrls.clientBenefit, { urlParams: params });
  }

  saveClientBenefit(params: {
    clientBenefit: ClientBenefitDTO | any;
  }): Observable<any> {
    return this.http.post(this.apiUrls.clientBenefit, {
      body: params.clientBenefit,
      urlParams: {
        clientBenefitId: params.clientBenefit.clientBenefitId
      }
    });
  }

  deleteClientBenefit(params: { clientBenefitId: string }): Observable<any> {
    return this.http.delete(this.apiUrls.clientBenefit, { urlParams: params });
  }

  getClientBenefitConfig(params: {
    clientBenefitId: string;
  }): Observable<ClientBenefitConfigDTO | any> {
    return this.http.get(this.apiUrls.clientBenefitConfig, {
      urlParams: params
    });
  }

  saveClientBenefitConfig(params: {
    clientBenefitConfig: ClientBenefitConfigDTO | any;
  }): Observable<any> {
    return this.http.post(this.apiUrls.clientBenefitConfig, {
      body: params.clientBenefitConfig,
      urlParams: {
        clientBenefitId: params.clientBenefitConfig.clientBenefitId
      }
    });
  }

  deleteClient(params: { clientId: string }): Observable<any> {
    return this.http.delete(this.apiUrls.client, { urlParams: params });
  }
}
