import { PipeTransform, Pipe } from '@angular/core';
import * as _ from 'lodash';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { getLookupState } from '@store/lookup/lookup.reducer';
import { LookupValue, LookupState } from '@store/lookup/lookup.state';
import { LoadLookupAction } from '@store/lookup/lookup.actions';
import { Subscription } from 'rxjs';
import { ClientService } from '@services/client/client.service';
@Pipe({
  name: 'calculate',
  pure: true
})
export class CalcuPipe implements PipeTransform {
  constructor() {

  }
  transform(lookupCode: string): any {
    const search = '&';

const searchRegExp = new RegExp(search, 'g');
    let myUrl =  lookupCode;
    const arr:any=myUrl.split("&");
    let dd:any=Date.parse(arr[1].toString())
    var mydate = new Date(arr[1].toString()); 
    const tomorrow = mydate;
    let days=0; 
    if (isNaN(mydate.getTime())) {  // d.valueOf() could also work
      // date is not valid
      return '';
    } else {
      // date is valid 
      switch(arr[0]){
        case '':
        case '00':
          case 'e1cf733c-5af6-4c1a-8cd1-e5b20aa2106b': //ov override null
          return '';
          break;
        case 'b62c09d9-7d7a-43c3-8257-f7d78845bf7b': //di divorce 36
        case '2d2c333e-3cdc-450a-a86d-1104eafe9ad4': //de death of employee 36
        case '0ecc6d8e-6664-4355-ad2c-cebbb7c2adea': //dc dependent ceasing to be eligible 36
          days=36
          break;
        case 'adb7069b-a53a-4a9a-ac01-36faab77ed4e': //me medicare eligible 29
          days=29;
          break;
        case '74b41003-98cf-4eac-b2d8-ea2d512fe222': //re reduction in work hours 18
        case '9a6480e2-249a-474c-b868-40fee32d841b': //te termination of employment 18
          days=18;
          break;
        case '0be3c5e7-c1bf-4063-abbc-b167933e9d2c': //ex extension of benefits 9
          days=9;
          break;
        case 'c3016a5e-8a28-4a93-aa56-aa0f3525f905': //op one person group 6
          days=6;
          break;
        default:
          return ''
      }
      
      tomorrow.setMonth(mydate.getMonth() + days);
      tomorrow.setDate(tomorrow.getDate()-1);

    }
    return  tomorrow;
  }
}
@Pipe({
  name: 'replace',
  pure: true
})
export class ReplacePipe implements PipeTransform {
  constructor() {

  }
  transform(lookupCode: string): any {
    const search = '&';

const searchRegExp = new RegExp(search, 'g');
    let myUrl = lookupCode.replace(searchRegExp," ");
    
    return  myUrl;
  }
}
@Pipe({
  name: 'transurl',
  pure: true
})
export class TransUrlPipe implements PipeTransform {
  constructor(protected clientService: ClientService) {

  }
  protected transurl(url, curval) {
    let myurl = url;
    while (true) {
      let n = myurl.search(/:/i);
      if (n === -1) {
        break;
      }
      let res = myurl.substring( n + 1);
      n = res.search(/&/i);
      if ( n === -1) {
        n = res.search(/\//i);
        if ( n === -1) {
        res = res.substring( 0 );
        }else{
          res = res.substring( 0, n );
        }
      } else {
        res = res.substring( 0, n );
      }
      let val = curval[res];
      if(val instanceof Date){
        val=val.toLocaleDateString();
      }
      const pt = ':' + res;
      if (val != null) {
        myurl = myurl.replace(pt, val);
      } else {
        break;
      }
    }
    return myurl;
  }
  transform(lookupCode: string, obj): any {
    let myUrl = lookupCode;
    if (obj != null) {
      myUrl = this.transurl(lookupCode, obj);
    }


    return  myUrl;
  }
}
@Pipe({
  name: 'lookupapi',
  pure: true
})
export class LookupApiPipe implements PipeTransform {
  lookup;
  constructor(protected clientService: ClientService) {

  }

  transform(lookupCode: string): any {
    const  observ$ = this.clientService.getWithUrl(lookupCode, { });
    // observ$.subscribe((data) => {
    //  // this.lookup = data;
    //  } );
    return observ$;
   // return  this.lookup;
  }
}
@Pipe({
  name: 'lookup',
  pure: false
})
export class LookupPipe implements PipeTransform {
  lookup: LookupValue;
  loadLookup = true;
  lookupSubscription$: Subscription;

  constructor(protected store: Store<AppState>) {}

  transform(lookupCode: string): LookupValue {
    this.lookupSubscription$ = this.store
      .pipe(select(getLookupState))
      .subscribe(x => this.lookupStateChange(lookupCode, x));

    return this.lookup;
  }

  protected lookupStateChange(lookupCode: string, state: LookupState) {
    const lookup = state.lookups && state.lookups[lookupCode];

    if (lookup) {
      this.lookup = lookup;
      this.unsubscribe();
      return;
    }

    if (this.loadLookup) {
      // this.store.dispatch(new LoadLookupAction({ lookupCode }));
      this.dispatchAction(lookupCode);
      this.loadLookup = false;
    }
  }

    protected dispatchAction(lookupCode) {
      this.store.dispatch(new LoadLookupAction({ lookupCode , isMock : true  }));
    }
  private unsubscribe() {
    if (this.lookupSubscription$) {
      this.lookupSubscription$.unsubscribe();
    }
  }
}

@Pipe({
  name: 'lookupHttp',
  pure: false
})
export class LookupHttpPipe extends LookupPipe implements PipeTransform {
  protected dispatchAction(lookupCode) {
    this.store.dispatch(new LoadLookupAction({ lookupCode , isMock : false }));
  }

}
