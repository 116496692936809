<div class="app-container">
  <app-selected-member-header-share [memberState]="memberState"></app-selected-member-header-share>


  <hr />
  <div>
    <h6 class="member-function-header">Benefits</h6>
  </div>
  <span [hidden]="initloadpolicy!=true" style="font-size:30px;position: fixed;left:47%;top: 45%; color: #48c5cb ">
    <div class="loader"></div>
  </span>
  <div class="row">
    <div class="col-12">
      <form>
        <div class="row">
          <div class="col-12">
            <app-form-group-wrapper>
              <form novalidate [formGroup]="policiesForm">

                <div class="row">

                  <div class="col-9">
                    <div class="tab-content" id="v-pills-tabContent" *ngIf="policies" [hidden]='initloadpolicy===true'>
                      <ng-container *ngFor="let item of policies;">
                        <app-member-plan [benefitEntityPolicy]='item'
                          (formReady)="formInitialized(item.benefitEntityPolicyId, $event)"> </app-member-plan>

                      </ng-container>
                    </div>
                  </div>
                  <div class="col-3 ">
                    <div class="nav flex-column nav-pills cartblock" id="v-pills-tab" role="tablist"
                      aria-orientation="vertical">
                      <app-member-plans-cart [benefitEntities]='formValue' [benefitValue]='policiesForm.value'
                        (OnChange)='onEntityChange($event)' (OnConfirm)='onConfirm()'></app-member-plans-cart>
                    </div>
                  </div>
                </div>
              </form>
            </app-form-group-wrapper>
          </div>
        </div>
        <div class="row">
          <div class="col form-button-bar">
            <a mat-flat-button color="primary" class="mat-flat-button mat-primary"
              routerLink="../dashboard">Cancel</a>&nbsp;
          </div>
        </div>
      </form>
    </div>

  </div>

</div>
<div class="bottom-space"></div>
<!-- <h2 class='mat-h2'>Form Value policiesForm</h2>
    <pre>
      {{policiesForm.value | json}}
    </pre> -->