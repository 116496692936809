import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
@Component({
    selector: 'app-member-coverage',
    templateUrl: './coverage.component.html',
    styleUrls: ['./../dynamic.component.scss']
  })
  export class CoverageComponent extends BaseComponent implements OnInit {
    @Input()
    coverages;
    @Input()
    set coverageValue(coverageValue) {
      this._coverageValue = coverageValue;
      if (coverageValue) {
        this.coverage = coverageValue['coverage'];
        this.coverageVal = coverageValue['coverageValue'];
      }

    }
    _coverageValue;
    coverageVal;
    coverage;
    @Output() formReady = new EventEmitter<UntypedFormGroup>();
    @Output() changeCov = new EventEmitter<any>();
    coverageForm: UntypedFormGroup;
    constructor(private fb: UntypedFormBuilder) {
      super();
    }
    onChange(event) {
      const selectV = event.target.value;
      const f = this.coverages.find(cov => cov.benefitEntityPolicyDetailCoverageId === selectV);
      if (f != null) {
        this.coverageForm.value.coverageValue = f.benefitEntityPolicyDetailCoverageValue;
        this.coverageForm.patchValue(this.coverageForm.value);
      }
      this.changeCov.emit({id: selectV, value: f.benefitEntityPolicyDetailCoverageValue});
    }
    ngOnInit() {
      if (this.coverageValue) {
          this.coverage = this.coverageValue['coverage'];
      }
      this.coverageForm = this.fb.group({
        coverage: this.coverage,
        coverageValue: this.coverageVal
      });

      // Emit the form group to the father to do whatever it wishes
      this.formReady.emit(this.coverageForm);
      }
}
