import {
  Component,
  OnInit,
  Inject,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from '@angular/material/dialog';
import { BaseComponent } from '@shared/components/base.component';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '@store/app.state';
import { Store } from '@ngrx/store';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { LoggerService } from '@shared/logging/logger.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { MemberService } from '@services/client/member.service';
import { ExcelService } from '@services/excel/excel.service';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { GetMainUrlAction } from '@store/main/main.actions';
import { MainState } from '@store/main/main.state';
import * as _ from 'lodash';
import {
  getMemberState,
  isMemberStateMutating,
} from '@store/main/member/member.selector';
import { getMainState, isMainStateMutating } from '@store/main/main.selector';
import { getAuthState } from '@store/auth/auth.selector';
import { MemberState } from '@store/main/member/member.state';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import {
  GetMemberAction,
  GetMemberPagingAction,
  GetMemberUrlAction,
} from '@store/main/member/member.actions';
import { HttpService } from '@shared/api/http.service';
import { ClientService } from '@services/client/client.service';
import { AuthService } from '@services/auth/auth.service';
import { DynamicFormWithGridContainnerComponent } from '@shared/components/dynamic_form_with_grid/dynamic_form_with_grid.component';

@Component({
  selector: 'app-dynamic-base-grid',
  templateUrl: './dynamic-grid.component.html',
  styleUrls: ['./../dynamic.component.scss'],
})
export class DynamicGridComponent extends BaseComponent implements OnInit {
  disablememberquick=false;
  searchValue = '';
  icon = 'search';
  sortName: string | null = null;
  sortValue: string | null = null;
  sortType;
  searchFormValue;
  searchUrl;
  id: string;
  gridConfigs: any;
  accountNumIndexList: any = [];
  rows: any = [];
  orirows: any = [];
  filters: any = [];
  _jsonURL;
  summaryColumn1;
  summaryColumn2;
  sum = 0;
  summaryLabel;
  summaryExpr;
  summaryColumn3;
  memberState?: MemberState;
  memberProp;
  innerMemberProp;
  paging;
  pageSize = 10;
  newForm;
  searchForm;
  editForm;
  TotalPageCount;
  TotalRowCount;
  pageIndex = 0;
  getPagingUrl: string;
  title: string;
  actionButton;
  popup;
  cancelButton;
  innerform;
  innerform1;
  entityIdFromPath;
  isInner = false;
  getUrl;
  useDataItem;
  EntityId: string;
  ClientId: string;
  MemberId: string;
  entityClientId;
  addNewButton = 'true';
  loading;

  constructor(
    protected authService: AuthService,
    private http: HttpClient,
    public dialog: MatDialog,
    private logger: LoggerService,
    protected clientService: ClientService,
    protected store: Store<AppState>,
    protected routeHelper: RouteHelperService,
    protected route: ActivatedRoute,
    protected memberService: MemberService,
    protected router: Router,
    protected spinner: SpinnerService,
    private httpser: HttpService,
    protected excelService: ExcelService
  ) {
    super();
  }

  protected toCamel(o): any {
    const newO = {};
    for (const origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        const newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        const value = o[origKey];
        newO[newKey] = value;
      }
    }
    return newO;
  }

  download() {
    // convert rows
    const excelrows: any = [];
    this.rows.forEach((row) => {
      const obj = {};
      this.gridConfigs.forEach((col) => {
        /*  if(col.columnType=='text')
           obj[col.columnLabel] =row[col.columnName];
         if(col.columnType=='number')
           obj[col.columnLabel] =parseFloat(row[col.columnName]);*/
        if (col.columnType === 'rolelist') {
          var itemconv = this.transformrole(row[col.columnName]);
          obj[col.columnLabel] = itemconv;
        } else if (col.columnType === 'clientlist') {
          var itemconv = this.transform(row[col.columnName]);
          obj[col.columnLabel] = itemconv;
        } else {
          obj[col.columnLabel] = row[col.columnName];
        }
      });
      excelrows.push(obj);
    });
    this.excelService.exportAsExcelFile(excelrows, this.id);
  }

  searchCust(key, obj) {
    // obj.filterType = 'in';
    const index = this.filters.findIndex((x) => x.key === key);
    if (index === -1) {
      this.filters.push({
        value: this.searchValue,
        key,
        type: 'search',
        columnType: obj.columnType,
      });
    } else {
      this.filters[index] = {
        value: this.searchValue,
        key,
        type: 'search',
        columnType: obj.columnType,
      };
    }
    this.search();
  }

  resetCust(key) {
    const index = this.filters.findIndex((x) => x.key === key);
    if (index === -1) {
      // this.filters.push({value: this.searchValue, key , type: 'search'});
    } else {
      this.filters[index] = { value: '', key, type: 'search' };
      this.search();
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    // need to find data type
    if (this.gridConfigs != null) {
      this.gridConfigs.forEach((element) => {
        if (element['columnName'] === this.sortName) {
          this.sortType = element.columnType;
        }
      });
    }
    this.search();
  }

  filter(value, key, type): void {
    const index = this.filters.findIndex((x) => x.key === key);
    if (index === -1) {
      this.filters.push({ value, key, type });
    } else {
      this.filters[index] = { value, key, type };
    }
    // this.filters.
    /* this.filterKey = key;
    this.filterVal = value; */
    this.search();
  }

  transformrole(value): string {
    var rolenames = '';
    value.forEach((it) => {
      switch (it) {
        case '3a6b9f38-e8c2-4fc1-a6e1-04b55139f83f':
          rolenames = rolenames + ' APIUser,';
          break;
        case '6fe1f373-f810-43f0-883e-2d21abc513f0':
          rolenames = rolenames + ' Configuration Manager,';
          break;
        case '581f538a-89db-4c00-a678-6818f8cc3593':
          rolenames = rolenames + ' Administration Manager,';
          break;
        case '110c9594-b146-4344-bf82-9034c66d8bda':
          rolenames = rolenames + ' Admin Global,';
          break;
        case '9f332003-c38e-4751-8f14-9d86074cf937':
          rolenames = rolenames + ' LegalUser,';
          break;
        case 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028':
          rolenames = rolenames + ' Anthem,';
          break;
        case '9eba25ef-56b8-4d5a-8e91-c82d3f3d776b-bba0-48fa-9396-a6b202631669':
          rolenames = rolenames + ' B2BJob,';
          break;
        default:
          rolenames = rolenames + ' ';
      }
    });
    return rolenames;
  }

  transform(value): string {
    var listnames = '';
    value.forEach((it) => {
      switch (it) {
        case 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b':
          listnames = listnames + ' LEEBA,';
          break;
        case '9808f856-f047-4843-bec8-277e414fc649':
          listnames = listnames + ' TMAIT,';
          break;
        case 'c935affd-0e7c-4b2c-ae8b-1c9d0e53905b':
          listnames = listnames + ' TENET,';
          break;
        case '3b7805a6-10fa-48b5-b0ee-ea5365258341':
          listnames = listnames + ' Essex,';
          break;
        case 'ac586073-69d6-41bf-875c-eb754f750457':
          listnames = listnames + ' RTX,';
          break;
        case '68bc62e6-dd96-4428-91e8-efe285bd23a0':
          listnames = listnames + ' APWU,';
          break;
        case 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028':
          listnames = listnames + ' Anthem,';
          break;
        case 'd6a2ff7e-bba0-48fa-9396-a6b202631669':
          listnames = listnames + ' COBRA,';
          break;
        case '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d':
          listnames = listnames + ' Horace Mann,';
          break;
        case 'f9d5f5e4-9e28-4a23-9cc3-9bb9d81d15e4':
          listnames = listnames + '  SafetyNet,';
          break;
        //  Removed per Jodie's request CHESH-544
        // case '31e56172-ac96-487c-a6b3-d5847954f3b2':
        //         listnames=listnames+' UNUM,';
        //      break;
        default:
          listnames = listnames + ' ';
      }
    });
    return listnames;
  }

  search(): void {
    this.rows = [...this.orirows];
    this.filters.forEach((element) => {
      if (element.type === 'in') {
        if (element.value.length !== 0) {
          this.rows = this.rows.filter((item) => {
            const index = element.value.findIndex(
              (e) => e === item[element.key]
            );
            return index !== -1;
          });
        }
      }
      if (element.type === 'search') {
        if (element.value !== '') {
          this.rows = this.rows.filter((item) => {
            if (element.value.toLowerCase() == '"null"') {
              if (item[element.key] == null) {
                return true;
              }
              if (item[element.key] == '') {
                return true;
              }
            }
            if (item[element.key] == null) {
              return false;
            }
            if (element.columnType === 'date') {
              const mydate = new Date(element.value);
              if (item[element.key].getTime() === mydate.getTime()) {
                return true;
              }
              return false;
            } else if (element.columnType === 'clientlist') {
              var itemconv = this.transform(item[element.key]);
              const index = itemconv
                .toLowerCase()
                .indexOf(element.value.toLowerCase());
              return index !== -1;
            } else if (element.columnType === 'rolelist') {
              var itemconv = this.transformrole(item[element.key]);
              const index = itemconv
                .toLowerCase()
                .indexOf(element.value.toLowerCase());
              return index !== -1;
            } else {
              const index = item[element.key]
                .toLowerCase()
                .indexOf(element.value.toLowerCase());
              return index !== -1;
            }
          });
        }
      }
    });
    const filteredData = [...this.rows];
    /* const filterFunc = (item: { name: string; age: number; address: string }) =>
      (this.searchAddress ? item.address.indexOf(this.searchAddress) !== -1 : true) &&
      (this.listOfSearchName.length ? this.listOfSearchName.some(name => item.name.indexOf(name) !== -1) : true);
    const data = this.listOfData.filter(item => filterFunc(item)); */
    /** sort data **/

    if (this.sortName && this.sortValue) {
      this.rows = this.rows.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
      );
      this.rows = [...this.rows];
    } else {
      if (filteredData != null) {
        this.rows = [...filteredData];
      }
    }
  }

  readJson(data1) {
    this.gridConfigs = (<any>data1).GridConfig;
    this.memberProp = (<any>data1).memberProp;
    this.innerMemberProp = (<any>data1).innerMemberProp;
    this.paging = (<any>data1).paging;
    this.pageSize = (<any>data1).pageSize;
    this.getPagingUrl = (<any>data1).getPagingUrl;
    this.title = (<any>data1).title;
    this.actionButton = (<any>data1).actionButton;
    this.summaryColumn1 = (<any>data1).summaryColumn1;
    this.summaryColumn2 = (<any>data1).summaryColumn2;
    this.summaryLabel = (<any>data1).summaryLabel;
    this.summaryExpr = (<any>data1).summaryExpr;
    this.popup = (<any>data1).popup;
    this.entityIdFromPath = (<any>data1).entityIdFromPath;
    this.getUrl = (<any>data1).getUrl;
    this.cancelButton = (<any>data1).cancelButton;
    this.addNewButton = (<any>data1).addNewButton;
    this.innerform = (<any>data1).innerform;
    this.innerform1 = (<any>data1).innerform1;
    this.newForm = (<any>data1).newForm;
    this.editForm = (<any>data1).editForm;
    this.searchForm = (<any>data1).searchForm;
    this.searchUrl = (<any>data1).searchUrl;
    this.useDataItem = (<any>data1).useDataItem;
    if (this.addNewButton == null) {
      this.addNewButton = 'true';
    }
    if (this.actionButton == null) {
      this.actionButton = 'true';
    }
    if (this.pageSize == null) {
      this.pageSize = 10;
    }
  }

  protected onSearch() {
    this.searchFormValue = {};
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: false,
        item: this.searchFormValue,
        formid: this.searchForm,
        prePath: '',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        this.rows = [];
        this.searchFormValue = result;
        const observ$ = this.clientService
          .postWithUrl({ url: this.searchUrl, dto: result })
          .pipe(untilComponentDestroyed(this));
        observ$.subscribe((data) => {
          this.rows = data;
        });
      }
    });
  }

  protected transurl(url, curval) {
    let myurl = url;
    while (true) {
      let n = myurl.search(/:/i);
      if (n === -1) {
        break;
      }
      let res = myurl.substring(n + 1);
      n = res.search(/&/i);
      if (n === -1) {
        n = res.search(/\//i);
        if (n !== -1) {
          res = res.substring(0, n);
        } else {
          res = res.substring(0);
        }
      } else {
        res = res.substring(0, n);
      }
      const val = curval[res];
      const pt = ':' + res;
      if (val != null) {
        myurl = myurl.replace(pt, val);
      } else {
        break;
      }
    }
    return myurl;
  }

  ngOnInit() {
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.ClientId = '00000000-0000-0000-0000-000000000000'; // this.routeHelper.getClientId(this.route);
    this.MemberId = this.routeHelper.getMemberId(this.route);
    this.entityClientId = this.routeHelper.getEntityClientId(this.route);
    this.init();
  }

  protected init() {
    this.route.params.subscribe((params) => {
      this.id = params['gridid'];
      if (this.id) {
        this.subscribeJson();
      } else {
        this.route.data.subscribe((data) => {
          this.id = data.gridid;
          this.subscribeJson();
        });
      }
    });
  }

  public subscribeJson(): void {
    this.getJsonPath();
    this.getJSON().subscribe((data1) => {
      const json = JSON.parse((<any>data1.jsonText) as string);

      if (data1 === null) {
        this.gridConfigs = [];
      } else {
        this.readJson(json);
        this.subscribeMemberState();
        if (this.getUrl != null) {
          this.GetUrlAction();
        }
      }
    });
  }

  protected GetUrlAction() {
    // this.spinner.show();
    this.store.dispatch(
      new GetMemberUrlAction({
        Url: this.getUrl,
        Prop: this.memberProp,
        EntityId: this.EntityId,
        ClientId: this.ClientId,
        MemberId: this.MemberId,
        entityclientId: this.entityClientId,
      })
    );
  }

  protected getJsonPath() {
    this._jsonURL = 'member/' + this.id.toLowerCase() + 'grid';
  }

  protected subscribeMemberState() {
    this.store
      .select(getMemberState)
      .pipe(untilComponentDestroyed(this))
      .subscribe((x) => this.memberStateChange(x));
  }

  getRows(id) {}

  protected loadMemberData() {
    const EntityId = this.routeHelper.getEntityId(this.route);
    const ClientId = this.routeHelper.getClientId(this.route);
    const MemberId = this.routeHelper.getMemberId(this.route);
    this.spinner.show();
    this.store.dispatch(new GetMemberAction({ EntityId, ClientId, MemberId }));
  }

  protected addNew() {
    let formid = this.id;
    if (this.newForm != null) {
      formid = this.newForm;
    }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: true,
        entityId: this.isInner
          ? this.EntityId
          : this.routeHelper.getEntityId(this.route),
        clientId: this.isInner
          ? this.ClientId
          : this.routeHelper.getClientId(this.route),
        participantId: this.isInner
          ? this.MemberId
          : this.routeHelper.getMemberId(this.route),
        entityClientId: this.entityClientId,
        item: {
          entityClientId: this.entityClientId,
          entityId: this.EntityId,
          participantId: this.isInner
            ? this.MemberId
            : this.routeHelper.getMemberId(this.route),
        },
        formid: formid,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (this.getUrl != null) {
        this.GetUrlAction();
      }
    });
  }

  protected action(item: any): void {
    let dialogRef;
    if (this.popup === 'grid') {
      dialogRef = this.dialog.open(DynamicDialogGridComponent, {
        width: '90%',
        data: {
          entityId: this.routeHelper.getEntityId(this.route),
          clientId: this.routeHelper.getClientId(this.route),
          participantId: this.routeHelper.getMemberId(this.route),
          item: item,
          formid: this.id,
        },
      });
    } else {
      dialogRef = this.dialog.open(DynamicDialogFormComponent, {
        width: '90%',
        data: {
          addNew: false,
          entityId: this.routeHelper.getEntityId(this.route),
          clientId: this.routeHelper.getClientId(this.route),
          participantId: this.routeHelper.getMemberId(this.route),
          item: { ...item, EntityId: this.routeHelper.getEntityId(this.route) },
          formid:
            this.isInner && this.editForm != null ? this.editForm : this.id,
        },
      });
    }
    dialogRef.afterClosed().subscribe((result) => {
      if (this.getUrl != null) {
        this.GetUrlAction();
      }
    });
  }

  public getJSON(): Observable<any> {
    return this.httpser.get('json?path=' + this._jsonURL, {
      urlParams: null,
      options: {
        isMock: false,
      },
    });
  }

  onCancel() {
    if (this.router.url.includes('letters/letters') && this.MemberId == '') {
     // this.router.navigate(['../../../dashboard'], { relativeTo: this.route });
     this.router.navigate(['../../'], { relativeTo: this.route });
    } else
      this.router.navigate(['../../dashboard'], { relativeTo: this.route });
  }

  calcsummaryColumn(rows) {
    if (this.summaryColumn1 != null) {
      const reduser = (acc, cur) => acc + cur;
      const sum1 = rows
        .filter((el) => el['status'] == 'OP')
        .map((el) => el[this.summaryColumn1])
        .reduce(reduser, 0);
      const sum2 =
        this.summaryColumn2 == null
          ? 0
          : rows
              .filter((el) => el['status'] == 'OP')
              .map((el) => el[this.summaryColumn2])
              .reduce(reduser, 0);
      const sum3 =
        this.summaryColumn3 == null
          ? 0
          : rows
              .filter((el) => el['status'] == 'OP')
              .map((el) => el[this.summaryColumn3])
              .reduce(reduser, 0);
      this.sum = eval(this.summaryExpr);
    }
  }

  private memberStateChange(state: MemberState) {
    if (!isMemberStateMutating(state)) {
      this.memberState = state;
      if (this.memberState && this.memberState.member) {
        if (this.memberState.member) {
          const pp = this.memberProp;
          if (pp) {
            if (this.innerMemberProp && this.memberState.member[pp]) {
              this.spinner.hide();
              if (this.memberState.member[pp].details != null) {
                this.rows = this.memberState.member[pp].details;
              } else {
                this.rows = this.memberState.member[pp][this.innerMemberProp];
              }
              this.orirows = [...this.rows];
              this.calcsummaryColumn(this.rows);
            } else {
              if (this.memberState.member[pp]) {
                if (this.memberState.member[pp].details != null) {
                  this.rows = this.memberState.member[pp].details;
                } else {
                  this.rows = this.memberState.member[pp];
                }

                this.orirows = [...this.rows];
                this.calcsummaryColumn(this.rows);
              }
            }
            if (!this.memberState.member[pp]) {
            }
            if (this.rows.length > 0) {
              let tmpAccountNumIndexList: any = [];
              this.rows.forEach(function (value, index) {
                if (value.changeName === 'AccountNumber') {
                  tmpAccountNumIndexList.push(index);
                }
              });
              this.accountNumIndexList = tmpAccountNumIndexList;
            }
          }
        }
      }
    }
  }
}

@Component({
  selector: 'app-dynamic-dialog-grid',
  templateUrl: './dynamic-dialog.grid.component.html',
  styleUrls: ['./../dynamic.component.scss'],
})
export class DynamicDialogGridComponent
  extends DynamicGridComponent
  implements OnInit
{
  dataItem;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected authService: AuthService,
    public dialogRef: MatDialogRef<DynamicGridComponent>,
    http: HttpClient,
    public dialog: MatDialog,
    logger: LoggerService,
    store: Store<AppState>,
    routeHelper: RouteHelperService,
    route: ActivatedRoute,
    protected clientService: ClientService,
    public memberService: MemberService,
    router: Router,
    spinner: SpinnerService,
    httpser: HttpService,
    protected excelService: ExcelService
  ) {
    super(
      authService,
      http,
      dialog,
      logger,
      clientService,
      store,
      routeHelper,
      route,
      memberService,
      router,
      spinner,
      httpser,
      excelService
    );
    if (data) {
      this._jsonURL = 'member/' + data.formid.toLowerCase() + 'innergrid';
      this.EntityId = this.data.entityId;
      this.MemberId = this.data.participantId;
      this.ClientId = this.data.clientId;
      this.id = data.formid;
      this.entityClientId = this.data.entityClientId;
      if (data.item != null) {
        this.dataItem = data.item;
      }
      this.isInner = true;
    }
  }

  protected GetUrlAction() {
    let myUrl = this.transurl(this.getUrl, this.data);
    if (this.dataItem != null) {
      myUrl = this.transurl(myUrl, this.dataItem);
    }
    const observ$ = this.clientService.getWithUrl(myUrl, {});
    observ$.subscribe((data) => {
      this.rows = data[this.memberProp];
      this.onload();
    });
  }

  protected onload() {}
  protected addNew() {
    let formid = this.id;
    if (this.newForm != null) {
      formid = this.newForm;
    }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: true,
        entityId: this.isInner
          ? this.EntityId
          : this.routeHelper.getEntityId(this.route),
        clientId: this.isInner
          ? this.ClientId
          : this.routeHelper.getClientId(this.route),
        participantId: this.isInner
          ? this.MemberId
          : this.routeHelper.getMemberId(this.route),
        entityClientId: this.entityClientId,
        item: this.useDataItem
          ? {
              entityClientId: this.entityClientId,
              entityId: this.EntityId,
              ...this.dataItem,
            }
          : { entityClientId: this.entityClientId, entityId: this.EntityId },
        formid: formid,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (this.getUrl != null) {
        this.GetUrlAction();
      }
    });
  }

  ngOnInit() {
    this.getJSON().subscribe((data1) => {
      const json = JSON.parse((<any>data1.jsonText) as string);
      if (data1 === null) {
        this.gridConfigs = [];
      } else {
        this.readJson(json);
        this.GetUrlAction();
      }
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-dynamic-main-grid',
  templateUrl: './dynamic-main-grid.component.html',
  styleUrls: ['./../dynamic.component.scss'],
})
export class DynamicMainGridComponent
  extends DynamicGridComponent
  implements OnInit
{
  @Input()
  hideButton = false;
  @Input()
  addInit;
  @Input()
  gridid;
  @Input()
  formid;
  @Input()
  set params(params) {
    this._params = params;
    this.GetUrlAction();
  }
  @Input()
  set inputRows(inputRows) {
    if (inputRows != null) {
      this.rows = inputRows;
      if (this.rows != null) {
        this.orirows = [...this.rows];
        this.search();
      }
    }
  }
  @Input()
  pageSize;
  @Input()
  policyIds;
  @Output()
  selected: EventEmitter<any> = new EventEmitter();
  @Output()
  actionPopup: EventEmitter<any> = new EventEmitter();
  @Output()
  onprint: EventEmitter<any> = new EventEmitter();
  _params;
  entityList;
  loading;

  print(item) {
    this.onprint.emit(item);
  }

  ngOnInit() {
    this.init();
    if (this.pageSize == null) {
      this.pageSize = 10;
    }
    this.entityList = this.authService.getEntityList();
  }

  onCancel() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  protected subscribeMemberState() {
    this.store
      .select(getMainState)
      .pipe(untilComponentDestroyed(this))
      .subscribe((x) => this.MainStateChange(x));
  }

  private MainStateChange(state: MainState) {
    if (!isMainStateMutating(state)) {
      const pp = this.memberProp;
      if (pp) {
        // this.spinner.hide();
        if (state[pp] != null && state[pp].details) {
          this.rows = state[pp].details.map((o) => {
            return this.toCamel(o);
          });
          //  this.rows = state[pp].details;
        } else if (state[pp] != null && state[pp][pp]) {
          this.rows = state[pp][pp];
        } else {
          if (
            this.innerMemberProp &&
            state[pp] &&
            state[pp][this.innerMemberProp]
          ) {
            this.rows = state[pp][this.innerMemberProp];
          } else {
            this.rows = state[pp];
          }
        }
        if (this.rows != null) {
          this.orirows = [...this.rows];
          this.calcsummaryColumn(this.rows);
          this.search();
        }
      }
    }
  }

  protected GetUrlAction() {
    let myUrl = this.getUrl;
    if (this._params && this.getUrl != null) {
      myUrl = this.transurl(this.getUrl, this._params);
    }
    if (this.addInit != null && myUrl != null) {
      myUrl = this.transurl(myUrl, this.addInit);
    }
    if (this._params == null && myUrl != null) {
      const EntityId = this.routeHelper.getEntityId(this.route);
      const entityClientId = this.routeHelper.getEntityClientId(this.route);
      const obj = { EntityId, entityClientId };
      myUrl = this.transurl(myUrl, obj);
    }
    if (myUrl != null) {
      this.rows = [];
      this.spinner.show();

      this.store.dispatch(
        new GetMainUrlAction({
          Url: myUrl,
          Prop: this.memberProp,
        })
      );
    }
  }

  protected getJsonPath() {
    if (this.gridid == null) {
      this._jsonURL = this.id.toLowerCase() + 'grid';
    } else {
      this.id = this.gridid;
      this._jsonURL = this.gridid.toLowerCase() + 'grid';
    }
  }

  protected action(item: any): void {
    if (this.entityList == null) {
      this.entityList = [
        {
          entityId: '68bc62e6-dd96-4428-91e8-efe285bd23a0',
          entityName: 'APWU',
        },
        {
          entityId: '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d',
          entityName: 'Horace Mann',
        },
        {
          entityId: '9808f856-f047-4843-bec8-277e414fc649',
          entityName: 'TMA Insurance Trust',
        },
        {
          entityId: '3b7805a6-10fa-48b5-b0ee-ea5365258341',
          entityName: 'Essex Corporation',
        },
      ];
    }

    let dialogRef;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (this.popup === 'none') {
      this.actionPopup.emit(item);
      return;
    }

    if (this.popup === 'formandgrid') {
      dialogRef = this.dialog.open(DynamicFormWithGridContainnerComponent, {
        disableClose: true,
        width: '90%',
        data: {
          paraArr: this.entityList,
          addNew: false,
          innerformid: this.innerform,
          innerformid1: this.innerform1,
          item: item,
          formid: this.id,
        },
      });
    } else {
      if (this._params != null) {
        item = { ...item, ...this._params };
      }
      dialogRef = this.dialog.open(DynamicDialogFormComponent, {
        disableClose: true,
        width: '90%',
        data: {
          paraArr: this.entityList,
          addNew: false,
          item: item,
          formid: this.id,
          prePath: '',
        },
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== false && result != null) {
        if (this.getUrl != null) {
          this.GetUrlAction();
        } else {
          Object.assign(item, result);
        }
      }
    });
  }

  protected addNew() {
    if (this.entityList == null) {
      this.entityList = [
        {
          entityId: '68bc62e6-dd96-4428-91e8-efe285bd23a0',
          entityName: 'APWU',
        },
        {
          entityId: '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d',
          entityName: 'Horace Mann',
        },
        {
          entityId: '9808f856-f047-4843-bec8-277e414fc649',
          entityName: 'TMA Insurance Trust',
        },
        {
          entityId: '3b7805a6-10fa-48b5-b0ee-ea5365258341',
          entityName: 'Essex Corporation',
        },
      ];
    }
    let dialogRef;
    if (this.popup === 'formandgrid') {
      if (this.newForm != null) {
        dialogRef = this.dialog.open(DynamicDialogFormComponent, {
          width: '90%',
          data: {
            addNew: true,
            prePath: '',
            item: this.addInit ? { ...this.addInit } : {},
            formid: this.newForm,
            innerformid: this.innerform,
          },
        });
      } else {
        dialogRef = this.dialog.open(DynamicDialogFormComponent, {
          width: '90%',
          data: {
            paraArr: this.entityList,
            addNew: true,
            prePath: '',
            item: this.addInit ? { ...this.addInit } : {},
            formid: this.id,
            innerformid: this.innerform,
          },
        });
      }
    } else {
      if (this._params == null) {
        this._params = {};
      }
      if (this.entityIdFromPath === 'true') {
        this.EntityId = this.routeHelper.getEntityId(this.route);
        // this.ClientId = this.routeHelper.getClientId(this.route);
        this.entityClientId = this.routeHelper.getEntityClientId(this.route);
        if (this.EntityId != '') {
          this._params['entityId'] = this.EntityId;
          this._params['EntityId'] = this.EntityId;
        }
        if (this.entityClientId != '') {
          this._params['entityClientId'] = this.entityClientId;
        }
      }
      dialogRef = this.dialog.open(DynamicDialogFormComponent, {
        width: '90%',
        data: {
          addNew: true,
          item: { ...this._params },
          formid: this.newForm ? this.newForm : this.id,
          prePath: '',
          entityId: this.EntityId,
          entityClientId: this.entityClientId,
        },
      });
    }
    dialogRef.afterClosed().subscribe((result) => {
      this.GetUrlAction();
    });
  }

  selection(item: any): void {
    this.selected.emit(item);
  }
}

@Component({
  selector: 'app-dynamic-main-grid-header',
  templateUrl: './dynamic-main-grid-header.component.html',
  styleUrls: ['./../dynamic.component.scss'],
})
export class DynamicMainGridHeaderComponent extends DynamicMainGridComponent {}
