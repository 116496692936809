<app-screen-content>
<mat-dialog-content>

<app-dynamic-form #form [formid]='formid' [formIntiValue]='formValue' 
(formChange)='onChangeForm($event)'
(delete)="onDelete()" 
[saveButtonInit]='saveButtonInit' [paraArrInput]='paraArr'   >
 
</app-dynamic-form>

<app-dynamic-main-grid [addInit]='addInit'  [gridid]='innerformid' [params]='params' ></app-dynamic-main-grid>
<app-dynamic-main-grid *ngIf='innerformid1!=null' [addInit]='addInit1'  [gridid]='innerformid1' [params]='params' ></app-dynamic-main-grid>
</mat-dialog-content>
<div mat-dialog-actions>


<div class="col form-button-bar pull-right" style="float: right;padding: 20px 0px;border-top: 1px solid rgb(209, 209, 209)">
    <ng-container *ngIf='actionButton1!=null && action1Check()'>
    <button mat-flat-button color="primary" (onOK)="onAction1()" app-popconfirm [abcTitle]='action1msg' >{{actionButton1}}</button>&nbsp;&nbsp;
  </ng-container>
  <ng-container *ngIf='actionButton2!=null'>
      <button mat-flat-button color="primary" (click)="onAction2()">{{actionButton2}}</button>&nbsp;&nbsp;
    </ng-container>
 
    <button *ngIf='deleteButton=="true"' mat-flat-button color="primary" app-popconfirm abcTitle="Are you sure you want to delete this?" (onOK)="saved=true; form.triggerDelete()" >Delete</button>
  &nbsp;     &nbsp;
    <button mat-flat-button color="primary" app-popconfirm abcTitle="Are you sure you want to save?" (onOK)="saved=true;form.triggerSave()">Save</button>
    &nbsp;     &nbsp;
    <ng-container>
      <button mat-flat-button color="primary" (click)="onCancel()">Cancel</button>&nbsp;&nbsp;
    </ng-container>
    <ng-container *ngIf='printButton!=null'>
      <!-- <button  class="mat-flat-button mat-primary" (click)="onPrint()" >Print</button>&nbsp;&nbsp; -->
    </ng-container>
</div>

</div>
</app-screen-content>
<app-print-content>
<!--   <app-dynamic-form [formid]='printformid' [formIntiValue]='formValue' [isPrint]='true'
 [paraArrInput]='paraArr'>
</app-dynamic-form> -->
<!-- <app-dynamic-printfields>
</app-dynamic-printfields> -->
</app-print-content>