<h2 class="col-12 member-function-header">File Execution Result</h2>
<nz-tabset  [nzTabPosition]="nzTabPosition" [(nzSelectedIndex)]="selectedIndex" (nzSelectChange)="log([$event])">
  <nz-tab  *ngFor="let tab of tabs" [nzTitle]="tab.tabName" (nzSelect)="log(['select', tab])"
    (nzClick)="log(['click', tab])" (nzDeselect)="log(['deselect', tab])">

    <app-filetabs [processId]="processId" [processTypeId]="processTypeId" [type]="tab.type" [sql]="tab.sql"
      [tabName]="tab.tabName" [jsonType]="tab.jsonType" [scheduleRunnerId]="scheduleRunnerId">
    </app-filetabs>

  </nz-tab>
</nz-tabset>
<button mat-flat-button color="primary" (click)="onClose()">Close</button>