<div class="d-flex flex-row py-4">
  <img mat-card-sm-image src={{entities.icon}}>
  <div class="px-4">
    <div style="font-weight: 550; font-size: 28px;">{{entities.title}}</div>
    <div class="px-4" style="font-size: 20px; font-weight: 490; color: rgb(117, 117, 117)">{{entities.vendors}}</div>
  </div>
</div>
<div class="d-flex flex-row py-2 px-3">
  <h4>Benefit Entity</h4>
</div>
<div class="d-flex flex-row">
  <div class="col-6">
    <table class="table">
      <tbody>
        <tr>
          <td>Benefit / Vendor Name</td>
          <td class="value">Critical illness / The Hartford</td>
        </tr>
        <tr>
          <td>Payroll Remit Priority</td>
          <td class="value">2</td>
        </tr>
        <tr>
          <td>Benefit Status at Time of Launch</td>
          <td class="value">Active</td>
        </tr>
        <tr>
          <td>Benefit Effective Date at Time Launch</td>
          <td class="value">1/1/2018</td>
        </tr>
        <tr>
          <td>Enrollment Restrictions (Days)</td>
          <td class="value">None</td>
        </tr>
        
      </tbody>
    </table>
  </div>

  <div class="col-6">
    <table class="table">
      <tbody>
        <tr>
          <td>Are Dependent Covered?</td>
          <td class="value">Yes</td>
        </tr>
        <tr>
          <td>Evergreen Benefit</td>
          <td class="value">No</td>
        </tr>
        <tr>
          <td>Enrollment by ABC</td>
          <td class="value">Yes</td>
        </tr>
        <tr>
          <td>Billing by AB</td>
          <td class="value">Yes</td>
        </tr>
        <tr>
          <td>Premium Calc Dependent</td>
          <td class="value">Yes</td>
        </tr>
        
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex flex-row py-2 px-3">
  <h4>Benefit Entity Details Settings - $10,000 Coverage</h4>
</div>
<div class="d-flex flex-row">
  <div class="col-6">
    <table class="table">
      <tbody>
        <tr>
          <td>Benefit Entity Detail Name (i.e. Standard or Family)</td>
          <td class="value">Employee Only - Non Tobaco User</td>
        </tr>
        <tr>
          <td>Cost/Coverage Amount</td>
          <td class="value">See Chart to Right</td>
        </tr>
        <tr>
          <td>Dependents are Allowed</td>
          <td class="value">No</td>
        </tr>
        <tr>
          <td>Minimum Age</td>
          <td class="value">18</td>
        </tr>
        <tr>
          <td>Maximum Age</td>
          <td class="value">70 to Enroll, otherwise 80</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-6">
    <table class="table">
      <tbody>
        <tr>
          <td>Benefit Entity Detail Name (i.e. Standard or Family)</td>
          <td class="value">Employee Only - Non Tobaco User</td>
        </tr>
        <tr>
          <td>Cost/Coverage Amount</td>
          <td class="value">See Chart to Right</td>
        </tr>
        <tr>
          <td>Dependents are Allowed</td>
          <td class="value">No</td>
        </tr>
        <tr>
          <td>Minimum Age</td>
          <td class="value">18</td>
        </tr>
        <tr>
          <td>Maximum Age</td>
          <td class="value">70 to Enroll, otherwise 80</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>