<br />
<span class="title">Coverages:</span>
<button class="add-btn" (click)=onAdd()>Add</button><br />
<nz-table *ngIf='benefitEntityPolicyCoverageDTO!=null && benefitEntityPolicyCoverageDTO.length>0' #basicTable [nzData]="benefitEntityPolicyCoverageDTO" nzSize="small" [nzShowPagination]="true">
  <thead>
    <tr>
      <th nzWidth="30px" nzShowExpand *ngIf="expandable"></th>
      <ng-container *ngFor="let access of gridConfigs">
        <th>
          {{access?.columnLabel}}
        </th>
      </ng-container>
      <th><b>Action</b></th>
    </tr>
  </thead>
  <tbody>
    <ng-template ngFor let-data [ngForOf]="basicTable.data">
      <tr>
        <td *ngIf="expandable && data.enrollmentCoverageObjectType==null"></td>
        <td nzShowExpand *ngIf="expandable && data.enrollmentCoverageObjectType!=null"
          [(nzExpand)]="mapOfExpandData[data.benefitEntityPolicyDetailCoverageId]"
          (nzExpandChange)="expand(data,$event)"></td>
        <ng-container *ngFor="let access of gridConfigs">
          <ng-container [ngSwitch]="access.columnType">
            <td class="ng-binding" *ngSwitchCase="'text'">{{data[access.columnName]}}</td>
            <td class="ng-binding" *ngSwitchCase="'date'">{{data[access.columnName]| date:'M/d/yy'}}</td>
            <td class="ng-binding" *ngSwitchCase="'datetime'">{{data[access.columnName]| date:'M/d/yy, h:mm a'}}</td>
            <td class="ng-binding" *ngSwitchCase="'gender'">{{data[access.columnName] | gender}}</td>
          </ng-container>
        </ng-container>
        <td>
          <!-- <a class="PatchFont" (click)=onDelete(data)>Delete</a> -->
          <button class="edit-btn" (click)=onEdit(data)>Edit</button>
        </td>
      </tr>
      <tr [nzExpand]="mapOfExpandData[data.benefitEntityPolicyDetailCoverageId] && expandable "
        *ngIf='mapOfExpandData[data.benefitEntityPolicyDetailCoverageId] && data.enrollmentCoverageObjectType!=null'>
        <td></td>
        <td [attr.colspan]="gridConfigs.length">
          <!--   <json-editor [options]="editorOptions" [data]="getJson(data.enrollmentCoverageObject)"  style="width:100%;height: 100%;" ></json-editor> -->
          <app-simplerangecoverage [jsonStr]='data.enrollmentCoverageObject'>
          </app-simplerangecoverage>
        </td>
      </tr>
    </ng-template>
  </tbody>
</nz-table>