import { NgModule } from '@angular/core';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { MockInMemoryService } from '@mock/services/mock-in-memory.service';
import { MockHelperService } from '@mock/services/mock-helper.service';
// import { TestModule } from '@mock/test/test.module';
// import { MockRoutingModule } from '@mock/mock-routing.module';

@NgModule({
  imports: [
    // MockRoutingModule,
    // TestModule,
    HttpClientInMemoryWebApiModule.forRoot(
      MockInMemoryService,
      getInMemoryDbConfig()
    )
  ],
  declarations: [],
  providers: [
    {
      provide: MockHelperService,
      useClass: MockHelperService
    }
  ]
})
export class MockModule {}

export function getInMemoryDbConfig() {
  return {
    passThruUnknownUrl: true,
    delay: 0
  };
}
