import { Component,Input,  OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { ClientService } from '@services/client/client.service';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { ActivatedRoute } from '@angular/router';
import { HistoryComponent} from '@shared/components/dynamicplan/history.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@services/auth/auth.service';
import { NotificationService } from '@shared/services/notification.service';

@Component({
    selector: 'app-benefitsummarylink',
    templateUrl: './benefitsummarylink.component.html',
})

export class BenefitSummaryLink extends BaseComponent implements OnInit {
  @Input()
  item;
  @Input()
  participantBenefitId;
  @Input()
  hideReissue
  EntityId: string;
  MemberId: string;
  EntityClientId;
  currentEnrollment;
  unenrolledList: string[] = [];
  loading;reissured = false;
  IDNTFlag = false;
  userName;
  cartItems;
  cancelPendigFlag: boolean = false
  allowedUser: boolean = true

  constructor( 
    protected clientService: ClientService,
    public dialog: MatDialog,
    public notification: NotificationService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private routeHelper: RouteHelperService) {
      super();
      this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
      this.EntityId = this.routeHelper.getEntityId(this.route);
      this.MemberId = this.routeHelper.getMemberId(this.route);
      this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
  }

  ngOnInit() {
    this.isCancelPendig()
    this.userName = this.authService.getUsername();
    // this.isUserAllowed()
    this.loadreissuer();
    if(this.item.cancelledEnrollment!=null){
      if(this.item.cancelledEnrollment.idnDflag==null){
        this.IDNTFlag=false;
      } else {
        this.IDNTFlag=this.item.cancelledEnrollment.idnDflag;
      }
    }

    if(this.item.currentEnrollment!=null){
      if(this.item.currentEnrollment.idnDflag==null){
        this.IDNTFlag=false;
      } else {
        this.IDNTFlag=this.item.currentEnrollment.idnDflag;
      }
    }
  }

  loadreissuer(){
    const myurl1 = 'genericapi/Query?typeName=ParticipantBenefitCertficate&entityId=' + this.EntityId + '&participantId=' + this.MemberId + '&benefitEntityPolicyId=' + this.item.benefitEntityPolicyId ;
    const observ11$ = this.clientService.getWithUrl(myurl1, {}).pipe(untilComponentDestroyed(this));
    observ11$.subscribe((data1) => {
      if (data1.details!=null && data1.details.length>0) {
        this.reissured=true;
      }
    });
  }

  reissue(item){
    const url = 'genericapi/AddWithEntityId?typeName=ParticipantBenefitCertficate&entityId='+this.EntityId;
    const repobj = {};
    repobj['BenefitEntityPolicyId'] = item.benefitEntityPolicyId;
    repobj['ParticipantId'] = this.MemberId;
    repobj['Action'] = 'P';
    repobj['SentDate'] = new Date();
    repobj['ActionDate'] = new Date();
    repobj['ParticipantBenefitId'] = this.participantBenefitId;
    this.loading = true; 
    const observ$ = this.clientService.postWithUrl({url, dto: repobj}).pipe(untilComponentDestroyed(this));
    observ$.subscribe((data) => {
      this.loading = false;
      this.loadreissuer();
    });        
  }

  Idnd(item) {
    const url = 'Enrollment/ParticipantBenefitAdditionalProfile';
    const repobj = {};
    repobj['BenefitEntityPolicyId'] = item.benefitEntityPolicyId;
    repobj['entityId'] = this.EntityId;
    repobj['entityClientId'] = this.EntityClientId;
    repobj['participantId'] = this.MemberId;
    repobj['idndflag'] = !this.IDNTFlag;
    const observ$ = this.clientService.postWithUrl({url, dto: repobj}).pipe(untilComponentDestroyed(this));
    this.loading = true; 
    observ$.subscribe((data) => {
      this.loading = false;
      this.IDNTFlag=data.idnDflag;
    }, err => {
      this.loading = false;
    });
  }

  isCancelPendig() {
    if (this.item.currentEnrollment != null) {
      this.cancelPendigFlag = this.item.currentEnrollment.isPendingEnrollment
    }
    if (this.item.cancelledEnrollment != null) {
      this.cancelPendigFlag = this.item.cancelledEnrollment.isPendingEnrollment
    }
  }

  // isUserAllowed() {
  //   if (this.userName === 'kgudigar' || 'AAstremskyi' || 'isingh' || 'vqwang') {
  //     this.allowedUser = true
  //   }
  // }

  onCancel(item) {
    const url = 'Enrollment/CancelEnrollment';
    const body = {};
      body['entityId'] = this.EntityId;
      body['entityClientId'] = this.EntityClientId;
      body['participantId'] = this.MemberId;
      body['cartItems'] = [
        {
          benefitEntityPolicyId: item.benefitEntityPolicyId
        }
      ]
    const observ$ = this.clientService.postWithUrl({url, dto: body}).pipe(untilComponentDestroyed(this));
    this.loading = true; 
    observ$.subscribe( (data) => {
      this.loading = false;
      if (data.cartItems[0].messages[0].message != null) {
        this.notification.show( data.cartItems[0].messages[0].message, 'Success');
      } else {
        this.notification.show('Pending Transaction had been canceled !', 'Success');
      }
    }, err => {
      this.loading = false;
    });
  }
  
  history(item) {
    const  dialogRef = this.dialog.open(HistoryComponent, {
      width: '90%',
      data: {
        entityId: this.routeHelper.getEntityId(this.route),
        entityClientId: this.routeHelper.getEntityClientId(this.route),
        clientId: this.routeHelper.getClientId(this.route),
        participantId: this.routeHelper.getMemberId(this.route),
        benefitEntityPolicyId: item.benefitEntityPolicyId,
        benefitEntityId: item.benefitEntityId,
        // item: item,
        formid: 'activity'
      }
    });
  }
} 
