import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { LoadLookupAction } from '@store/lookup/lookup.actions';

@Component({
  selector: 'app-main',
  templateUrl: './enterprise-dashboard-main.component.html',
  styleUrls: ['./enterprise-dashboard-main.component.scss', './styles/enterprise-dashboard-nav.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnterpriseDashboardMainComponent implements OnInit {
  public isNavbarCollapsed = true;
  popoverTitle = 'Popover title';
  popoverMessage = 'Popover description';
  confirmClicked = false;
  cancelClicked = false;
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    //   this.loadLookups();
  }

  loadLookups() {
    //   this.store.dispatch(new LoadLookupAction());
  }
}
