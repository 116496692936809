import { Component, OnInit, Inject, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { Observable } from 'rxjs';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';

@Component({
  templateUrl: './history.component.html',
})

export class HistoryComponent extends BaseComponent implements OnInit {
  EntityId; 
  MemberId; 
  ClientId; 
  entityClientId; 
  benefitEntityId; 
  benefitEntityPolicyId;
  HistoryData;
  qualCodeText: string = 'Qualification Code: '

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any, 
    protected clientService: ClientService, 
    public dialog: MatDialog,
  ) {
    super();
    if (data) {
      this.entityClientId = data.entityClientId
      this.EntityId = data.entityId;
      this.MemberId = data.participantId;
      this.ClientId = data.clientId;
      this.benefitEntityId = data.benefitEntityId;
      this.benefitEntityPolicyId = data.benefitEntityPolicyId;
    }
  }

  ngOnInit() {
    this.dynamicEntityValue()
    const url = `Enrollment/EnrollmentHistory?entityId=${this.EntityId}&entityclientId=${this.entityClientId}&participantId=${this.MemberId}&benefitEntityId=${this.benefitEntityId}&benefitEntityPolicyId=${this.benefitEntityPolicyId}`
    const observ$ = this.clientService.getWithUrl(url, {}).pipe(untilComponentDestroyed(this));
    observ$.subscribe(data => {
      this.HistoryData = data;
    });
  }

  dynamicEntityValue() {
    if (this.EntityId === 'd6a2ff7e-bba0-48fa-9396-a6b202631669') {
      this.qualCodeText = 'Volume: '
    }
  }
}
