import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { map } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { MatDialog } from '@angular/material/dialog';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { SpinnerService } from '@shared/services/spinner.service';

@Component({
  selector: 'app-suspense-section',
  templateUrl: './section.component.html',
  styleUrls: ['./suspense-management.component.scss'],
})

export class SectionComponent extends BaseComponent implements OnInit, OnChanges {

  @Input()
  entityId;
  @Input()
  entityClientId;
  @Input()
  clientId;
  @Output() refresh = new EventEmitter<any>();
  @ViewChild(NzTableComponent)
  memberComponent: NzTableComponent<any>;
  mapOfExpandData: { [key: string]: boolean } = {};
  suspenseComments;
  member$; 
  dates; 
  selectedDate;
  expandable = true;
  totalBillAmount: number = 0
  loading = true;
  spiner: boolean = false;

  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');
  constructor(
    public dialog: MatDialog,
    protected spinner: SpinnerService,  
    protected clientService: ClientService, 
    private notification: NzNotificationService) {
    super();
  }

  onMoveUp(data) {
    const url = 'Payment/ReshuffleParticipantSuspense';
    const dto = {
      'amount': data.amount,
      'participantId': data.participantGuid,
      'entityId': this.entityId,
      'clientId': this.clientId,
      'entityClientId': this.entityClientId,
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe((res) => {
      this.member$ = this.getMember(this.selectedDate);
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(res);
      // this.getSuspenseComments() ;
    }, err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  moveSuspense(data) {
    const url = 'Payment/MoveSuspenseCommentBalance';
    const dto = {
      'suspenseAccountId': data.suspenseAccountId,
      'entityId': this.entityId,
      'clientId': this.clientId,
      'entityClientId': this.entityClientId,
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    observ$.subscribe((res) => {
      this.member$ = this.getMember(this.selectedDate);
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(res);
      // this.getSuspenseComments() ;
    }, err => {
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  onRefresh() {
    this.member$ = this.getMember(this.selectedDate);
    this.refresh.emit();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    const url = 'Payment/PayDateDetails?entityId=' + this.entityId + '&clientId=' + this.clientId + '&entityClientId=' + this.entityClientId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.dates = [];
      if (data != null && data.billDetails != null) {
        data.billDetails.forEach(element => {
          this.dates.push(element.payDate.toLocaleDateString());
        });
      }
    });
  }

  getSuspenseComments() {
    // tslint:disable-next-line:max-line-length
    const url = 'Payment/SuspenseAccountCommentBalance?suspenseType=section&entityId=' + this.entityId + '&clientId=' + this.clientId + '&entityClientId=' + this.entityClientId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      
      this.suspenseComments = data.suspenseAmountBalanceDTO;
    });
  }

  addSuspenseComment() {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: false,
        item: { 'entityId': this.entityId, 'ClientId': this.clientId, 'entityClientId': this.entityClientId, 'suspenseType': 'Section' },
        formid: 'suspensecomment',
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSuspenseComments();
    });
  }

  expand(data, event) {}

  onChange($event) {
    localStorage.setItem('SectiondueDate', this.selectedDate);
    this.member$ = this.getMember(this.selectedDate);
  }

  onApply(data) {
    this.spinner.show();
    const url = 'Payment/SplitParticipantSuspenseBalance';
    const dto = {
      'suspenseAccountType': 'ENTITY',
      'suspenseAccountId': '',
      'entityId': this.entityId,
      'clientId': this.clientId,
      'entityClientId': this.entityClientId,
      'participantId': data.participantGuid,
      'amount': data.amount,
      'autoPost': data.autoPost,
      // 'userName' : data.SectionName,
      'payDate': this.selectedDate
    };
    
    const observ$ = this.clientService.postWithUrl({ url, dto });
    this.spinner.hide();
    observ$.subscribe( res => {
      
      this.member$ = this.getMember(this.selectedDate);
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(res);
    }, err => {
      this.spinner.hide();
        this.notification.blank(err.error[0], ' ');
      }
    );
  }

  getMember(date) {
    const url = 'Payment/ParticipantOutstandingBillSummary?entityId=' + this.entityId + '&clientId=' + this.clientId + '&entityClientId=' + this.entityClientId + '&payDate=' + date;
    const observ$ = this.clientService.getWithUrl(url, {}).pipe(
      // map(x => ({...x, amount: x.outstandingBillAmount - x.suspenseAmount}))
      
      map(item => {
        item.participantList = item.participantList.map(x => ({ ...x, Id: x.participantGuid, autoPost: true, amount: (x.outstandingBillAmount - x.suspenseAmount) > 0 ? x.outstandingBillAmount - x.suspenseAmount : '' }));
        return item;
      })
    );
      observ$.subscribe(data => {
        this.totalBillAmount = data.participantList.reduce((acc, member) => {
          acc += member.amount
          return acc
        }, 0)
     }); 
    return observ$;
  }

  onApplyAll() {
    this.spiner = true
    const url = 'Payment/AutopostParticipant';
    const participantDetails = this.memberComponent.data.map(x => ({ 'participantId': x.participantGuid, 'amount': x.amount, 'autoPost': x.autoPost }));
    const dto = {
      'clientId': this.clientId,
      'entityClientId': this.entityClientId,
      'entityId': this.entityId,
      'payDate': this.selectedDate,
      'participantDetails': participantDetails
    };
    const observ$ = this.clientService.postWithUrl({ url, dto });
    
    observ$.subscribe((data) => {
      this.spiner = false
      this.notification.blank('Saved  successfully', ' ');
      this.refresh.emit(data);
    }, err => {
        this.notification.blank(err.error[0], ' ');
        this.spiner = false
      }
    );
  }

  ngOnInit() {
    if (localStorage.getItem('SectiondueDate') != null) {
      this.selectedDate = localStorage.getItem('SectiondueDate');
      this.member$ = this.getMember(this.selectedDate);
    }
    this.getSuspenseComments();
  }
}



