
import { Component, OnInit } from '@angular/core';
import { ClientService } from '@services/client/client.service';
import { BaseComponent } from '@shared/components/base.component';
import { SpinnerService } from '@shared/services/spinner.service';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
@Component({
  templateUrl: './cobrareport.component.html',
  styleUrls: ['./cobrareport.component.scss']
})
export class CobraReportComponent extends BaseComponent
  implements OnInit {
  rows; groups: any;
  constructor(
    public spinner: SpinnerService, private clientService: ClientService) {
    super();
  }
  
  ngOnInit() {
    const url = 'ReportManagement/Report/Reports?groupid=CD725A77-ABD4-41B5-90FC-64E9CB3A541F';
    const observ$ = this.clientService.getWithUrl(url, {
    }).pipe(untilComponentDestroyed(this));
    observ$.subscribe((data) => {
      this.rows = data;
    });
  }
}
