<div class="app-container">

  <div class="row no-gutters mt-4">

    <div style="margin-right: 30px;" *ngIf='_entityIdLink==null'>
      <app-selected-client-header-share [entityId]="entityId" [noClient]='true' *ngIf='_entityIdLink==null'>
      </app-selected-client-header-share>
    </div>
    <div class="col-xs-12 col-md-5" *ngIf='_entityIdLink==null'>
      <input class="custom-control" placeholder="Search" [(ngModel)]="filterText">
      <span style="position: absolute;top:6px; left:15px; font-size: 18px; color: #acacac; z-index: 1000"><i
          class="fa fa-search" aria-hidden="true"></i></span>
    </div>
    <div class="col" *ngIf='addNew==true && _entityIdLink==null'>
      <div style="text-align: right;">
        <a mat-flat-button color="primary" style="color:#fff !important" (click)="addClient()"><i class="fa fa-plus"
            aria-hidden="true"></i>&nbsp;Add New</a>
      </div>
    </div>

    <div class="col-12 mt-15" style="border-top: 1px solid rgb(194, 194, 194)" *ngIf="clientListState">
      <h5 style="padding-left: 30px;" *ngIf='_entityIdLink!=null &&  clientListState.clients.length>0'>Sub Clients List
      </h5>
      <div class="row" style="padding: 0px 15px 0 0">
        <div class="row col-10" style="padding:15px"  *ngIf="!clientListState.loading">

          <!-- Client List Cards (Tiles) -->
          <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12 no-gutters client-icon"
            *ngFor="let client of clientListState.clients | filter:filterText; trackBy:trackClient;">

            <a *ngIf='_entityIdLink==null' [routerLink]="[client.entityClientId]">
              <div class="icon-format" style="
                  padding: 5px;
                  margin: 10px 3px;
                  height: 85px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  font-size: 14px;
                  justify-content: center;
                  box-shadow: 1px 2px 4px 1px #c0bfc0;">
                <div><span style="font-weight: 500;"> {{client.entityClientName}}</span></div>
                <div><span
                    *ngIf="client.clientNumber != null && client.clientNumber != ''">&nbsp;({{client.clientNumber}}</span>
                  <span *ngIf="client.entityClientNumber != null && client.entityClientNumber != ''">&nbsp;-
                    {{client.entityClientNumber}}</span>
                  <span *ngIf="client.clientNumber != null && client.clientNumber != ''">)</span>
                </div>
                <div class="shape"></div>
              </div>
            </a>

            <a *ngIf='_entityIdLink!=null' [routerLink]="getRouterLink(client)">
              <div class="icon-format" style="padding: 15px;margin: 15px;height: 60px;display: flex;
                  align-items: center;
                  justify-content: center;">
                <span> {{client.entityClientName}}</span>
                <span
                  *ngIf="client.clientNumber != null && client.clientNumber != ''">&nbsp;({{client.clientNumber}}</span>
                <span *ngIf="client.entityClientNumber != null && client.entityClientNumber != ''">&nbsp;-
                  {{client.entityClientNumber}}</span>
                <span *ngIf="client.clientNumber != null && client.clientNumber != ''">)</span>
              </div>
            </a>

          </div>
        </div>
        <div class="row col-2" *ngIf='_entityIdLink==null'>
          <div style="margin: 0 0 0 15px ; padding: 15px 0 10px 15px; border-left:1px solid rgb(200, 224, 224)"
            *ngIf="!clientListState.loading">
            <!-- <h3>Quick links</h3> -->
            <a [routerLink]="['/main/clients/member-lookup']" class="member-quick-link"><i class="fa fa-search"
                aria-hidden="true"></i>&nbsp;&nbsp;Member Lookup</a><br>
            <a [routerLink]="path" class="member-quick-link"><i class="fa fa-files-o"
                aria-hidden="true"></i>&nbsp;&nbsp;Reports</a><br>
            <a [routerLink]="path1" class="member-quick-link"
              *ngIf='entityId=="4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d"'>Commission Schedule</a><br>
            <a [routerLink]="path2" class="member-quick-link"
              *ngIf='entityId=="4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d"'>Agent Management</a><br>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="clientListState.loading">
        <div class="col-12">
          <p>Loading...</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bottom-space"></div>