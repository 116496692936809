<div>
	<img class="bg-image" src="https://cdn.abcsys.com/Library/Eniac/network-architect.jpg">
	<div class="logo">AEBSys</div>
	<div class="form-container">
		<form class="login-form" [formGroup]="resetform" (submit)="onSubmit()">
			<mat-card>
				<mat-card-title>
					<h4>&nbsp;&nbsp;&nbsp;
						Your password is expired<br>
						Please enter a new password</h4>
				</mat-card-title>
				<div>
					<span style="color: rgb(73, 73, 73) !important; font-weight: 550;">Password must have at least:<br> </span>	
					<span [ngClass]="{'validation-label': true, 'valid-parameter' : isPasswordLength}">16 characters length</span> <br>
					<span [ngClass]="{'validation-label': true, 'valid-parameter' : isPasswordUppercase}">1 lowercase and uppercase characters</span><br>
					<span [ngClass]="{'validation-label': true, 'valid-parameter' : isPasswordDidgit}">1 digit</span><br>
					<span [ngClass]="{'validation-label': true, 'valid-parameter' : isPasswordSpecial}">1 special character<br> @ # $ + * ! = </span> 
				  </div>
				
				<mat-card-content>
					<p>
						<mat-form-field>
							<input matInput autocomplete="off" type="password" placeholder="Old Password" formControlName="prevPassword" required />
							<mat-error *ngIf="resetform.get('prevPassword').touched && resetform.get('prevPassword').invalid">
								Your previous password is required
							</mat-error>
						</mat-form-field>
					</p>
					<p>
						<mat-form-field>
							<input matInput autocomplete="off" type="password" placeholder="New Password" formControlName="newPassword" (keyup)="onPasswordValidation($event)" required />
							<mat-error *ngIf="resetform.get('newPassword').touched && resetform.get('newPassword').invalid">
								Enter new valid password
							</mat-error>
						</mat-form-field>
					</p>
					<p>
						<mat-form-field>
							<input matInput autocomplete="off" type="password" placeholder="Repeat New Password" formControlName="repeatPassword" required/>
						</mat-form-field>
					</p>
					<div class="error-label" *ngIf="resetform.get('repeatPassword').touched && resetform.get('repeatPassword').invalid && !resetform.hasError('mismatch')">
						Repeat new password
					</div>
					<div class="error-label" *ngIf="resetform.hasError('mismatch')">
						Passwords must match
					</div>
				</mat-card-content>
				<mat-card-actions>
					<button mat-flat-button type="submit" color="primary">Reset Password</button>
					<div class="login__error">
						<p></p>
					</div>
				</mat-card-actions>
			</mat-card>
		</form>
	</div>
</div>