import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { DynamicMainFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
@Component({
    selector: 'app-stepper',
    templateUrl: './dynamic_steppers.component.html',
    styleUrls: ['./dynamic_steppers.component.scss'],
  })
  export class StepperComponent implements OnInit {
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild(DynamicMainFormComponent)
    memberComponent: DynamicMainFormComponent;
    initForm;param;param1;
    constructor(public dialog: MatDialog,
        protected router: Router, private authService: AuthService) {

    }
    ngOnInit() {
     let auth= this.authService.getAuth();
      this.initForm={
        participantId:auth.ParticipantId
      }
      this.param={
        participantId:auth.ParticipantId
      }
      this.param1={
        ParticipantId:auth.ParticipantId
      }
    }
    onNextStepper(e){
    if(this.stepper.selectedIndex==0){
        if(this.memberComponent.validate()==true){
          this.memberComponent.triggerSave();
          this.stepper.next();        }
    }else{
      this.stepper.next();
    }
  }
  onCancel() {
    ///main/user-dashboard
    this.router.navigate(['/main/user-dashboard']);
  }
  saveFirm() {

  }
  onPreviousStepper(e) {
    this.stepper.previous();
  }

  openDialog() {
    this.dialog.open(DialogElementsFinishPopup);
  }
}

@Component({
  selector: 'finish-popup',
  templateUrl: 'finish-popup.html',
})
export class DialogElementsFinishPopup { }   