import { LocalStorageService } from '@shared/storage/local-storage.service';
import { CacheItem } from '@shared/cache/cache-item';
import * as _ from 'lodash';

export function LocalStorageCacheFactory(localStorage: LocalStorageService): LocalStorageCacheService<CacheItem> {
  return new LocalStorageCacheService<CacheItem>('LOCAL-STORAGE-CACHE', localStorage);
}

const STORAGE_KEY_PREFIX = 'ABC';

export class LocalStorageCacheService<T extends CacheItem> {
  constructor(private cacheId: string, private localStorage: LocalStorageService) {
    this.cacheId = STORAGE_KEY_PREFIX + '-' + cacheId;
  }

  get(key: string): T | undefined {
    const value = this.getInternal(key);

    if (!value) {
      return undefined;
    }

    // update lastRead property
    value.lastRead = Date.now();

    return value;
  }

  set(key: string, value: T) {
    this.validateKey(key);
    this.setInternal(key, value);
  }

  delete(key: string) {
    this.localStorage.removeItem(key);
    // local storage does not return a boolean on remove.
    // so we always return true to indicate that the item was removed
    return true;
  }

  has(key: string): boolean {
    // local storage does not support a has() method
    return false;
  }

  clear() {
    // we do nothing because we do not want to clear the entire local storage
  }

  forEach(callbackfn: (value: T, key: any) => void, thisArg?: any): void {
    // we loop in reverse as removing items will change indices of tail
    // just in case items are removed by the caller of this method
    for (let i = this.localStorage.length - 1; i >= 0; --i) {
      const lsKey = localStorage.key(i);
      if (lsKey && _.startsWith(lsKey, this.cacheId)) {
        const lsValue = localStorage.getItem(lsKey);
        callbackfn.call(thisArg, lsKey, lsValue);
      }
    }
  }

  private validateKey(key: any) {
    if (!key) {
      throw new Error('Cache "key" is required');
    }
  }

  private getInternal(key: string): T | undefined {
    key = this.prefixKey(key);
    const value = this.localStorage.getItem(key);
    if (!value) {
      return undefined;
    }
    return JSON.parse(value) as T;
  }

  private setInternal(key: string, value: T) {
    key = this.prefixKey(key);
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  private prefixKey(key: string): string {
    return this.cacheId + '-' + key;
  }
}
