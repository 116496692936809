import { ActionReducerMap } from '@ngrx/store';
import { authReducer } from '@store/auth/auth.reducer';
import { lookupReducer } from '@store/lookup/lookup.reducer';
import { AuthState } from '@store/auth/auth.state';
import { LookupState } from '@store/lookup/lookup.state';
import { selectionReducer } from '@store/selection/selection.reducer';
import { SelectionState } from '@store/selection/selection.state';
import { MemberState } from '@store/main/member/member.state';
import { memberReducer } from '@store/main/member/member.reducer';
import { MainState } from '@store/main/main.state';
import { mainReducer } from '@store/main/main.reducer';
export type ErrorType = any | any[];

export class AppState {
  error?: ErrorType;
  auth: AuthState;
  lookup: LookupState;
  selection?: SelectionState;
  member: MemberState;
  main: MainState;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  auth: authReducer,
  lookup: lookupReducer,
  selection: selectionReducer,
  member: memberReducer,
  main: mainReducer
};
