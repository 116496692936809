import { Component, OnInit, Input, Inject } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';
@Component({
  templateUrl: './benefit-management.component.html',
  styleUrls: ['./benefit-management.component.scss']
})

export class BenefitManagementComponent extends BaseComponent implements OnInit {

  searchFormValue;
  searchForm = 'benefitsearch';
  mapOfExpandData: { [key: string]: boolean } = {};
  listOfData: any[] = [];
  EntityId: string;
  EntityClientId;
  bordered = false;
  loading = false;
  sizeChanger = false;
  pagination = true;
  header = true; title = true;
  footer = true;
  fixHeader = false;
  size = 'small';
  expandable = true;
  simple = false;
  benefitEntityId: string


  constructor(
    public dialog: MatDialog,
    protected spinner: SpinnerService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected routeHelper: RouteHelperService,
    protected clientService: ClientService) {
    super();
  }

  ngOnInit() {
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
    const url = `CaseManagement/BenefitVendorBenefitEntities?entityId=${this.EntityId}&entityclientId=${this.EntityClientId}`
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.listOfData = data;
    });
  }

  onAdd() {
    let formid;
    if (this.EntityId == 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028') {
      formid = 'anthembenefitentity';
    } else if (this.EntityId == '9808f856-f047-4843-bec8-277e414fc649') {
      formid = 'texasmbenefitentity';
    } else {
      formid = 'benefitentity';
    }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: true,
        item: {
          entityClientId: this.EntityClientId,
          entityId: this.EntityId,
          benefitEntityId: this.benefitEntityId
        },
        formid,
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitVendorBenefitEntities?entityId=${this.EntityId}&entityclientId=${this.EntityClientId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe(data1 => {
          this.listOfData = data1;
        },
        );
      }
    });
  }

  onCancel() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  onEdit(data) {
    let formid;
    if (this.EntityId == 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028') {
      formid = 'anthembenefitentity';
    } else if (this.EntityId == '9808f856-f047-4843-bec8-277e414fc649') {
      formid = 'texasmbenefitentity';
    } else {
      formid = 'benefitentity';
    }
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: false,
        item: data,
        formid,
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || typeof result != undefined) {
        const url = `CaseManagement/BenefitVendorBenefitEntities?entityId=${this.EntityId}&entityclientId=${this.EntityClientId}`
        const observ$ = this.clientService.getWithUrl(url, {});
        observ$.subscribe(data1 => {
          this.listOfData = data1;
        },
        );
      }
    });
  }

  expand(data, event) {
    if (event === true) {
      this.mapOfExpandData = {};
      this.mapOfExpandData[data.benefitEntityId] = true;
    }
  }

  reload() {
    const url = 'Payment/SuspenseAccount-Lookup';
    if (this.searchFormValue == null) {
      this.searchFormValue = {
        EntityId: '4E4D0CD3-830A-4D3D-A41F-E9CF0740A75D'
      };
    }

    this.spinner.show();
    const observ$ = this.clientService.postWithUrl({ url, dto: this.searchFormValue });
    observ$.subscribe((data) => {
      this.spinner.hide();
      const oriList = [...this.listOfData];
      if (this.listOfData.length === 0) {
        this.listOfData = [];
      } else {
        this.listOfData = [];
        // this.listOfData = [...this.listOfData];
      }
      this.listOfData.sort(function (a: any, b: any) { return (a.Type > b.Type) ? 1 : -1; });
    });
  }

  protected onSearch() {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: false,
        item: this.searchFormValue,
        formid: this.searchForm,
        prePath: '',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.searchFormValue = result;
        this.listOfData = [];
        this.reload();
      }
    });
  }
}
