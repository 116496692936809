<div class="m-2 container overflow-hidden">
  <div>
    <div class="row d-flex align-items-center justify-content-between no-gutters">
      <div class="" *ngIf="logoPath && logoPath !=''; else elseBlock"><img
          style="width: 150px; height: 50px; object-fit:contain;" src="{{logoImagePath}}"
          alt="Client logo for {{clientName}}">
      </div>
      <ng-template #elseBlock>
        <h2>{{clientName}}</h2>
      </ng-template>
      <div>
        <input type="text"  [(ngModel)]="filterKey" class="form-control  my-2 mr-2" placeholder="Start typing for search" aria-label="Search"
          aria-describedby="basic-addon2">
      </div>
      <div>
        <button nz-button nzType="default" (click)="onView('grid')"><i class="fa fa-building"></i></button>
        <button nz-button nzType="default" (click)="onView('card')"><i class="fa-tasks" ></i></button> 
        
      </div>
    
    </div>
    <div class="col-12" style="background: rgba(72, 196, 203) !important;height: 2px; "></div>
    <mat-tab-group animationDuration="0ms" class="mb-4">
      <mat-tab label="General">
        <app-general [clientData]="clientData" [filterKeyChange]="filterKeyChange" [view]="view"></app-general>
      </mat-tab>
      <mat-tab label="Benefit">
        <app-benefit [benefitData]="benefitData" [filterKeyChange]="filterKeyChange" [view]="view"></app-benefit>
      </mat-tab>

      <mat-tab label="Ui-Config">
        <app-ui-config  [website]="website" [clientName]="clientName"></app-ui-config>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>