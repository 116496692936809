import {
  AllClientActions,
  ClientActionTypes
} from '@store/main/client-management/client/client/client.actions';
import { ClientState } from '@store/main/client-management/client/client/client.state';
// import { ClientBenefitListActionTypes } from '@store/main/client-management/client/horace/client-benefit-list/client-benefit-list.actions';
// import { ClientBenefitActionTypes } from '@store/main/client-management/client/horace/client-benefit/client-benefit.actions';
// tslint:disable-next-line:max-line-length
// import { ClientBenefitConfigActionTypes } from '@store/main/client-management/client/horace/client-benefit-config/client-benefit-config.actions';
// import { ClientContactActionTypes } from '@store/main/client-management/client/horace/client-contact/client-contact.actions';
// import { ClientConfigActionTypes } from '@store/main/client-management/client/horace/client-config/client-config.actions';
import { extractError } from '@shared/api/url-utils';

export const initialState = new ClientState();

const resetErrorProps = {
  loadError: null,
  saveError: null,
  deleteError: null
};

export function clientReducer(
  state: ClientState = initialState,
  action: AllClientActions
): ClientState {
  switch (action.type) {
    case ClientActionTypes.GET_CLIENT: {
      return { ...state, ...resetErrorProps, loading: true };
    }
    case ClientActionTypes.GET_CLIENT_FAIL: {
      return {
        ...state,
        loading: false,
        loadError: extractError(action.payload)
      };
    }
    case ClientActionTypes.GET_CLIENT_SUCCESS: {
      return { ...state, loading: false, client: action.payload.client };
    }
    ///
 /*    case ClientBenefitListActionTypes.GET_CLIENT_BENEFIT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        client: {
          ...state.client,
          benefits: action.payload.clientBenefits
        }
      };
    } */
    ///
    case ClientActionTypes.SAVE_CLIENT_SUCCESS: {
      return { ...state, saving: false };
    }
    case ClientActionTypes.SAVE_CLIENT_FAIL: {
      return {
        ...state,
        saving: false,
        saveError: extractError(action.payload)
      };
    }
    ///
    case ClientActionTypes.DELETE_CLIENT: {
      return { ...state, deleting: true };
    }
    case ClientActionTypes.DELETE_CLIENT_SUCCESS: {
      return { ...state, deleting: false };
    }
    case ClientActionTypes.DELETE_CLIENT_FAIL: {
      return {
        ...state,
        deleting: false,
        deleteError: extractError(action.payload)
      };
    }
    ///
    default:
      return state;
  }
}
