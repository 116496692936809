<div [formGroup]='planForm'>
        <ng-container *ngIf="benefitEntityPolicy">
                <div  *ngIf="getEffdatedisp()=='Effective Date' && EntityId != 'd6a2ff7e-bba0-48fa-9396-a6b202631669'" style="width: 20%;margin-left: 80%;margin-top: -40px; ">
                    Effective Date
                    <!-- <app-date-input  [formControlName]="'effactiveDate'">
                </app-date-input> -->
                    <div class="date_wrap">
                      <input  [matDatepicker]="picker" [formControlName]="'effactiveDate'" class="input date_wrap__input ">
                      <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      </div>
                </div>  
                <div *ngIf="getEffdatedisp()=='Drop Date' && EntityId != 'd6a2ff7e-bba0-48fa-9396-a6b202631669'" style="width: 20%;margin-left: 80%;margin-top: -40px; ">
                  Drop Date
                  <!-- <app-date-input  [formControlName]="'dropEffectiveDate'"></app-date-input> -->
                  <div class="date_wrap">
                    <input  [matDatepicker]="picker" [formControlName]="'dropEffectiveDate'" class="input date_wrap__input ">
                     <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
                     <mat-datepicker #picker></mat-datepicker>
                    </div>
                
                </div>
              
                <div  style="width: 20%;margin-left: 80%;  ">
                  <div *ngIf='benefitEntityPolicy?.originalConfigurationSettings!=null'>
                    <!-- Override Original Effective Date:<br /> 
                    <label nz-checkbox formControlName="selectOriffDate" (ngModelChange)="Check($event)"></label>
                    -->
                    Override Original Effective Date:
                    <div  class="date_wrap">
                      <input  [matDatepicker]="picker" [formControlName]="'oriEffDate'" class="input date_wrap__input ">
                       <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
                       <mat-datepicker #picker></mat-datepicker>
                      </div>
                </div>
              </div>  

               
            <app-member-coverage-runtime 
            *ngIf="benefitEntityPolicy.coverages!=null && benefitEntityPolicy.coverages[0]?.enrollmentCoverageObjectType==='EnrollmentRuntimeCoverageObject' "
                 (formReady)="formInitialized('coverages', $event)"
                 [coverages]='benefitEntityPolicy.coverages[0]' 
                 (changeCov)='onChangeCov($event)'
                 [coverageValue]='coverage' 
                 >

                 </app-member-coverage-runtime> 
                 <ng-container *ngIf='EntityId=="4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d"' >
                  Qualification Code:
                  <app-typeahead [mask]='"000"' [noclass]="'yes'" [init]='qualificationCode' style="padding: 0px;" (changed)="modelChanged($event)"></app-typeahead>
                 </ng-container>   
               <app-member-tier-eis-coverage
               [dropReason]='dropReason'
               [rate]='rates'  
               [tiers]='benefitEntityPolicy.tiers'
               [benefitEntityPolicy] = 'benefitEntityPolicy'
               [eisDetails]='eisDetails'
               [currentEnrollment] = 'currentEnrollment'
               (formReady)="formInitialized('tiercoverage', $event)"
               (change1)='onChangeEis($event)'
               [entityId]='EntityId' [clientId] = 'ClientId' [memberId]='MemberId' [entityClientId]='EntityClientId'
               *ngIf="benefitEntityPolicy.enrollmentUITierDisplay==='HideTier'">
               </app-member-tier-eis-coverage>
              
        </ng-container>
        <!-- <pre>
            {{planForm.value | json}}
          </pre> -->
</div>  