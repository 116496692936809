import { Injectable, Injector } from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { NotificationOverlayComponent } from '@shared/components/notificationoverlay/notificationoverlay.component';
import { PopconfirmRef } from '@shared/components/confirmoverlay/popconfirm-ref';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(public overlay: Overlay, private injector: Injector) { }
  show(message, messageType = '') {
    const width = '550px'
    const height = '100px'
    let config = this.getOverlayConfig({ width, height });
    config.hasBackdrop = true;
    let overlayRef = this.overlay.create(config);
    overlayRef.backdropClick().subscribe(() => {
      overlayRef.dispose();
    });
    const popoverRef = new PopconfirmRef(message, messageType);
    const myinjector = this.createInjector(popoverRef, this.injector);
    const popconfirmPortal = new ComponentPortal(NotificationOverlayComponent, null, myinjector);

    overlayRef.attach(popconfirmPortal);
    popoverRef.afterClosed$.subscribe(res => {
      overlayRef.dispose();
    })
  }
  createInjector(popconfirmRef: PopconfirmRef, injector: Injector) {
    const tokens = new WeakMap([[PopconfirmRef, popconfirmRef]]);
    return new PortalInjector(injector, tokens);
  }
  private getOverlayConfig({ width, height }): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global().right().top()
    // .centerHorizontally()
    // .centerVertically();
    return new OverlayConfig({
      hasBackdrop: true,
      width,
      height,
      backdropClass: 'popover-backdrop',
      positionStrategy: positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
  }
}