<mat-dialog-content>

  <app-dynamic-form #form [formid]='formid' [formIntiValue]='formValue' (delete)="onDelete()"
    [saveButtonInit]='saveButtonInit'>
  </app-dynamic-form>
  <div *ngIf="header!=null">
    Header: <button nz-button (click)="addHeaderRow()" nzType="primary">Add</button>
    <div *ngIf="addHeader===true">
      <table>
        <tr>
          <td>Field Name</td>
          <td> Start Position</td>
          <td> End Position</td>
        </tr>
        <tr>
          <td><input type="text" nz-input [(ngModel)]="newHeader.Name" /></td>
          <td><input type="text" nz-input [(ngModel)]="newHeader.StartPosition" /> </td>
          <td><input type="text" nz-input [(ngModel)]="newHeader.EndPosition" /> </td>
        </tr>
      </table>

      <a (click)="saveHeaderNew()">Save</a>
      <a app-popconfirm abcTitle="Are you sure you want to cancel this?" (onOK)="cancelHeaderNew()">Cancel</a>
    </div>
    <nz-table #headerTable [nzData]="header" nzSize="small" nzBordered class="table table-hover"
      style="margin-top: 10px">
      <thead>
        <tr>
          <th>Id.</th>
          <th>Field Name</th>
          <th>Start Position</th>
          <th>End Position</th>
          <th>Length</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of headerTable.data">
          <td>{{data.index}}</td>
          <td>
            <ng-container *ngIf="!editHeaderCache[data.index].edit; else nameInputTpl">
              {{ data.Name }}
            </ng-container>
            <ng-template #nameInputTpl>
              <input type="text" nz-input [(ngModel)]="editHeaderCache[data.index].data.Name" />
            </ng-template>
          </td>
          <td>
            <ng-container *ngIf="!editHeaderCache[data.index].edit; else StartPositionInputTpl">
              {{ data.StartPosition }}
            </ng-container>
            <ng-template #StartPositionInputTpl>
              <input type="text" nz-input [(ngModel)]="editHeaderCache[data.index].data.StartPosition" />
            </ng-template>

          </td>
          <td>
            <ng-container *ngIf="!editHeaderCache[data.index].edit; else EndPositionInputTpl">
              {{ data.EndPosition }}
            </ng-container>
            <ng-template #EndPositionInputTpl>
              <input type="text" nz-input [(ngModel)]="editHeaderCache[data.index].data.EndPosition" />
            </ng-template>

          </td>
          <td>{{data.EndPosition - data.StartPosition+1}}</td>
          <td>
            <div class="editable-row-operations">
              <ng-container *ngIf="!editHeaderCache[data.index].edit; else saveTpl">
                <a (click)="startHeaderEdit(data.index)">Edit</a>
                <a app-popconfirm abcTitle="Are you sure you want to delete this?" (onOK)="deleteHeaderRow(data.index)">Delete</a>
              </ng-container>
              <ng-template #saveTpl>
                <a (click)="saveHeaderEdit(data.index)">Save</a>
                <a app-popconfirm abcTitle="Are you sure you want to cancel this?" (onOK)="cancelHeaderEdit(data.index)">Cancel</a>
              </ng-template>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div *ngIf="detail!=null">
    Detail:<button nz-button (click)="addDetailRow()" nzType="primary">Add</button>
    <div *ngIf="addDetail===true">
      <table>
        <tr>
          <td>Field Name</td>
          <td> Start Position</td>
          <td> End Position</td>
        </tr>
        <tr>
          <td><input type="text" nz-input [(ngModel)]="newDetail.Name" /></td>
          <td><input type="text" nz-input [(ngModel)]="newDetail.StartPosition" /> </td>
          <td><input type="text" nz-input [(ngModel)]="newDetail.EndPosition" /> </td>
        </tr>
      </table>

      <a (click)="saveDetailNew()">Save</a>
      <a app-popconfirm abcTitle="Are you sure you want to cancel this?" (onOK)="cancelDetailNew()">Cancel</a>
    </div>
    <nz-table #basicTable [nzData]="detail" nzSize="small" nzBordered class="table table-hover"
      style="margin-top: 10px">
      <thead>
        <tr>
          <th>No.</th>
          <th>Field Name</th>
          <th>Start Position</th>
          <th>End Position</th>
          <th>Length</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>{{data.index}}</td>
          <td>
            <ng-container *ngIf="!editCache[data.index].edit; else nameInputTpl">
              {{ data.Name }}
            </ng-container>
            <ng-template #nameInputTpl>
              <input type="text" nz-input [(ngModel)]="editCache[data.index].data.Name" />
            </ng-template>
          </td>
          <td>
            <ng-container *ngIf="!editCache[data.index].edit; else StartPositionInputTpl">
              {{ data.StartPosition }}
            </ng-container>
            <ng-template #StartPositionInputTpl>
              <input type="text" nz-input [(ngModel)]="editCache[data.index].data.StartPosition" />
            </ng-template>

          </td>
          <td>
            <ng-container *ngIf="!editCache[data.index].edit; else EndPositionInputTpl">
              {{ data.EndPosition }}
            </ng-container>
            <ng-template #EndPositionInputTpl>
              <input type="text" nz-input [(ngModel)]="editCache[data.index].data.EndPosition" />
            </ng-template>

          </td>
          <td>{{data.EndPosition - data.StartPosition+1}}</td>
          <td>
            <div class="editable-row-operations">
              <ng-container *ngIf="!editCache[data.index].edit; else saveTpl">
                <a (click)="startEdit(data.index)">Edit</a>
                <a app-popconfirm abcTitle="Are you sure you want to delete this?" (onOK)="deleteRow(data.index)">Delete</a>
              </ng-container>
              <ng-template #saveTpl>
                <a (click)="saveEdit(data.index)">Save</a>
                <a app-popconfirm abcTitle="Are you sure you want to cancel this?" (onOK)="cancelEdit(data.index)">Cancel</a>
              </ng-template>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div *ngIf="trailer!=null">
    trailer:<button nz-button (click)="addTrailerRow()" nzType="primary">Add</button>
    <div *ngIf="addTrailer===true">
      <table>
        <tr>
          <td>Field Name</td>
          <td> Start Position</td>
          <td> End Position</td>
        </tr>
        <tr>
          <td><input type="text" nz-input [(ngModel)]="newTrailer.Name" /></td>
          <td><input type="text" nz-input [(ngModel)]="newTrailer.StartPosition" /> </td>
          <td><input type="text" nz-input [(ngModel)]="newTrailer.EndPosition" /> </td>
        </tr>
      </table>

      <a (click)="saveTrailerNew()">Save</a>
      <a app-popconfirm abcTitle="Are you sure you want to cancel this?" (onOK)="cancelTrailerNew()">Cancel</a>
    </div>
    <nz-table #trailerTable [nzData]="trailer" nzSize="small" nzBordered class="table table-hover"
      style="margin-top: 10px">
      <thead>
        <tr>
          <th>No.</th>
          <th>Field Name</th>
          <th>Start Position</th>
          <th>End Position</th>
          <th>Length</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of trailerTable.data">
          <td>{{data.index}}</td>
          <td>
            <ng-container *ngIf="!editTrailerCache[data.index].edit; else nameInputTpl">
              {{ data.Name }}
            </ng-container>
            <ng-template #nameInputTpl>
              <input type="text" nz-input [(ngModel)]="editTrailerCache[data.index].data.Name" />
            </ng-template>
          </td>
          <td>
            <ng-container *ngIf="!editTrailerCache[data.index].edit; else StartPositionInputTpl">
              {{ data.StartPosition }}
            </ng-container>
            <ng-template #StartPositionInputTpl>
              <input type="text" nz-input [(ngModel)]="editTrailerCache[data.index].data.StartPosition" />
            </ng-template>

          </td>
          <td>
            <ng-container *ngIf="!editTrailerCache[data.index].edit; else EndPositionInputTpl">
              {{ data.EndPosition }}
            </ng-container>
            <ng-template #EndPositionInputTpl>
              <input type="text" nz-input [(ngModel)]="editTrailerCache[data.index].data.EndPosition" />
            </ng-template>

          </td>
          <td>{{data.EndPosition - data.StartPosition+1}}</td>
          <td>
            <div class="editable-row-operations">
              <ng-container *ngIf="!editTrailerCache[data.index].edit; else saveTpl">
                <a (click)="startTrailerEdit(data.index)">Edit</a>
                <a app-popconfirm abcTitle="Are you sure you want to delete this?" (onOK)="deleteTrailerRow(data.index)">Delete</a>
              </ng-container>
              <ng-template #saveTpl>
                <a (click)="saveTrailerEdit(data.index)">Save</a>
                <a app-popconfirm abcTitle="Are you sure you want to cancel this?" (onOK)="cancelTrailerEdit(data.index)">Cancel</a>
              </ng-template>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div mat-dialog-actions>

    <div class="col form-button-bar pull-right"
      style="float: right;padding: 20px 0px;border-top: 1px solid rgb(209, 209, 209)">
      <ng-container *ngIf='actionButton1!=null && action1Check()'>
        <button class="mat-flat-button mat-primary" (onOK)="onAction1()" app-popconfirm
          [abcTitle]='action1msg'>{{actionButton1}}</button>&nbsp;&nbsp;
      </ng-container>
      <ng-container *ngIf='actionButton2!=null'>
        <button class="mat-flat-button mat-primary" (click)="onAction2()"
          disabled>{{actionButton2}}</button>&nbsp;&nbsp;
      </ng-container>

      <button class="mat-flat-button mat-primary" app-popconfirm
        abcTitle="Are you sure you want to delete this?" (onOK)="form.triggerDelete()">Delete</button>
      &nbsp; &nbsp;
      <button class="mat-flat-button mat-primary" app-popconfirm
        abcTitle="Are you sure you want to save?" (onOK)="Save()">Save</button>
      &nbsp; &nbsp;
      <ng-container>
        <button class="mat-flat-button mat-primary"
          (click)="onCancel()">Cancel</button>&nbsp;&nbsp;
      </ng-container>
    </div>