import { createSelector } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { MainState } from '@store/main/main.state';


export const getMainState = (state: AppState) => state.main;
export const getDummyState = (state: MainState) => state;
export const isMainStateMutating = createSelector(
  getDummyState,
  (state: MainState) => state.loading || state.saving || state.deleting
);

export const isMemberStateValid = createSelector(
  getMainState,
  (state: MainState) =>
    !state.loadError && !state.saveError && !state.deleteError
);
