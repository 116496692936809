<div style="padding:17px 10px">
  <h3 *ngIf='userName.toLowerCase()=="tmaituser"' style="font-family: 'Roboto', sans-serif; font-size: 20px; font-weight: 200">
    <img src="https://cdn.abcsys.com/Library/Eniac/TMAITLogo.png" /> 
    <div> Welcome to TMA Insurance Trust</div>
  </h3>
  <div class="col-xs-12 col-md-4 mt-1">
    
    <input class="custom-control" placeholder="Search" [(ngModel)]="filterText">
    <span style="position: absolute;top:6px; left:28px; font-size: 18px; color: #acacac; z-index: 1000"><i class="fa fa-search"  aria-hidden="true"></i></span>
  </div>
</div>
<div class="col-12" style="background: rgba(72, 196, 203) !important;height: 1px; ">
</div>

<div class="col-12" style="padding:15px;">
  <div class="row col-12">

    <ng-container *ngFor="let item of entities | filter:filterText; trackBy:trackClient;">
      <div  *ngIf=' userName.toLowerCase()!=="legalclaim" '>
        <div>
          <a [routerLink]="['/main/clients', item.id]">
            <div class="custom-client-icon">
              <div style="font-size: 32px; font-family: Monoton; z-index: 2;" class="px-2">{{item.entityName.substring(0,1)}}</div>
                <div style="margin: 0 auto; color: rgb(78, 78, 78);">{{item.entityName}}</div>
              <div class="shape"></div>
            </div>
          </a>
        </div>
      </div>
    </ng-container>

    <div class="col-12" style="border-top: 1px solid #48c4cb; margin: 15px 0;"></div>
    <div  *ngIf='onlyone==true'>
      <div>
        <a [routerLink]="reportpath">
          <div class="custom-icon">
            <div style="font-size: 32px;"><i class="fa fa-envelope-o" aria-hidden="true"></i></div><div>Reports</div>
          </div>
        </a>
      </div>
    </div>
    <div  *ngIf='legal==true'>
      <div>
        <a [routerLink]="['/main/legal/legal']">
          <div class="custom-icon">
            <div style="font-size: 20px;"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></div><div>Legal Case Management</div>
          </div>
        </a>
      </div>
    </div>
    <div  *ngIf='legal==true'>
      <div>
        <a [routerLink]="['/main/attorney/attorney']">
          <div class="custom-icon">
            <div style="font-size: 20px;"><i class="fa fa-users" aria-hidden="true"></i></div><div>Attorney Management</div>
          </div>
        </a>
      </div>
    </div>
    <div 
      *ngIf='userName.toLowerCase()!=="legalclaim" && entities!=null && entities.length!=0'>
      <div>
        <a [routerLink]="['/main/clients/member-lookup']">
          <div class="custom-icon">
          <div style="font-size: 20px; padding: 0 0 0 10px;"><i class="fa fa-search" aria-hidden="true"></i></div><div>Member Lookup</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>