import { Component, OnInit } from '@angular/core';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { Store } from '@ngrx/store';
import {  GetMemberUrlAction } from '@store/main/member/member.actions';
import { Router, ActivatedRoute } from '@angular/router';
  import { AppState } from '@store/app.state';

@Component({
    selector: 'app-dynamic-member',
    templateUrl: './dynamicmembercontainner.component.html',
  })
  export class DynamicMemberContainnerComponent implements OnInit  {
    EntityId: string;
    ClientId: string;
    MemberId: string;
    constructor(
         public store: Store<AppState>, private route: ActivatedRoute,
         private routeHelper: RouteHelperService) {

        }
    ngOnInit() {
        this.EntityId = this.routeHelper.getEntityId(this.route);
        this.ClientId = '00000000-0000-0000-0000-000000000000'; //    this.ClientId = this.routeHelper.getClientId(this.route);
        this.MemberId = this.routeHelper.getMemberId(this.route);
        this.store.dispatch(new GetMemberUrlAction({ Url: 'Participant/:EntityId/:ClientId/:ParticipantId/Profile', Prop: 'profile',
            EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId }));

  }
}
