<div class="m-2 container overflow-hidden">

    <h2>American Airlines</h2>
    <div class="row d-flex align-center justify-content-between">
      <div class="mb-3 mx-3" style="font-size: 18px;">
        <span style="margin-right: 0.5rem; font-size: 18px;">Number of Benefits: <span style="color:darkcyan">6</span></span>
      <span style="margin: 0 0.5rem; font-size: 18px;">Number of vendors: <span style="color:darkcyan">10</span></span>
      Number of Eligible: <span style="color:darkcyan">10302</span>
      <span style="margin: 0 0.5rem; font-size: 18px;">Total B2B files: <span style="color:darkcyan">9021</span></span>
      <span style="margin: 0 0.5rem; font-size: 18px;">Number of Retirees: <span style="color:darkcyan">3949</span></span>
      </div>
      <div class="d-inline-flex">
        <!-- <div class="my-2">
          <button class="mat-flat-button mat-primary" style="width: 8rem" type="button"
          [routerLink]="['/main/compare']"> Compare </button>
        </div> -->
        <!-- <input type="text" class="form-control  my-2 mx-3" placeholder="Start typing for search" aria-label="Search"
          aria-describedby="basic-addon2"> -->
      </div>
    </div>
</div>

<div class="m-2 container overflow-hidden" style="padding: 1rem 2rem">

    <div class="align-items-center row d-flex">
      <button type="button" class="btn btn-dark mr-3 mb-2" (click)="stg()">Staging</button>
      <button type="button" class="btn btn-dark mr-3 mb-2" (click)="prod()">Production</button>
      <button type="button" class="btn btn-dark mr-3 mb-2" (click)="compare()">Compare</button>
      <input type="text" class="form-control w-50 mb-2 mr-3" [(ngModel)]="searchText" placeholder="Start typing for search" aria-label="Search" aria-describedby="basic-addon2">
    </div>
    <!-- <div class="align-items-center mb-2 row d-flex justify-content-between">
      <input type="text" class="form-control w-50  mr-3" [(ngModel)]="searchText" placeholder="Start typing for search" aria-label="Search" aria-describedby="basic-addon2">
      <div class="d-flex">
        <div class="mr-3 dropdown">
            <button class="btn btn-info btn-info dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Client
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
              <button class="dropdown-item" type="button">American Airlines</button>
              <button class="dropdown-item" type="button">Walgreens</button>
              <button class="dropdown-item" type="button">Pepsi</button>
              <button class="dropdown-item" type="button">Sothern Company</button>
              <button class="dropdown-item" type="button">Stantec</button>
            </div>
        </div>
        <div class="p-0 custom-control custom-checkbox">
            <button type="button" (click)="toggle()" class="btn btn-info">
              {{ showDiff !== true ? 'Diffrence' : 'Back To All' }}
            </button>
        </div>
      </div>
    </div> -->
      
    <div class="row gx-5">
      <table class="table table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th class="" scope="col">PARAMETERS</th>
            <th class="" *ngIf="!showStg" scope="col">
              STAGING
              <a color="primary" href="javascript:void(0)" (click)="download()">
                <i class="fa fa-file-excel-o float-right" style="color: green; font-size: 18px;" aria-hidden="true"></i></a>  
            </th>
            <th class="" *ngIf="!showPrd" scope="col">
              PRODUCATION     
              <a color="primary" href="javascript:void(0)" (click)="download()">
                <i class="fa fa-file-excel-o float-right" style="color: green; font-size: 18px;" aria-hidden="true"></i></a>          
            </th>

          </tr>
        </thead>
        <ng-container *ngFor="let data of PROD_DATA">
          <ng-container *ngFor="let item of data.value | tablefilter:searchText"> 
            <tbody>
              <tr>
                <td *ngIf="item.key.includes('Client Config') || item.key.includes('Benefits Config'); else regular"> 
                  <span class="badge badge-info"> {{ item.key }} </span>   
                </td>
                <ng-template #regular>
                  <td class="pl-4"> {{item.key}} </td>
                </ng-template>
                <ng-container *ngIf="!showPrd" [ngSwitch]="true">
                  <td *ngSwitchCase="item.key === 'Background Image'">
                    <img class="w-25" src="{{item.prdvalue}}">
                  </td>
                  <td [ngClass]="{'tdanger': item.prdvalue !== item.stgvalue}" *ngSwitchCase="item.key === 'Welcome Message'">
                    <textarea id="wmsg" name="wmsg" rows="4" cols="50"> {{item.prdvalue }}</textarea>
                  </td>
                  <td [ngClass]="{'tdanger': item.prdvalue !== item.stgvalue}" *ngSwitchDefault class="value">{{item.prdvalue}}</td>
                </ng-container>
                <ng-container *ngIf="!showStg" [ngSwitch]="true">
                  <td *ngSwitchCase="item.key === 'Background Image'">
                    <img class="value  w-25" src="{{item.stgvalue}}">
                  </td>
                  <td [ngClass]="{'tdanger': item.prdvalue !== item.stgvalue}" *ngSwitchCase="item.key === 'Welcome Message'">
                    <textarea id="wmsg" name="wmsg" rows="4" cols="50"> {{item.stgvalue }}</textarea>
                  </td>
                  <td [ngClass]="{'tdanger': item.prdvalue !== item.stgvalue}" *ngSwitchDefault class="value">{{item.stgvalue}}</td>
                </ng-container>
              </tr>
            </tbody>
          </ng-container>
        </ng-container>
      </table>
    </div> 
  
    <!-- <div class="row gx-5 table-responsive-xl">
      <table class="table table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th class="col-md-4" scope="col">PARAMETERS</th>
            <th class="col-md-4" *ngIf="!showPrd" scope="col">PRODUCATION</th>
            <th class="col-md-4" *ngIf="!showStg" scope="col">STAGING</th>
          </tr>
        </thead>
        <ng-container *ngFor="let data of PROD_DATA">
          <ng-container *ngFor="let item of data.value | filter: searchText"> 
            <tbody>
              <tr [ngClass]="{'table-danger': item.prdvalue !== item.stgvalue}">
                <td colspan="3" class="clickable" data-toggle="collapse" data-target="#group-of-rows-1" *ngIf="item.key.includes('Client Config') || item.key.includes('Benefits Config')"> 
                  <span class="badge badge-info"> {{ item.key }} </span>   
                </td>
              </tr>
            </tbody>
  
            <tbody id="group-of-rows-1" class="collapse show">
              <tr  [ngClass]="{'table-danger': item.prdvalue !== item.stgvalue}">
                <td class="pl-4" *ngIf="!item.key.includes('Client Config') || !item.key.includes('Benefits Config')"> {{item.key}} </td>
                <ng-container *ngIf="!showPrd" [ngSwitch]="true">
                  <td *ngSwitchCase="item.key === 'Background Image'">
                    <img class="w-25" src="{{item.prdvalue}}">
                  </td>
                  <td *ngSwitchCase="item.key === 'Welcome Message'">
                    <textarea id="wmsg" name="wmsg" rows="4" cols="50"> {{item.prdvalue }}</textarea>
                  </td>
                  <td *ngSwitchDefault class="value">{{item.prdvalue}}</td>
                </ng-container>
                <ng-container *ngIf="!showStg" [ngSwitch]="true">
                  <td *ngSwitchCase="item.key === 'Background Image'">
                    <img class="value  w-25" src="{{item.stgvalue}}">
                  </td>
                  <td *ngSwitchCase="item.key === 'Welcome Message'">
                    <textarea id="wmsg" name="wmsg" rows="4" cols="50"> {{item.stgvalue }}</textarea>
                  </td>
                  <td *ngSwitchDefault class="value">{{item.stgvalue}}</td>
                </ng-container>
              </tr>
            </tbody>
  
  
          </ng-container>
        </ng-container>
  
      </table>
    </div>  -->
  </div>