import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';


@Component({
  selector: 'app-member-plans-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./../dynamic.component.scss']
})
export class CartComponent extends BaseComponent implements OnInit {
  @Input()
  benefitEntities;
  @Input()
  benefitValue;
  @Input()
  policies;
  @Output()
  OnChange: EventEmitter<any> = new EventEmitter();
  @Output()
  OnConfirm: EventEmitter<any> = new EventEmitter();
  selected = 0;
  getSum() {
    let sum = 0;
    for (const property in this.benefitValue) {
      if (this.benefitValue.hasOwnProperty(property)) {
        const tiers = this.benefitValue[property].tiers;
        if (tiers) {
          const tier = tiers.tier;
          const rate = tiers.rate;
          if (tier && rate) {
            sum = sum + rate;
          }

        }



      }
    }
    return sum;
  }
  onEnrollment() {
    this.OnConfirm.emit();
  }
  ngOnInit() {

  }
  getPolicies(entityId) {
    const ret: string[] = [];
    for (const property in this.benefitValue) {
      if (this.benefitValue.hasOwnProperty(property)) {
        if (this.benefitValue[property].benefitEntityId === entityId) {
          ret.push(property);
        }
      }
    }
    return ret;
  }
  onSelect(i): void {
    this.selected = i;
    this.OnChange.emit(this.benefitEntities[i].benefitEntityId);
  }
}
