<mat-dialog-content>
  <div class="app-container">
    <div class="alert alert-success" role="alert" *ngIf="saveSuccess">
      {{successMessage}}
    </div>
    <div>
      <h6 class="member-function-header">{{formType}}</h6>
    </div>
    <div class="row">
      <div class="col-12">
        <app-dynamic-fields [formConfigs]='formConfigs' [searchobvUrl]='searchobvUrl' [formValue]="formValue"
          [deleteButton]='deleteButton' [saveButton]='isSaveButton()' [addNew]='addNew' [validators]='validators'
          [reAddButton]='reAddButton' [actionButton1]='actionButton1' [actionButton2]='actionButton2'
          (getFields)="onGetFields($event)" [paraArr]='paraArr' [saving]=saving [saveSuccess]=saveSuccess
          (action1)="onAction1()" (action2)="onAction2()" (cancle)="onCancel($event)" (delete)="onDelete($event)"
          (search)="onSearch($event)" (changeObvSub)="onchangeObvSub($event)"
          (save)="onSave($event)"></app-dynamic-fields>
      </div>
    </div>
  </div>
</mat-dialog-content>