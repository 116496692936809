<div style="padding: 30px">
  <div class="row">
    <div class="col-6" style="resize: both;overflow: auto;min-height: 800px;">
      <!--  <pre>
            {{json | json}}
        </pre> -->
      <json-editor [options]="editorOptions" [data]="json" (change)="getData($event)"
        style="resize: both;overflow: auto;width:100%;min-height: 800px;"></json-editor>
    </div>
    <div class="col-6" style="padding: 15px; border:1px solid #43b0b64d">
      <h2>{{currentFile}}</h2>
      <div *ngIf="type=='form'">
        <app-dynamic-fields [formConfigs]='formConfigs'></app-dynamic-fields>
      </div>
      <div *ngIf="type=='grid'">
        <app-dynamic-grid-preview [gridConfigs]='gridConfigs' [paging]='paging'></app-dynamic-grid-preview>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col form-button-bar">
      <button mat-flat-button color="primary" (click)="onAdd('form')">Add New
        Form</button>
      &nbsp;
      <button mat-flat-button color="primary" (click)="onAdd('grid')">Add New
        Grid</button>
      &nbsp;
      <button mat-flat-button color="primary" (click)="onOpen()">Open</button>
      &nbsp;
      <button mat-flat-button color="primary" (click)="onSave()">Save</button>
    </div>
  </div>
</div>