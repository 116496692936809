import * as _ from 'lodash';
import {
  startsWithHttpPrefix,
  getUrlAndExcessParams,
  joinPaths
} from '@shared/api/url-utils';
import { HttpRequestOptions } from '@shared/api/shared';

// Not decorated with @Injectable() on purpose. This service will be created by HttpFactory
export class ApiEndpointService {
  private baseUrl = '';
  private mockBaseUrl = '';
  private _configUseMock = false;

  constructor(config: {
    baseUrl: string;
    mockBaseUrl: string;
    useMock: boolean;
  }) {
    this.baseUrl = config.baseUrl;
    this.mockBaseUrl = config.mockBaseUrl;
    this._configUseMock = config.useMock;
  }

  get configUseMock(): boolean {
    return this._configUseMock;
  }

  buildUrl(
    url: string,
    params: any,
    options: HttpRequestOptions
  ): {
    url: string;
    excessParams?: any;
  } {
    // if the url starts with 'http' no need to prefix with 'baseUrl'
    if (startsWithHttpPrefix(url)) {
      return getUrlAndExcessParams(url, params);
    }

    // otherwise join with 'baseUrl'
    const baseUrl = this.isMockEnabled(options)
      ? this.mockBaseUrl
      : this.baseUrl;
    const absoluteUrl = joinPaths(baseUrl, url);
    return getUrlAndExcessParams(absoluteUrl, params);
  }

  isMockEnabled(options: HttpRequestOptions): boolean {
    // if 'isMock' is undefined in the request header, return the 'useMock' from the config
    if (!options || _.isUndefined(options.isMock)) {
      return this._configUseMock;
    }
    return options.isMock;
  }
}
