<br />
<br />
<div class="row col-20" style="margin: 0px">
  <div class="col-2">
    <ng-select (change)="selectedEntityChange($event)" [(ngModel)]="selectedEntity">
      <ng-container *ngFor="let item of entities">
        <ng-option value='{{item.id}}'>{{item.entityName}}</ng-option>
      </ng-container>
    </ng-select>
  </div>
  <div class="col-8" style="padding-left: 0px">
  </div>
</div>
<app-dynamic-main-grid pageSize=50 class="co-12" gridid='{{_jsonURL}}' [params]='dto'></app-dynamic-main-grid>