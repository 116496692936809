import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { LoggerService } from '@shared/logging/logger.service';
import { CLIENT_ROUTE_SEGMENT } from '@shared/route/constants';

export const ROUTE_PARAM_NAME = {
  ID: 'id',
  ENTITY_ID: 'entityId',
  CLIENT_ID: 'clientId',
  BENEFIT_ID: 'benefitId',
  BENEFIT_CLASS_ID: 'benefitClassId',
  MEMBER_ID: 'participantId',
  CREATE_NEW: 'create-new',
  ENTITY_CLIENT_ID: 'entityClientId'
};

export const ROUTE_PARAM = {
  ID: `:${ROUTE_PARAM_NAME.ID}`,
  ENTITY_ID: `:${ROUTE_PARAM_NAME.ENTITY_ID}`,
  CLIENT_ID: `:${ROUTE_PARAM_NAME.CLIENT_ID}`,
  BENEFIT_ID: `:${ROUTE_PARAM_NAME.BENEFIT_ID}`,
  BENEFIT_CLASS_ID: `:${ROUTE_PARAM_NAME.BENEFIT_CLASS_ID}`,
  MEMBER_ID: `:${ROUTE_PARAM_NAME.MEMBER_ID}`,
  ENTITY_CLIENT_ID: `:${ROUTE_PARAM_NAME.ENTITY_CLIENT_ID}`,
};

@Injectable({
  providedIn: 'root'
})
export class RouteHelperService {
  constructor(private logger: LoggerService) {
    this.logger = this.logger.create(this);
  }

  getEntityId(route: ActivatedRoute): string {
    return this.getId(route, ROUTE_PARAM_NAME.ENTITY_ID);
  }

  getClientId(route: ActivatedRoute): string {
    return this.getId(route, ROUTE_PARAM_NAME.CLIENT_ID);
  }
  getEntityClientId(route: ActivatedRoute): string {
    return this.getId(route, ROUTE_PARAM_NAME.ENTITY_CLIENT_ID);
  }
  getBenefitId(route: ActivatedRoute): string {
    return this.getId(route, ROUTE_PARAM_NAME.BENEFIT_ID);
  }

  getMemberId(route: ActivatedRoute): string {
    return this.getId(route, ROUTE_PARAM_NAME.MEMBER_ID);
  }

  getId(route: ActivatedRoute, routeParam: string): string {
    const routeSnapshot = route.snapshot;

    // 1. see if its available as a route parameter
    let id = routeSnapshot.paramMap.get(routeParam) || '';
    if (id) {
      // this.logger.debug(
      //   `${routeParam}: '${id}' resolved from route parameter.`
      // );
      return id;
    }

    // 2. see if its available on route data
    id = this.getIdFromRouteData(routeSnapshot, routeParam);
    if (id) {
      // this.logger.debug(`${routeParam}: '${id}' resolved from route data.`);
      return id;
    }

    // 3. something is not right.
    this.logger.warn(
      `Unable to resolve the value for '${routeParam}' from the route.`
    );
    return '';
  }

  getIdFromRouteData(
    route: ActivatedRouteSnapshot,
    routeParam: string
  ): string {
    if (!route.data) {
      return '';
    }

    const id = route.data[routeParam];
    if (id) {
      return id;
    }

    if (route.parent) {
      return this.getIdFromRouteData(route.parent, routeParam);
    }

    return '';
  }

  getMemberLookupRoute() {
    return `${this.getClientsRoute()}/${CLIENT_ROUTE_SEGMENT.memberLookup}`;
  }

  getMemberRoute(params?: {
    EntityId?: string;
    ClientId?: string;
    MemberId?: string;
    Route?: ActivatedRoute;
    Segment: string;
    EntityClientId: string;
  }): string {
    const {
      EntityId = '',
      ClientId = '',
      MemberId = '',
      Route = null,
      Segment = '',
      EntityClientId = ''
    } = params || {};

    if (Route) {
      return this.getMemberRouteFromRoute(Route, Segment);
    }

    return this.getMemberRouteFromIds({
      EntityId,
      ClientId,
      MemberId,
      Segment,
      EntityClientId
    });
  }

  getClientsRoute(params?: {
    EntityId?: string;
    ClientId?: string;
    Route?: ActivatedRoute;
  }) {
    const { EntityId = '', ClientId = '', Route = null } = params || {};

    if (Route) {
      return this.getClientsRouteFromActivatedRoute(Route);
    }

    return this.getClientsRouteFromIds({ EntityId, ClientId });
  }

  private getClientsRouteFromIds(params?: {
    EntityId: string;
    ClientId?: string;
  }) {
    let clientsRoute = '/main/clients';
    const { EntityId = '', ClientId = '' } = params || {};

    if (!EntityId) {
      return clientsRoute;
    }

    // append entityId
    clientsRoute = `${clientsRoute}/${EntityId}`;

    if (!ClientId) {
      return clientsRoute;
    }

    // append clientId
    return `${clientsRoute}`;
  }

  private getClientsRouteFromActivatedRoute(Route: ActivatedRoute) {
    return this.getClientsRouteFromIds({
      EntityId: this.getEntityId(Route),
      ClientId: this.getClientId(Route)
    });
  }

  private getMemberRouteFromIds(params: {
    EntityId: string;
    ClientId?: string;
    MemberId: string;
    Segment: string;
    EntityClientId: string;
  }): string {
    const { EntityId, ClientId, MemberId, Segment, EntityClientId } = params;

    const clientsRoute = this.getClientsRoute({
      EntityId,
      ClientId
    });

    return `${clientsRoute}/${EntityClientId}/${
      CLIENT_ROUTE_SEGMENT.member
    }/${MemberId}/${Segment}`;
  }

  private getMemberRouteFromRoute(
    Route: ActivatedRoute,
    Segment: string
  ): string {
    const EntityId = this.getEntityId(Route);
  //  const ClientId = this.getClientId(Route);
    const MemberId = this.getMemberId(Route);
    const EntityClientId = this.getEntityClientId(Route);
    return this.getMemberRouteFromIds({
      EntityId,

      MemberId,
      Segment,
      EntityClientId
    });
  }
}
