import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BaseComponent } from '@shared/components/base.component';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';

@Component({
  selector: 'app-ratesupload',
  templateUrl: './ratesupload.component.html',
})
export class RatesUploadComponent extends BaseComponent implements OnInit {
  loading;
  entities;
  rows;
  selectedEntity;
  entityClients;
  selectedEntityClient;

  constructor(
    private clientService: ClientService,
    private notification: NzNotificationService
  ) {
    super();
  }

  ngOnInit() {
    const url = 'Entity/Entities';
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.entities = data;
    });
  }

  fileChange(e) {
    let file: File = e.target.files[0];
    let myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      let data = (<any>e.target).result;
      let workbook = XLSX.read(data, {
        type: 'binary',
      });
      workbook.SheetNames.forEach((sheetname) => {
        let XL_row_object = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetname],
          {
            raw: false,
          }
        );
        if (XL_row_object.length > 500) {
          this.notification.error(
            'The file must contain less than 500 rates',
            ''
          );
          this.rows = [];
        } else {
          this.rows = XL_row_object;
        }
      });
    };
    myReader.onerror = function (ex) {};
    myReader.readAsBinaryString(file);
  }

  selectedEntityChange(entityId) {}

  actionPopup($event) {}

  // checkduplicate() {
  //   const newrows: any = [];
  //   this.rows.forEach(item => {
  //     const it = {
  //       ClientId: item.ClientId,
  //       SectionId: item.SectionId,
  //       SSN: item.SSN == null ? '' : item.SSN.trim(),
  //       Id: item.Id,
  //       BenefitCode: item.BenefitCode == null ? '' : item.BenefitCode.trim(),
  //       PlanCode: item.PlanCode,
  //       PlanSubCode: item.PlanSubCode == null ? '' : item.PlanSubCode.trim(),
  //       CovCode: item.CovCode,
  //       PackageCode: item.PackageCode,
  //       PayDate: item.PayDate,
  //       PayFromDate: item.PayFromDate,
  //       PayToDate: item.PayToDate,
  //     }
  //     newrows.push(JSON.stringify(it));
  //   });

  //   const tt = new Set(newrows);
  //   return new Set(newrows).size !== newrows.length;
  // }

  onSubmit() {
    if (this.selectedEntity == null) {
      this.notification.blank('must select an client', ' ');
      return;
    }
    const RateTableAAggregates: any = [];
    let error = false;
    this.rows.forEach((item) => {
      const obj = {
        ClientNumber: item.ClientNumber,
        EntityClientNumber: item.EntityClientNumber,
        BenefitEntityPolicyId: item.BenefitEntityPolicyId,
        PolicyCode: item.PolicyCode,
        PolicySubCode: item.PolicySubCode,
        PlanCode: item.PlanCode,
        CoverageTierCode: item.CoverageTierCode,
        LocationCode: item.LocationCode,
        FromDate: item.FromDate,
        PaymentPerYearMode: item.PaymentPerYearMode,
        Rate: item.Rate,
        RatePerUnits: item.RatePerUnits,
        FromAge: item.FromAge,
        ToAge: item.ToAge,
        Smoker: item.Smoker,
        ContributorCode: item.ContributorCode,
        ContributorInRate: item.ContributorInRate,
        AreaCode: item.AreaCode,
        MannualRate: item.MannualRate,
        BatchCode: item.BatchCode,
        PKGCode: item.PKGCode,
        Gender: item.Gender,
        ProductCode: item.ProductCode,
        IsFlatRate: parseInt(item.IsFlatRate),
        IsMedicalHealthPlan: parseInt(item.IsMedicalHealthPlan),
        IsAgeRequired: parseInt(item.IsAgeRequired),
        IsPaymentPerYearMode: parseInt(item.IsPaymentPerYearMode),
      };
      RateTableAAggregates.push(obj);
    });
    if (error) {
      return;
    }
    const url = 'CaseManagement/RateCreateTool';
    const dto = {
      EntityId: this.selectedEntity,
      RateTableAAggregates,
    };
    this.loading = true;
    const observ$ = this.clientService
      .postWithUrl({ url, dto })
      .pipe(untilComponentDestroyed(this));
    observ$.subscribe(
      (res) => {
        this.notification.blank('Saved Successfully', ' ');
        this.loading = false;
      },
      (err) => {
        this.notification.blank(err.error[0], ' ');
        this.loading = false;
      }
    );
  }
}
