
<div class=" app-form-group-wrapper">
<div *ngIf="type==='Json'">
   
<div *ngIf="jsonType!=='ErrorSummary'">
    
    <ng-container  *ngFor="let key of summaryKeys"> 
       
      <div *ngIf="key!=='$type'">
          {{key}} <div style="display: inline-block ;vertical-align: bottom;padding-bottom:3px;">
              <a 
                  color="primary"
                  href="javascript:void(0)" (click)="downloadJson(key)"><i class="fa fa-file-excel-o" style="color: green; font-size: 28px; " aria-hidden="true"></i></a>
              </div>
          <nz-table *ngIf="_array.isArray(summaryJson[key])===true" nzSize="small"  [nzData]="summaryJson[key]">
              <tr>
                  <ng-container  *ngFor="let keyobj of _object.keys(summaryJson[key][0])"> 
                      <td *ngIf="keyobj!=='$type'">
                          {{keyobj}}
                      </td>
                      
                    </ng-container> 
              </tr>
                <tr  *ngFor="let obj of summaryJson[key]"> 

                   
                   <ng-container  *ngFor="let keyobj of _object.keys(obj)"> 
                      <td *ngIf="keyobj!=='$type'">
                          {{obj[keyobj]}}
                      </td>
                      
                    </ng-container> 
                </tr>
            </nz-table>
            <div *ngIf="_array.isArray(summaryJson[key])===false" > 
                <div *ngIf="getTypeof(summaryJson[key] )!== 'object'">
                    {{summaryJson[key]}}
                </div>
            </div>
            <nz-table *ngIf="_array.isArray(summaryJson[key])===false && getTypeof(summaryJson[key] )=== 'object'"  nzSize="small"  >
              <tr>
                  <ng-container  *ngFor="let keyobj of _object.keys(summaryJson[key])"> 
                      <td *ngIf="keyobj!=='$type'">
                          {{keyobj}}
                      </td>
                    </ng-container> 
              </tr>
                <tr  > 
                    
                   <ng-container  *ngFor="let keyobj of _object.keys(summaryJson[key])"> 
                      <td *ngIf="keyobj!=='$type'">
                          {{summaryJson[key][keyobj]}}
                      </td>
                    </ng-container> 
                </tr>
            </nz-table>
      </div>
   
    </ng-container>
</div>
<div *ngIf="jsonType==='ErrorSummary'">
    <div style="display: inline-block ;vertical-align: bottom;padding-bottom:3px;">
        <a 
            color="primary"
            href="javascript:void(0)" (click)="downloadErrorSummary()"><i class="fa fa-file-excel-o" style="color: green; font-size: 28px; " aria-hidden="true"></i></a>
        </div>
 <table>
    <tr *ngFor="let key of errorKeys" style="height: 25px;" >
        <td>
            {{key}}
        </td>
        <td>
            {{errorJson[key]}}
        </td>
    </tr>
 </table>
   
   </div>
</div>

<div *ngIf="type==='Sql'"  >
   
        <div class="col-12" style="display: inline-block ; text-align: right; padding:10px ;">
            <a color="primary" href="javascript:void(0)" (click)="download()"><i class="fa fa-file-excel-o" style="color: green; font-size: 28px; " aria-hidden="true"></i></a>
    </div>
   
    <input class="form-control col-4"  type="text" nz-input placeholder='Search' [(ngModel)]="searchValue"  />
    <nz-table #basicTable [nzData]="rows | search:searchValue" nzPageSize="50"  nzSize="small" class="table table-hover" style="margin-top: 10px" >
        <thead  (nzSortChange)="sort($event)" nzSingleSort class="thead-dark"  >
          <tr >
            <ng-container *ngFor="let header of headers"> 
            <th nzShowSort [nzSortKey]="header" >{{header}}
            
            </th>
        </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td *ngFor="let header of headers">{{data[header]}}</td>
            
          </tr>
        </tbody>
    </nz-table>
</div>
</div>