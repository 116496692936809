<br/>
<span class="title">Tiers:</span>
<button class="add-btn" (click)=onAdd()>Add</button><br/>
<nz-table *ngIf='rows!=null && rows.length>0' #basicTable  [nzData]="rows" [nzShowPagination]="true">
  <thead>
    <tr>
      <th nzWidth="30px" nzShowExpand *ngIf="expandable"></th>
      <ng-container *ngFor="let access of gridConfigs">
        <th>
          {{access?.columnLabel}}
        </th>
      </ng-container>
      <th><b>Action</b></th>
    </tr>
  </thead>
  <tbody>
    <ng-template ngFor let-data [ngForOf]="basicTable.data">
      <tr>
        <td nzShowExpand *ngIf="expandable && data.benefitEntityPolicyDetailCoverages!=null"
          [(nzExpand)]="mapOfExpandData[data.benefitEntityPolicyDetailId]" (nzExpandChange)="expand(data,$event)"></td>
        <td *ngIf="expandable && data.benefitEntityPolicyDetailCoverages==null"></td>
        <ng-container *ngFor="let access of gridConfigs">
          <ng-container [ngSwitch]="access.columnType">
            <td class="ng-binding" *ngSwitchCase="'text'">{{data[access.columnName]}}</td>
            <td class="ng-binding" *ngSwitchCase="'date'">{{data[access.columnName]| date:'M/d/yy'}}</td>
            <td class="ng-binding" *ngSwitchCase="'datetime'">{{data[access.columnName]| date:'M/d/yy, h:mm a'}}</td>
            <td class="ng-binding" *ngSwitchCase="'gender'">{{data[access.columnName] | gender}}</td>
          </ng-container>
        </ng-container>
        <td>
          <!-- <a class="PatchFont" (click)=onDelete(data)>Delete</a> -->
          <button class="edit-btn" (click)=onEdit(data)>Edit</button>
        </td>
      </tr>
      <tr [nzExpand]="mapOfExpandData[data.benefitEntityPolicyDetailId] && expandable "
        *ngIf='mapOfExpandData[data.benefitEntityPolicyDetailId] && data.benefitEntityPolicyDetailCoverages!=null'>
        <td></td>
        <td [attr.colspan]="gridConfigs.length">
          <app-benefitcoverage [benefitEntityPolicyCoverageDTO]='data.benefitEntityPolicyDetailCoverages'>
          </app-benefitcoverage>
        </td>
      </tr>
    </ng-template>
  </tbody>
</nz-table>