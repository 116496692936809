<div *ngIf='tierName!="Employee Only"  && benefitEntityPolicy.showBeneficiary===true'>
  <mat-tab-group>
    <mat-tab label="Dependent(s)" style="border:none">
      <app-plan-dependent 
        [tierName]='tierName' 
        [entityId]='EntityId' 
        [clientId]='ClientId' 
        [memberId]='MemberId'
        [enrolled]='enrolled' 
        (changeDep)='onChangeDep()'
      ></app-plan-dependent>
    </mat-tab>
    <mat-tab label="Beneficiaries">
      <app-plan-beneficiary 
        *ngIf='benefitEntityPolicy.showBeneficiary===true' 
        [beneficiaries]='beneficiaries'
        [entityId]='EntityId' 
        [clientId]='ClientId' 
        [memberId]='MemberId'
      ></app-plan-beneficiary>
    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf='tierName!="Employee Only"  && benefitEntityPolicy.showBeneficiary === false'>
  <app-plan-dependent 
    [tierName]='tierName' 
    [entityId]='EntityId' 
    [clientId]='ClientId' 
    [memberId]='MemberId'
    [dependentRules]='dependentRules' 
    [enrolled]='enrolled' (changeDep)='onChangeDep()'
  ></app-plan-dependent>
</div>
<div *ngIf='tierName=="Employee Only" && benefitEntityPolicy.showBeneficiary === true'>
  <app-plan-beneficiary 
    [entityId]='EntityId' 
    [clientId]='ClientId' 
    [memberId]='MemberId'
    [beneficiaries]='beneficiaries' (changeBen)='onChangeBen()'
  ></app-plan-beneficiary>
</div>
