import { Component, Input, OnInit } from '@angular/core';

import { BaseComponent } from '@shared/components/base.component';
@Component({
    selector: 'app-waiverdisp',
    templateUrl: './waiverdisplay.component.html'
  })
  export class WaiverDisplayComponent extends BaseComponent implements OnInit {
    @Input()
    waiver;

    history = false;
    ngOnInit() {

    }
  }
