import { Injectable, Inject } from '@angular/core';
import { LoggerService } from '@shared/logging/logger.service';
import * as _ from 'lodash';
import { WINDOW } from '@shared/window.service';

// Based on AngularJs $log service
// https://github.com/angular/angular.js/blob/v1.7.3/src/ng/log.js

@Injectable()
export class ConsoleLoggerService extends LoggerService {
  // Support: IE 9-11, Edge 12-14+
  // IE/Edge display errors in such a way that it requires the user to click in 4 places
  // to see the stack trace. There is no way to feature-detect it so there's a chance
  // of the user agent sniffing to go wrong but since it's only about logging, this shouldn't
  // break apps. Other browsers display errors in a sensible way and some of them map stack
  // traces along source maps if available so it makes sense to let browsers display it
  // as they want.
  formatStackTrace;
  loggerName: string;

  logFn: any;
  traceFn: any;
  debugFn: any;
  infoFn: any;
  warnFn: any;
  errorFn: any;

  constructor(@Inject(WINDOW) private window: Window) {
    super();
    this.initialize();
  }

  create(loggerSource?: any): LoggerService {
    const logger = new ConsoleLoggerService(this.window);

    if (
      loggerSource &&
      loggerSource.__proto__ &&
      loggerSource.__proto__.constructor
    ) {
      logger.loggerName = loggerSource.__proto__.constructor.name;
    } else {
      logger.loggerName = loggerSource;
    }

    return logger;
  }

  trace(message: any, ...additional: any[]): void {
    this.traceFn(message, ...additional);
  }

  debug(message: any, ...additional: any[]): void {
    this.debugFn(message, ...additional);
  }

  info(message: any, ...additional: any[]): void {
    this.infoFn(message, ...additional);
  }

  log(message: any, ...additional: any[]): void {
    this.logFn(message, ...additional);
  }

  warn(message: any, ...additional: any[]): void {
    this.warnFn(message, ...additional);
  }

  error(message: any, ...additional: any[]): void {
    this.errorFn(message, ...additional);
  }

  private initialize() {
    if (!this.window) {
      throw Error('Browser window object is not available.');
    }

    this.formatStackTrace =
      this.window['msie'] ||
      /\bEdge\//.test(this.window.navigator && this.window.navigator.userAgent);

    this.logFn = this.consoleLog('log');
    this.traceFn = this.consoleLog('trace');
    this.debugFn = this.consoleLog('debug');
    this.infoFn = this.consoleLog('info');
    this.warnFn = this.consoleLog('warn');
    this.errorFn = this.consoleLog('error');
  }

  private formatError(arg) {
    if (!this.isError(arg)) {
      return arg;
    }

    if (arg.stack && this.formatStackTrace) {
      return arg.message && arg.stack.indexOf(arg.message) === -1
        ? 'Error: ' + arg.message + '\n' + arg.stack
        : arg.stack;
    }

    if (arg.sourceURL) {
      return arg.message + '\n' + arg.sourceURL + ':' + arg.line;
    }

    return arg;
  }

  private consoleLog(type: string): any {
    const self = this;
    //const console = this.window.console || {};
    const logFn = console[type] || console['log'] || _.noop;

    return function() {
      const args: any[] = [];
      if (!!this.loggerName) {
        args.push(`[${this.loggerName}]:`);
      }
      _.each(arguments, function(arg) {
        args.push(self.formatError(arg));
      });
      // Support: IE 9 only
      // console methods don't inherit from Function.prototype in IE 9 so we can't
      // call `logFn.apply(console, args)` directly.
      return Function.prototype.apply.call(logFn, console, args);
    };
  }

  /**
   * Determines if a reference is an `Error`.
   * Loosely based on https://www.npmjs.com/package/iserror
   */
  private isError(value: any): boolean {
    if (!value) {
      return false;
    }

    const tag = value.toString();
    switch (tag) {
      case '[object Error]':
        return true;
      case '[object Exception]':
        return true;
      case '[object DOMException]':
        return true;
      default:
        return value instanceof Error;
    }
  }
}
