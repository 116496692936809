import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnInit {
  @Input()
  pageIndex = 0;
  @Input()
  pageSize = 10;
  @Input()
  totalRowsCount = 0;
  @Input()
  pageCount = 0;
  @Output()
  pageIndexChange = new EventEmitter<{ pageIndex: number; pageSize: number }>();

  constructor() {}

  ngOnInit() {}

  get prevPageDisabled(): boolean {
    return this.pageIndex === 0;
  }

  get nextPageDisabled(): boolean {
    return this.pageIndex === this.pageCount - 1;
  }

  // get pageCount() {
  //   return Math.ceil(this.totalRowsCount / this.pageSize);
  // }

  pageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    this.loadPage(0);
  }

  getPageIndexRange() {
    const GROUP_SIZE = 5;
    const GROUP_INDEX = Math.floor(this.pageIndex / GROUP_SIZE);
    const startIndex = GROUP_INDEX * GROUP_SIZE;

    let endIndex = startIndex + 4;
    if (endIndex >= this.pageCount) {
      endIndex = this.pageCount - 1;
    }

    return _.range(startIndex, endIndex + 1);
  }

  loadPage(pageIndex: number) {
    if (pageIndex < 0 || pageIndex >= this.pageCount) {
      return;
    }

    this.pageIndex = pageIndex;
    this.pageIndexChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize
    });
  }
}
