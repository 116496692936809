<div style="display: flex;justify-content: space-between">

  <div>
    <ul class="pagination">
      <li class="page-item"
          [ngClass]="{disabled: prevPageDisabled}"><a class="page-link"
           href="javascript:void(0)"
           (click)="loadPage(pageIndex - 1)">« Prev</a></li>
      <li *ngFor="let index of getPageIndexRange()"
          class="page-item"
          [ngClass]="{active: index === pageIndex}"
          (click)="loadPage(index)"><a class="page-link"
           href="javascript:void(0)">{{index + 1}}</a></li>
      <li class="page-item"
          [ngClass]="{disabled: nextPageDisabled}"><a class="page-link"
           href="javascript:void(0)"
           (click)="loadPage(pageIndex + 1)">Next »</a></li>
    </ul>
  </div>

  <div>
    <span style="display: inline-block; padding-right: 0px !important;"><b>Items per Page:</b></span>&nbsp;
    <span>
      <select style="display: inline-block; padding: 0px; width: 80px"
              class="form-control"
              [(ngModel)]="pageSize"
              (ngModelChange)="pageSizeChange($event)">
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select></span>
    <span style="display: inline; padding-top: 6px; text-align: right;">
      <b>&nbsp;&nbsp;Total Items:</b>&nbsp;{{totalRowsCount | number}}</span>
  </div>

</div>