import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '@services/client/client.service';
import { BaseComponent } from '@shared/components/base.component';
import { Observable } from 'rxjs';
import { HttpService } from '@shared/api/http.service';
import { MatDialog } from '@angular/material/dialog';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';

@Component({
  selector: 'app-dynamic-search-action',
  templateUrl: './dynamic_search_action.component.html',
})

export class DynamicSearchActionComponent extends BaseComponent implements OnInit
{
  id;
  _jsonURL;
  formConfigs;
  saveButton;
  gridConfigs;
  rows = [];
  searchUrl;
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  mapOfCheckedId: { [key: string]: boolean } = {};
  actionButton;
  searchForm;
  searchFormValue;
  listOfDisplayData: any[] = [];
  rowid = 'id';
  listOfAllData: any[] = [];
  cancelButton;
  constructor(
    protected clientService: ClientService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private routeHelper: RouteHelperService,
    private httpser: HttpService,
    private spinner: SpinnerService
  ) {
    super();
  }
  public onSearch() {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: false,
        item: this.searchFormValue,
        formid: this.searchForm,
        prePath: '',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        this.rows = [];
        this.searchFormValue = result;
        const observ$ = this.clientService
          .postWithUrl({ url: this.searchUrl, dto: result })
          .pipe(untilComponentDestroyed(this));
        observ$.subscribe((data) => {
          this.rows = data;
        });
      }
    });
  }
  checkedChange(obj, id) {
    this.mapOfCheckedId[id] = obj;
    this.refreshStatus();
  }
  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(
      (item) => this.mapOfCheckedId[item[this.rowid]]
    );
    this.isIndeterminate =
      this.listOfDisplayData.some(
        (item) => this.mapOfCheckedId[item[this.rowid]]
      ) && !this.isAllDisplayDataChecked;
  }
  currentPageDataChange($event: any): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }
  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(
      (item) => (this.mapOfCheckedId[item[this.rowid]] = value)
    );
    this.refreshStatus();
  }
  protected reSearch() {
    this.rows = [];

    const observ1$ = this.clientService
      .postWithUrl({ url: this.searchUrl, dto: this.searchFormValue })
      .pipe(untilComponentDestroyed(this));
    observ1$.subscribe((data1) => {
      this.rows = data1;
    });
  }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['formid'];
      if (this.id) {
        this._jsonURL = this.id + 'formgrid';
        this.getJSON()
          .pipe(untilComponentDestroyed(this))
          .subscribe((data1) => {
            //     this.spinner.hide();
            const json = JSON.parse((<any>data1.jsonText) as string);
            if (data1 === null) {
              this.formConfigs = [];
            } else {
              // tslint:disable-next-line:no-shadowed-variable
              const json = JSON.parse((<any>data1.jsonText) as string);
              this.formConfigs = json.FormConfig;
              this.saveButton = json.saveButton;
              this.gridConfigs = json.GridConfig;
              this.searchUrl = json.searchUrl;
              this.cancelButton = json.cancelButton;
              this.actionButton = json.actionButton;
              this.rowid = json.rowid;
              this.searchForm = json.searchForm;
            }
          }
        )
      }
    })
  }
  public getJSON(): Observable<any> {
    return this.httpser.get('json?path=' + this._jsonURL, {
      urlParams: null,
      options: {
        isMock: false,
      },
    })
  }
}
