import { Component, OnInit, ChangeDetectionStrategy, Input,  Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { map, filter, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-member-coverage-runtime',
    templateUrl: './coverageruntime.component.html',
    styleUrls: ['./../dynamic.component.scss'],
     changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class CoverageRuntimeComponent extends BaseComponent implements OnInit {
    @Input()
    coverages;
    @Input()
    set coverageValue(coverageValue) {
      this._coverageValue = coverageValue;
      if (coverageValue) {
        this.coverage = coverageValue['coverage'];
        this.coverageVal = coverageValue['coverageValue'];
        this.packageCode = coverageValue['packageCode'];
        this.classCode = coverageValue['classCode'];
        this.CoverageNumber = coverageValue['CoverageNumber'];
        // this.changePackage(this.packageCode);
      }

    }
    @Input()
    set entityId(entityId) {
      if (entityId != null) {
        this.EntityId = entityId;
      }
    }
    _coverageValue;
    @Output() changeCov = new EventEmitter<any>();
    @Output() formReady = new EventEmitter<UntypedFormGroup>();
    @Output() changeCovInit = new EventEmitter<any>();
    coverageForm: UntypedFormGroup; init = true;
    coverageVal ; packageCode; dropdownbox; droplist: any = [];
    coverage; classCode; CoverageNumber; IncrementalValue;
    CoverageNumberPackages; IsCoverageAmountRequired;
    IsNoPackageCoverageNumberRequired; UIControlType; usedropdown = true;
    // public keyUp = new Subject<KeyboardEvent>();
    classes ; selectedClass; selectedPackage; MaximumumIssueValue; MinimumIssueValue;
    EntityId: string;
    formatterDollar = (value: number) => `$ ${value}`;
    parserDollar = (value: string) => value.replace('$ ', '');

    constructor(private fb: UntypedFormBuilder, private routeHelper: RouteHelperService, private route: ActivatedRoute) {
        super();
        this.EntityId = this.routeHelper.getEntityId(this.route);
      }
      Check(e) {
       // this.usedropdown = !e.target.checked;
       this.usedropdown = !this.coverageForm.value.notusedropdown;
      }
      onChangeAmt(e) {
        this.coverageVal = e.target.value;
        this.coverageForm.value.coverageValue = e.target.value;
        this.coverageForm.patchValue(this.coverageForm.value);
            const classCode = this.selectedClass != null ? this.selectedClass.ClassCode : null;
            if (this.selectedPackage != null) {
              // tslint:disable-next-line:max-line-length
              this.changeCov.emit({id: this.coverages.benefitEntityPolicyDetailCoverageId, value: e.target.value, packageCode: this.selectedPackage.PackageCode, classCode});

            } else {
              this.changeCov.emit({id: this.coverages.benefitEntityPolicyDetailCoverageId, value: e.target.value });
            }
      }
      modelChanged(e) {
        this.coverageForm.value.coverageValue = e;
        this.coverageVal = e ;
            this.coverageForm.patchValue(this.coverageForm.value);
            const classCode = this.selectedClass != null ? this.selectedClass.ClassCode : null;
            if (this.selectedPackage != null) {
              // tslint:disable-next-line:max-line-length
              this.changeCov.emit({id: this.coverages.benefitEntityPolicyDetailCoverageId, value: e, packageCode: this.selectedPackage.PackageCode, classCode});

            } else {
              this.changeCov.emit({id: this.coverages.benefitEntityPolicyDetailCoverageId, value: e });
            }

      }
      getRate() {

      }
      ngOnInit() {
        const obj  = JSON.parse(this.coverages.enrollmentCoverageObject);
        this.CoverageNumberPackages = obj.CoverageNumberPackages;
        this.IsCoverageAmountRequired = obj.IsCoverageAmountRequired;
        this.IsNoPackageCoverageNumberRequired = obj.IsNoPackageCoverageNumberRequired;
        this.UIControlType = obj.UIControlType;
        if (this.IsNoPackageCoverageNumberRequired === true) {
          const CoverageNumberPackages = obj.CoverageNumberPackages;
          const PackageClassCodes = CoverageNumberPackages[0].PackageClassCodes;
          if (this.IsNoPackageCoverageNumberRequired === true) {
            this.MaximumumIssueValue = PackageClassCodes[0].MaximumumIssueValue;
            this.MinimumIssueValue = PackageClassCodes[0].MinimumIssueValue;
            const  CoverageCalculationTypeCode = PackageClassCodes[0].CoverageCalculationTypeCode;
            let AllowedCoverageAmount = PackageClassCodes[0].AllowedCoverageAmount;
            if (AllowedCoverageAmount === 0 && this.coverageVal!=null) {
              AllowedCoverageAmount = this.coverageVal;
            }
            if (CoverageCalculationTypeCode === 'C') {
              if (this.MaximumumIssueValue > AllowedCoverageAmount) {
                this.MaximumumIssueValue = AllowedCoverageAmount;
              }
  
            }
           this.IncrementalValue = PackageClassCodes[0].IncrementalValue;
            if (this.IncrementalValue != null && this.IncrementalValue !== 1) {
              this.dropdownbox = true;
              let amt = this.MinimumIssueValue;
  
              while (amt <= this.MaximumumIssueValue) {
                this.droplist.push(amt);
                amt = amt + this.IncrementalValue;
              }
              if (this.coverageVal != null && (this.coverageVal % this.IncrementalValue !== 0 || Number(this.coverageVal)>this.MaximumumIssueValue)) {
                this.usedropdown = false;
              }
            }
  
          }

         


        }
        // this.demoValue = 0;
        /* if (this.coverageForm.value.coverageValue == null) {
          this.coverageForm.value.coverageValue = 0;
        } */
        if (this.packageCode != null) {
          this.changePackage(this.packageCode);
        }
      this.coverageForm = this.fb.group({
        coverage: this.coverages.benefitEntityPolicyDetailCoverageId,
        coverageValue: this.coverageVal,
        packageCode: this.packageCode,
        classCode: this.classCode,
        selected: null,
        CoverageNumber: this.CoverageNumber,
        benefitEntityPolicyDetailCoverageTierCode: this.coverages.benefitEntityPolicyDetailCoverageTierCode,
        coverageRelationShipCodes: this.coverages.coverageRelationShipCodes==null?null:this.coverages.coverageRelationShipCodes[0],
        notusedropdown: !this.usedropdown
      });
      // this.coverageForm.value.coverageValue = this.coverage;
      // this.coverageForm.patchValue(this.coverageForm.value);
      // Emit the form group to the father to do whatever it wishes
      this.formReady.emit(this.coverageForm);
      if (this.packageCode != null) {
        this. changePackage(this.packageCode);
        this.changeClass(this.classCode);
      }
      if (this.IsNoPackageCoverageNumberRequired === true) {
        /* this.changeCov.emit({id: this.coverages.benefitEntityPolicyDetailCoverageId,
                              value: this.coverageVal
            }); */
      }
      this.init = false;

      }
      changePackage(pcode) {
         this.selectedClass = null;
        this.selectedPackage = this.CoverageNumberPackages.find(p => p.PackageCode === pcode);

        if (this.selectedPackage != null) {
          this.CoverageNumber = this.selectedPackage.CoverageNumber;
          this.classes = this.selectedPackage.PackageClassCodes;
        }  else {
          this.classes = null;
        }
        if (this.IsCoverageAmountRequired === false) {
          if (this.init === true) {
            /* this.changeCovInit.emit({id: this.coverages.benefitEntityPolicyDetailCoverageId,
              benefitEntityPolicyDetailCoverageTierCode: this.coverages.benefitEntityPolicyDetailCoverageTierCode,
              packageCode: this.selectedPackage.PackageCode, CoverageNumber: this.CoverageNumber});
 */          } else {
            this.changeCov.emit({id: this.coverages.benefitEntityPolicyDetailCoverageId,
              benefitEntityPolicyDetailCoverageTierCode: this.coverages.benefitEntityPolicyDetailCoverageTierCode,
              packageCode: this.selectedPackage.PackageCode, CoverageNumber: this.CoverageNumber});
          }
        }
      }
      onChangePackage(event) {
          const pcode = event.target.value;
          // find cover number
         /*  const f = this.CoverageNumberPackages.find(x => x.PackageCode === pcode);
          if (f != null) {
            this.CoverageNumber = f.CoverageNumber;
          } */
          this. changePackage(pcode);
          this.coverageForm.value.classCode = null;
          this.coverageForm.value.CoverageNumber = this.CoverageNumber;
          this.coverageForm.patchValue(this.coverageForm.value);

      }
      changeClass(ccode) {
        let amount = this.coverageForm.value.coverageValue;
        if (ccode != null && this.classes != null) {
          this.selectedClass = this.classes.find(p => p.ClassCode === ccode);
          if ( this.selectedClass.AllowToChooseLowerValue === false) {
            switch (this.selectedClass.CoverageCalculationTypeCode) {
              case 'T':
                amount = this.selectedClass.MaximumumIssueValue;
                break;
              case 'C':
                  amount = this.selectedClass.AllowedCoverageAmount;
                  break;
              case 'F':
                      amount = this.selectedClass.FlatAmount;
                      break;
            }
        }
        }
        if (amount != null) {
          this.coverageForm.value.coverageValue = amount;
          this.coverageForm.value.CoverageNumber = this.CoverageNumber;
          this.coverageForm.patchValue(this.coverageForm.value);
          const classCode = this.selectedClass != null ? this.selectedClass.ClassCode : null;
          if (this.init === true) {
            /* this.changeCovInit.emit({id: this.coverages.benefitEntityPolicyDetailCoverageId, coverage: amount,
              benefitEntityPolicyDetailCoverageTierCode: this.coverages.benefitEntityPolicyDetailCoverageTierCode,
              packageCode: this.selectedPackage.PackageCode, classCode, CoverageNumber: this.CoverageNumber});
 */          } else {
            this.changeCov.emit({id: this.coverages.benefitEntityPolicyDetailCoverageId, coverage: amount,
              benefitEntityPolicyDetailCoverageTierCode: this.coverages.benefitEntityPolicyDetailCoverageTierCode,
              packageCode: this.selectedPackage.PackageCode, classCode, CoverageNumber: this.CoverageNumber});
          }

        }
      }
      onChange(event) {
        const ccode = event.target.value;
        this.changeClass(ccode);
      }
  }
