import { Component, OnInit, ViewChild, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DecimalInputComponent } from '@shared/components/decimal-input/decimal-input.component';
import * as _ from 'lodash';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'app-integer-input',
  templateUrl: './integer-input.component.html',
  styleUrls: ['./integer-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntegerInputComponent),
      multi: true
    }
  ]
})
export class IntegerInputComponent extends BaseComponent
  implements OnInit, ControlValueAccessor {
  @ViewChild('input')
  decimalInput: DecimalInputComponent;

  @Input()
  allowInvalid = false;
  @Input()
  emptyToZero = true;
  @Input()
  icon: string;

  constructor() {
    super();
  }

  ngOnInit() {
    this.applyOptions();
  }

  writeValue(obj: any): void {
    this.decimalInput.writeValue(obj);
  }

  registerOnChange(fn: any): void {
    this.decimalInput.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.decimalInput.registerOnTouched(fn);
  }

  private applyOptions() {
    _.assign(this.decimalInput, {
      digitsInfo: '1.0',
      allowInvalid: this.allowInvalid,
      emptyToZero: this.emptyToZero,
      icon: this.icon
    });
  }

  private invalidAllowed(): boolean {
    return super.isTruthy(this.allowInvalid);
  }
}
