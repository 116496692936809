<br/>
<span class="title">Reductions Rule:
  <a color="primary" href="javascript:void(0)" (click)="download()"><i class="fa fa-file-excel-o doc-icon" aria-hidden="true"></i></a>
</span>
<span> 
  <button class="add-btn" (click)=onAdd()>Add</button><br />
</span>
<nz-table *ngIf='coverageReductionsRuleDTO!=null && coverageReductionsRuleDTO.length>0' #basicTable [nzData]="coverageReductionsRuleDTO" nzSize="small" [nzShowPagination]="true">
  <thead>
    <tr>
      <ng-container *ngFor="let access of gridConfigs">
        <th>
          {{access?.columnLabel}}
        </th>
      </ng-container>
      <th><b>Action</b></th>
    </tr>
  </thead>
  <tbody>
    <ng-template ngFor let-data [ngForOf]="basicTable.data">
      <tr>
        <ng-container *ngFor="let access of gridConfigs">
          <ng-container [ngSwitch]="access.columnType">
            <td class="ng-binding" *ngSwitchCase="'text'">{{data[access.columnName]}}</td>
            <td class="ng-binding" *ngSwitchCase="'date'">{{data[access.columnName] | date:'M/d/yy'}}</td>
            <td class="ng-binding" *ngSwitchCase="'datetime'">{{data[access.columnName] | date:'M/d/yy, h:mm a'}}</td>
            <td class="ng-binding" *ngSwitchCase="'gender'">{{data[access.columnName] | gender}}</td>
          </ng-container>
        </ng-container>
        <td>
          <!-- <a class="PatchFont" (click)=onDelete(data)>Delete</a> -->
          <button class="edit-btn" (click)=onEdit(data)>Edit</button>
        </td>
      </tr>
    </ng-template>
  </tbody>
</nz-table>