<div style="width: 300px; margin: 30px;  border: 1px solid rgb(136, 136, 136) ;border-radius: 5px;padding: 20px; ">
  <b style="font-size:16px ;"><u>Benefits</u></b> <br />
  Dental: <b>$10</b> <br />
  Vision: <b>$15</b><br />
  Total: <b>$25</b> <br />
  <b style="font-size:16px ;">Total :$50</b> <br />
  <br />
  <button title="Enrollment Submission" style="width:100%" class="mat-flat-button mat-primary"
    nz-popconfirm nzPopconfirmTitle="Are you sure you want to check out?" (nzOnConfirm)='onCheckout()'>Chech Out <i
      class="fa fa-arrow-right"></i>

  </button>
</div>