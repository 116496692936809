<app-selected-member-header-share></app-selected-member-header-share>

<div style="padding:10px 0px 10px 0px">
  <h2 class="member-function-header"> </h2>
</div>
<div class="row">
  <div class="col-12">
    <form>
      <div class="row">
        <div class="col-12">
          <app-form-group-wrapper>
            <div class="col-12 no-padding">
              <div style="text-align: right;">
                <button mat-flat-button color="primary" href="javascript:void(0)">Add New</button>
              </div>
            </div>
            <br>
            <table class="table table-hover" style="margin-top: 10px">
              <thead class="thead-dark">
                <tr>
                  <th *ngFor="let access of gridConfigs" nowrap="">
                    {{access.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                  </th>
                  <th style="width:6%"><b>Action</b></th>
                </tr>
              </thead>
              <tbody>

              </tbody>
            </table>
            <!-- pager row -->
            <div *ngIf="paging=='true'" style="padding: 10px 0px;">
              <app-pager [pageIndex]="pageIndex" [pageSize]="pageSize" [totalRowsCount]="TotalRowCount"
                [pageCount]="TotalPageCount" (pageIndexChange)="pageIndexChange($event)">
              </app-pager>
            </div>

          </app-form-group-wrapper>
        </div>
      </div>
      <div class="row">
        <div class="col form-button-bar">
          <button mat-flat-button color="primary">Cancel</button>
        </div>
      </div>
    </form>
  </div>

</div>