<app-suspense-comment 
  (AddSuspensecomment)="addSuspenseComment()" 
  (moveSuspense)="moveSuspense($event)"
  [suspenseComments]='suspenseComments'>
</app-suspense-comment>
<br />
<div style="width:100%;border:1px solid rgb(182, 182, 182);padding: 15px;display: inline-block;">
  Select Group
  <ng-container *ngIf="observ$ | async as Lookup">
    <select (change)="onChange($event)" [(ngModel)]="clientId">
      <option></option>
      <option *ngFor="let opt of Lookup" [value]="opt['clientId']">{{opt['clientNameWithGroupAndSection']}}</option>
    </select>
  </ng-container>
  &nbsp;&nbsp;
  Suspense Balance : {{suspenseAmount | currency }} &nbsp;&nbsp; Outstanding Balance {{outstandingAmount | currency}}:
  <nz-input-number [nzFormatter]="formatterDollar" [nzParser]="parserDollar" [(ngModel)]="applyAmt"></nz-input-number>
  &nbsp;&nbsp;
  <i style="cursor: pointer" class="fa fa-floppy-o PatchFont-toppadding" aria-hidden="true" nz-popconfirm
    nzPopconfirmTitle="Are you sure you want to save  ?" (nzOnConfirm)="onApply()"></i>&nbsp;&nbsp;<i
    class="fa fa-arrow-up PatchFont-toppadding" aria-hidden="true" nz-popconfirm nzPopconfirmTitle="Are you sure you want to move this up?"
    (nzOnConfirm)="onMoveUp()" *ngIf='suspenseAmount!=0'></i>
  <br /> <br />
  <app-suspense-group (refresh)="onRefresh()" [entityId]='entityId' [clientId]='clientId'
    (suspenseAmount)='suspenseAmountChange($event)'></app-suspense-group>
</div>