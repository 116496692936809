import {
  Component,
  OnInit,
  ViewChild,
  Input,
  forwardRef,
  Renderer2,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import {
  DateFormatterService,
  MIN_DATE,
  DATE_FORMAT
} from '@shared/formatters/date-formatter.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BaseComponent } from '@shared/components/base.component';
import {
  MatDatepicker
} from '@angular/material/datepicker';
import {
  MAT_DATE_FORMATS,
  MatDateFormats
} from '@angular/material/core';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';

const APP_MAT_MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'L'
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_MAT_MOMENT_DATE_FORMATS
    }
  ]
})
export class DateInputComponent extends BaseComponent
  implements OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild('input')
  input: ElementRef;

  @ViewChild(MatDatepicker)
  private datepicker: MatDatepicker<any>;

  @Input()
  dateFormat = DATE_FORMAT;
  @Input()
  allowInvalid = true;
  @Input()
  maskModelValue = true;
  @Input()
  emptyToToday = false;
  @Input()
  readonlydate = false;
  textMaskConfig = {
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    keepCharPositions: true
  };

  constructor(
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2,
    private formatter: DateFormatterService
  ) {
    super();
  }

  // callbacks for ControlValueAccessor
  onChange: any;
  onTouched: any;

  private _rawValue: any;
  get rawValue(): any {
    return this._rawValue;
  }

  private _modelValue: any;
  get modelValue(): any {
    return this._modelValue;
  }

  ngOnInit() {
    this.watchResolution();
  }

  // datepicker callback - Emits when a change event is fired on this <input>.
  onDateChange(value: any) {
    this.onChangeInternal(value);
  }

  // datepicker callback - Emits when an input event is fired on this <input>.
  onDateInput(value: any) {
    this.onChangeInternal(value);
  }

  ngAfterViewInit() {
    // InputMask({ mask: '99/99/9999' }).mask(this.input.nativeElement);
  }

  // ControlValueAccessor method
  // This method will be called by the forms API to write to the view
  // when programmatic (model -> view) changes are requested.
  writeValue(value: any): void {
    this.updateModelToView(value);
  }

  // ControlValueAccessor method
  // Registers a callback function that should be called when the control's value changes in the UI.
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // ControlValueAccessor method
  // This is called by the forms API on initialization so it can update the form model when
  // values propagate from the view (view -> model).
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  inputFocus(value: string) {
    this.onTouched();
  }

  inputBlur(value: string) {
    this.updateViewToModel(value);
  }

  private onChangeInternal(value: any) {
    this.onChange(this.formatter.format(value));
  }

  private updateModelToView(value: any) {
    if (this.modelValue === value) {
      return;
    }

    const { valid, date } = this.validateAndParse(value);

    if (valid) {
      this.formatAndUpdateDOM(date);
    } else {
      this.updateDOMWithInvalid(value);
    }
  }

  private updateViewToModel(value: any) {
    if (this.rawValue === value) {
      return;
    }

    const { valid, date } = this.validateAndParse(value);

    if (valid) {
      this.formatAndUpdateDOM(date);
    } else {
      this.updateDOMWithInvalid(value);
    }

    this.raiseOnChange(value, valid, date);
  }

  private formatAndUpdateDOM(date: Date) {
    const formatted = this.format(date);
    this.updateDOM(formatted);
  }

  private updateDOMWithInvalid(value: any) {
    if (this.invalidAllowed()) {
      this.updateDOM(value);
    } else {
      this.updateDOM('');
    }
  }

  private updateDOM(value: string) {
    this._rawValue = value;
    this.renderer.setProperty(this.input.nativeElement, 'value', value);
  }

  private validateAndParse(
    value: any
  ): {
    valid: boolean;
    date: Date;
  } {
    if (_.isEmpty(value) && this.emptyToToday) {
      return { valid: true, date: new Date() };
    }

    const date = this.parse(value);
    if (date && this.formatter.isValidDate(date)) {
      return { valid: true, date };
    }
    return { valid: false, date: MIN_DATE };
  }

  private format(value: Date): string {
    return this.formatter.format(value, this.dateFormat);
  }

  private parse(value: any) {
    return this.formatter.parse(value, this.dateFormat);
  }

  private raiseOnChange(viewValue: string, valid: boolean, date: Date) {
    this._modelValue = this.getModelValue(viewValue, valid, date);
    this.onChange(this._modelValue);
  }

  private getModelValue(viewValue: string, valid: boolean, date: Date): any {
    const maskModelValue = this.isTruthy(this.maskModelValue);

    if (valid) {
      return maskModelValue ? viewValue : date;
    }

    if (this.invalidAllowed()) {
      return maskModelValue ? viewValue : _.trimEnd(viewValue, '_'); // remove the trailing mask placeholder chars
    }

    return undefined;
  }

  private watchResolution() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(untilComponentDestroyed(this))
      .subscribe(result => (this.datepicker.touchUi = !result.matches));
  }

  private invalidAllowed(): boolean {
    return super.isTruthy(this.allowInvalid);
  }
}
