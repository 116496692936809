<div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;">
  <nz-spin [nzSize]="'large'" nzSimple style="width:200px; 
    height:200px; 
    position: fixed; 
    top: 50%; 
    left: 50%; 
    margin-top: -100px; 
    margin-left: -100px;"></nz-spin>
  <span class="default-font-Color-dark">Loading ...</span>
</div>

<div *ngIf='hideReissue == "true"'>
  <a *ngIf='reissured == false' style="text-align: right;" class="default-font-Color-dark"
    (click)="reissue(item)"><u>Re-issue</u></a><br/>
  <div *ngIf='reissured == true'>Reissued</div>
  <ng-container *ngIf='EntityId == "68bc62e6-dd96-4428-91e8-efe285bd23a0"'>
    <a style="text-align: right;" class="default-font-Color-dark" (click)="Idnd(item)"><u>IDND {{IDNTFlag.toString() | titlecase}}</u></a><br />
  </ng-container>
</div>
<div *ngIf='cancelPendigFlag && allowedUser'>
  <a style="text-align: right;" class="default-font-Color-dark" (click)="onCancel(item)"><u>Cancel Pending </u></a><br />
</div>
<div>
  <a style="text-align: right;" class="default-font-Color-dark" (click)="history(item)"><u>History</u></a>
</div>
