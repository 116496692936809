import * as faker from 'faker';
import * as _ from 'lodash';
import {
  MockRequestHandler,
  MockRequestHandlerParams
} from '@mock/mock-data/shared/mock-handler.interface';
import { WELL_KNOWN_GENDER } from '@mock/mock-data/shared/common.mock';

export class GenderLookupMockHandler extends MockRequestHandler<any[]> {
  handleRequest(params: MockRequestHandlerParams): any[] {
    return _.map(
      _.keys(WELL_KNOWN_GENDER),
      key => WELL_KNOWN_GENDER[key] as any
    );
  }
}
