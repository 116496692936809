import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ExcelService } from '@services/excel/excel.service';


@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent implements OnInit {
  constructor(protected excelService: ExcelService) {}
  @Input()
  set clientData(clientData) {
    this._clientData = clientData;
    this.currentClientData = clientData;
    this.filterClientData = this.currentClientData;
  }
  _clientData;
  @Input()
  set filterKeyChange(filterKeyChange) {
    this._filterKeyChange = filterKeyChange;
    if (filterKeyChange.length >= 3) {
      this.filter(filterKeyChange);
    } else if (filterKeyChange.length == 0) {
      this.filterClientData = this.currentClientData;
    }
  }
  _filterKeyChange;
  filterClientData;
  currentClientData;
  mapOfExpandData: { [key: string]: boolean } = {};
  @Input()
  view='card';
  filter(filter: any) {
    this.filterClientData = {};
    for (const key in this.currentClientData) {
      if (key != 'Benefit Name') {
        let value = this.currentClientData[key];
        for (const key1 in value[0]) {
          if (key1.toLowerCase().includes(filter.toLowerCase())) {
            this.filterClientData[key] = value;
            break;
          }
        }
      }
    }
  }
  @ViewChild('firstAccordion') firstAccordion: MatAccordion;
  @ViewChild('secondAccordion') secondAccordion: MatAccordion;
  @ViewChild('thirdAccordion') thirdAccordion: MatAccordion;

  openAllFirst() {
    this.firstAccordion.openAll();
  }
  closeAllFirst() {
    this.firstAccordion.closeAll();
  }
  openAllSecond() {
    this.secondAccordion.openAll();
  }
  closeAllSecond() {
    this.secondAccordion.closeAll();
  }

  openAllThird() {
    this.thirdAccordion.openAll();
  }
  closeAllThird() {
    this.thirdAccordion.closeAll();
  }

  @Input() headerData;
  @Input() tableData;

  ngOnInit(): void {}
  expandAll(){
    this.filterClientData;
    for (const [key, value] of Object.entries( this.filterClientData)) {
      console.log(`${key}: ${value}`);
      if(key!='Benefits' && key!='ClientName' && key!='ClientCode')
        this.mapOfExpandData[key]=true;
    }
    
  //   this.listOfData1.forEach(data=>{
  //     this.mapOfExpandData[data.Id]=true;
  // })
  }
  collapseAll(){
    this.mapOfExpandData={};
  }
  download() {

    // window.location.href = 'http://localhost:4200/#/?ABCSSOToken=ABCV1SSO0naGREswpkip7QWhuPV5Qg&appid=8058cd2e-b455-4625-82be-e4b4ebb24bbe';
    // return;
    const excelrows: any = [];

    for (const data in this.filterClientData) {
      if (data != 'ClientName' && data != 'ClientCode' && data != 'Benefits') {
        let obj = {};
        obj['.'] = data;
        obj['..'] = '';
        obj['...'] = '';
        excelrows.push(obj);
        for (const key in this.filterClientData[data][0]) {
          obj = {};
          obj['..'] = key;
          obj['...'] = this.filterClientData[data][0][key];
          excelrows.push(obj);
        }
        obj = {};
        obj['.'] = '';
        obj['..'] = '';
        obj['...'] = '';

        excelrows.push(obj);
      }
    }

    this.excelService.exportAsExcelFileNoHeader(excelrows, 'general');
  }
}
