<h1 mat-dialog-title>{{data.title}}</h1>
<div *ngIf="loading" class="col-12 justify-content-center" style="padding: 30px;" >
  <nz-spin [nzSize]="'large'" nzSimple style = "width:200px; 
  height:200px; 
  position: fixed; 
  
  top: 50%; 
  left: 50%; 
  margin-top: -100px; 
  margin-left: -100px;"></nz-spin>
  <span  class="default-font-Color-dark">Loading ...</span>
</div>
<div mat-dialog-content>

 
      <nz-select  class="form-control"  [(ngModel)]="fileName" [nzShowSearch]="'true'" nzAllowClear style="width: 100%; border: none !important;" >
          <nz-option *ngFor="let opt of pathNames" [nzValue]="opt"  [nzLabel]="opt"></nz-option>
      </nz-select><br/>
      <br/>
      <mat-form-field  style="width: 100%;">
    <input matInput [(ngModel)]="fileName">
    <br/>
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="onNoClick()">Cancel</button>&nbsp; &nbsp; 
  <button mat-flat-button color="primary" (click)="closeDialog()"  cdkFocusInitial>Ok</button>
</div> 