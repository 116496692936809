<br />
<div class="title-row">
  <span class="title">Bill Config: 
    <a color="primary" href="javascript:void(0)" (click)="download()"><i class="fa fa-file-excel-o doc-icon" aria-hidden="true"></i></a>
  </span>
  <button class="add-btn" (click)=onAdd()>Add</button><br/>
</div>


<nz-table *ngIf='rows!=null && rows.length>0' #basicTable  [nzData]="rows" [nzShowPagination]="true" class="col-12">
  <thead>
    <tr>
      <ng-container *ngFor="let access of gridConfigs">
        <th>
          {{access?.columnLabel}}
        </th>
      </ng-container>
      <th nzRight><b>Action</b></th>
    </tr>
  </thead>
  <tbody>
    <!-- <ng-template ngFor let-data [ngForOf]="basicTable.data"> -->
    <tr *ngFor="let data of basicTable.data">
      <ng-container *ngFor="let access of gridConfigs">
        <ng-container [ngSwitch]="access.columnType">
          <td class="ng-binding" *ngSwitchCase="'text'">{{data[access.columnName]}}</td>
          <td class="ng-binding" *ngSwitchCase="'date'">{{data[access.columnName] | date:'M/d/yy'}}</td>
          <td class="ng-binding" *ngSwitchCase="'datetime'">{{data[access.columnName] | date:'M/d/yy, h:mm a'}}</td>
          <td class="ng-binding" *ngSwitchCase="'gender'">{{data[access.columnName] | gender}}</td>
        </ng-container>
      </ng-container>
      <td nzRight>
        <!-- <a class="PatchFont" (click)=onDelete(data)>Delete</a> -->
        <button class="edit-btn" (click)=onEdit(data)>Edit</button>
      </td>
    </tr>
    <!-- </ng-template> -->
  </tbody>
</nz-table>