<div class="app-container">
  <div *ngIf="loading" class="col-12 justify-content-center loading">
    <nz-spin [nzSize]="'large'" nzSimple></nz-spin>
    <span class="default-font-Color-dark ">Loading ...</span>
  </div>
  <!--   <app-selected-client-header-share ></app-selected-client-header-share> -->
  <br>
  <div class="row no-gutters">
    <h2 class="col-6 member-function-header">Member Lookup</h2>
    <div class="col-6">
      <div *ngIf="selectedEntityClient != null && selectedEntityClient.entityId != null" class="add-button">
        <a mat-flat-button color="primary" style="color: #fff !important" (click)="addMember()">Add New</a>
      </div>
    </div>

    <div class="col-12 mt-15 row no-gutters">
      <ng-select (change)="selectedEntityClientChange($event)" [(ngModel)]="selectedEntityClient">
        <ng-option *ngFor="let item of entityListWithClients" [value]='item'>{{ getEntityClientDisplayText(item) }}
        </ng-option>
      </ng-select>
    </div>

    <div class="row col-12 no-margin no-padding">
      <div class="col-12no-padding">
        <app-dynamic-fields #fields [cancelButton]="'false'" [formConfigs]='formConfigs' [saveButton]='saveButton'
          (save)="onSave($event)"></app-dynamic-fields>
      </div>
    </div>
    <div class="row col-12 no-margin no-padding" *ngIf="rows !=null">

      <div class="col-12 no-margin no-padding">
        <app-form-group-wrapper class="no-padding">
          <h5>Search Result</h5>
          <nz-table #basicTable [nzData]="rows" nzSize="small" class="table table-hover"
            *ngIf='gridConfigs!=null' class="col-12">
            <thead (nzSortChange)="sort($event)" nzSingleSort class="thead-dark">
              <tr>
                <th *ngIf="actionButton!=='none'" class="ssn-column"><b>&nbsp;SSN</b></th>
                <ng-container *ngFor="let access of gridConfigs">

                  <th nowrap="" nzShowSort [nzSortKey]="access.columnName"
                    (nzFilterChange)="filter($event,access.columnName,access.filterType )" [nzFilterMultiple]="'false'"
                    nzShowFilter [nzFilters]="access.filterList"
                    *ngIf="access.sort!=null && access.filter!=null && access.filterType==='in'">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                  </th>

                  <th nowrap="" nzShowSort [nzSortKey]="access.columnName" nzCustomFilter
                    *ngIf="access.sort!=null && access.filter!=null && access.filterType==='search'">
                    {{access?.columnLabel}}&nbsp;
                    <nz-filter-trigger [nzVisible]="visible" [nzActive]="'true'" [nzDropdownMenu]="menu">
                      <i nz-icon [nzType]="'search'"></i>
                    </nz-filter-trigger>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <div class="ant-table-filter-dropdown">
                        <div class="search-box">
                          <input type="text" nz-input placeholder={{access.columnLabel}} [(ngModel)]="searchValue" />
                          <button nz-button nzSize="small" nzType="primary"
                            (click)="searchCust(access.columnName,access)" class="search-button">
                            Search
                          </button>
                          <button nz-button nzSize="small" (click)="resetCust(access.columnName)">Reset</button>
                        </div>
                      </div>
                    </nz-dropdown-menu>
                  </th>

                  <th nowrap="" (nzFilterChange)="filter($event,access.columnName,access.filterType )"
                    [nzFilterMultiple]="'false'" nzShowFilter [nzFilters]="access.filterList"
                    *ngIf="access.sort==null && access.filter!=null  && access.filterType==='in'">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                  </th>

                  <th nowrap="" nzShowSort [nzSortKey]="access.columnName"
                    *ngIf="access.sort!=null && access.filter==null">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>         
                  </th>

                  <th nowrap="" *ngIf="access.sort==null &&  access.filter==null">
                    {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
                      ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                  </th>

                </ng-container>

              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of basicTable.data">
                <tr class="ng-scope">
                  <td class="default-font-Color">
                    <a *ngIf="item['ssNumber'] != '' && item['ssNumber'] != null && item['ssNumber'] != ''"
                      [routerLink]="selection(item)"><span 
                      class="btnEdit">***-**-{{item['ssNumber'].substring(5)}}</span></a>
                    <a *ngIf="item['ssNumber'] == '' || item['ssNumber'] == null" [routerLink]="selection(item)"><span
                         class="btnEdit">No SSN</span></a>
                  </td>
                  <ng-container *ngFor="let access of gridConfigs">
                    <ng-container [ngSwitch]="access.columnType">
                      <td class="ng-binding" *ngSwitchCase="'text'">{{item[access.columnName]}}</td>
                      <td class="ng-binding" *ngSwitchCase="'date'">{{item[access.columnName] | date:'M/d/yy'}}</td>
                      <td class="ng-binding" *ngSwitchCase="'phone'">{{ item[access.columnName] | slice:0:3 }}-{{
                        item[access.columnName] | slice:3:6 }}-{{ item[access.columnName] | slice:6:10 }}</td>
                    </ng-container>
                  </ng-container>

                </tr>
              </ng-container>
            </tbody>
          </nz-table>
          <!-- pager row -->
        </app-form-group-wrapper>
      </div>

    </div>
  </div>
</div>
<div class="bottom-space"></div>