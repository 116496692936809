

<br />
<div class="title-row">
  <div class="title">Policy: </div> 
  <button class="policy-btn" (click)=onAdd()>Add</button><br/>
</div>

<nz-table nzWidth="100%" *ngIf='listOfData!=null && listOfData.length>0 && gridConfigs!=null' #dynamicTable [nzData]="listOfData" [nzShowPagination]="true" [nzSize]="size">
  <thead>
    <tr>
      <th nzWidth="30px" nzShowExpand *ngIf="expandable"></th>
      <ng-container *ngFor="let access of gridConfigs">
        <th>
          {{access?.columnLabel}}
        </th>
      </ng-container>
      <th><b>Action</b></th>
    </tr>
  </thead>
  <tbody>
    <ng-template ngFor let-data [ngForOf]="dynamicTable.data">
      <tr>
        <td nzShowExpand *ngIf="expandable" [(nzExpand)]="mapOfExpandData[data.benefitEntityPolicyId]"
          (nzExpandChange)="expand(data,$event)"></td>
        <ng-container *ngFor="let access of gridConfigs">
          <ng-container [ngSwitch]="access.columnType">
            <td class="ng-binding" *ngSwitchCase="'text'">{{data[access.columnName]}}</td>
            <td class="ng-binding" *ngSwitchCase="'date'">{{data[access.columnName]| date:'M/d/yy'}}</td>
            <td class="ng-binding" *ngSwitchCase="'datetime'">{{data[access.columnName]| date:'M/d/yy, h:mm a'}}</td>
            <td class="ng-binding" *ngSwitchCase="'gender'">{{data[access.columnName] | gender}}</td>
          </ng-container>
        </ng-container>
        <td>
          <!-- <a class="PatchFont" (click)=onDelete(data)>Delete</a> -->
          <a class="edit-btn" (click)=onEdit(data)>Edit</a>
        </td>
      </tr>
      <tr [nzExpand]="mapOfExpandData[data.benefitEntityPolicyId] && expandable"
        *ngIf='mapOfExpandData[data.benefitEntityPolicyId]'>
        <td></td>
        <td [attr.colspan]="gridConfigs.length" style="width: 100% !important;">
          <app-benefitdetail-policy [benefitEntityPolicyId]='data.benefitEntityPolicyId'>
          </app-benefitdetail-policy>
        </td>
      </tr>
    </ng-template>
  </tbody>
</nz-table>
<br />