<nz-back-top></nz-back-top>
<div style="position: absolute; top: 0px; text-align: right; padding-right: 12%;" class="col-12">
  <app-breadcrumb-router [paths]='paths'> </app-breadcrumb-router>
</div>
<div class="row" style="padding:0px 10%;margin: 0px">
  <!-- <div   class="ProductOverViewBlock" onclick="$('.ProductOverViewBlock').hide(); $('.ProductOverView').hide();" style="z-index:9998; position:fixed ;top:0px;left:0px; width:100%;height:5000px;background-color:rgba(0, 0, 0, 0.81);display:none " ></div> -->

  <app-selected-member-header-share class="col-12" style="padding: 0px" [memberState]="memberState"
    [entityClientId]='entityclientId'></app-selected-member-header-share>

  <div class="col-8 no-gutters" style="padding-left: 0px">
  </div>
  <div class="col-4" style="text-align: right;font-size:18px;padding-left: 0px">
    <u><a color="primary" routerLink="../dashboard">Back To Employee Dashboard</a></u>
  </div>
</div>
<hr />
<div *ngIf="profile?.payFrequency != null">
  <div class="row" style="padding:0px 10%">
    <div class="col-9" style=" border: 1px ">
      <h2 style="font-weight:100;vertical-align: top">Manage Benefits (<a [routerLink]="['../benefitssummary']">Benefit Summary</a>)
        <div *ngIf="((EntityId == 'd6a2ff7e-bba0-48fa-9396-a6b202631669') || (EntityId == 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028')) && memberProfile?.cobraStatus == 'P'"
          style="cursor: pointer; display: inline-block;position: absolute;margin-top: 15px;margin-left: 15px;"
          (click)="onActivate()" class="PatchFont ng-star-inserted">Activate Plans
        </div>
      </h2>
      <div *ngFor="let item of formValue; index as i">
        <app-member-plans-benefitentity [memberProfile]="memberProfile" [benefitEntity]="item"
        [index]="i"
          (loadingComplete)='onLoadingComplete($event)' (formReady)="formInitialized(item.benefitEntityId, $event)">
        </app-member-plans-benefitentity>
      </div>
    </div>
    <div class="col-3" style="padding-top:54px; ">
      <div class="nav flex-column nav-pills cartblock" id="v-pills-tab" role="tablist" aria-orientation="vertical">
        <app-member-plans-cartnew [benefitEntities]='formValue' [entityId]='EntityId' [benefitValue]='entityForm.value'
          [memberState]="memberState" (OnConfirm)='onConfirm()'></app-member-plans-cartnew>
        <div *ngIf="loading">
          <nz-spin nzSimple [nzSize]="'large'" style="width:200px; 
                                        height:200px; 
                                        position: fixed; 
                                        
                                        top: 50%; 
                                        left: 50%; 
                                        margin-top: -100px; 
                                        margin-left: -100px;">
          </nz-spin>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="blurredBox row justify-content-center " style="padding: 20px;margin:0 10%;width: 100%;width:80%;"
  *ngIf="profile?.payFrequency == null ">
  <h5 style="color:red;">Pay Frequency is required to Enroll in Benefits, Please click <a style="color:blue"
      [routerLink]="getRouterLink(MEMBER_ROUTE_SEGMENT.profile)"><u>here</u></a> and update the details.</h5>
</div>
<div class="row">
  <div class="col form-button-bar">
    &nbsp;
  </div>
</div>


<!-- <h2 class='mat-h2'>Form Value policiesForm</h2> -->
<!-- <pre>
      {{entityForm.value | json}}
    </pre> -->