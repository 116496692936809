import { Component, OnInit } from '@angular/core';
import { DynamicSearchActionComponent } from '@shared/components/dynamic_search_action/dynamic_search_action.component';

@Component({
  selector: 'app-legal-claim',
  templateUrl: './legal-claim.component.html',
  styleUrls: ['./legal-claim.component.scss']
})
export class LegalClaimComponent extends DynamicSearchActionComponent {
  checkNumber; checkDate;

  checkAssignCheck() {
    // tslint:disable-next-line:max-line-length
    const find = this.rows.some(item => this.mapOfCheckedId[item[this.rowid]] && item['checkStatus'] === 'Void');
    return find;
  }

  checkVoidCheck() {
    // tslint:disable-next-line:max-line-length
    const find = this.rows.some(item => this.mapOfCheckedId[item[this.rowid]] && item['legalClaimStatus'] === 'Paid' && item['checkStatus'] !== 'Void');
    const find1 = this.rows.some(item => this.mapOfCheckedId[item[this.rowid]] && item['legalClaimStatus'] !== 'Paid');
    return find && !find1;
  }

  checkVoid() {
    const dataarr: any = [];
    // tslint:disable-next-line:forin
    for (const id in this.mapOfCheckedId) {
      if (this.mapOfCheckedId[id] === true) {
        const obj1 = { legalCheckRegisterId: id };
        dataarr.push(obj1);
      }
    }
    const observ$ = this.clientService.postWithUrl({ url: 'LegalManagement/VoidLegalCheckRegister', dto: dataarr });
    observ$.subscribe((data) => {
      this.reSearch();
    });
  }

  checkAssign() {
    const dataarr: any = [];
    for (const id in this.mapOfCheckedId) {
      if (this.mapOfCheckedId[id] === true) {
        const obj1 = { legalCheckRegisterId: id, checkNumber: this.checkNumber, checkDate: this.checkDate };
        dataarr.push(obj1);
      }
    }
    const observ$ = this.clientService.postWithUrl({ url: 'LegalManagement/AssignLegalCheckRegister', dto: dataarr });
    observ$.subscribe((data) => {
      this.reSearch();
    });
  }
}