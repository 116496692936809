import { Component, OnInit } from '@angular/core';
import { ClientService } from '@services/client/client.service';
import { SelectionService } from '@store/selection/selection.service';

import { BaseComponent } from '@shared/components/base.component';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { EntityIdClientIdDTO } from '@models/entity/entity.dto';
import { EntityService } from '@services/client/entity.service';
import * as _ from 'lodash';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  templateUrl: './assigngroupcheck.component.html',
  styleUrls: ['./assigncheck.component.scss']
})

export class AssignGroupCheckComponent extends BaseComponent implements OnInit {
  editCache: { [key: string]: { edit: boolean; data: any } } = {};
  totAmt = 0; 
  entities; 
  selectedEntity;
  listOfData: any[] = [];
  lastid = 5; 
  dto = {};
  entityListWithClients: EntityIdClientIdDTO[] = [];
  selectedEntityClient: EntityIdClientIdDTO;
  assignGroupCheck: UntypedFormGroup

  constructor(
    private selectionService: SelectionService,
    private entityService: EntityService, 
    private clientService: ClientService, 
    private notification: NzNotificationService) {
    super();
  }

  selectedEntityChange(entityId) {
    if (!entityId) {
      return;
    }
    this.dto = {
      //'entityClientId': entityClient.entityClientId,
      'entityId': entityId,
      //  'clientId': entityClient.clientId,
      'isBillInfo': true
    };
  }

  addRow() {
    this.lastid = this.lastid + 1;
    this.listOfData = [...this.listOfData, { id: `${this.lastid}` }];
    this.updateEditCache();
    this.editCache[this.lastid].edit = true;
  }

  selected(e, id) {
    const opt = e.option.value;
    this.editCache[id].data.name = e.option.viewValue;
    this.editCache[id].data.employerCode = opt.clientNumber;
    this.editCache[id].data.ssNumber = opt.ssNumber;
    this.editCache[id].data.idNumber = opt.idNumber;
    this.editCache[id].data.externalId = opt.externalId;
    this.editCache[id].data.participantId = opt.participantId;
    this.editCache[id].data.entityClientId = opt.entityClientId;
    // tslint:disable-next-line:max-line-length
    //const url = 'payment/ParticipantOutstandingAndSuspense?entityId=' + this.selectedEntityClient.entityId  + '&entityClientId=' + this.selectedEntityClient.entityClientId + '&participantId=' + opt.participantId;
    const url = 'payment/ParticipantBillDetails?entityId=' + this.selectedEntity + '&entityClientId=' + opt.entityClientId + '&participantId=' + opt.participantId;
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.editCache[id].data.suspense = data.suspenseAmount;
      this.editCache[id].data.outstanding = data.outstandingAmount;
    });
  }

  reloadbalance() {
   
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: { ...this.listOfData[index] },
      edit: false
    };
  }

  delete(id): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.listOfData.splice(index, 1);
    this.listOfData = [...this.listOfData];
    this.updateEditCache();
    this.calcuTot();
  }

  saveEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    Object.assign(this.listOfData[index], this.editCache[id].data);
    this.editCache[id].edit = false;
    this.calcuTot();
  }

  calcuTot() {
    this.totAmt = 0;
    this.listOfData.forEach(item => {
      this.totAmt = this.totAmt + Number(item.amount);
    });
  }

  updateEditCache(): void {
    this.listOfData.forEach(item => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  submit() {
    if (this.assignGroupCheck.valid) {
      const groupCheckDetails: any = [];
      this.listOfData.forEach(item => {
      const obj = {
        employerCode: item.employerCode,
        checkAmount: item.amount,
      };
      groupCheckDetails.push(obj);
    });

    const url = 'Payment/GroupCheck';
    const dto = {
      //entityClientId: this.selectedEntityClient.entityClientId,
      entityId: this.selectedEntity,
      groupCheckDetails
    };
    
    const observ$ = this.clientService.postWithUrl({ url, dto }).pipe(untilComponentDestroyed(this));
    observ$.subscribe((res) => {
      this.notification.blank('Check Assigned Successfully', ' ');
      this.reloadbalance();
      this.listOfData = []
    },
      err => {
        this.notification.blank(err.error[0], ' ');
        this.reloadbalance();
      });
    } else {
      this.assignGroupCheck.markAllAsTouched()
    }  
  }

  ngOnInit() {
    //this.initializeDropdown();
    this.assignGroupCheck = new UntypedFormGroup({
      selectEntityId: new UntypedFormControl('', Validators.required),
      emplCodeInput: new UntypedFormControl(''),
      amountInput: new UntypedFormControl('')
    })
    const url = 'Entity/Entities';
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe((data) => {
      this.entities = data;
      if (data.length == 1) {
        this.selectedEntity = data[0].id;
        this.selectedEntityChange(this.selectedEntity);
      }
    });
  }
}
