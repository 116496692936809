import { CacheItem } from '@shared/cache/cache-item';

export function MemoryCacheFactory(): MemoryCacheService<CacheItem> {
  return new MemoryCacheService<CacheItem>('MEMORY-CACHE');
}

export class MemoryCacheService<T extends CacheItem> {
  private cache = new Map<object, T>();

  constructor(private cacheId: string) {}

  get(key: any): T | undefined {
    const value = this.cache.get(key);

    if (!value) {
      return undefined;
    }

    // update lastRead property
    value.lastRead = Date.now();

    return value;
  }

  set(key: any, value: T) {
    this.validateKey(key);
    this.cache.set(key, value);
  }

  delete(key: any): boolean {
    return this.cache.delete(key);
  }

  has(key: any): boolean {
    return this.cache.has(key);
  }

  clear() {
    this.cache.clear();
  }

  forEach(callbackfn: (value: T, key: any) => void, thisArg?: any): void {
    this.cache.forEach(callbackfn, thisArg);
  }

  private validateKey(key: any) {
    if (!key) {
      throw new Error('Cache "key" is required');
    }
  }
}
