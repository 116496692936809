import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import { RequestInfo } from 'angular-in-memory-web-api/interfaces';
import { MockHelperService } from '@mock/services/mock-helper.service';
import { HttpHelperService } from '@shared/api/http-helper.service';
import { HttpHeaders } from '@angular/common/http';
import { REQUEST_HEADERS } from '@shared/api/shared';

@Injectable()
export class MockInMemoryService implements InMemoryDbService {
  constructor(
    private httpHelper: HttpHelperService,
    private mockHelper: MockHelperService
  ) {
    this.mockHelper.registerUrls();
  }

  createDb() {
    return {};
  }

  // override GET requests
  get(reqInfo: RequestInfo) {
    if (this.shouldMockRequest(reqInfo)) {
      return this.mockHelper.handleRequest(reqInfo);
    }
    return undefined;
  }

  // override POST requests
  post(reqInfo: RequestInfo) {
    if (this.shouldMockRequest(reqInfo)) {
      return this.mockHelper.handleRequest(reqInfo);
    }
    return undefined;
  }

  // override DELETE requests
  delete(reqInfo: RequestInfo) {
    if (this.shouldMockRequest(reqInfo)) {
      return this.mockHelper.handleRequest(reqInfo);
    }
    return undefined;
  }

  private shouldMockRequest(reqInfo: RequestInfo) {
    const requestHeaders = reqInfo.req['headers'] as HttpHeaders;
    return this.httpHelper.shouldMockRequest(requestHeaders);
  }
}
