<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  <br />
  <div class="row no-gutters" style="margin: 0px">
    <div class="col-4">
      <span>Client</span>
      <ng-select (change)="selectedEntityClientChange($event)" [(ngModel)]="selectedEntityClient">
        <ng-option *ngFor="let item of entityListWithClients" [value]='item'>{{ getEntityClientDisplayText(item) }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-4 px-4">
      <span>Benefit and Vendor</span>
      <ng-select (change)="selectedBenefitChange($event)" [(ngModel)]="selectedBenefit">
        <ng-option *ngFor="let item of BenefitsArray" [value]='item'>{{ item.benefitEntityDescription +' - '
          +item.vendorName }}</ng-option>
      </ng-select>
    </div>
    <div class="col-4">
      <span>Policy Description / Policy code / Policy sub code</span>
      <ng-select (change)="selectedPolicyChange($event)" [(ngModel)]="selectedPolicy">
        <ng-option *ngFor="let item of PolicyArray" [value]='item'>{{ item.benefitEntityPolicyDescription +' - '+
          item.benefitEntityPolicyCode +' - '+ item.benefitEntityPolicySubCode }}</ng-option>
      </ng-select>
    </div>
  </div>

  <div class="row d-flex justify-content-end mb-3">
    <div class="add-button" style="margin: 20px 15px 0 0; color: #fff !important">
      <a mat-flat-button color="primary" href="javascript:void(0)" (click)="download()">Download &nbsp;<i
          class="fa fa-file-excel-o doc-icon" aria-hidden="true"></i></a>
    </div>
    <div class="add-button" style="margin: 20px 32px 0 0; color: #fff !important">
      <a mat-flat-button color="primary" (click)="addNewRates()">Add New Rates</a>
    </div>
  </div>
  <!-- <app-dynamic-main-grid pageSize=200 class="co-12" gridid='ratelist' [inputRows]='rows' [policyIds]='policyIds'></app-dynamic-main-grid> -->

  <nz-table #basicTable [nzData]="rows" nzSize="small" class="table table-hover" nzShowPagination
  nzShowSizeChanger
  *ngIf='gridConfigs!=null' class="col-12">
  <thead (nzSortChange)="sort($event)" nzSingleSort class="thead-dark">
    <tr>
      
      <ng-container *ngFor="let access of gridConfigs">

        <th nowrap="" nzShowSort [nzSortKey]="access.columnName"
          (nzFilterChange)="filter($event,access.columnName,access.filterType )" [nzFilterMultiple]="'false'"
          nzShowFilter [nzFilters]="access.filterList"
          *ngIf="access.sort!=null && access.filter!=null && access.filterType==='in'">
          {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
            ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
        </th>

        <th nowrap="" nzShowSort [nzSortKey]="access.columnName" nzCustomFilter
          *ngIf="access.sort!=null && access.filter!=null && access.filterType==='search'">
          {{access?.columnLabel}}&nbsp;
          <nz-filter-trigger [nzVisible]="visible" [nzActive]="'true'" [nzDropdownMenu]="menu">
            <i nz-icon [nzType]="'search'"></i>
          </nz-filter-trigger>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <div class="ant-table-filter-dropdown">
              <div class="search-box">
                <input type="text" nz-input placeholder={{access.columnLabel}} [(ngModel)]="searchValue" />
                <button nz-button nzSize="small" nzType="primary"
                  (click)="searchCust(access.columnName,access)" class="search-button">
                  Search
                </button>
                <button nz-button nzSize="small" (click)="resetCust(access.columnName)">Reset</button>
              </div>
            </div>
          </nz-dropdown-menu>
        </th>

        <th nowrap="" (nzFilterChange)="filter($event,access.columnName,access.filterType )"
          [nzFilterMultiple]="'false'" nzShowFilter [nzFilters]="access.filterList"
          *ngIf="access.sort==null && access.filter!=null  && access.filterType==='in'">
          {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
            ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
        </th>

        <th nowrap="" nzShowSort [nzSortKey]="access.columnName"
          *ngIf="access.sort!=null && access.filter==null">
          {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
            ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>         
        </th>

        <th nowrap="" *ngIf="access.sort==null &&  access.filter==null">
          {{access?.columnLabel}}&nbsp;<span class="glyphicon  glyphicon-chevron-down ng-hide"
            ng-class="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
        </th>

      </ng-container>
      <th><b>Action</b></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let item of basicTable.data">
      <tr class="ng-scope">
       
        <ng-container *ngFor="let access of gridConfigs">
          <ng-container [ngSwitch]="access.columnType">
            <td class="ng-binding" *ngSwitchCase="'text'">{{item[access.columnName]}}</td>
            <td class="ng-binding" *ngSwitchCase="'date'">{{item[access.columnName] | date:'M/d/yy'}}</td>
            <td class="ng-binding" *ngSwitchCase="'phone'">{{ item[access.columnName] | slice:0:3 }}-{{
              item[access.columnName] | slice:3:6 }}-{{ item[access.columnName] | slice:6:10 }}</td>
          </ng-container>
        </ng-container>
        <td>
          <a class="PatchFont" (click)=editRates(item)>Edit</a>
        </td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>

</div>
