import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { EntityService } from '@services/client/entity.service';
import {
  SetSelectedEntityAction,
  SetSelectedEntityByIdAction,
  SelectionActionTypes,
  SetSelectedEntityFailAction,
  SetSelectedClientByIdAction,
  SetSelectedClientAction,
  SetSelectedClientFailAction,
  ClearSelectedEntityAction,
  ClearSelectedClientAction
} from '@store/selection/selection.action';
import { ClientService } from '@services/client/client.service';

@Injectable()
export class SelectionEffects {
  constructor(
    private actions$: Actions,
    private entityService: EntityService,
    private clientService: ClientService
  ) {}

  @Effect()
  SetSelectedEntityById$ = this.actions$.pipe(
    ofType<SetSelectedEntityByIdAction>(
      SelectionActionTypes.SET_SELECTED_ENTITY_BY_ID
    ),
    switchMap(action => {
      // if (!action.payload.entityId) {
      //   return of(new ClearSelectedEntityAction());
      // }
      return this.entityService.getEntity({ ...action.payload }).pipe(
        switchMap(entity => of(new SetSelectedEntityAction({ entity }))),
        catchError(error => of(new SetSelectedEntityFailAction({ error })))
      );
    })
  );

  @Effect()
  SetSelectedClientById$ = this.actions$.pipe(
    ofType<SetSelectedClientByIdAction>(
      SelectionActionTypes.SET_SELECTED_CLIENT_BY_ID
    ),
    switchMap(action => {
      // if (!action.payload.clientId) {
      //   return of(new ClearSelectedClientAction());
      // }
      return this.clientService.getClient({ ...action.payload }).pipe(
        switchMap(client => of(new SetSelectedClientAction({ client }))),
        catchError(error => of(new SetSelectedClientFailAction({ error })))
      );
    })
  );
}
