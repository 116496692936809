import { Component, OnInit, Input, Output, EventEmitter, ApplicationRef, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { GetMemberUrlAction } from '@store/main/member/member.actions';
import { getMemberState, isMemberStateMutating } from '@store/main/member/member.selector';
import { MemberState } from '@store/main/member/member.state';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { MatDialog } from '@angular/material/dialog';
import { DynamicDialogFormComponent } from '@shared/components/dynamic_form/dynamic-form.component';

@Component({
  selector: 'app-dependentbeneficiaries',
  templateUrl: './dependent.component.html',
  encapsulation: ViewEncapsulation.None
})

export class DynamicPlanDependentBeneficiariesComponent extends BaseComponent implements OnInit {

  @Input()
  dependentRules;
  @Input()
  benefitEntityPolicy;
  @Input()
  set entityId(entityId) {
    if (entityId != null) {
      this.EntityId = entityId;
    }
  }

  @Input()
  set clientId(clientId) {
    if (clientId != null) {
      this.ClientId = clientId;
    }
  }

  @Input()
  set memberId(memberId) {
    if (memberId != null) {
      this.MemberId = memberId;
    }
  }

  @Input()
  tierName;
  @Input()
  enrolled;
  @Input()
  beneficiaries;
  @Output() changeDep = new EventEmitter<any>();
  EntityId: string;
  ClientId: string;
  MemberId: string;

  ngOnInit() {
    // this.enrolled.length = 0;
  }

  onChangeDep() {
    this.changeDep.emit(event);
  }

  onChangeBen() {
    // this.changeDep.emit(event);
  }

}

@Component({
  selector: 'app-plan-dependent',
  templateUrl: './dependentonly.component.html',
  encapsulation: ViewEncapsulation.None
})

export class DynamicPlanDependentComponent extends BaseComponent implements OnInit {
  EntityId: string; ClientId: string; MemberId: string;
  memberState?: MemberState;
  rows;
  @Input()
  dependentRules;
  @Input()
  set entityId(entityId) {
    if (entityId != null) {
      this.EntityId = entityId;
    }
  }

  @Input()
  set clientId(clientId) {
    if (clientId != null) {
      this.ClientId = clientId;
    }
  }

  @Input()
  set memberId(memberId) {
    if (memberId != null) {
      this.MemberId = memberId;
    }
  }

  @Input()
  tierName;
  @Input()
  enrolled;
  @Output() changeDep = new EventEmitter<any>();
  enrolledArr;
  test = 0;

  constructor(
    public changeDetectorRef: ChangeDetectorRef, 
    public applicationRef: ApplicationRef, 
    public dialog: MatDialog, 
    private route: ActivatedRoute, 
    private routeHelper: RouteHelperService, 
    protected clientService: ClientService, 
    private store: Store<AppState>, 
    protected notification: NzNotificationService
    ) {
    super();
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.ClientId = this.routeHelper.getClientId(this.route);
    this.MemberId = this.routeHelper.getMemberId(this.route);
  }

  findnumofdep(type) {
    let num = 0;
    this.enrolled.forEach(element => {
      if (element.relationShip === type) {
        num++;
      }
    });
    return num;
  }

  dependentValidation() {
    const msgs: any = [];
    if (this.dependentRules != null) {
      this.dependentRules.forEach(element => {
        if (element.minAge > 0) {
          const f = this.enrolled.find(en => en.age < element.minAge && en.relationShip === element.relationshipCode);
          if (f != null) {
            msgs.push(' min age must be  ' + element.minAge + ' for dependents(' + element.relationshipCode + ')');
          }
        }
        if (element.maxAge > 0 && element.excludeDisabled == false) {
          const f = this.enrolled.find(en => en.age > element.maxAge && en.relationShip === element.relationshipCode);
          if (f != null) {
            msgs.push(' max age must be  ' + element.maxAge + ' for dependents(' + element.relationshipCode + ')');
          }
        }
        if (element.minNumOfDependents > 0) {
          if (this.findnumofdep(element.relationshipCode) < element.minNumOfDependents) {
            msgs.push(' at least ' + element.minNumOfDependents + '(' + element.relationshipCode + ') dependent(s) must be selected ');
          }
        }
      });
    }
    return msgs;
  }

  ngOnInit() {
    this.store.dispatch(new GetMemberUrlAction({
      Url: 'Participant/:EntityId/:ParticipantId/DependentLists', Prop: 'dependents',
      EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId
    }));
    this.subscribeMemberState();
  }

  calcuAge(birthdate) {
    const today = new Date();
    const yyyy = today.getFullYear();
    const byyyy = birthdate.getFullYear();
    return yyyy - byyyy;
  }

  enrollDependent($event, item) {
    if (this.enrolled == null) {
      this.enrolled = [];
    }
    if ($event === true) {
      const f = this.enrolled.find(en => en.dependentId === item.dependentId);
      if (f == null) {
        this.enrolled.push({ dependentId: item.dependentId, relationShip: item.relationship, age: this.calcuAge(item.birthDate) });
      }
    } else {
      const index = this.enrolled.findIndex(en => en.dependentId === item.dependentId);
      if (index !== -1) {
        this.enrolled.splice(index, 1);
      }
    }
    this.changeDep.emit();
  }

  addNew() {
    const dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      data: {
        addNew: true,
        entityId: this.EntityId,
        clientId: this.ClientId,
        participantId: this.MemberId,
        item: {},
        formid: 'dependents'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.loadMemberData();
      this.store.dispatch(new GetMemberUrlAction({
        Url: 'Participant/:EntityId/:ParticipantId/DependentLists', Prop: 'dependents',
        EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId
      }));
    });
  }

  edit(item) {
    let dialogRef;
    dialogRef = this.dialog.open(DynamicDialogFormComponent, {
      width: '90%',
      // height: '90%',
      data: {
        addNew: false,
        entityId: this.EntityId,
        clientId: this.ClientId,
        participantId: this.MemberId,
        item: { ...item, EntityId: this.EntityId },
        formid: 'dependents'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.loadMemberData();
      this.store.dispatch(new GetMemberUrlAction({
        Url: 'Participant/:EntityId/:ParticipantId/DependentLists', Prop: 'dependents',
        EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId
      }));
    });
  }

  onDelete(item) {
    item.entityId = this.EntityId;
    item.participantId = this.MemberId;
    const url = 'Dependents/Delete';
    const observ$ = this.clientService.postWithUrl({ url, dto: item }).pipe(untilComponentDestroyed(this));
    observ$.subscribe(res => {
      this.store.dispatch(new GetMemberUrlAction({
        Url: 'Participant/:EntityId/:ParticipantId/DependentLists', Prop: 'beneficiaries',
        EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId
      }))
      this.notification.blank(res, ' ');
    }, err => {
      if (err != null && err.error.length > 0) {
        this.notification.blank(err.error[0], ' ');
      } else {
        this.notification.blank(err, ' ');
      }
    });
  }

  private subscribeMemberState() {
    this.store
      .select(getMemberState)
      .pipe(untilComponentDestroyed(this))
      .subscribe(x => this.memberStateChange(x));
  }

  selectEnrolled(item) {
    if (this.enrolled == null) {
      return false;
    }
    const f = this.enrolled.find(en => en.dependentId === item.dependentId);
    if (f == null) {
      return false;
    }
    return true;
  }

  private filter(rows) {
    const ret: any = [];
    rows.forEach((element: any) => {
      // add info for enrolled
      const f = this.enrolled.find(en => en.dependentId === element.dependentId);
      if (f != null) {
        f.age = this.calcuAge(element.birthDate);
      }
      const obj = Object.assign({}, element);
      const ttt = this.tierName;
      switch (this.tierName) {
        case 'Child(ren) Only':
        case 'Child Only':
        case 'Member Plus Child(ren) Only':
          if (element.relationship === 'C') {
            ret.push(obj);
          }
          break;
        case 'Member Plus Spouse Only':
        case 'Spouse Only':
          if (element.relationship === 'S') {
            ret.push(obj);
          }
          break;
        default:
          ret.push(obj);
      }
    });
    return ret;
  }

  private memberStateChange(state: MemberState) {
    if (!isMemberStateMutating(state)) {
      this.memberState = state;
      if (this.memberState && this.memberState.member) {
        if (this.memberState.member) {
          const pp = 'dependents';
          if (this.memberState.member['dependents'] != null) {
            this.rows = this.memberState.member['dependents']['dependentInformationDTO'];
            if (this.rows) {
              this.rows = this.filter(this.rows);
              this.changeDetectorRef.detectChanges();
            }
          }
        }
      }
    }

  }

}
