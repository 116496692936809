import * as _ from 'lodash';

export const ENTITY_CODE_TO_ID = {
  apwu: '68bc62e6-dd96-4428-91e8-efe285bd23a0',
  horace: '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d',
  tenet: '8ea15cba-c0f0-4ed0-86b8-d78389372f5f',
  cobra: 'd6a2ff7e-bba0-48fa-9396-a6b202631669',
  enterprise: '22535064-288d-43db-ab8d-6bd5d306bece',
  texas: '9808f856-f047-4843-bec8-277e414fc649',
  Anthem: 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028',
  TMAIT: '9808f856-f047-4843-bec8-277e414fc649',
  Essex: '3b7805a6-10fa-48b5-b0ee-ea5365258341',
  FireFighters: '9256dc20-03ae-469f-a45f-f66c5122f9ca',
  LEEBA: 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b'
};

export const CLIENT_ROUTE_SEGMENT = {
  ///
  UNUM: '31e56172-ac96-487c-a6b3-d5847954f3b2',
  UTC: 'ac586073-69d6-41bf-875c-eb754f750457',
  AGENT: 'fd6fa6d7-3684-45e9-beb2-44c677449b7b',
  TENET: 'c935affd-0e7c-4b2c-ae8b-1c9d0e53905b',
  Coronado:'f9d5f5e4-9e28-4a23-9cc3-9bb9d81d15e4',
  apwu: ENTITY_CODE_TO_ID.apwu,
  horace: ENTITY_CODE_TO_ID.horace,
  tenet: ENTITY_CODE_TO_ID.tenet,
  cobra: ENTITY_CODE_TO_ID.cobra,
  texas: ENTITY_CODE_TO_ID.texas,
  Anthem: ENTITY_CODE_TO_ID.Anthem,
  TMAIT: ENTITY_CODE_TO_ID.TMAIT,
  Essex: ENTITY_CODE_TO_ID.Essex,
  enterprise: ENTITY_CODE_TO_ID.enterprise,
  FireFighters: ENTITY_CODE_TO_ID.FireFighters,
  LEEBA: ENTITY_CODE_TO_ID.LEEBA,
  ///
  memberLookup: 'member-lookup',
  members: 'members',
  member: 'member',
  dashboard: 'dashboard'
};

export const MEMBER_ROUTE_SEGMENT = {
  dashboard: 'dashboard',
  addMember: 'add-member',
  contacts: 'contacts',
  profile: 'profile',
  benefits: 'benefits',
  dependents: 'dependents',
  beneficiaries: 'beneficiaries',
  refund: 'refund/refund',
  adjustments: 'adjustments',
  deductions: 'deductions',
  bank: 'bank',
  letters: 'adjustments',
  callLog: 'call-log',
  ledger: 'ledger',
  documents: 'documents',
  dynamicGrid: 'dynamicgrid',
  dynamicForm: 'dynamicform',
  dynamicDashbord: 'dashboard',
  dynamicBenefit: 'benefits',
  dynamicBenefitSummary: 'benefitssummary',
  dynamicReinstate: 'reinstate',
  dynamicBenefitnew: 'benefitsnew'
};
