import { MockHelperService } from '@mock/services/mock-helper.service';
import { MemberListMockHandler } from '@mock/mock-data/member-list.mock.handler';
/* import {
  MemberProfileMockHandler,
  MemberBenefitListMockHandler
} from '@mock/mock-data/member.mock.handler'; */
import {
  MemberCallLogListMockHandler,
  MemberCallLogAddMockHandler
} from '@mock/mock-data/member/member.mock.handler';

export function registerMemberUrls(mock: MockHelperService) {
  mock.addPostHandler('Participant-Lookup', new MemberListMockHandler());
 /*  mock.addGetHandler(
    'Participant/:entityId/:participantId/Profile',
    new MemberProfileMockHandler()
  );
  mock.addGetHandler(
    'Enrollment/BenefitVendorBenefitEntities',
    new MemberBenefitListMockHandler()
  );
 */
  mock.addPostHandler('CallLogList', new MemberCallLogListMockHandler());
  mock.addPostHandler('AddCallLog', new MemberCallLogAddMockHandler());
}
