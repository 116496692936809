<div *ngFor="let formConfig of formConfigs " >

    <div *ngFor="let item of (formConfig| keyvalue)"  >
        <div >
            <div > <h5>{{item.key}} </h5></div>
             <ng-container *ngFor="let access of item.value"  >
                
                <ng-container [ngSwitch]="access.controlType" >
                    <span *ngSwitchCase="'label'" [ngStyle]="access.style"
                    >
                    {{access.controlLabel}} 
                    </span>
                    <span *ngSwitchCase="'spantext'" [ngStyle]="access.style"
                    >
                    {{access.controlLabel}}: {{_formValue[access.controlName]}}
                    </span>
                    
                    <div *ngSwitchCase="'labeldiv'" [ngStyle]="access.style"
                    >
                    {{access.controlLabel}} 
                    </div>
                    <br *ngSwitchCase="'br'"  />
                    <div *ngSwitchCase="'divtext'" [ngStyle]="access.style"
                    >
                    {{access.controlLabel}}: {{_formValue[access.controlName]}}
                    </div>
                    <div *ngSwitchCase="'divtextnolabel'" [ngStyle]="access.style"
                    >
                     {{_formValue[access.controlName]}}
                    </div>
                    <div *ngSwitchCase="'divdate'" [ngStyle]="access.style"
                    >
                    {{access.controlLabel}}: {{_formValue[access.controlName]  | date}}
                    </div>
                    <div *ngSwitchCase="'pr'" class="page-break"></div>
                </ng-container>
            </ng-container>
    </div>
</div>
