<!-- Login page for LEEBA -->
<div *ngIf="logintype == 'portalApp' || logintype == 'user'">
  <div class="w-100">
    <img style="width: 100%; position: fixed; object-fit: fit;" src="{{bgImage}}" alt="">
  </div>
  <div class="row container loginscreen" style="padding: 0px !important; margin: 0 auto;">
    <div class="col-xl-1 col-lg-1"></div>
    <div data-ng-include="'views/RegisterTerms.html'"></div>
    <div style="padding: 10% 0px 0px 0px;" class="row col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 LoginPanel">
      <div class="row" style="margin: 0px; background: rgba(255, 255, 255, 0.9)">
        <div style="display: block; margin: 0px 400px;">
          <img *ngIf="logintype == 'user' || logintype == 'portalApp'" style="height: 180px;" src="{{logoImage}}"
            alt="logo">
        </div>
        <div style="margin: 0px auto;">
          <h2 *ngIf="logintype == 'portalApp' || logintype == 'user'" [style.color]="primaryColor"
            style="display: block; font-weight: bold;">{{welcomeText}}</h2>
        </div>
        <div class="row">
          <div style="text-align: center; padding-top: 30px">
          </div>
          <form class="col-lg-6 col-md-6 col-sm-6 col-xs-12" [formGroup]="form" (submit)="onSubmit()"
            autocomplete="off">
            <div style="border-radius: 6px; margin: 0 25px;">
              <div style="padding: 3% 3% 3% 3%; margin: 0px auto" class="row">
                <div><span style="font-family: Calibri; font-size: 22px">Log In To Your Account</span>
                </div>
                <br />
                <div>
                  <div class="row no-gutters"
                    style="margin: 0px 0px 0px -10px; padding: 10px; background: rgba(255, 255, 255, 0.95)">
                    <div class="col-12"><span style="font-family: Calibri; font-size: 14px">Username</span> <span
                        style="cursor: pointer; font-family: Calibri; font-size: 14px" class="FontColor pull-right"
                        routerLink="/retrieve_username">
                        <u>Forgot your username?</u></span></div>
                    <input tabindex="1" formControlName="username" type="text"
                      class="form-control input-sm RegisterClass" required>
                    <div class="col-12" style="padding-top: 5px"><span
                        style="font-family: Calibri; font-size: 14px">Password</span>
                      <span style="cursor: pointer; font-family: Calibri; font-size: 14px; text-align: right;"
                        class="FontColor pull-right" routerLink="/reset_password"><u>Forgot your
                          password?</u></span>
                    </div>
                    <div class="row col-lg-12" style="margin: 0px auto; padding: 0px">
                      <input tabindex="2" type="password" formControlName="password"
                        class="form-control input-sm RegisterClass" id="userpassword" required>
                    </div>
                  </div>
                  <div>
                    <div style="display: inline; padding-top: 3px">
                      <br />
                      <button [style.background-color]="primaryColor" tabindex="3" mat-flat-button type="submit"
                        style="color: #fff; border: 2px solid; float: left; margin-bottom: 15px; width: 100%; width: 150px; border: none; font-size: 18px;">
                        <i class="fa fa-lock"></i>&nbsp;Log In
                      </button>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
              <br />
            </div>
          </form>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="height: 100%; border-right: 60px;">
            <div id="divIntroduction" style="padding-right: 10px; padding-left: 10%; background-size: cover;">
              <div style="background-color: transparent;">
                <div style="text-align: center;">
                  <div>
                    <div style="text-align: left;"><span style="font-family: Calibri; font-size: 22px;">Sign up for an
                        account</span></div>
                    <br />
                    <button [style.background-color]="primaryColor" routerLink="/register" mat-flat-button type="button"
                      style="font-weight: bold; float: left; border: none;"><span
                        style="font-family: Calibri; font-size: 18px; color: #fff">CREATE AN ACCOUNT</span></button>
                    <br />
                  </div>
                  <p style="text-align: left;font-size:14px;">
                    <br>
                    <br>
                    Questions on enrollment or program offerings?
                    <br>
                    <b style="font-family: Calibri; font-size: 18px">Call: {{phoneNum}}</b>
                  </p>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="SpeciallinksDiv" style="position: absolute; bottom: 10px; right: 10px;">
          <a *ngIf="isTermsLink" class="FontColor" style="cursor: pointer; font-family: Calibri; font-size: 14px"
            href="https://cdn.abcsys.com/Documents/Legal/terms.html" target="_blank"><u>Terms of Use
            </u></a><span class="FontColor">&nbsp; | &nbsp;</span>
          <a *ngIf="isPrivacyLink" class="FontColor" style="cursor: pointer; font-family: Calibri; font-size: 14px"
            href="https://cdn.abcsys.com/Documents/Legal/privacy.html" target="_blank"><u>Privacy
              Policy</u></a><span class="FontColor"> &nbsp; </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Login page for Enterprise admin -->

<div *ngIf="logintype == 'enterpriseadmin'">
  <div class="main-login">
    <div class="shape1"></div>
    <div class="shape2"></div>
    <div class="shape3"></div>
    <div class="form-container">
      <form class="login-form" [formGroup]="form" (submit)="onSubmit()">
        <mat-card>
          <div class="d-flex justify-content-center m-3">
            <h4 style="display: block; color: #47c5ca; font-weight: bold; text-align: center;">Added Benefits <br>
              Administrative Console </h4>
          </div>
          <mat-card-content>
            <p>

              <mat-form-field>
                <span style="position: absolute;top:8px; left:5px;font-size: 18px; color: #acacac;"><i
                    class="fa fa-user" aria-hidden="true"></i></span>
                <input matInput autocomplete="off"
                  placeholder="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type your username"
                  formControlName="username" style="padding-left: 30px;" required />
              </mat-form-field>
            </p>
            <p>
              <mat-form-field>
                <span style="position: absolute;top:8px; left:5px;font-size: 18px;  color: #acacac;"><i
                    class="fa fa-lock" aria-hidden="true"></i></span>
                <input matInput autocomplete="off" type="password"
                  placeholder="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type your password"
                  formControlName="password" style="padding-left: 30px;" required />
              </mat-form-field>
            </p>
          </mat-card-content>
          <mat-card-actions>
            <div *ngIf="authState as auth">
              <div *ngIf="!auth.loading && (auth.error || !auth.success)">
                <button mat-flat-button type="submit" color="primary">LOGIN</button>
              </div>
              <ng-container *ngIf="auth.loading && !auth.error">
                <p class="login__loading">Signing in...</p>
              </ng-container>
              <ng-container *ngIf="auth.error">
                <div class="login__error">
                  <p>{{auth.error.Message}}</p>
                </div>
              </ng-container>
            </div>
          </mat-card-actions>
        </mat-card>
      </form>
    </div>
  </div>

  <!-- <div>
    <img class="bg-image en-admin" src="https://cdn.abcsys.com/Library/Eniac/ep_mock_bg.jpg">
  </div>
  <div class="row container loginscreen" style="padding: 0px !important; margin: 0 auto;">
    <div class="col-xl-3 col-lg-3"></div>
    <div data-ng-include="'views/RegisterTerms.html'"></div>
    <div style="padding: 10% 0px 0px 0px;"
      class="row col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="row" style="background: rgba(255, 255, 255, 0.9)">
        <div style="margin: 15px auto;">
          <h2 style="display: block; color: #47c5ca; font-weight: bold; text-align: center;">Added Benefits <br> Administrative Console </h2>
        </div>
        <div class="row">
          <div style="text-align: center; padding-top: 30px">
          </div>
          <form class="col-12" [formGroup]="form" (submit)="onSubmit()" autocomplete="off">
            <div style="border-radius: 6px; margin: 0 25px;">
              <div style="padding: 3% 3% 3% 3%; margin: 0px auto" class="row">
                <div><span style="font-family: Calibri; font-size: 22px">Log In To Your Account</span>
                </div>
                <br />
                <div>
                  <div class="row no-gutters"
                    style="margin: 0px 0px 0px -10px; padding: 10px; background: rgba(255, 255, 255, 0.95)">
                    <div class="col-12">
                      <span style="font-family: Calibri; font-size: 14px">Username</span> 
                      </div>
                    <input tabindex="1" maxlength="22" formControlName="username" type="text"
                      class="form-control input-sm RegisterClass" required>
                    <div class="col-12" style="padding-top: 5px"><span
                        style="font-family: Calibri; font-size: 14px">Password</span>
                      
                    </div>
                    <div class="row col-lg-12" style="margin: 0px auto; padding: 0px">
                      <input tabindex="2" maxlength="22" type="password" formControlName="password"
                        class="form-control input-sm RegisterClass" id="userpassword" required>
                    </div>
                  </div>
                  <div>
                    <div style="display: inline; padding-top: 3px">

                      <button mat-flat-button type="submit" color="primary"
                        style="float: left; margin: 15px 0; width: 200px; border: none; font-size: 18px;">
                        <i class="fa fa-lock"></i>&nbsp;Log In
                      </button>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
              <br />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> -->

</div>