import { Action } from '@ngrx/store';
import { EntityDTO } from '@models/entity/entity.dto';
import { ClientDTO } from '@models/client/client.dto';

export enum SelectionActionTypes {
  SET_SELECTED_ENTITY_BY_ID = '[SELECTION] SET_SELECTED_ENTITY_BY_ID',
  SET_SELECTED_ENTITY = '[SELECTION] SET_SELECTED_ENTITY',
  CLEAR_SELECTED_ENTITY = '[SELECTION] CLEAR_SELECTED_ENTITY',
  SET_SELECTED_ENTITY_FAIL = '[SELECTION] SET_SELECTED_ENTITY_FAIL',
  ///
  SET_SELECTED_CLIENT_BY_ID = '[SELECTION] SET_SELECTED_CLIENT_BY_ID',
  SET_SELECTED_CLIENT = '[SELECTION] SET_SELECTED_CLIENT',
  CLEAR_SELECTED_CLIENT = '[SELECTION] CLEAR_SELECTED_CLIENT',
  SET_SELECTED_CLIENT_FAIL = '[SELECTION] SET_SELECTED_CLIENT_FAIL'
}

export class SetSelectedEntityByIdAction implements Action {
  readonly type = SelectionActionTypes.SET_SELECTED_ENTITY_BY_ID;
  constructor(public payload: { entityId: string }) {}
}
export class SetSelectedEntityAction implements Action {
  readonly type = SelectionActionTypes.SET_SELECTED_ENTITY;
  constructor(public payload: { entity: EntityDTO }) {}
}
export class ClearSelectedEntityAction implements Action {
  readonly type = SelectionActionTypes.CLEAR_SELECTED_ENTITY;
}
export class SetSelectedEntityFailAction implements Action {
  readonly type = SelectionActionTypes.SET_SELECTED_ENTITY_FAIL;
  constructor(public payload: { error: any }) {}
}

export class SetSelectedClientByIdAction implements Action {
  readonly type = SelectionActionTypes.SET_SELECTED_CLIENT_BY_ID;
  constructor(public payload: { entityClientId: string}) {}
}
export class SetSelectedClientAction implements Action {
  readonly type = SelectionActionTypes.SET_SELECTED_CLIENT;
  constructor(public payload: { client: ClientDTO }) {}
}
export class ClearSelectedClientAction implements Action {
  readonly type = SelectionActionTypes.CLEAR_SELECTED_CLIENT;
}
export class SetSelectedClientFailAction implements Action {
  readonly type = SelectionActionTypes.SET_SELECTED_CLIENT_FAIL;
  constructor(public payload: { error: any }) {}
}

export type AllSelectionActions =
  | SetSelectedEntityByIdAction
  | SetSelectedEntityAction
  | ClearSelectedEntityAction
  | SetSelectedEntityFailAction
  | SetSelectedClientAction
  | ClearSelectedClientAction;
