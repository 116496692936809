import { Component, OnInit, Input,ViewChild } from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import { ExcelService } from '@services/excel/excel.service';

@Component({
  selector: 'app-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.scss'], 
})
export class BenefitComponent implements OnInit {
  constructor(protected excelService: ExcelService) {}
  @Input() 
  set benefitData(benefitData){
      this._benefitData=benefitData;
      if(benefitData.length>0){
        this.currentPolicy=benefitData[0];
        this.filterPolicy=this.currentPolicy;
      }
  }
  _benefitData;
  @Input() 
  set filterKeyChange(filterKeyChange){
    this._filterKeyChange=filterKeyChange;
    if(filterKeyChange.length>=3){
      this.filter(filterKeyChange);
    }else if(filterKeyChange.length==0){
      this.filterPolicy=this.currentPolicy;
    }
  }
  _filterKeyChange;
  currentPolicy;
  filterPolicy;
  currentPlans=[];
  mapOfExpandData: { [key: string]: boolean } = {};
  @Input()
  view='card';
  filter(filter:any){
      this.filterPolicy={};
      for (const key in this.currentPolicy) {
        if (key!='Benefit Name'){
          let value=this.currentPolicy[key];
          for (const key1 in value[0]) {
            if (key1.toLowerCase().includes(filter.toLowerCase())){
              this.filterPolicy[key]=value;
              break;
            }
          }
          
        }
     
      }
  }

  selPolicy(name){
    for (const item of this._benefitData) {
      if(item['Benefit Name']+item['Vendor Name']==name){
        this.currentPolicy=item;
        this.filterPolicy=this.currentPolicy;
      }
     
    }

  }

  ngOnInit(): void {
  }
  expandAll(){
    this.filterPolicy;
    for (const [key, value] of Object.entries( this.filterPolicy)) {
      console.log(`${key}: ${value}`);
      if(key!='Benefit Name' && (key!='Vendor Name'))
        this.mapOfExpandData[key]=true;
    }
    
  //   this.listOfData1.forEach(data=>{
  //     this.mapOfExpandData[data.Id]=true;
  // })
  }
  collapseAll(){
    this.mapOfExpandData={};
  }
  download(){
    const excelrows: any = [];

    for (const data in this.filterPolicy) {
      if (data != 'Benefit Name' && data != 'Vendor Name' && data != 'Benefits') {
        let obj = {};
        obj['.'] = data;
        obj['..'] = '';
        obj['...'] = '';
        excelrows.push(obj);
        for (const key in this.filterPolicy[data][0]) {
          obj = {};
          obj['..'] = key;
          obj['...'] = this.filterPolicy[data][0][key];
          excelrows.push(obj);
        }
        obj = {};
        obj['.'] = '';
        obj['..'] = '';
        obj['...'] = '';

        excelrows.push(obj);
      }
    }

    this.excelService.exportAsExcelFileNoHeader(excelrows, 'benefit');
  }
}