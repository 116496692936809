
<div *ngIf='EntityId!="ac586073-69d6-41bf-875c-eb754f750457"'>
  <br />
  If you don’t see your beneficiary listed, please <a class="default-font-Color" href="javascript:void(0)"
  (click)="addNew()"><u>click</u></a> here to add beneficiary.
  <br /><br />
</div>


<table>
  <tr *ngFor="let item of rows">
    <td>
      <span class="default-font-Color-dark"> {{item.firstName}} {{item.lastName}} {{item.otherName}} </span>
    </td>
    <td>
      <select class="form-control" style="height: 32px; font-size: 12px" [(ngModel)]="item.beneficiaryTypeId"
        (ngModelChange)="percentChange(item)">
        <option>
        </option>
        <option [value]="'A93D0A6F-3810-4BD4-821C-0B33C6260B31'">
          Primary
        </option>
        <option [value]="'4532D28C-783B-4334-9966-814117B770EC'">
          Contingent
        </option>
      </select>
    </td>
    <td>
      <input style="height: 32px;" type="number" min="0" max="100" class="form-control" [(ngModel)]="item.percentage" (ngModelChange)="percentChange(item)">

      <!-- <nz-input-number [(ngModel)]="item.percentage" [nzMin]="1" [nzMax]="100" [nzStep]="1"
        [nzFormatter]="formatterPercent" [nzParser]="parserPercent" (ngModelChange)="percentChange(item)">
      </nz-input-number> &nbsp; -->
    </td>
    <td> % &nbsp;&nbsp;</td>
    <td>
      <span style="color:gray">
        <u>
          <a (click)="edit(item)">Edit</a>
        </u>&nbsp;<u>
          <a nz-popconfirm
            nzPopconfirmTitle="Are you sure delete this ?" 
            (nzOnConfirm)="onDelete(item)">delete</a>
        </u>
      </span>
    </td>
  </tr>
</table>