<div *ngIf='logintype === "admin" || undefined' class="app-navbar">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" [routerLink]="['/main/user-dashboard']">
      <img style="width: 60px;margin-left: 15px "src="{{logo}}" alt="">
      <!-- <span class="logo">AEBSys</span> -->
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01"
      aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarColor01">
      <ul class="navbar-nav mr-auto">

        <li class="nav-item active" *ngIf='userName.toLowerCase()!=="legalclaim"'>
          <a class="nav-link" onclick="$('.navbar-collapse').collapse('hide');"
            [routerLink]="['/main/user-dashboard']">Home <span class="sr-only">(current)</span></a>
        </li>

        <li class="nav-item dropdown"
          *ngIf='userName.toLowerCase()!=="legalclaim" && entities!=null && entities.length!=0'>
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">Cases</a>
          <div class="dropdown-menu border-radius0" aria-labelledby="navbarDropdown"
            *ngIf='entityList==null || entityList==[]'>
            <ng-container *ngFor="let item of entities">
              <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
                [routerLink]="['/main/clients', item.id]">
                {{item.entityName}}
              </a>
            </ng-container>
          </div>
          <div class="dropdown-menu border-radius0" aria-labelledby="navbarDropdown" *ngIf='entityList!=null '>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/clients', CLIENT_ROUTE_SEGMENT.texas]">
              {{KNOWN_ENTITY_NAME.texas}}
            </a>
          </div>
        </li>

        <li class="nav-item dropdown" *ngIf='tools==true '>
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">Tools</a>
          <div class="dropdown-menu border-radius0" aria-labelledby="navbarDropdown">
            <!-- <a   onclick="$('.navbar-collapse').collapse('hide');" [routerLink]="['/main/benefits']" class="dropdown-item" href="#">Benefits - Need Review!</a>
              <a   onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item" >File and Schedules - Coming Soon!</a> -->
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/jeditor']">UI Configuration</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/report/report']">Report Configuration</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/file/b2bfile']">Inbound/Outbound Layouts</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/filesetup/filesetup/']">File Dashboard Setup</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/effectivedaterule/']">Effective Date Rules</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/reductions']">Reductions Rule</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/coverageTermination']">Coverage Termination</a>
          </div>
        </li>

        <li class="nav-item dropdown" *ngIf='legal==true'>
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Legal Claim
          </a>
          <div class="dropdown-menu border-radius0" aria-labelledby="navbarDropdown">
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/legal/legal']">Legal Case Management</a>
            <!--               <a   onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item" [routerLink]="['/main/claimpayment/claimpayment']">Check Register/Check Void/Check Reissue</a> -->
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/attorney/attorney']">Attorney Management</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/clients/68bc62e6-dd96-4428-91e8-efe285bd23a0/reportsgroup/Claims']">Reports</a>
          </div>
        </li>

        <li class="nav-item dropdown"
          *ngIf='userName.toLowerCase()!=="legalclaim" && entities!=null && entities.length!=0'>
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">Operations</a>
          <div class="dropdown-menu border-radius0" aria-labelledby="navbarDropdown">
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/quickenrollment']" *ngIf='qenroll==true'>Quick Enrollment</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/assigncheck']">Assign Member Check</a>
              <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/assigngroupcheck']">Assign Group Check</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/suspense-management']">Suspense Management</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/clients/member-lookup']">Member Lookup</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/file-list']">B2B Dashboard</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/billcreation']">Bill Creation</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/rate-list']">Rate Maintenance</a>
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/ratesupload']">Rates Upload</a>
            <!-- <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/clients/00000000-0000-0000-0000-000000000000/reports']">Global Reports</a> -->
          </div>
        </li>
        <li class="nav-item dropdown" *ngIf='admin'>
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">Admin</a>
          <div class="dropdown-menu border-radius0" aria-labelledby="navbarDropdown">
            <a onclick="$('.navbar-collapse').collapse('hide');" class="dropdown-item"
              [routerLink]="['/main/users/users']">User Management</a>
          </div>
        </li>
        <li class="nav-item" *ngIf='userName.toLowerCase()!=="legalclaim"'>
          <a class="nav-link" onclick="$('.navbar-collapse').collapse('hide');">About</a>
        </li>

      </ul>
      <form class="form-inline">
        <!-- <input class="form-control mr-sm-2"
               type="search"
               placeholder="Search"
               aria-label="Search">
        <button class="btn btn-outline-info my-2 my-sm-0"
                type="submit">Search</button>&nbsp;&nbsp; -->
        <button class="my-2 my-sm-0 off-btn" mat-flat-button type="button" (click)="logout()">Log Out</button>
      </form>
    </div>
  </nav>
</div>

<!-- Login Type - User Navbar 
<nav *ngIf='logintype=="user"' class="navbar fixed-top navbar-dark" style="background-color: #47c5ca;">


  <div class="row d-flex justify-content-between align-items-center" style="width: 100%;">
    <div class="p-2"><a class="navbar-brand user-logo" style="margin-right: 10rem;" routerLink="/main/user-dashboard">
        <h3 style=" color: #fff; font-weight: bold;">
          <img style="height: 75px;" src="https://cdn.abcsys.com/Library/Eniac/leeba_logo.png" alt="LEEBA logo">
          Member Benefits Program
        </h3>
      </a></div>
    <div class="row p-2">
      <div><a class="nav-link active" aria-current="page" [routerLink]="['/main/user-dashboard']">HOME</a></div>
      <div><a class="nav-link active" aria-current="page" [routerLink]="['/main/createprofile']">MEMBERSHIP DETAILS</a></div>
      <div>
        <li class="nav-item dropdown" style="list-style: none;">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            PRODUCTS
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" [routerLink]="['/main/pharmacy']">Pharmacy</a>
            <a class="dropdown-item" [routerLink]="['/main/dental']">Dental</a>
            <a class="dropdown-item" [routerLink]="['/main/vision']">Vision</a>
            <a class="dropdown-item" [routerLink]="['/main/lifeadnd']">Life/AD&D</a>
          </div>
        </li>
      </div>
    </div>
    <div class="ml-auto p-2">
      <button class="btn btn-outline-info my-2 my-sm-0" type="button" (click)="logout()">Log Out</button>
    </div>
  </div>

</nav>-->

<nav *ngIf='logintype=="user"' class="navbar navbar-expand-lg sticky-top navbar-dark" style="background-color: #47c5ca; box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.50);">

  <a class="navbar-brand" routerLink="/main/user-dashboard">
    <img src="https://cdn.abcsys.com/Library/Eniac/leeba_logo.png" width="69" height="69" class="d-inline-block align-center" alt="LEEBA logo">
    Member Benefits Program
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-between p-4" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link active" aria-current="page" [routerLink]="['/main/user-dashboard']">HOME</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link active" aria-current="page" [routerLink]="['/main/createprofile']">MEMBERSHIP DETAILS</a>
      </li>
      <li class="nav-item dropdown" style="list-style: none;">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          PRODUCTS
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" [routerLink]="['/main/pharmacy']">Pharmacy</a>
          <a class="dropdown-item" [routerLink]="['/main/dental']">Dental</a>
          <a class="dropdown-item" [routerLink]="['/main/vision']">Vision</a>
          <a class="dropdown-item" [routerLink]="['/main/lifeadnd']">Life/AD&D</a>
        </div>
      </li>
    </ul>
    <button class="btn btn-outline-info my-2 my-sm-0" type="button" (click)="logout()">Log Out</button>
  </div>

</nav>


<nav *ngIf="logintype === 'enterpriseadmin'" class="navbar enterprise-nav navbar-expand-lg sticky-top navbar-dark" style="background-color: #13494c; box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.50);">
  <a routerLink="/main/enterprise-dashboard">
    <span style="letter-spacing: -3px; font-weight: 650; color: #fff; font-size: 30px; padding: 0 10px; border: 3px solid #fff; border-radius: 5px;">ABAC</span>
    <span style="color: #fff; font-size: 20px; margin: 0 15px;">Added Benefits Administrative Console</span>  
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-end p-4" id="navbarNav">
    <!-- <button class="btn btn-outline-info my-2 mx-3 my-sm-0" type="button">Role</button> -->
    <button class="btn my-2 my-sm-0" style="color:#fff; text-decoration: underline" type="button" (click)="logout()">Log Out</button>
  </div>
</nav>

<nav *ngIf="logintype === 'consoleuser'">
  
</nav>
