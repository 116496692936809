<div *ngIf='formConfigs'>
  <app-form-error [formGroup]="form" [show]="submitted">
  </app-form-error>
  <div *ngIf="form.errors?.dateValidate && (form.touched || form.dirty)"
    class="cross-validation-error-message alert alert-danger">
    {{form.errors?.dateValidate}}
  </div>
  <div *ngIf="form.errors?.crossfield && (submitted || form.touched || form.dirty)"
    class="cross-validation-error-message alert alert-danger">
    {{form.errors?.crossfield}}
  </div>
  <form [formGroup]="form">

    <div *ngFor="let formConfig of formConfigs " style="display: inline !important">
      <div *ngFor="let item of (formConfig| keyvalue)" style="display: inline !important">

        <div [class]="classExpression">
          <div class="col-12">
            <h2>{{item.key}} </h2>
          </div>
          <ng-container *ngFor="let access of item.value">
            <div [class]="access.css"
              *ngIf='access.controlType!="conditiontextnotequal" || access.controlType=="conditiontextnotequal" && testcondNotEqual(access)'>
              <label></label>
              <span *ngIf="access.controlLabel != null && access.controlType!='hidden'">{{access.controlLabel}}
                <span *ngIf="access.validator_required" style="color: red">*</span></span>
              <ng-container [ngSwitch]="access.controlType">
                <ng-container *ngSwitchCase="'expr'">
                  <br />
                  <div *ngIf='_formValue!=null'>
                    {{access.controlName| transurl: form.value | replace}}
                  </div>

                </ng-container>
                <ng-container *ngSwitchCase="'exprcalcu'">
                  <br />
                  <div *ngIf='_formValue!=null'>
                    <div class="date_wrap" *ngIf=" access.expr| transurl: form.value | calculate as obj ;else noItems">
                      <input [matDatepicker]="picker" [formControlName]="access.controlName" placeholder="mm/dd/yyyy"
                        [ngModel]="obj" class="input date_wrap__input ">
                      <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </div>
                  </div>
                  <ng-template #noItems>
                    <input [matDatepicker]="picker" [formControlName]="access.controlName" placeholder="mm/dd/yyyy"
                      class="input date_wrap__input ">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </ng-template>
                </ng-container>
                <mat-selection-list *ngSwitchCase="'list'" [formControlName]="access.controlName"
                  style="max-height: 200px; overflow: auto; ">
                  <ng-container *ngIf="(access.dropDownSource | lookupHttp) as Lookup">
                    <mat-list-option *ngFor="let it of Lookup.items" [value]="it[access.bindValue]">
                      {{it[access.bindLabel]}}
                    </mat-list-option>
                  </ng-container>
                </mat-selection-list>
                <mat-selection-list (selectionChange)='onSelectionChange()' *ngSwitchCase="'listapi'"
                  [formControlName]="access.controlName" style="max-height: 200px; overflow: auto; ">
                  <ng-container *ngIf="(access.dropDownSource | transurl: _formValue | lookupapi ) | async as Lookup ">
                    <ng-container *ngIf="checkform(Lookup,access)">
                      <mat-list-option *ngFor="let it of Lookup" [value]="it[access.bindValue]">
                        {{it[access.bindLabel]}}
                      </mat-list-option>
                    </ng-container>
                  </ng-container>
                </mat-selection-list>
                <mat-selection-list (selectionChange)='onSelectionChange()' *ngSwitchCase="'listapidetails'"
                  [formControlName]="access.controlName" style="max-height: 200px; overflow: auto; ">
                  <ng-container *ngIf="(access.dropDownSource | transurl: _formValue | lookupapi ) | async as Lookup ">
                    <ng-container *ngIf="checkform(Lookup,access)">
                      <mat-list-option *ngFor="let it of Lookup.details" [value]="it[access.bindValue]">
                        {{it[access.bindLabel]}}
                      </mat-list-option>
                    </ng-container>
                  </ng-container>
                </mat-selection-list>
                <ng-container *ngSwitchCase="'datetime'">
                  <br><input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [formControlName]="access.controlName"
                    placeholder="Date Time">
                  <owl-date-time #dt1></owl-date-time>
                </ng-container>
                <!-- <input  *ngSwitchCase="'datetime'"
                  [formControlName]="access.controlName"
                       [owlDateTimeTrigger]="dt11" [owlDateTime]="dt11"
                       >
                      <owl-date-time #dt11></owl-date-time> -->

                <!-- <nz-date-picker  *ngSwitchCase="'datetime'" style="width: 100%;"  [formControlName]="access.controlName" [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue }"  ></nz-date-picker> -->
                <nz-range-picker *ngSwitchCase="'daterange'" [formControlName]="access.controlName"></nz-range-picker>
                <ng-container *ngSwitchCase="'search'" class="form-control">
                  <input type="hidden" [formControlName]="access.controlName" readonly /><br />
                  <button nz-button nzType="default" (click)="onSearch(access.searchId,access.searchFields)"><i nz-icon
                      [nzType]="search"></i>Search</button>
                </ng-container>
                <ng-container *ngSwitchCase="'file'" class="form-control">
                  <input type="file" (change)="onFileSelect($event,access)">
                </ng-container>
                <ng-container *ngSwitchCase="'downloadfile'" class="form-control">
                  <button mat-flat-button color="primary" (click)="onDownload(access)">
                    Download </button>&nbsp;&nbsp;
                </ng-container>
                <input class="form-control" style="width: 30px;" *ngSwitchCase="'checkbox'" type="checkbox"
                  [formControlName]="access.controlName" />

                <input class="form-control" *ngSwitchCase="'zipcode'" type="text" [formControlName]="access.controlName"
                  pattern="[0-9]{5}([0-9]{4})?" [validation]="false" mask='00000-0000' />
                <input class="form-control" *ngSwitchCase="'renewDate'" type="text"
                  [formControlName]="access.controlName" [validation]="false" mask='#00/00' />
                <ng-container *ngSwitchCase="'conditionreadonly'" class="form-control">
                  <input class="form-control" *ngIf='testcond(access)' type="text"
                    [formControlName]="access.controlName" readonly />
                  <input class="form-control" *ngIf='testcond(access)==false' type="text"
                    [formControlName]="access.controlName" />
                </ng-container>
                <ng-container *ngSwitchCase="'conditiontext'" class="form-control">
                  <input class="form-control" *ngIf='testcond(access)' type="text"
                    [formControlName]="access.controlName" />
                </ng-container>
                <ng-container *ngSwitchCase="'conditiontextnotequal'" class="form-control">
                  <input class="form-control" *ngIf='testcondNotEqual(access)' type="text"
                    [formControlName]="access.controlName" />
                </ng-container>

                <input class="form-control" *ngSwitchCase="'readonly'" type="text"
                  [formControlName]="access.controlName" readonly />
                <!-- <input class="form-control" *ngSwitchCase="'readonlydate'" type="text" 
                    [formControlName]="access.controlName" readonly/> -->
                <input class="form-control" *ngSwitchCase="'currentusername'" type="text"
                  [formControlName]="access.controlName" readonly />
                <input *ngSwitchCase="'hidden'" type="hidden" [formControlName]="access.controlName" />
                <input *ngSwitchCase="'hiddenobv'" type="hidden" [formControlName]="access.controlName" />
                <textarea rows="{{access.rows ? access.rows:6}}" class="form-control" *ngSwitchCase="'textarea'"
                  [formControlName]="access.controlName"></textarea>
                <textarea rows="{{access.rows ? access.rows:6}}" class="form-control" *ngSwitchCase="'textareareadonly'"
                  readonly="readonly" [formControlName]="access.controlName"></textarea>
                <input class="form-control" *ngSwitchCase="'text'" type="text" placeholder="{{access.placeholder}}"
                  [formControlName]="access.controlName" />
                  <input class="form-control" *ngSwitchCase="'number'" type="number" placeholder="{{access.placeholder}}"
                  [formControlName]="access.controlName" />
                <ng-container *ngSwitchCase="'dropdownobv'">
                  <select class="form-control" [formControlName]="access.controlName"
                    (change)="onChangeobv(access.obvUrl,$event.target.value)">
                    <option *ngFor="let opt of access.options" [value]="opt.key">{{opt.value}}</option>
                  </select>
                </ng-container>
                <ng-container *ngSwitchCase="'lookupApi1'">
                  <ng-container *ngIf="(access.dropDownSource | transurl: _formValue | lookupapi )  | async as Lookup ">
                    <ng-select [items]="Lookup[access.innerProp]" [formControlName]="access.controlName" [bindLabel]="access.bindLabel"
                      [bindValue]="access.bindValue" class="contact-custom">
                    </ng-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'lookupApi'">
                  <ng-container *ngIf="(access.dropDownSource | transurl: _formValue | lookupapi )  | async as Lookup ">
                    <ng-select [items]="Lookup" [formControlName]="access.controlName" [bindLabel]="access.bindLabel"
                      [bindValue]="access.bindValue" class="contact-custom">
                    </ng-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'lookupApireadonly'">
                  <ng-container *ngIf="(access.dropDownSource | transurl: _formValue | lookupapi )  | async as Lookup ">
                    <ng-select [items]="Lookup" [formControlName]="access.controlName" [bindLabel]="access.bindLabel"
                      [bindValue]="access.bindValue" class="contact-custom" [readonly]="true">
                    </ng-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'lookupApiwithgtext'">
                  <input type="checkbox" #dropcheck>
                  <ng-container *ngIf="(access.dropDownSource | transurl: _formValue | lookupapi )  | async as Lookup ">
                    <input class="form-control"
                      *ngIf="_formValue!=null && Lookup!=null && (dropcheck.checked==true || (form.value[access.controlName]!='' && !findinlist(Lookup,form.value[access.controlName],access.bindValue)))"
                      type="text" [formControlName]="access.controlName" />
                    <ng-select [items]="Lookup" [formControlName]="access.controlName"
                      *ngIf="_formValue!=null && Lookup!=null && (dropcheck.checked==false && (form.value[access.controlName]=='' || findinlist(Lookup,form.value[access.controlName],access.bindValue)))"
                      [bindLabel]="access.bindLabel" [bindValue]="access.bindValue" class="contact-custom">
                    </ng-select>

                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'lookupApiDetails'">
                  <ng-container *ngIf="(access.dropDownSource | transurl: _formValue | lookupapi )  | async as Lookup ">
                    <ng-select [items]="Lookup.details" [formControlName]="access.controlName"
                      [bindLabel]="access.bindLabel" [bindValue]="access.bindValue" class="contact-custom">
                    </ng-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'lookupApiobv'">
                  <ng-container *ngIf="(access.dropDownSource | transurl: _formValue | lookupapi )  | async as Lookup ">
                    <ng-select [items]="Lookup" [formControlName]="access.controlName"
                      (change)="onChange(access,$event)" [bindLabel]="access.bindLabel" [bindValue]="access.bindValue"
                      class="contact-custom" #obv>
                    </ng-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'lookupApiobv1'">
                  <ng-container *ngIf="(access.dropDownSource | transurl: _formValue | lookupapi )  | async as Lookup ">
                    <ng-select [items]="Lookup" [formControlName]="access.controlName"
                      (change)="onChange1(access,$event)" [bindLabel]="access.bindLabel" [bindValue]="access.bindValue"
                      class="contact-custom" #obv>
                    </ng-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'lookupApiobvdetails'">
                  <ng-container *ngIf="(access.dropDownSource | transurl: _formValue | lookupapi )  | async as Lookup ">
                    <ng-select [items]="Lookup.details" [formControlName]="access.controlName"
                      (change)="onChange(access,$event)" [bindLabel]="access.bindLabel" [bindValue]="access.bindValue"
                      class="contact-custom" #obv>
                    </ng-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'lookupHttpobv'">
                  <ng-container *ngIf="(access.dropDownSource | lookupHttp) as Lookup">
                    <ng-select [items]="Lookup.items" [formControlName]="access.controlName"
                      (change)="onChange(access,$event)" [bindLabel]="access.bindLabel" [bindValue]="access.bindValue"
                      class="contact-custom" #obv>
                    </ng-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'lookupHttpobv1'">
                  <ng-container *ngIf="(access.dropDownSource | lookupHttp) as Lookup">
                    <ng-select [items]="Lookup.items" [formControlName]="access.controlName"
                      (change)="onChange1(access,$event)" [bindLabel]="access.bindLabel" [bindValue]="access.bindValue"
                      class="contact-custom" #obv1>
                    </ng-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'dropdownobvsub'">
                  <ng-container *ngIf='access.prop==null'>
                    <select class="form-control" [formControlName]="access.controlName"
                      (change)="onChangeObvSub(access,$event)">
                      <option *ngFor="let opt of obv$ | async" [value]="opt[access.bindValue]">
                        {{opt[access.bindLabel]}}</option>
                    </select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'dropdownobvsub1'">
                  <ng-container *ngIf='access.prop==null'>
                    <select class="form-control" [formControlName]="access.controlName"
                      (change)="onChangeObvSub(access,$event)">
                      <option *ngIf='access.default!=null'>{{access.default}}</option>
                      <option *ngFor="let opt of obv1$ | async" [value]="opt[access.bindValue]">
                        {{opt[access.bindLabel]}}</option>
                    </select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'textobvsub1'">
                  <ng-container *ngIf="obv1$ | async as obj">
                    <input class="form-control" type="text" [ngModel]="obj[access.propobv]"
                      [formControlName]="access.controlName" (change)="onChangeObvSub(access,$event)" readonly />
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'dropdownfrompara'">
                  <select class="form-control" [formControlName]="access.controlName"
                    (change)="onChangeObvSub(access,$event)">
                    <option *ngFor="let opt of paraArr" [value]="opt[access.bindValue]">{{opt[access.bindLabel]}}
                    </option>
                  </select>
                </ng-container>
                <ng-container *ngSwitchCase="'dropdownobvsub'">
                  <ng-container *ngIf="access.prop!=null && obv$ | async as obj">
                    <select class="form-control" [formControlName]="access.controlName"
                      (change)="onChangeObvSub(access,$event)">
                      <option *ngFor="let opt of obj[access.prop]" [value]="opt[access.bindValue]">
                        {{opt[access.bindLabel]}}</option>
                    </select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'dropdownconcat'">
                  <ng-container *ngIf="access.prop!=null && obv$ | async as obj">
                    <select class="form-control" [formControlName]="access.controlName"
                      (change)="onChangeObvSub(access,$event)">
                      <option *ngFor="let opt of obj[access.prop]" [value]="opt[access.bindValue]">
                        {{opt[access.bindLabel] + ' ' + opt[access.bindLabel2]}}</option>
                    </select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'dropdownsearchobvsub'">
                  <select class="form-control" [formControlName]="access.controlName">
                    <option *ngFor="let opt of searchObv$ | async" [value]="opt[access.bindValue]">
                      {{opt[access.bindLabel]}}</option>
                  </select>
                </ng-container>
                <ng-container *ngSwitchCase="'dropdownsearchobvsubwithdetails'">
                  <select class="form-control" [formControlName]="access.controlName">
                    <option *ngFor="let opt of (searchObv$ | async).details" [value]="opt[access.bindValue]">
                      {{opt[access.bindLabel]}}</option>
                  </select>
                </ng-container>
                <ng-container *ngSwitchCase="'dropdownreadonly'">
                  <select class="form-control" [formControlName]="access.controlName" disabled>
                    <option *ngFor="let opt of access.options" [value]="opt.key">{{opt.value}}</option>
                  </select>
                </ng-container>
                <ng-container *ngSwitchCase="'dropdown'">
                  <select class="form-control" [formControlName]="access.controlName">
                    <option *ngFor="let opt of access.options" [value]="opt.key">{{opt.value}}</option>
                  </select>
                </ng-container>
                <ng-container *ngSwitchCase="'dropdown1'">
                  <select class="form-control" [formControlName]="access.controlName">
                    <option *ngFor="let opt of access.options" [value]="opt.key">{{opt.value}}</option>
                  </select>
                </ng-container>
                <ng-select *ngSwitchCase="'ngdropdown'" [formControlName]="access.controlName">
                  <ng-option *ngFor="let opt of access.options" [value]="opt.key">{{opt.value}}</ng-option>
                </ng-select>
                <ng-container *ngSwitchCase="'date'">
                  <br />
                  <!-- <app-date-input  [formControlName]="access.controlName">
                      </app-date-input> -->
                  <div class="date_wrap">
                    <input [matDatepicker]="picker" [formControlName]="access.controlName" placeholder="mm/dd/yyyy"
                      class="input date_wrap__input ">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="date_wrap__icon"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'readonlytext'">
                  <!-- <br /> -->
                  <div *ngIf='_formValue!=null'>
                    {{_formValue[access.controlName]}}
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'calcuage'">
                  <br />
                  <div *ngIf='_formValue!=null'>
                    {{calcuage(_formValue[access.controlName])}}
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'readonlydate'">
                  <div class="form-control" *ngIf='_formValue!=null' readonly>
                    {{_formValue[access.controlName] | date:'shortDate'}}
                  </div>
                </ng-container>
                <input class="form-control" *ngSwitchCase="'phone'" type="text" [formControlName]="access.controlName"
                  placeholder="{{access.placeholder}}" mask='(000) 000-0000' />
                <ckeditor *ngSwitchCase="'richtext'" debounce="500" [formControlName]="access.controlName">
                </ckeditor>
                <ng-container *ngSwitchCase="'currency'">
                  <br />
                  <app-decimal-input [formControlName]="access.controlName">
                  </app-decimal-input>
                </ng-container>
                <ng-container *ngSwitchCase="'lookup'">
                  <ng-container *ngIf="(access.dropDownSource | lookup ) as Lookup">
                    <ng-select [items]="Lookup.items" [formControlName]="access.controlName"
                      [bindLabel]="access.bindLabel" [bindValue]="access.bindValue" class="contact-custom">
                    </ng-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'getFields'">
                  <br />
                  <button mat-flat-button color="primary"
                    (click)="onGetFields(access.url,access.fields)"> Fetch </button>&nbsp;&nbsp;
                </ng-container>
                <ng-container *ngSwitchCase="'getFields2'">
                  <br />
                  <button mat-flat-button color="primary"
                    (click)="onGetFields(access.url,access.fields)"> Default to Member </button>&nbsp;&nbsp;
                </ng-container>
                <ng-container *ngSwitchCase="'lookupHttp'">
                  <ng-container *ngIf="(access.dropDownSource | lookupHttp) as Lookup">
                    <ng-select [items]="Lookup.items" [formControlName]="access.controlName"
                      [bindLabel]="access.bindLabel" [bindValue]="access.bindValue" class="contact-custom">
                    </ng-select>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'ssnedit'">
                  <input class="form-control" app-matinputssn [formControlName]="access.controlName" type="text" />
                </ng-container>

                <ng-container *ngSwitchCase="'achaccountedit'">
                  <input class="form-control" app-matinputachaccount [formControlName]="access.controlName"
                    type="text" />
                </ng-container>

                <app-ssn-input *ngSwitchCase="'ssn'" [formControlName]="access.controlName">
                </app-ssn-input>
              </ng-container>


            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="pull-left" *ngIf="hideButton==false">
      <div class="col form-button-bar" style="text-align: left;padding-left: 0px">

        <ng-container *ngIf='actionButton2!=null'>
          <button mat-flat-button color="primary" (click)="onAction2()"
            disabled>{{actionButton2}}</button>&nbsp;&nbsp;
        </ng-container>

        <ng-container *ngIf='deleteButton==="true" && !addNew'>
          <button style=" z-index:7000;" mat-flat-button color="primary" app-popconfirm
            abcTitle="Are you sure this should be deleted ?" (onOK)="onDelete()">Delete</button>
          &nbsp;&nbsp;
        </ng-container>

        <ng-container *ngIf='_saveButton!="Search"'>
          <button mat-flat-button color="primary" (onOK)='onSave()' app-popconfirm
            abcTitle="Are you sure you want to save?" color="primary"
            *ngIf='_saveButton!=="false"  && _saveButton!="Search"'><span
              class="mat-button-wrapper">{{_saveButton}}</span></button>&nbsp;&nbsp;
          <!--<button mat-flat-button color="primary" (onOK)='onSave()' app-popconfirm
            abcTitle="Are you sure you want to save?" color="primary"
            *ngIf='_saveButton!=="false"  && _saveButton!="Search"'
            disabled><span class="mat-button-wrapper">{{_saveButton}}</span></button>&nbsp;&nbsp;-->
          <!-- <button mat-flat-button color="primary" (click)=' onconfirm()'  color="primary" *ngIf='_saveButton!=="false"  && _saveButton!="Search" && saving!==true && saveSuccess!==true'  ><span class="mat-button-wrapper"   >{{_saveButton}}</span></button>&nbsp;&nbsp;
          <button mat-flat-button color="primary"   (click)='onconfirm()'   color="primary" *ngIf='_saveButton!=="false"  && _saveButton!="Search" && (saving===true || saveSuccess===true)'  disabled><span class="mat-button-wrapper"   >{{_saveButton}}</span></button>&nbsp;&nbsp; -->
          <!-- <button mat-flat-button color="primary" nz-popconfirm  nzPopconfirmTitle="Are you sure you want to save?" (nzOnConfirm)="onSave()" color="primary" *ngIf='_saveButton!=="false"  && _saveButton!="Search" && saving!==true && saveSuccess!==true'  ><span class="mat-button-wrapper"   >{{_saveButton}}</span></button>&nbsp;&nbsp;
          <button mat-flat-button color="primary" nz-popconfirm  nzPopconfirmTitle="Are you sure you want to save?" (nzOnConfirm)="onSave()" color="primary" *ngIf='_saveButton!=="false"  && _saveButton!="Search" && (saving===true || saveSuccess===true)'  disabled><span class="mat-button-wrapper"   >{{_saveButton}}</span></button>&nbsp;&nbsp; -->
        </ng-container>

        <ng-container *ngIf='_saveButton==="Search"'>
          <button mat-flat-button color="primary" #name (click)="onSave()" color="primary"
            *ngIf='_saveButton!=="false"  && _saveButton==="Search" '><span
              class="mat-button-wrapper">{{_saveButton}}</span></button>&nbsp;&nbsp;
        </ng-container>

        <ng-container *ngIf='actionButton1!=null'>
          <button mat-flat-button color="primary"
            (click)="onAction1()">{{actionButton1}}</button>&nbsp;&nbsp;
        </ng-container>

        <ng-container *ngIf='reAddButton==="true" && addNew'>
          <button mat-flat-button color="primary" (click)="onReset()">ReAdd</button>
          &nbsp;&nbsp;
        </ng-container>

        <ng-container *ngIf='cancelButton!=="false" '>
          <button mat-flat-button color="primary" (click)="onCancel()">Cancel</button>
          &nbsp;&nbsp;
        </ng-container>

        <ng-container *ngIf='cancelButton!=="false" '>
          <button mat-flat-button color="primary" (click)="onClose()">Close</button>
          &nbsp;&nbsp;
          <!-- <button mat-flat-button color="primary" (onOK)='onSave()' app-popconfirm >test</button> -->
        </ng-container>
      </div>
    </div>
    <!-- <pre>
      {{form.value | json}}
    </pre> -->
  </form>
</div>