import { HttpRequest, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { CacheItem } from '@shared/cache/cache-item';
import { LocalStorageService } from '@shared/storage/local-storage.service';
import { LocalStorageCacheService } from '@shared/cache/local-storage-cache.service';
import { LoggerService } from '@shared/logging/logger.service';
import { Injector, inject } from '@angular/core';
import { HttpHelperService } from '@shared/api/http-helper.service';
import { REQUEST_HEADERS } from '@shared/api/shared';

export function HttpCacheFactory(injector: Injector): HttpCacheService {
  const localStorage = injector.get(LocalStorageService);
  const logger = injector.get(LoggerService);
  const httpHelper = injector.get(HttpHelperService);

  return new HttpCacheService(
    logger,
    httpHelper,
    new LocalStorageCacheService('HTTP-CACHE', localStorage)
  );
}

export class HttpCacheService {
  constructor(
    private logger: LoggerService,
    private httpHelper: HttpHelperService,
    private cache: LocalStorageCacheService<ResponseCacheItem>
  ) {
    this.logger = this.logger.create(this);
  }

  get(request: HttpRequest<any>): HttpResponse<any> | undefined {
    const cacheHeader = this.httpHelper.getRequestHeader(
      request.headers,
      REQUEST_HEADERS.CACHE
    );

    // if header is set to 'Cache-Control:no-cache' delete from cache
    if (cacheHeader === REQUEST_HEADERS.NO_CACHE) {
      this.logger.debug(
        `'${REQUEST_HEADERS.NO_CACHE}' header found. deleting from cache`
      );
      this.delete(request);
      return undefined;
    }

    const key = this.httpHelper.createRequestHash(request);
    const cacheItem = this.cache.get(key);

    if (!cacheItem) {
      return undefined;
    }

    // delete if expired
    if (cacheItem.expiration < Date.now()) {
      this.logger.debug(
        `cache expired for ${this.getRequestMethodUrl(
          request
        )}. deleting from cache`
      );
      this.cache.delete(key);
      return undefined;
    }

    // verify the 'data' property has value
    if (!cacheItem.data) {
      this.logger.debug(
        `cacheItem.data property is undefined for ${this.getRequestMethodUrl(
          request
        )}`
      );
      return undefined;
    }

    this.logger.debug(
      `response found in cache for ${this.getRequestMethodUrl(request)}`
    );
    return cacheItem.data.response;
  }

  set(
    request: HttpRequest<any>,
    response: HttpResponse<any>,
    cacheExpiration: number
  ) {
    const cacheKey = this.httpHelper.createRequestHash(request);
    const cacheItem: ResponseCacheItem = {
      data: {
        method: request.method,
        url: request.urlWithParams,
        response: response
      },
      lastRead: Date.now(),
      expiration: cacheExpiration
    };

    this.logger.debug(
      `adding response to cache for ${this.getRequestMethodUrl(
        request
      )} at key ${cacheKey}`
    );
    this.cache.set(cacheKey, cacheItem);

    this.deleteExpired();
  }

  // delete the cached response for the request
  delete(request: HttpRequest<any>) {
    const key = this.httpHelper.createRequestHash(request);
    this.cache.delete(key);
  }

  // deletes expired items from the cache
  deleteExpired() {
    const now = Date.now();
    this.cache.forEach((cacheItem, key) => {
      if (cacheItem.expiration < now) {
        this.cache.delete(key);
      }
    });
  }

  private getRequestMethodUrl(request: HttpRequest<any>): string {
    return request.method + ' ' + request.urlWithParams;
  }
}

export class ResponseCacheItem extends CacheItem {
  data: {
    method: string;
    url: string;
    response: HttpResponse<any>;
  };
  expiration: number;
}
