import { MockHelperService } from '@mock/services/mock-helper.service';


import { MaritalStatusLookupMockHandler } from '@mock/mock-data/lookup/marital-status.mock.handler';
import { RelationshipTypeLookupMockHandler } from '@mock/mock-data/lookup/relationship-type.mock.handler';
import { GenderLookupMockHandler } from '@mock/mock-data/lookup/gender-type.mock.handler';

export function registerLookupUrls(mock: MockHelperService) {
  // mock.addGetHandler('lookup/states', new StateLookupMockHandler());
  // mock.addGetHandler('lookup/industry', new IndustryLookupMockHandler());
  // mock.addGetHandler('lookup/banks', new BankLookupMockHandler());
  // mock.addGetHandler(
  //   'lookup/bank-account-types',
  //   new BankAccountTypesLookupMockHandler()
  // );
  mock.addGetHandler(
    'lookup/marital-status',
    new MaritalStatusLookupMockHandler()
  );
  mock.addGetHandler(
    'lookup/relationship_types',
    new RelationshipTypeLookupMockHandler()
  );
  mock.addGetHandler('lookup/genders', new GenderLookupMockHandler());
}
