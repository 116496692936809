import { Component, Output, Input, ElementRef, HostListener, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ClientService } from '@services/client/client.service';
import { Observable } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, filter, tap, finalize } from 'rxjs/operators';
export class User {
  constructor(public id: number, public name: string) { }
}

export interface IUserResponse {
  total: number;
  results: User[];
}
@Component({
  selector: 'app-dynamic-autocomplete',
  templateUrl: './dynamic_autocomplete.component.html',

})

export class DynamicAutoCompleteComponent implements OnInit {
  filteredUsers: Observable<any>;
  usersForm: UntypedFormGroup;
  @Input()
  dto;
  @Output()
  selected: EventEmitter<any> = new EventEmitter();
  isLoading = false;
  constructor(private fb: UntypedFormBuilder, private clientService: ClientService) { }
  ngOnInit() {
    this.usersForm = this.fb.group({
      userInput: null
    });
    const tt = this.usersForm.get('userInput');
    if (tt != null) {
      const url = 'Participant-Lookup';
      tt.valueChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          filter((query: string) => query.length > 2),
          filter((query: string) => typeof query === 'string'),
          tap(() => this.isLoading = true),
          switchMap(value => {
            this.dto['keyWord'] = value;
            return this.clientService.postWithUrl({ url, dto: this.dto }).pipe(
              finalize(() => this.isLoading = false),
            );
          })
        ).subscribe(users => {
          this.filteredUsers = users;
        });
    }
  }

  optionSelected(e) {
    this.selected.emit(e);
  }

  displayFn(user) {
    if (user) { return user.firstName + ' ' + user.lastName; } else {
      return null;
    }
  }
}
