import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '@services/client/client.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { NewUser } from '../../../shared/Interfaces/user-screens-interfaces'
import { RegisterService } from '../../../shared/services/register.service';

@Component({
  selector: 'app-submit-registration',
  templateUrl: './submit-registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class SubmitRegistrationComponent implements OnInit {
  registerForm: UntypedFormGroup
  secQuestions: any
  alertRed: boolean = false
  alertGreen: boolean = false
  alertMessage: any = ''
  clientId: string
  participantId: string
  entityClientId: string
  response: any

  config = window['___alliant-config-eniac-APP___']
  logintype = this.config.logintype
  welcomeText = this.config.welcomeText
  phoneNum = this.config.phoneNum
  primaryColor = this.config.primaryColor
  bgImage = this.config.bgImage
  isPrivacyLink = this.config.isPrivacyLink
  isTermsLink = this.config.isTermsLink
  registrationParam = this.config.registrationParam

  constructor(
    private registerService: RegisterService,
    private router: Router,
    protected clientService: ClientService,
    protected spinner: SpinnerService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.participantId = this.route.snapshot.queryParams["ptd"];
    this.entityClientId = this.route.snapshot.queryParams["ecid"];
    this.registerForm = new UntypedFormGroup({
      username: new UntypedFormControl('', [Validators.required,
      Validators.minLength(6),
      Validators.maxLength(12)]),
      password: new UntypedFormControl('', [Validators.required,
      Validators.minLength(10),
      Validators.pattern(/[A-Z]/),
      Validators.pattern(/[a-z]/),
      Validators.pattern(/[@#$%+*!=]/),
      ]),
      reEnterPassword: new UntypedFormControl('', [Validators.required,
      Validators.minLength(8),
      ]),
      email: new UntypedFormControl('', [Validators.required,
      Validators.email]),
      reEnterEmail: new UntypedFormControl('', [Validators.required,
      Validators.email]),
      secQuestion: new UntypedFormControl(''),
      secAnswer: new UntypedFormControl('', Validators.required),
    }, { validators: this.fieldMatchValidator }
    )
    this.getSecQuestions();
  }

  onSubmit() {

    const newUser: NewUser = {
      // entityId: "E3C3E77D-D76E-4BB8-AF30-AC165CA9807B",
      //entityClientId: "42445106-54B5-44D3-A134-A2DFA7837C17", //LEEBA
      entityId: "9808F856-F047-4843-BEC8-277E414FC649",
      entityClientId: this.entityClientId,
      email: this.registerForm.value.email,
      employeeId: null,
      firstName: null,
      lastFourSSN: null,
      lastName: null,
      securityAnswer: this.registerForm.value.secAnswer,
      securityQuestionId: this.registerForm.value.secQuestion,
      SecurityQuestions: null,
      telephone: null,
      zipCode: '',
      Password: this.registerForm.value.password,
      PasswordSalt: null,
      PasswordSecureMethodType: null,
      UserID: null,
      UserName: this.registerForm.value.username,
      ParticipantId: this.participantId,
      LastLockoutDate: null,
      LastLoginDate: null,
      LastPasswordChangedDate: null,
      ErrorMessage: null,
      ErrorCode: null
    }

    if (this.registerForm.valid) {
      const observ$ = this.clientService.postWithUrl({ url: '/CaseManagement/UserRegistration', dto: newUser });
      observ$.subscribe(res => {
        this.response = res
        if (res) {
          this.alertGreen = true
          this.alertRed = false
          this.alertMessage = 'Registration successful. Please click Ok to login! '
          this.openDialog()
        }
      }, err => {
        this.alertRed = true
        this.alertGreen = false
        if (err.error) {
          this.alertMessage = err.error
        } else if (err.error) {
          this.alertMessage = err.errors.message
        } else {
        }
        this.openDialog()
      })
    } else {
      this.registerForm.markAllAsTouched()
    }
  }

  openDialog() {
    this.dialog.open(DialogElementsFinishPopup, {
      data: {
        alertMessage: this.alertMessage,
        alertRed: this.alertRed,
        alertGreen: this.alertGreen
      }
    });
  }

  fieldMatchValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const password = control.get('password').value;
    const repeatedPassword = control.get('reEnterPassword').value;
    const email = control.get('email').value;
    const reemail = control.get('reEnterEmail').value;

    if (password !== repeatedPassword) {
      return { 'mismatch': true }
    } else if ((email !== reemail)) {
      return { 'emailmismatch': true }
    }
  }

  //Security Questions GET Request
  getSecQuestions() {
    const url = `CaseManagement/SecurityQuestionsByEntity?entityId=9808F856-F047-4843-BEC8-277E414FC649`; 
    const observ$ = this.clientService.getWithUrl(url, {});
    observ$.subscribe(data => {
      this.secQuestions = data;
    }, err => {
      this.spinner.hide();
    }
    );
  }
}

@Component({
  selector: 'finish-popup',
  templateUrl: 'finish-popup.html',
})
export class DialogElementsFinishPopup {
  constructor(@Inject(MAT_DIALOG_DATA) public data) { }
}