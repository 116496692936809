
<div [formGroup]='coverageForm'>
  <ng-container *ngIf="coverages.length>0" style="margin-bottom: 10px">
    <span> Select Coverage Amount</span> <br />
    <select class="form-control  input-sm col-4"
            formControlName="coverage"
            (change)="onChange($event)">
      <option *ngFor="let opt of  coverages "
              [value]="opt.benefitEntityPolicyDetailCoverageId">{{opt.benefitEntityPolicyDetailCoverageName}}</option>
    </select>
    <br />
  </ng-container>
</div>
