import { Component, OnInit, Input, Inject } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SpinnerService } from '@shared/services/spinner.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteHelperService } from '@shared/route/route-helper.service';
@Component({
  selector: 'app-plan-dependentview',
  templateUrl: './dependentview.component.html',
})
export class DependentViewComponent extends BaseComponent implements OnInit {
  @Input()
  dependents;
  @Input()
  set dependentsInfo(dependentsInfo) {
    this.populatedepinfo( dependentsInfo); 
  }
  _dependentsInfo;
  @Input()
  benefitEntityPolicyDetailId;
  EntityId: string; ClientId: string; MemberId: string; EntityClientId;
  dependentsInfoOwn: any = [];
  constructor(
    protected route: ActivatedRoute,
    protected routeHelper: RouteHelperService,
    protected router: Router,
    public spinner: SpinnerService,
    protected clientService: ClientService,
    protected notification: NzNotificationService) {
    super();
  }

  selectEnrolled(item) {
    if (this.dependents == null) {
      return false;
    }
    const f = this.dependents.find(en => en.dependentId === item.dependentId);
    if (f == null) {
      return false;
    }
    return true;
  }
  
  populatedepinfo(dependentsInfo) {
    dependentsInfo.forEach(element => {
      const obj = this.dependents.find(en => en.dependentId === element.dependentId);
      // if (obj != null && obj.coverages.length > 0) {
      //   element.coverageValue = obj.coverages[0].benefitEntityPolicyDetailCoverageValue;
      //   // element.effectiveDate = obj.coverages[0].effectiveDate;
      // }
      if (obj != null) {
        const newele = { ...element }
        newele.effectiveDate = obj.effectiveDate;
        if (obj.coverages != null && obj.coverages[0] != null) {
          const cc = obj.coverages.find(x => x.benefitEntityPolicyDetailId == this.benefitEntityPolicyDetailId);
          if (cc != null) {         
            newele.coverageValue = cc.benefitEntityPolicyDetailCoverageValueEnrolled;
            newele.coverageValueCurrent = cc.benefitEntityPolicyDetailCoverageValueCurrent;
            newele.coverageValueBillable = cc.benefitEntityPolicyDetailCoverageValueBillable;
          }
        }
        this.dependentsInfoOwn.push({ ...newele });
      }
    });
  }

  ngOnInit() {
  //  this.populatedepinfo(this.dependentsInfo);
  }
}
