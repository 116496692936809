<div class="w-100">
  <img style="width: 100%; position: fixed; object-fit: fit;" src="{{bgImage}}" alt="">
</div>

<div class="form-wraper">
  <div class="form-wraper">
    <div class="row no-gutters">
      <div class="col">

        <div class="form-container">

          <div class="description">
            <h4>Security Question:</h4>
          </div>
          <form [formGroup]="resetForm" (ngSubmit)="onSubmit()" autocomplete="off">

            <div class="question-text">
              <h6>{{question}}</h6>
            </div>

            <div class="form-group">
              <label for="inputAnswer">Security Answer</label>
              <input type="text" class="form-control" id="inputAnswer" formControlName="securityAnswer">
              <div class="validation">
                <span *ngIf="resetForm.get('securityAnswer').touched && resetForm.get('securityAnswer').invalid">Please
                  enter security answer</span>
              </div>
            </div>

            <div *ngIf="alertRed" class="alert alert-danger" role="alert">
              {{alertMessage}}
            </div>
            <div *ngIf="alertGreen" class="alert alert-success text-center" role="alert">
              <p>A temporary password has been sent to your email.</p>
            </div>
            <div style="display: inline; padding-top: 3px">
              <button mat-flat-button routerLink='/' [style.background-color]="primaryColor"
                style="color: #fff; margin: 0 15px 15px 0; width: 100%; width: 150px; border: none; font-size: 18px;">
                Go to Log in
              </button>
              <button mat-flat-button type="submit" [style.background-color]="primaryColor"
                style="color: #fff; float: left; margin: 0 15px 15px 0; width: 100%; width: 180px; border: none; font-size: 18px;">
                Reset Password
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>