import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EnterpriseAdminDashboardComponent} from './enterprise-admin-dashboard.component';
import {BenefitsConfigComponent} from './entrprise-components/benefits-config/benefits-config.component';
import {CompareScreenComponent} from './entrprise-components/compare-screen/compare-screen.component';
import {GeneralComponent} from './entrprise-components/general/general.component';
import {GlobalConfigComponent} from './entrprise-components/global-config/global-config.component';
import {LoginRegistrationComponent} from './entrprise-components/login-registration/login-registration.component';
import {ProductsComponent} from './entrprise-components/products/products.component';
import {BenefitComponent} from './entrprise-components/benefit/benefit.component';
import {ClientComponent} from './entrprise-components/client/client.component';
import {EnterpriseMainComponent} from './entrprise-components/enterprise-main.component';
import {EnterpriseDashboardMainComponent} from './enterprise-dashboard-main.component';

const routes: Routes  = [
  {
    path: '',
    component: EnterpriseDashboardMainComponent,
    children: [
      {
        path: 'enterprise-dashboard',
        component: EnterpriseAdminDashboardComponent
      },
      {
        path: 'enterprise-client/:entityId',
        component: EnterpriseMainComponent
      },
      {
        path: 'compare',
        component: CompareScreenComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EnterpriseDashboardRoutingModule { }
