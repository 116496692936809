import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { ClientListState } from '@store/main/client-management/client-list/client-list.state';
import { ClientState } from '@store/main/client-management/client/client/client.state';
import { clientListReducer } from '@store/main/client-management/client-list/client-list.reducer';
import { clientReducer } from '@store/main/client-management/client/client/client.reducer';

export const StateProp = 'entity';

export class ClientMngState {
  clients: ClientListState;
  client: ClientState;
}

const REDUCER: ActionReducerMap<ClientMngState> = {
  clients: clientListReducer,
  client: clientReducer
};

export const ClientMngStoreModule = StoreModule.forFeature(
    StateProp,
  REDUCER
);
