import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouteHelperService } from '@shared/route/route-helper.service';
import { BaseComponent } from '@shared/components/base.component';
import { ActivatedRoute } from '@angular/router';
import { MEMBER_ROUTE_SEGMENT } from '@shared/route/constants';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.state';
import { getMemberState } from '@store/main/member/member.selector';
import { untilComponentDestroyed } from 'ng2-rx-componentdestroyed';
import { MemberState } from '@store/main/member/member.state';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '@shared/api/http.service';
import { GetMemberAction } from '@store/main/member/member.actions';
// import { forEach } from '@angular/router/src/utils/collection';
// import { ItemsList } from '@ng-select/ng-select/ng-select/items-list';
@Component({
  selector: 'app-dynamic-dashboard',
  templateUrl: './dynamic-dashboard.component.html',
  styleUrls: ['./dynamic-dashboard.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynanicDashboardComponent extends BaseComponent implements OnInit {
  @Input()
  prePath;
  readonly MEMBER_ROUTE_SEGMENT = MEMBER_ROUTE_SEGMENT;
  memberState: MemberState;
  memberLookupRoute: string;
  //
  EntityId: string;
  ClientId: string;
  MemberId: string;
  EntityClientId: string;
  _jsonURL;
  dashboardConfigs;
  constructor(private http: HttpClient,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private routeHelper: RouteHelperService, private httpser: HttpService
  ) {
    super();
    // this._jsonURL = 'assets/member/dashboard.json';
  }
  public subscribeJson(): void {
    this.getJSON().pipe(untilComponentDestroyed(this)).subscribe(data1 => {
      if (data1 === null) {
        this.dashboardConfigs = [];
      } else {

        // this.dashboardConfigs = (<any>data1.jsonText).DashboardConfigs;
        const json = JSON.parse((<any>data1.jsonText) as string);
        this.dashboardConfigs = json.DashboardConfigs;

        for (let _i = this.dashboardConfigs.length - 1; _i > -1; _i--) {
          // tslint:disable-next-line: max-line-length
          if (this.dashboardConfigs[_i].controlComponent === 'dynamicformentity' && this.dashboardConfigs[_i].entityid !== this.EntityId) {
            this.dashboardConfigs.splice(_i, 1);
          }
        }
        this.subscribeMemberState();
      }
    });
  }
  public getJSON(): Observable<any> {
    // return this.http.get(this._jsonURL);
    if (this.prePath == null) {
      switch (this.EntityId) {
        case '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d':
          this._jsonURL = 'member/dashboardhorace';
          break;
        case '68bc62e6-dd96-4428-91e8-efe285bd23a0':
          this._jsonURL = 'member/dashboardapwu';
          break;
        case 'd6a2ff7e-bba0-48fa-9396-a6b202631669':
          this._jsonURL = 'member/dashboardcobra';
          break;
        case '9808f856-f047-4843-bec8-277e414fc649':
          this._jsonURL = 'member/dashboardtexas';
          break;
        case 'ac586073-69d6-41bf-875c-eb754f750457':
          this._jsonURL = 'member/dashboardutc';
          break;
        case '3b7805a6-10fa-48b5-b0ee-ea5365258341':
          this._jsonURL = 'member/dashboardessex';
          break;
        case 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028':
          this._jsonURL = 'member/dashboardanthem';
          break;
        case 'f9d5f5e4-9e28-4a23-9cc3-9bb9d81d15e4':
          this._jsonURL = 'member/dashboardcsc';
          break;
        case '9256dc20-03ae-469f-a45f-f66c5122f9ca':
          this._jsonURL = 'member/dashboardfirefighters';
          break;
        case 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b':
          this._jsonURL = 'member/dashboardleeba';
          break;
        default:
          this._jsonURL = 'member/dashboard';
      }
    } else {
      switch (this.EntityId) {
        
        case '4e4d0cd3-830a-4d3d-a41f-e9cf0740a75d':
          //   this._jsonURL = 'member/dashboardhorace';
          this._jsonURL = this.prePath + '/' + 'dashboardhorace';
          break;
        case '68bc62e6-dd96-4428-91e8-efe285bd23a0':
          //   this._jsonURL = 'member/dashboardapwu';
          this._jsonURL = this.prePath + '/' + 'dashboardapwu';
          break;
        case 'd6a2ff7e-bba0-48fa-9396-a6b202631669':
          this._jsonURL = this.prePath + '/' + 'dashboardcobra';
          break;
        case 'ac586073-69d6-41bf-875c-eb754f750457':
          this._jsonURL = this.prePath + '/' + 'dashboardutc';
          break;
        case '9808f856-f047-4843-bec8-277e414fc649':
          this._jsonURL = this.prePath + '/' + 'dashboardtexas';
          break;
        case '3b7805a6-10fa-48b5-b0ee-ea5365258341':
          this._jsonURL = this.prePath + '/' + 'dashboardessex';
          break;
        case 'a11ee4de-9b44-4bcf-a01e-04e1a2fc6028':
          this._jsonURL = this.prePath + '/' + 'dashboardanthem';
          break;
        case '9256dc20-03ae-469f-a45f-f66c5122f9ca':
          this._jsonURL = this.prePath + '/' + 'dashboardfirefighters';
          break;
        case 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b':
          this._jsonURL = this.prePath + '/' + 'dashboardleeba';
          break;
        default:
          this._jsonURL = this.prePath + '/' + 'dashboard';
      }
    }

    return (
      this.httpser.get('json?path=' + this._jsonURL, {
        urlParams: null,
        options: {
          isMock: false
        }
      })
    );

  }
  get memberName(): string {
    if (
      !this.memberState ||
      !this.memberState.member ||
      !this.memberState.member.profile
    ) {
      return '';
    }

    return `${this.memberState.member.profile.firstName} ${this.memberState.member.profile.lastName
      }`;
  }

  ngOnInit() {
    this.EntityId = this.routeHelper.getEntityId(this.route);
    this.ClientId = '00000000-0000-0000-0000-000000000000'; // this.routeHelper.getClientId(this.route);
    this.MemberId = this.routeHelper.getMemberId(this.route);
    this.EntityClientId = this.routeHelper.getEntityClientId(this.route);
    if (this.prePath == null) {
      this.store.dispatch(new GetMemberAction({ EntityId: this.EntityId, ClientId: this.ClientId, MemberId: this.MemberId }));
      this.subscribeMemberState();
    }
    this.memberLookupRoute = this.routeHelper.getMemberLookupRoute();
    this.subscribeJson();
  }

  getMemberRoute(Segment: string) {
    if (this.prePath == null) {
      const ret = this.routeHelper.getMemberRoute({ Route: this.route, Segment, EntityClientId: this.EntityClientId });
      return ret;
    } else {
      return this.getLink(Segment);
    }
  }
  getLink(p) {
    return [p];
  }
  private subscribeMemberState() {
    this.store
      .select(getMemberState)
      .pipe(untilComponentDestroyed(this))
      .subscribe(x => this.memberStateChange(x));
  }

  private memberStateChange(state: MemberState) {
    this.memberState = state;
  }
}
