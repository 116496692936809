import {
  MemberCallLogListDTO,
  MemberCallLogDTO
} from '@models/member-list/member-list.dto';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpService } from '@shared/api/http.service';
import { MemberListDTO } from '@models/member-list/member-list.dto';
import { ConfigService } from '@shared/config.service';
import {
  MemberProfileDTO,
  MemberDTO,
  AddMemberDTO
} from '@models/shared/member.dto';
import { DependentListDTO } from '@models/shared/dependent.dto';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  private memberApiUrls: any;

  constructor(private configService: ConfigService, private http: HttpService) {
    this.memberApiUrls = this.configService.api.memberService;
  }
  updateMemberType(params: {url: string, dto: any }): Observable<any> {
    return this.http.post(params.url, {
      body: params.dto,
      options: {
        isMock: false
      }

    });
  }
  getMemberList(body: {
    EntityId?: string;
    ClientId?: string;
    EntityClientId?: string;
    Keyword?: string;
    PageSize: number;
    PageIndex: number;
  }): Observable<MemberListDTO> {
    return this.http.post(this.memberApiUrls.memberList, {
      body,
      options: {
        isMock: false
        // cache: 7 * 60 * 60 // 7 hours
      }
    });

  }

  postWithUrl(params: {url: string, dto: any }): Observable<any> {
    return this.http.post(params.url, {
      body: params.dto,
      options: {
        isMock: false
      }

    });
  }


  getPagingList(body: {
    Url: string;
    EntityId: string;
    ParticipantId: string;
    PageSize?: number;
    PageIndex?: number;
  }): Observable<any> {
    if (body.Url === 'Deductions') {
      // tslint:disable-next-line:max-line-length
      return  of({PageSize: 10, PageIndex: 0, TotalRowCount: 100, TotalPageCount: 10, Deductions: [{PeriodEnding: '11/02/2018', TotalPaidAmount: '899'}]});
    } else if (body.Url === 'Ledger') {
      return  of({PageSize: 10, PageIndex: 0, TotalRowCount: 100, TotalPageCount: 10
        , Ledger: [{Actions : 'Enrollment', TransactionBy : 'mark_s', TransactionDate: '11/16/2018'}]});
    } else if (body.Url === 'Refund') {
      return  of({pageSize: 10, pageIndex: 0, totalRowCount: 100, totalPageCount: 10
        , Refund: [{Plan : 'EVision', RefundAmount : '$20.00', RefundType: 'ACH', Status: 'Complete'},
        {Plan : 'Life', RefundAmount : '$30.00', RefundType: 'ACH', Status: 'Pending'}
      ]});
    } else if (body.Url === 'Banks') {
      return  of({pageSize: 10, pageIndex: 0, totalRowCount: 100, totalPageCount: 10
        , bank: [{bank : 'Citi', accountType : 'Saving', routingNo: '1212121212', accountNo: '214668788'},
        {bank : 'HSBC', accountType : 'Checking', routingNo: '32323232212', accountNo: '53343468788'}

      ]});
     }  else if (body.Url === 'Adjustments') {
      return  of({pageSize: 10, pageIndex: 0, totalRowCount: 100, totalPageCount: 10
        , Adjustments: [{benefit : 'aaaaa', amount : '123'}

      ]});
    }   else {
      return this.http.post(body.Url, {
        body,
        // urlParams : {'test' : 'ssss'}, This is for adding parameter
        options: {
          isMock: true
          // cache: 7 * 60 * 60 // 7 hours
        }
      });
    }

  }
  getWithUrl(body: {
    Url: string;
    EntityId: string;
    ParticipantId: string;
  }): Observable<any> {
    if (body.Url === 'DeductionsDetail') {
      return  of( [{BenifitName: 'aaagjhj', VendorName: 'ssss899', PeriodEnding: '', PaidAmount: '6767'}]);
    } else {
      return this.http.post(body.Url, {
        body,
        // urlParams : {'test' : 'ssss'}, This is for adding parameter
        options: {
          isMock: true
          // cache: 7 * 60 * 60 // 7 hours
        }
      });
    }
  }
  getCallLogList(body: {
    EntityId: string;
    ParticipantId: string;
    PageSize?: number;
    PageIndex?: number;
  }): Observable<MemberCallLogListDTO> {
    return this.http.post(this.memberApiUrls.memberCallLog, {
      body,
      // urlParams : {'test' : 'ssss'}, This is for adding parameter
      options: {
        isMock: true
        // cache: 7 * 60 * 60 // 7 hours
      }
    });
  }

  getMemberProfile(params: {
    EntityId: string;
    ClientId: string;
    ParticipantId: string;
  }): Observable<MemberProfileDTO> {
    return this.http.get(this.memberApiUrls.getProfile, {
      urlParams: params,
      options: {
        isMock: false
      }
    });
  }
  getMemberUrl(url: string, params: {
    // Url: string;
    EntityId: string;
    ClientId?: string;
    ParticipantId: string;
    BenefitEntityId?: string;
    BenefitEntityPolicyId?: string;
    entityclientId?: string;
    // BenefitEntityPolicyDetailId?: string;
  }): Observable<any> {
    return this.http.get(url, {
      urlParams: params,
      options: {
        isMock: false
      }
    });
  }


  getBenefitVendors(params: {
    EntityId: string;
    ClientId: string;
    ParticipantId: string;
  }): Observable<any> {
    return this.http.get(this.memberApiUrls.getBenefitEntities, {
      urlParams: params,
      options: {
        isMock: false
      }
    });
  }

  getMemberDependents(params: {
    EntityId: string;
    ParticipantId: string;
  }): Observable<any> {
    return this.http.get(this.memberApiUrls.memberDependents, {
      urlParams: params,
      options: {
        isMock: false
      }
    });
  }
  getMemberBeneficiaryLists(params: {
    EntityId: string;
    ParticipantId: string;
  }): Observable<any> {
    return this.http.get(this.memberApiUrls.beneficiaryLists, {
      urlParams: params,
      options: {
        isMock: false
      }
    });
  }
  saveMemberDependents(params: { dto: DependentListDTO }): Observable<any> {
    return this.http.post(this.memberApiUrls.saveDependent, {
      body: params.dto,
      options: {
        isMock: false
      }
    });
  }


  addMember(params: { dto: AddMemberDTO }): Observable<any> {
    return this.http.post(this.memberApiUrls.addMember, {
      body: params.dto,
      options: {
        isMock: false
      }
    });
  }

  addCallLog(params: { dto: MemberCallLogDTO }): Observable<any> {
    return this.http.post(this.memberApiUrls.addCallLog, {
      body: params.dto,
      options: {
        isMock: false
      }
    });
  }

  updateMemberProfile(params: { profile: MemberProfileDTO }): Observable<any> {
    return this.http.post(this.memberApiUrls.updateProfile, {
      body: params.profile,
      options: {
        isMock: false
      }
    });
  }

  deleteMember(params: { MemberId: string }): Observable<any> {
    return this.http.delete(this.memberApiUrls.deleteMember, {
      urlParams: params
    });
  }
}
