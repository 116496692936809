import { Component, OnInit, Input , Inject} from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ClientService } from '@services/client/client.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
  })
  export class UploadComponent implements OnInit {
    SERVER_URL = 'http://localhost:3000/upload';
    uploadForm: UntypedFormGroup ;
    fileToUpload: File | null = null;
     httpUploadOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
      };
    constructor(private formBuilder: UntypedFormBuilder, private httpClient: HttpClient) { }
    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }
    onSubmit() {
        /* const formData1 = new FormData();
        formData1.append('file', this.uploadForm.get('profile').value);

        this.httpClient.post<any>(this.SERVER_URL, formData1).subscribe(
          (res) => console.log(res),
          (err) => console.log(err)
        ); */
      }
    ngOnInit() {
        this.uploadForm = this.formBuilder.group({
            profile: ['']
          });
    }
    onFileSelect(event) {
        /* if (event.target.files.length > 0) {
          const file = event.target.files[0];
          this.uploadForm.get('profile').setValue(file);
        } */
      }
  }
