import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import {
  FormError,
  FormHelperService,
  FORM_CONTROL_ERROR_KEY
} from '@shared/form-controls/form-helper.service';
import {
  FORM_CONTROL_DEFAULT_ERROR_TEMPLATE,
  FORM_CONTROL_VALIDATION_ERROR_TEMPLATE
} from '@shared/form-controls/form-error-template';
import { BaseComponent } from '@shared/components/base.component';

const FORM_CONTROL_PATH_SEPARATOR =
  '&nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i>&nbsp;';

export class ControlErrorMessage {
  message: string;
  controlPath: string;
  controlKeys: string[];
  friendlyKeys: string[];
}

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent extends BaseComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;
  @Input()
  show: boolean;

  errors?: ControlErrorMessage[];

  constructor(private formHelper: FormHelperService) {
    super();
  }

  ngOnInit() {}

  public update() {
    this.errors = this.getErrors();
  }

  private getErrors(): ControlErrorMessage[] | undefined {
    this.errors = [];
    if (this.formGroup.valid) {
      return;
    }

    const formErrors = this.formHelper.getFormError(this.formGroup);
    const errors = _.map(formErrors, (value, controlName) =>
      this.getControlErrors({ controlName, value, controlKeys: [] })
    );

    return _.compact(_.flattenDeep(errors));
  }

  private getControlErrors(params: {
    controlName: string;
    value: FormError;
    controlKeys: string[];
  }): any {
    const { controlName, value } = { ...params };
    const controlKeys = [...params.controlKeys, controlName];
    const keys = Object.keys(value);
    if (_.isEmpty(keys)) {
      return;
    }

    return _.map(keys, key => {
      if (key === FORM_CONTROL_ERROR_KEY) {
        return this.createControlErrorMessages({ value, controlKeys });
      }

      // check if its a nested control
      // if yes, make a recursive call
      if (value[key]) {
        return this.getControlErrors({
          value: value[key],
          controlName: key,
          controlKeys
        });
      }

      // show generic error for the control path
      return this.createDefaultErrorMessage(controlKeys);
    });
  }

  private createControlErrorMessages(params: {
    value: FormError;
    controlKeys: string[];
  }): ControlErrorMessage[] | undefined {
    const { value, controlKeys } = { ...params };
    const validationErrors = value[FORM_CONTROL_ERROR_KEY];
    if (!validationErrors) {
      return;
    }

    return _.map(Object.keys(validationErrors), validatorKey => {
      if (FORM_CONTROL_VALIDATION_ERROR_TEMPLATE.hasOwnProperty(validatorKey)) {
        return this.createValidatorErrorMessage({
          validatorKey,
          error: validationErrors[validatorKey],
          controlKeys
        });
      }
      // fallback to generic error for the control path
      return this.createDefaultErrorMessage(controlKeys);
    });
  }

  private createValidatorErrorMessage(params: {
    validatorKey: string;
    error: any;
    controlKeys: string[];
  }): ControlErrorMessage {
    const { validatorKey, error, controlKeys } = { ...params };
    const template = FORM_CONTROL_VALIDATION_ERROR_TEMPLATE[validatorKey];
    const data = {
      controlPath: this.getFriendlyControlPath(controlKeys),
      ...error
    };
    const message = template(data);

    return {
      message,
      controlPath: data.controlPath,
      controlKeys,
      friendlyKeys: this.getFriendlyControlKeys(controlKeys)
    };
  }

  private createDefaultErrorMessage(
    controlKeys: string[]
  ): ControlErrorMessage {
    const controlPath = this.getFriendlyControlPath(controlKeys);
    const message = FORM_CONTROL_DEFAULT_ERROR_TEMPLATE({ controlPath });

    return {
      message,
      controlPath,
      controlKeys,
      friendlyKeys: this.getFriendlyControlKeys(controlKeys)
    };
  }

  private getFriendlyControlPath(controlKeys: string[]): string {
    return this.getFriendlyControlKeys(controlKeys).join(
      FORM_CONTROL_PATH_SEPARATOR
    );
  }

  private getFriendlyControlKeys(controlKeys: string[]): string[] {
    const keys: any = [];

    _.each(controlKeys, key => {
      const v = parseInt(key, 10);
      if (Number.isFinite(v)) {
        // update the prior key in the array by appending the number
        const prevIndex = keys.length - 1;
        const prevKey = keys[prevIndex];
        keys[prevIndex] = `${prevKey} #${v + 1}`;
      } else {
        keys.push(_.startCase(key));
      }
    });

    return keys;
  }
}
