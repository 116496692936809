import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClientService } from '@services/client/client.service';
// import { ResetPasswordUser } from '../shared/interfaces';

@Component({
  selector: 'app-reset-validation',
  templateUrl: './reset-validation.component.html',
  styleUrls: ['./reset-validation.component.scss']
})

export class ResetValidationComponent implements OnInit {
  config = window['___alliant-config-eniac-APP___']
  logintype = this.config.logintype
  welcomeText = this.config.welcomeText
  phoneNum = this.config.phoneNum
  primaryColor = this.config.primaryColor
  bgImage = this.config.bgImage
  isPrivacyLink = this.config.isPrivacyLink
  isTermsLink = this.config.isTermsLink
  registrationParam = this.config.registrationParam
  resetValidationForm: UntypedFormGroup
  clientId: string
  validUser:any;
  userName: any;
  email: any;
  alertRed: boolean = false
  alertMessage: any = ''
  SecurityQuestionId: string
  SecurityQuestionText: string
  // entityClientId: string = '42445106-54b5-44d3-a134-a2dfa7837c17'   //LEEBA  hardcoded value
  // entityId: string = 'e3c3e77d-d76e-4bb8-af30-ac165ca9807b'   //LEEBA ClientID hardcoded value
  entityId: string = '9808F856-F047-4843-BEC8-277E414FC649'   //TMAIT  hardcoded value
  entityClientId: string = '00000000-0000-0000-0000-000000000000'   //TMAIT ClientID hardcoded value
  

  constructor(
    private http: HttpClient,
    private router: Router,
    protected clientService: ClientService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.resetValidationForm = new UntypedFormGroup({
      username: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('',[ Validators.required, Validators.email])
      
    })
  }

  onSubmit() {
    if (this.resetValidationForm.valid) {
     
      const userToRetrive = {
        username: this.resetValidationForm.value.username,
        email: this.resetValidationForm.value.email
      }

      const observ$ = this.clientService.postWithUrl({ url: `/CaseManagement/SecurityQuestion/${this.entityId}/${this.entityClientId}`, dto: userToRetrive });
      observ$.subscribe(res => {
        this.email = this.resetValidationForm.value.email;
        this.userName = this.resetValidationForm.value.username
        this.SecurityQuestionId = res[0].securityQuestionId
        this.SecurityQuestionText = res[0].securityQuestionText
        this.router.navigate(['/reset_confirm'], { queryParams: { unm: this.userName, QId: this.SecurityQuestionId, sqt: this.SecurityQuestionText,  ml: this.email}} )

      }, err => {
        this.alertMessage = err.error
        this.openDialog()
      })
  } else {
    this.resetValidationForm.markAllAsTouched()
  }
  }

  openDialog() {
    this.dialog.open(DialogElementsResetPopup, {
      data: {
        alertMessage: this.alertMessage
      }
    });
  }
}

@Component({
  selector: 'reset-popup',
  templateUrl: 'reset-popup.html',
})
export class DialogElementsResetPopup { 
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}
}  

