import {
  MemberCallLogListDTO,
  MemberCallLogDTO
} from '@models/member-list/member-list.dto';
import { HttpRequest } from '@angular/common/http';
import * as faker from 'faker';
import * as _ from 'lodash';
import {
  MockRequestHandler,
  MockRequestHandlerParams
} from '@mock/mock-data/shared/mock-handler.interface';
import {
  MemberListItemDTO,
  MemberListDTO
} from '@models/member-list/member-list.dto';

export class MemberCallLogListMockHandler extends MockRequestHandler<
  MemberCallLogListDTO
> {
  handleRequest(params: MockRequestHandlerParams): MemberCallLogListDTO {
    const req = params.reqInfo.req as HttpRequest<any>;
    const body = req.body;
    const entityId = body.entityId;
    const participantId = body.participantId;
    const pageIndex = body.PageIndex || 0;
    const pageSize = body.PageSize || 10;
    const totalRowCount = body.TotalRowCount || 1000;

    return {
      entityId,
      participantId,
      pageSize,
      pageIndex,
      totalRowCount,
      totalPageCount: Math.ceil(totalRowCount / pageSize),
      callLogList: _.range(pageSize).map(() =>
        createMockMemberCallLogDTO({ entityId, participantId })
      )
    };
  }
}

export function createMockMemberCallLogDTO(params: {
  entityId: string;
  participantId: string;
}): MemberCallLogDTO {
  return {
    entityId: '',
    participantId: '',
    callLogId: faker.random.number(1000),
    status: 'Active',
    contactNumber: faker.phone.phoneNumber(),
    emailId: faker.internet.email(),
    subject: faker.random.words(5),
    callDetail: faker.random.words(50),
    callDuration: '2:30'
  };
}

export class MemberCallLogAddMockHandler extends MockRequestHandler<
MemberCallLogDTO
> {
  handleRequest(params: MockRequestHandlerParams): any {
    return {};
  }
}
