import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NewUser } from '../Interfaces/user-screens-interfaces';
import { CheckParticipant } from '../Interfaces/user-screens-interfaces';

@Injectable({providedIn: 'root'})
export class RegisterService {

    private url: any;
    
    constructor(
        private http: HttpClient
        ) {}

    //POST requset for register a new member/user
    registerNewUser(user: NewUser): Observable<NewUser> {
        return this.http.post<NewUser>('https://jsonplaceholder.typicode.com/todos/1', user)   // Dummy API POST request for testing !Must be changesd ASAP!
        .pipe(catchError(this.errorHandler))
    }

    //POST requset to check if participant exist in the DB
    isParticipantExist(user: CheckParticipant): Observable<CheckParticipant> {
        return this.http.post<CheckParticipant>('https://jsonplaceholder.typicode.com/todos/1', user)   // Dummy API POST request for testing !Must be changesd ASAP!
        .pipe(catchError(this.errorHandler))
    }

    //Custom error handlesr are useless now but might be used for future scalability
    errorHandler(err) {
        if(err instanceof HttpErrorResponse) {
            //Server side error
        } else {
            //Client side error 
        }
        return throwError(err)
    }

}