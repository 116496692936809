import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Observable,
  of,
  forkJoin,
  empty,
  combineLatest,
  throwError
} from 'rxjs';
import { switchMap, defaultIfEmpty, catchError } from 'rxjs/operators';
import { AppState } from '@store/app.state';
import { getSelectionState } from './selection.selector';
import { ClientDTO } from '@models/client/client.dto';
import {
  EntityDTO,
  EntityAndClientDTO,
  EntityIdClientIdDTO
} from '@models/entity/entity.dto';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {
  constructor(private store: Store<AppState>) {}

  getEntity(): Observable<EntityDTO | undefined | null> {
    return this.store
      .select(getSelectionState)
      .pipe(switchMap(state => (state ? of(state.entity) : of(null))));
  }

  getClient(): Observable<ClientDTO | undefined | null> {
    return this.store
      .select(getSelectionState)
      .pipe(switchMap(state => (state ? of(state.client) : of(null))));
  }

  getEntityAndClient(): Observable<EntityAndClientDTO> {
    return combineLatest(this.getEntity(), this.getClient()).pipe(
      switchMap(all =>
        of({
          entity: all[0],
          client: all[1]
        })
      )
    );
  }

  getSelectedEntityIdClientId(): Observable<EntityIdClientIdDTO | null> {
    return this.getEntityAndClient().pipe(
      switchMap(all => {
        const { entity, client } = all;

        if (!entity) {
          return of(null);
        }

        if (client) {
          return of({
            entityId: entity.id,
            clientId: client.clientId
          });
        }

        return of({
          entityId: entity.id
        });
      })
    );
  }
}
