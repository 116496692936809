import { Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';
import * as _ from 'lodash';

export const NUMBER_FORMAT_REGEX = /^(\d+)?\.((\d+)(-(\d+))?)?$/;
export const ONLY_DIGITS_REGEX = /^[0-9]*$/;
export const LOCALE_ID = 'en-US';
export const DECIMAL_SEP = '.';
export const GROUP_SEP = ',';
export const MINUS_SIGN = '-';

@Injectable({
  providedIn: 'root'
})
export class DecimalFormatterService {
  format(value: number, digitsInfo?: string): string {
    return formatNumber(value, LOCALE_ID, digitsInfo);
  }

  parse(
    value: string,
    digitsInfo?: string,
    defaultValue?: number
  ): number | undefined {
    value = (value + '').trim();
    if (!value) {
      return defaultValue;
    }

    // remove group separator
    value = value.split(GROUP_SEP).join('');
    if (!value) {
      return defaultValue;
    }

    // remove the minus sign, but only if it's the start of the string
    let negateFactor = 1;
    if (value[0] === MINUS_SIGN) {
      negateFactor = -1;
      value = value.slice(1);
    }

    // multiple dots are never allowed
    const parts = value.split(DECIMAL_SEP);
    if (parts.length > 2) {
      return defaultValue;
    }

    // characters other than digits and a single decimal dot are not allowed
    // (group separators were removed earlier)
    if (!parts.every(part => ONLY_DIGITS_REGEX.test(part))) {
      return defaultValue;
    }

    const { maxFraction } = this.parseDigitsInfo(digitsInfo);

    // limit fraction part to maxFraction digits.
    if (parts.length > 1) {
      const afterDecimal = parts[1];
      parts[1] = afterDecimal.substr(0, maxFraction);
    }

    value = parts.join(DECIMAL_SEP);
    const decimalValue = parseFloat(value);
    if (_.isFinite(decimalValue)) {
      return decimalValue * negateFactor;
    }

    return defaultValue;
  }

  private parseDigitsInfo(digitsInfo?: string) {
    let minInt = 1;
    let minFraction = 0;
    let maxFraction = 0;

    if (!digitsInfo) {
      return { minInt, minFraction, maxFraction };
    }

    const parts = digitsInfo.match(NUMBER_FORMAT_REGEX);
    if (parts === null) {
      throw new Error(`${digitsInfo} is not a valid digit info`);
    }

    const minIntPart = parts[1];
    const minFractionPart = parts[3];
    const maxFractionPart = parts[5];

    if (minIntPart != null) {
      minInt = parseInt(minIntPart, 10);
    }

    if (minFractionPart != null) {
      minFraction = parseInt(minFractionPart, 10);
    }

    if (maxFractionPart != null) {
      maxFraction = parseInt(maxFractionPart, 10);
    } else if (minFractionPart != null && minFraction > maxFraction) {
      maxFraction = minFraction;
    }

    return { minInt, minFraction, maxFraction };
  }
}
