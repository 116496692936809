import { createSelector } from '@ngrx/store';
import {
  AllLookupActions,
  LookupActionTypes
} from '@store/lookup/lookup.actions';
import { LookupState, LookupValue } from '@store/lookup/lookup.state';
import { AppState } from '@store/app.state';

export const initialState: LookupState = {
  loading: false
};

export function lookupReducer(
  state: LookupState = initialState,
  action: AllLookupActions
): LookupState {
  switch (action.type) {
    case LookupActionTypes.LOAD_LOOKUP: {
      return { ...state, loading: true };
    }

    case LookupActionTypes.LOAD_LOOKUP_SUCCESS: {
      const { lookupCode, items, defaultValue } = {
        ...action.payload.lookupDTO
      };
      const lookups = { ...state.lookups };
      lookups[lookupCode] = { items, defaultValue };
      return { ...state, loading: false, lookups };
    }

    case LookupActionTypes.LOAD_LOOKUP_FAIL: {
      const error = {
        lookupCode: action.payload.lookupCode,
        error: action.payload.error
      };

      return { ...state, loading: false, error };
    }

    default:
      return state;
  }
}

export const getLookupState = (state: AppState) => state.lookup;

export const getLookups = createSelector(
  getLookupState,
  lookupState => lookupState.lookups
);
