import { Injector } from '@angular/core';
import { MemoryCacheService, MemoryCacheFactory } from '@shared/cache/memory-cache.service';
import { LocalStorageCacheService, LocalStorageCacheFactory } from '@shared/cache/local-storage-cache.service';
import { LocalStorageService } from '@shared/storage/local-storage.service';
import { HttpCacheService, HttpCacheFactory } from '@shared/cache/http-cache.service';

export const CACHE_PROVIDERS = [
    {
        provide: MemoryCacheService,
        useFactory: MemoryCacheFactory
    },
    {
        provide: LocalStorageCacheService,
        useFactory: LocalStorageCacheFactory,
        deps: [LocalStorageService]
    },
    {
        provide: HttpCacheService,
        useFactory: HttpCacheFactory,
        deps: [Injector]
    }
];
