<a color="primary" href="javascript:void(0)" (click)="download()">
  <div style="padding-right: 20px;padding-top: 5px;">
    <i class="fa fa-file-excel-o float-right" style="color: green; font-size: 30px;" aria-hidden="true"></i> 
  </div>
 </a>
<a *ngIf="view=='grid'" (click)="expandAll()"> Expand All</a>&nbsp;&nbsp;<a *ngIf="view=='grid'" (click)="collapseAll()"> Collapse All</a>&nbsp;&nbsp;

 <div *ngIf="view=='grid'">
  <nz-table nzSize="small" [nzPageSize]='50'   #basicTable [nzData]="filterClientData | keyvalue">
  <thead>
    <tr>
      <th style="font-size:16px;font-weight:bold;color:#170e5c" nzWidth="50px" nzShowExpand ></th>
      <th style="font-size:16px;font-weight:bold;color:#170e5c" nzWidth="215px"> Name</th>
      
    </tr>
  </thead>
  <tbody>
    <ng-template ngFor let-data1 [ngForOf]="basicTable.data">
      <tr  *ngIf="data1.key!='ClientCode' && data1.key!='ClientName' && data1.key!='Benefits'" >
        <td nzShowExpand [(nzExpand)]="mapOfExpandData[data1.key] "
        (nzExpandChange)="expand(data1,$event)" ></td>
       
        <td>{{data1.key}}</td>
       
      </tr>
      <tr [nzExpand]="mapOfExpandData[data1.key] ">
        <td>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;  </td>
        <td colspan="1">
          <div class="main-data" *ngFor="let data of data1.value[0] | keyvalue">
            <div>
              <span class="key">{{data.key}}: </span>
              <span class="value">{{data.value}}</span>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </tbody>
</nz-table>
 </div>
<div class="row col-12" style="margin: 0px !important;" *ngIf="view=='card'">
  <ng-container *ngFor="let data  of filterClientData | keyvalue" >
    <div class="card-wraper col-lg-3 col-md-6 col-sm-6 col-xs-12" style="margin-top:15px;padding: 0px 7px 0px 7px !important;" *ngIf="data.key!='Benefits' && data.key!='ClientCode' && data.key!='ClientName'">
      <div class="card-body">
        <div class="label">{{data.key}} <i class="" aria-hidden="true"></i></div>
        <div class="p-1">
          <div class="main-data" *ngFor="let data1 of data.value[0] | keyvalue">
            <div>
              <span class="key">{{data1.key}}: </span>
              <span class="value">{{data1.value}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>
